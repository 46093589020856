import React, { useRef } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import useClickOutside from 'hooks/useClickOutside';

import { Container, TextButton } from './styles';

const SingleCommentMenu = ({ closeMenu, handleDelete }) => {
  const containerRef = useRef();
  useClickOutside(containerRef, closeMenu);

  return (
    <Container ref={containerRef}>
      <TextButton onClick={handleDelete}>
        <DeleteIcon />
        Delete
      </TextButton>
    </Container>
  );
};

export default SingleCommentMenu;

import React from 'react';
import {
  CategoryListContainer,
} from './styles';
import CategoryCard from './CategoryCard';
import iconRocket from 'assets/images/icon-rocket.svg';
import iconFeatures from 'assets/images/icon-features.svg';
import iconTools from 'assets/images/icon-tools.svg';
import iconFolders from 'assets/images/icon-folders.svg';

const CategoryList = () => {
  return (
    <CategoryListContainer>
      <CategoryCard
        icon={iconRocket}
        title="Getting Started"
        description="New to Lyncwork? Find guides to help you get started and learn the basics."
        path="/help/topic/getting-started"
      />
      <CategoryCard
        icon={iconFeatures}
        title="Features"
        description="Details on all the features available on Lyncwork."
        path="/help/topic/features"
      />
      <CategoryCard
        icon={iconTools}
        title="Applications"
        description="Learn how to connect third party integrations with Lyncwork."
        path="/help/topic/applications"
      />
      <CategoryCard
        icon={iconFolders}
        title="Use Cases"
        description="Specific use case examples for different industries."
        path="/help/topic/use-cases"
      />
    </CategoryListContainer>
  );
};

export default CategoryList;

import styled from 'styled-components';
import Card from 'components/Card';
import { multilineEllipsis } from 'StyleVariables/StandardComponents';
import { IconButton } from 'components/NewLyncworkUIKit';

export const Container = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 340px;
  width: 100%;
`;

export const InfoContainer = styled.div`
  flex-grow: 1;
  margin: 56px 0 0 0;
  padding: 8px 24px 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`;

export const CompanyName = styled.h1`
  margin: 0 0 4px;
  ${props => props.theme.typography.header1};
  font-weight: 600;
`;

export const DetailContainer = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 100%;
  margin: 4px 0;
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey07};
  span {
    min-width: 0;
    ${multilineEllipsis};
  }
  .MuiSvgIcon-root {
    margin-right: 4px;
    font-size: inherit;
  }

  strong {
    color: ${props => props.theme.colors.neutral.grey10};
    flex-shrink: 0;
    width: 80px;
  }

  a {
    cursor: pointer;
    color: ${props => props.theme.colors.feedback.confirmation};
  }
`;

DetailContainer.defaultProps = { lineClamp: 1 };

export const SocialMediaLinkContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left:auto;
  a {
    border-radius:50%;
    box-sizing:border-box;
    cursor:pointer;
    width: 16px;
    height: 16px;
    margin-left: 8px;
    display:flex
    align-items:center;
    justify-content: center;
    img{
      width:16px;
      height:16px;
      object-fit:contain;
    }
  }
`;

export const StyledIconButton = styled(IconButton)`
  width: 18px;
  height: 18px;
  font-size: 18px;
  .MuiSvgIcon-root {
    font-size: inherit;
  }
`;

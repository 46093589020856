import { useState, useEffect } from 'react';
import { RoleService, LicenceService } from 'services';
import { useModal } from 'hooks';

import { getLicenseDetails, getRoleData, compare } from './utils';

const roleDataInitialValue = { applications: [], licenseInfo: {} };
const initAppChanges = {
  applicationsDeleted: [],
  applicationsAdded: [],
  appsWithLicenseChanged: [],
  appsWithLicenseIssues: []
};

const useRolesChange = userData => {
  const {
    isOpen: isSavePreflightOpen,
    openModal: openSavePreflight,
    closeModal: closeSavePreflight
  } = useModal();
  const [rolesDetail, setRolesDetail] = useState({});
  const [licenseDetail, setLicenseDetail] = useState({});
  const [applicationChanges, setApplicationChanges] = useState(initAppChanges);
  const [oldRoleData, setOldRoleData] = useState(roleDataInitialValue);

  useEffect(() => {
    if (!userData) {
      return;
    }
    const initRolesData = async () => {
      try {
        const roles = await Promise.all(
          userData.roles.map(async role => {
            const response = await RoleService.find(role.id);
            return response.data;
          })
        );
        const rolesDetail = roles.reduce((prev, currentRole) => {
          prev[currentRole.id] = currentRole;
          return prev;
        }, {});

        const roleData = getRoleData(roles);
        const licenseDetail = await getLicenseDetails(roleData);
        setRolesDetail(rolesDetail);
        setLicenseDetail(licenseDetail);
        setOldRoleData(roleData);
      } catch (error) {
        console.log(error);
      }
    };
    initRolesData();
  }, [userData]);

  const shouldDisplayPreflight = async newRoles => {
    const oldRolesDetails = { ...rolesDetail };
    const roles = await Promise.all(
      newRoles.map(async role => {
        if (oldRolesDetails[role.id]) {
          return oldRolesDetails[role.id];
        }
        const { data: roleData } = await RoleService.find(role.id);
        oldRolesDetails[role.id] = roleData;
        return roleData;
      })
    );
    const newRoleData = getRoleData(roles);
    const newLicenseDetail = await getLicenseDetails(
      newRoleData,
      licenseDetail
    );
    setLicenseDetail(newLicenseDetail);
    setRolesDetail(oldRolesDetails);
    const { applicationChanges, displayPreflight } = compare(
      oldRoleData,
      newRoleData,
      licenseDetail
    );
    setApplicationChanges(applicationChanges);
    return displayPreflight;
  };

  return {
    shouldDisplayPreflight,
    applicationChanges,
    isSavePreflightOpen,
    openSavePreflight,
    closeSavePreflight
  };
};

export default useRolesChange;

import styled from 'styled-components';
import Card from 'components/Card';
import { multilineEllipsis } from 'StyleVariables/StandardComponents';

export const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 24px;

  grid-row: 2/3;
  grid-column: 1/2;
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 0 24px 0;
  h1 {
    margin: 0;
    ${props => props.theme.typography.header2};
    color: ${props => props.theme.colors.neutral.grey10};
    font-weight: 600;
  }

  a {
    text-decoration: none;
  }
`;

export const JourneysContainer = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 80px;
  width: 100%;
  .MuiCircularProgress-root {
    margin: 36px;
  }

  .empty-message {
    margin: 36px;
    ${props => props.theme.typography.body};
    font-weight: 600;
    color: ${props => props.theme.colors.neutral.grey10};
  }
`;

export const TableRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 36px;
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey10};
  &.header {
    ${props => props.theme.typography.metadata};
    font-weight: 500;
    color: ${props => props.theme.colors.neutral.grey07};
  }

  .name {
    ${multilineEllipsis}
    flex-basis:40%;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .description {
    ${multilineEllipsis}
    flex-basis:40%;
    flex-grow: 1;
  }

  .view {
    text-decoration: none;
    flex-basis: 80px;
  }
`;

export const ProgressBar = styled.div`
  position: relative;
  height: 8px;
  flex-grow: 1;
  border-radius: 4px;
  margin: 0 12px 0 0;
  background-color: ${props => props.theme.colors.neutral.grey02};

  &:after {
    content: '';
    position: absolute;
    border-radius: 4px;
    height: 100%;
    width: ${props => props.barWidth};
    background-color: ${props => props.theme.colors.brand.primary};
  }
`;

TableRow.defaultProps = { lineClamp: 1 };

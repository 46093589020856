import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 132px;
  background: ${props => props.theme.colors.brand.primaryLite};
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: ${props => (props.showImage ? 'unset' : 'none')};
  }
  input {
    cursor: pointer;
    position: absolute;
    box-sizing: border-box;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: blue;
  }
`;

import styled from 'styled-components';

import Card from 'components/Card';

export const AppInfoContainer = styled(Card)`
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  display: grid;
  gap: 24px;
  grid-template-rows: 72px auto;
  grid-template-columns: auto 148px;
`;

export const GeneralInfo = styled.div`
  grid-column: 1/3;
  height: 72px;
  display: flex;
  align-items: center;
  gap: 20px;
  color: ${props => props.theme.colors.neutral.grey10};
  ${props => props.theme.typography.header2};
  font-weight: 600;

  .avatar {
    width: 64px;
    height: 64px;
  }
`;

export const AppDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  h2 {
    ${props => props.theme.typography.body};
    font-weight: 600;
    margin: 0 0 8px 0;
  }
  p {
    ${props => props.theme.typography.control};
    color: ${props => props.theme.colors.neutral.grey07};
    margin: 0;
  }
`;

export const CapabilityContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  ${props => props.theme.typography.control};
  font-weight: 500;
  color: ${props => props.theme.colors.neutral.grey10};
  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 0;
    img {
      object-fit: contain;
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-shrink: 0;
  width: 35%;
  max-width: 380px;
  box-shadow: 2px 0px 10px rgba(223, 226, 233, 0.5);
  background-color: white;
`;

export const LogoContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  height: 80px;
  box-sizing: border-box;
  padding: 0 40px;
  width: 100%;
  background-color: white;
  z-index: 2;
  img {
    width: 150px;
  }
`;

export const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 0 40px;

  box-sizing: border-box;
`;

export const Title = styled.p`
  ${props => props.theme.typography.header1};
  font-weight: 600;
  margin-bottom: 0;
`;

export const Description = styled.div`
  ${props => props.theme.typography.control};
  margin-block-start: 1em;
  margin-block-end: 1em;
  flex-shrink: 0;
`;

export const SideBarImage = styled.img`
  flex-grow: 0;
  margin-top: auto;
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: contain;
`;

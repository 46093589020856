import React from 'react';

import { Container, Select, Label } from './styles';

const getNewOptionData = inputValue => {
  const name = inputValue;
  const id = inputValue.toLowerCase();
  return { name, id, isNew: true };
};
const formatCreateLabel = inputValue => `Creat '${inputValue}'`;
const getOptionLabel = option => {
  const { isNew, name } = option;
  if (isNew) {
    return `Add Skill '${name}'`;
  }
  return name;
};

const AddSkills = ({ addSkill, createSkill, loadSkills }) => {
  return (
    <Container>
      <Label>Add Skill</Label>
      <Select
        placeholder="search skills"
        value={null}
        onChange={addSkill}
        loadOptions={loadSkills}
        getNewOptionData={getNewOptionData}
        getOptionLabel={getOptionLabel}
        onCreateOption={createSkill}
      />
    </Container>
  );
};

export default AddSkills;

import styled from 'styled-components';

export const SectionTitle = styled.h2`
  margin: 40px 0 24px 0;
  width: 100%;
  ${props => props.theme.typography.header1};
  color: ${props => props.theme.colors.neutral.grey10};
  font-weight: 600;
  text-align: left;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.grey06};
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .MuiCircularProgress-root {
    margin: 40px auto;
  }
`;

export const NoSkillsMessageContainer = styled.div`
  box-sizing: border-box;
  padding: 0 20px;
  margin: 0 0 8px;
  text-align: center;
  width: 100%;
`;

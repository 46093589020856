import styled from 'styled-components';
import { multilineEllipsisFunc } from 'StyleVariables/StandardComponents';
import { colors } from 'designSystem/foundations';

export const PortraitContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.neutral.white};
  gap: 8px;
  margin: 12px 0;
  padding: 4px;
  /* box-sizing: border-box; */
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.neutral.grey01};
  }
`;

export const Portrait = styled.div`
  min-width: 36px;
  height: 36px;
  border-radius: 50px;
  background-color: ${colors.neutral.grey02};
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
`;
export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.small}) {
    display: none;
  }
`;
export const Name = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  /* identical to box height, or 143% */

  /* Neutral/Grey 10 */
${multilineEllipsisFunc(1)}
  color: ${colors.neutral.grey10};
`;
export const Role = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  /* identical to box height, or 143% */

  /* Neutral/Grey 10 */
${multilineEllipsisFunc(1)}
  color: ${colors.neutral.grey05};
`;

export const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 20px;
  height: 20px;
  img {
    object-fit: contain;
    transform-origin: center center;
    transform: rotate(${({ open }) => (!open ? '90deg' : '-90deg')});
    transition: transform 0.3s;
  }
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.small}) {
    display: none;
  }
`;

export const DropMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 100%;
  right: 12px;
  width: 191px;
  padding: 10px 0;
  background-color: white;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
`;
export const MenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20x;
  margin: 0;
  height: 32px;
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  padding: 20px 0 20px 20px;

  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background-color: ${colors.neutral.grey02};
  }
`;

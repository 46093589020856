import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  useRef
} from 'react';

import useInterval from 'hooks/useInterval';
import { NotificationService } from 'services/notification.service';

import NotificationList from './NotificationList';

const notificationContext = React.createContext();

export const useNotification = () => useContext(notificationContext);

const oneMinuteInMS = 60 * 1000;

const NotificationContext = ({ children, shouldFetch }) => {
  const [notifications, setNotifications] = useState([]);
  const [open, setOpen] = useState(false);
  const toggleRef = useRef();
  const fetchInterval = shouldFetch ? oneMinuteInMS : null;
  const unseenNotifications = notifications.filter(x => !x.seen);
  const unseenCount = unseenNotifications.length;

  const getNotifications = async () => {
    try {
      let { data } = await NotificationService.list();
      setNotifications(data);
    } catch (err) {
      console.log(err);
    }
  };

  useInterval(getNotifications, fetchInterval, true);

  useEffect(() => {
    const handleClick = e => {
      if (toggleRef.current?.contains(e.target)) {
        return;
      }
      setOpen(false);
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [setOpen]);

  const toggleNotificationPanel = () => {
    setOpen(a => !a);
    if (unseenCount > 0) {
      const unseenIds = unseenNotifications.map(({ id }) => id);
      setNotifications(oldNotifications =>
        oldNotifications.map(notification => ({ ...notification, seen: true }))
      );
      NotificationService.setSeen(unseenIds);
    }
  };

  const clickNotification = async id => {
    setNotifications(oldNotifications =>
      oldNotifications.map(notification => {
        if (notification.id !== id) {
          return notification;
        }
        return { ...notification, clicked: true };
      })
    );
    NotificationService.setClicked(id);
  };

  const value = {
    unseenCount,
    toggleNotificationPanel,
    toggleRef
  };

  return (
    <notificationContext.Provider value={value}>
      {children}
      {open && (
        <NotificationList
          notifications={notifications}
          clickNotification={clickNotification}
        />
      )}
    </notificationContext.Provider>
  );
};

export default NotificationContext;

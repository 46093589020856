import {
  differenceInYears,
  differenceInMonths,
  differenceInWeeks,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  formatRelative,
  subHours,
  subMinutes
} from 'date-fns';

const dateCalculationList = [
  { type: 'y', calc: differenceInYears },
  { type: 'mo', calc: differenceInMonths },
  { type: 'w', calc: differenceInWeeks },
  { type: 'd', calc: differenceInDays },
  { type: 'h', calc: differenceInHours },
  { type: 'm', calc: differenceInMinutes }
];

const getTimeDifference = date => {
  const today = new Date();
  const dateToCompare = new Date(date);
  const differenceList = dateCalculationList.map(({ type, calc }) => {
    const difference = calc(today, dateToCompare);
    return { type, difference };
  });

  const differenceObject = differenceList.find(
    ({ difference }) => difference > 0
  );
  if (!differenceObject) {
    return 'now';
  }
  const { difference, type } = differenceObject;

  return `${difference}${type}`;
};

export default getTimeDifference;

import React from 'react';

import { useUsers } from './hooks';
import { InfiniteScrollList, BlankScreen, UserCard } from './components';

const RoleUsers = ({ roleId }) => {
  const {
    searchQuery,
    name,
    loading,
    users,
    hasMore,
    loadMore,
    handleInputChange
  } = useUsers(roleId);
  const hasUsers = users.length > 0;
  const noResultFound = !hasUsers && !!name;
  const hasNoUsers = !hasUsers && !name;
  return (
    <InfiniteScrollList
      hasMore={hasMore}
      loadMore={loadMore}
      placeholder="Search Users"
      searchQuery={searchQuery}
      handleInputChange={handleInputChange}
    >
      {loading ? (
        <div />
      ) : noResultFound ? (
        <BlankScreen message="Sorry, we couldn't find anything" />
      ) : hasNoUsers ? (
        <BlankScreen message="There are no users linked to this role, please assign them in the ‘Users’ page." />
      ) : (
        users.map(user => {
          return <UserCard key={user.id} user={user} />;
        })
      )}
    </InfiniteScrollList>
  );
};

export default RoleUsers;

import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import emptyState from 'assets/images/GenericIcons/empty state.svg';

import {
  TableContainer,
  ListContainer,
  HeaderRow,
  Cell,
  BlankContainer
} from './styles';

const NoResults = () => {
  return (
    <BlankContainer>
      <img src={emptyState} alt="empty state" />
      Sorry, we couldn't find anything
    </BlankContainer>
  );
};

const Loading = () => (
  <BlankContainer>
    <CircularProgress />
  </BlankContainer>
);

const Header = () => {
  return (
    <HeaderRow>
      <Cell>Name</Cell>
      <Cell>Teams</Cell>
      <Cell>Email</Cell>
    </HeaderRow>
  );
};

const TableContent = ({ loading, hasUsers, children }) => {
  if (loading) {
    return <Loading />;
  }

  if (!hasUsers) {
    return <NoResults />;
  }

  return <ListContainer>{children}</ListContainer>;
};

const Table = ({ loading, hasUsers, children }) => {
  return (
    <TableContainer>
      <Header />
      <TableContent loading={loading} hasUsers={hasUsers}>
        {children}
      </TableContent>
    </TableContainer>
  );
};

export default Table;

import React from 'react';
import { Grid } from './styles';
import {
  Overview,
  Journeys,
  TodayAgenda,
  Applications,
  Community,
  UpcomingTasks,
  RewardCentre,
  Documents
} from './components';
import showDemoFeature from 'utilities/showDemoFeature';

const Dashboard = () => {
  const render = showDemoFeature(window.location.hostname);
  return (
    <Grid>
      <Applications />
      <Overview />
      <Journeys />
      <TodayAgenda />
      <Documents />
      <Community />
      <UpcomingTasks />
      {render && <RewardCentre />}
    </Grid>
  );
};

export default Dashboard;

import styled from 'styled-components';
import { colors } from 'designSystem/foundations';
import { CustomRadio } from 'components/NewLyncworkUIKit';

export const Card = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.neutral.white};
  padding: 24px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  grid-row: 1 / 2;
  grid-column: 2 / 3;
`;
export const SectionTitle = styled.h1`
  margin: 0 0 16px 0;
  ${props => props.theme.typography.header2};
  font-weight: 600;
`;

export const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 16px 0;
  width: 260px;
  height: 20px;
`;

export const RadioButton = styled(CustomRadio)`
  margin-right: 12px;
`;

export const Label = styled.span`
  ${props => props.theme.typography.control};
  margin: 0 0 8px 0;
  font-weight: 600;
`;

const {
  neutral: { grey07 },
  feedback: { error, warning02 }
} = colors;

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  .MuiSvgIcon-root {
    color: ${warning02};
    font-size: inherit;
  }
`;

export const SubLabel = styled.span`
  text-align: left;
  ${props => props.theme.typography.metadata};
  /* color: ${props => props.theme.colors.neutral.grey07}; */
  color: ${props => (props.error ? error : grey07)};
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  margin: 8px 0;
`;

export const KeyWords = styled.span`
  text-align: left;
  ${props => props.theme.typography.metadata};
  color: ${props => (props.error ? error : grey07)};
  text-decoration: underline;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  margin: 8px 0;
`;

import styled from 'styled-components';
import Card from 'components/Card';

export const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 872px;
  height: 660px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 32px;
  gap: 8px;
  button {
    font-size: 1.5rem;
  }
  h3 {
    ${props => props.theme.typography.control};
    color: ${props => props.theme.colors.neutral.grey07};
    margin: 0;
  }
  h2 {
    ${props => props.theme.typography.header2};
    font-weight: 600;
    flex-basis: 100%;
    margin: 0;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  padding: 0 32px;
  gap: 32px;
`;

export const ScrollContainer = styled.div`
  flex-grow: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 40px;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const CapabilityContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  h2 {
    ${props => props.theme.typography.control};
    font-weight: 600;
    margin: 0;
  }
  &.unselected-capabilities {
    h2 {
      color: ${props => props.theme.colors.neutral.grey07};
      font-weight: 500;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  flex-shrink: 0;
  border-top: 1px solid ${props => props.theme.colors.neutral.grey02};
  border-radius: 0 0 8px 8px;
  height: 88px;
  padding: 0 32px;
`;

export const CapabilityCardContainer = styled.div`
  height: 68px;
  box-sizing: border-box;
  display: flex;
  gap: 16px;
  padding: 0 16px;
  align-items: center;
  .icon {
    border-radius: 0;
  }
  span {
    ${props => props.theme.typography.body};
    font-weight: 500;
  }
  button {
    margin-left: auto;
  }
  border: 1px solid ${props => props.theme.colors.neutral.grey05};
  border-radius: 8px;
`;

export const LicenseContainer = styled.div`
  flex-basis: 348px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  h2 {
    ${props => props.theme.typography.control};
    font-weight: 600;
    padding: 0 0 0 32px;
    margin: 0;
  }
`;

export const LicenseDetail = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-left: 1px solid ${props => props.theme.colors.neutral.grey02};
  padding: 0 0 0 32px;
  ${props => props.theme.typography.body};
  font-weight: 600;
  text-transform: capitalize;
`;

export const AhaProductCard = styled.div`
  border-radius: 8px;
  flex-shrink: 0;
  height: 68px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
  background: white;
  gap: 8px;
  border: 1px solid ${props => props.theme.colors.neutral.grey03};
  ${props => props.theme.typography.body};
  font-weight: 600;
  .role-select {
    flex-shrink: 0;
    width: 200px;
  }
`;

import React, { useState, useEffect } from 'react';
import { PageSettingService } from 'services';

const useCustomUserFields = () => {
  const [customUserFields, setCustomUserFields] = useState({
    LyncWorkUser: [],
    EmergencyContact: []
  });
  useEffect(() => {
    const getCustomUserFields = async () => {
      try {
        const { data } = await PageSettingService.personalInfo();
        const { entitiesSettings } = data;
        const customFields = {};
        entitiesSettings.forEach(({ name: groupName, companyFields }) => {
          const fields = companyFields.map(({ order, name, label }) => {
            return { order, label, name };
          });
          customFields[groupName] = fields;
        });
        setCustomUserFields(customFields);
      } catch (error) {
        console.log(error);
      }
    };
    getCustomUserFields();
  }, []);
  return customUserFields;
};

export default useCustomUserFields;

import React from 'react';
import { CustomCheckbox, CustomSelect } from 'components/NewLyncworkUIKit';
import { HeaderRow, Row, Cell, CellData } from '../UserTable';
import { options, getOptionLabel } from './utilities';

export const TableHeader = ({
  csvData,
  handleFieldChange,
  handleSelectAll,
  isAllSelected
}) => {
  const { header, users, selected } = csvData;
  return (
    <HeaderRow>
      <Cell className="checkbox">
        <CustomCheckbox checked={isAllSelected} onChange={handleSelectAll} />
      </Cell>
      {header.map((option, index) => {
        const handleChange = newOption => handleFieldChange(newOption, index);

        return (
          <Cell key={index}>
            <CustomSelect
              className="lw-select"
              placeholder="Select a field"
              options={options}
              value={option}
              onChange={handleChange}
              getOptionLabel={getOptionLabel}
              isClearable
            />
          </Cell>
        );
      })}
    </HeaderRow>
  );
};

export const TableRow = ({ userData, userSelected, toggleSection }) => {
  return (
    <Row selected={userSelected} onClick={toggleSection}>
      <Cell className="checkbox">
        <CustomCheckbox
          label=" "
          checked={userSelected}
          onChange={toggleSection}
        />
      </Cell>
      {userData.map((data, index) => {
        return (
          <Cell key={index}>
            <CellData>{data}</CellData>
          </Cell>
        );
      })}
    </Row>
  );
};

export { TableContainer } from '../UserTable';

import axios from 'axios';
import env from '../env';

const { storeDomain } = env;

const createSearchParamsStrings = params => {
  const searchParams = new URLSearchParams(params);
  return searchParams.toString();
};

export const DropdownService = {
  roles: ({ name = '', page = '0', size = '10', idsIgnored = [] }) => {
    const paramString = createSearchParamsStrings({
      name,
      page,
      size,
      idsIgnored
    });
    return axios.get(
      `${env.storeDomain}/api/roles/dropdown-list?${paramString}`
    );
  },
  teams: ({ name = '', page = '0', size = '10', idsIgnored = [] }) => {
    const paramString = createSearchParamsStrings({
      name,
      page,
      size,
      idsIgnored
    });
    return axios.get(
      `${env.storeDomain}/api/teams/dropdown-list?${paramString}`
    );
  },
  users: ({
    name = '',
    page = '0',
    size = '10',
    role = '',
    idsIgnored = []
  }) => {
    const paramString = createSearchParamsStrings({
      name,
      page,
      size,
      role,
      idsIgnored
    });
    return axios.get(
      `${env.storeDomain}/api/lync-work-users/dropdown-list?${paramString}`
    );
  }
};

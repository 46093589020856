import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { IconButton as CloseButton } from 'components/NewLyncworkUIKit';
import { Container, Header, Message, StyledLink } from './styles';

const defaultIcon = <CheckCircleIcon />;

const LargeToast = ({
  title,
  closeToast,
  message,
  link,
  linkText,
  handleLinkClick,
  dismissToast,
  icon
}) => {
  return (
    <Container className="lw-toast-container-large">
      <Header>
        {icon} {title}
        <CloseButton onClick={dismissToast} />
      </Header>
      <Message className="lw-toast-message-large">{message}</Message>
      {linkText && (
        <StyledLink
          to={link}
          onClick={handleLinkClick}
          className="lw-toast-link-large "
        >
          {linkText}
        </StyledLink>
      )}
    </Container>
  );
};

export default LargeToast;

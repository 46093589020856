import styled from 'styled-components';
import { scrollBar } from 'StyleVariables/StandardComponents';

export const Container = styled.div`
  flex-basis: 45%;
  padding: 0 0 24px 24px;
  border-left: 1px solid ${props => props.theme.colors.brand.primary01};
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  ${props => props.theme.typography.header2};
  margin-top: 0;
  font-weight: 600;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  ${scrollBar}
`;

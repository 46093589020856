import RewardGrid from './RewardGrid';
import {
  Container,
  HeaderSection,
  Header,
  SubHeader,
  GridContainer
} from './styles';

const RewardCentre = () => {
  return (
    <Container>
      <HeaderSection>
        <Header>Reward Centre</Header>
        <SubHeader>Please choose one reward to redeem</SubHeader>
      </HeaderSection>
      <GridContainer>
        <RewardGrid />
      </GridContainer>
    </Container>
  );
};

export default RewardCentre;

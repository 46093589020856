import styled from 'styled-components';
import { IconButton as CloseButton } from 'components/NewLyncworkUIKit/CustomButton';
import {
  borderGrey,
  offGrey,
  paleGrey,
  white,
  orange
} from 'StyleVariables/LyncworkColors';

export const Container = styled.div`
  // width: 320px;
  width: 375px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${borderGrey};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px;
  background-color: ${white};
  &:focus {
    outline: none;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

export const CloseModalButton = styled(CloseButton)`
  float: right;
  .MuiSvgIcon-root {
    font-size: 20px;
  }
`;

export const CloseButtonContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

import React from 'react';
import { CustomButton } from 'components/NewLyncworkUIKit';
import importImage from '../../assets/importImage.svg';

import {
  Container,
  ImportImage,
  Title,
  ImportCount,
  Paragraph,
  TableContainer,
  ButtonContainer
} from './styles';

const ImportComplete = ({ nextPage, userArray }) => {
  const importLength = userArray.length;
  const message = `${importLength} users are now being added to Lyncwork`;
  return (
    <>
      <Container>
        <ImportImage src={importImage} alt=" " />
        <Title>Import Confirmation</Title>
        <ImportCount>{message}</ImportCount>
        <Paragraph>
          Import duration varies based on the number of imported users. We will
          send you an email and a notification on Lyncwork when it’s done!
        </Paragraph>
      </Container>
      <ButtonContainer>
        <CustomButton onClick={nextPage}>Okay</CustomButton>
      </ButtonContainer>
    </>
  );
};

export default ImportComplete;

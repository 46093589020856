import React from 'react';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

import { Container, PageButton, ChevronButton } from './styles';

const fivePages = [1, 2, 3, 4, 5];

const getAdjacentPages = (currentPage, totalPages) => {
  if (totalPages <= 5) {
    return fivePages.filter(page => page <= totalPages);
  }

  switch (currentPage) {
    case 1:
    case 2:
      return [1, 2, 3, '...', totalPages];
    case 3:
      return [1, 2, 3, 4, '...', totalPages];
    case totalPages:
    case totalPages - 1:
      return [1, '...', totalPages - 2, totalPages - 1, totalPages];
    case totalPages - 2:
      return [
        1,
        '...',
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages
      ];
    default:
      return [
        1,
        '...',
        currentPage - 1,
        currentPage,
        currentPage + 1,
        '...-end',
        totalPages
      ];
  }
};

const PageNumber = ({ page, isSelected, onClick }) => {
  switch (page) {
    case '...':
    case '...-end':
      return '...';
    default:
      return (
        <PageButton type="button" $isSelected={isSelected} onClick={onClick}>
          {page}
        </PageButton>
      );
  }
};

// note: pages here refers to pages to display, NOT indices
// be careful of off by one errors
// e.g. your fetch data function might accept indexes instead of pages
//  fetchData(3) returns result for page 4
// in this case, changePage function should be like this this
// page => fetchData(page - 1)

const Pagination = ({ totalPages, currentPage, changePage }) => {
  if (!totalPages) {
    return null;
  }
  const adjacentPages = getAdjacentPages(currentPage, totalPages);
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;
  const toPrevPage = () => changePage(Math.max(1, currentPage - 1));
  const toNextPage = () => changePage(Math.min(totalPages, currentPage + 1));

  return (
    <Container>
      <ChevronButton disabled={isFirstPage} onClick={toPrevPage}>
        <ChevronLeftRoundedIcon />
      </ChevronButton>
      {adjacentPages.map(page => {
        const isSelected = page === currentPage;
        const onClick = () => changePage(page);
        return (
          <PageNumber
            page={page}
            key={page}
            isSelected={isSelected}
            onClick={onClick}
          />
        );
      })}
      <ChevronButton disabled={isLastPage} onClick={toNextPage}>
        <ChevronRightRoundedIcon />
      </ChevronButton>
    </Container>
  );
};

export default Pagination;

import styled, { css } from 'styled-components';
import { multilineEllipsis } from 'StyleVariables/StandardComponents';
import { CustomButton } from 'components/NewLyncworkUIKit';

const getBackgroundImage = strokeColor => {
  const sanitizeStrokeColor = strokeColor.replace('#', '%23');
  return `data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='${sanitizeStrokeColor}' stroke-width='2' stroke-dasharray='12' stroke-dashoffset='12' stroke-linecap='round'/%3e%3c/svg%3e`;
};
const backgroundImg = css`
  background-image: url("${props =>
    getBackgroundImage(props.theme.colors.brand.primary01)}");
`;

export const Container = styled.div`
  position: relative;
  padding: 48px;
  background-color: ${props => props.theme.colors.neutral.white};
  height: 500px;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

export const ImportImage = styled.img`
  position: absolute;
  right: 48px;
  bottom: 0;
`;

export const Title = styled.h2`
  color: ${props => props.theme.colors.neutral.grey10};
  ${props => props.theme.typography.header2};
  font-weight: 600;
  margin: 0;
`;

export const Paragraph = styled.p`
  max-width: 480px;
  margin: 16px 0;
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey07};
  a {
    text-decoration: none;
    color: ${props => props.theme.colors.feedback.confirmation};
  }

  strong {
    color: ${props => props.theme.colors.feedback.error};
  }
`;

export const ButtonContainer = styled.div`
  margin: 32px 0 0 0;
  display: flex;
  justify-content: flex-end;
  button {
    margin: 0 0 0 16px;
  }
`;

export const Button = styled(CustomButton)`
  margin: 0 0 32px 0;
`;

export const ZoneContainer = styled.div`
  height: 150px;
  width: 300px;
  margin: 16px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  ${backgroundImg}
`;

export const DropZoneContainer = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey06};
  ${({ noSetHeight }) => noSetHeight && '  padding: 1em 0;'}

  img {
    width: 72px;
    height: auto;
    margin-bottom: 12px;
  }

  &:hover {
    background-color: ${props => props.theme.colors.brand.primary01}20;
  }
`;

export const FileCardContainer = styled.div`
  margin: 16px 0 0;

  width: 400px;
  height: 72px;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.colors.neutral.grey05};
  border-radius: 4px;
  padding: 12px 24px;
  display: flex;
  img {
    flex-shrink: 0;
    margin-right: 16px;
    width: 28px;
    height: 36px;
    object-fit: contain;
  }
`;

export const FileInfoContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  width: 280px;
  h1 {
    width: 100%;
    margin: 0;
    ${props => props.theme.typography.header3};
    color: ${props => props.theme.colors.neutral.grey10};
    font-weight: 500;
    ${multilineEllipsis}
  }

  span {
    ${multilineEllipsis}
    margin: 0;
    ${props => props.theme.typography.body};
    color: ${props => props.theme.colors.neutral.grey06};
  }
`;

FileInfoContainer.defaultProps = { lineClamp: 1 };

export const CloseButtonContainer = styled.button`
  appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.neutral.grey05};
  align-self: center;
  background-color: transparent;
  margin-left: auto;
  width: 16px;
  height: 16px;
  &:focus-visible {
    outline: 2px solid #0078d4;
  }
`;

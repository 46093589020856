import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress';

import { CMSService, RoleService, TeamService } from 'services';
import BackButton from 'components/BackButton';
import LoadingScreen from 'components/LoadingScreen';
import {
  CustomButton,
  CustomTextInput,
  CustomRTE
} from 'components/NewLyncworkUIKit';

import {
  Container,
  ButtonContainer,
  EditContainer,
  BreadCrumb,
  Footer,
  MarginRight,
  ButtonWrapper,
  Spacer,
  BlockHeightFixer,
  ErrorP
} from './styles';

import Thumbnail from './Thumbnail';
import MetaData from './MetaData';

const getTitle = content => {
  //this includes style attributes
  const firstIncompleteTag = content.split('>')[0];

  //e.g <h2 width="50px">
  const openingTag = `${firstIncompleteTag}>`;

  //e.g <h2
  const firstTagName = firstIncompleteTag.split(' ')[0];

  //<h2>
  const firstTag = `${firstTagName}>`;
  const firstClosingTag = firstTag.replace('<', '</');
  const titleWithOpeningTag = content.split(firstClosingTag)[0] || '';
  const title = titleWithOpeningTag
    .replace(openingTag, '')
    .replaceAll('<br>', '');
  return title;
};

const blankPostObject = {
  title: '',
  category: null, //id of category
  comments_allowed: false,
  content: '', //rte output
  featured: false,
  medias: [], //id of thumbnail
  reactions_allowed: false,
  team_permissions: [],
  role_permissions: [] //id's of teams who can see the post if none included, everyone can see
  // title: 'Title' //first h1 of the content
};

//inserts additional html elements into the content.
const AddEditPost = () => {
  const [metaData, setMetaData] = useState(null);
  const [publishing, setPublishing] = useState(false);
  const [saving, setSaving] = useState(false);

  const [deleting, setDeleting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [drafting, setDrafting] = useState(false);

  const [initialImages, setInitialImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [thumbnail, setThumbnail] = useState(null);

  let history = useHistory();
  let { id } = useParams();

  useEffect(() => {
    (async () => {
      if (id) {
        //operations to fire on creating new post
        let permittedRoles = [];
        let permittedTeams = [];
        const { data } = await CMSService.getPostById(id);
        const { role_permissions, team_permissions } = data;
        const hasRoles = role_permissions.length > 0;
        const hasTeams = team_permissions.length > 0;
        if (hasRoles) {
          try {
            const roleIds = data.role_permissions.map(x => x.role);
            const roleRequest = await RoleService.listByIds(roleIds);
            permittedRoles = roleRequest.data.map(x => ({
              ...x,
              name: x.roleName
            }));
          } catch (error) {
            console.log(error);
          }
        }
        if (hasTeams) {
          try {
            const teamIds = data.team_permissions.map(x => x.team);
            const teamRequest = await TeamService.listByIds(teamIds);
            permittedTeams = teamRequest.data.map(x => ({
              ...x,
              name: x.teamName
            }));
          } catch (error) {
            console.log(error);
          }
        }
        const content = data.content;
        setInitialImages(data.medias);
        let newContent = content;
        let imgIdArray = [];
        //create array of ids, push id after replace into array, if id is not included, must be thumb
        //if
        data.medias.forEach(media => {
          const isIncluded = content.includes(`lw-img-id:${media.id}`);
          //replace all uploaded image src's with the correct url
          newContent = newContent.replace(`lw-img-id:${media.id}`, media.url);
          isIncluded && imgIdArray.push(media.id);
        });
        setThumbnail(data.medias.find(img => !imgIdArray.includes(img.id)));
        setMetaData({
          ...data,
          content: newContent,
          team_permissions: permittedTeams,
          role_permissions: permittedRoles
        });
      } else {
        setMetaData(blankPostObject);
      }
    })();
  }, []);
  const submitFunction = async saveAsDraft => {
    saveAsDraft ? setDrafting(true) : setSubmitted(true);

    const { content, category, role_permissions, team_permissions } = metaData;
    const title = getTitle(metaData.content);
    const draftErrors = !title?.length;
    const publishErrors =
      !title?.length || title === '&nbsp;' || !content?.length || !category;
    const errors = saveAsDraft ? draftErrors : publishErrors;

    if (!errors) {
      saveAsDraft ? setSaving(true) : setPublishing(true);

      let newMetaData = metaData;
      newMetaData = {
        ...newMetaData,
        team_permissions: newMetaData.team_permissions.map(x => x.id),
        role_permissions: newMetaData.role_permissions.map(x => x.id)
      };

      const allImages = [...initialImages, ...newImages]; //all Images
      for (const img of allImages) {
        const url = img.url;
        if (content.includes(url) || img.url === thumbnail?.url) {
          //if the image is not the thumbnail, is the image still in the doc?
          if (!!img.file) {
            //is it a new image?
            const { data } = await CMSService.uploadMedia(img.file);
            newMetaData = {
              ...newMetaData,
              medias: [...newMetaData.medias, data[0].id],
              content: newMetaData.content.replace(
                url,
                `lw-img-id:${data[0].id}`
              )
            };
          } else if (img.id) {
            //if it is a previously updated image
            newMetaData = {
              ...newMetaData,
              medias: [...newMetaData.medias, img.id],
              content: newMetaData.content.replace(url, `lw-img-id:${img.id}`)
            };
          } else {
            console.log('exception occurred, look into it! -from lucas', img);
          }
        } else {
          //the image is no longer in the doc
          if (img.id) {
            newMetaData = {
              ...newMetaData,
              medias: newMetaData.medias.filter(x => x.id !== img.id)
            };
            await CMSService.deleteMedia(img.id);
          } else {
            console.log(
              'This image was added, then deleted before uploading',
              img
            );
          }
        }
      }

      //format medias
      let contentMedias = newMetaData.medias.map(media => {
        if (media.id && media.id !== null) {
          return media.id;
        } else {
          return media;
        }
      });
      //non id's are sneaking into the medias array. lets remove those
      contentMedias = contentMedias.filter(item => typeof item === 'number');
      //found duplicates in test, using filter to remove them
      contentMedias = contentMedias.filter(
        (item, index) => contentMedias.indexOf(item) === index
      );

      const postData = {
        ...newMetaData,
        title,
        category: metaData.category?.id,
        medias: contentMedias,
        role_permissions: newMetaData.role_permissions?.filter(x => x),
        team_permissions: newMetaData.team_permissions?.filter(x => x),
        draft: saveAsDraft
      };

      if (!id) {
        try {
          saveAsDraft ? setSaving(true) : setPublishing(true);
          await CMSService.createPost(postData);
          history.push('/admin-community');
        } catch (err) {
          saveAsDraft ? setSaving(true) : setPublishing(true);
        }
      } else {
        try {
          saveAsDraft ? setSaving(true) : setPublishing(true);
          const { data } = await CMSService.editPost(id, postData);
          !!data && history.push('/admin-community');
        } catch (err) {
          saveAsDraft ? setSaving(true) : setPublishing(true);
        }
      }
    } else {
      setMetaData({ ...metaData, title });
    }
  };

  const handleBlockChange = x => {
    setMetaData({ ...metaData, content: x });
  };
  const deleteFunction = async () => {
    if (id) {
      try {
        setDeleting(true);
        const mediasToDelete = metaData.medias;
        for (const media of mediasToDelete) {
          await CMSService.deleteMedia(media.id);
        }
        await CMSService.deletePost(id);

        setDeleting(false);
        history.push('/admin-community');
      } catch (error) {
        setDeleting(false);
      }
    } else {
      history.push('/admin-community');
    }
  };
  const addImage = newImage => {
    setNewImages(oldNewImages => {
      return [...oldNewImages, newImage];
    });
  };
  const addThumbnail = x => {
    addImage(x); //add image to upload que
    setThumbnail(x); //set it as the thumbnail
  };

  const removeThumbnail = async id => {
    if (id) {
      //if the ID exists, it was given by the "createMedia" endpoint and must be deleted.
      const filteredImages = newImages.filter(x => x.id !== id);
      //it must be removed from the metadata
      setThumbnail();
      setNewImages(filteredImages);
    } else {
      //if it hasn't been uploaded yet, it must be removed from the upload que.
      //We operate under the assumption that the only media not included within the content,
      //must be the thumbnail
      const filteredImages = newImages.filter(img =>
        metaData.content.includes(img.url)
      );
      setThumbnail();
      setNewImages(filteredImages);
    }
  };

  if (!metaData) {
    return (
      <Container>
        <BackButton />
        <LoadingScreen />
      </Container>
    );
  }

  return (
    <Container>
      <BackButton />
      <BreadCrumb>{id ? 'Edit Your Article' : 'Create an Article'}</BreadCrumb>
      <EditContainer className="lw-editor-container">
        {(submitted || drafting) &&
          (metaData?.title === '&nbsp;' || !metaData.title?.length) && (
            <ErrorP>
              Cannot publish an article without a title, please add a title.
            </ErrorP>
          )}
        {submitted && !metaData.content.length && (
          <ErrorP>
            Cannot publish empty article, please add some content.
          </ErrorP>
        )}
        <BlockHeightFixer className="lw-block-height-fixer">
          <CustomRTE
            data={metaData.content}
            callback={x => handleBlockChange(x)}
            addImage={addImage}
          />
        </BlockHeightFixer>
        <Spacer />
        <MetaData
          submitted={submitted}
          data={metaData}
          setMetaData={setMetaData}
        />
        <Thumbnail
          thumbnail={thumbnail}
          addThumbnail={addThumbnail}
          removeThumbnail={removeThumbnail}
        />
        <Footer>
          <ButtonWrapper>
            <CustomButton
              variant="secondary"
              size="small"
              onClick={deleteFunction}
            >
              {deleting ? (
                <CircularProgress color="primary" size="20px" />
              ) : id ? (
                'Delete'
              ) : (
                'Discard Draft'
              )}
            </CustomButton>
          </ButtonWrapper>

          <ButtonContainer>
            <MarginRight>
              <ButtonWrapper>
                <CustomButton
                  variant="secondary"
                  size="small"
                  onClick={() => submitFunction(true)}
                >
                  {saving ? (
                    <CircularProgress color="primary" size="20px" />
                  ) : (
                    'Save as Draft'
                  )}
                </CustomButton>
              </ButtonWrapper>
            </MarginRight>
            <ButtonWrapper>
              <CustomButton size="small" onClick={() => submitFunction(false)}>
                {publishing ? (
                  <CircularProgress color="secondary" size="20px" />
                ) : (
                  'Publish'
                )}
              </CustomButton>
            </ButtonWrapper>
          </ButtonContainer>
        </Footer>
      </EditContainer>

      <br />
    </Container>
  );
};

export default AddEditPost;

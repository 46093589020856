import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px 0;
`;

export const Title = styled.h1`
  ${props => props.theme.typography.header1};
  font-weight: 600;
  margin: 20px 0 8px 0;
`;

export const Tagline = styled.h1`
  ${props => props.theme.typography.body};
`;

export const ImageContainer = styled.div`
  width: 800px;
  max-width: 80%;
  margin: 44px 0;
  box-shadow: 0px 2.875px 9.58333px rgba(186, 186, 186, 0.3);
  border-radius: 10px;
  line-height: 0;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`;

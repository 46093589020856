import React from 'react';
import { useButtonTrack } from 'matomo/hooks';

import { Cell, Row } from 'components/ListTable';
import { CustomButton } from 'components/NewLyncworkUIKit';

import { teamsListPageEvents } from '../../teamsInstrumentationEvents';

import { StyledLink } from './styles';
import TeamUsers from './TeamUsers';

const TeamCard = ({ team, allUsers }) => {
  const trackButton = useButtonTrack();

  const { teamName, users, totalWorkflows, sysCreationDate, id } = team;
  const editLink = `/admin-teams/edit/${id}`;
  const handleEditClick = () => trackButton(teamsListPageEvents.edit_team);

  return (
    <Row>
      <Cell flex={2} className="list-row-name">
        {teamName}
      </Cell>
      <Cell flex={2}>
        <TeamUsers users={users} />
      </Cell>
      <Cell flex={2}>{totalWorkflows}</Cell>
      <Cell flex={2}>{sysCreationDate?.split('T')[0]}</Cell>
      <Cell>
        <StyledLink to={editLink}>
          <CustomButton
            variant="secondary"
            size="small"
            tabIndex="-1"
            onClick={handleEditClick}
          >
            Edit
          </CustomButton>
        </StyledLink>
      </Cell>
    </Row>
  );
};

export default TeamCard;

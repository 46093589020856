import React from 'react';
import { Redirect } from 'react-router-dom';

import useLocalStorage from 'hooks/useLocalStorage';

import WelcomeScreen from './WelcomeScreen';

const onboardingVisitedKey = 'LW-admin-onboarding-visited';

const RootPage = () => {
  const [visited, setVisited] = useLocalStorage(onboardingVisitedKey, false);
  if (visited) {
    return <Redirect to="/admin-onboarding/welcome" />;
  }
  return <WelcomeScreen setVisited={setVisited} />;
};

export default RootPage;

import React from 'react';

import { CookiesProvider } from 'react-cookie';

import AppContext from './AppContext';

const AppWrapper = ({ children }) => {
  return (
    <AppContext>
      <CookiesProvider>{children}</CookiesProvider>
    </AppContext>
  );
};

export default AppWrapper;

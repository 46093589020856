import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import colors from 'designSystem/foundations/colors';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-column-gap: 120px;
  width: 100%;
  height: 100%;
  max-width: 610px;
  margin: 0 auto;
`;
export const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-row-start: span
    ${({ group }) => (group.name === 'LyncWorkUser' ? '2' : '1')};
`;
export const GroupTitle = styled.h2`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  /* Off Black */

  color: #1d1d20;
`;
export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 104px;
`;
export const ButtonSpacer = styled.div`
  width: 16px;
`;
export const RequiredStar = styled.p`
  display: ${({ required }) => (required ? 'flex' : 'none')};
  color: ${colors.feedback.error};
  margin: 0;
`;
export const FakeInput = styled.div`
  position: relative;
  display: flex;
  height: 40px;
  box-sizing: border-box;
  align-items: center;
  padding: 1em;
  background: ${({ field }) =>
    field.isDefault ? colors.neutral.offWhite01 : colors.neutral.white};
  /* Pink */

  border: 1px solid ${colors.neutral.grey05};
  box-sizing: border-box;
  border-radius: 4px;
  ${props => props.theme.typography.control};

  /* identical to box height, or 137% */

  /* Grey 06 */

  color: ${colors.neutral.grey06};
`;
export const Options = styled.div`
  position: absolute;
  right: 1em;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
`;
export const OptionsLightbox = styled.div`
  position: absolute;
  right: -162px;
  z-index: 10;
  top: 0px;
  display: ${({ open }) => (open ? 'flex' : 'none')};
  flex-direction: column;
  background: ${colors.neutral.white};

  border: 1px solid ${colors.neutral.grey05};
  box-sizing: border-box;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  overflow: hidden;
`;
export const LightboxRow = styled.p`
  display: flex;
  width: 100%;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  background: ${colors.neutral.white};
  /* identical to box height, or 143% */
  box-sizing: border-box;
  margin: 0;
  /* Neutral/Grey 08 */
  padding: 1em;
  color: ${colors.neutral.grey08};
  transition: background 0.3s;
  &:hover {
    background: ${colors.neutral.grey01};
  }
  cursor: pointer;
`;
export const Icon = styled.img`
  margin-right: 1em;
  width: 16px;
`;

export const Spacer = styled.div`
  flex-shrink: 0;
  height: 72px;
`;

import React from 'react';
import { Edit, Delete } from '@material-ui/icons';

import pdfIcon from 'assets/images/adminOnboarding/documents/pdfIcon.svg';
import docIcon from 'assets/images/adminOnboarding/documents/docIcon.svg';
import {
  Container,
  TextContainer,
  IconButton,
  FileName,
  FileStatus
} from './styles';

const getStatusText = file => {
  const { fileCategory, description, signingTitle } = file;
  if (fileCategory !== 'DRAFT') {
    return 'Details Complete';
  }

  if (description && signingTitle) {
    return 'Draft';
  }

  return 'Details Required';
};

const DocumentCard = ({ file, handleEditClick, handleDeleteClick }) => {
  const { fileName, fileCategory } = file;
  const statusText = getStatusText(file);
  const complete = fileCategory !== 'DRAFT';
  const isPdf = fileName.includes('.pdf');
  return (
    <Container>
      <img src={isPdf ? pdfIcon : docIcon} alt="icon" />
      <TextContainer>
        <FileName>{fileName}</FileName>
        <FileStatus complete={complete}>{statusText}</FileStatus>
      </TextContainer>
      <IconButton onClick={handleDeleteClick}>
        <Delete />
      </IconButton>
      <IconButton onClick={handleEditClick}>
        <Edit />
      </IconButton>
    </Container>
  );
};

export default DocumentCard;

import styled from 'styled-components';

const singleWidth = 280;

export const Container = styled.div`
  display: flex;
  color: ${props => props.theme.colors.neutral.grey10};
  height: 75px;
  position: sticky;
  top: 0;
  z-index: 30;
`;

export const Cell = styled.div`
  padding: 8px 0;
  box-sizing: border-box;
  position: relative;
  height: 100%;
  width: ${singleWidth}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.theme.colors.neutral.offWhite01};
  border-right: 1px solid ${props => props.theme.colors.neutral.grey05};
  border-bottom: 1px solid ${props => props.theme.colors.neutral.offWhite03};
  &:last-child {
    border-right: 1px solid transparent;
  }
`;

export const Label = styled.span`
  ${props => props.theme.typography.metadata};
  margin: 0 0 4px 0;
  font-weight: 600;
`;

export const Days = styled.div`
  display: flex;
`;

export const DayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40px;
  span {
    ${props => props.theme.typography.footnote};
    color: ${props => props.theme.colors.neutral.grey05};
  }
  ${props => props.theme.typography.control};
`;

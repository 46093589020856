import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  width: 240px;
  height: 260px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  margin: 0 8px 20px;
  border: 1px solid ${props => props.theme.colors.brand.primary01};
  color: ${props => props.theme.colors.neutral.grey07};
`;

export const IconContainer = styled.div`
  width: 52px;
  height: 52px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff2f2;
  color: ${props => props.theme.colors.brand.primary};
`;

export const Title = styled.h1`
  ${props => props.theme.typography.body};
  font-weight: 600;
`;
export const Description = styled.p`
  text-align: center;
  ${props => props.theme.typography.control};
`;

export const Link = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.brand.primary};
  text-decoration: none;
`;

export const ArrowContainer = styled.div`
  width: 12px;
  height: 12px;
  margin-left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

import styled, { css } from 'styled-components';
import { multilineEllipsisFunc } from 'StyleVariables/StandardComponents';

export const Container = styled.div`
  display: flex;
  position: sticky;
  flex-shrink: 0;
  top: 0;
  width: 100%;
  height: 80px;
  background-color: ${props => props.theme.colors.neutral.offWhite01};
  z-index: 1;
`;

export const ProgressNode = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const activeColor = css`
  ${props => props.theme.colors.brand.primary};
`;
const inactiveColor = css`
  ${props => props.theme.colors.neutral.grey06};
`;
export const ProgressTitle = styled.p`
  transform: scale(0.9);
  ${props => props.theme.typography.footnote};
  text-transform: uppercase;
  color: ${props => (props.active ? activeColor : inactiveColor)};
  margin: 0;
  ${multilineEllipsisFunc(1)}
`;

export const ProgressIndicator = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 12px;
`;

const lineColors = {
  none: 'transparent',
  active: activeColor,
  inactive: inactiveColor
};

const progressLine = styled.div`
  position: absolute;
  width: 50%;
  height: 1px;
  background-color: ${props => lineColors[props.status || 'active']};
`;

export const ProgressLineLeft = styled(progressLine)`
  left: 0;
`;
export const ProgressLineRight = styled(progressLine)`
  right: 0;
`;

const backgroundColor = {
  current: css`
    ${props => props.theme.colors.neutral.white}
  `,
  complete: activeColor,
  incomplete: inactiveColor
};
const borderColor = {
  current: activeColor,
  complete: activeColor,
  incomplete: inactiveColor
};

export const ProgressCircle = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 1px solid ${props => borderColor[props.status || 'incomplete']};
  background-color: ${props => backgroundColor[props.status || 'incomplete']};
`;

import React from 'react';
import {
  SearchHeaderContainer,
  SearchHeaderTitle
} from './styles';

const SearchHeader = ({ term }) => {
  return (
    <SearchHeaderContainer>
      <SearchHeaderTitle>
        Search result
        {term && <>for: <strong>{term}</strong></>}
      </SearchHeaderTitle>
    </SearchHeaderContainer>
  );
};

export default SearchHeader;

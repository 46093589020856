import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import pdfIcon from 'assets/images/adminOnboarding/documents/pdfIcon.svg';

import {
  FileCardContainer,
  FileInfoContainer,
  CloseButtonContainer
} from './styles';

const FileCard = ({ file, handleFileRemove }) => {
  const { name: fileName, size } = file;
  return (
    <FileCardContainer>
      <img src={pdfIcon} alt="icon" />
      <FileInfoContainer>
        <h1>{fileName}</h1>
        <span>{size} KB</span>
      </FileInfoContainer>
      <CloseButtonContainer onClick={handleFileRemove}>
        <CloseIcon />
      </CloseButtonContainer>
    </FileCardContainer>
  );
};

export default FileCard;

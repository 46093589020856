import React, { useEffect } from 'react';
import { ApplicationService } from 'services/application.service';

import useEmailSetup from './useEmailSetup';

import EmailAppSelection from './EmailAppSelection';
import ApplicationSetup from './ApplicationSetup';
import SetupComplete from './SetupComplete';

const AdminSetupEmailIntegration = ({
  prevPage,
  nextPage,
  pageData,
  onPageDataChange
}) => {
  const {
    integratedApp,
    loading,
    apps,
    appSelected,
    selectApp,
    cancelSelection,
    integrateApplication
  } = useEmailSetup({ pageData, onPageDataChange });
  if (loading) {
    return null;
  }
  if (integratedApp) {
    return (
      <SetupComplete
        integratedApp={integratedApp}
        prevPage={prevPage}
        nextPage={nextPage}
      />
    );
  }

  if (appSelected) {
    return (
      <ApplicationSetup
        appSelected={appSelected}
        cancelSelection={cancelSelection}
        integrateApplication={integrateApplication}
      />
    );
  }

  return (
    <EmailAppSelection prevPage={prevPage} apps={apps} selectApp={selectApp} />
  );
};

export default AdminSetupEmailIntegration;

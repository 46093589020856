import styled from 'styled-components';

export * from '../styles';

export const ContentStyles = styled.div`
  max-width: 668px;
  h2 {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
  }
`;

import React from 'react';
import {
  DetailContainer,
  KeyContactDetail,
  KeyContactAvatar,
  KeyContactName
} from './styles';

export const KeyContact = ({ label, halfWidth = true, contact }) => {
  if (!contact) {
    return null;
  }
  const { firstName, lastName, id, avatar } = contact;
  const fullName = `${firstName} ${lastName}`;
  const src = avatar?.url;

  return (
    <DetailContainer halfWidth={halfWidth}>
      <span>
        <strong>{label}</strong>
      </span>
      <KeyContactDetail>
        <KeyContactAvatar colorKey={id} name={fullName} src={src} key={id} />
        <KeyContactName>{fullName}</KeyContactName>
      </KeyContactDetail>
    </DetailContainer>
  );
};

const UserInfoDetail = ({ label, text, halfWidth = false }) => {
  return (
    <DetailContainer halfWidth={halfWidth}>
      <span>
        <strong>{label}</strong>
      </span>
      <span>{text}</span>
    </DetailContainer>
  );
};

export default UserInfoDetail;

import React from 'react';

import { useJourneys } from './hooks';
import { InfiniteScrollList, BlankScreen, JourneyCard } from './components';

const RoleJourneys = ({ roleId }) => {
  const {
    searchQuery,
    name,
    loading,
    journeys,
    hasMore,
    loadMore,
    handleInputChange
  } = useJourneys(roleId);
  const hasJourneys = journeys.length > 0;
  const noResultFound = !hasJourneys && !!name;
  const hasNoJourneys = !hasJourneys && !name;
  return (
    <InfiniteScrollList
      hasMore={hasMore}
      loadMore={loadMore}
      placeholder="Search Journeys"
      searchQuery={searchQuery}
      handleInputChange={handleInputChange}
    >
      {loading ? (
        <div />
      ) : noResultFound ? (
        <BlankScreen message="Sorry, we couldn't find anything" />
      ) : hasNoJourneys ? (
        <BlankScreen message="There are no journeys linked to this role, please assign them in the ‘Journeys’ page." />
      ) : (
        journeys.map(journey => {
          return <JourneyCard key={journey.id} journey={journey} />;
        })
      )}
    </InfiniteScrollList>
  );
};

export default RoleJourneys;

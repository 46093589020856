import styled from 'styled-components';
const mobileBreakpoint = '900px';
export const SidebarContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;

  box-sizing: border-box;

  @media only screen and (max-width: ${mobileBreakpoint}) {
    z-index: 1;
  }
`;
export const SidebarContentTitle = styled.p`
  ${props => props.theme.typography.title2};
  color: ${props => props.theme.colors.neutral.grey10};
  font-weight: 600;
  margin-bottom: 0px;
  margin-right: ${props => props.mobile && '80px'};
`;

export const SidebarContentDescription = styled.div`
  color: ${props => props.theme.colors.neutral.grey10};
  margin: 16px 0 0;
  ${props => props.theme.typography.header3};
  line-height: 1.75rem;
  display: flex;
  flex-direction: column;

  z-index: 2;
`;
export const SideBarImg = styled.img`
  margin-top: ${props => (props.mobile ? '32px' : 'auto')};
  height: auto;
  box-sizing: border-box;
  max-height: 350px;
  max-width: 80%;
  align-self: center;
`;

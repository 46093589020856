import React from 'react';
import ReactDom from 'react-dom';

import { useMobileTutorial } from 'context/MobileTutorialContext';
import TutorialStart from './TutorialStart';
import TutorialExit from './TutorialExit';
import TutorialFinish from './TutorialFinish';
import Tutorial from './Tutorial';

const TutorialStep = () => {
  const { tutorialStarted, tutorialFinished, showExitConfirmation } =
    useMobileTutorial();
  if (showExitConfirmation) {
    return <TutorialExit />;
  }
  if (!tutorialStarted) {
    return <TutorialStart />;
  }

  if (tutorialFinished) {
    return <TutorialFinish />;
  }
  return <Tutorial />;
};

const MobileTutorial = () => {
  const { showTutorial } = useMobileTutorial();
  if (!showTutorial) {
    return null;
  }
  return ReactDom.createPortal(
    <TutorialStep />,
    document.getElementById('portal')
  );
};

export default MobileTutorial;

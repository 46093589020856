import React from 'react';

import UserInfoDetail from './UserInfoDetail';
import { Container, SectionContainer } from './styles';

const formatDOB = dob => {
  if (!dob) {
    return '';
  }
  const [year, month, day] = dob.split('-');
  if (!year || !month || !day) {
    return '';
  }

  return `${month}/${day}/${year}`;
};

const AddressInfo = ({ address }) => {
  const { lineOne, lineTwo, city, state, country, postalCode } = address;
  return (
    <SectionContainer>
      <h2>Address</h2>
      <UserInfoDetail label="Line One" text={lineOne} />
      {lineTwo && <UserInfoDetail label="Line Two" text={lineTwo} />}
      <UserInfoDetail label="City" text={city} halfWidth />
      <UserInfoDetail label="State" text={state} halfWidth />
      <UserInfoDetail label="Country" text={country} halfWidth />
      <UserInfoDetail label="Postal Code" text={postalCode} halfWidth />
    </SectionContainer>
  );
};

const EmergencyContactInfo = ({ emergencyContact, customFields }) => {
  const { firstName, lastName, phoneNumber } = emergencyContact;
  const fullName = `${firstName} ${lastName}`;
  console.log({ customFields, emergencyContact });
  return (
    <SectionContainer>
      <h2>Emergency Contact</h2>
      <UserInfoDetail label="Name" text={fullName} />
      <UserInfoDetail label="Phone Number" text={phoneNumber} />
      {customFields.map(({ name, label }) => {
        const text = emergencyContact[name] || 'Not Provided';
        return <UserInfoDetail label={label} text={text} key={name} />;
      })}
    </SectionContainer>
  );
};

const PersonalInfoTab = ({ user, customFields }) => {
  const {
    emailSecondary,
    phonePrimary,
    phoneSecondary,
    dob,
    addresses,
    emergencyContact
  } = user;
  const formattedDOB = formatDOB(dob);
  const firstAddress = addresses?.[0];

  return (
    <Container>
      <SectionContainer>
        <h2>Basic Info</h2>
        <UserInfoDetail label="Personal Email" text={emailSecondary} />
        <UserInfoDetail label="Primary Phone" text={phonePrimary} halfWidth />
        <UserInfoDetail
          label="Secondary Phone"
          text={phoneSecondary || 'Not Provided'}
          halfWidth
        />
        <UserInfoDetail label="Date of Birth" text={formattedDOB} halfWidth />
        {customFields.LyncWorkUser.map(({ name, label }) => {
          const text = user[name] || 'Not Provided';
          return (
            <UserInfoDetail label={label} text={text} key={name} halfWidth />
          );
        })}
      </SectionContainer>
      {firstAddress && <AddressInfo address={firstAddress} />}
      {emergencyContact && (
        <EmergencyContactInfo
          emergencyContact={emergencyContact}
          customFields={customFields.EmergencyContact}
        />
      )}
    </Container>
  );
};

export default PersonalInfoTab;

import React, { useRef, useEffect } from 'react';
import NotificationsIcon from '@material-ui/icons/Notifications';

import { useNotification } from './NotificationContext';

import { ToggleButton, UnseenCounter } from './styles';

const NotificationIcon = () => {
  const {
    unseenCount,
    closeNotificationPanel,
    toggleNotificationPanel,
    toggleRef
  } = useNotification();

  return (
    <ToggleButton
      onClick={toggleNotificationPanel}
      ref={toggleRef}
      data-tutorial-id="notification-button"
    >
      <NotificationsIcon color="inherit" />
      {unseenCount > 0 && <UnseenCounter>{unseenCount}</UnseenCounter>}
    </ToggleButton>
  );
};

export default NotificationIcon;

import React, { useState } from 'react';

import useAppLicenceInfo from 'hooks/useAppLicenceInfo';

import useImportSteps from './useImportSteps';
import { PageContainer, Header, Container } from './styles';
import {
  Progress,
  SelectApplication,
  SelectUsers,
  Review,
  ImportComplete
} from './components';

const ImportAppContent = ({
  licenseInfo,
  applications,
  nextPage,
  previousPage,
  selectedApp,
  completedStepCount,
  setSelectedApp,
  setData,
  data
}) => {
  const hasLicenseInfo = Object.keys(licenseInfo).length > 0;
  const { users, selected } = data;
  const selectedUsers = users.filter(({ username }) => selected[username]);
  switch (completedStepCount) {
    case 0:
      return (
        <SelectApplication
          applications={applications}
          nextPage={nextPage}
          previousPage={previousPage}
          selectedApp={selectedApp}
          setSelectedApp={setSelectedApp}
          setData={setData}
        />
      );
    case 1:
      return (
        <SelectUsers
          appAPIName={selectedApp.appAPIName}
          licenseInfo={licenseInfo}
          nextPage={nextPage}
          previousPage={previousPage}
          data={data}
          setData={setData}
        />
      );
    case 2:
      return (
        <Review
          hasLicenseInfo={hasLicenseInfo}
          nextPage={nextPage}
          previousPage={previousPage}
          userArray={selectedUsers}
        />
      );
    case 3:
      return <ImportComplete nextPage={nextPage} userArray={selectedUsers} />;
    default:
      return null;
  }
};
const steps = ['Select Application', 'Select Users', 'Review', 'Import'];

const UsersImportAppPage = ({ applications }) => {
  const { previousPage, nextPage, completedStepCount } = useImportSteps(steps);
  const [selectedApp, setSelectedApp] = useState({
    appAPIName: null,
    licenseManagements: []
  });
  const { appAPIName, licenseManagements } = selectedApp;
  const [data, setData] = useState({
    users: [],
    selected: {},
    nextPageParams: null
  });
  const licenseInfo = useAppLicenceInfo(appAPIName, licenseManagements);
  return (
    <PageContainer>
      <Header>Import Users</Header>
      <Progress completedStepCount={completedStepCount} steps={steps} />
      <ImportAppContent
        licenseInfo={licenseInfo}
        applications={applications}
        completedStepCount={completedStepCount}
        selectedApp={selectedApp}
        setSelectedApp={setSelectedApp}
        setData={setData}
        data={data}
        nextPage={nextPage}
        previousPage={previousPage}
      />
    </PageContainer>
  );
};

export default UsersImportAppPage;

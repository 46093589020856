import React from 'react';
import { useLocation, withRouter } from 'react-router-dom/';
import { StyledNavLink } from './styles';
import colors from 'designSystem/foundations/colors';

const styles = {
  color: colors.brand.primary
};
const NavItem = ({ activeIcon, inactiveIcon, title, href }) => {
  const { pathname } = useLocation();
  const active = pathname.includes(href);
  return (
    <StyledNavLink
      to={href}
      className="nav-link"
      activeClassName="nav-link-active"
      style={styles}
    >
      <span>{active ? activeIcon : inactiveIcon}</span>
      <p>{title}</p>
    </StyledNavLink>
  );
};

export default withRouter(NavItem);

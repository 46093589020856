import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { colors } from 'designSystem/foundations';

const {
  brand: { primary },
  neutral: { grey10, grey05 }
} = colors;

export const underlineCSS = css`
  content: '';
  position: absolute;
  top: calc(100% + 4px);
  left: 50%;
  transform: translateX(-8px);
  width: 16px;
  height: 4px;
  border-radius: 2px;
  background: ${primary};
`;
const UnderlineLink = styled(Link)`
  position: relative;
  ${props => props.theme.typography.header3};
  font-weight: 600;
  color: ${props => (props.selected ? grey10 : grey05)};
  padding: 0;
  text-decoration: none;
  margin: 0 0 8px 0;

  &:after {
    ${props => props.selected && underlineCSS}
  }

  &:hover {
    color: ${grey10};
    :after {
      ${underlineCSS}
    }
  }
`;

UnderlineLink.defaultProps = { to: '#' };

export default UnderlineLink;

import React from 'react';
import { Info } from '@material-ui/icons';
import { CustomToggle, CustomTooltip } from 'components/NewLyncworkUIKit';

import { useRoleForm } from '../../RoleFormContext';

import { permissionConstants } from './constants';
import {
  Card,
  SectionTitle,
  IconContainer,
  ToggleContainer,
  CheckboxContainer,
  CheckboxTitle,
  Checkbox
} from './styles';

const AuthorizationTooltip = () => {
  return (
    <CustomTooltip
      title="All users in this role will have access to selected admin features."
      placement="right"
    >
      <IconContainer>
        <Info fontSize="inherit" />
      </IconContainer>
    </CustomTooltip>
  );
};

const RoleAuthorization = () => {
  const {
    formState: { adminAccess, permissions },
    handlePermissionChange,
    handleAdminAccessToggle
  } = useRoleForm();
  return (
    <Card>
      <ToggleContainer>
        <SectionTitle>
          Authorization
          <AuthorizationTooltip />
        </SectionTitle>
        <CustomToggle
          label="Admin Access"
          checked={adminAccess}
          onChange={handleAdminAccessToggle}
        />
      </ToggleContainer>
      <CheckboxContainer disabled={!adminAccess}>
        <CheckboxTitle>Features</CheckboxTitle>
        {permissionConstants.map(permission => {
          const { name, id } = permission;
          const checked = !!permissions.find(item => item.id === id);
          return (
            <Checkbox
              label={name}
              key={id}
              checked={checked}
              value={id}
              onChange={handlePermissionChange}
              disabled={!adminAccess}
            />
          );
        })}
      </CheckboxContainer>
    </Card>
  );
};

export default RoleAuthorization;

import React from 'react';
import { useAccountDetailsForm } from '../accountDetailsFormContext';
import { TextInput } from './styles';

const TextField = ({ name, className, label, required = false }) => {
  const { formInfo, updateForm, formError } = useAccountDetailsForm();
  const value = formInfo[name];
  const subLabel = formError[name];
  const error = !!subLabel;
  const onChange = e => {
    const { name, value } = e.target;
    updateForm(name, value);
  };
  return (
    <TextInput
      required={required}
      label={label}
      value={value}
      className={className}
      name={name}
      subLabel={subLabel}
      error={error}
      onChange={onChange}
    />
  );
};

export default TextField;

import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import { Container, TitleContainer, Description } from './styles';

const MonitoredMessage = () => {
  return (
    <Container>
      <TitleContainer>
        <ErrorIcon />
        <span>Applications for this user can no longer be modified</span>
      </TitleContainer>
      <Description>
        During monitored deprovisioning, you still have access to some of the
        user’s application accounts using temporary credentials.
      </Description>
      <Description>
        After the monitoring period expires, you will no longer have access to
        any of the user’s accounts.
      </Description>
      <Description>
        Please note that all licences and charges for monitored applications
        will still be applied until full deprovisioning occurs.
      </Description>
    </Container>
  );
};

export default MonitoredMessage;

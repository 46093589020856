import React from 'react';
import placeholder from './placeholder.svg';

import {
  Container,
  ContentContainer,
  Title,
  Tagline,
  CustomButton,
  ImageContainer,
  CardsContainer
} from './styles';

import EngagementCard from './EngagementCard';
const EngagementPage = () => {
  return (
    <Container>
      <ContentContainer>
        <Title>Engagement</Title>
        <Tagline>Please sign these documents</Tagline>
        <CustomButton>Upgrade</CustomButton>
        <ImageContainer>
          <img src={placeholder} alt="placeholder" />
        </ImageContainer>
        <Title color="black">Engagement</Title>
        <Tagline color="black">Please sign these documents</Tagline>
        <CardsContainer>
          <EngagementCard />
          <EngagementCard />
          <EngagementCard />
        </CardsContainer>
      </ContentContainer>
    </Container>
  );
};

export default EngagementPage;

import React, { useState } from 'react';

import { CustomTextInput, CustomButton } from 'components/NewLyncworkUIKit';

import {
  SectionContainer,
  Description,
  StyledList,
  SectionTitle,
  ButtonContainer
} from './styles';

const ImmediateDeprovision = ({ fullName, deleteUser, returnToMenu }) => {
  const [inputValue, setInputValue] = useState('');
  const onChange = e => setInputValue(e.target.value);
  const label = `To proceed, type in the user’s name (${fullName})`;
  const disableDeprovisionButton = fullName !== inputValue;
  return (
    <>
      <SectionContainer className="bottom-divider">
        <Description>
          Are you sure you want to proceed with immediate deprovision option for{' '}
          {fullName}? The following will occur:
        </Description>
        <StyledList>
          <li>
            User access to all associated accounts will revoked immediately
          </li>
          <li>All associated accounts will be deleted</li>
          <li>All Lyncwork user profile data will be deleted</li>
        </StyledList>
      </SectionContainer>
      <SectionContainer>
        <CustomTextInput
          value={inputValue}
          onChange={onChange}
          label={label}
          width="340px"
        />
        <SectionTitle>This action is permanent and irreversible.</SectionTitle>
        <Description>
          To keep the user’s accounts available for monitoring, press “Back” and
          select the “Monitored Deprovision” option instead.
        </Description>
      </SectionContainer>
      <ButtonContainer>
        <CustomButton variant="secondary" size="medium" onClick={returnToMenu}>
          Back
        </CustomButton>
        <CustomButton
          size="medium"
          disabled={disableDeprovisionButton}
          onClick={deleteUser}
        >
          Confirm Deprovision
        </CustomButton>
      </ButtonContainer>
    </>
  );
};

export default ImmediateDeprovision;

import React from 'react';
import styled, { css } from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';

export const TooltipWrapper = props => (
  <Tooltip classes={{ popper: props.className }} {...props} />
);

const CustomTooltip = styled(TooltipWrapper)`
  .MuiTooltip-arrow {
    transform: scale(1, 1.5);
    :before {
      background-color: ${props => props.theme.colors.neutral.grey08};
    }
  }

  .MuiTooltip-tooltipPlacementRight,
  .MuiTooltip-tooltipPlacementLeft {
    .MuiTooltip-arrow {
      transform: scale(1.5, 1);
    }
  }
  .MuiTooltip-tooltip {
    font-family: Work Sans;
    padding: 8px 16px;
    background-color: ${props => props.theme.colors.neutral.grey08};
    border-color: ${props => props.theme.colors.neutral.grey08};
    color: white;
    text-align: center;
    ${props => props.theme.typography.metadata};
  }
`;

export default CustomTooltip;

import styled, { css } from 'styled-components';
import { CustomButton } from 'components/NewLyncworkUIKit';
import colors from 'designSystem/foundations/colors';
import {
  scrollBar,
  multilineEllipsisFunc
} from 'StyleVariables/StandardComponents';
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
`;
export const ErrorText = styled.span`
  color: red;
  width: 100%;
  text-align: center;
  margin: 1em 0;
`;
export const JourneysContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 308px;
  overflow-y: auto;
  ${scrollBar}
`;
export const JourneyContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;
  width: 100%;
  height: 77px;
  box-sizing: border-box;
  padding: 1em 24px;
  border-bottom: 1px solid ${colors.neutral.grey02};
`;
export const JourneyTitle = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  p {
    margin: 0;
    flex: 1;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* width: 100%; */
    /* identical to box height, or 143% */

    /* Neutral/Grey 10 */

    color: ${colors.neutral.grey10};
    ${multilineEllipsisFunc(1)}
  }
`;
export const JourneyDescription = styled.span`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Neutral/Grey 10 */

  color: ${colors.neutral.grey06};
  ${multilineEllipsisFunc(1)}
`;
export const Tasks = styled.span`
  display: flex;
  align-items: center;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 143% */

  color: ${colors.feedback.confirmation};
`;
export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  p {
    margin: 0;
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    text-align: center;

    /* Neutral/Grey 07 */

    color: ${colors.neutral.grey07};
  }
`;
export const Empty = styled.img`
  width: 30%;
  height: auto;
`;

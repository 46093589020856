import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 8px 12px;
  border-radius: 8px;
  min-height: 48px;
  box-sizing: border-box;
  background-color: ${props => props.theme.colors.neutral.grey01};
`;

export const TextArea = styled.textarea`
  border: none;
  outline: none;
  background-color: transparent;
  font-family: inherit;
  resize: none;
  box-sizing: border-box;
  flex-grow: 1;
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey10};
  &::placeholder {
    color: ${props => props.theme.colors.neutral.grey07};
  }
`;

TextArea.defaultProps = { rows: 1 };

import React, { useState, useEffect } from 'react';
import {
  format,
  isSameDay,
  addDays,
  eachDayOfInterval,
  isWithinInterval
} from 'date-fns';
import { CircularProgress } from '@material-ui/core';
import { UserJourneyService } from 'services';

import imageEndOfOnboarding from 'assets/images/Onboarding/EndOfOnboarding.svg';

import {
  Container,
  Header,
  DayLabel,
  TaskCardContainer,
  EmptyImage,
  EmptyMessage
} from './styles';

const formatDate = date => format(date, 'yyyy-MM-dd');

const TaskCard = ({ task }) => {
  const {
    taskDue,
    workflow,
    task: { taskEventName }
  } = task;
  const taskDateText = taskDue ? 'Task Due' : 'Task Start';
  const workflowId = workflow?.id;

  const link = workflowId ? `/user-journeys/${workflowId}` : '/dashboard';

  return (
    <TaskCardContainer $taskDue={taskDue} to={link}>
      <span>{taskEventName}</span>
      <span>{taskDateText}</span>
    </TaskCardContainer>
  );
};

const TaskGroup = ({ taskGroup }) => {
  const { date, tasks } = taskGroup;
  return (
    <>
      <DayLabel>{date}</DayLabel>
      {tasks.map(task => (
        <TaskCard task={task} key={task.id} />
      ))}
    </>
  );
};

const getTaskGroups = tasks => {
  const start = new Date();
  const end = addDays(start, 7);
  const interval = { start, end };
  const daysWithinInterval = eachDayOfInterval(interval);

  const taskTable = {};
  const dateStrings = [];
  for (let day of daysWithinInterval) {
    const dateString = formatDate(day);
    dateStrings.push(dateString);
    taskTable[dateString] = [];
  }

  for (let task of tasks) {
    const { schedStartDate, schedDueDate } = task;
    const taskStartDate = new Date(schedStartDate);
    const taskDueDate = new Date(schedDueDate);

    const showDueDate = isWithinInterval(taskDueDate, interval);
    const showStartDate = isWithinInterval(taskStartDate, interval);

    const taskWithDueInfo = { ...task, taskDue: showDueDate };

    if (!showDueDate && !showStartDate) {
      continue;
    }

    const groupDate = showDueDate ? taskDueDate : taskStartDate;
    const dateString = formatDate(groupDate);
    taskTable[dateString].push(taskWithDueInfo);
  }

  const taskGroups = dateStrings.reduce((taskGroup, currentDate) => {
    const tasks = taskTable[currentDate];
    if (tasks.length > 0) {
      return [
        ...taskGroup,
        {
          date: currentDate,
          tasks: taskTable[currentDate]
        }
      ];
    }
    return taskGroup;
  }, []);
  return taskGroups;
};

const TaskGroups = ({ taskGroups, loading }) => {
  if (loading) {
    return <CircularProgress />;
  }

  if (taskGroups.length < 1) {
    return (
      <>
        <EmptyImage alt="no-tasks-upcoming" src={imageEndOfOnboarding} />
        <EmptyMessage>
          You have no tasks starting or due in the coming days.
        </EmptyMessage>
      </>
    );
  }

  return taskGroups.map(taskGroup => (
    <TaskGroup key={taskGroup.date} taskGroup={taskGroup} />
  ));
};

const UpcomingTasks = () => {
  const [{ loading, taskGroups }, setData] = useState({
    loading: true,
    taskGroups: []
  });

  useEffect(() => {
    let mounted = true;
    const getTask = async () => {
      const today = new Date();
      const endDay = addDays(today, 7);
      const startDate = formatDate(today);
      const endDate = formatDate(endDay);
      try {
        const response = await UserJourneyService.listTask({
          startDate,
          endDate,
          status: 'ASSIGNED'
        });
        const tasks = response.data.content;
        const taskGroups = getTaskGroups(tasks);
        mounted&&setData({ loading: false, taskGroups });
      } catch (error) {
        mounted&&setData({ loading: false, taskGroups:[] });
      }
    };
    getTask();
    return () => {
      mounted = false;
    };
  }, []);
  return (
    <Container data-tutorial-id="upcoming-tasks">
      <Header>Upcoming Tasks</Header>
      <TaskGroups taskGroups={taskGroups} loading={loading} />
    </Container>
  );
};

export default UpcomingTasks;

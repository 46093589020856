import React from 'react';
import ReactDOM from 'react-dom';
import WebFont from 'webfontloader';

import App from './App';

import AppWrapper, { ApolloWrapper } from './AppWrapper';
import KeyCloakWrapper from './AppWrapper/KeyCloakWrapper';
import swDev from './swDev';

import {
  createGenerateClassName,
  jssPreset,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core'; //info on css overides for datepicker https://material-ui-pickers.dev/guides/css-overrides
const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: { main: '#ff6b6b' },
    secondary: { main: '#ffffff' }
  }
});

WebFont.load({
  google: {
    families: ['Work Sans:300,400,400i,500,600,700']
  }
});

ReactDOM.render(
  <AppWrapper>
    <ThemeProvider theme={defaultMaterialTheme}>
      <ApolloWrapper>
        <KeyCloakWrapper>
          <App />
        </KeyCloakWrapper>
      </ApolloWrapper>
    </ThemeProvider>
  </AppWrapper>,
  document.getElementById('root')
);
swDev();

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';

import { RoleForm } from './components';
import {
  SingleRolePageContainer,
  BackButtonContainer,
  BackButton
} from './styles';

const RolesAddPage = () => {
  const history = useHistory();

  return (
    <SingleRolePageContainer>
      <BackButtonContainer>
        <BackButton
          variant="secondary"
          size="small"
          onClick={() => history.push('/admin-roles')}
        >
          <ArrowBack fontSize="inherit" /> Back
        </BackButton>
      </BackButtonContainer>
      <RoleForm />
    </SingleRolePageContainer>
  );
};

export default RolesAddPage;

import styled from 'styled-components';
import Card from 'components/Card';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  button {
    align-self: flex-start;
  }
  button:disabled {
    align-self: center;
  }
  .MuiCircularProgress-root {
    margin: auto;
  }
`;

export const BreadCrumb = styled.h1`
  ${props => props.theme.typography.title3};
  font-weight: 600;
  margin: 0;
`;

export const ContentContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 32px 64px;
`;

export const AppCategoryButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 28px 0 32px;
  gap: 16px;
`;

export const ListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const BlankListContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 400px;
  gap: 16px;
  justify-content: center;
  align-items: center;

  img {
    width: 200px;
    height: 200px;
    object-fit: contain;
  }

  .message-title {
    ${props => props.theme.typography.header3};
    color: ${props => props.theme.colors.neutral.grey08};
    margin: 0;
    font-weight: 600;
  }
  p {
    margin: 0;
    color: ${props => props.theme.colors.neutral.grey07};
    max-width: 400px;
    text-align: center;
  }
`;

import styled from 'styled-components';
import { CustomButton, ImageCircle } from 'components/NewLyncworkUIKit';
import colors from 'designSystem/foundations/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  scrollBar,
  multilineEllipsisFunc
} from 'StyleVariables/StandardComponents';
export const AddSelectedButton = styled(CustomButton)`
  align-items: center;
  width: 221px;
  margin-top: 24px;
`;
export const Loader = styled(CircularProgress)`
  margin: 1em 50%;
  align-self: center;
`;
export const RightSideBar = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  flex: 1;
  padding: 24px 24px 32px 24px;
`;
export const Users = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  ${scrollBar}
`;
export const UserContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 77px;
  box-sizing: border-box;
  padding: 1em 0;
  border-bottom: 1px solid ${colors.neutral.grey02};
`;
export const UserName = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  p {
    margin: 0;
    flex: 1;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* width: 100%; */
    /* identical to box height, or 143% */

    /* Neutral/Grey 10 */

    color: ${colors.neutral.grey10};
    ${multilineEllipsisFunc(1)}
  }
`;
export const UserEmail = styled.span`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Neutral/Grey 10 */

  color: ${colors.neutral.grey06};
  ${multilineEllipsisFunc(1)}
`;
export const InfoContainer = styled.span`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  margin-left: 8px;
`;
export const ErrorText = styled.span`
  color: red;
  width: 100%;
  text-align: center;
  margin: 1em 0;
`;
export const StyledButton = styled(CustomButton)`
  align-items: center;
  color: ${({ selected }) => selected && colors.neutral.grey07};
  img {
    margin-right: 4px;
  }
`;
export const Added = styled.span`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Neutral/Grey 04 */

  color: ${colors.neutral.grey04};
`;

export const ExitButton = styled.img`
  width: 16px;
  height: 16px;
  margin: 0 0 0 1em;
  cursor: pointer;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
`;

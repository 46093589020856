import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import imageDocuments from 'assets/images/Onboarding/Documents.svg';
import imagePersonalInformation from 'assets/images/Onboarding/PersonalInformation.svg';

const IMG = styled.img`
  object-position: bottom;
`;

const Title = styled.span`
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.neutral.grey08};
  margin: 0;
  font-weight: 600;
  margin: 0 0 16px 0;
`;

const Message = styled.p`
  margin: 0;
  max-width: 492px;
  text-align: center;
  ${props => props.theme.typography.control};
  a {
    color: ${props => props.theme.colors.brand.primary};
    font-weight: 600;
  }
`;

export const NoResultsFound = () => {
  return (
    <>
      <IMG src={imageDocuments} alt="empty state" />
      <Title>No Results Found</Title>
      <Message>Looking for an applications you haven’t added yet?</Message>
      <Message>
        You can <Link to="/admin-applications/browse">Browse</Link> all
        applications instead.
      </Message>
    </>
  );
};
export const NoAppsFound = () => {
  return (
    <>
      <IMG src={imagePersonalInformation} alt="empty state" />
      <Title>It’s a little empty in here...</Title>
      <Message>
        Not sure where to start?{' '}
        <Link to="/admin-applications/browse">Browse</Link> to view and add your
        first application!
      </Message>
    </>
  );
};

import { UserImportService } from 'services/userImport.service';

export const getOptionLabel = role => role.roleName;

export const getLicenseInfo = licenseManagements => {
  if (licenseManagements.length < 1) {
    return {
      licenseName: 'None',
      licenseId: null
    };
  }
  //we only care about the first license
  const [firstLicense] = licenseManagements;
  const {
    applicationLicenseInformation: { licenseName, licenseId }
  } = firstLicense;
  return { licenseName, licenseId };
};

export const closeMenuOnScroll = e => {
  const { className: targetClassName = '' } = e.target;
  const isSelect = targetClassName.includes('lw-select');
  if (isSelect) {
    e.stopPropagation();
    return false;
  }
  return true;
};

const getNextPageParams = link => {
  if (!link) {
    return null;
  }

  const items = link.split(',');
  const next = items.find(item => item.includes('rel="next"'));
  if (!next) {
    return null;
  }
  const nextLink = next.split(';')[0].replaceAll(/[<,>]/g, '');
  const params = new URL(nextLink).searchParams;
  return params;
};

const formatAppUser = userData => {
  const {
    firstName,
    lastName,
    emailPrimary,
    emailSecondary,
    username,
    licenseManagements,
    provisionedBy
  } = userData;
  return {
    firstName,
    lastName,
    emailPrimary,
    emailSecondary,
    username,
    licenseManagements: licenseManagements || [],
    roles: [],
    provisionedBy
  };
};

export const defaultPageSize = 10;

export const getAppUsers = async (
  appAPIName,
  params = { size: defaultPageSize }
) => {
  const response = await UserImportService[appAPIName](params);
  const {
    data: fetchedUsers,
    headers: { link }
  } = response;
  const nextPageParams = getNextPageParams(link);
  const users = fetchedUsers.map(formatAppUser);
  return { users, nextPageParams };
};

import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import colors from 'designSystem/foundations/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContentContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.colors.brand.primary01};
  border-radius: 10px;
  padding: 0 24px;
  margin: 16px 0 0;
  background-color: ${props => props.theme.colors.neutral.white};
  overflow: hidden;
`;

export const Title = styled.h1`
  ${props => props.theme.typography.title3};
  font-weight: 600;
  margin: 0 0 24px 0;
`;

export const PageDescription = styled.div`
  ${props => props.theme.typography.control};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
  span {
    height: 1.25rem;
    margin-right: 4px;
    color: ${props => props.theme.colors.feedback.error};
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 600px;
  max-width: 100%;
  align-self: center;
`;
export const StyledNavLink = styled(NavLink)`
  &.onboarding-nav-link {
    position: relative;
    text-decoration: none;
    transition: all 0.3s;
    border-bottom: 2px solid ${colors.neutral.grey06};

    &:hover {
      background-color: #f4f5f7;
    }
    p {
      font-family: Work Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      color: #000000;
      margin: 0;
    }
  }
  &.onboarding-nav-link-active {
    p {
      color: ${colors.brand.primary};
    }
    .active-div {
      opacity: 1;
    }
    border-bottom: 2px solid ${colors.brand.primary};
  }
`;

import React from 'react';

import Button from 'components/NewLyncworkUIKit/CustomButton';
import { Container, ButtonContainer, ContentContainer } from './styles';

const Welcome = ({ nextPage }) => {
  return (
    <Container>
      <ContentContainer>
        <h2>Our Vision</h2>
        <p>
          Magic happens when people are given the freedom to create their own
          path. We trust you to be resourceful, solve problems as they arise,
          and define how you want to make an impact.
        </p>
        <img src="https://media.lyncwork.com/content/public/onboarding/image_497369935fb3g4v42.png" />
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={nextPage}>Next</Button>
      </ButtonContainer>
    </Container>
  );
};

export default Welcome;

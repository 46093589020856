import React from 'react';
import Info from '@material-ui/icons/Info';
import {
  Card,
  SectionTitle,
  RadioContainer,
  RadioButton,
  Label,
  Container,
  SubLabel,
  KeyWords
} from './styles';
import AssignmentSelection from './AssignmentSelection';

const JourneyAssignment = ({ assignmentInfoProps }) => {
  const {
    assignmentType,
    teams,
    roles,
    changeAssignmentType,
    changeAssignment
  } = assignmentInfoProps;
  const selectionType = assignmentType === 'ROLE' ? 'Roles' : 'Teams';
  return (
    <Card>
      <SectionTitle>Assign Journey</SectionTitle>
      <RadioContainer>
        <RadioButton
          label="By Roles"
          value="ROLE"
          checked={assignmentType === 'ROLE'}
          onChange={changeAssignmentType}
        />
        <RadioButton
          label="By Teams"
          value="TEAM"
          checked={assignmentType === 'TEAM'}
          onChange={changeAssignmentType}
        />
      </RadioContainer>
      <Label>Select {selectionType}</Label>
      <AssignmentSelection
        key={assignmentType}
        assignmentInfoProps={assignmentInfoProps}
      />
      <Container>
        <Info />
        <SubLabel>
          This journey will be assigned to{' '}
          <KeyWords>all {selectionType.toLowerCase()}</KeyWords>
          {' '}if no selection is made.
        </SubLabel>
      </Container>
    </Card>
  );
};

export default JourneyAssignment;

import styled from 'styled-components';

const HiddenCounter = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.neutral.offWhite03};
  border-radius: 50%;
  color: ${props => props.theme.colors.neutral.grey10};
  &.remaining-count-small {
    width: 16px;
    height: 16px;
    ${props => props.theme.typography.footnote};
  }
`;

export default HiddenCounter;

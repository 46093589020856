import axios from 'axios';
import env from '../env';
const baseUrl = `${env.storeDomain}/api`;
const CMSUrl = `${env.frontDoorDomain}/services/cms/api`;
const workaroundUrl = `${env.frontDoorDomain}/services/cms/api/medias/avatar`;
//https://api.lync.work/services/cms/api/medias
export const CMSService = {
  accountDetail: () => {
    return axios.get(`${baseUrl}/account-detail`);
  },
  accountEmailProvider: () => {
    return axios.get(`${baseUrl}/account-detail/email-provider`);
  },
  applications: () => {
    return axios.get(`${baseUrl}/account-detail/applications`);
  },
  firstLogin: () => {
    return axios.get(`${baseUrl}/account-detail/firstLogin`);
  },
  updateAvatar: file => {
    const formData = new FormData();
    formData.append('files', file);
    return axios.post(`${workaroundUrl}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  updateUser: user => {
    return axios.put(`${baseUrl}/account-detail`, user);
  },

  listPost: ({ page = '0', size = '10', draft = false, sort = '' }) => {
    const searchParams = new URLSearchParams({
      page,
      size,
      draft,
      sort
    });
    const queryString = searchParams.toString();
    return axios.get(`${CMSUrl}/news-and-updates?${queryString}`);
  },

  community: () => {
    return axios.get(`${CMSUrl}/news-and-updates/view`);
  },
  // getAllCategories: () => {
  //   return axios.get(`${CMSUrl}/categories`);
  // },
  getAllCategories: (search, page = '0', size = '10') => {
    const searchParams = new URLSearchParams({
      name: search,
      page,
      size
    });
    const queryString = searchParams.toString();
    return axios.get(`${CMSUrl}/categories?${queryString}`);
  },
  createCategory: body => {
    return axios.post(`${CMSUrl}/categories`, body);
  },
  createPost: body => {
    return axios.post(`${CMSUrl}/news-and-updates`, body);
  },

  editPost: (id, body) => {
    return axios.put(`${CMSUrl}/news-and-updates/${id}`, body);
  },
  deletePost: id => {
    return axios.delete(`${CMSUrl}/news-and-updates/${id}`);
  },
  getPostById: id => {
    return axios.get(`${CMSUrl}/news-and-updates/${id}`);
  },
  uploadMedia: file => {
    const formData = new FormData();
    Array.isArray(file)
      ? file.forEach(x => formData.append('files', x))
      : formData.append('files', file);
    return axios.post(`${CMSUrl}/medias`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  deleteMedia: id => {
    return axios.delete(`${CMSUrl}/medias/${id}`);
  },
  getMediaById: id => {
    return axios.get(`${CMSUrl}/medias/${id}`);
  },
  getCustomOnboarding: () => {
    return axios.get(`${CMSUrl}/onboarding-process-page/welcome`);
  },
  postCustomOnboarding: body => {
    return axios.put(`${CMSUrl}/onboarding-process-page/${body.id}`, body);
  },

  createComment: ({ comment, post }) => {
    return axios.post(`${CMSUrl}/comments`, { comment, post });
  },
  deleteComment: commentId => {
    return axios.delete(`${CMSUrl}/comments/${commentId}`);
  },

  createReactionComment: ({ comment, post, reactionType = 'like' } = {}) => {
    return axios.post(`${CMSUrl}/reaction-comments`, {
      comment,
      post,
      reaction_type: reactionType
    });
  },
  deleteReactionComment: ({ comment, post } = {}) => {
    return axios.delete(
      `${CMSUrl}/reaction-comments/post/${post}/comment/${comment}`
    );
  },
  createReactionPost: ({ post, reactionType = 'like' } = {}) => {
    return axios.post(`${CMSUrl}/reaction-posts`, {
      post,
      reaction_type: reactionType
    });
  },
  deleteReactionPost: postId => {
    return axios.delete(`${CMSUrl}/reaction-posts/post/${postId}`);
  }
};

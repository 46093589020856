import React from 'react';
import {
  Container,
  SectionContainer,
  Title,
  Tagline,
  CardContainer
} from './styles';

import { CompanyResourceCard, DocumentCard } from './Cards';

const documentCategory = 'SIGNABLE_ALL';
const companyResourceCategory = 'ALL';

const DocumentsPagePreview = ({ files }) => {
  const documents = files.filter(
    file => file.fileCategory === documentCategory
  );
  const companyResources = files.filter(
    file => file.fileCategory === companyResourceCategory
  );
  return (
    <Container>
      {documents.length > 0 && (
        <SectionContainer>
          <Title>Sign Documents</Title>
          <Tagline>Please sign these employee documents</Tagline>
          <CardContainer>
            {documents.map(file => {
              const { id, description, signingTitle } = file;
              return (
                <DocumentCard
                  key={id}
                  title={signingTitle}
                  description={description}
                />
              );
            })}
          </CardContainer>
        </SectionContainer>
      )}
      {companyResources.length > 0 && (
        <SectionContainer>
          <Title>Company Resources</Title>
          <Tagline>
            Please provide the following personal information to complete your
            company profile.
          </Tagline>
          <CardContainer>
            {companyResources.map(file => {
              const { id, description, signingTitle } = file;
              return (
                <CompanyResourceCard
                  key={id}
                  title={signingTitle}
                  description={description}
                />
              );
            })}
          </CardContainer>
        </SectionContainer>
      )}
    </Container>
  );
};

export default DocumentsPagePreview;

import React, { useState } from 'react';

import RoleUsers from './RoleUsers';
import RoleJourneys from './RoleJourneys';
import { Container, Tab, TabContainer } from './styles';

const RoleDetails = ({ roleId }) => {
  const [tab, setTab] = useState('users');

  const navigateToUsers = () => setTab('users');
  const navigateToJourneys = () => setTab('journeys');

  const isUserTab = tab === 'users';
  return (
    <Container>
      <TabContainer>
        <Tab selected={isUserTab} onClick={navigateToUsers}>
          Users
        </Tab>
        <Tab selected={!isUserTab} onClick={navigateToJourneys}>
          Journeys
        </Tab>
      </TabContainer>
      {isUserTab ? (
        <RoleUsers roleId={roleId} />
      ) : (
        <RoleJourneys roleId={roleId} />
      )}
    </Container>
  );
};

export default RoleDetails;

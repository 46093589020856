import React from 'react';

import { Container, Tab } from './styles';

const TabNavigation = ({ activeTab, toggleTab }) => {
  const workSelected = activeTab === 'work';
  return (
    <Container>
      <Tab selected={workSelected} onClick={toggleTab}>
        Work Info
      </Tab>
      <Tab selected={!workSelected} onClick={toggleTab}>
        Personal Info
      </Tab>
    </Container>
  );
};

export default TabNavigation;

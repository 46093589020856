import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from 'components/NewLyncworkUIKit/CustomButton';

const LoadingButton = ({
  spinnerSize = '24px',
  loading,
  children,
  ...buttonProps
}) => {
  return (
    <Button {...buttonProps}>
      {loading ? (
        <CircularProgress color="inherit" size={spinnerSize} />
      ) : (
        children
      )}
    </Button>
  );
};

export default LoadingButton;

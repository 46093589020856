import React, { useEffect, useState } from 'react';

import { DropdownService } from 'services';
import { CustomTextInput, AsyncSelect } from 'components/NewLyncworkUIKit';

import { DateInput, Select } from './Fields';

import useJobTitle from './useJobTitle';

import {
  SectionContainer,
  SectionDescription,
  FieldContainer,
  Container,
  Label,
  JobTitleSelect
} from './styles';

const getNewOptionData = inputValue => {
  const title = inputValue;
  return { title, isNew: true };
};

const getOptionLabel = option => {
  const { isNew, title } = option;
  if (isNew) {
    return `Add Job Title '${title}'`;
  }
  return title;
};

const AddJobTitle = ({
  searchJobTitles,
  createJobTitle,
  handleJobSelectChange,
  display
}) => {
  const [key, setKey] = useState(1);
  const getOptions = (input, callback) => {
    searchJobTitles(input).then(res => {
      callback(res);
    });
  };

  const createNewJobTitle = async title => {
    const result = await createJobTitle(title);
    handleJobSelectChange(result);
    setKey(key * -1); // this is hacky but will force a re-render to display the new job title.
  };

  return (
    <Container>
      <Label>Job Title</Label>
      <JobTitleSelect
        value={display}
        key={key}
        isClearable
        onChange={handleJobSelectChange}
        placeholder="search job titles..."
        loadOptions={getOptions}
        getNewOptionData={getNewOptionData}
        getOptionLabel={getOptionLabel}
        onCreateOption={createNewJobTitle}
      />
    </Container>
  );
};

const getUserLoadOptions = (key, additionalParams = {}) => {
  const fetch = async (inputValue, idsIgnored = []) => {
    const response = await DropdownService.users({
      ...additionalParams,
      idsIgnored,
      name: inputValue
    });
    return response.data.content;
  };
  return fetch;
};

const loadTeams = async inputValue => {
  const response = await DropdownService.teams({
    name: inputValue
  });
  return response.data.content;
};
const loadRoles = async inputValue => {
  const response = await DropdownService.roles({
    name: inputValue
  });
  return response.data.content;
};
const loadUsers = getUserLoadOptions('users');
const loadITPrimes = getUserLoadOptions('users', { role: 'IT Prime' });
const loadHRPrimes = getUserLoadOptions('users', { role: 'HR Prime' });

export const ProvisionData = ({
  workDetails,
  handleFieldChange,
  domain,
  formErrors,
  disabled
}) => {
  const {
    firstName,
    lastName,
    username,
    emailPrimary,
    emailSecondary,
    provisionUserDate,
    jobTitle
  } = workDetails;
  const { getAllJobTitles, createJobTitle, searchJobTitles } = useJobTitle();
  const handleInputChange = e => {
    const { name, value } = e.target;
    handleFieldChange(name, value);
  };
  const handleJobSelectChange = newJobTitle => {
    handleFieldChange('jobTitle', newJobTitle);
  };
  const handleDateChange = value =>
    handleFieldChange('provisionUserDate', value);
  return (
    <SectionContainer>
      <SectionDescription>
        <h2>Lyncwork Provisioning Data</h2>
        <p>
          Base information required by Lyncwork in order to provision a new
          user. Once a new user is saved an activation link will be sent to the
          user’s personal email account
        </p>
      </SectionDescription>
      <FieldContainer>
        <CustomTextInput
          className="half-width"
          required
          label="First Name"
          value={firstName}
          name="firstName"
          error={!!formErrors.firstName}
          subLabel={formErrors.firstName}
          onChange={handleInputChange}
          disabled={disabled}
        />
        <CustomTextInput
          className="half-width"
          required
          label="Last Name"
          value={lastName}
          name="lastName"
          error={!!formErrors.lastName}
          subLabel={formErrors.lastName}
          onChange={handleInputChange}
          disabled={disabled}
        />
        <CustomTextInput
          className="half-width"
          required
          label="Username"
          value={username}
          name="username"
          error={!!formErrors.username}
          subLabel={formErrors.username}
          onChange={handleInputChange}
          disabled={disabled}
        />
        <DateInput
          className="half-width"
          name="provisionUserDate"
          date={provisionUserDate}
          onChange={handleDateChange}
          disabled={disabled}
        />
        <CustomTextInput
          required
          endAdornment={<span>@{domain}</span>}
          label="Company Email"
          value={emailPrimary}
          name="emailPrimary"
          error={!!formErrors.emailPrimary}
          subLabel={formErrors.emailPrimary}
          onChange={handleInputChange}
          disabled={disabled}
        />
        <CustomTextInput
          required
          label="Personal Email"
          value={emailSecondary}
          error={!!formErrors.emailSecondary}
          subLabel={formErrors.emailSecondary}
          name="emailSecondary"
          onChange={handleInputChange}
          disabled={disabled}
        />
        <AddJobTitle
          createJobTitle={createJobTitle}
          loadJobTitles={getAllJobTitles}
          searchJobTitles={searchJobTitles}
          handleJobSelectChange={handleJobSelectChange}
          display={jobTitle}
        />
      </FieldContainer>
    </SectionContainer>
  );
};

export const Assignments = ({ workDetails, handleFieldChange, disabled }) => {
  const { teams, roles } = workDetails;
  const handleSelect = (newOptions, event) => {
    const { name } = event;
    handleFieldChange(name, newOptions);
  };
  return (
    <SectionContainer>
      <SectionDescription>
        <h2>Roles and Teams</h2>
        <p>
          Here you can assign a role and a team to the user, you can select from
          existing teams and roles you have already created. If you would like
          to assign the user to a new role or team, you can save the user as a
          draft before navigating to the corresponding roles and teams pages to
          create them
        </p>
      </SectionDescription>
      <FieldContainer>
        <Select
          name="roles"
          label="Roles"
          value={roles}
          loadOptions={loadRoles}
          onChange={handleSelect}
          isMulti
          isClearable
          isDisabled={disabled}
        />
        <Select
          name="teams"
          label="Teams"
          value={teams}
          loadOptions={loadTeams}
          onChange={handleSelect}
          isMulti
          isClearable
          isDisabled={disabled}
        />
      </FieldContainer>
    </SectionContainer>
  );
};

const UserSelect = ({ usersToIgnore = [], fetchOptions, ...rest }) => {
  const idsIgnored = usersToIgnore
    .filter(option => option !== null)
    .map(({ id }) => id);
  const loadOptions = inputValue => fetchOptions(inputValue, idsIgnored);
  return <Select {...rest} loadOptions={loadOptions} key={idsIgnored.length} />;
};

export const KeyContacts = ({
  workDetails,
  handleFieldChange,
  usersToIgnore,
  disabled
}) => {
  const { mentor, manager, itPrime, hrPrime, directReports, mentee } =
    workDetails;
  const handleSelect = (newOptions, event) => {
    const { name } = event;
    handleFieldChange(name, newOptions);
  };
  return (
    <SectionContainer>
      <SectionDescription>
        <h2>Key Contacts</h2>
        <p>
          Here you can assign key contacts to a user within your organization,
          however contacts are not necessary. The HR prime and IT Prime are the
          first points of contact for a user should they require assitance with
          either fields.
        </p>
      </SectionDescription>
      <FieldContainer>
        <UserSelect
          name="manager"
          label="Manager"
          value={manager}
          fetchOptions={loadUsers}
          usersToIgnore={[...usersToIgnore, ...directReports]}
          onChange={handleSelect}
          isClearable
          isDisabled={disabled}
        />
        <UserSelect
          name="directReports"
          label="Direct Reports"
          value={directReports}
          fetchOptions={loadUsers}
          usersToIgnore={[...usersToIgnore, manager]}
          onChange={handleSelect}
          isMulti
          isClearable
          isDisabled={disabled}
        />
        <UserSelect
          name="mentor"
          label="Mentor"
          value={mentor}
          fetchOptions={loadUsers}
          usersToIgnore={[...usersToIgnore, ...mentee]}
          onChange={handleSelect}
          isClearable
          isDisabled={disabled}
        />
        <UserSelect
          name="mentee"
          label="Mentee"
          value={mentee}
          fetchOptions={loadUsers}
          usersToIgnore={[...usersToIgnore, mentor]}
          onChange={handleSelect}
          isMulti
          isClearable
          isDisabled={disabled}
        />
        <UserSelect
          name="itPrime"
          label="IT Prime"
          value={itPrime}
          fetchOptions={loadITPrimes}
          usersToIgnore={usersToIgnore}
          onChange={handleSelect}
          isClearable
          isDisabled={disabled}
        />
        <UserSelect
          name="hrPrime"
          label="HR Prime"
          value={hrPrime}
          fetchOptions={loadHRPrimes}
          usersToIgnore={usersToIgnore}
          onChange={handleSelect}
          isClearable
          isDisabled={disabled}
        />
      </FieldContainer>
    </SectionContainer>
  );
};

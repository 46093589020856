import React, { useState } from 'react';

import useSubscriptionApps from 'hooks/useSubscriptionApps';

import SubscriptionList from './SubscriptionList';
import SubscriptionDetail from './SubscriptionDetail';

import { useRoleForm } from '../../../RoleFormContext';

import {
  Container,
  SidebarContainer,
  ApplicationCategoryButton,
  ContentContainer
} from './styles';

const SideBar = ({ applicationCategories, handleCategoryChange, category }) => {
  const hasCategories = applicationCategories.length > 0;
  const removeCategory = () => handleCategoryChange('');
  return (
    <SidebarContainer>
      <h2>Add Application</h2>
      {hasCategories && (
        <>
          <ApplicationCategoryButton
            selected={category === ''}
            onClick={removeCategory}
          >
            All
          </ApplicationCategoryButton>
          {applicationCategories.map(applicationCategory => {
            const { id, name } = applicationCategory;
            const selected = name === category;
            const selectCategory = () => handleCategoryChange(name);
            return (
              <ApplicationCategoryButton
                key={id}
                selected={selected}
                onClick={selectCategory}
              >
                {name}
              </ApplicationCategoryButton>
            );
          })}
        </>
      )}
    </SidebarContainer>
  );
};

const SubscriptionModal = ({ applicationCategories, closeModal }) => {
  const {
    formState: { applications }
  } = useRoleForm();
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const {
    name,
    category,
    changeCategory,
    loading,
    handleSearchInputChange,
    searchQuery,
    changePage,
    subscriptionApps,
    page,
    totalPages
  } = useSubscriptionApps({ size: 8 });

  const handleCategoryChange = name => {
    setSelectedSubscription(null);
    changeCategory(name);
  };

  const backToList = () => setSelectedSubscription(null);

  return (
    <Container onClick={e => e.stopPropagation()}>
      <SideBar
        applicationCategories={applicationCategories}
        handleCategoryChange={handleCategoryChange}
        category={category}
      />
      <ContentContainer>
        {selectedSubscription ? (
          <SubscriptionDetail
            subscription={selectedSubscription}
            backToList={backToList}
            closeModal={closeModal}
          />
        ) : (
          <SubscriptionList
            closeModal={closeModal}
            applications={applications}
            selectSubscription={setSelectedSubscription}
            loading={loading}
            handleSearchInputChange={handleSearchInputChange}
            searchQuery={searchQuery}
            changePage={changePage}
            subscriptionApps={subscriptionApps}
            page={page}
            totalPages={totalPages}
          />
        )}
      </ContentContainer>
    </Container>
  );
};

export default SubscriptionModal;

import styled, { css } from 'styled-components';
import { CustomButton } from 'components/NewLyncworkUIKit';
import colors from 'designSystem/foundations/colors';
import {
  scrollBar,
  multilineEllipsisFunc
} from 'StyleVariables/StandardComponents';
import CircularProgress from '@material-ui/core/CircularProgress';

export const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 720px;
  max-width: 100%;
  height: 700px;
  max-height: 80vh;
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  background-color: ${props => props.theme.colors.neutral.white};
  overflow: hidden;
`;

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 240px;
  height: 100%;
  border-right: 1px solid ${props => props.theme.colors.neutral.grey02};
`;

export const ErrorText = styled.span`
  color: red;
  width: 100%;
  text-align: center;
  margin: 1em 0;
`;
export const Title = styled.span`
  margin: 24px 0 0px 24px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  display: flex;
  align-items: center;

  /* Neutral/Grey 10 */

  color: ${colors.neutral.grey10};
`;
export const Roles = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow-y: auto;
  ${scrollBar}
`;
export const Role = styled.span`
  box-sizing: border-box;
  padding-left: 24px;
  min-height: 52px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  /* identical to box height, or 140% */

  display: flex;
  align-items: center;

  /* Neutral/Grey 10 */

  color: ${colors.neutral.grey07};
  cursor: pointer;
  background-color: ${({ selected }) =>
    selected ? colors.neutral.grey01 : colors.neutral.white};
`;

export const AddSelectedButton = styled(CustomButton)`
  align-items: center;
  width: 221px;
  margin-top: 24px;
`;
export const Loader = styled(CircularProgress)`
  margin: 1em 50%;
  align-self: center;
`;

import React from 'react';

import DocumentCard from './DocumentCard';
import { Container, Title, ListContainer } from './styles';

const DocumentList = ({ files, editFile, openDeletePrompt }) => {
  return (
    <Container>
      <Title>Documents</Title>
      <ListContainer>
        {files.map(file => {
          const handleEditClick = () => editFile(file);
          const handleDeleteClick = () => openDeletePrompt(file);
          return (
            <DocumentCard
              key={file.dropboxFileId}
              file={file}
              handleEditClick={handleEditClick}
              handleDeleteClick={handleDeleteClick}
            />
          );
        })}
      </ListContainer>
    </Container>
  );
};

export default DocumentList;

import React from 'react';
import CheckMark from 'components/CheckMark';

import Button from 'components/NewLyncworkUIKit/CustomButton';
import { ButtonContainer } from 'pages/Onboarding/common/pages/styles';

import { SetupCompleteContainer, StyledCheckMark } from './styles';

const SetupComplete = ({ integratedApp, prevPage, nextPage }) => {
  return (
    <>
      <SetupCompleteContainer>
        <CheckMark className="check-mark" />
        <h1>Setup Complete</h1>
        <p>
          You have successfully integrated <strong>{integratedApp}</strong> with
          Lyncwork.
        </p>
      </SetupCompleteContainer>
      <ButtonContainer>
        <Button variant="secondary" onClick={prevPage}>
          Back
        </Button>
        <Button onClick={nextPage}>Next</Button>
      </ButtonContainer>
    </>
  );
};

export default SetupComplete;

import styled from 'styled-components';
import colors from 'designSystem/foundations/colors';
import PointBackground from './PointBackground.svg';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100vh;
  min-width: 240px;
  max-width: 240px;
  box-shadow: 1px 1px 4px 0 rgba(137, 152, 170, 0.32);
  transition: all 0.5s;
  z-index: 100;
  overflow-x: hidden;
  top: 0px;
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.small}) {
    display: none;
  }
`;
export const ToggleContainer = styled.div`
  display: flex;
  justify-content: stretch;
  background-color: ${colors.neutral.offWhite02};
  height: 28px;
  width: 176px;
  border-radius: 100px;
  margin: 28px auto 12px;
`;
export const ToggleP = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ selected }) =>
    selected ? colors.brand.primary : colors.neutral.offWhite02};
  color: ${({ selected }) =>
    selected ? colors.neutral.white : colors.neutral.grey06};
  height: 28px;
  width: 50%;
  padding: 0 1em;
  border-radius: 100px;
  margin: 0;
  font-family: Work Sans;
  font-style: normal;
  font-weight: ${({ selected }) => (selected ? '600' : '400')};
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
`;
export const PointFooter = styled.div`
  position: absolute;
  justify-content: flex-end;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 147px;
  padding: 32px;
  box-sizing: border-box;
  background-image: url(${PointBackground});
`;
export const Points = styled.div`
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;

  color: ${colors.neutral.white};
`;
export const Engagement = styled.div`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;

  color: ${colors.neutral.white};
`;
export const Arrow = styled.img`
  margin-left: 0.5em;
`;
export const StyledLogo = styled.img`
  margin-right: 8px;
`;
export const Title = styled.div`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;

  color: ${colors.brand.primary};
  margin: 21px auto 21px 48px;
`;
export const ToggleSpacer = styled.div`
  height: 32px;
`;

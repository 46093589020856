import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import PortalContainer from 'components/PortalContainer';
import {
  CustomTextInput,
  CustomTextArea,
  CustomButton,
  CustomSelect
} from 'components/NewLyncworkUIKit';

import {
  Container,
  CloseButton,
  Title,
  Label,
  Row,
  DayInput,
  Button,
  ErrorMessage
} from './styles';

const options = [
  {
    label: 'Event',
    value: 'EVENT'
  },
  {
    label: 'Action',
    value: 'ACTION'
  }
];

const TaskModalForm = ({ journeyTasksProps }) => {
  const {
    taskInfo,
    validationMessage,
    editTaskParams,
    saveTaskModal,
    closeTaskModal,
    deleteTask
  } = journeyTasksProps;
  const {
    taskEventName,
    taskDesc,
    taskType,
    startDay,
    endDay,
    order
  } = taskInfo;
  const selectedOption = options.find(option => option.value === taskType);
  const handleSelection = newOption => {
    editTaskParams(newOption.value, 'taskType');
  };
  const handleInputChange = e => {
    const { name, value } = e.target;
    editTaskParams(value, name);
  };
  const handleDeletion = () => deleteTask(order);
  return (
    <Container>
      <CloseButton onClick={closeTaskModal}>
        <CloseIcon />
      </CloseButton>
      <Title>Task Info</Title>
      <CustomTextInput
        required
        label="Name"
        value={taskEventName}
        name="taskEventName"
        onChange={handleInputChange}
        error={!!validationMessage.taskEventName}
        subLabel={validationMessage.taskEventName}
      />
      <CustomTextArea
        required
        label="Description"
        rows={4}
        value={taskDesc}
        name="taskDesc"
        onChange={handleInputChange}
        error={!!validationMessage.taskDesc}
        subLabel={validationMessage.taskDesc}
      />
      <Label>Task Type</Label>
      <CustomSelect
        isClearable={false}
        value={selectedOption}
        options={options}
        onChange={handleSelection}
      />
      <Row>
        <DayInput
          label="Start Day"
          value={startDay}
          name="startDay"
          onChange={handleInputChange}
          error={!!validationMessage.date}
        />
        <DayInput
          label="End Day"
          value={endDay}
          name="endDay"
          onChange={handleInputChange}
          error={!!validationMessage.date}
        />
      </Row>
      {validationMessage.date && (
        <ErrorMessage>{validationMessage.date}</ErrorMessage>
      )}
      <Row>
        {order !== null && (
          <CustomButton
            size="medium"
            variant="secondary"
            onClick={handleDeletion}
          >
            Delete
          </CustomButton>
        )}
        <CustomButton
          size="medium"
          variant="secondary"
          className="isCancel"
          onClick={closeTaskModal}
        >
          Cancel
        </CustomButton>
        <CustomButton size="medium" onClick={saveTaskModal}>
          Save
        </CustomButton>
      </Row>
    </Container>
  );
};

const TaskModal = ({ journeyTasksProps }) => {
  const { taskInfo } = journeyTasksProps;
  return (
    <PortalContainer isOpen={!!taskInfo}>
      <TaskModalForm journeyTasksProps={journeyTasksProps} />
    </PortalContainer>
  );
};

export default TaskModal;

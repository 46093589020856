import styled, { css } from 'styled-components';
import { grey } from './LyncworkColors';

export const H1 = styled.h1`
  font-weight: 500;
  margin: 0.5em 0 0.5em 0;
  font-size: 18px;
  line-height: 20px;
  ${props => props.style && props.style}
  ${(
    props //takes an optional prop that limits the amount of lines in
  ) =>
    `  -webkit-line-clamp: ${!!props.clamp ? props.clamp : '1'};
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;`}
`;
export const H2 = styled.h2`
  font-weight: 500;
  margin: 0;
  font-size: 16px;
  line-height: 18px;
  ${props =>
    `  -webkit-line-clamp: ${!!props.clamp ? props.clamp : '2'};
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;`}
`;

export const P = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  ${props =>
    !!props.clamp &&
    `  -webkit-line-clamp: ${props.clamp};
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;`}
`;
export const scrollBar = css`
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    position: absolute;
    background: transparent;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: ${grey};
      border-radius: 5px;
    }
  }
`;

export const multilineEllipsis = css`
  -webkit-line-clamp: ${props => props.lineClamp || 0};
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
`;
export const multilineEllipsisFunc = lineClamp => `
  -webkit-line-clamp: ${lineClamp || 0};
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
`;

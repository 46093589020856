import styled from 'styled-components';
import { grey, white, black } from 'StyleVariables/LyncworkColors';

export const EditorContainer = styled.div`
  position: relative;

  width: 100%;

  box-sizing: border-box;
  height: 100%;
`;
export const RemoveContainer = styled.div`
  display: flex;
  position: absolute;
  right: 20px;
  top: 20px;
  transition: all 0.3s;
  background-color: ${white}50;
  border: 1px solid ${black};
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: ${white};
    transform: scale(1.1);
  }
`;

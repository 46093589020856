import styled from 'styled-components';

export const AnnouncementHeading = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0;
  color: ${props => props.theme.colors.brand.primary};
  ${props => props.theme.typography.control};
  .MuiSvgIcon-root {
    font-size: 16px;
    margin-right: 4px;
  }
`;

export const AnnouncementContainer = styled.div`
  ${props => props.theme.typography.control};
  line-height: 24px;
  font-weight: 500;
  color: ${props => props.theme.colors.neutral.grey10};
  background: ${props => props.theme.colors.neutral.offWhite02};
  white-space: pre-line;
  padding: 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  .MuiCircularProgress-root {
    margin: 36px;
    align-self: center;
  }
`;

export const AnnouncementDate = styled.span`
  ${props => props.theme.typography.body};
  font-weight: 500;
`;

export const ButtonContainer = styled.div`
  margin: 12px 0 0;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

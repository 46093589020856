import styled from 'styled-components';
import Card from 'components/Card';
import { CustomTextInput, IconButton } from 'components/NewLyncworkUIKit';
import { scrollBar } from 'StyleVariables/StandardComponents';

export const Container = styled(Card)`
  position: relative;
  width: 600px;
  max-width: calc(100% - 40px);
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FormContainer = styled.div`
  position: relative;
  max-height: 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow-y: scroll;
  ${scrollBar}
`;

export const Title = styled.h1`
  ${props => props.theme.typography.header2};
  font-weight: 600;
  flex-basis: 100%;
  margin: 0 0 8px;
`;

export const SectionTitle = styled.h2`
  margin: 16px 0 8px;
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.neutral.grey05};
  flex-basis: 100%;
`;

export const TextInput = styled(CustomTextInput)`
  flex-basis: ${props => (props.fullWidth ? '100%' : 'calc(50% - 12px)')};
  margin: 0 0;
`;

export const Label = styled.span`
  ${props => props.theme.typography.control};
  margin: 8px 0;
  font-weight: 600;
  span {
    color: ${props => props.theme.colors.feedback.error};
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: calc(50% - 12px);
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 36px 0 0;
  button {
    margin: 0 4px;
    width: 126px;
  }
`;

export const CloseButton = styled(IconButton)`
  width: 18px;
  height: 18px;
  font-size: 18px;
  position: absolute;
  right: 24px;
  top: 24px;
  .MuiSvgIcon-root {
    font-size: inherit;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ doRender }) => (doRender ? '40px 0 64px' : '10px 0 10px')};
`;

export const Header = styled.h3`
  ${props => props.theme.typography.header2};
  font-weight: 600;
  margin: 0;
`;

export const Description = styled.span`
  ${props => props.theme.typography.header3};
  margin: 8px 0 40px;
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

import styled from 'styled-components';
import {
  // multilineEllipsisFunc,
  scrollBar
} from 'StyleVariables/StandardComponents';

import { Avatar } from 'components/NewLyncworkUIKit';

export const Container = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  width: 365px;
  height: 600px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow-y: auto;
  ${scrollBar} /* ... */
  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const StyledAvatar = styled(Avatar)`
  width: 96px;
  height: 96px;
`;

import React, { useEffect, useState } from 'react';
import getPageData from './getPageData';
import {
  useAuthentication,
  useAuthenticationActions
} from 'hooks/useAuthentication';
import { useAccountSetupActions } from 'hooks/useAccountSetupActions';
import { AuthenticationService } from 'services/authentication.service';
import { UserService } from 'services/user.service';
import translatePermissions from 'utilities/translatePermissions';
import jwt_decode from 'jwt-decode';
import LoadingScreen from 'components/LoadingScreen';
import { Wizard } from 'pages/Onboarding/common';
import { useCookies } from 'react-cookie';

const AdminSetup = () => {
  const { user } = useAuthentication();
  const [pageData, setPageData] = useState(getPageData(user));

  return <Wizard pageData={pageData} onPageDataChange={setPageData} />;
};

export default AdminSetup;

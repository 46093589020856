import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { UserService } from 'services/user.service';
import { CMSService } from 'services/cms.service';

import useAuthentication, {
  useAuthenticationActions
} from 'hooks/useAuthentication';

import { IceBreakers, WhoSaidWhat } from './components';
import { Container, ButtonContainer, CustomButton } from './styles';

const searchParams = {
  name: '',
  page: 0,
  size: '100',
  sort: 'firstName',
  direction: 'ASC',
  roleId: ''
};
const { name, page, size, sort, direction, roleId } = searchParams;

const getUserFormData = user => {
  const descriptions = user.userDescription?.descriptions || [];
  const [word1 = '', word2 = '', word3 = ''] = descriptions;
  const formData = {
    word1,
    word2,
    word3
  };
  return formData;
};

const UserWelcomeIceBreaker = ({ prevPage, nextPage }) => {
  const { user } = useAuthentication();
  const [teamMembers, setTeamMembers] = useState([]);
  const { setGetUser } = useAuthenticationActions();
  const teamId = user?.teams?.[0]?.id;
  const { register, handleSubmit, formState } = useForm({
    defaultValues: getUserFormData(user)
  });
  const { isDirty: isFormModified } = formState;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const {
          data: { content }
        } = await UserService.companyDirectory({
          name,
          page,
          size,
          sort,
          direction,
          roleId,
          teamId
        });
        const formattedTeamMembers = content.filter(currentUser => {
          if (currentUser.id === user.id) {
            return false;
          }

          const hasWords = currentUser.userDescription?.descriptions?.length;
          return hasWords;
        });

        setTeamMembers(formattedTeamMembers);
      } catch (error) {
        console.log(error);
      }
    };

    if (teamId) {
      fetchData();
    }
  }, []);

  const onSubmit = async (formData, e) => {
    e.preventDefault();
    if (!isFormModified) {
      nextPage();
      return;
    }
    const { word1, word2, word3 } = formData;
    const descriptions = [word1, word2, word3]
      .map(a => a.trim())
      .filter(a => !!a);
    const response = await CMSService.updateUser({
      ...user,
      userDescription: { descriptions }
    });
    const userResponse = response.data;
    const updatedUserObject = {
      ...user,
      ...userResponse
    };
    setGetUser(updatedUserObject);
    nextPage();
  };
  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <IceBreakers register={register} />
      {teamMembers.length > 0 && (
        <WhoSaidWhat user={user} teamMembers={teamMembers} />
      )}
      <ButtonContainer>
        <CustomButton variant="secondary" onClick={prevPage} type="button">
          Back
        </CustomButton>
        <CustomButton type="submit">Next</CustomButton>
      </ButtonContainer>
    </Container>
  );
};

export default UserWelcomeIceBreaker;

import React from 'react';
import { Column, Cell } from './styles';
import HeaderRow from './HeaderRow';
import Row from './Row';
import TimeLineGrid from './TimeLineGrid';
const defaultNumberOfWeeks = 8;

const getNumberOfWeeks = journeyTasks => {
  if (journeyTasks.length < 1) {
    return defaultNumberOfWeeks;
  }
  const endDays = journeyTasks.map(({ task }) => task.endDay);
  const maxDay = Math.max(...endDays);
  const maxWeek = Math.ceil(maxDay / 7);
  return Math.max(maxWeek, defaultNumberOfWeeks);
};

const TaskColumn = ({ journeyTasks, openTaskModal, changeTaskDay }) => {
  const numberOfWeeks = getNumberOfWeeks(journeyTasks);
  return (
    <Column numberOfWeeks={numberOfWeeks}>
      <HeaderRow numberOfWeeks={numberOfWeeks} />
      <TimeLineGrid numberOfWeeks={numberOfWeeks} />
      {journeyTasks.map(journeyTask => {
        const { order } = journeyTask;
        const editTask = () => openTaskModal(journeyTask);
        return (
          <Row
            numberOfWeeks={numberOfWeeks}
            key={order}
            journeyTask={journeyTask}
            changeTaskDay={changeTaskDay}
            editTask={editTask}
          />
        );
      })}
    </Column>
  );
};

export default TaskColumn;

import React from 'react';
import styled from 'styled-components';
import error from 'assets/images/error.svg';
import { useHistory } from 'react-router-dom';
import {
  Container,
  TextContainer,
  Title,
  Description,
  ButtonContainer,
  Button
} from './styles';

const NotFound404Page = () => {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };
  return (
    <Container>
      <img src={error} alt="asd" />
      <TextContainer>
        <Title>Hmm...</Title>
        <Description>
          We couldn’t find what you’re looking for. Let’s find a better place
          for you to go.
        </Description>
        <ButtonContainer>
          <Button onClick={goBack}>Go Back</Button>
        </ButtonContainer>
      </TextContainer>
    </Container>
  );
};

export default NotFound404Page;

import { LicenceService } from 'services';

export const getRoleData = roles => {
  const licenseInfo = {};
  const appExists = {};
  const applications = [];
  roles.forEach(({ licenseManagements, applications: roleApps }) => {
    roleApps.forEach(app => {
      if (!appExists[app.id] && app.id !== 6) {
        appExists[app.id] = true;
        applications.push(app);
      }
    });
    licenseManagements.forEach(license => {
      const { appAPIName } = license.applicationLicenseInformation.application;
      const oldLicense = licenseInfo[appAPIName];
      if (!oldLicense) {
        licenseInfo[appAPIName] = license;
      } else if (oldLicense.licenseOrder < license.licenseOrder) {
        licenseInfo[appAPIName] = license;
      }
    });
  });

  return { applications, licenseInfo };
};

export const getLicenseDetails = async (roleData, licenseDetail = {}) => {
  const { applications, licenseInfo } = roleData;
  const newLicenseDetails = { ...licenseDetail };
  for (let { appAPIName } of applications) {
    const currentLicense = licenseInfo[appAPIName];
    if (!currentLicense) {
      continue;
    }
    const {
      applicationLicenseInformation: { licenseId },
      subscriptionApp: { licenseManagements }
    } = currentLicense;
    for (let license of licenseManagements) {
      const {
        defaultLicense,
        applicationLicenseInformation: { licenseId: currentLicenseId }
      } = license;
      if (newLicenseDetails[currentLicenseId]) {
        continue;
      }
      const idMatches = currentLicenseId === licenseId;
      if (defaultLicense || idMatches) {
        const { data: licenseData } = await LicenceService.getDetail(
          appAPIName,
          currentLicenseId
        );
        newLicenseDetails[currentLicenseId] = licenseData;
      }
    }
  }
  return newLicenseDetails;
};

const appListSubtract = (list, listToSubtract) => {
  const shouldSubtract = {};
  listToSubtract.forEach(app => (shouldSubtract[app.appAPIName] = true));
  return list.filter(app => !shouldSubtract[app.appAPIName]);
};

const getLicenseId = license => {
  return license.applicationLicenseInformation.licenseId;
};

const getDefaultLicense = license => {
  const appDefaultLicense = license.subscriptionApp.licenseManagements.find(
    ({ defaultLicense }) => defaultLicense
  );
  return appDefaultLicense;
};

const getLicenseAssignmentChange = (
  newApps,
  newLicenseInfo,
  oldLicenseInfo,
  licenseDetail
) => {
  const appsWithIntendedLicenseChange = newApps.filter(({ appAPIName }) => {
    const newLicense = newLicenseInfo[appAPIName];
    const oldLicense = oldLicenseInfo[appAPIName];
    if (!newLicense) {
      //app doesn't need license
      return false;
    }
    if (!oldLicense) {
      //newly added app that requires license
      return true;
    }
    return oldLicense.id !== newLicense.id;
  });

  const isLicenseAvailable = license => {
    if (!license) {
      return false;
    }
    const licenseId = getLicenseId(license);
    return licenseDetail[licenseId]?.accountLicensesAvailable > 0;
  };

  const getAssignedLicense = (
    newIntendedLicense,
    oldLicense,
    defaultLicense
  ) => {
    const isIntendedLicenseAvailable = isLicenseAvailable(newIntendedLicense);
    if (isIntendedLicenseAvailable) {
      return newIntendedLicense;
    }
    if (oldLicense) {
      return oldLicense;
    }
    const isDefaultLicenseAvailable = isLicenseAvailable(defaultLicense);
    if (isDefaultLicenseAvailable) {
      return defaultLicense;
    }
    return null;
  };

  const appsWithLicenseInfo = appsWithIntendedLicenseChange.map(application => {
    const { appAPIName } = application;
    const newIntendedLicense = newLicenseInfo[appAPIName];
    const oldLicense = oldLicenseInfo[appAPIName];
    const defaultLicense = getDefaultLicense(newIntendedLicense);
    const assignedLicense = getAssignedLicense(
      newIntendedLicense,
      oldLicense,
      defaultLicense
    );
    return {
      application,
      newIntendedLicense,
      oldLicense,
      assignedLicense,
      defaultLicense
    };
  });

  const appsWithLicenseChanged = appsWithLicenseInfo.filter(
    ({ assignedLicense, oldLicense }) => {
      return assignedLicense?.id !== oldLicense?.id;
    }
  );

  const appsWithLicenseIssues = appsWithLicenseInfo.filter(
    ({ assignedLicense, newIntendedLicense }) => {
      return assignedLicense?.id !== newIntendedLicense?.id;
    }
  );

  return { appsWithLicenseChanged, appsWithLicenseIssues };
};

export const compare = (oldRoleData, newRoleData, licenseDetail) => {
  const { applications: oldApps, licenseInfo: oldLicenseInfo } = oldRoleData;
  const { applications: newApps, licenseInfo: newLicenseInfo } = newRoleData;
  const applicationsDeleted = appListSubtract(oldApps, newApps);
  const applicationsAdded = appListSubtract(newApps, oldApps);

  const {
    appsWithLicenseChanged,
    appsWithLicenseIssues
  } = getLicenseAssignmentChange(
    newApps,
    newLicenseInfo,
    oldLicenseInfo,
    licenseDetail
  );

  const applicationChanges = {
    applicationsDeleted,
    applicationsAdded,
    appsWithLicenseChanged,
    appsWithLicenseIssues
  };

  const hasAppsDeleted = applicationsDeleted.length > 0;
  const hasAppsAdded = applicationsAdded.length > 0;
  const hasAppsModified = appsWithLicenseChanged.length > 0;
  const hasLicenseIssues = appsWithLicenseIssues.length > 0;

  const displayPreflight =
    hasAppsDeleted || hasAppsAdded || hasAppsModified || hasLicenseIssues;

  return {
    applicationChanges,
    displayPreflight
  };
};

import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';

import PortalContainer from 'components/PortalContainer';
import { CustomTextArea, CustomButton } from 'components/NewLyncworkUIKit';
import { useButtonTrack } from 'matomo/hooks';
import { companyAboutEvents } from 'pages/CompanyInfo/instrumentationEvents';

import {
  ModalContainer,
  SectionTitle,
  ButtonContainer,
  CloseButton
} from './styles';

const getInitialState = companyInfo => {
  const { companyDesc } = companyInfo;
  const { about, ourMission } = companyDesc || {};
  return { about, ourMission };
};

const { save_changes, discard_changes } = companyAboutEvents;
const ModalContent = ({ closeModal, companyInfo, updateCompany }) => {
  const trackButton = useButtonTrack();
  const [loading, setLoading] = useState(false);
  const [{ about, ourMission }, setCompanyAbout] = useState(
    getInitialState(companyInfo)
  );

  const handleInputChange = e => {
    const { name, value } = e.target;
    setCompanyAbout(old => ({ ...old, [name]: value }));
  };

  const handleSubmit = async () => {
    trackButton(save_changes);
    setLoading(true);
    try {
      const newCompanyInfo = {
        ...companyInfo,
        companyDesc: { about, ourMission }
      };
      await updateCompany(newCompanyInfo);
      closeModal();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDiscard = () => {
    trackButton(discard_changes);
    closeModal();
  };

  return (
    <ModalContainer onClick={e => e.stopPropagation()}>
      <SectionTitle>About</SectionTitle>
      <CustomTextArea
        rows={5}
        value={about}
        name="about"
        onChange={handleInputChange}
      />
      <SectionTitle>Our Mission</SectionTitle>
      <CustomTextArea
        rows={5}
        value={ourMission}
        name="ourMission"
        onChange={handleInputChange}
      />
      <ButtonContainer>
        <CustomButton
          variant="secondary"
          onClick={handleDiscard}
          disabled={loading}
        >
          Cancel
        </CustomButton>
        <CustomButton onClick={handleSubmit} disabled={loading}>
          {loading ? <CircularProgress color="inherit" size="16px" /> : 'Save'}
        </CustomButton>
      </ButtonContainer>
      <CloseButton onClick={closeModal} />
    </ModalContainer>
  );
};

const CompanyAboutEditModal = ({
  isOpen,
  closeModal,
  companyInfo,
  updateCompany
}) => {
  return (
    <PortalContainer handleClickOutside={closeModal} isOpen={isOpen}>
      <ModalContent
        closeModal={closeModal}
        companyInfo={companyInfo}
        updateCompany={updateCompany}
      />
    </PortalContainer>
  );
};

export default CompanyAboutEditModal;

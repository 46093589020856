import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress';

import LoadingScreen from 'components/LoadingScreen';
import PageTransitionBlock from 'components/PageTransitionBlock';
import { CustomRTE, CustomButton } from 'components/NewLyncworkUIKit';

import useToast from 'hooks/useToast';

import { useButtonTrack } from 'matomo/hooks';
import PostParser from 'pages/Posts/PostParser';
import { CMSService } from 'services';

import PreviewModal from '../../PreviewModal';
import { adminOnboardingWelcomePageEvents } from '../../instrumentationEvents';
import {
  ButtonContainer,
  ButtonSpacer,
  RTEContainer,
  ErrorP,
  Container
} from './styles';

const {
  save_post,
  open_preview,
  close_preview
} = adminOnboardingWelcomePageEvents;

const Welcome = () => {
  const toastId = useRef(null);
  const { createToast, dismissToast } = useToast();
  const trackButton = useButtonTrack();
  const [loading, setLoading] = useState(true);
  const [metaData, setMetaData] = useState(null);
  const [previewing, setPreviewing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [changed, setChanged] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [initialImages, setInitialImages] = useState([]);
  const [newImages, setNewImages] = useState([]);

  let history = useHistory();
  let { id } = useParams();
  useEffect(() => {
    return () => dismissToast(toastId.current);
  }, [dismissToast]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await CMSService.getCustomOnboarding();
        const content = data.content;
        setInitialImages(data.medias);
        let newContent = content;
        let imgIdArray = [];
        //create array of ids, push id after replace into array, if id is not included, must be thumb
        //if
        data.medias.forEach(media => {
          const isIncluded = content.includes(`lw-img-id:${media.id}`);
          //replace all uploaded image src's with the correct url
          newContent = newContent.replace(`lw-img-id:${media.id}`, media.url);
          isIncluded && imgIdArray.push(media.id);
        });
        setMetaData({ ...data, content: newContent });
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    })();
  }, []);

  const handleOpenPreview = () => {
    trackButton(open_preview);
    setPreviewing(true);
  };

  const handleClosePreview = () => {
    trackButton(close_preview);
    setPreviewing(false);
  };

  const handleBlockChange = x => {
    setMetaData({ ...metaData, content: x });
    setChanged(true);
  };
  const addImage = newImage => {
    setNewImages(oldNewImages => {
      return [...oldNewImages, newImage];
    });
  };
  const submitFunction = async () => {
    trackButton(save_post);
    setSubmitted(true);

    const { content } = metaData;
    const title = metaData.content.split('</h1>')[0].split('>')[1];
    const publishErrors =
      !title?.length || title === '&nbsp;' || !content?.length;
    const errors = publishErrors;

    if (!errors) {
      setSaving(true);

      let newMetaData = metaData;

      const allImages = [...initialImages, ...newImages]; //all Images
      for (const img of allImages) {
        const url = img.url;
        if (content.includes(url)) {
          //if the image is not the thumbnail, is the image still in the doc?
          if (!!img.file) {
            //is it a new image?
            const { data } = await CMSService.uploadMedia(img.file);
            newMetaData = {
              ...newMetaData,
              medias: [...newMetaData.medias, data[0].id],
              content: newMetaData.content.replace(
                url,
                `lw-img-id:${data[0].id}`
              )
            };
          } else if (img.id) {
            //if it is a previously updated image
            newMetaData = {
              ...newMetaData,
              medias: [...newMetaData.medias, img.id],
              content: newMetaData.content.replace(url, `lw-img-id:${img.id}`)
            };
          } else {
            console.log('exception occurred, look into it! -from lucas', img);
          }
        } else {
          //the image is no longer in the doc
          if (img.id) {
            newMetaData = {
              ...newMetaData,
              medias: newMetaData.medias.filter(x => x.id !== img.id)
            };
            await CMSService.deleteMedia(img.id);
          } else {
            console.log(
              'This image was added, then deleted before uploading',
              img
            );
          }
        }
      }

      //format medias
      let contentMedias = newMetaData.medias.map(media => {
        if (media.id && media.id !== null) {
          return media.id;
        } else {
          return media;
        }
      });
      //non id's are sneaking into the medias array. lets remove those
      contentMedias = contentMedias.filter(item => typeof item === 'number');
      //found duplicates in test, using filter to remove them
      contentMedias = contentMedias.filter(
        (item, index) => contentMedias.indexOf(item) === index
      );

      const postData = {
        ...newMetaData,
        title,
        medias: contentMedias
      };

      try {
        setSaving(true);
        await CMSService.postCustomOnboarding(postData);
        toastId.current = createToast({
          size: 'small',
          message: 'Page edits saved',
          linkText: 'View Preview',
          linkAction: handleOpenPreview
        });
        setSaving(false);
        setChanged(false);
      } catch (err) {
        setSaving(false);
      }
    } else {
      setMetaData({ ...metaData, title });
    }
  };
  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Container>
      <RTEContainer>
        {metaData.content.length < 1 && submitted && (
          <ErrorP>Welcome article must have content</ErrorP>
        )}
        <CustomRTE
          data={metaData.content}
          callback={e => handleBlockChange(e)}
          addImage={addImage}
        />
      </RTEContainer>

      <ButtonContainer>
        <CustomButton
          onClick={handleOpenPreview}
          variant="secondary"
          width="160px"
        >
          Preview
        </CustomButton>
        <ButtonSpacer />
        <CustomButton onClick={submitFunction} width="160px">
          {saving ? <CircularProgress color="inherit" size="20px" /> : 'Save'}
        </CustomButton>
      </ButtonContainer>
      <PreviewModal
        isOpen={previewing}
        handleClose={handleClosePreview}
        previewTitle="Onboarding Welcome Page"
        pathName="/user/welcome"
      >
        <RTEContainer>
          <PostParser content={metaData.content} onboarding />
        </RTEContainer>
      </PreviewModal>
      <PageTransitionBlock when={changed} />
    </Container>
  );
};
export default Welcome;

import React from 'react';
import { CustomButton } from 'components/NewLyncworkUIKit';

const SuspendButton = ({ suspendInfo }) => {
  const { userId, status, suspendFunction } = suspendInfo;
  console.log(suspendInfo);
  if (status && status?.toLowerCase() !== 'pending_activation') {
    return (
      <CustomButton
        buttonStyle="alternative"
        size="large"
        onClick={() => suspendFunction()}
        styles={{ marginLeft: '8px', width: '82px' }}
      >
        {`${status.toLowerCase() === 'suspended' ? 'Restore' : 'Suspend'}`}
      </CustomButton>
    );
  } else {
    return null;
  }
};
export default SuspendButton;

import styled from 'styled-components';

export const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: 5fr 6fr 10fr;
  gap: 16px;
  margin: 24px 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  button.discard {
    margin: 0 8px 0 auto;
  }
`;

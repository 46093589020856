import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import jwt_decode from 'jwt-decode';

import env from 'env';

import { AuthenticationService } from 'services/authentication.service';

import CustomButton from 'components/NewLyncworkUIKit/CustomButton';

import useAppLicenceInfo from 'hooks/useAppLicenceInfo';

import useIntegrationDetails from './useIntegrationDetails';

import { FormContainer, ButtonContainer } from './styles';
import IntegrationDetails from './IntegrationDetails';
import ApplicationLicences from './ApplicationLicences';
import NavBar from './NavBar';

const SubscriptionForm = ({
  licenseManagements = [],
  application,
  properties = [],
  handleSave,
  handleCancel
}) => {
  const {
    id: appId,
    appAPIName,
    appOauthAPI,
    authenticationRequired
  } = application;
  const {
    loading,
    formError,
    formValue,
    formLabels,
    fieldNames,
    enableEmailProviderToggle,
    onInputChange,
    handleToggle,
    licenses,
    canManageLicenses,
    showLicensesTab,
    switchTab,
    onDragEnd,
    toggleLicense,
    onSubmit
  } = useIntegrationDetails({
    application,
    properties,
    handleSave,
    licenseManagements
  });
  const licenceInfo = useAppLicenceInfo(appAPIName, licenseManagements);

  const authApplication = async e => {
    e.preventDefault();
    const authToken = AuthenticationService.getToken();
    const tenant = jwt_decode(authToken).tenant.toLowerCase();
    const newHref = `${env.frontDoorDomain}${appOauthAPI}/${tenant}`;
    window.open(newHref, '_newtab');
  };

  return (
    <>
      {canManageLicenses && (
        <NavBar showLicensesTab={showLicensesTab} switchTab={switchTab} />
      )}
      <FormContainer onSubmit={onSubmit}>
        {showLicensesTab ? (
          <ApplicationLicences
            licenses={licenses}
            onDragEnd={onDragEnd}
            toggleLicense={toggleLicense}
            licenceInfo={licenceInfo}
          />
        ) : (
          <IntegrationDetails
            appId={appId}
            fieldNames={fieldNames}
            formValue={formValue}
            formLabels={formLabels}
            formError={formError}
            onInputChange={onInputChange}
            handleToggle={handleToggle}
            enableEmailProviderToggle={enableEmailProviderToggle}
            authenticationRequired={authenticationRequired}
            authApplication={authApplication}
          />
        )}
        <ButtonContainer>
          <CustomButton variant="secondary" onClick={handleCancel}>
            Cancel
          </CustomButton>
          <CustomButton type="submit" disabled={loading}>
            {loading ? (
              <CircularProgress size="20px" color="inherit" />
            ) : (
              'Save'
            )}
          </CustomButton>
        </ButtonContainer>
      </FormContainer>
    </>
  );
};

export default SubscriptionForm;

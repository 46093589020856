import styled from 'styled-components';
import Card from 'components/Card';
import { multilineEllipsis } from 'StyleVariables/StandardComponents';

export const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 24px;

  grid-row: 1/3;
  grid-column: 2/3;

  .MuiCircularProgress-root {
    margin: auto;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 0 24px 0;
  h1 {
    margin: 0;
    ${props => props.theme.typography.header2};
    color: ${props => props.theme.colors.neutral.grey10};
    font-weight: 600;
  }

  a {
    text-decoration: none;
  }
`;

export const ListContainer = styled.div`
  margin: 24px 0 0;
  display: flex;
  flex-direction: column;
`;

export const UserCardContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 72px;
  padding: 12px 0;

  display: flex;
  align-items: center;
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.neutral.grey10};
  border-bottom: 1px solid ${props => props.theme.colors.neutral.grey05};
  font-weight: 600;

  .avatar {
    width: 48px;
    height: 48px;
    margin-right: 16px;
  }

  .text-container {
    height: 48px;
    display: flex;
    flex-direction: column;
    .detail {
      ${props => props.theme.typography.control};
      color: ${props => props.theme.colors.neutral.grey07};

      font-weight: 400;
    }
  }
`;

import axios from 'axios';
import env from '../env';

const baseUrl = `${env.frontDoorDomain}/services/cms/api/announcements`;
//https://api.lync.work/services/cms/api/medias
export const AnnouncementService = {
  list: ({ page = '0', size = '10', sort = 'updated_at,desc' }) => {
    const searchParams = new URLSearchParams({
      page,
      size,
      sort
    });
    const queryString = searchParams.toString();
    return axios.get(`${baseUrl}?${queryString}`);
  },
  create: announcement => {
    return axios.post(`${baseUrl}`, announcement);
  }
};

import { Toaster, ToastBar } from 'react-hot-toast';
import styled from 'styled-components';

const ToasterWrapper = props => (
  <Toaster
    containerClassName={props.className}
    toastOptions={{
      ...props.toastOptions,
      duration: 5000,
      className: 'lw-toast'
    }}
    {...props}
  />
);

const StyledToaster = styled(ToasterWrapper)`
  .lw-toast {
    background-color: ${props => props.theme.colors.neutral.grey08};
    padding: 12px 6px;
    border-radius: 4px;
  }
`;

const LWToaster = () => <StyledToaster position="bottom-right" />;

export default LWToaster;

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import useApplicationCategories from 'hooks/useApplicationCategories';
import useApplications from 'hooks/useApplications';

import Button from 'components/NewLyncworkUIKit/CustomButton';

import { NavBar, ApplicationCard, FeaturedApps } from './components';
import {
  Container,
  BreadCrumb,
  ContentContainer,
  AppCategoryButtonContainer,
  ListContainer
} from './styles';

const filterApplications = (apps, category) => {
  if (category === 'All') {
    return apps;
  }

  const filteredApps = apps.filter(({ categories }) => {
    const hasCategory = categories.some(({ name }) => name === category);
    return hasCategory;
  });
  return filteredApps;
};

const ApplicationBrowse = ({ applicationCategories }) => {
  const history = useHistory();
  const [selectedCategory, setSelectedCategory] = useState('All');
  const { applications } = useApplications({
    initialSize: 30,
    getSubscriptionId: true
  });

  const [value, setValue] = useState('');
  const onChange = e => setValue(e.target.value);

  useEffect(() => {
    let timeout = null;
    if (value) {
      timeout = setTimeout(() => {
        history.push('/admin-applications/search', {
          searchValue: value
        });
      }, 500);
    }
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [value]);

  const filteredApps = filterApplications(applications, selectedCategory);

  return (
    <Container>
      <BreadCrumb>Applications</BreadCrumb>
      <NavBar value={value} onChange={onChange} />
      <ContentContainer>
        <FeaturedApps />
        <AppCategoryButtonContainer>
          {applicationCategories.map(({ id, name }) => {
            const selected = name === selectedCategory;
            const variant = selected ? 'primary' : 'secondary';
            const onClick = () => setSelectedCategory(name);
            return (
              <Button variant={variant} size="small" key={id} onClick={onClick}>
                {name}
              </Button>
            );
          })}
        </AppCategoryButtonContainer>
        <ListContainer>
          {filteredApps.map(app => (
            <ApplicationCard app={app} key={app.id} />
          ))}
        </ListContainer>
      </ContentContainer>
    </Container>
  );
};

export default ApplicationBrowse;

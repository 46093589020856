import styled from 'styled-components';
import CustomButton from 'components/NewLyncworkUIKit/CustomButton';

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 24px;
  box-sizing: border-box;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: ${props => (props.showBackground ? '#3a3a3b90' : 'transparent')};
`;

export const PromptContainer = styled.div`
  position: fixed;
  padding: 16px;
  box-sizing: border-box;
  width: calc(100% - 48px);
  display: flex;
  flex-direction: column;
  color: white;
  background: black;
  border-radius: 4px;
  ${props => props.theme.typography.control};
  p {
    margin: 8px 0 16px;
  }
  img {
    position: absolute;
    bottom: 70%;
    width: 100%;
    height: auto;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    margin: 0;
    font-weight: 600;
  }
  .close-button {
    color: white;
  }
  .step-counter {
    color: ${props => props.theme.colors.neutral.grey02};
  }
`;

export const NextButton = styled(CustomButton)`
  color: ${props => props.theme.colors.neutral.grey10};
  background-color: ${props => props.theme.colors.neutral.white};
  &:hover:enabled {
    background-color: ${props => props.theme.colors.neutral.white};
  }
`;

export const UnmaskedElement = styled.div`
  position: fixed;
  border-radius: 8px;
  box-shadow: 0 0 0 1000000px #3a3a3b90;
  cursor: ${props => (props.clickElement ? 'pointer' : 'unset')};
`;

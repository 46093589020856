import React from 'react';
import styled from 'styled-components';

import UnderlineLink from 'components/UnderlineLink';
const Container = styled.div`
  display: flex;
  gap: 24px;
`;

const NavBar = ({ showLicensesTab, switchTab }) => {
  return (
    <Container>
      <UnderlineLink to="#" selected={!showLicensesTab} onClick={switchTab}>
        Integration
      </UnderlineLink>
      <UnderlineLink to="#" selected={showLicensesTab} onClick={switchTab}>
        Licences
      </UnderlineLink>
    </Container>
  );
};

export default NavBar;

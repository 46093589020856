import styled, { css } from 'styled-components';
import { colors } from 'designSystem/foundations';
import { multilineEllipsis } from 'StyleVariables/StandardComponents';
import CheckMark from 'components/CheckMark';

const {
  brand: { primary },
  neutral: { grey10, grey08, grey07 }
} = colors;
export const Container = styled.div`
  box-sizing: border-box;
  background: white;
  padding: 16px 24px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin: 8px 0;
`;

const header1 = css`
  ${props => props.theme.typography.header1};
`;
const header2 = css`
  ${props => props.theme.typography.header2};
`;

export const Title = styled.h1`
  margin: 4px 0;
  ${multilineEllipsis};
`;

Title.defaultProps = { isJourney: false, lineClamp: 1 };

export const JourneyTitle = styled(Title)`
  ${header1};
  font-weight: 600;

  color: ${primary};
`;

export const TaskTitle = styled(Title)`
  ${header2};
  flex-basis: calc(100% - 160px);
  font-weight: 600;
  color: ${grey10};
`;

export const Description = styled.p`
  ${props => props.theme.typography.control};
  color: ${props => (props.isJourney ? grey08 : grey07)};
  margin: 0 0 24px;
`;

Description.defaultProps = { isJourney: false };

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MetaData = styled.span`
  ${props => props.theme.typography.metadata};
  color: ${props => props.theme.colors.neutral.grey05};
  font-weight: 500;
  &.bold {
    color: ${props => props.theme.colors.neutral.grey07};
    font-weight: 600;
  }
`;

export const ProgressBar = styled.div`
  position: relative;
  width: 100%;
  height: 8px;
  border-radius: 4px;
  margin: 4px 0 24px;
  background-color: ${props => props.theme.colors.neutral.grey02};

  &:after {
    content: '';
    position: absolute;
    border-radius: 4px;
    height: 100%;
    width: ${props => props.barWidth};
    background-color: ${props => props.theme.colors.brand.primary};
    transition: all 0.3s ease-in-out;
  }
`;

export const CompletedBox = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  ${props => props.theme.typography.control};
  font-weight: 600;
  margin: 0 0 12px;
  color: ${grey10};
`;

export const StyledCheckMark = styled(CheckMark)`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  .role-discard {
    margin-left: auto;
  }
`;

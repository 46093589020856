import { createStore } from 'redux';
import { persistStore } from 'redux-persist';

import rootReducer from './rootReducer.js';

const store = createStore(rootReducer);
const persistor = persistStore(store);

export { store, persistor };
//This file is for the store, and only the store.

import React, { useState } from 'react';
import { fileSizeLimit } from 'pages/Documents/constant';

import PortalContainer from 'components/PortalContainer';

import FileEditContent from './index';
import { UserSelectionModal } from './UserSelectionModal';

const getInitialState = file => {
  const fileCategory = file?.fileCategory || '';
  const description = file?.description || '';
  const signingTitle = file?.signingTitle || '';
  {
    fileCategory, description, signingTitle;
  }
  return { fileCategory, description, signingTitle };
};

const FileEditModal = ({
  fileToEdit,
  closeEditModal,
  updateMetadata,
  downloadFile,
  deleteFile
}) => {
  const [nextStage, setNextStage] = useState(false);
  const [fileState, setFileState] = useState({});
  const [fileSizeError, setFileSizeError] = useState(false);
  const [draft, setDraft] = useState(getInitialState(fileToEdit));
  const [group, setGroup] = useState(
    fileToEdit?.externalGroupId === 'ONBOARDING'
  );

  const handleNextStage = (draftVal, groupVal) => {
    setDraft(draftVal);
    setGroup(groupVal);
    setNextStage(true);
  };

  const handleCloseModal = () => {
    setNextStage(false);
    closeEditModal();
  };

  const uploadCallback = () => {
    setNextStage(false);
  };

  const handleFileInputChange = e => {
    const [file] = e.target.files;
    setFileSizeError(false);
    const { size } = file;
    if (size > fileSizeLimit) {
      setFileSizeError(true);
      return;
    }
    setFileState({
      file: e.target.files[0],
      fileName: e.target.files[0].name
    });
  };

  return (
    <PortalContainer
      isOpen={!!fileToEdit}
      handleClickOutside={handleCloseModal}
    >
      {!nextStage ? (
        <FileEditContent
          file={fileToEdit}
          fileState={fileState}
          handleFileInputChange={handleFileInputChange}
          fileSizeError={fileSizeError}
          setFileSizeError={setFileSizeError}
          setFileState={setFileState}
          closeEditModal={closeEditModal}
          updateMetadata={updateMetadata}
          downloadFile={downloadFile}
          deleteFile={deleteFile}
          handleNextStage={handleNextStage}
        />
      ) : (
        <UserSelectionModal
          file={fileToEdit}
          fileState={fileState}
          setFileState={setFileState}
          uploadCallback={uploadCallback}
          draft={draft}
          group={group}
          closeEditModal={closeEditModal}
          updateMetadata={updateMetadata}
          downloadFile={downloadFile}
          deleteFile={deleteFile}
        />
      )}
    </PortalContainer>
  );
};

export default FileEditModal;

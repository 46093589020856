import styled from 'styled-components';
import { scrollBar } from 'StyleVariables/StandardComponents';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`;

export const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  gap: 16px;
  padding: 16px 0 24px;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.grey02};
  .search-input {
    flex-grow: 1;
  }
`;

export const EmptyContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  p {
    margin: 0;
    ${props => props.theme.typography.body};
    color: ${props => props.theme.colors.neutral.grey07};
  }
  img {
    width: 112px;
    height: auto;
    object-fit: contain;
  }
`;

export const OverflowContainer = styled.div`
  box-sizing: border-box;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const ApplicationContainer = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 64px;
  gap: 8px;
  ${props => props.theme.typography.body};
  font-weight: 500;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.grey01};

  button {
    margin-left: auto;
  }
`;

import styled from 'styled-components';

import colors from 'designSystem/foundations/colors';

const disabledColor = colors.neutral.grey05;
const regularTextColor = colors.neutral.grey10;
const confirmationColor = colors.feedback.confirmation;

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 20px;
  padding: 0;
  margin: 8px 8px 8px 0;
  color: ${props => (props.disabled ? disabledColor : regularTextColor)};
`;

Container.defaultProps = { disabled: false };

export const Radio = styled.input`
  position: absolute;
  cursor: pointer;
  z-index: 5;
  height: 12px;
  width: 12px;
  margin: 0;
  padding: 0;
  opacity: 0;
  &:disabled {
    cursor: not-allowed;
  }
`;

Radio.defaultProps = { type: 'radio' };

export const RadioToggle = styled.div`
  position: relative;
  height: 12px;
  width: 12px;
  margin-right: 4px;
  box-sizing: border-box;
  border: 1px solid ${confirmationColor};
  border-radius: 50%;

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 6px;
    height: 6px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

  ${Radio}:disabled ~ & {
    border-color: ${disabledColor};
  }
  ${Radio}:checked ~ &:after {
    background-color: ${confirmationColor};
  }
  ${Radio}:checked:disabled ~ &:after {
    background-color: ${disabledColor};
  }
`;

export const Label = styled.span`
  ${props => props.theme.typography.control};
  font-weight: ${props => (props.bold ? 600 : 500)};
`;
Label.defaultProps = { bold: false };

import { useState, useEffect, useRef } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import HoverInfo from 'components/HoverInfo';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DropboxService } from 'services';
import { UserService } from 'services';

import {
  Container,
  UserSelectionSection,
  TooltipContainer,
  InputLabel,
  ButtonContainer,
  SaveButton,
  DeleteButton,
  CloseButton,
  UserDetailSection,
  SubContainer,
  UserInfo,
  UserInfoCard,
  LoadingContainer
} from './styles';
import UserSelection from '../UserSelection/UserSelection';

const initialSearchParams = {
  name: '',
  page: 0,
  size: 5,
  arr: [
    'id',
    'firstName',
    'lastName',
    'username',
    'roles',
    'teams',
    'manager',
    'mentor',
    'emailPrimary',
    'userStatus',
    'avatar',
    'hrPrime',
    'itPrime',
    'provisionUserDate'
  ],
  teamId: '',
  roleId: '',
  status: ''
};

const UserSelectionModal = ({
  file,
  fileState,
  closeEditModal,
  updateMetadata,
  uploadCallback,
  draft,
  group,
  deleteFile
}) => {
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const { description, fileCategory, signingTitle } = draft;
  const [searchParams, setSearchParams] = useState(initialSearchParams);
  const [data, setData] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const selectedUserIds = selectedUsers.map(user => user.id);
  const [pageInfo, setPageInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');

  const firstRender = useRef(true);

  const fetchUsers = async searchParams => {
    const { name, page, size, arr, teamId, roleId, status } = searchParams;
    try {
      const response = await UserService.list(
        name,
        page,
        size,
        arr,
        teamId,
        roleId,
        status
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setData([]);
    const getUsers = async () => {
      try {
        setIsLoading(true);
        let userIds = [];
        const response = await fetchUsers(searchParams);

        if (firstRender.current) {
          const currAssignedUsers = file.dropboxFileSharers;
          const assignedIds = file.dropboxFileSharers?.map(
            user => user.lyncWorkUserId
          );
          const assignedRequest = await UserService.listByIds(assignedIds);
          setSelectedUsers(assignedRequest.data || []);

          firstRender.current = false;
        }

        setData(response.data.content);
        setPageInfo({
          totalPages: response.data.totalPages,
          currentPage: response.data.number + 1
        });
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    getUsers();
  }, [searchParams]);

  const handleDelete = async () => {
    try {
      setDeleting(true);
      await deleteFile(file);
      closeEditModal();
    } catch (error) {
      console.log(error);
      setDeleting(false);
    }
  };

  const uploadFile = async selectedFile => {
    try {
      const response = await DropboxService.upload(selectedFile);
      const responseFile = response.data;
      return responseFile;
    } catch (error) {
      console.log(error);
      throw 'something went wrong';
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const responseFile = fileState.id
        ? fileState
        : await uploadFile(fileState.file);
      let externalGroupId;
      !group ? (externalGroupId = 'GENERAL') : (externalGroupId = 'ONBOARDING');

      const dropboxFileSharers = [];

      let category = fileCategory;
      if (selectedUsers.length > 0) {
        selectedUsers.forEach((user, i) => {
          dropboxFileSharers.push({ lyncWorkUserId: user.id });
        });
        category = 'SHAREABLE';
      }

      const metaData = {
        ...responseFile,
        fileCategory: category,
        description,
        signingTitle,
        externalGroupId,
        signingMessage: description,
        signingSubject: signingTitle,
        dropboxFileSharers
      };
      await updateMetadata(metaData);
      setLoading(false);
      uploadCallback();
    } catch (error) {
      console.log(error);
    }
  };

  const handleParamChange = key => {
    const updateParams = value => {
      switch (key) {
        case 'name':
          setSearchParams(old => ({
            ...old,
            name: search,
            page: 0
          }));
          return;
        case 'roleId':
        case 'teamId':
          const id = value?.id || '';
          setSearchParams(old => ({ ...old, [key]: id, page: 0 }));
          return;
        default:
          return;
      }
    };
    return updateParams;
  };

  const handlePageChange = x => {
    setSearchParams(old => ({ ...old, page: x - 1 }));
  };

  const handleClose = () => {
    uploadCallback();
    closeEditModal();
  };

  return (
    <Container onClick={e => e.stopPropagation()}>
      <CloseButton onClick={handleClose}>
        <CloseIcon />
      </CloseButton>
      <SubContainer>
        <UserSelectionSection>
          <InputLabel>
            User Assignment
            <TooltipContainer>
              <HoverInfo title="Select specific users to be assigned to this document (Optional)" />
            </TooltipContainer>
          </InputLabel>
          <UserSelection
            setSearchParams={setSearchParams}
            data={data}
            setSelectedUsers={setSelectedUsers}
            selectedUserIds={selectedUserIds}
            pageInfo={pageInfo}
            isLoading={isLoading}
            search={search}
            setSearch={setSearch}
            handleParamChange={handleParamChange}
            handlePageChange={handlePageChange}
          />
        </UserSelectionSection>
        <UserDetailSection>
          <InputLabel>
            Users Selected
            <TooltipContainer>
              <HoverInfo title="These users are currently being assigned to this document" />
            </TooltipContainer>
          </InputLabel>
          <UserInfo>
            {isLoading ? (
              <LoadingContainer>
                <CircularProgress size="24px" color="inherit" />
              </LoadingContainer>
            ) : selectedUsers.length > 0 ? (
              selectedUsers.map((user, i) => {
                return (
                  <UserInfoCard key={i}>
                    {user.firstName + ' ' + user.lastName}
                  </UserInfoCard>
                );
              })
            ) : (
              <UserInfoCard>All users can see this document</UserInfoCard>
            )}
          </UserInfo>
        </UserDetailSection>
      </SubContainer>
      <ButtonContainer>
        <DeleteButton type="button" disabled={loading} onClick={handleDelete}>
          {deleting ? (
            <CircularProgress size="24px" color="inherit" />
          ) : (
            'Delete'
          )}
        </DeleteButton>
        <SaveButton
          type="button"
          disabled={loading}
          onClick={() => handleSubmit()}
        >
          {loading ? <CircularProgress size="24px" color="inherit" /> : 'Save'}
        </SaveButton>
      </ButtonContainer>
    </Container>
  );
};

export default UserSelectionModal;

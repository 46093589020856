import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';

import pdfIcon from 'assets/images/adminOnboarding/documents/pdfIcon.svg';
import docIcon from 'assets/images/adminOnboarding/documents/docIcon.svg';

import {
  CustomButton,
  CustomTextArea,
  CustomCheckbox,
  CustomSelect
} from 'components/NewLyncworkUIKit';
import { DropboxService } from 'services/dropbox.service';

import {
  Container,
  Title,
  Form,
  InputWrapper,
  ErrorMessage,
  CustomInput,
  InputLabel,
  CloseButton,
  FileContainer,
  FileName,
  ButtonContainer,
  DownloadButton,
  RedText,
  AcceptedFiles
} from './styles';

const values = [
  {
    label: 'Signature Required',
    value: 'SIGNABLE_ALL'
  },
  {
    label: 'Read and Download',
    value: 'ALL'
  },
  {
    label: 'Draft',
    value: 'DRAFT'
  }
];

const getInitialState = file => {
  const { fileCategory } = file;
  const description = file.description || '';
  const signingTitle = file.signingTitle || '';
  return { fileCategory, description, signingTitle };
};

const initialErrorMessage = {
  description: false,
  signingTitle: false
};

const FileEditContent = ({
  file,
  closeEditModal,
  updateMetadata,
  downloadFile
}) => {
  const [loading, setLoading] = useState(false);
  const [draft, setDraft] = useState(getInitialState(file));
  const [error, setError] = useState(initialErrorMessage);
  const { fileCategory, description, signingTitle } = draft;
  const defaultValue = values.find(option => option.value === fileCategory);

  const updateInput = e => {
    let { name, value } = e.target;
    name === 'externalGroupId' &&
      (value ? (value = 'ONBOARDING') : (value = 'general'));

    setDraft(oldDraft => ({ ...oldDraft, [name]: value }));
    setError(oldError => ({ ...oldError, [name]: false }));
  };

  const handleSelect = selection => {
    setDraft(oldDraft => ({ ...oldDraft, fileCategory: selection.value }));
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (!description || !signingTitle) {
      setError(oldError => ({
        description: !description,
        signingTitle: !signingTitle
      }));
      return;
    }
    setLoading(true);
    const metaData = {
      ...file,
      fileCategory,
      description,
      signingTitle,
      externalGroupId: 'ONBOARDING',
      signingMessage: description,
      signingSubject: signingTitle
    };
    try {
      await updateMetadata(metaData);
      closeEditModal();
    } catch (error) {
      console.log(error);
    }
  };
  const fileName = file.fileName;
  const isPdf = fileName?.includes('.pdf');
  return (
    <Container onClick={e => e.stopPropagation()}>
      <CloseButton onClick={closeEditModal}>
        <CloseIcon />
      </CloseButton>
      <Title>{signingTitle ? 'Edit File' : 'New File'}</Title>
      <Form onSubmit={handleSubmit}>
        <InputWrapper>
          <InputLabel>
            Title<RedText>*</RedText>
          </InputLabel>
          <CustomInput
            placeholder="Name your document"
            value={signingTitle}
            name="signingTitle"
            onChange={updateInput}
          />
          {error.signingTitle && (
            <ErrorMessage>Document name is required</ErrorMessage>
          )}
        </InputWrapper>
        <InputWrapper>
          <InputLabel>
            Category<RedText>*</RedText>
          </InputLabel>
          <CustomSelect
            options={values}
            value={defaultValue}
            onChange={handleSelect}
          />
        </InputWrapper>
        <InputWrapper fullWidth>
          <InputLabel>
            Description<RedText>*</RedText>
          </InputLabel>
          <CustomTextArea
            placeholder="Write about what this document is"
            value={description}
            name="description"
            onChange={updateInput}
            rows={4}
          />
          {error.description && (
            <ErrorMessage>Description is required</ErrorMessage>
          )}
        </InputWrapper>
        <InputWrapper fullWidth>
          <InputLabel>
            Upload File<RedText>*</RedText>
          </InputLabel>
          <FileContainer>
            <img src={isPdf ? pdfIcon : docIcon} alt="icon" />
            <FileName>{file.fileName}</FileName>
            <DownloadButton onClick={() => downloadFile(file)}>
              Download File
            </DownloadButton>
          </FileContainer>
          <AcceptedFiles>Accepted file types: PDF, Doc only</AcceptedFiles>
        </InputWrapper>
        <ButtonContainer>
          <CustomButton type="submit" size="medium" disabled={loading}>
            {loading ? (
              <CircularProgress size="24px" color="inherit" />
            ) : (
              'Save'
            )}
          </CustomButton>
        </ButtonContainer>
      </Form>
    </Container>
  );
};

export default FileEditContent;

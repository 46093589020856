import { useState, useEffect } from 'react';
import { CMSService, TeamService, RoleService } from 'services';

const getTeams = async posts => {
  const teamIds = posts
    .map(draft => draft.team_permissions.map(permission => permission.team))
    .flat();
  const uniqueTeamIds = Array.from(new Set(teamIds));
  if (uniqueTeamIds.length < 1) {
    return {};
  }
  const teamsResponse = await TeamService.listByIds(uniqueTeamIds);
  const teamList = teamsResponse.data;
  const teamsDictionary = {};
  teamList.forEach(team => (teamsDictionary[team.id] = team.teamName));
  return teamsDictionary;
};
const getRoles = async posts => {
  const roleIds = posts
    .map(draft => draft.role_permissions.map(permission => permission.role))
    .flat();
  const uniqueRoleIds = Array.from(new Set(roleIds));
  if (uniqueRoleIds.length < 1) {
    return {};
  }
  const roleResponse = await RoleService.listByIds(uniqueRoleIds);
  const roleList = roleResponse.data;
  const rolesDictionary = {};
  roleList.forEach(role => (rolesDictionary[role.id] = role.roleName));
  return rolesDictionary;
};

const getRolesAndTeams = async posts => {
  return Promise.all([getRoles(posts), getTeams(posts)]);
};

const initialState = {
  page: 0,
  posts: [],
  loading: true,
  error: false,
  totalPages: 0
};

const useCommunityPosts = ({ draft = false, getRolesTeams = true } = {}) => {
  const [{ page, loading, totalPages, posts, error }, setFetchState] =
    useState(initialState);
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await CMSService.listPost({ page, draft });
        const { content: posts, totalPages } = response.data;
        if (getRolesTeams) {
          const [roles, teams] = await getRolesAndTeams(posts);
          posts.forEach(draft => {
            draft.role_permissions.forEach(
              permission => (permission.roleName = roles[permission.role])
            );
            draft.team_permissions.forEach(
              permission => (permission.teamName = teams[permission.team])
            );
          });
        }
        setFetchState(oldState => ({
          ...oldState,
          loading: false,
          posts,
          totalPages
        }));
      } catch (error) {
        setFetchState(oldState => ({
          ...oldState,
          loading: false,
          error: true
        }));
        console.log(error);
      }
    };

    fetchPosts();
  }, [page, draft]);

  const changePage = newPage => {
    const newPageIndex = newPage - 1;
    setFetchState(oldVar => ({ ...oldVar, page: newPageIndex, loading: true }));
  };

  return { page, loading, totalPages, posts, error, changePage };
};

export default useCommunityPosts;

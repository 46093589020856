import React from 'react';

import {
  Container,
  Title,
  Header,
  Description,
  InputGroupContainer,
  InputWrapper,
  InputLabel,
  CustomInput
} from './styles';

const IceBreakers = ({ register }) => {
  return (
    <Container>
      <Title>What three words best describe yourself?</Title>
      <InputGroupContainer>
        <CustomInput
          name="word1"
          label="Word 1"
          placeholder="e.g 'Funny'"
          inputRef={register}
        />
        <CustomInput
          name="word2"
          label="Word 2"
          placeholder="e.g 'Friendly'"
          inputRef={register}
        />
        <CustomInput
          name="word3"
          label="Word 3"
          placeholder="e.g 'Cat Lover'"
          inputRef={register}
        />
      </InputGroupContainer>
    </Container>
  );
};

export default IceBreakers;

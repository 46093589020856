import styled from 'styled-components';
import { multilineEllipsis } from 'StyleVariables/StandardComponents';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  flex-shrink: 0;
  background-color: ${props => props.theme.colors.neutral.offWhite01};
  border-bottom: 1px solid ${props => props.theme.colors.neutral.grey03};
`;

export const Cell = styled.div`
  flex: ${props => props.flex};
  box-sizing: border-box;
  margin: 0 0 0 24px;
  ${props => props.theme.typography.metadata};
  color: ${props => props.theme.colors.neutral.grey07};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

Cell.defaultProps = { flex: 1 };

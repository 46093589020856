import styled from 'styled-components';

import colors from 'designSystem/foundations/colors';

const disabledColor = colors.neutral.grey05;
const regularTextColor = colors.neutral.grey10;
const confirmationColor = colors.feedback.confirmation;

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 20px;
  padding: 0;
  margin: 8px 0;
  color: ${props => (props.disabled ? disabledColor : regularTextColor)};
`;

Container.defaultProps = { disabled: false };

export const Checkbox = styled.input`
  position: absolute;
  cursor: pointer;
  z-index: 5;
  height: 20px;
  width: 20px;
  margin: 0;
  padding: 0;
  opacity: 0;
  &:disabled {
    cursor: not-allowed;
  }
`;

Checkbox.defaultProps = { type: 'checkbox' };

export const CheckboxToggle = styled.div`
  position: relative;
  height: 18px;
  width: 18px;
  margin-right: 4px;
  box-sizing: border-box;
  border: 1px solid ${colors.neutral.grey06};
  background-color: transparent;
  border-radius: 4px;

  &:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 2px;
    width: 3px;
    height: 6px;
    border: 2px solid transparent;
    border-top: none;
    border-left: none;
    transform-origin: bottom right;
    transform: rotate(45deg);
  }

  ${Checkbox}:checked ~ & {
    background-color: ${confirmationColor};
    border-color ${confirmationColor};
  }
  ${Checkbox}:disabled ~ & {
    border-color: ${disabledColor};
  }
  ${Checkbox}:checked:disabled ~ & {
    background-color: ${disabledColor};
  }

  ${Checkbox}:checked ~ &:after {
    border-color: ${props => props.theme.colors.neutral.white};
  }

  ${Checkbox}:focus-visible ~ & {
    background-color: ${confirmationColor}80;
    border-color: transparent;
    :after {
      border-color: ${props => props.theme.colors.neutral.white};
    }
  }
`;

export const Label = styled.span`
  ${props => props.theme.typography.control};
  font-weight: ${props => (props.bold ? 600 : 500)};
`;
Label.defaultProps = { bold: false };

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Avatar } from 'components/NewLyncworkUIKit';
import colors from 'designSystem/foundations/colors';

const {
  neutral: { grey10, offWhite03 }
} = colors;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
`;

export const StyledAvatar = styled(Avatar)`
  width: 28px;
  height: 28px;
  margin: 0 8px 0 0;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  margin: 0 24px 0 auto;
`;

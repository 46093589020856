import styled from 'styled-components';

export const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 3em;
  margin: 1.5em 0;
  width: 100%;
  background-color: white;
  border-radius: 4px;
`;
export const Carousel = styled.div`
  display: block;
  flex-direction: row;
  width: (100% - 48px);
  overflow-x: auto;
  border-radius: 4px;
  padding: 1em;
`;

export const Article = styled.div`
  height: 271px;
  min-width: 200px;
  max-width: 240px;
  margin: 2em;
`;
export const Heading = styled.h1`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: #4d4d4d;
`;

import React, { useEffect, useState } from 'react';
import X from 'assets/images/GenericIcons/X.svg';
import styled from 'styled-components';
import colors from 'designSystem/foundations/colors';
import {
  multilineEllipsisFunc,
  scrollBar
} from 'StyleVariables/StandardComponents';
import AsyncDropdown from 'components/AsyncDropdown';
import { CustomButton, ImageCircle } from 'components/NewLyncworkUIKit';
import { RoleService } from 'services/role.service';
import { TeamService } from 'services/team.service';
import { UserService } from 'services/user.service';
import { CircularProgress } from '@material-ui/core';
import { useButtonTrack } from 'matomo/hooks';
import { usersListPageEvents } from 'pages/Users/UsersListPage/instrumentationEvents';
export const Container = styled.div`
  padding: 24px;
  position: relative;
  box-sizing: border-box;
  width: 522px;
  max-width: 100%;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background-color: ${props => props.theme.colors.neutral.white};
  overflow-y: scroll;
  ${scrollBar}
`;
export const ExitButton = styled.img`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 16px;
  height: 16px;
  margin: 0 0 0 1em;
  cursor: pointer;
`;
export const RemoveButton = styled.img`
  width: 12px;
  height: 12px;
  margin: 0 0 0 1em;
  cursor: pointer;
`;
export const Title = styled.h1`
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  color: #000000;
  margin-bottom: 26px;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
`;
export const Users = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 100%;
  max-height: 300px;
  box-sizing: border-box;
  overflow-y: overlay;
  ${scrollBar}
  border-right:1px solid ${colors.neutral.grey05};
  padding-right: 1em;
`;
export const Dropdowns = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  box-sizing: border-box;
  padding: 8px 16px;
`;
export const UserRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  width: 100%;
  margin: 8px 0;
  p {
    flex: 1;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    display: flex;
    align-items: center;

    /* Neutral/Grey 10 */

    color: ${colors.neutral.grey10};
    margin: 0;
    margin-left: 4px;
    ${multilineEllipsisFunc(1)}
  }
`;
export const FakeUnit = styled.div`
  width: 50px;
  min-height: 50px;
  margin: 24px;
`;
export const Label = styled.h2`
  margin: 0;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Neutral/Grey 10 */

  color: ${colors.neutral.grey10};
  margin-top: ${({ marginTop }) => marginTop && '1em'};
`;
const removeDuplicates = array => {
  let uniques = [];
  array.forEach(item => {
    const uniqueIds = uniques.map(x => x.id);
    if (uniqueIds.includes(item.id)) {
      return;
    } else {
      uniques.push(item);
    }
  });
  console.log({ uniques });
  return uniques;
};
const AssignModal = ({ closeModal, users, setSelectedUsers, setData }) => {
  const trackButton = useButtonTrack();
  const [roles, setRoles] = useState([]);
  const [teams, setTeams] = useState([]);
  const [manager, setManager] = useState(null);
  const [mentor, setMentor] = useState(null);

  const [submitting, setSubmitting] = useState(false);

  const batchAssign = async () => {
    trackButton(usersListPageEvents.confirm_batch_assignment);
    setSubmitting(true);
    let updatedUsers = [];
    const noNullTeams = teams === null ? [] : teams;
    const noNullRoles = roles === null ? [] : roles;

    try {
      for (const user of users) {
        const updatedUser = {
          ...user,
          roles: removeDuplicates([...user.roles, ...noNullRoles]),

          teams: removeDuplicates([...user.teams, ...noNullTeams]),

          mentor: mentor ? mentor : user.mentor,
          manager: manager ? manager : user.manager
        };
        updatedUsers.push(updatedUser);
        await UserService.update(updatedUser);
      }
    } catch (error) {
      console.log(error);
    }
    setSelectedUsers(updatedUsers);
    setData(old => {
      let newUsers = [];
      for (const user of old) {
        if (updatedUsers.map(x => x.id).includes(user.id)) {
          const replacedUser = updatedUsers.find(x => x.id === user.id);
          console.log({ replacedUser });
          newUsers.push(replacedUser);
        } else {
          newUsers.push(user);
        }
      }
      console.log({ newUsers });
      return newUsers;
    });

    setSubmitting(false);
    closeModal();
  };
  return (
    <Container>
      <ExitButton onClick={closeModal} src={X} />
      <Title>Assign Users({users?.length})</Title>
      <Content>
        <Users>
          {users.map(user => (
            <UserRow key={user.id}>
              <ImageCircle
                maxWidth={32}
                url={user.avatar?.formats?.thumbnail?.url}
              />
              <p>{`${user.firstName} ${user.lastName}`}</p>
              <RemoveButton
                onClick={() =>
                  setSelectedUsers(old => old.filter(x => x !== user))
                }
                src={X}
              />
            </UserRow>
          ))}
        </Users>
        <Dropdowns>
          <Label>Role(s)</Label>
          <AsyncDropdown
            onChange={value => setRoles(value)}
            label="Select Roles"
            paginatedCallback={RoleService.listLite}
            minHeight="24px"
            isMulti
          />
          <Label marginTop>Teams(s)</Label>
          <AsyncDropdown
            onChange={value => setTeams(value)}
            label="Select Teams"
            paginatedCallback={TeamService.listLite}
            minHeight="24px"
            isMulti
          />
          <Label marginTop>Mentor</Label>
          <AsyncDropdown
            onChange={value => setMentor(value)}
            label={'Select a mentor'}
            paginatedCallback={UserService.list}
            minHeight="24px"
          />
          <Label marginTop>Manager</Label>
          <AsyncDropdown
            onChange={value => setManager(value)}
            label={'Select a Manager'}
            paginatedCallback={UserService.list}
            minHeight="24px"
          />
        </Dropdowns>
      </Content>
      <CustomButton onClick={batchAssign} size="medium">
        {submitting ? (
          <CircularProgress size="16px" color="inherit" />
        ) : (
          'Assign'
        )}
      </CustomButton>
    </Container>
  );
};
export default AssignModal;

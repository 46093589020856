import styled from 'styled-components';
import { colors } from 'designSystem/foundations';

const {
  neutral: { grey10, grey07 }
} = colors;

export const Title = styled.h2`
  ${props => props.theme.typography.header2};
  font-weight: 600;
  margin: 0 0 16px;
  color: ${grey10};
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 32px;
  h3 {
    ${props => props.theme.typography.header2};
    font-weight: 600;
    margin: 0;
    color: ${grey10};
  }
  p {
    margin: 0;
    ${props => props.theme.typography.body};
    color: ${grey07};
  }
  img {
    margin: 24px auto 0;
    width: 80%;
    height: auto;
    object-fit: contain;
  }
`;

import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Progress,
  ProgressNode,
  ProgressIndicator,
  ProgressLineLeft,
  ProgressCircle,
  ProgressLineRight
} from './styles';
import checkmark from 'assets/images/Onboarding/checkmark.svg';

const ProgressDesktop = ({ pageData, completedPaths }) => {
  const { pathname } = useLocation();
  const lastIndex = pageData.length - 1;

  const pathHistory = completedPaths.includes(pathname)
    ? completedPaths
    : [...completedPaths, pathname];
  const rightActivePaths = pathHistory.slice(0, pathHistory.length - 1);
  return (
    <Progress>
      {pageData.map((node, index) => {
        const currentIndex = pageData.indexOf(
          pageData.find(x => x.pathName === pathname)
        );
        const nodePathName = node.pathName;
        const done = currentIndex > index;

        const doing = nodePathName === pathname;
        const leftActive = currentIndex > index;
        const rightActive = rightActivePaths.includes(nodePathName);
        return (
          <ProgressNode active={done || doing} key={nodePathName}>
            <p>{node.progress.title}</p>
            <ProgressIndicator>
              {index !== 0 && <ProgressLineLeft active={leftActive || doing} />}
              <ProgressCircle done={done} doing={doing}>
                {done && !doing && <img src={checkmark} />}
              </ProgressCircle>
              {index !== lastIndex && <ProgressLineRight active={done} />}
            </ProgressIndicator>
          </ProgressNode>
        );
      })}
    </Progress>
  );
};
export default ProgressDesktop;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserJourneyService } from 'services/userJourneys.services';
import { CustomButton } from 'components/NewLyncworkUIKit';
import { Container, HeaderContainer, JourneysContainer } from './styles';

import JourneyTable from './JourneyTable';

const Journeys = () => {
  const [state, setState] = useState({ loading: true, journeys: [] });
  const { journeys, loading } = state;
  const hasJourneys = journeys.length > 0;

  useEffect(() => {
    let mounted = true;
    const fetch = async () => {
      try {
        const response = await UserJourneyService.list({
          size: 5,
          completed: false,
          sort: 'taskWork.lastDueDate,asc'
        });
        const fetchedJourneys = response.data.content;
        mounted && setState({ loading: false, journeys: fetchedJourneys });
      } catch (error) {
        mounted && setState({ loading: false, journeys: [] });
        console.log(error);
      }
    };
    fetch();
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Container>
      <HeaderContainer>
        <h1>Journeys</h1>
        <Link to="/user-journeys">
          <CustomButton tabIndex="-1" variant="secondary" size="small">
            View All
          </CustomButton>
        </Link>
      </HeaderContainer>
      <JourneyTable loading={loading} journeys={journeys} />
    </Container>
  );
};

export default Journeys;

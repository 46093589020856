import React from 'react';

import HiddenCounter from 'components/HiddenCounter';

import {
  Row,
  Cell,
  UserMainInfoContainer,
  StyledAvatar,
  MobileRow,
  MobileRowDetailContainer,
  MobileUserDetail
} from './styles';

const getRoleName = roles => {
  if (roles.length < 1) {
    return 'Unassigned';
  }
  const firstTwoRoles = roles.slice(0, 2);
  const roleNames = firstTwoRoles.map(({ roleName }) => roleName);
  return roleNames.join(', ');
};

const UserProfileInfo = ({ user }) => {
  const { avatar, id, firstName, lastName, roles } = user;
  const fullName = `${firstName} ${lastName}`;
  const src = avatar?.url;

  const roleName = getRoleName(roles);
  const remainingRolesCount = roles.length - 2;

  return (
    <>
      <StyledAvatar src={src} colorKey={id} name={fullName} />
      <UserMainInfoContainer>
        <span className="user-name">{fullName}</span>
        <div className="user-roles">
          {roleName}
          {remainingRolesCount > 0 && (
            <HiddenCounter className="remaining-count-small">
              +{remainingRolesCount}
            </HiddenCounter>
          )}
        </div>
      </UserMainInfoContainer>
    </>
  );
};

const getTeamName = teams => {
  if (teams.length < 1) {
    return '';
  }
  const firstTwoTeams = teams.slice(0, 2);
  const teamNames = firstTwoTeams.map(({ teamName }) => teamName);
  return teamNames.join(', ');
};

const MobileRowContent = ({ user, onClick }) => {
  const { avatar, id, firstName, lastName, roles, teams } = user;
  const fullName = `${firstName} ${lastName}`;
  const src = avatar?.url;

  const roleNames =
    roles.map(({ roleName }) => roleName).join(', ') || 'No roles assigned';
  const teamNames =
    teams.map(({ teamName }) => teamName).join(', ') || 'No teams assigned';

  return (
    <MobileRow onClick={onClick}>
      <StyledAvatar src={src} colorKey={id} name={fullName} />
      <MobileRowDetailContainer>
        <MobileUserDetail>
          <strong>{fullName}</strong>
        </MobileUserDetail>
        <MobileUserDetail>{roleNames}</MobileUserDetail>
        <MobileUserDetail>{teamNames}</MobileUserDetail>
      </MobileRowDetailContainer>
    </MobileRow>
  );
};

const TableRow = ({ user, handleSelect }) => {
  const { teams, emailPrimary } = user;
  const teamName = teams[0]?.teamName || 'Unassigned';
  const remainingTeamsCount = teams.length - 1;

  return (
    <>
      <MobileRowContent user={user} onClick={handleSelect} />
      <Row onClick={handleSelect}>
        <Cell>
          <UserProfileInfo user={user} />
        </Cell>
        <Cell>
          {teamName}
          {remainingTeamsCount > 0 && (
            <HiddenCounter>+{remainingTeamsCount}</HiddenCounter>
          )}
        </Cell>
        <Cell>{emailPrimary}</Cell>
      </Row>
    </>
  );
};

export default TableRow;

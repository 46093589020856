import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import {
  Buttons,
  Children,
  Modal,
  Backdrop,
  ModalWrapper,
  SaveCancel,
  DeleteSuspend
} from './styles';
import CustomButton from '../CustomButton';
import DeleteButton from '../../DeleteButton';
import SuspendButton from './components/SuspendButton';

// We get hold of the div with the id modal that we have created in index.html
const portal = document.createElement('div');
portal.classList.add('lyncwork-modal-portal');
if (!document.body) {
  throw new Error('body not ready for portal creation!');
}

class CustomModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  // We append the created div to the div#modal
  componentDidMount() {
    document.body.appendChild(portal);
    document.addEventListener('mousedown', this.handleClickOutside); //when you click, this function fires
  }

  componentWillUnmount() {
    document.body.removeChild(portal);
    document.removeEventListener('mousedown', this.handleClickOutside); //turns off listener
  }
  handleClickOutside(event) {
    //if the target of the event (click) is not within the wrapperRef (our modal) then use the dispatch function
    this.wrapperRef.current &&
      this.childRef.current &&
      this.wrapperRef.current.contains(event.target) &&
      this.props.buttonFunctions.cancelButton();
  }
  wrapperRef = React.createRef();
  childRef = React.createRef();
  render() {
    const { isOpen, dimensions, children, buttonFunctions } = this.props;
    const usePortal = isOpen;

    const trueDimensions = {
      minWidth:
        dimensions && dimensions.minWidth
          ? `calc(${dimensions.minWidth} - 64px)`
          : '300px',
      minHeight:
        dimensions && dimensions.minHeight
          ? `calc(${dimensions.minHeight} - 64px)`
          : '300px',

      maxWidth:
        dimensions && dimensions.maxWidth
          ? `calc(${dimensions.maxWidth} - 124px)`
          : '500px',
      maxHeight:
        dimensions && dimensions.maxHeight
          ? `calc(${dimensions.maxHeight} - 128px)`
          : '800px'
    };
    if (usePortal) {
      return createPortal(
        <ModalWrapper className="custom-modal-wrapper">
          <Backdrop ref={this.wrapperRef} className="custom-modal-backdrop" />
          <Modal className="custom-modal">
            <Children
              ref={this.childRef}
              className="custom-modal-children"
              style={trueDimensions}
            >
              {children}
            </Children>

            {!!buttonFunctions.deleteButton &&
              (() => {
                const {
                  deleteButton,
                  saveButton,
                  cancelButton
                } = buttonFunctions;
                const {
                  deleteFunction,
                  canDelete,
                  toDeleteMessage,
                  id
                } = deleteButton;

                return (
                  <Buttons className="custom-modal-button-wrapper">
                    <DeleteSuspend>
                      {deleteButton && deleteFunction && (
                        <DeleteButton
                          deleteFunction={deleteFunction}
                          id={id}
                          toDeleteMessage={toDeleteMessage}
                          message={toDeleteMessage}
                          canDelete={canDelete}
                        />
                      )}
                      {buttonFunctions?.suspendButton && (
                        <SuspendButton
                          suspendInfo={buttonFunctions?.suspendButton}
                        />
                      )}
                    </DeleteSuspend>

                    <SaveCancel id={id} className="save-cancel">
                      <CustomButton
                        onClick={() => cancelButton()}
                        variant="secondary"
                      >
                        Cancel
                      </CustomButton>
                      {saveButton && (
                        <CustomButton
                          styles={{ marginLeft: '8px', width: '82px' }}
                          onClick={() => saveButton()}
                        >
                          Save
                        </CustomButton>
                      )}
                    </SaveCancel>
                  </Buttons>
                );
              })()}
          </Modal>
        </ModalWrapper>,

        portal
      );
    } else {
      return null;
    }
  }
}
export default CustomModal;

import styled from 'styled-components';
import { CustomSearch } from 'components/NewLyncworkUIKit';

import { scrollBar } from 'StyleVariables/StandardComponents';

export const SearchInput = styled(CustomSearch)`
  width: 100%;
  flex-shrink: 0;
`;
SearchInput.defaultProps = { offWhite: true };

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
`;

export const ListContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0;
  }
`;

import React from 'react';
import FileCopy from '@material-ui/icons/FileCopy';

import CustomTooltip from 'components/NewLyncworkUIKit/CustomTooltip';
import { CredentialContainer, CopyButton } from './styles';

export const copyText = text => {
  var dummy = document.createElement('textarea');
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
};

const Credential = ({ label, text }) => {
  const handleClick = () => copyText(text);
  return (
    <CredentialContainer>
      {label}:
      <CustomTooltip
        title="Click to copy"
        arrow
        placement="top"
        disableFocusListener
        disableTouchListener
      >
        <CopyButton variant="secondary" size="small" onClick={handleClick}>
          {text} <FileCopy />
        </CopyButton>
      </CustomTooltip>
    </CredentialContainer>
  );
};

export default Credential;

import React from 'react';
import { AsyncSelect } from 'components/NewLyncworkUIKit';
import { getOptionLabel, getOptionValue } from 'utilities/dropdown';
import { DropdownService } from 'services';

const loadTeams = async inputValue => {
  const response = await DropdownService.teams({ name: inputValue });
  return response.data.content;
};
const loadRoles = async inputValue => {
  const response = await DropdownService.roles({ name: inputValue });
  return response.data.content;
};

const AssignmentSelection = ({ assignmentInfoProps }) => {
  const {
    assignmentType,
    teams,
    roles,
    changeAssignment
  } = assignmentInfoProps;

  const isTeamSelected = assignmentType === 'TEAM';
  const value = isTeamSelected ? teams : roles;
  const paramName = isTeamSelected ? 'teams' : 'roles';
  const loadOptions = isTeamSelected ? loadTeams : loadRoles;

  const onChange = selection => {
    changeAssignment(paramName, selection);
  };
  const placeholder = `Type to search...`;
  return (
    <AsyncSelect
      placeholder={placeholder}
      loadOptions={loadOptions}
      defaultOptions
      isMulti
      value={value}
      onChange={onChange}
    />
  );
};

export default AssignmentSelection;

import styled from 'styled-components';
import Card from 'components/Card';
import { IconButton } from 'components/NewLyncworkUIKit';

export const Container = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px;
  min-height: 200px;
  .MuiCircularProgress-root {
    margin: auto;
  }
`;

export const SectionTitle = styled.h2`
  ${props => props.theme.typography.header2};
  font-weight: 600;
  margin: 24px 0 8px 0;
  &:first-child {
    margin: 0 0 8px;
  }
`;

export const Paragraph = styled.p`
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey07};
  margin: 0;
`;

export const ModalContainer = styled(Card)`
  position: relative;
  width: 600px;
  max-width: calc(100% - 40px);
  padding: 24px;
  display: flex;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 36px 0 0;

  button {
    margin: 0 4px;
    width: 126px;
  }
`;

export const CloseButton = styled(IconButton)`
  width: 18px;
  height: 18px;
  font-size: 18px;
  position: absolute;
  right: 24px;
  top: 24px;
  .MuiSvgIcon-root {
    font-size: inherit;
  }
`;

export const StyledIconButton = styled(IconButton)`
  width: 18px;
  height: 18px;
  font-size: 18px;
  position: absolute;
  right: 24px;
  top: 24px;
  .MuiSvgIcon-root {
    font-size: inherit;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  .search-input {
    margin-left: auto;
  }
  gap: 24px;
`;

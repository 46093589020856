import delay from 'utilities/delay';

const skillNames = [
  'Leadership',
  'React',
  'NodeJS',
  'JAVA',
  'Public Speaking',
  'Communication',
  'SEO',
  'Accounting',
  'DevOps',
  'Video Editing',
  'Marketing',
  'UI/UX',
  'Architecture',
  'Database Optimization'
];

export const fakeSkills = skillNames.map((name, id) => ({ id, name }));

export const fetchSkills = async inputValue => {
  await delay(100);
  return fakeSkills.filter(option =>
    option.name.includes(inputValue.toLowerCase())
  );
};
export const removeDuplicateSkills = (list1, list2) => {
  const reducer = (existingSkills, currentSkill) => {
    const doesSkillExist = existingSkills.find(
      skill => skill.id === currentSkill.id
    );
    if (!doesSkillExist) {
      existingSkills.push(currentSkill);
    }
    return existingSkills;
  };
  return list2.reduce(reducer, list1);
};

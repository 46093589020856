import React from 'react';
import { Route, Switch } from 'react-router-dom';
import UsersListPage from './UsersListPage';
import UsersImportPage from './UsersImportPage';
import { UserAddPage, UserEditPage } from './UserDetailPage';

const UsersPage = () => {
  return (
    <Switch>
      <Route path="/admin-users" exact>
        <UsersListPage />
      </Route>
      <Route path="/admin-users/add" exact>
        <UserAddPage />
      </Route>
      <Route path="/admin-users/edit/:id">
        <UserEditPage />
      </Route>
      <Route path="/admin-users/import">
        <UsersImportPage />
      </Route>
    </Switch>
  );
};

export default UsersPage;

import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import colors from 'designSystem/foundations/colors';

export const StyledNavLink = styled(NavLink)`
  cursor: pointer;
  &.nav-link {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;
    transition: all 0.3s;
    padding-left: 40px;
    margin: 1em 0;
    min-height: 24px;

    p {
      box-sizing: border-box;
      left: 80px;
      font-family: Work Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: ${colors.neutral.grey06};
      margin: 0;
      margin-left: 0.5em;
    }
  }
  span {
    display: flex;
    align-items: center;
    img {
      height: 14px;
    }
  }
  &.nav-link-active {
    p {
      color: ${colors.brand.primary};
    }

    border-right: 3px solid ${colors.brand.primary};
  }
`;

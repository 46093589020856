import styled from 'styled-components';
import { orange, white, grey } from 'StyleVariables/LyncworkColors';
import { multilineEllipsisFunc } from 'StyleVariables/StandardComponents';

const mobileBreakpoint = '900px';

export const Progress = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 80px;
  background-color: ${props => props.theme.colors.neutral.offWhite01};
  @media only screen and (max-width: ${mobileBreakpoint}) {
    display: none;
  }
`;
export const ProgressNode = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  text-align: center;

  p {
    ${props => props.theme.typography.metadata};
    font-weight: 500;

    text-align: center;
    text-transform: capitalize;
    ${multilineEllipsisFunc(1)}
    /* Grey 06 */
    color:${props => (props.active ? '#E84740' : '#8e8e93')} ;
    margin: 0;
  }
`;
export const ProgressIndicator = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 12px;
`;
export const ProgressLineLeft = styled.div`
  position: absolute;
  left: 0;
  width: 50%;
  height: 1px;
  background-color: ${props => (props.active ? '#E84740' : '#8e8e93')};
`;
export const ProgressLineRight = styled.div`
  position: absolute;

  width: 50%;
  height: 1px;
  background-color: ${props => (props.active ? '#E84740' : '#8e8e93')};

  right: 0;
`;
export const ProgressCircle = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8px;
  height: 8px;
  z-index: 2;
  border-radius: 50%;
  border: ${({ done, doing }) =>
    done || doing ? `2px solid ${'#E84740'}` : 'none'};
  background: ${({ done, doing }) => (doing ? white : done ? '#E84740' : grey)};
`;

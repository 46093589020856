import React, { useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import HoverInfo from 'components/HoverInfo';

import { reorder } from './utils';
import { Container, Header, ListContainer, ScrollContainer } from './styles';

import LicenceCard from './LicenceCard';

const List = ({ children, onDragEnd }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <ScrollContainer>
            <ListContainer {...provided.droppableProps} ref={provided.innerRef}>
              {children}
              {provided.placeholder}
            </ListContainer>
          </ScrollContainer>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const LicenceOverview = ({
  licenses,
  onDragEnd,
  setSelectedLicenceId,
  selectedLicenceId
}) => {
  return (
    <Container>
      <Header>
        <h2>Application Licences</h2>
        <div className="description">
          <span>Manage licence hierarchy</span>
          <HoverInfo title="For users belonging to multiple roles with different licences assigned, the licence with the highest hierarchy is the one that will be active. " />
        </div>
      </Header>
      <List onDragEnd={onDragEnd}>
        {licenses.map((licence, index) => {
          const currentLicenseId =
            licence.applicationLicenseInformation.licenseId;
          const selectLicence = () => setSelectedLicenceId(currentLicenseId);
          const selected = selectedLicenceId === currentLicenseId;
          return (
            <LicenceCard
              selected={selected}
              key={licence.id}
              licence={licence}
              index={index}
              selectLicence={selectLicence}
            />
          );
        })}
      </List>
    </Container>
  );
};

export default LicenceOverview;

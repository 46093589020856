import styled from 'styled-components';
import colors from 'designSystem/foundations/colors';
const mobileBreakpoint = '900px';

export const Card = styled.div`
  display: flex;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  width: 150px;
  height: 185px;
  border-radius: 10px;
  /* background-color: ${colors.neutral.white}; */
    background-color:red;
box-shadow: 3.81237px 3.81237px 28.5928px rgba(158, 159, 164, 0.4);
`;
export const CardContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  box-shadow: none;
  background-color: transparent;
`;
export const CoverflowContainer = styled.div`
  position: relative;
  width: 80%;
  max-width: 795px;
  --swiper-navigation-size: 24px;
  --swiper-theme-color: ${colors.brand.primary};
  .swiper-button-next {
    right: -50px;
  }
  .swiper-button-prev {
    left: -50px;
  }
  .swiper-container {
    padding: 30px 0;
  }
  @media only screen and (max-width: ${mobileBreakpoint}) {
    display: none;
  }
`;

import styled, { css } from 'styled-components';
import { Rnd } from 'react-rnd';

const singleWidth = 280;

export const Container = styled.div`
  position: relative;
  display: flex;
  color: ${props => props.theme.colors.neutral.grey10};
  height: 56px;
`;

const colors = {
  ACTION: '#409CFF',
  EVENT: '#9440E8'
};
const handlerColors = {
  ACTION: '#004997',
  EVENT: '#4E0598'
};

export const TaskSpan = styled(Rnd)`
  background-color: ${props => colors[props.tasktype]};
  border-radius: 8px;
  cursor: pointer !important;
  z-index: 1;
  .resize-handler {
    opacity: 0;
    height: 10px !important;
    background-color: ${props => handlerColors[props.tasktype]};
    top: 11px !important;
    border-radius: 50%;
    cursor: ew-resize !important;
    transition: opacity 0.2s ease-in;
  }
  &:hover {
    .resize-handler {
      opacity: 1;
    }
  }
`;

export const formatJourneysAssignments = journeyData => {
  const {
    assignedToAll,
    teams,
    roles,
    prerequisiteWorkflow = null
  } = journeyData;
  const repeatable = !!journeyData.repeatable;
  const eventbased = !!journeyData.eventbased;

  if (assignedToAll) {
    return {
      ...journeyData,
      roles: [],
      teams: [],
      lyncWorkUsers: [],
      repeatable,
      eventbased,
      prerequisiteWorkflow
    };
  }

  const formattedTeams = teams.map(({ id, teamName: name }) => ({ id, name }));
  const formattedRoles = roles.map(({ id, roleName: name }) => ({ id, name }));
  return {
    ...journeyData,
    teams: formattedTeams,
    roles: formattedRoles,
    repeatable,
    eventbased,
    prerequisiteWorkflow
  };
};

import useAuthentication, {
  useAuthenticationActions
} from 'hooks/useAuthentication';
import useToast from 'hooks/useToast';
import { useEffect, useState } from 'react';
import { CMSService, LocaleService, PageSettingService } from 'services';

const blankAddress = {
  lineOne: '',
  lineTwo: '',
  city: '',
  state: '',
  country: '',
  postalCode: ''
};

const fieldLabels = {
  emailSecondary: 'Personal Email',
  phonePrimary: 'Primary Phone',
  phoneSecondary: 'Secondary Phone',
  country: 'Country',
  lineOne: 'Address Line One',
  lineTwo: 'Address Line Two',
  state: 'State',
  city: 'City',
  postalCode: 'Postal Code'
};

const requiredFields = [
  'emailSecondary',
  'phonePrimary',
  'lineOne',
  'city',
  'state',
  'country',
  'postalCode'
];

const getUserFormInfo = user => {
  const {
    emailSecondary,
    phonePrimary,
    phoneSecondary,
    addresses,
    linkedinUrl,
    mediumUrl
  } = user;
  const { lineOne, lineTwo, city, state, country, postalCode } =
    addresses?.[0] || blankAddress;

  const userThreeWords = user?.userDescription?.descriptions || [];
  const threeWords = new Array(3)
    .fill('')
    .map((_, index) => userThreeWords[index] || '');

  const formInfo = {
    emailSecondary,
    phonePrimary,
    phoneSecondary,
    linkedinUrl,
    mediumUrl,
    lineOne,
    lineTwo,
    city,
    state,
    country,
    postalCode,
    threeWords
  };

  Object.keys(formInfo).forEach(key => {
    if (!formInfo[key]) {
      formInfo[key] = '';
    }
  });
  return formInfo;
};

const validateForm = (formInfo, optionalFields) => {
  const missingFields = requiredFields.filter(key => !formInfo[key]);
  const formError = missingFields.reduce(
    (errors, currentKey) => ({
      ...errors,
      [currentKey]: `${fieldLabels[currentKey]} is required`
    }),
    {}
  );

  optionalFields.EmergencyContact.forEach(field => {
    const { name, required, label } = field;
    if (!required) {
      return;
    }

    const inputName = `emergencyContact-${name}`;
    if (!formInfo[inputName]) {
      missingFields.push(inputName);
      formError[inputName] = `${label} is required`;
    }
  });

  return { missingFields, formError };
};
const addressKeys = [
  'lineOne',
  'lineTwo',
  'city',
  'state',
  'country',
  'postalCode'
];
const getNewUserData = (formInfo, user) => {
  const { lineOne, lineTwo, city, state, country, postalCode } = formInfo;
  const address = {
    lineOne,
    lineTwo,
    city,
    state,
    country,
    postalCode
  };

  const updatedUserInfo = {};
  Object.keys(formInfo).forEach(key => {
    const value = formInfo[key];
    if (addressKeys.includes(key)) {
      return;
    }
    if (key.startsWith('emergencyContact')) {
      const name = key.replace('emergencyContact-', '');
      if (!updatedUserInfo.emergencyContact) {
        updatedUserInfo.emergencyContact = { [name]: value };
      } else {
        updatedUserInfo.emergencyContact[name] = value;
      }
      return;
    }

    if (key === 'threeWords') {
      updatedUserInfo.userDescription = { descriptions: value };
      return;
    }
    updatedUserInfo[key] = value;
  });

  return { ...user, ...updatedUserInfo, addresses: [address] };
};

const getUserAvatarInfo = user => {
  const { avatar } = user;
  return { file: null, src: avatar?.url };
};

const populateUserFormOptionalFields = (oldFormInfo, optionalFields, user) => {
  const { LyncWorkUser, EmergencyContact } = optionalFields;
  const additionalFields = {};
  LyncWorkUser.forEach(({ name }) => {
    additionalFields[name] = user[name] || '';
  });

  if (EmergencyContact.length > 0) {
    EmergencyContact.forEach(({ name }) => {
      const key = `emergencyContact-${name}`;
      additionalFields[key] = user.emergencyContact?.[name] || '';
    });
    if (user.emergencyContact) {
      additionalFields[`emergencyContact-id`] = user.emergencyContact.id;
    }
  }
  return { ...oldFormInfo, ...additionalFields };
};

const useAccountDetails = () => {
  const { user } = useAuthentication();
  const { createToast } = useToast();
  const { setGetUser } = useAuthenticationActions();
  const [loading, setLoading] = useState(true);
  const [optionalFields, setOptionalFields] = useState({});
  const [formModified, setFormModified] = useState(false);
  const [avatarData, setAvatarData] = useState(getUserAvatarInfo(user));
  const [formInfo, setFormInfo] = useState(getUserFormInfo(user));
  const [formError, setFormError] = useState({});
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const getOptionalFields = async () => {
      try {
        const { data } = await PageSettingService.personalInfo();
        const optionalFields = data.entitiesSettings.reduce(
          (fields, currentGroup) => {
            const { name, companyFields } = currentGroup;
            return { ...fields, [name]: companyFields };
          },
          {}
        );
        setOptionalFields(optionalFields);
        setFormInfo(oldFormInfo =>
          populateUserFormOptionalFields(oldFormInfo, optionalFields, user)
        );
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getOptionalFields();
  }, []);
  const updateForm = (name, value) => {
    setFormModified(true);
    setFormInfo(oldInfo => {
      switch (name) {
        case 'country':
          const state = LocaleService.states(value)?.[0] || '';
          return {
            ...oldInfo,
            country: value,
            state
          };
        default:
          return { ...oldInfo, [name]: value };
      }
    });
    setFormError(oldError => ({ ...oldError, [name]: '' }));
  };

  const updateThreeWords = (index, value) => {
    setFormInfo(oldInfo => {
      oldInfo.threeWords.splice(index, 1, value);
      return { ...oldInfo };
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const { formError, missingFields } = validateForm(formInfo, optionalFields);
    const hasFieldsMissing = missingFields.length > 0;

    if (hasFieldsMissing) {
      const [firstMissingField] = missingFields;
      const firstMissingElement = document.getElementsByName(
        firstMissingField
      )[0];
      firstMissingElement?.scrollIntoView({ block: 'center' });
      setFormError(formError);
      return;
    }
    const newUserData = getNewUserData(formInfo, user);
    setSubmitting(true);
    try {
      if (avatarData.file) {
        const avatarResponse = await CMSService.updateAvatar(avatarData.file);
        newUserData.avatar = avatarResponse.data[0];
      }
      const response = await CMSService.updateUser(newUserData);
      const updatedUser = response.data;

      const updatedUserObject = {
        ...user,
        ...updatedUser,
        roles: user.roles
      };
      createToast({
        message: 'Your account info has been updated!',
        size: 'small'
      });
      setGetUser(updatedUserObject);
      setFormModified(false);
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  const changeAvatar = e => {
    const [file] = e.target.files;
    setFormModified(true);
    const src = URL.createObjectURL(file);
    setAvatarData({ file, src });
  };

  return {
    loading,
    optionalFields,
    formInfo,
    updateForm,
    updateThreeWords,
    formError,
    handleSubmit,
    formModified,
    submitting,
    avatarData,
    changeAvatar
  };
};

export default useAccountDetails;

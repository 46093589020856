import styled from 'styled-components';
import Button from 'components/NewLyncworkUIKit/CustomButton';

const breakPoint = '770px';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${props => props.theme.colors.neutral.white};
  min-width: 20vw;
  max-width: 40vw;
  padding: 24px;
  border-radius: 8px;
  @media only screen and (max-width: ${breakPoint}) {
    width: 80vw;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  margin-left: 4px;
  border-radius: 50%;
  background-color: transparent;
  color: ${props => props.theme.colors.neutral.black};

  &:hover {
    background-color: ${props => props.theme.colors.neutral.offWhite03};
  }
  &:focus-visible {
    outline: 2px solid blue;
  }
`;

export const Title = styled.h1`
  ${props => props.theme.typography.header3};
  margin-top: 0;
  font-weight: 600;
`;

export const Tagline = styled.h2`
  ${props => props.theme.typography.control};
  margin: 8px 0 0;
  font-weight: 600;
`;

export const Message = styled.p`
  ${props => props.theme.typography.control};
  max-width: 85%;
  margin: 4px 32px 4px 0;
`;

export const ErrorMessage = styled.span`
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.feedback.error};
  margin: 4px 0;
`;

export const ButtonContainer = styled.div`
  margin: 8px 0 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const CustomButton = styled(Button)`
  width: 100px;
  margin-left: 10px;
  align-self: center;
`;
CustomButton.defaultProps = {
  size: 'medium'
};

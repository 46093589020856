import styled from 'styled-components';

import { typography, colors } from 'designSystem/foundations';

import { Avatar } from 'components/NewLyncworkUIKit';

const { header2, control, metadata } = typography;
const {
  neutral: { grey10, grey07, grey02, offWhite01 }
} = colors;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledAvatar = styled(Avatar)`
  width: 100px;
  height: 100px;
  font-size: 44px;
  &.small {
    width: 24px;
    height: 24px;
    font-size: 12px;
  }
`;

export const MainInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 28px;

  h2 {
    ${header2};
    font-weight: 600;
    margin: 26px 0 0;
  }

  p.employee-info-roles {
    ${control};
    color: ${grey07};
    margin: 8px 0 0;
  }

  .employee-info-teams-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 20px 0 0;
    gap: 8px;
  }
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.small}) {
    align-items: center;
  }
`;

export const TeamChip = styled.div`
  box-sizing: border-box;
  padding: 4px 8px;
  border-radius: 12px;
  background: ${offWhite01};
  ${metadata};
  font-weight: 500;
`;

export const SectionContainer = styled.div`
  border-top: 1px solid ${grey02};
  padding: 24px 0;
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${control};
  color: ${grey07};
  label {
    color: ${grey10};
    font-weight: 600;
    margin: 0 0 4px;
  }
`;

export const CloseButtonContainer = styled.div`
  float: right;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${control};
  color: ${grey07};
  label {
    color: ${grey10};
    font-weight: 600;
    margin: 0 0 0;
  }
  ul {
    padding-left: 18px;
  }
`;

export const KeyContactDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StickyContainer = styled.div`
  position: sticky;
  background: white;
  z-index: 5;
  top: -2vw;
  margin: 0 0 0 0;
  padding: 32px;
  box-sizing: border-box;
  height: calc(100vh - 60px);
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 5px;
  }
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.small}) {
    position: relative;
    height: calc(100vh - 136px);
    top: 0;
    //hacky way of overcoming the pagecontainer
    width: calc(100% + 8vw);
    left: -4vw;
  }
`;

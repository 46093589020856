import colors from './colors';
import typography from './typography';
import breakPoints from './breakPoints';

const theme = {
  colors,
  typography,
  breakPoints
};

export { colors, typography, breakPoints };

export default theme;

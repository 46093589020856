import styled from 'styled-components';

const singleWidth = 280;

export const Container = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
`;

export const WeekContainer = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: ${singleWidth}px;
  display: flex;
  border-right: 1px solid ${props => props.theme.colors.neutral.grey05};
  border-bottom: 1px solid ${props => props.theme.colors.neutral.offWhite03};
  &:last-child {
    border-right: 1px solid transparent;
  }
`;

export const DayContainer = styled.div`
  width: 40px;
  height: 100%;
  border-right: 1px solid ${props => props.theme.colors.neutral.offWhite03};
  &:last-child {
    border-right: none;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 240px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    height: 60%;
    width: auto;
    object-fit: contain;
  }

  span {
    text-align: center;
    margin: 8px 60px;
    ${props => props.theme.typography.control};
    color: ${props => props.theme.colors.neutral.grey07};
  }
`;

import React, { useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Input, Search, IconSearch } from './styles';

const CustomSearch = ({
  className = '',
  value,
  placeholder = '',
  onChange = () => {},
  width = '225px',
  offWhite = false,
  onSearch = () => {}
}) => {
  const [selected, toggleSelected] = useState(false);

  const onEnterFunc = e => {
    if (e.key === 'Enter') {
      onSearch();
    }
  };

  return (
    <Search
      className={className}
      selected={selected}
      offWhite={offWhite}
      width={width}
    >
      <IconSearch
        className="search-icon"
        onClick={onSearch}
        selected={!!value}
      />
      <Input
        onFocus={() => toggleSelected(true)}
        onBlur={() => toggleSelected(false)}
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={e => onEnterFunc(e)}
      />
    </Search>
  );
};

export default CustomSearch;

import React from 'react';
import { Column, Cell, HeaderCell } from './styles';
const NameColumn = ({ journeyTasks, openTaskModal }) => {
  return (
    <Column>
      <HeaderCell>Name</HeaderCell>
      {journeyTasks.map(task => {
        const {
          order,
          task: { taskEventName }
        } = task;
        const handleClick = () => openTaskModal(task);
        return (
          <Cell key={order} onClick={handleClick}>
            {taskEventName}
          </Cell>
        );
      })}
    </Column>
  );
};

export default NameColumn;

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  width: 312px;
  display: flex;
  flex-direction: row;
  align-items: center;
  &.lw-toast-container-large {
    flex-direction: column;
    align-items: flex-start;
  }
  ${props => props.theme.typography.control};
  color: white;

  .MuiSvgIcon-root {
    font-size: 20px;
    margin-right: 12px;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  font-weight: 600;
  align-items: center;
  button {
    margin-left: auto;
    color: ${props => props.theme.colors.neutral.grey02};
  }
`;

export const Message = styled.p`
  margin: 0 auto 0 0;
  &.lw-toast-message-large {
    margin: 10px 32px 0;
  }
`;

export const StyledLink = styled(Link)`
  flex-shrink: 0;
  color: white;
  font-weight: 600;
  margin: 0 0 0 16px;
  &.lw-toast-link-large {
    box-sizing: border-box;
    padding: 6px 0;
    margin: 10px 32px 0 auto;
  }
`;

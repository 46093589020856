import styled from 'styled-components';
import colors from 'designSystem/foundations/colors';

const {
  brand: { primary },
  neutral: { grey05 }
} = colors;

export const Container = styled.div`
  display: flex;
  margin: 0 0 14px 0;
  ${props => props.theme.typography.control};
  font-weight: 600;
  color: ${props => props.theme.colors.neutral.grey05};
`;

export const Divider = styled.div`
  position: relative;
  width: 24px;
  margin: 0 4px;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 1px;
    background: ${props => (props.mark ? primary : grey05)};
  }
`;

export const Step = styled.div`
  color: ${props => (props.mark ? primary : grey05)};
`;

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Card from 'components/Card';

export const Container = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 24px;
  grid-row: 2/3;
  grid-column: 3/4;
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey10};
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.medium}) {
    flex-direction: row;
  }
  span {
    margin: 0 40px 4px 0;
    @media only screen and (max-width: 1400px) {
      margin: 0 20px 4px 0;
    }
  }
  img {
    position: absolute;
    bottom: 0;
    right: 40px;
    height: 70px;
    width: auto;
    @media only screen and (max-width: 1200px) {
      display: none;
    }
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

import React from 'react';
import { Add } from '@material-ui/icons';

import { CustomSearch, CustomButton } from 'components/NewLyncworkUIKit';
import { SearchBarContainer } from './styles';

const SearchBar = ({ value, onInputChange, onClick }) => {
  return (
    <SearchBarContainer>
      <CustomSearch
        className="search-input"
        offWhite
        placeholder="Search Applications in Role"
        value={value}
        onChange={onInputChange}
      />
      <CustomButton size="medium" onClick={onClick}>
        <Add fontSize="inherit" />
        Add Application
      </CustomButton>
    </SearchBarContainer>
  );
};

export default SearchBar;

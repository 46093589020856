import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Container } from './styles';
const LoadingScreen = () => {
  return (
    <Container>
      <CircularProgress color="primary" size="80px" />
    </Container>
  );
};
export default LoadingScreen;

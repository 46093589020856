import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  gap: 24px;
  flex-grow: 1;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const LicenseName = styled.h2`
  text-transform: capitalize;
  margin: 0 auto 0 0;
  ${props => props.theme.typography.body};
  font-weight: 600;
  color: ${props => props.theme.colors.neutral.grey10};
`;

export const DetailContainer = styled.div`
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey07};
  strong {
    color: ${props => props.theme.colors.neutral.grey10};
  }
  .roles {
    display: flex;
    gap: 4px;
  }
`;

import { useState, useEffect } from 'react';
import { AhaService } from 'services/aha.service';

const useAha = () => {
  const [ahaData, setAhaData] = useState({ roles: [], products: [] });

  useEffect(() => {
    let mounted = true;
    const fetchProducts = async () => {
      try {
        const response = await AhaService.getProducts();
        mounted && setAhaData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchProducts();
    return () => {
      mounted = false;
    };
  }, []);

  return ahaData;
};

export default useAha;

import styled, { css } from 'styled-components';
import colors from 'designSystem/foundations/colors';

const {
  feedback: { confirmation, warning02 },
  neutral: { grey10, grey07 }
} = colors;

const disableHover = css`
  pointer-events: none;
`;

export const Container = styled.div`
  position: sticky;
  z-index: 5;
  top: 0;
  margin: 0 0 0 -4vw;
  padding: 16px 64px 16px 40px;
  box-sizing: border-box;
  width: calc(100% + 8vw);
  background: white;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);

  display: flex;
  align-items: center;
  justify-content: space-between;

  transform: translateY(-2vw);
  button {
    ${props => props.emailSent && disableHover}
    .MuiSvgIcon-root {
      margin-right: 4px;
    }
    .MuiSvgIcon-fontSizeInherit {
      font-size: 1rem;
    }
  }
`;

export const StatusTitle = styled.span`
  display: flex;
  align-items: center;
  font-weight: 600;
  color: ${grey10};

  &:before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-right: 8px;
    background-color: ${props =>
      props.status === 'MONITORED' ? warning02 : confirmation};
  }
`;

export const StatusDesc = styled.span`
  margin: 0 0 0 16px;
`;

export const TextContainer = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  ${props => props.theme.typography.control};
  color: ${grey07};
`;

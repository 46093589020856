import axios from 'axios';
import env from '../env';

const { storeDomain, serviceDomain } = env;

export const AhaService = {
  getProducts: () => {
    return axios.get(`${serviceDomain}/aha/api/aha/products`);
  }
};

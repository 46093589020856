import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import cloud from './cloud.svg';
import { useDropzone } from 'react-dropzone';

import {
  Container,
  Title,
  ZoneContainer,
  DropZoneContainer,
  AcceptedFileType
} from './styles';
import { fileSizeLimit } from 'pages/Documents/constant';

const UploadArea = ({ uploadFile, noTitle, noSetHeight }) => {
  const [loading, setLoading] = useState(false);
  const onDrop = async files => {
    setLoading(true);
    try {
      for (const file of files) {
        await uploadFile(file);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.pdf, .doc',
    maxSize: fileSizeLimit
  });
  return (
    <Container>
      {!noTitle && <Title>Upload</Title>}

      <ZoneContainer noSetHeight={noSetHeight}>
        {loading ? (
          <CircularProgress />
        ) : (
          <DropZoneContainer noSetHeight={noSetHeight} {...getRootProps()}>
            <input {...getInputProps()} />
            <img src={cloud} alt="" />
            Drag and drop, or click to upload
          </DropZoneContainer>
        )}
      </ZoneContainer>
      <AcceptedFileType>Accepted file types: PDF, Doc only</AcceptedFileType>
      <AcceptedFileType>Maximum Size: 25MB</AcceptedFileType>
    </Container>
  );
};

export default UploadArea;

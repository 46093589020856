import styled, { css } from 'styled-components';
import { colors } from 'designSystem/foundations';
import Card from 'components/Card';

const {
  neutral: { grey10, grey07 },
  brand: { primary, primaryLite }
} = colors;
export const Container = styled(Card)`
  padding: 32px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 16px;
`;

export const CardContainer = styled(Card)`
  padding: 32px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 16px;
  margin-top: 20px;
`;

export const Header = styled.h2`
  ${props => props.theme.typography.header3};
  font-weight: 600;
  margin: 0;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  button {
    margin: 20px 0 0;
    align-self: flex-end;
  }
`;

export const SkillName = styled.span`
  ${props => props.theme.typography.control};
  color: ${grey10};
  font-weight: 500;
`;

export const SliderContainer = styled.div`
  pointer-events: none;
  margin: 12px 0 0;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 16px;
  display: flex;
  align-items: center;
`;

const sliderThumbStyle = css`
  position: relative;
  top: 50%;
  box-sizing: border-box;
  width: 16px;
  -webkit-appearance: none;
  height: 16px;
  cursor: ew-resize;
  border: 1px solid ${primary};
  border-radius: 50%;
  background-color: white;
`;

export const SliderInput = styled.input`
  position: relative;
  z-index: 2;
  width: 100%;
  -webkit-appearance: none;
  border-radius: 3px;
  background-color: transparent;
  padding: 0;
  margin: 0;
  &::-webkit-slider-runnable-track {
    height: 6px;
    -webkit-appearance: none;
  }

  &::-webkit-slider-thumb {
    transform: translateY(-50%);
    ${sliderThumbStyle}
  }
  &::-moz-range-thumb {
    ${sliderThumbStyle}
  }
`;

SliderInput.defaultProps = { type: 'range' };

export const Progress = styled.div`
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 5px;
  height: 6px;
  border-radius: 3px;
  width: 100%;
  padding: 0 2px;
  background: ${primaryLite};
  &::after {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    width: ${props => props.width};
    background: ${primary};
  }
`;

export const ProgressDot = styled.div`
  position: relative;
  z-index: 2;
  width: 4px;
  height: 4px;
  background: ${props => (props.isBefore ? primaryLite : primary)};
  border-radius: 2px;
  &:first-child {
    opacity: 0;
  }
`;

export const SkillLevelTextContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

export const SkillLevelText = styled.span`
  width: 25%;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
  ${props => props.theme.typography.metadata};
  color: red;
  color: ${props => (props.isSelected ? grey10 : grey07)};
  font-weight: ${props => (props.isSelected ? 600 : 400)};
  &:last-child {
    width: 12.5%;
    text-align: right;
  }
  &:first-child {
    display: none;
  }
`;

import React from 'react';
import { Grid } from './styles';
import { CompanyOverview, Journeys, People } from './components';
const AdminDashboard = () => {
  return (
    <Grid>
      <CompanyOverview />
      <Journeys />
      <People />
    </Grid>
  );
};

export default AdminDashboard;

import { useState, useEffect } from 'react';

import { ApplicationCategoryService } from 'services/applicationCategory.service';

const useApplicationCategories = () => {
  const [applicationCategories, setApplicationCategories] = useState([]);
  useEffect(() => {
    let updateState = true;
    const fetchCategories = async () => {
      try {
        const response = await ApplicationCategoryService.list();
        const categories = response.data;
        if (updateState) {
          setApplicationCategories(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchCategories();
    return () => {
      updateState = false;
    };
  }, []);
  return applicationCategories;
};

export default useApplicationCategories;

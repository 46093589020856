import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import colors from 'designSystem/foundations/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2em;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: -10px 9px 21px #f4f5f7;
  border-radius: 4px;
`;
export const HotBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 2em;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: -10px 9px 21px #f4f5f7;
  border-radius: 4px;
`;
export const StyledNavLink = styled(NavLink)`
  &.onboarding-nav-link {
    position: relative;
    text-decoration: none;

    &:hover {
      background-color: #f4f5f7;
    }
    p {
      font-family: Work Sans;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
      /* identical to box height, or 143% */

      color: ${colors.neutral.grey07};
      margin: 8px;
      text-decoration: none;
    }
  }
  &.onboarding-nav-link-active {
    p {
      font-weight: 600;
      color: ${colors.neutral.grey10};
    }
    .active-div {
      opacity: 1;
    }
    border-bottom: 2px solid ${colors.neutral.grey10};
  }
`;
export const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 135px;
  max-width: 100%;
  align-self: left;
  margin-bottom: 1em;
`;
export const Breadcrumb = styled.h1`
  box-sizing: border-box;
  ${props => props.theme.typography.title3};
  font-weight: 600;
  color: ${props => props.theme.colors.neutral.grey10};
  margin: 3px auto 0 0;
`;

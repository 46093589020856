import React, { useState, useEffect } from 'react';

import { useMobileTutorial, steps } from 'context/MobileTutorialContext';

import Prompt from './Prompt';
import { UnmaskedElement, Background } from './styles';
import { getTutorialStyle, defaultTutorialStyle } from './utils';

const totalSteps = steps.length;

const Tutorial = () => {
  const { currentStep, next, exitTutorial } = useMobileTutorial();
  const { stepNumber, unmaskedElement, prompt, clickElement } = currentStep;

  const [loading, setLoading] = useState(true);
  const [{ promptStyle, unmaskedElementStyle }, setTutorialStyle] =
    useState(defaultTutorialStyle);

  useEffect(() => {
    const setTutorialStyles = async () => {
      setLoading(true);
      const newTutorialStyle = await getTutorialStyle(unmaskedElement, prompt);
      setTutorialStyle(newTutorialStyle);
      setLoading(false);
    };
    setTutorialStyles();
  }, [unmaskedElement, prompt]);

  if (loading) {
    return <Background showBackground />;
  }

  const handleNext = () => {
    setLoading(true);
    next();
  };

  const unMaskedElementClick = handleNext ? next : undefined;

  return (
    <Background showBackground={!unmaskedElement}>
      {unmaskedElement && (
        <UnmaskedElement
          style={unmaskedElementStyle}
          clickElement={clickElement}
          onClick={unMaskedElementClick}
        />
      )}
      <Prompt
        clickElement={clickElement}
        exitTutorial={exitTutorial}
        handleNext={handleNext}
        prompt={prompt}
        totalSteps={totalSteps}
        stepNumber={stepNumber}
        promptStyle={promptStyle}
      />
    </Background>
  );
};

export default Tutorial;

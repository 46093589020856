const colorOptions = ['#4a90e2', '#50e3c2', '#9013fe', '#f5a623', '#ff6b6b'];

const getRandomColor = (key = null) => {
  if (key === null) {
    const index = Math.random() % colorOptions.length;
    return colorOptions[index];
  }
  if (key === -1) {
    return 'transparent';
  }
  const index = key % colorOptions.length;
  return colorOptions[index];
};

export default getRandomColor;

import React from 'react';

import { useButtonTrack } from 'matomo/hooks';
import { rolesListPageEvents } from 'pages/Roles/instrumentationEvents';
import generateMediaUrl from 'utilities/generateMediaUrl';

import { Row, Cell } from 'components/ListTable';
import { CustomButton } from 'components/NewLyncworkUIKit';
import HiddenCounter from 'components/HiddenCounter';

import {
  AdminAccess,
  AvatarContainer,
  StyledAvatar,
  StyledLink
} from './styles';

const RoleApplications = ({ applications, totalApplications }) => {
  const remainingAppCount = totalApplications - 5;
  return (
    <AvatarContainer>
      {applications.map(({ id, appName, appLogo }) => {
        const src = generateMediaUrl(appLogo);
        return <StyledAvatar key={id} colorKey={id} src={src} name={appName} />;
      })}
      {remainingAppCount > 0 && (
        <HiddenCounter>+{remainingAppCount}</HiddenCounter>
      )}
    </AvatarContainer>
  );
};

const RoleUsers = ({ users, totalUsers }) => {
  const remainingUserCount = totalUsers - 5;
  return (
    <AvatarContainer>
      {users.map(({ id, firstName, avatar }) => {
        const { url, thumbnail } = avatar || {};
        const src = thumbnail || url;
        return (
          <StyledAvatar
            key={id}
            colorKey={id}
            src={src}
            name={firstName}
            objectFit="cover"
          />
        );
      })}
      {remainingUserCount > 0 && (
        <HiddenCounter>+{remainingUserCount}</HiddenCounter>
      )}
    </AvatarContainer>
  );
};

const RoleCard = ({ role }) => {
  const trackButton = useButtonTrack();

  const {
    id,
    roleName,
    adminAccess,
    applications,
    totalApplications,
    users,
    totalUsers
  } = role;
  const adminAccessText = adminAccess ? 'Enabled' : 'Disabled';
  const adminAccessStatus = adminAccessText.toLowerCase();
  const editLink = `/admin-roles/edit/${id}`;
  const handleEditClick = () => trackButton(rolesListPageEvents.edit_role);

  return (
    <Row>
      <Cell className="list-row-name">{roleName}</Cell>
      <Cell>
        <AdminAccess adminAccessStatus={adminAccessStatus}>
          {adminAccessText}
        </AdminAccess>
      </Cell>
      <Cell flex={2}>
        <RoleApplications
          applications={applications}
          totalApplications={totalApplications}
        />
      </Cell>
      <Cell flex={2}>
        <RoleUsers users={users} totalUsers={totalUsers} />
      </Cell>
      <Cell>
        <StyledLink to={editLink}>
          <CustomButton
            variant="secondary"
            size="small"
            tabIndex="-1"
            onClick={handleEditClick}
          >
            Edit
          </CustomButton>
        </StyledLink>
      </Cell>
    </Row>
  );
};

export default RoleCard;

import {
  StyledCard,
  LogoContainer,
  DescriptionContainer,
  Information,
  RedeemButton
} from './styles';
import NetflixLogo from './place-holders/netflix.png';
import SpotifyLogo from './place-holders/spotify.png';
import StarbucksLogo from './place-holders/starbucks.png';
import AmazonLogo from './place-holders/amazon.png';

const Logo = ({ logo }) => {
  switch (logo) {
    case 'Netflix':
      return <img src={NetflixLogo} />;
    case 'Spotify':
      return <img src={SpotifyLogo} />;
    case 'Starbucks':
      return <img src={StarbucksLogo} />;
    case 'Amazon':
      return <img src={AmazonLogo} />;
    default:
      return <img src={AmazonLogo} />;
  }
};

const RewardCard = ({ product }) => {
  const { logo, backgroundColor } = product;
  return (
    <StyledCard>
      <LogoContainer backgroundColor={backgroundColor}>
        <Logo logo={logo} />
      </LogoContainer>
      <DescriptionContainer>
        <Information>
          <h2>$10</h2>
          <p>{logo} Credit</p>
        </Information>
        <RedeemButton>Redeem</RedeemButton>
      </DescriptionContainer>
    </StyledCard>
  );
};

export default RewardCard;

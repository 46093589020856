import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import MobileTutorialContext from 'context/MobileTutorialContext';
import MobileTutorial from 'components/MobileTutorial';
import Menu from 'navigation';

import AccountDetails from 'pages/AccountDetails';

import AdminDashboard from 'pages/AdminDashboard';
import AdminCommunity from 'pages/AdminCommunity';
import Dashboard from 'pages/DashBoard';
import CompanyDirectory from 'pages/CompanyDirectory';
import Community from 'pages/Community';

import RolesPage from 'pages/Roles';

import EngagementAnalytics from 'pages/EngagementAnalytics';
import RewardPoints from 'pages/RewardPoints';

import TeamsPage from 'pages/Teams';

import UsersPage from 'pages/Users';

import ApplicationPage from 'pages/Application';

import CompanyInfo from 'pages/CompanyInfo';

import UserJourneysPage from 'pages/UserJourneys';

import Post from 'pages/Posts/Post';
import UserHelpPage from 'pages/UserHelp';

import NotFound404Page from 'pages/NotFound404Page';

import { Documents } from 'pages/Documents';

import AdminOnboarding from 'pages/AdminOnboarding';

import Journeys from 'pages/Journeys';

import { Container, PageContainerContent, SideNavBackdrop } from './styles';

// this might be ripped out later
import useEngagement from 'hooks/useEngagement';

import { isDev } from 'env';
const PageContainer = props => {
  const isEngagementAvailable = useEngagement();
  return (
    <MobileTutorialContext>
      <Container className="page-container">
        <MobileTutorial />
        <Menu />
        <PageContainerContent
          className="page-container-content"
          id="page-container-content"
        >
          <Switch>
            <Route path="/" exact>
              <Redirect to="/dashboard" />
            </Route>
            <Route path="/dashboard" exact component={Dashboard} />
            <Route path="/community" exact component={Community} />
            <Route path="/community/posts/:id" exact component={Post} />
            <Route path="/user-journeys" component={UserJourneysPage} />
            <Route
              path="/company-directory/:id?"
              component={CompanyDirectory}
            />
            <Route path="/account-details" exact component={AccountDetails} />

            {isDev && isEngagementAvailable && (
              <Route path="/reward-points" exact component={RewardPoints} />
            )}
            {isEngagementAvailable && (
              <Route
                path="/admin-engagement-analytics"
                exact
                component={EngagementAnalytics}
              />
            )}
            <Route path="/company-info" exact component={CompanyInfo} />
            <Route path="/admin-company-info" exact component={CompanyInfo} />
            <Route path="/admin-dashboard" exact component={AdminDashboard} />
            <Route path="/admin-community" component={AdminCommunity} />
            <Route path="/admin-teams" component={TeamsPage} />
            <Route path="/admin-roles" component={RolesPage} />
            <Route path="/admin-users" component={UsersPage} />
            <Route path="/admin-journeys" component={Journeys} />
            <Route path="/admin-onboarding" component={AdminOnboarding} />
            <Route path="/admin-applications" component={ApplicationPage} />
            <Route path="/admin-documents" component={Documents} />
            <Route path="/help" component={UserHelpPage} />
            <Route component={NotFound404Page} />
          </Switch>
        </PageContainerContent>
      </Container>
    </MobileTutorialContext>
  );
};

export default PageContainer;

import React, { useEffect, useState } from 'react';
import { Route, Switch, useParams, useHistory } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import { SubscriptionAppService } from 'services/subscriptionApp.service';

import BackButton from 'components/BackButton';
import useToast from 'hooks/useToast';

import { SubscriptionOverview, SubscriptionForm } from './components';
import { Container, ContentContainer } from './styles';

const LoadingScreen = () => (
  <Container>
    <BackButton />
    <CircularProgress />
  </Container>
);

const SingleSubscriptionApp = () => {
  const { createToast } = useToast();
  const { id } = useParams();
  const history = useHistory();
  const [subscriptionApp, setSubscriptionApp] = useState(null);
  useEffect(() => {
    let mounted = true;
    const fetchSubscription = async () => {
      try {
        const response = await SubscriptionAppService.find(id);
        if (mounted) {
          setSubscriptionApp(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchSubscription();
    return () => {
      mounted = false;
    };
  }, [id]);

  if (!subscriptionApp) {
    return <LoadingScreen />;
  }

  const { application, properties, licenseManagements } = subscriptionApp;

  const handleSave = async (formValue, licenses) => {
    const newProperties = application.applicationProperties.map(property => {
      const { name: key } = property;
      const value = formValue[key];
      const propertyObject = properties.find(property => property.key === key);
      const id = propertyObject?.id;
      return { ...property, value, key, id };
    });

    const licenseOrders = licenseManagements
      .map(license => license.licenseOrder)
      .sort((a, b) => b - a);
    const newLicenseManagements = licenses.map((license, index) => {
      return { ...license, licenseOrder: licenseOrders[index] };
    });

    const requestData = {
      ...subscriptionApp,
      properties: newProperties,
      licenseManagements: newLicenseManagements
    };

    await SubscriptionAppService.update(requestData);
    history.push('/admin-applications/my-apps/');
    createToast({
      title: 'Application Edit Saved',
      message: `Your edits to the ${application.appName} application integration has been saved successfully`
    });
  };

  const handleCancel = () => history.goBack();

  const deleteIntegration = async () => {
    await SubscriptionAppService.delete(id);
    history.push('/admin-applications/my-apps/');
    createToast({
      title: 'Application Deleted',
      message: `Your ${application.appName} application integration has been deleted`
    });
  };

  return (
    <Container>
      <BackButton />
      <Switch>
        <Route path="/admin-applications/my-apps/:id" exact>
          <SubscriptionOverview
            subscriptionApp={subscriptionApp}
            deleteIntegration={deleteIntegration}
          />
        </Route>
        <Route path="/admin-applications/my-apps/:id/edit" exact>
          <SubscriptionForm
            licenseManagements={licenseManagements}
            application={application}
            properties={properties}
            handleSave={handleSave}
            handleCancel={handleCancel}
          />
        </Route>
      </Switch>
    </Container>
  );
};

export default SingleSubscriptionApp;

import styled from 'styled-components';
import Card from 'components/Card';
import { Avatar } from 'components/NewLyncworkUIKit';
export const Container = styled(Card)`
  width: 112px;
  height: 112px;
  border-radius: 50%;
  background: white;
  box-sizing: border-box;
  position: absolute;
  z-index: 2;
  overflow: hidden;
  top: 76px;
  left: 24px;
`;

export const UploadContainer = styled.div`
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(10, 10, 10, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
  opacity: 0;
  &:hover {
    opacity: 1;
  }
  span {
    ${props => props.theme.typography.metadata};
    color: white;
    background-color: ${props => props.theme.colors.neutral.grey07};
    width: 100%;
    text-align: center;
  }

  input {
    cursor: pointer;
    position: absolute;
    z-index: 2;
    box-sizing: border-box;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: pink;
  }
`;

export const StyledAvatar = styled(Avatar)`
  width: 100%;
  height: 100%;
`;

StyledAvatar.defaultProps = {
  fontSize: '60px'
};

import React from 'react';
import error from 'assets/images/error.svg';
import logo from 'assets/images/navIcons/Logo.svg';

import {
  Container,
  ErrorImage,
  TextContainer,
  Title,
  Description,
  ButtonContainer,
  Button,
  LogoContainer,
  Logo
} from './styles';

const ErrorFallback = () => {
  const goBack = () => {
    window.location.reload();
  };
  return (
    <Container>
      <LogoContainer>
        <Logo src={logo} alt="logo" />
        Lyncwork
      </LogoContainer>
      <ErrorImage src={error} alt="not-found-img" />
      <TextContainer>
        <Title>Hmm...</Title>
        <Description>
          Looks like something went wrong. The server encountered a temporary
          error and could not complete your request. For now, let’s find a
          better place for you to go.
        </Description>
        <ButtonContainer>
          <Button onClick={goBack}>Go Back</Button>
        </ButtonContainer>
      </TextContainer>
    </Container>
  );
};

export default ErrorFallback;

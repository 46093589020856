import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { scrollBar } from 'StyleVariables/StandardComponents';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.h1`
  ${props => props.theme.typography.title3};
  font-weight: 600;
  color: ${props => props.theme.colors.neutral.grey10};
  margin: 0 0 24px;
`;

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 12px 0;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  margin-left: 16px;
`;

export const TableBody = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const Table = styled.div`
  box-sizing: border-box;
  margin: 28px 0;
  background: ${props => props.theme.colors.neutral.white};
  border: none;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 600px;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
`;

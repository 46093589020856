import React from 'react';
import { ArrowForward, Assignment } from '@material-ui/icons';
import {
  Container,
  Title,
  Description,
  Link,
  ArrowContainer,
  IconContainer
} from './styles';
const EngagementCard = () => {
  return (
    <Container>
      <IconContainer>
        <Assignment color="inherit" />
      </IconContainer>
      <Title>Engagement Points</Title>
      <Description>
        Please sign these 3 employee documents, thank you so much
      </Description>
      <Link href="#">
        Learn More
        <ArrowContainer>
          <ArrowForward color="inherit" fontSize="inherit" />
        </ArrowContainer>
      </Link>
    </Container>
  );
};

export default EngagementCard;

import React from 'react';
import { CustomButton } from 'components/NewLyncworkUIKit';
import { useButtonTrack } from 'matomo/hooks';
import { userFileImportPageEvents } from 'pages/Users/UsersListPage/instrumentationEvents';
import useDialogue from 'hooks/useDialogue';

import { TableContainer, TableHeader, TableRow } from './Table';

import {
  Container,
  Title,
  Paragraph,
  ButtonContainer,
  SelectionCountText
} from './styles';
import { areRequiredOptionsSelected } from './utilities';

const getSelectionCountText = selectionCount => {
  switch (selectionCount) {
    case 1:
      return `${selectionCount} user selected`;
    default:
      return `${selectionCount} users selected`;
  }
};
const { select_all_users, select_user, field_mapping_change } =
  userFileImportPageEvents;

const MapData = ({ csvData, setCSVData, nextPage, previousPage }) => {
  const trackButton = useButtonTrack();
  const { showDialogue, closeDialogue } = useDialogue();
  const { header, users, selected } = csvData;
  const handleFieldChange = (option, indexToChange) => {
    trackButton(field_mapping_change);
    setCSVData(oldData => {
      const { header } = oldData;
      const newHeader = header.map((value, index) => {
        if (index !== indexToChange) {
          return value;
        }
        return option;
      });

      return { ...oldData, header: newHeader };
    });
  };

  const handleSelectAll = e => {
    trackButton(select_all_users);
    const { checked } = e.target;
    if (checked) {
      setCSVData(oldData => {
        const { users } = oldData;
        return { ...oldData, selected: [...users] };
      });
      return;
    }

    setCSVData(oldData => ({ ...oldData, selected: [] }));
  };

  const selectionCount = selected.length;
  const isAllSelected = selectionCount === users.length;
  const { isValid, unselectedOptions } = areRequiredOptionsSelected(header);

  const handleNext = () => {
    if (!isValid) {
      const message = unselectedOptions.map(({ label }) => label).join(', ');
      showDialogue({
        title: 'Please select the following fields',
        message,
        confirmButtonText: 'Okay',
        confirmButtonAction: closeDialogue
      });
      return;
    }
    if (selectionCount < 1) {
      showDialogue({
        title: 'Please select at least one user',
        message: '',
        confirmButtonText: 'Okay',
        confirmButtonAction: closeDialogue
      });
      return;
    }
    nextPage();
  };

  const selectionCountText = getSelectionCountText(selectionCount);
  return (
    <>
      <Container>
        <Title>Match Information</Title>
        <Paragraph>
          Please select a field category from the dropdown menu to match the
          imported user information. All required fields (marked by{' '}
          <strong>*</strong>) must be provided to successfully import users to
          Lyncwork. Columns with an unselected field will be omitted.
        </Paragraph>
        <SelectionCountText>{selectionCountText}</SelectionCountText>
        <TableContainer>
          <TableHeader
            csvData={csvData}
            handleFieldChange={handleFieldChange}
            handleSelectAll={handleSelectAll}
            isAllSelected={isAllSelected}
          />
          {users.map((userData, index) => {
            const userSelected = selected.includes(userData);
            const toggleSection = () => {
              setCSVData(oldData => {
                const { selected: oldSelected } = oldData;
                if (userSelected) {
                  const newSelected = oldSelected.filter(
                    data => data !== userData
                  );
                  return {
                    ...oldData,
                    selected: newSelected
                  };
                }
                return { ...oldData, selected: [...oldSelected, userData] };
              });
            };
            return (
              <TableRow
                key={index}
                userData={userData}
                userSelected={userSelected}
                toggleSection={toggleSection}
              />
            );
          })}
        </TableContainer>
      </Container>
      <ButtonContainer>
        <CustomButton variant="secondary" onClick={previousPage}>
          Cancel
        </CustomButton>
        <CustomButton onClick={handleNext}>Next</CustomButton>
      </ButtonContainer>
    </>
  );
};

export default MapData;

import React, { useEffect, useState, useRef } from 'react';
import { ApplicationService } from 'services/application.service';

import { CustomButton } from 'components/NewLyncworkUIKit';
import {
  GuideContainer,
  StickyContainer,
  CredentialContainer,
  CredentialButton,
  CredentialTitle
} from './styles';

import CredentialContent from './CredentialContent';

const GuideContent = ({ credentials }) => {
  const containerRef = useRef();
  const [selectedCredential, setSelectedCredential] = useState(credentials[0]);

  useEffect(() => {
    containerRef.current.scroll({ top: 0 });
  }, [selectedCredential]);

  return (
    <GuideContainer ref={containerRef}>
      <StickyContainer>
        <CredentialContainer>
          {credentials.map(credential => {
            const { name, label } = credential;
            const selected = selectedCredential === credential;
            const onClick = () => setSelectedCredential(credential);
            return (
              <CredentialButton
                type="button"
                key={name}
                selected={selected}
                onClick={onClick}
              >
                {label}
              </CredentialButton>
            );
          })}
        </CredentialContainer>
        <CredentialTitle>
          How to find {selectedCredential.label}
        </CredentialTitle>
      </StickyContainer>
      <CredentialContent credential={selectedCredential} />
    </GuideContainer>
  );
};

const SetupGuide = ({ id }) => {
  const [{ credentials, loading }, setData] = useState({
    credentials: [],
    loading: true
  });
  const hasGuide = credentials.length > 0;
  useEffect(() => {
    if (!id) {
      return;
    }
    let mounted = true;
    const getHelperText = async () => {
      setData({ loading: true, credentials: [] });
      try {
        const response = await ApplicationService.helperTextById(id);
        mounted && setData({ credentials: response.data, loading: false });
      } catch (error) {
        mounted && setData({ loading: false, credentials: [] });
        console.log(error);
      }
    };
    getHelperText();
    return () => {
      mounted = false;
    };
  }, [id]);

  if (!id || loading || !hasGuide) {
    return null;
  }

  return <GuideContent credentials={credentials} />;
};

export default SetupGuide;

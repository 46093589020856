import React from 'react';
import { LoadingState, HoppingCircle } from './styles.js';

const LoadingDots = () => {
  return (
    <LoadingState>
      <HoppingCircle delay={0} /> <HoppingCircle delay={1} />{' '}
      <HoppingCircle delay={2} /> <HoppingCircle delay={3} />{' '}
    </LoadingState>
  );
};
export default LoadingDots;

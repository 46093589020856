import React from 'react';
import parse from 'html-react-parser';

import generateMediaUrl from 'utilities/generateMediaUrl';

import { Title, StepContainer } from './styles';

const generateUrl = image => {
  const isBase64 = image.startsWith('data:image/');
  if (isBase64) {
    return image;
  }
  return generateMediaUrl(image);
};

const Step = ({ step, stepNumber }) => {
  const { image, title, description } = step;
  const src = generateUrl(image);
  return (
    <StepContainer>
      <h3>
        Step {stepNumber}: {title}
      </h3>
      <p>{parse(description)}</p>
      <img src={src} alt={title} />
    </StepContainer>
  );
};

const CredentialContent = ({ credential }) => {
  const { label, steps } = credential;
  return steps.map((step, index) => (
    <Step key={step.id} stepNumber={index + 1} step={step} />
  ));
};

export default CredentialContent;

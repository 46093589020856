import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors } from 'designSystem/foundations';
import Card from 'components/Card';

const {
  brand: { primary, primaryLite },
  neutral: { grey10, grey07, grey06, grey03, grey02, offWhite01 },
  feedback: { confirmation }
} = colors;

export const Container = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 24px 24px;
  grid-row: 1/2;
  grid-column: 3/4;
  overflow: auto;
  .MuiCircularProgress-root {
    align-self: center;
    margin: auto;
    justify-self: center;
  }
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.medium}) {
    max-height: 360px;
  }
`;

export const EmptyImage = styled.img`
  max-width: 32%;
  max-height: 32%;
  object-fit: contain;
  margin: auto;
`;

export const EmptyMessage = styled.span`
  text-align: center;
  ${props => props.theme.typography.metadata};
  font-weight: 600;
  color: ${grey07};
`;

export const HeaderContainer = styled.div`
  padding: 24px 0 0;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  background: white;
`;

export const Header = styled.h2`
  margin: 0;
  ${props => props.theme.typography.header2};
  color: ${grey10};
  font-weight: 600;
`;

export const AgendaOverview = styled.span`
  ${props => props.theme.typography.metadata};
  color: ${grey07};
  padding: 0 0 16px 0;
  margin: 16px 0;
  border-bottom: 1px solid ${grey03};
`;

export const TaskHeader = styled.h3`
  ${props => props.theme.typography.metadata};
  color: ${primary};
  font-weight: 500;
  margin: 0 0 4px;
`;

export const TaskCardContainer = styled(Link)`
  margin: 4px 0;
  border-radius: 8px;
  border: 1px solid ${grey02};
  padding: 8px 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  ${props => props.theme.typography.metadata};
  color: ${grey06};
  cursor: pointer;
  text-decoration: none;
  .task-title {
    color: ${grey10};
    margin-bottom: 4px;
  }
  .task-desc {
    flex-basis: 100%;
  }

  &:hover {
    border-color: ${grey06};
  }
  &:active {
    background: ${offWhite01};
  }
`;

export const TaskStatusCard = styled.span`
  padding: 2px 8px;
  border-radius: 4px;
  background-color: ${props => (props.taskDue ? primaryLite : '#F0F7FF')};
  color: ${props => (props.taskDue ? primary : confirmation)};
  ${props => props.theme.typography.footnote};
  font-weight: 600;
`;

import React, { useState } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';

import { DropdownService } from 'services';
import { CustomButton } from 'components/NewLyncworkUIKit';

import {
  FilterContainer,
  SearchBarContainer,
  Select,
  SearchInput,
  DropdownContainer
} from './styles';

const loadRoles = async inputValue => {
  const response = await DropdownService.roles({ name: inputValue });
  return response.data.content;
};
const loadTeams = async inputValue => {
  const response = await DropdownService.teams({ name: inputValue });
  return response.data.content;
};

const Filter = ({
  showFilters,
  clearFilter,
  role,
  team,
  updateParams,
  toggleFilter
}) => {
  const filterButtonClick = showFilters ? clearFilter : toggleFilter;
  return (
    <FilterContainer>
      <CustomButton size="small" variant="tertiary" onClick={filterButtonClick}>
        {showFilters ? (
          <CloseIcon fontSize="inherit" />
        ) : (
          <FilterListIcon fontSize="inherit" />
        )}
        {showFilters ? 'Clear Filter' : 'Filter'}
      </CustomButton>
      {showFilters && (
        <DropdownContainer>
          <Select
            name="role"
            value={role}
            loadOptions={loadRoles}
            onChange={updateParams}
            isClearable
          />
          <Select
            name="team"
            value={team}
            loadOptions={loadTeams}
            onChange={updateParams}
            isClearable
          />
        </DropdownContainer>
      )}
    </FilterContainer>
  );
};

const SearchBar = ({
  searchQuery,
  handleInputChange,
  role,
  team,
  changeParams
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const toggleFilter = () => setShowFilters(a => !a);
  const clearFilter = () => {
    toggleFilter();
    changeParams({ role: null, team: null });
  };

  const updateParams = (selectedOption, action) => {
    const { name } = action;
    changeParams({ [name]: selectedOption });
  };
  return (
    <>
      <SearchBarContainer>
        <SearchInput
          placeholder="Search by name..."
          value={searchQuery}
          onChange={handleInputChange}
        />
        <Filter
          showFilters={showFilters}
          clearFilter={clearFilter}
          role={role}
          team={team}
          updateParams={updateParams}
          toggleFilter={toggleFilter}
        />
      </SearchBarContainer>
    </>
  );
};

export default SearchBar;

export const usersListPageEvents = {
  change_page: `admin users change page`,
  change_filter: `admin users change filter`,
  select_user: 'admin users select/deselect user',
  select_all_user: 'admin users select/deselect all click',
  edit_user: 'admin users edit user',
  add_user: 'admin users add user',
  import_user: 'admin users import user',
  open_assign_modal: 'open user batch assignment modal',
  close_assign_modal: 'close user batch assignment modal',
  confirm_batch_assignment: 'confirm user batch assignment',
  open_delete_modal: 'open user batch deletion modal',
  close_delete_modal: 'close user batch deletion modal',
  confirm_batch_deletion: 'confirm user batch deletion'
};

export const singleUserPageEvents = {
  save_user: `save user info`,
  delete_user: `delete user attempt`,
  suspend_user: `suspend user attempt`,
  restore_user: `restore user attempt`,
  delete_user_confirm: `confirm user deletion`,
  suspend_user_confirm: `confirm user suspension`,
  restore_user_confirm: `confirm user status restoration`,
  discard_change: `discard user info change`
};

export const userFileImportPageEvents = {
  download_template: 'user import download template',
  see_example: 'user import see example',
  add_file: 'user import add file',
  remove_file: 'user import remove file',
  select_user: 'user import select/deselect',
  select_all_users: 'user import select/deselect all',
  field_mapping_change: 'user import change field mapping'
};
export const userAppImportPageEvents = {
  select_app: 'user import select app',
  select_user: 'user import select/deselect',
  select_all_users: 'user import select/deselect all'
};

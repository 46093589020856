import styled from 'styled-components';
import Card from 'components/Card';
import { multilineEllipsis } from 'StyleVariables/StandardComponents';

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  grid-row: 2/3;
  grid-column: 2/3;
  @media only screen and (max-width: 1200px) {
    grid-row: unset;
    grid-column: unset;
  }
`;

export const ContentContainer = styled(Card)`
  width: 100%;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  .MuiCircularProgress-root {
    margin: auto;
  }
  a {
    cursor: pointer;
    color: ${props => props.theme.colors.feedback.confirmation};
    margin: 8px 0 0;
  }
`;

export const Title = styled.h2`
  margin: 0;
  ${props => props.theme.typography.header2};
  font-weight: 600;
`;

export const ResourceLink = styled.button`
  cursor: pointer;
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.feedback.confirmation};
  margin: 8px 0 0;
  text-decoration: underline;
  background: none;
  border: none;
  text-align: left;
  width: 100%;
  padding: 0;
  white-space: normal;
  &:focus-visible {
    outline: 2px solid #0078d4;
  }
`;

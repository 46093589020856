import React from 'react';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  CartesianGrid
} from 'recharts';

import { ChartContainer } from './styles';

const CustomChart = ({ data }) => {
  return (
    <ChartContainer>
      <ResponsiveContainer className="responsive-container">
        <LineChart data={data} margin={{ right: 50 }}>
          <CartesianGrid vertical={false} />
          <Line type="linear" dataKey="points" stroke="#ff6c6c" />
          <YAxis stroke="#4d4d4d" />
          <XAxis dataKey="name" stroke="#4d4d4d" padding={{ left: 20 }} />
          <Tooltip cursor={false} />
        </LineChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
};

export default CustomChart;

import React, { useEffect, useState } from 'react';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import CircularProgress from '@material-ui/core/CircularProgress';

import formatDate from 'utilities/formatDate';

import useAuthentication from 'hooks/useAuthentication';
import useAnnouncements from 'hooks/useAnnouncements';

import illustration from './illustration.svg';
import {
  Container,
  ContentContainer,
  Title,
  AnnouncementContainer,
  AnnouncementDate,
  LoadingContainer
} from './styles';

const Announcement = ({ announcement }) => {
  const { content, updated_at } = announcement;
  const date = formatDate(updated_at, 'MMM d, yyyy');
  return (
    <AnnouncementContainer>
      <AnnouncementDate>{date}</AnnouncementDate>
      <span>{content}</span>
    </AnnouncementContainer>
  );
};

const Overview = () => {
  const { loading, announcements } = useAnnouncements({
    size: 1
  });

  const announcement = announcements[0];
  const { user } = useAuthentication();
  const { firstName } = user;

  return (
    <Container data-tutorial-id="overview">
      <ContentContainer>
        <Title>Hello {firstName}</Title>
        <div className="announcement-text">
          <RecordVoiceOverIcon />
          Company Announcements
        </div>
        {loading ? (
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        ) : announcement ? (
          <Announcement announcement={announcement} />
        ) : (
          <span>There are currently no company announcements.</span>
        )}
      </ContentContainer>
      <img src={illustration} alt="illustration" />
    </Container>
  );
};

export default Overview;

import React from 'react';
import checkmark from 'assets/images/Onboarding/checkmark.svg';

import {
  Container,
  ProgressNode,
  ProgressTitle,
  ProgressIndicator,
  ProgressLineLeft,
  ProgressLineRight,
  ProgressCircle
} from './styles';

const getActiveIndex = (data, pathName) => {
  const matchingItem = data.find(item => item.pathName === pathName);
  return data.indexOf(matchingItem);
};

const getLeftLineStatus = (currentIndex, activeIndex) => {
  if (currentIndex === 0) {
    return 'none';
  }
  return currentIndex <= activeIndex ? 'active' : 'inactive';
};

const getRightLineStatus = (currentIndex, activeIndex, lastIndex) => {
  if (currentIndex === lastIndex) {
    return 'none';
  }
  return currentIndex < activeIndex ? 'active' : 'inactive';
};

const getProgressStatus = (currentIndex, activeIndex) => {
  if (currentIndex === activeIndex) {
    return 'current';
  }
  return currentIndex < activeIndex ? 'complete' : 'incomplete';
};

const Progress = ({ data, pathName }) => {
  const activeIndex = getActiveIndex(data, pathName);
  const lastIndex = data.length - 1;
  return (
    <Container>
      {data.map((item, index) => {
        const {
          progress: { title }
        } = item;
        const leftLineStatus = getLeftLineStatus(index, activeIndex);
        const rightLineStatus = getRightLineStatus(
          index,
          activeIndex,
          lastIndex
        );

        const progressStatus = getProgressStatus(index, activeIndex);
        return (
          <ProgressNode key={item.pathName}>
            <ProgressTitle active={index <= activeIndex}>{title}</ProgressTitle>
            <ProgressIndicator>
              <ProgressLineLeft status={leftLineStatus} />
              <ProgressCircle status={progressStatus}>
                {progressStatus === 'complete' && <img src={checkmark} />}
              </ProgressCircle>
              <ProgressLineRight status={rightLineStatus} />
            </ProgressIndicator>
          </ProgressNode>
        );
      })}
    </Container>
  );
};

export default Progress;

import React, { useState } from 'react';
import getRandomColor from 'utilities/getRandomColor';
import { Container, StyledImage } from './styles';

const Avatar = ({
  className,
  src,
  colorKey = -1,
  name = ' ',
  objectFit = 'cover',
  fontSize,
  size
}) => {
  const [showAvatar, setShowAvatar] = useState(src && true);
  const backgroundColor = showAvatar ? 'transparent' : getRandomColor(colorKey);
  const firstInitial = name.toUpperCase()[0];
  const handleImageError = () => setShowAvatar(false);
  const handleImageLoad = () => setShowAvatar(true);
  return (
    <Container
      size={size}
      className={className}
      fontSize={fontSize}
      backgroundColor={backgroundColor}
    >
      <StyledImage
        src={src}
        alt={name}
        objectFit={objectFit}
        onError={handleImageError}
        onLoad={handleImageLoad}
        showAvatar={showAvatar}
      />
      {firstInitial}
    </Container>
  );
};

export default Avatar;

import React from 'react';
import { CustomButton } from 'components/NewLyncworkUIKit';
import { UserImportService } from 'services/userImport.service';
import {
  Container,
  Title,
  Paragraph,
  TableContainer,
  ButtonContainer,
  ReviewText
} from './styles';

import Table from './Table';
const Review = ({ previousPage, nextPage, userArray, hasLicenseInfo }) => {
  const handleNextPage = async () => {
    try {
      await UserImportService.upload(userArray);
      nextPage();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Container>
        <Title>Import Preview</Title>
        <Paragraph>
          Please go over the following users to be imported and their associated
          information. You can always update this information later.
        </Paragraph>
        <ReviewText>
          Total Users To Import: <strong>{userArray.length}</strong>
        </ReviewText>
        <Table userArray={userArray} hasLicenseInfo={hasLicenseInfo} />
      </Container>
      <ButtonContainer>
        <CustomButton variant="secondary" onClick={previousPage}>
          Cancel
        </CustomButton>
        <CustomButton onClick={handleNextPage}>Next</CustomButton>
      </ButtonContainer>
    </>
  );
};

export default Review;

import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';

import PortalContainer from 'components/PortalContainer';
import { CustomSelect, CustomButton } from 'components/NewLyncworkUIKit';
import { useButtonTrack } from 'matomo/hooks';
import { companyBasicInfoEvents } from 'pages/CompanyInfo/instrumentationEvents';

import {
  Container,
  Title,
  SectionTitle,
  TextInput,
  FormContainer,
  SelectContainer,
  Label,
  ButtonContainer,
  CloseButton
} from './styles';
import {
  formatCompanyInfo,
  getOtherInputProps,
  validate,
  formatTempInfo,
  getStateOptions,
  countryOptions
} from './utils';

const Select = ({ options, selectId, onChange, selectedOption, label }) => {
  const value = options.find(option => option.value === selectedOption) || '';
  const onMenuOpen = () => {
    setTimeout(() => {
      const container = document.getElementById(selectId);
      const selectMenu = container.getElementsByClassName('lw-select__menu')[0];
      if (selectMenu) {
        selectMenu.scrollIntoView();
      }
    }, 5);
  };
  return (
    <SelectContainer>
      <Label>
        {label}<span>*</span>
      </Label>
      <CustomSelect
        id={selectId}
        value={value}
        isClearable={false}
        options={options}
        onChange={onChange}
        onMenuOpen={onMenuOpen} />
    </SelectContainer>
  );
};

const Input = ({ inputProps }) => {
  const {
    name,
    required,
    fullWidth,
    label,
    value,
    onChange,
    error
  } = inputProps;
  return (
    <TextInput
      error={!!error}
      subLabel={error}
      value={value}
      name={name}
      fullWidth={fullWidth}
      label={label}
      required={required}
      onChange={onChange}
    />
  );
};

const { discard_changes, save_changes } = companyBasicInfoEvents;

const ModalContent = ({ closeModal, companyInfo, updateCompany }) => {
  const trackButton = useButtonTrack(companyBasicInfoEvents.save_changes);
  const [tempInfo, setTempInfo] = useState(formatCompanyInfo(companyInfo));
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const handleInputChange = e => {
    const { name, value } = e.target;
    setTempInfo(oldInfo => ({ ...oldInfo, [name]: value }));
    setErrors(oldErrors => ({ ...oldErrors, [name]: '' }));
  };

  const getInputProps = key => {
    const { required, fullWidth, label } = getOtherInputProps(key);
    const value = tempInfo[key];
    const error = errors[key];

    return {
      name: key,
      required,
      fullWidth,
      label,
      error,
      value,
      onChange: handleInputChange
    };
  };

  const handleCountryChange = ({ value }) => {
    setTempInfo(oldInfo => {
      if(oldInfo.country === value) {
        return oldInfo;
      }
      return { ...oldInfo, country: value, state: '' };
    });
  };

  const handleStateChange = ({ value }) => {
    setTempInfo(oldInfo => ({ ...oldInfo, state: value }));
  };

  const handleSubmit = async () => {
    trackButton(save_changes);
    const { hasError, errors: newErrors } = validate(tempInfo);
    if (hasError) {
      setErrors(newErrors);
      return;
    }

    const newCompanyInfo = formatTempInfo(tempInfo, companyInfo);
    setLoading(true);
    try {
      await updateCompany(newCompanyInfo);
      closeModal();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDiscard = () => {
    trackButton(discard_changes);
    closeModal();
  };

  const stateOptions = getStateOptions(tempInfo.country);

  return (
    <Container onClick={e => e.stopPropagation()}>
      <CloseButton onClick={closeModal} disabled={loading} />
      <Title>Edit Info</Title>
      <FormContainer>
        <SectionTitle>General Info</SectionTitle>
        <Input inputProps={getInputProps('companyName')} />
        <Input inputProps={getInputProps('phonePrimary')} />
        <Input inputProps={getInputProps('website')} />
        <SectionTitle>Address</SectionTitle>
        <Select
          options={countryOptions}
          selectedOption={tempInfo.country}
          onChange={handleCountryChange}
          label="Country"
          selectId="country-select"
          />
        {stateOptions.length > 0 && (
          <Select
            options={stateOptions}
            selectedOption={tempInfo.state}
            onChange={handleStateChange}
            label="State/Province"
            selectId="state-select"
            />
        )}
        <Input inputProps={getInputProps('lineOne')} />
        <Input inputProps={getInputProps('lineTwo')} />
        <Input inputProps={getInputProps('city')} />
        <Input inputProps={getInputProps('postalCode')} />
        <SectionTitle>Social Media</SectionTitle>
        <Input inputProps={getInputProps('instagramUrl')} />
        <Input inputProps={getInputProps('linkedinUrl')} />
        <Input inputProps={getInputProps('mediumUrl')} />
        <Input inputProps={getInputProps('twitterUrl')} />
      </FormContainer>
      <ButtonContainer>
        <CustomButton
          variant="secondary"
          onClick={handleDiscard}
          disabled={loading}
        >
          Cancel
        </CustomButton>
        <CustomButton onClick={handleSubmit} disabled={loading}>
          {loading ? <CircularProgress color="inherit" size="16px" /> : 'Save'}
        </CustomButton>
      </ButtonContainer>
    </Container>
  );
};

const CompanyInfoEditModal = ({
  isOpen,
  closeModal,
  companyInfo,
  updateCompany
}) => {
  return (
    <PortalContainer handleClickOutside={closeModal} isOpen={isOpen}>
      <ModalContent
        closeModal={closeModal}
        companyInfo={companyInfo}
        updateCompany={updateCompany}
      />
    </PortalContainer>
  );
};

export default CompanyInfoEditModal;

import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.h1`
  color: ${props => props.theme.colors.neutral.grey10};
  ${props => props.theme.typography.title3};
  font-weight: 600;
  margin: 0 0 24px;
`;

export const Container = styled.div`
  position: relative;
  padding: 48px;
  background-color: ${props => props.theme.colors.neutral.white};
  height: 500px;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

export const ImportImage = styled.img`
  position: absolute;
  right: 48px;
  bottom: 0;
`;

export const Title = styled.h2`
  color: ${props => props.theme.colors.neutral.grey10};
  ${props => props.theme.typography.header2};
  font-weight: 600;
  margin: 0 0 56px;
`;

export const RadioSubLabel = styled.span`
  display: block;
  margin: 0 0 24px 16px;
  color: ${props => props.theme.colors.neutral.grey07};
  ${props => props.theme.typography.control};
`;

export const ButtonContainer = styled.div`
  margin: 32px 0 0 0;
  display: flex;
  justify-content: flex-end;
  button {
    margin: 0 0 0 16px;
  }
`;

import React from 'react';

import {
  PointsRedeemedCard,
  CurrentPointsCard,
  EngagementChart,
  RewardsLeaderBoard
} from './components';
import { Container, StatContainer, ChartContainer } from './styles';

const RewardPoints = () => {
  return (
    <Container>
      <StatContainer>
        <CurrentPointsCard />
        <PointsRedeemedCard />
        <RewardsLeaderBoard />
      </StatContainer>
      <ChartContainer>
        <EngagementChart />
      </ChartContainer>
    </Container>
  );
};
export default RewardPoints;

import styled from 'styled-components';
export const H2 = styled.h2`
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  margin: 0;
  padding: 0 0 0 8.4px;
`;
export const P = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */
  letter-spacing: 0.777778px;
  margin: 1em 0;
  color: #000000;
  padding-left: var(--ck-spacing-standard);
`;
export const Category = styled.div`
  padding: 0.35em;
  margin-bottom: 16px;
  margin-left: var(--ck-spacing-standard);

  z-index: 2;
  color: white;
  border-radius: 4px;
  width: fit-content;
  background-color: ${props =>
    props.colorOptions[props.id % props.colorOptions.length]};
`;

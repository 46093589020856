import React, { useEffect, useRef, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import options from 'assets/images/GenericIcons/options.svg';
import star from 'assets/images/GenericIcons/star.svg';
import trash from 'assets/images/GenericIcons/trash.svg';

import { CustomSelect, CustomButton } from 'components/NewLyncworkUIKit';
import PageTransitionBlock from 'components/PageTransitionBlock';

import useAuthentication from 'hooks/useAuthentication';
import useToast from 'hooks/useToast';

import { useButtonTrack } from 'matomo/hooks';
import PreviewModal from 'pages/AdminOnboarding/PreviewModal';
import getPageData from 'pages/Onboarding/UserOnboarding/getPageData';
import PersonalInfoUser from 'pages/Onboarding/UserOnboarding/pages/PersonalInfo';
import { defaultFields } from 'pages/Onboarding/UserOnboarding/pages/PersonalInfo/defaultFields';
import { adminOnboardingPersonalInfoPageEvents } from 'pages/AdminOnboarding/instrumentationEvents';
import { PageSettingService } from 'services/page-settings';

import {
  Container,
  GroupTitle,
  ButtonContainer,
  ButtonSpacer,
  RequiredStar,
  FakeInput,
  GroupContainer,
  OptionsLightbox,
  LightboxRow,
  Icon,
  Options,
  Spacer
} from './styles';

const {
  save_fields,
  open_preview,
  close_preview,
  add_field,
  remove_field,
  mark_field
} = adminOnboardingPersonalInfoPageEvents;

const Input = ({ field, removeField, toggleRequired, index }) => {
  const [open, setOpen] = useState(false);
  const node = useRef();
  const handleClick = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    setOpen(false);
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);
  const onRemove = () => {
    removeField(field, index);
    setOpen(false);
  };
  const onToggleRequired = () => {
    toggleRequired(field, index);
    setOpen(false);
  };
  return (
    <>
      <FakeInput field={field}>
        {field.label}
        <RequiredStar required={field.required}>*</RequiredStar>
        {!field.isDefault && (
          <Options onClick={() => setOpen(true)}>
            <img src={options} />
          </Options>
        )}
        <OptionsLightbox open={open} ref={node}>
          <LightboxRow onClick={onToggleRequired}>
            <Icon src={star} />
            {field.required ? 'Make Optional' : 'Make Required'}
          </LightboxRow>
          <LightboxRow onClick={onRemove}>
            <Icon src={trash} />
            Remove
          </LightboxRow>
        </OptionsLightbox>
      </FakeInput>
      <br />
    </>
  );
};

const PersonalInfo = () => {
  const toastId = useRef(null);
  const { createToast, dismissToast } = useToast();
  const trackButton = useButtonTrack();
  const [requestData, setRequestData] = useState({});
  const [groups, setGroups] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [previewing, setPreviewing] = useState(false);
  const [changed, setChanged] = useState(false);

  const { user } = useAuthentication();
  useEffect(() => {
    (async () => {
      const { data } = await PageSettingService.personalInfo();
      let groupsWithDefaults = data.entitiesSettings;
      const findDefaults = string =>
        groupsWithDefaults.find(x => x.name === string);
      const addDefaults = obj => {
        groupsWithDefaults[groupsWithDefaults.indexOf(obj)] = {
          ...obj,
          companyFields: defaultFields
            .find(x => x.name === obj.name)
            .fields.concat(obj.companyFields)
        };
      };
      addDefaults(findDefaults('LyncWorkUser'));
      addDefaults(findDefaults('Address'));
      setRequestData({ id: data.id, pageName: data.pageName });
      setGroups(groupsWithDefaults);
    })();
  }, []);

  useEffect(() => {
    return () => dismissToast(toastId.current);
  }, [dismissToast]);

  const addField = (newField, index) => {
    trackButton(add_field);
    setChanged(true);
    let newGroups = [...groups];
    newGroups[index] = {
      ...newGroups[index],
      companyFields: [...newGroups[index].companyFields, newField]
    };
    setGroups(newGroups);
  };
  const removeField = (delField, index) => {
    trackButton(remove_field);

    setChanged(true);
    let newGroups = [...groups];
    newGroups[index] = {
      ...newGroups[index],
      companyFields: newGroups[index].companyFields.filter(
        x => x.label !== delField.label
      )
    };
    setGroups(newGroups);
  };
  const toggleRequired = (selectedField, index) => {
    trackButton(mark_field);

    setChanged(true);
    let newGroups = [...groups];
    let newFields = [...newGroups[index].companyFields];
    newFields = newFields.map(x => {
      if (x.label === selectedField.label) {
        return { ...x, required: !x.required };
      } else {
        return x;
      }
    });
    newGroups[index] = {
      ...newGroups[index],
      companyFields: newFields
    };
    setGroups(newGroups);
  };

  const openPreview = () => {
    trackButton(open_preview);
    setPreviewing(true);
  };
  const closePreview = () => {
    trackButton(close_preview);
    setPreviewing(false);
  };

  const saveFunction = async () => {
    trackButton(save_fields);
    setSubmitting(true);
    const requestBody = {
      ...requestData,
      entitiesSettings: groups
    };
    try {
      await PageSettingService.updatePersonalInfo(requestBody);
      toastId.current = createToast({
        size: 'small',
        message: 'Page edits saved',
        linkText: 'View Preview',
        linkAction: openPreview
      });
      setChanged(false);
    } catch (err) {
      console.log(err);
    }
    setSubmitting(false);
  };
  const setPreviewPageData = () => ({
    ...getPageData(user)[3],
    entitiesSettings: groups
  });

  return (
    <>
      <Container>
        {groups.map((group, index) => {
          const DDValues = [...group.companyFieldsAllowed]
            .filter(x => !group.companyFields.map(x => x.name).includes(x.name))
            .map(x => ({ ...x, label: x.label, value: `+ ${x.label}` }));
          return (
            <GroupContainer key={group.order} group={group}>
              <GroupTitle>{group.label}</GroupTitle>
              {group.companyFields.map(field => (
                <Input
                  key={field.name}
                  field={field}
                  index={index}
                  removeField={removeField}
                  toggleRequired={toggleRequired}
                />
              ))}
              {!!DDValues.length && (
                <CustomSelect
                  placeholder="Add Another Field"
                  menuPlacement="auto"
                  value={null}
                  options={DDValues}
                  onChange={x => addField(x, index)}
                />
              )}
            </GroupContainer>
          );
        })}
      </Container>
      <PreviewModal
        isOpen={previewing}
        handleClose={closePreview}
        previewTitle="Onboarding Personal information Page"
        pathName="/user/welcome/personal-information"
      >
        <PersonalInfoUser pageData={setPreviewPageData()} hideActionButtons />
        <Spacer className="spacer" />
      </PreviewModal>
      <PageTransitionBlock when={changed} />
      <ButtonContainer>
        <CustomButton onClick={openPreview} variant="secondary" width="160px">
          Preview
        </CustomButton>
        <ButtonSpacer />
        <CustomButton onClick={saveFunction} width="160px">
          {submitting ? (
            <CircularProgress color="inherit" size="20px" />
          ) : (
            'Save'
          )}
        </CustomButton>
      </ButtonContainer>
    </>
  );
};
export default PersonalInfo;

import axios from 'axios';
import env from '../env';
const pageSettingBaseUrl = `${env.storeDomain}/api/pages-settings`;
const baseUrl = `${env.storeDomain}/api/lync-work-users`;
const baseUrlFrontdoor = `${env.frontDoorDomain}/api/users`;

export const PageSettingService = {
  personalInfo: () => {
    return axios.get(`${pageSettingBaseUrl}/personal_info`);
  },
  updatePersonalInfo: body => {
    if (!body.id) {
      return axios.post(`${pageSettingBaseUrl}`, body);
    } else {
      return axios.put(`${pageSettingBaseUrl}`, body);
    }
  }
};

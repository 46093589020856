import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: -10px 9px 21px #f4f5f7;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 48px 0;
`;

export const ChartContainer = styled.div`
  width: 100%;
  height: 30vw;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 50px 0 30px;
`;

export const Title = styled.h1`
  margin: 0;
  padding: 0;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.2px;

  color: #4d4d4d;
`;

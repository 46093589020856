import styled from 'styled-components';
import Button from 'components/NewLyncworkUIKit/CustomButton';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: space-between;
  height: ${props => (props.isCompanyResource ? '90px' : '134px')};
  padding: 24px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  margin: 4px 0;
  border-radius: 10px;
`;

export const CustomButton = styled(Button)`
  width: 80px;
  align-self: center;
`;

CustomButton.defaultProps = {
  size: 'medium'
};

export const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  ${props => props.theme.typography.body};
  font-weight: 600;
  margin: 0;
`;

export const Description = styled.span`
  color: ${props => props.theme.colors.neutral.grey06};
  ${props => props.theme.typography.metadata};
`;

export const CheckedContainer = styled.div`
  display: flex;
  align-items: center;
  ${props => props.theme.typography.metadata};
  font-weight: 600;
  color: ${props => props.theme.colors.neutral.grey05};
`;

export const CheckMarkContainer = styled.div`
  width: 18px;
  height: 18px;
  margin-right: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${props => props.theme.colors.feedback.error};
`;

export const ButtonContainer = styled.div`
  margin-left: auto;
  flex-basis: 100%;
  display: flex;
`;

import React, { useState } from 'react';

import useAha from 'hooks/useAha';
import { getOptionValue } from 'utilities/dropdown';

import DeleteButton from 'components/DeleteButton';
import {
  CustomButton,
  IconButton as CloseButton,
  CustomSelect
} from 'components/NewLyncworkUIKit';
import { useRoleForm } from 'pages/Roles/components/RoleForm/RoleFormContext';

import {
  getRoleSelection,
  formatRoleSelection,
  closeMenuOnScroll,
  getOptionLabel
} from './utils';

import {
  Container,
  ContentContainer,
  ScrollContainer,
  CapabilityContainer,
  TitleContainer,
  ButtonContainer,
  AhaProductCard
} from './styles';

const AhaProduct = ({ ahaProduct, ahaRoles, roleSelection, onChange }) => {
  const { id: productId, name } = ahaProduct;
  const selectedRole = roleSelection[productId];

  const selectId = `role-${productId}`;

  return (
    <AhaProductCard>
      <span>{name}</span>
      <CustomSelect
        className="role-select"
        menuPosition="fixed"
        value={selectedRole}
        onChange={(...a) => console.log(a)}
        isClearable={false}
        closeMenuOnScroll={closeMenuOnScroll}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        options={ahaRoles}
        onChange={onChange}
      />
    </AhaProductCard>
  );
};

const AhaContent = ({ application, closeModal, ahaProducts }) => {
  const {
    formState: { subscriptionAppCapabilityProperties },
    removeApplication,
    editAhaCapability
  } = useRoleForm();
  const { applicationCapabilities: ahaRoles } = application;
  const [roleSelection, setRoleSelection] = useState(
    getRoleSelection(ahaProducts, subscriptionAppCapabilityProperties)
  );

  const handleDelete = () => {
    closeModal();
    removeApplication(application);
  };

  const handleSave = () => {
    const subscriptionAppCapabilityProperties = formatRoleSelection(
      roleSelection
    );
    editAhaCapability(application, subscriptionAppCapabilityProperties);
    closeModal();
  };
  return (
    <>
      <ContentContainer>
        <ScrollContainer>
          <CapabilityContainer>
            {ahaProducts.map(ahaProduct => {
              const { id: productId } = ahaProduct;
              const onChange = role =>
                setRoleSelection(oldSelection => ({
                  ...oldSelection,
                  [productId]: role
                }));
              return (
                <AhaProduct
                  key={productId}
                  ahaProduct={ahaProduct}
                  ahaRoles={ahaRoles}
                  roleSelection={roleSelection}
                  onChange={onChange}
                />
              );
            })}
          </CapabilityContainer>
        </ScrollContainer>
      </ContentContainer>
      <ButtonContainer>
        <DeleteButton size="small" onClick={handleDelete}>
          Remove from Role
        </DeleteButton>
        <CustomButton onClick={handleSave}>Save</CustomButton>
      </ButtonContainer>
    </>
  );
};

const Blank = ({ application, closeModal }) => {
  const { removeApplication } = useRoleForm();
  const handleDelete = () => {
    closeModal();
    removeApplication(application);
  };
  return (
    <>
      <ContentContainer />
      <ButtonContainer>
        <DeleteButton size="small" onClick={handleDelete}>
          Remove from Role
        </DeleteButton>
        <CustomButton disabled>Save</CustomButton>
      </ButtonContainer>
    </>
  );
};

const AhaModal = ({ application, closeModal }) => {
  const { products } = useAha();
  const { applicationCapabilities: ahaRoles } = application;
  const hasProducts = products.length > 0;

  return (
    <Container onClick={e => e.stopPropagation()}>
      <TitleContainer>
        <h3>AHA</h3>
        <CloseButton onClick={closeModal} />
        <h2>Edit Application Permissions</h2>
      </TitleContainer>
      {hasProducts ? (
        <AhaContent
          application={application}
          closeModal={closeModal}
          ahaProducts={products}
        />
      ) : (
        <Blank application={application} closeModal={closeModal} />
      )}
    </Container>
  );
};

export default AhaModal;

import styled from 'styled-components';
import showDemoFeature from 'utilities/showDemoFeature';

const render = showDemoFeature(window.location.hostname);

export const Grid = styled.div`
  display: grid;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr 1fr 72px;
  grid-template-rows: ${render ? '1fr 132px 1fr 400px' : '1fr 132px 1fr'};
  height: ${render ? 'auto' : '100%'};
  gap: 16px;
  width: 1400px;
  max-width: 100%;
  @media only screen and (max-width: 1200px) {
    grid-template-columns: 2fr 1fr;
  }
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.medium}) {
    height: unset;
    display: flex;
    flex-direction: column;
  }
`;

import axios from 'axios';
import env from '../env';

const baseUrl = `${env.storeDomain}/api/applications`;

const unSubscriptionBaseUrl = `${baseUrl}/unsubscription-apps`;

export const UnSubscriptionAppService = {
  list: ({ name = '', page = 0 }) => {
    return axios.get(`${unSubscriptionBaseUrl}?name=${name}&page=${page}`);
  }
};

export const ApplicationService = {
  list: (name = '', page = '0', size = '10', category = '') => {
    const searchParams = new URLSearchParams({
      name,
      page,
      size,
      category
    });
    const queryString = searchParams.toString();
    return axios.get(`${baseUrl}?${queryString}`);
  },

  create: data => {
    return axios.post(`${baseUrl}`, data);
  },

  update: data => {
    return axios.put(`${baseUrl}`, data);
  },

  delete: data => {
    //DATA should be id
    return axios.delete(`${baseUrl}/${data}`);
  },

  find: id => {
    return axios.get(`${baseUrl}/${id}`);
  },
  helperTextById: id => {
    const helperTextUrl = `${env.storeDomain}/api/application-helper-texts/application`;
    return axios.get(`${helperTextUrl}/${id}`);
  }
};

import React, { useEffect, useState } from 'react';
import HelloSign from 'hellosign-embedded';

import { useButtonTrack } from 'matomo/hooks';
import useDialogue from 'hooks/useDialogue';

import {
  HelloSignService,
  canFileBeViewed,
  signatureRequestSigned,
  downloadBlob
} from 'services/hellosign.service';
import { DropboxService } from 'services/dropbox.service';

import {
  Container,
  SectionContainer,
  Title,
  Tagline,
  CardContainer,
  ButtonContainer,
  CustomButton
} from './styles';

import { CompanyResourceCard, DocumentCard } from './components';

const helloSignClient = new HelloSign({ skipDomainVerification: true });

const UserWelcomeDocuments = ({ prevPage, nextPage }) => {
  const trackButton = useButtonTrack();
  const { showDialogue, closeDialogue } = useDialogue();
  const [signatureRequests, setSignatureRequests] = useState([]);
  const [companyResources, setCompanyResources] = useState([]);
  const [awaitingPDFList, setAwaitingPDFList] = useState([]);
  const [refetchCount, setRefetchCount] = useState(0);

  useEffect(() => {
    const fetchSignatureRequests = async () => {
      try {
        const response = await HelloSignService.getSignatureRequestByJWT();
        const fetchedSignatureRequests = response.data.content;
        setSignatureRequests(fetchedSignatureRequests);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchCompanyResources = async () => {
      try {
        const response = await DropboxService.findDropboxFileByCategoryAll();
        setCompanyResources(
          response.data.content
          // response.data.content.filter(x => x.externalGroupId === 'ONBOARDING')
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchSignatureRequests();
    fetchCompanyResources();
  }, []);

  useEffect(() => {
    if (!awaitingPDFList.length) {
      return;
    }
    let timeout = null;
    const refetch = async () => {
      try {
        const response = await HelloSignService.getSignatureRequestByJWT();
        const fetchedSignatureRequests = response.data.content;
        setSignatureRequests(fetchedSignatureRequests);
        setAwaitingPDFList(oldList => {
          const newList = oldList.filter(id => {
            const signatureRequest = fetchedSignatureRequests.find(
              request => request.id === id
            );
            return !canFileBeViewed(signatureRequest.eventType);
          });
          return newList;
        });
      } catch (error) {
        console.log(error);
      }
    };
    timeout = setTimeout(() => {
      refetch();
    }, 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, [awaitingPDFList]);

  const handleNextButtonClick = () => {
    const readyForNextPage = signatureRequests.every(request =>
      canFileBeViewed(request.eventType)
    );
    if (readyForNextPage) {
      nextPage();
      return;
    }

    const pendingPDF = signatureRequests.every(request => {
      const pdfReady = canFileBeViewed(request.eventType);
      const awaitingPDF = awaitingPDFList.includes(request.id);
      return pdfReady || awaitingPDF;
    });

    if (pendingPDF) {
      showDialogue({
        title: 'One moment please...',
        message: 'Please wait for the documents to be processed',
        confirmButtonText: 'OK',
        confirmButtonAction: closeDialogue
      });
      return;
    }

    showDialogue({
      title: 'Unsigned Document',
      message: 'Please sign all documents before proceeding',
      confirmButtonText: 'OK',
      confirmButtonAction: closeDialogue
    });
  };

  return (
    <>
      <Container>
        {signatureRequests.length > 0 && (
          <SectionContainer>
            <Title>Sign Documents</Title>
            <Tagline>Please sign these employee documents</Tagline>
            <CardContainer>
              {signatureRequests.map(request => {
                const { title, message, id, eventType, signatureRequestInfo } =
                  request;
                const { signatureRequestId } = signatureRequestInfo;

                const handleViewFile = async () => {
                  trackButton('onboarding - download signed document');
                  const response = await HelloSignService.getFileBySignatureId(
                    signatureRequestId
                  );
                  const fileName = `${title}.pdf`;
                  downloadBlob(response.data, fileName);
                };

                const handleSignDocuments = async () => {
                  trackButton('onboarding - sign document');
                  try {
                    const signatureRequestInfo =
                      await HelloSignService.getSignUrl(id);
                    const { clientId, signUrl } = signatureRequestInfo.data;
                    helloSignClient.open(signUrl, { clientId });
                    helloSignClient.on('sign', () =>
                      setAwaitingPDFList(oldList => {
                        if (oldList.includes(id)) {
                          return oldList;
                        }
                        return [...oldList, id];
                      })
                    );
                  } catch (error) {
                    console.log(error);
                  }
                };

                const awaitingPDF = awaitingPDFList.includes(id);
                const readyForView = canFileBeViewed(eventType);

                return (
                  <DocumentCard
                    awaitingPDF={awaitingPDF}
                    readyForView={readyForView}
                    key={id}
                    title={title}
                    description={message}
                    handleViewFile={handleViewFile}
                    handleSignDocuments={handleSignDocuments}
                  />
                );
              })}
            </CardContainer>
          </SectionContainer>
        )}
        {companyResources.length > 0 && (
          <SectionContainer>
            <Title>Company Resources</Title>
            <Tagline>
              Please review the following documents before your start date, you
              can click open to download the documents
            </Tagline>
            <CardContainer>
              {companyResources.map(file => {
                const { id, description, signingTitle } = file;
                const downloadFile = async () => {
                  trackButton('onboarding - download company resource');

                  const { dropboxFileId, fileName } = file;
                  const response = await DropboxService.downloadUser(
                    dropboxFileId
                  );
                  const blob = response.data;
                  downloadBlob(blob, fileName);
                };
                return (
                  <CompanyResourceCard
                    key={id}
                    title={signingTitle}
                    description={description}
                    downloadFile={downloadFile}
                  />
                );
              })}
            </CardContainer>
          </SectionContainer>
        )}
        <ButtonContainer>
          <CustomButton variant="secondary" onClick={prevPage}>
            Back
          </CustomButton>
          <CustomButton onClick={handleNextButtonClick}>Next</CustomButton>
        </ButtonContainer>
      </Container>
    </>
  );
};

export default UserWelcomeDocuments;

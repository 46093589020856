import React, { createContext, useState } from 'react';
import style, { ThemeProvider } from 'styled-components';

import theme from './foundations';
import '../index.css';

export const DarkModeContext = createContext();

const darkModeBrand = { primary: 'green', primary01: 'orange' };

const darkTheme = {
  ...theme,
  colors: { ...theme.colors, brand: darkModeBrand }
};

const GlobalThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);
  const toggleDarkMode = () => setDarkMode(prevState => !prevState);
  const value = { darkMode, toggleDarkMode };
  return (
    <DarkModeContext.Provider value={value}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </DarkModeContext.Provider>
  );
};

export default GlobalThemeProvider;

import { Grid } from './styles';
import RewardCard from './RewardCard';

const RewardGrid = () => {
  return (
    <Grid>
      <RewardCard product={{ logo: 'Netflix', backgroundColor: '#000000' }} />
      <RewardCard product={{ logo: 'Amazon', backgroundColor: '#222E3C' }} />
      <RewardCard product={{ logo: 'Spotify', backgroundColor: '#191414' }} />
      <RewardCard product={{ logo: 'Starbucks', backgroundColor: '#007041' }} />
    </Grid>
  );
};

export default RewardGrid;

import React from 'react';
import styled from 'styled-components';
import DoneIcon from '@material-ui/icons/Done';

export const CheckMarkContainer = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 2px solid ${props => props.theme.colors.feedback.success};
  background-color: ${props => props.theme.colors.feedback.success};
  font-size: 1em;
  .MuiSvgIcon-root {
    color: white;
    font-size: inherit;
  }
`;

const CheckMark = ({ className }) => {
  return (
    <CheckMarkContainer className={className}>
      <DoneIcon />
    </CheckMarkContainer>
  );
};

export default CheckMark;

import React from 'react';

import { UserService } from 'services';
import { useButtonTrack } from 'matomo/hooks';
import { useDialogue, useAuthentication, useUse, useToast } from 'hooks';
import BackButton from 'components/BackButton';

import { singleUserPageEvents } from 'pages/Users/UsersListPage/instrumentationEvents';

import { WorkDetailForm } from './components';
import { formatEmailPrimary, convertToUTC } from './utils';
import { Breadcrumb } from './styles';

const { save_user } = singleUserPageEvents;

const UserAddPage = () => {
  const { createToast } = useToast();
  const trackButton = useButtonTrack();
  const { user: loggedInUser } = useAuthentication();
  const { domain } = loggedInUser.company;

  const handleSubmit = async workDetails => {
    trackButton(save_user);
    const {
      firstName,
      lastName,
      emailPrimary,
      provisionUserDate,
      directReports,
      mentee
    } = workDetails;
    const formattedEmailPrimary = formatEmailPrimary(emailPrimary);
    const newEmailPrimary = `${formattedEmailPrimary}@${domain}`;

    const userInfo = {
      ...workDetails,
      provisionUserDate: convertToUTC(provisionUserDate),
      emailPrimary: newEmailPrimary,
      directReportsInserted: directReports,
      menteeInserted: mentee
    };
    await UserService.create(userInfo);
    const name = `${firstName} ${lastName}`;
    createToast({
      title: 'User Added',
      message: `${name} has been added successfully.`
    });
  };

  return (
    <>
      <BackButton link="/admin-users" />
      <Breadcrumb>User Details</Breadcrumb>
      <WorkDetailForm domain={domain} handleSubmit={handleSubmit} />
    </>
  );
};

export default UserAddPage;

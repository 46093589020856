import React, { useState } from 'react';
import { differenceInCalendarDays } from 'date-fns';
import { useParams } from 'react-router-dom';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';

import { UserService } from 'services';

import { Container, TextContainer, StatusTitle, StatusDesc } from './styles';
import { CustomButton } from 'components/NewLyncworkUIKit';

import delay from 'utilities/delay';

const isStatusValid = status => {
  switch (status) {
    case 'PENDING_ACTIVATION':
    case 'PENDING_EMAIL_NOTIFICATION':
    case 'MONITORED':
      return true;
    default:
      return false;
  }
};

const getStatusTitle = (status, endDate) => {
  switch (status) {
    case 'PENDING_ACTIVATION':
      return 'Pending User Activation';
    case 'PENDING_EMAIL_NOTIFICATION':
      return 'Pending Activation Email Delivery';
    case 'MONITORED':
      const lastDay = new Date(endDate);
      const today = new Date();
      const remaining = differenceInCalendarDays(lastDay, today);
      return `Monitored Deprovisioning - ${remaining} days remaining`;
    default:
      return '';
  }
};
const getStatusDesc = status => {
  switch (status) {
    case 'PENDING_ACTIVATION':
      return 'An activation email has been sent to the user’s inbox. The user will be activated after completing the email.';
    case 'PENDING_EMAIL_NOTIFICATION':
      return 'The activation email will be delivered to the user’s inbox on their indicated provision date. To modify this, choose a different Provision Date below.';
    case 'MONITORED':
      return 'This user has begun monitored deprovisioning, no longer has access to their Lyncwork profile or any associated accounts. After the monitoring period expires, the user along with all of their associated application accounts will be fully deprovisioned.';
    default:
      return '';
  }
};

const ResendButtonContent = ({ emailStatus }) => {
  switch (emailStatus) {
    case 'sending':
      return <CircularProgress color="inherit" size="18px" />;
    case 'sent':
      return (
        <>
          <CheckIcon fontSize="inherit" /> Activation Email Sent
        </>
      );
    default:
      return 'Resend Activation Email';
  }
};

const StatusBanner = ({ lyncWorkUserStatus }) => {
  const { status, endDate } = lyncWorkUserStatus;
  const { id } = useParams();
  const [emailStatus, setEmailStatus] = useState('not-sent');
  const shouldRender = isStatusValid(status);
  if (!shouldRender) {
    return null;
  }
  const statusTitle = getStatusTitle(status, endDate);
  const statusDesc = getStatusDesc(status);
  const canResend = status === 'PENDING_ACTIVATION';

  const resendEmail = async () => {
    if (emailStatus !== 'not-sent') {
      return;
    }
    try {
      setEmailStatus('sending');
      const response = await UserService.resendEmail(id);
      await delay(500);
      setEmailStatus('sent');
    } catch (error) {
      console.log(error);
      setEmailStatus('not-sent');
    }
  };
  const buttonWidth = emailStatus === 'sending' ? '60px' : '180px';
  const buttonVariant = emailStatus === 'sent' ? 'tertiary' : 'secondary';
  return (
    <Container emailSent={emailStatus === 'sent'}>
      <TextContainer>
        <StatusTitle status={status}>{statusTitle}</StatusTitle>
        <StatusDesc>{statusDesc}</StatusDesc>
      </TextContainer>
      {canResend && (
        <CustomButton
          size="small"
          variant={buttonVariant}
          onClick={resendEmail}
          width={buttonWidth}
        >
          <ResendButtonContent emailStatus={emailStatus} />
        </CustomButton>
      )}
    </Container>
  );
};

export default StatusBanner;

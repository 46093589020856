import React from 'react';

import {
  CustomTextInput,
  Label,
  CustomToggle,
  CustomButton
} from 'components/NewLyncworkUIKit';

import { StyledCard, FieldContainer, HeaderContainer } from './styles';
import { primary_email_provider } from './utils';
import SetupGuide from './SetupGuide';

const EmailProviderToggle = ({ name, checked, onChange, label }) => {
  const toggleText = checked ? 'On' : 'Off';
  const toggleClassName = checked ? '' : 'toggled-off';
  return (
    <div>
      <Label>{label}</Label>
      <CustomToggle
        name={name}
        label={toggleText}
        className={toggleClassName}
        checked={checked}
        onChange={onChange}
      />
    </div>
  );
};

const IntegrationDetails = ({
  appId,
  fieldNames,
  formValue,
  formLabels,
  formError,
  onInputChange,
  handleToggle,
  enableEmailProviderToggle,
  authenticationRequired,
  authApplication
}) => {
  return (
    <StyledCard>
      <FieldContainer>
        <HeaderContainer>
          <h2>Integration Details</h2>
          {authenticationRequired && (
            <CustomButton size="medium" onClick={authApplication}>
              Authorize
            </CustomButton>
          )}
        </HeaderContainer>
        {fieldNames.map(name => {
          const value = formValue[name];
          const label = formLabels[name];
          const errorMessage = formError[name];
          return (
            <CustomTextInput
              required
              key={name}
              label={label}
              name={name}
              value={value}
              onChange={onInputChange}
              error={!!errorMessage}
              subLabel={errorMessage}
            />
          );
        })}
        {enableEmailProviderToggle && (
          <EmailProviderToggle
            name={primary_email_provider}
            onChange={handleToggle}
            checked={formValue[primary_email_provider]}
            label={formLabels[primary_email_provider]}
          />
        )}
      </FieldContainer>
      <SetupGuide id={appId} />
    </StyledCard>
  );
};

export default IntegrationDetails;

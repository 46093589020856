import axios from 'axios';
import env from '../env';

const baseUrl = `${env.storeDomain}/api/workflows`;

export const JourneyService = {
  list: params => {
    const {
      name = '',
      page = '0',
      size = '10',
      teams = '',
      roles = '',
      assignmentType = '',
      ...rest
    } = params;
    const searchParams = new URLSearchParams({
      name,
      page,
      size,
      teams,
      roles,
      assignmentType,
      ...rest
    });
    const queryString = searchParams.toString();
    return axios.get(`${baseUrl}?${queryString}`);
  },
  find: id => {
    return axios.get(`${baseUrl}/${id}`);
  },
  create: journey => {
    return axios.post(`${baseUrl}`, journey);
  },
  update: journey => {
    return axios.put(`${baseUrl}`, journey);
  },
  delete: id => {
    return axios.delete(`${baseUrl}/${id}`);
  }
};

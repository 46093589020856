import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-items: center;
  justify-content: center;
  width: 100%;
`;

import styled from 'styled-components';
import Card from 'components/Card';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FieldContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 16px 24px 24px;
  h2 {
    ${props => props.theme.typography.body};
    font-weight: 600;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

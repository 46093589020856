import React from 'react';

import {
  Container,
  Header,
  Description,
  InputGroupContainer,
  CustomInput
} from './styles';

const SocialInfo = ({ register }) => {
  return (
    <Container>
      <Header>Connect On Social Networks</Header>
      <Description>
        Add your social links and build your social network with your coworkers.
      </Description>
      <InputGroupContainer>
        <CustomInput
          name="linkedinUrl"
          label="LinkedIn"
          placeholder="www.linkedin.com/johndoe"
          inputRef={register}
        />
        <CustomInput
          name="mediumUrl"
          label="Medium"
          inputRef={register}
          placeholder="www.medium.com/johndoe"
        />
      </InputGroupContainer>
    </Container>
  );
};

export default SocialInfo;

import styled from 'styled-components';

import { CustomButton } from 'components/NewLyncworkUIKit';

export const Container = styled.div`
  position: absolute;
  z-index: 3;
  top: 32px;
  right: 12px;
  padding: 4px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.neutral.white};
  border: 1px solid ${props => props.theme.colors.neutral.grey04};
  box-shadow: 1px 1px 4px 0 rgba(137, 152, 170, 0.32);
  border-radius: 2px;
`;

export const TextButton = styled(CustomButton)`
  color: ${props => props.theme.colors.neutral.grey07};
  border-radius: 2px;
  padding: 6px 8px;
  .MuiSvgIcon-root {
    margin-right: 4px;
    color: inherit;
    font-size: inherit;
    font-size: 0.875rem;
  }
`;

TextButton.defaultProps = {
  variant: 'tertiary',
  size: 'small'
};

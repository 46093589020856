import styled, { css } from 'styled-components';
import { CustomButton, ImageCircle } from 'components/NewLyncworkUIKit';
import colors from 'designSystem/foundations/colors';
import {
  scrollBar,
  multilineEllipsisFunc
} from 'StyleVariables/StandardComponents';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  flex: 1;
`;
export const ErrorText = styled.span`
  color: red;
  width: 100%;
  text-align: center;
  margin: 1em 0;
`;
export const UsersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  flex: 1;
  overflow-y: auto;
  ${scrollBar}
`;
export const UserContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 77px;
  box-sizing: border-box;
  padding: 1em 24px;
  border-bottom: 1px solid ${colors.neutral.grey02};
  div {
    width: 48px;
    height: 48px;
  }
  p {
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    /* Neutral/Grey 05 */

    color: ${({ theme }) => theme.colors.neutral.grey05};
    margin-right: 1em;
  }
  &:hover {
    background-color: ${colors.neutral.offWhite02};
  }
  background-color: ${({ isSelected }) =>
    isSelected ? colors.neutral.offWhite02 : colors.neutral.white};
  transition: all 0.3s;
  cursor: pointer;
`;
export const InfoContainer = styled.span`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  margin-left: 8px;
`;
export const UserName = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  p {
    margin: 0;
    flex: 1;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* width: 100%; */
    /* identical to box height, or 143% */

    /* Neutral/Grey 10 */

    color: ${colors.neutral.grey10};
    ${multilineEllipsisFunc(1)}
  }
`;
export const UserEmail = styled.span`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Neutral/Grey 10 */

  color: ${colors.neutral.grey06};
  ${multilineEllipsisFunc(1)}
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
export const AddButton = styled(CustomButton)`
  margin-left: 1em;
`;
export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70%;

  p {
    margin: 0;
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    text-align: center;

    /* Neutral/Grey 07 */

    color: ${colors.neutral.grey07};
  }
`;
export const Empty = styled.img`
  width: auto;
  height: 50%;
`;

import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  height: 28px;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  ${props => props.theme.typography.control};
  font-weight: 500;
  color: ${props => props.theme.colors.neutral.grey10};
  /* &:hover, */
  &:last-child {
    color: ${props => props.theme.colors.brand.primary};
  }
`;

export const ChevronWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.neutral.grey10};
`;

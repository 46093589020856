import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { useMobileTutorial } from 'context/MobileTutorialContext';

import UserJourneyListPage from './UserJourneyListPage';
import SingleJourneyPage from './SingleJourneyPage';

import UserJourneyListPageTutorial from './UserJourneyListPageTutorial';
import SingleJourneyPageTutorial from './SingleJourneyPageTutorial';

const UserJourneysPage = () => {
  const { showTutorial } = useMobileTutorial();

  if (showTutorial) {
    return (
      <Switch>
        <Route path="/user-journeys" exact>
          <UserJourneyListPageTutorial />
        </Route>
        <Route path="/user-journeys/tutorial" exact>
          <SingleJourneyPageTutorial />
        </Route>
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path="/user-journeys" exact>
        <UserJourneyListPage />
      </Route>
      <Route path="/user-journeys/:id">
        <SingleJourneyPage />
      </Route>
    </Switch>
  );
};

export default UserJourneysPage;

import React from 'react';

import { CustomButton } from 'components/NewLyncworkUIKit';

import { Container, PointsRedeemedContainer, Title, Points } from './styles';

const fakePoints = 1234;
const PointsRedeemedCard = ({ points = fakePoints }) => {
  const formattedPoints = points.toLocaleString('en-US');
  return (
    <Container>
      <PointsRedeemedContainer>
        <Title>Points Redeemed</Title>
        <Points>{formattedPoints}</Points>
        <CustomButton buttonStyle="alternative">View Rewards</CustomButton>
      </PointsRedeemedContainer>
    </Container>
  );
};

export default PointsRedeemedCard;

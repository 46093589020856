import React, { useEffect, useState } from 'react';
import {
  ImageCircleContainer,
  Container,
  GroupTitle,
  FormContainer,
  GroupWrapper,
  ButtonContainer,
  ButtonSpacer,
  Heading,
  Paragraph,
  TextInput,
  InputLabel,
  InputSubLabel,
  InputContainer
} from './styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import LoadingScreen from 'components/LoadingScreen';
import {
  CustomButton,
  CustomDropdown,
  CustomSelect
} from 'components/NewLyncworkUIKit';
import { PageSettingService } from 'services/page-settings';
import { LocaleService } from 'services/locale.service';
import ImageCircle from 'components/NewLyncworkUIKit/ImageCircle';
import useAuthentication, {
  useAuthenticationActions
} from 'hooks/useAuthentication';
import { defaultFields } from './defaultFields';
import { CMSService } from 'services/cms.service';

import DOBInput, { validateDate } from './DOBInput';

const countryOptions = LocaleService.countries().map(country => ({
  label: country,
  value: country
}));

const getStateOptions = country => {
  const stateList = LocaleService.states(country) || [];
  const stateOptions = stateList.map(state => ({ label: state, value: state }));
  return stateOptions;
};
const shirtSizes = [
  'XS Extra-Small',
  'S Small',
  'M Medium',
  'L Large',
  'XL Extra-Large'
];
const dietaryOptions = ['Vegan', 'Vegetarian', 'Kosher', 'No Restrictions'];

const CustomInput = ({
  inputData,
  value,
  groupData,
  setLocalUser,
  submitted,
  localUser
}) => {
  const inputChange = e => {
    const value = e.target.value;
    switch (groupData.name) {
      case 'LyncWorkUser':
        setLocalUser(localUser => ({ ...localUser, [inputData.name]: value }));

        break;
      case 'Address':
        setLocalUser(localUser => ({
          ...localUser,
          addresses: [{ ...localUser.addresses[0], [inputData.name]: value }]
        }));
        break;
      case 'EmergencyContact':
        setLocalUser(localUser => ({
          ...localUser,
          emergencyContact: {
            ...localUser.emergencyContact,
            [inputData.name]: value
          }
        }));
    }
  };
  const { label, required, disabled, name, type } = inputData;

  const hasNoValue = !value.length;
  const displayError = required && submitted;
  const showError = displayError && hasNoValue;
  const errorMessage = showError ? `${label} is required` : '';
  if (type === 'DATE') {
    const error = displayError && !validateDate(value);
    const errorMessage = error ? `Please select valid date of birth` : '';
    const handleChange = (year, month, day) => {
      const dob = `${year}-${month}-${day}`;
      setLocalUser(localUser => ({ ...localUser, dob }));
    };
    return (
      <DOBInput
        value={value}
        handleChange={handleChange}
        errorMessage={errorMessage}
      />
    );
  }
  if (name === 'state') {
    const selectedState = { value, label: value };
    const selectedCountry =
      localUser?.addresses?.[0]?.country || 'United States';
    const stateOptions = getStateOptions(selectedCountry);

    const handleChange = selectedOption => {
      const state = selectedOption?.value || '';
      setLocalUser(localUser => {
        return {
          ...localUser,
          addresses: [{ ...localUser.addresses[0], state }]
        };
      });
    };

    return (
      <InputContainer>
        <InputLabel>
          {label}
          <span>*</span>
        </InputLabel>
        <CustomSelect
          name={name}
          onChange={handleChange}
          value={selectedState}
          options={stateOptions}
        />
        {errorMessage && <InputSubLabel error>{errorMessage}</InputSubLabel>}
      </InputContainer>
    );
  }
  if (name === 'country') {
    const selectedCountry = { value, label: value };

    const handleChange = selectedOption => {
      const { value: newCountry } = selectedOption;
      setLocalUser(localUser => {
        const oldCountry = localUser?.addresses?.[0]?.country;
        if (oldCountry === newCountry) {
          return localUser;
        }
        const newState = LocaleService.states(newCountry)?.[0] || newCountry;
        return {
          ...localUser,
          addresses: [
            { ...localUser.addresses[0], country: newCountry, state: newState }
          ]
        };
      });
    };

    return (
      <InputContainer>
        <InputLabel>
          {label}
          <span>*</span>
        </InputLabel>
        <CustomSelect
          name={name}
          onChange={handleChange}
          value={selectedCountry}
          options={countryOptions}
        />
        {errorMessage && <InputSubLabel error>{errorMessage}</InputSubLabel>}
      </InputContainer>
    );
  }
  if (name === 'dietaryRestrictions') {
    const handleChange = selectedOption =>
      setLocalUser(localUser => ({
        ...localUser,
        dietaryRestrictions: selectedOption.value
      }));
    return (
      <CustomDropdown
        values={dietaryOptions}
        label={label}
        required={required}
        onChange={handleChange}
        name={name}
        subLabel={errorMessage}
        error={showError}
      />
    );
  }
  if (name === 'shirtSize') {
    const handleChange = selectedOption =>
      setLocalUser(localUser => ({
        ...localUser,
        shirtSize: selectedOption.value
      }));
    return (
      <CustomDropdown
        values={shirtSizes}
        label={label}
        required={required}
        onChange={handleChange}
        name={name}
        subLabel={errorMessage}
        error={showError}
      />
    );
  }
  return (
    <TextInput
      inputData={inputData}
      label={label}
      required={required}
      value={value}
      disabled={disabled}
      onChange={inputChange}
      type={type}
      name={name}
      subLabel={errorMessage}
      error={showError}
    />
  );
};

const Group = ({ groupData, localUser, setLocalUser, submitted }) => (
  <GroupWrapper>
    {!!groupData.companyFields.length && (
      <GroupTitle>{groupData.label}</GroupTitle>
    )}
    {groupData.companyFields.map((input, index) => {
      const determineValue = () => {
        switch (groupData.name) {
          case 'LyncWorkUser':
            const valUser = localUser?.[input.name];
            return valUser ? valUser : '';
            break;
          case 'Address':
            if (input.name === 'country') {
              return localUser.addresses[0]?.country || 'United States';
            }
            const valAddress = localUser.addresses[0]?.[input.name];
            return valAddress ? valAddress : '';
            break;
          case 'EmergencyContact':
            const valEC = localUser.emergencyContact?.[input.name];
            return valEC ? valEC : '';
        }
      };
      return (
        <CustomInput
          key={index}
          groupData={groupData}
          inputData={input}
          localUser={localUser}
          setLocalUser={setLocalUser}
          value={determineValue()}
          submitted={submitted}
        />
      );
    })}
  </GroupWrapper>
);

const PersonalInfo = ({
  nextPage = () => {},
  prevPage = () => {},
  pageData,
  onPageDataChange = () => {},
  hideActionButtons = false
}) => {
  const { user } = useAuthentication();
  const { setGetUser } = useAuthenticationActions();

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [avatar, setAvatar] = useState();
  const [localUser, setLocalUser] = useState(user);
  const groups = pageData.entitiesSettings;

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const { data } = await PageSettingService.personalInfo();

        let groupsWithDefaults = data.entitiesSettings;
        const findDefaults = string =>
          groupsWithDefaults.find(x => x.name === string);
        const addDefaults = obj => {
          groupsWithDefaults[groupsWithDefaults.indexOf(obj)] = {
            ...obj,
            companyFields: defaultFields
              .find(x => x.name === obj.name)
              .fields.concat(obj.companyFields)
          };
        };
        addDefaults(findDefaults('LyncWorkUser'));
        addDefaults(findDefaults('Address'));
        setLocalUser(pageData.requestBody || user);
        setAvatar(user.avatar);
        onPageDataChange({
          ...pageData,
          entitiesSettings: data.entitiesSettings
        });
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const onSubmit = async () => {
    setSubmitted(true);
    //lets find some errors!
    let requiredInputNames = [];
    groups.forEach(group => {
      requiredInputNames = requiredInputNames
        .concat(group.companyFields)
        .filter(x => x.required);
    });
    requiredInputNames = requiredInputNames.map(x => x.name);

    const inputTags = Array.from(document.getElementsByTagName('input'));
    const requiredInputTags = inputTags.filter(tag =>
      requiredInputNames.includes(tag.name)
    );

    const invalidTags = requiredInputTags.filter(tag => {
      if (tag.name === 'dob') {
        return !validateDate(tag.value);
      }
      return !tag.value.length;
    });

    const hasErrors = invalidTags.length > 0;
    if (!hasErrors) {
      const updatedUserData = localUser;
      const avatarModified = avatar !== user.avatar;
      setSubmitting(true);
      if (pageData.requestBody === localUser) {
        nextPage();
        return;
      }
      try {
        if (avatarModified) {
          const avatarResponse = await CMSService.updateAvatar(avatar);
          updatedUserData.avatar = avatarResponse.data[0];
        }
        const { addresses } = updatedUserData;
        const [{ country }] = addresses;
        updatedUserData.addresses[0].country = country || 'United States';
        const response = await CMSService.updateUser(updatedUserData);
        const updatedUser = response.data;

        const updatedUserObject = {
          ...user,
          ...updatedUser,
          avatar: updatedUserData.avatar
        };
        setGetUser(updatedUserObject);
        pageData.requestBody = updatedUserObject;
        onPageDataChange(pageData);
        nextPage();
      } catch (error) {
        setSubmitting(false);
        console.log(error);
      }
    } else {
      const [firstInvalidTag] = invalidTags;
      if (firstInvalidTag.name !== 'dob') {
        invalidTags[0].scrollIntoView({ block: 'center' });
      } else {
        const dateInputContainer = document.querySelector(
          `[data-input-id="dob"]`
        );
        dateInputContainer.scrollIntoView({ block: 'center' });
      }
    }
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Container>
      <FormContainer className="form container">
        <Heading>Personal Information</Heading>
        <Paragraph>
          Please provide the following personal information to complete your
          company profile.
        </Paragraph>
        <GroupTitle>Profile Photo</GroupTitle>
        <ImageCircleContainer>
          <ImageCircle
            editMode
            user={user}
            sizeCap={4000 * 1024}
            url={avatar}
            firstName={user?.firstName}
            uploadCallback={setAvatar}
            fontSize="70px"
          />
        </ImageCircleContainer>
        {groups.map((group, index) => (
          <Group
            groupData={group}
            editData={onPageDataChange}
            key={index}
            submitted={submitted}
            localUser={localUser}
            setLocalUser={setLocalUser}
          />
        ))}
      </FormContainer>
      {hideActionButtons ? (
        <ButtonSpacer />
      ) : (
        <ButtonContainer>
          <CustomButton onClick={prevPage} variant="secondary" width="160px">
            Back
          </CustomButton>
          <ButtonSpacer />
          <CustomButton onClick={onSubmit} width="160px">
            {submitting ? (
              <CircularProgress color="secondary" size="20px" />
            ) : (
              'Next'
            )}
          </CustomButton>
        </ButtonContainer>
      )}
    </Container>
  );
};

export default PersonalInfo;

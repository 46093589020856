import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import env from 'env';
import { DropboxService } from 'services';
import { downloadBlob } from 'services/hellosign.service';
import { Container, ContentContainer, Title, ResourceLink } from './styles';

const Resource = ({ resource }) => {
  const { signingTitle, dropboxFileId, fileName } = resource;
  const downloadFile = async () => {
    try {
      const response = await DropboxService.downloadUser(dropboxFileId);
      const blob = response.data;
      downloadBlob(blob, fileName);
    } catch (error) {
      console.log(error);
    }
  };
  return <ResourceLink onClick={downloadFile}>{signingTitle}</ResourceLink>;
};

const CompanyResources = () => {
  const [{ loading, resources }, setState] = useState({
    loading: true,
    resources: []
  });

  useEffect(() => {
    const fetchCompanyResources = async () => {
      try {
        const response = await DropboxService.findDropboxFileByCategoryAll();
        const resources = response.data.content.slice(0, 5);
        setState({
          loading: false,
          resources
        });
      } catch (error) {
        console.log(error);
        setState({
          loading: false,
          resources: []
        });
      }
    };
    fetchCompanyResources();
  }, []);

  if (loading) {
    return (
      <Container>
        <ContentContainer>
          <CircularProgress />
        </ContentContainer>
      </Container>
    );
  }

  return (
    <Container>
      <ContentContainer>
        <Title>Resources</Title>
        {resources.map(resource => (
          <Resource key={resource.id} resource={resource} />
        ))}
      </ContentContainer>
    </Container>
  );
};

export default CompanyResources;

import React, { useEffect, useState } from 'react';
import { CMSService } from 'services/cms.service';
import PostParser from 'pages/Posts/PostParser';
import LoadingScreen from 'components/LoadingScreen';
import Button from 'components/NewLyncworkUIKit/CustomButton';
import { ButtonContainer, ContentStyles } from './styles';

const OnboardingWelcome = ({ nextPage }) => {
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState(null);
  useEffect(() => {
    (async () => {
      try {
        const { data } = await CMSService.getCustomOnboarding();
        let newContent = data.content;
        let imgIdArray = [];
        //create array of ids, push id after replace into array, if id is not included, must be thumb
        //if
        data.medias.forEach(media => {
          const isIncluded = data.content.includes(`lw-img-id:${media.id}`);
          //replace all uploaded image src's with the correct url
          newContent = newContent.replace(`lw-img-id:${media.id}`, media.url);
          isIncluded && imgIdArray.push(media.id);
        });
        setPageData({ ...data, content: newContent });
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }
  console.log({ pageData });

  const { content } = pageData;
  const hasContent = content.length > 0;
  return (
    <ContentStyles>
      {hasContent && <PostParser content={content} onboarding />}
      <ButtonContainer>
        <Button onClick={nextPage}>Get Started</Button>
      </ButtonContainer>
    </ContentStyles>
  );
};

export default OnboardingWelcome;

export * from './styles';

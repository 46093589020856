import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 424px;
  max-width: calc(100% - 40px);
  margin: 0 20px;
`;

export const Title = styled.h1`
  margin: 36px 0 20px 0;
  ${props => props.theme.typography.title2};
  color: ${props => props.theme.colors.neutral.grey10};
  font-weight: 600;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 48px 0 36px;
  button {
    width: 128px;
    margin: 0 8px 0;
  }
  /* ... */
`;

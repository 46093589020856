import React, { useState, useRef } from 'react';

import { useButtonTrack } from 'matomo/hooks';
import { companyBasicInfoEvents } from 'pages/CompanyInfo/instrumentationEvents';

import { Container } from './styles';
const BannerUpload = ({ companyBanner, updateCompanyMedia, canEdit }) => {
  const trackButton = useButtonTrack();
  const [showImage, setShowImage] = useState(false);
  const fileInputRef = useRef();

  const url = companyBanner?.url;
  const onImageLoad = () => setShowImage(true);
  const onImageError = () => setShowImage(false);
  const handleChange = async e => {
    const {
      files: [file],
      name
    } = e.target;
    try {
      const logoResponse = await updateCompanyMedia(file, name);
    } catch (error) {
      console.log(error);
    } finally {
      fileInputRef.current.value = '';
    }
  };

  const handleInputClick = () => {
    trackButton(companyBasicInfoEvents.banner_click);
  };
  return (
    <Container
      showImage={showImage}
      onLoad={onImageLoad}
      onError={onImageError}
    >
      {canEdit && (
        <input
          type="file"
          name="companyBanner"
          title=""
          accept="image/x-png,image/gif,image/jpeg,image/png"
          ref={fileInputRef}
          onChange={handleChange}
          onClick={handleInputClick}
        />
      )}
      <img src={url} />
    </Container>
  );
};

export default BannerUpload;

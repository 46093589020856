import styled from 'styled-components';
import { grey, white, black } from 'StyleVariables/LyncworkColors';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  margin-bottom: 5em;
  box-sizing: border-box;
`;
export const ButtonContainer = styled.div`
  position: relative;
  align-items: center;
  justify-content: flex-end;
  display: flex;
`;
export const EditContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 25px 50px;
  background: ${white};
  border: 1px solid ${grey}70;
  box-sizing: border-box;
  box-shadow: 1px 1px 4px ${grey}50;
  border-radius: 4px;
  margin-bottom: 1em;
  /* min-height: 100vh; */
  height: fit-content;
`;
export const Flex = styled.div`
  flex: 1;
`;
export const BreadCrumb = styled.h1`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  margin-top: 8px;
  color: ${black};
`;
export const Footer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0 0 0;
`;
export const Spacer = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dcdde4;
  margin: 1em 0 0;
`;
export const BlockHeightFixer = styled.div`
  min-height: 500px;
  flex: 1;
`;

export const MarginRight = styled.div`
  margin-right: 1em;
`;
export const Preview = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 1em;
`;
export const ErrorP = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */
  margin: 0;

  letter-spacing: 0.777778px;
  margin-bottom: 1em;
  color: red;
`;
export const ButtonWrapper = styled.div`
  margin: 1em 0 0 0;
`;

import React from 'react';
import { useHistory } from 'react-router';

import HiddenCounter from 'components/HiddenCounter';
import { Cell } from 'components/ListTable';

import { StyledAvatar, StyledCheckbox, StyledRow } from './styles';

const UserCard = ({
  user,
  handleChecked,
  checked,
  allowDoubleClick = true
}) => {
  const history = useHistory();
  const { id, firstName, lastName } = user;
  const src = user.avatar?.url;
  const fullName = `${firstName} ${lastName}`;
  const roleName = user.roles[0]?.roleName || 'Unassigned';
  const teamName = user.teams[0]?.teamName || 'Unassigned';
  const remainingRolesCount = user.roles.length - 1;
  const remainingTeamsCount = user.teams.length - 1;

  const checkUser = () => handleChecked(user);

  return (
    <StyledRow>
      <StyledCheckbox name={id} onChange={checkUser} checked={checked} />
      <Cell flex={6}>
        <StyledAvatar src={src} colorKey={id} name={firstName} />
        <span className="list-row-name">{fullName}</span>
      </Cell>
      <Cell flex={6}>
        <span className="overflow">{roleName} </span>
        {remainingRolesCount > 0 && (
          <HiddenCounter>+{remainingRolesCount}</HiddenCounter>
        )}
      </Cell>
      <Cell flex={6}>
        <span className="overflow">{teamName} </span>
        {remainingTeamsCount > 0 && (
          <HiddenCounter>+{remainingTeamsCount}</HiddenCounter>
        )}
      </Cell>
    </StyledRow>
  );
};

export default UserCard;

import React from 'react';

import CoreApp from './CoreApp';

import { Title, Tagline } from './styles';

const CoreAppsSetup = ({ coreApp, errorMessage }) => {
  const firstLogin = coreApp?.firstLogin;
  if (firstLogin) {
    return null;
  }
  return (
    <>
      <Title>Core Application Setup</Title>
      <Tagline>
        The following applications are required by your organization, lets set
        them up before your first day
      </Tagline>
      <CoreApp coreApp={coreApp} errorMessage={errorMessage} />
    </>
  );
};

export default CoreAppsSetup;

import React from 'react';
import { Description, RedDescription } from '../styles';

const EmailDescription = () => (
  <>
    <Description>
      Please select the email provider your organization uses.
    </Description>
    <RedDescription>
      We will use this to provision email accounts for all future employees you
      create on Lyncwork.
    </RedDescription>
  </>
);

export default EmailDescription;

import delay from 'utilities/delay';

const defaultPromptStyle = { bottom: 80 };

const makeElementVisible = elementId => {
  const element = document.querySelector(`[data-tutorial-id="${elementId}"]`);
  element.scrollIntoView({ behavior: 'smooth' });
};

const buttonPadding = 8;
const sizeOffset = 2 * buttonPadding;

const getUnmaskedElementStyle = elementId => {
  const element = document.querySelector(`[data-tutorial-id="${elementId}"]`);
  const { top, left, width, height } = element.getBoundingClientRect();

  switch (elementId) {
    case 'view-button':
    case 'complete-task-button':
      const sizeOffset = 2 * buttonPadding;
      return {
        top: top - buttonPadding,
        left: left - buttonPadding,
        width: width + sizeOffset,
        height: height + sizeOffset
      };
    default:
      return { top, left, width, height };
  }
};

const getPromptStyle = (placement, unmaskedElementStyle) => {
  const { top, height } = unmaskedElementStyle;
  switch (placement) {
    case 'top':
      const distanceToBottom = window.innerHeight - (top + height);
      return { bottom: distanceToBottom + height + 20 };
    case 'bottom':
      return { top: top + height + 20 };
    default:
      return defaultPromptStyle;
  }
};

export const defaultTutorialStyle = {
  promptStyle: defaultPromptStyle,
  unmaskedElementStyle: {}
};

export const getTutorialStyle = async (unmaskedElement, prompt) => {
  if (!unmaskedElement) {
    return defaultTutorialStyle;
  }
  makeElementVisible(unmaskedElement);
  await delay(750);
  const unmaskedElementStyle = getUnmaskedElementStyle(unmaskedElement);
  const promptStyle = getPromptStyle(prompt.placement, unmaskedElementStyle);
  return { promptStyle, unmaskedElementStyle };
};

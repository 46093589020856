import {
  CLEAR_AUTH,
  ERROR_MESSAGE,
  GET_SESSION,
  GET_USER,
  LOGIN,
  LOGOUT,
  SET_COMPANY
} from './authentication.actions';

import axios from 'axios';

const authenticationInitialState = {
  loading: false,
  isAuthenticated: false,
  loginSuccess: false,
  loginError: false, // Errors returned from server side
  showModalLogin: false,
  account: {},
  user: {},
  errorMessage: null, // Errors returned from server side
  redirectMessage: null,
  sessionHasBeenFetched: false,
  idToken: null,
  logoutUrl: null
};

export const AUTH_TOKEN_KEY = 'lw-authentication';

const authenticationReducer = (state = authenticationInitialState, action) => {
  switch (action.type) {
    case CLEAR_AUTH:
      return {
        ...state,
        account: {},
        loading: false,
        showModalLogin: true,
        isAuthenticated: false
      };
    case ERROR_MESSAGE:
      return {
        ...authenticationInitialState,
        redirectMessage: action.message
      };
    case GET_SESSION:
      return {
        ...state,
        isAuthenticated: action.payload && action.payload.activated,
        loading: false,
        sessionHasBeenFetched: true,
        account: action.payload
      };
    case GET_USER:
      return {
        ...state,
        user: action.payload
      };

    case SET_COMPANY:
      return {
        ...state,
        user: {
          ...state.user,
          company: action.payload
        }
      };
    case LOGIN:
      return {
        ...state,
        loading: false,
        loginError: false,
        showModalLogin: false,
        loginSuccess: true,
        idToken: action.payload.id_token
      };
    case LOGOUT:
      return {
        ...state,
        showModalLogin: true
      };
    default:
      return state;
  }
};

export const login = (username, password, rememberMe = false) => async (
  dispatch,
  getState
) => {
  const result = await dispatch({
    type: LOGIN,
    payload: axios.post('api/authenticate', { username, password, rememberMe })
  });
  const bearerToken = result.value.headers.authorization;
  if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
    const jwt = bearerToken.slice(7, bearerToken.length);
    if (rememberMe) {
      Storage.local.set(AUTH_TOKEN_KEY, jwt);
    } else {
      Storage.session.set(AUTH_TOKEN_KEY, jwt);
    }
  }
  await dispatch(getSession());
};

export const getSession = () => async (dispatch, getState) => {
  await dispatch({
    type: GET_SESSION,
    payload: axios.get('api/account')
  });
};

export default authenticationReducer;

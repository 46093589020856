import { TrendingUpOutlined } from '@material-ui/icons';

export const defaultFields = [
  {
    name: 'LyncWorkUser',
    label: 'Employee Info',
    fields: [
      {
        label: 'First Name',
        name: 'firstName',
        required: true,
        type: 'TEXT',
        disabled: true,
        isDefault: true
      },
      {
        label: 'Last Name',
        name: 'lastName',
        required: true,
        type: 'TEXT',
        disabled: true,
        isDefault: true
      },
      {
        label: 'Preferred Name',
        name: 'preferredName',
        required: false,
        type: 'TEXT',
        isDefault: true
      },
      {
        label: 'Email',
        name: 'emailPrimary',
        required: true,
        type: 'TEXT',
        disabled: true,
        isDefault: true
      },
      {
        label: 'Primary Phone',
        name: 'phonePrimary',
        required: true,
        type: 'TEL',
        isDefault: true
      },
      {
        label: 'Secondary Phone',
        name: 'phoneSecondary',
        required: false,
        type: 'TEL',
        isDefault: true
      },
      {
        label: 'Date of Birth',
        name: 'dob',
        required: true,
        type: 'DATE',
        isDefault: true
      }
    ]
  },
  {
    name: 'Address',
    label: 'Address',
    fields: [
      {
        label: 'Country',
        name: 'country',
        required: true,
        type: 'TEXT',
        isDefault: true
      },
      {
        label: 'Street Address Line 1',
        name: 'lineOne',
        required: true,
        type: 'TEXT',
        isDefault: true
      },
      {
        label: 'Street Address Line 2',
        name: 'lineTwo',
        required: false,
        type: 'TEXT',
        isDefault: true
      },
      {
        label: 'City',
        name: 'city',
        required: true,
        type: 'TEXT',
        isDefault: true
      },
      {
        label: 'State / Province',
        name: 'state',
        required: true,
        type: 'TEXT',
        isDefault: true
      },
      {
        label: 'Zip / Postal Code',
        name: 'postalCode',
        required: true,
        type: 'TEXT',
        isDefault: true
      }
    ]
  }
];

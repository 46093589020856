import styled from 'styled-components';
import { Link } from 'react-router-dom';
import colors from 'designSystem/foundations/colors';

export const Container = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  box-sizing: border-box;
  height: 56px;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.grey03};
`;

export const Cell = styled.div`
  box-sizing: border-box;
  flex: ${props => props.flex};
  margin: 0 0 0 24px;
  ${props => props.theme.typography.control};
  font-weight: 500;
  color: ${props => props.theme.colors.neutral.grey10};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

Cell.defaultProps = { flex: 1 };

const backgroundColor = {
  enabled: `${colors.feedback.confirmation}20`,
  disabled: colors.neutral.grey01
};
const textColor = {
  enabled: colors.feedback.confirmation,
  disabled: colors.neutral.grey05
};

export const AdminAccess = styled.div`
  width: 76px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  background-color: ${props => backgroundColor[props.adminAccessStatus]};
  color: ${props => textColor[props.adminAccessStatus]};
  ${props => props.theme.typography.control};
  font-weight: 500;
`;

AdminAccess.defaultProps = { adminAccessStatus: 'disabled' };

export const WideCell = styled(Cell)`
  display: flex;
  align-items: center;
  height: 100%;
`;
WideCell.defaultProps = { flex: 2 };

const appLogoBackground = colors.neutral.offWhite03;
const appLogoTextColor = colors.neutral.grey10;

export const HiddenAppCounter = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${appLogoBackground};
  border-radius: 50%;
  color: ${appLogoTextColor};
`;

export const AppLogoContainer = styled(HiddenAppCounter)`
  margin-right: 4px;
  background-color: ${props => props.backgroundColor};
  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
`;

export const ButtonCell = styled(Cell)`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  margin-right: 24px;
`;

import styled, { css } from 'styled-components';

export const HiddenUserCounter = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.neutral.offWhite03};
  border-radius: 50%;
  color: ${props => props.theme.colors.neutral.grey10};
  overflow: hidden;
`;

export const AvatarContainer = styled(HiddenUserCounter)`
  margin-right: 4px;
  background-color: ${props => props.backgroundColor};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

import { CMSService } from 'services/cms.service';
import axios from 'axios';

const uploadImage = async (file, addImage) => {
  try {
    const filePreview = URL.createObjectURL(file);
    const newFile = { url: filePreview, file };
    addImage(newFile);
    return { default: filePreview };
  } catch (error) {
    console.log(error);
  }
};

class CustomUploadAdapter {
  constructor(loader, addImage = () => {}) {
    this.loader = loader;
    this.addImage = addImage;
  }
  upload() {
    return this.loader.file.then(file => uploadImage(file, this.addImage));
  }
  abort() {}
}

export default function uploadAdapter(addImage) {
  return function adapter(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      return new CustomUploadAdapter(loader, addImage);
    };
  };
}

import React, { useReducer, useState } from 'react';
import { useDialogue, useEscapePage } from 'hooks';

import { RoleService } from 'services/role.service';

import useUserByRole from './useUserByRole';

import { getRoleInfo } from './utils';
import roleFormReducer from './roleFormReducer';
import {
  CHANGE_TEXT_VALUE,
  HANDLE_PERMISSION_CHANGE,
  TOGGLE_ADMIN_ACCESS,
  EDIT_APPLICATION_CAPABILITY,
  REMOVE_APPLICATION,
  ADD_APPLICATION
} from './roleFormActions';

import { SavePreflight, DeletePreflight } from './preflight';

const getDeletedApps = formState => {
  const { applications, oldApplications } = formState;
  const currentAppIds = applications.map(({ id }) => id);
  const deletedApps = oldApplications.filter(({ id }) => {
    const included = currentAppIds.includes(id);
    return !included;
  });
  return deletedApps;
};

const useRoleFormEdit = roleDTO => {
  const { showDialogue, closeDialogue } = useDialogue();
  const { shouldEscape, escapePage } = useEscapePage('/admin-roles');
  const [formState, dispatch] = useReducer(
    roleFormReducer,
    roleDTO,
    getRoleInfo
  );
  const [roleNameError, setRoleNameError] = useState('');
  const [formModified, setFormModified] = useState(false);
  const [loadingType, setLoadingType] = useState(null);

  const roleId = formState.id;
  const users = useUserByRole(roleId);
  const blockTransition = formModified && !shouldEscape;
  const deletedApps = getDeletedApps(formState);

  const modifyForm = () => setFormModified(true);

  const handleTextChange = e => {
    const { name, value } = e.target;
    modifyForm();
    dispatch({ type: CHANGE_TEXT_VALUE, name, value });
    if (name === 'roleName') {
      setRoleNameError('');
    }
  };

  const handleAdminAccessToggle = e => {
    const { checked } = e.target;
    const toggle = () => {
      dispatch({ type: TOGGLE_ADMIN_ACCESS, checked });
      closeDialogue();
    };

    if (!checked) {
      modifyForm();
      toggle();
      return;
    }

    const roleName = roleDTO?.roleName || 'this role';
    const message = `Are you sure you want to enable admin access for ${roleName}?`;
    showDialogue({
      title: 'Enable Admin Authorization',
      message,
      confirmButtonText: 'Enable',
      confirmButtonAction: toggle,
      cancelButtonAction: closeDialogue
    });
  };

  const handlePermissionChange = e => {
    const { value, checked } = e.target;
    const id = Number(value);
    modifyForm();
    dispatch({ type: HANDLE_PERMISSION_CHANGE, id, checked });
  };

  const editApplicationCapability = (application, selectedCapabilities) => {
    modifyForm();
    dispatch({
      type: EDIT_APPLICATION_CAPABILITY,
      application,
      selectedCapabilities
    });
  };

  const editAhaCapability = (
    application,
    subscriptionAppCapabilityProperties
  ) => {
    modifyForm();
    dispatch({
      type: EDIT_APPLICATION_CAPABILITY,
      application,
      subscriptionAppCapabilityProperties
    });
  };

  const removeApplication = application => {
    modifyForm();
    dispatch({ type: REMOVE_APPLICATION, application });
  };

  const addApplication = (
    application,
    selectedCapabilities,
    selectedLicense = null
  ) => {
    modifyForm();
    dispatch({
      type: ADD_APPLICATION,
      application,
      selectedCapabilities,
      selectedLicense
    });
  };

  const addAha = (application, subscriptionAppCapabilityProperties) => {
    modifyForm();
    dispatch({
      type: ADD_APPLICATION,
      application,
      subscriptionAppCapabilityProperties
    });
  };

  const deleteRole = async () => {
    try {
      setLoadingType('delete');
      await RoleService.delete(roleId);
      escapePage();
    } catch (error) {
      setLoadingType(null);
      showDialogue({
        title: 'Oops, something went wrong',
        message: 'Please try again later',
        confirmButtonText: 'Okay',
        confirmButtonAction: closeDialogue
      });
      console.log(error);
    }
  };

  const handleDelete = () => {
    const confirmButtonAction = () => {
      closeDialogue();
      deleteRole();
    };

    showDialogue({
      title: 'Delete Role',
      message: (
        <DeletePreflight
          applications={formState.oldApplications}
          users={users}
        />
      ),
      confirmButtonText: 'Delete',
      confirmButtonAction,
      cancelButtonAction: closeDialogue
    });
  };

  const isValid = () => {
    if (!formState.roleName) {
      setRoleNameError('Role name is required');
      return false;
    }
    return true;
  };

  const saveRole = async () => {
    const operation = roleId === null ? 'create' : 'update';
    const {
      roleName,
      description,
      licenseManagements,
      subscriptionAppCapabilityProperties
    } = formState;
    const getApplicationCapabilities = () => {
      const { adminAccess, permissions, capabilities } = formState;

      const permissionCapabilities = adminAccess ? permissions : [];
      const applicationCapabilities = [
        ...permissionCapabilities,
        ...capabilities
      ];
      return applicationCapabilities;
    };
    const applicationCapabilities = getApplicationCapabilities();

    const roleObject = {
      id: roleId,
      roleName,
      description,
      applicationCapabilities,
      licenseManagements,
      subscriptionAppCapabilityProperties
    };
    try {
      setLoadingType('save');
      const response = await RoleService[operation](roleObject);
      escapePage();
    } catch (error) {
      const { errorKey, title } = error?.response?.data || {};
      console.log(error);
      setLoadingType(null);
      switch (errorKey) {
        case 'uniqueconstraintviolation':
        case 'cannotupdatename':
          setRoleNameError(title);
          return;
        default:
          showDialogue({
            title: 'Oops, something went wrong',
            message: 'Please try again later',
            confirmButtonText: 'Okay',
            confirmButtonAction: closeDialogue
          });
          return;
      }
    }
  };
  const handleDiscard = () => {
    escapePage();
  };
  const handleSave = () => {
    if (!isValid()) {
      return;
    }
    if (deletedApps.length > 0 && users.length > 0) {
      const confirmButtonAction = () => {
        saveRole();
        closeDialogue();
      };
      showDialogue({
        title: 'Application Deleted',
        message: <SavePreflight applications={deletedApps} users={users} />,
        confirmButtonAction,
        cancelButtonAction: closeDialogue
      });
      return;
    }

    saveRole();
  };

  return {
    roleId,
    formState,
    roleNameError,
    handleTextChange,
    handleAdminAccessToggle,
    handlePermissionChange,
    editApplicationCapability,
    removeApplication,
    addApplication,
    escapePage,
    blockTransition,
    loadingType,
    handleSave,
    handleDiscard,
    handleDelete,

    //aha specific
    addAha,
    editAhaCapability
  };
};

export default useRoleFormEdit;

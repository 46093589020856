import React from 'react';
import { ThumbUpAlt, MoreHoriz } from '@material-ui/icons';
import {
  differenceInYears,
  differenceInMonths,
  differenceInWeeks,
  differenceInDays,
  differenceInHours,
  differenceInMinutes
} from 'date-fns';
import { lowercaseComparison } from 'utilities/stringComparison';
import useAuthentication from 'hooks/useAuthentication';
import useModal from 'hooks/useModal';

import SingleCommentMenu from './SingleCommentMenu';

import {
  Container,
  CommentContainer,
  StyledAvatar,
  CommentInfo,
  Author,
  CommentBody,
  TimeDifference,
  ButtonContainer,
  TextButton,
  Divider,
  LikeCount,
  CommentEditButton
} from './styles';

const dateCalculationList = [
  { type: 'y', calc: differenceInYears },
  { type: 'mo', calc: differenceInMonths },
  { type: 'w', calc: differenceInWeeks },
  { type: 'd', calc: differenceInDays },
  { type: 'h', calc: differenceInHours },
  { type: 'm', calc: differenceInMinutes }
];

const getTimeDifference = date => {
  const today = new Date();
  const dateToCompare = new Date(date);
  const differenceList = dateCalculationList.map(({ type, calc }) => {
    const difference = calc(today, dateToCompare);
    return { type, difference };
  });

  const differenceObject = differenceList.find(
    ({ difference }) => difference > 0
  );
  if (!differenceObject) {
    return '';
  }
  const { difference, type } = differenceObject;

  return `${difference}${type}`;
};

const getAuthorInfo = (authorUsername, authors) => {
  const authorInfo =
    authors.find(({ username }) =>
      lowercaseComparison(username, authorUsername)
    ) || {};
  const avatarUrl = authorInfo?.avatar?.url || '';
  const { firstName = '', lastName = '' } = authorInfo;
  const hasName = firstName || lastName;
  const authorName = hasName ? `${firstName} ${lastName}` : authorUsername;
  return { avatarUrl, authorName };
};

const SingleComment = ({
  authors,
  commentData,
  addCommentReaction,
  deleteCommentReaction,
  deleteComment
}) => {
  const { isOpen, openModal: openMenu, closeModal: closeMenu } = useModal();
  const { user } = useAuthentication();
  const { username } = user;
  const { author, created_at, comment, id, reaction_comments } = commentData;
  const timeDifference = getTimeDifference(created_at);
  const { avatarUrl, authorName } = getAuthorInfo(author, authors);
  const likeComment = () => addCommentReaction(id);
  const unlikeComment = () => deleteCommentReaction(id);

  const hasLiked = reaction_comments.some(({ author }) =>
    lowercaseComparison(author, username)
  );
  const likeCount = reaction_comments.length;
  const handleLikeButtonClick = hasLiked ? unlikeComment : likeComment;

  const isAuthor = lowercaseComparison(author, username);

  const handleDelete = () => deleteComment(id);

  return (
    <Container>
      <StyledAvatar colorKey={4} name={authorName} src={avatarUrl} />
      <CommentInfo>
        <CommentContainer>
          {isOpen && isAuthor && (
            <SingleCommentMenu
              closeMenu={closeMenu}
              handleDelete={handleDelete}
            />
          )}
          {isAuthor && (
            <CommentEditButton onClick={openMenu}>
              <MoreHoriz />
            </CommentEditButton>
          )}
          <Author>
            {authorName}
            <TimeDifference>{timeDifference}</TimeDifference>
          </Author>
          <CommentBody>{comment}</CommentBody>
          {likeCount > 0 && (
            <LikeCount>
              <ThumbUpAlt /> {likeCount}
            </LikeCount>
          )}
        </CommentContainer>
        <ButtonContainer>
          <TextButton onClick={handleLikeButtonClick} hasLiked={hasLiked}>
            Like
          </TextButton>
          <Divider />
          <TextButton>Reply</TextButton>
        </ButtonContainer>
      </CommentInfo>
    </Container>
  );
};

export default SingleComment;

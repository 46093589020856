import styled, { css } from 'styled-components';
import colors from 'designSystem/foundations/colors';
import { IconButton } from 'components/NewLyncworkUIKit';
const {
  neutral: { grey04, grey07, grey10, white, offWhite03 }
} = colors;

export const Container = styled.div`
  cursor: default;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 28px auto 0;
  color: ${grey07};
  ${props => props.theme.typography.control};
  gap: 8px;
`;

const selectedStyle = css`
  background-color: ${grey10};
  color: ${white};
`;
export const PageButton = styled.button`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  height: 24px;
  padding: 2px;
  cursor: pointer;
  border: 1px solid transparent;
  background: transparent;
  color: ${grey07};
  ${props => props.theme.typography.control};
  font-weight: 600;
  ${props => props.$isSelected && selectedStyle};
  border-radius: 4px;
  transition: all 0.1s ease-in;
  &:hover {
    background-color: white;
    color: ${grey07};
    border-color: ${offWhite03};
  }
`;

export const ChevronButton = styled(IconButton)`
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  color: ${grey04};
  transition: color 0.1s ease-in;
  .MuiSvgIcon-root {
    font-size: 24px;
  }

  cursor: default;
  &:enabled {
    cursor: pointer;
  }
  &:hover:enabled {
    color: ${grey07};
  }
`;

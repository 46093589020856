import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import SkillCard from './SkillCard';
import {
  SectionTitle,
  ListContainer,
  NoSkillsMessageContainer
} from './styles';

const NoSkillsMessage = () => (
  <>
    <NoSkillsMessageContainer>
      You haven't added any skills yet
    </NoSkillsMessageContainer>
    <NoSkillsMessageContainer>
      Search to add a new skill to your profile.
    </NoSkillsMessageContainer>
  </>
);

const MySkills = ({ skills, removeSkill, updateSkill, loading }) => {
  const hasSkills = skills.length > 0;
  return (
    <>
      <SectionTitle>My Skills</SectionTitle>
      <ListContainer>
        {loading ? (
          <CircularProgress />
        ) : hasSkills ? (
          skills.map((skill, index) => {
            return (
              <SkillCard
                index={index}
                key={skill.id}
                skill={skill}
                removeSkill={removeSkill}
                updateSkill={updateSkill}
              />
            );
          })
        ) : (
          <NoSkillsMessage />
        )}
      </ListContainer>
    </>
  );
};

export default MySkills;

import React from 'react';

import noUsers from 'assets/images/GenericIcons/no users.svg';
import { CustomTextInput, CustomTextArea } from 'components/NewLyncworkUIKit';

import { Card, SectionTitle, IllustrationContainer } from './styles';
import RoleDetails from './RoleDetails';

import { useRoleForm } from '../../RoleFormContext';

const RoleTabs = ({ roleId }) => {
  if (!roleId) {
    return (
      <IllustrationContainer>
        <img src={noUsers} alt="no users" />
      </IllustrationContainer>
    );
  }
  return <RoleDetails roleId={roleId} />;
};

const RoleGeneralInfo = () => {
  const {
    roleId,
    formState: { roleName, description },
    handleTextChange,
    roleNameError
  } = useRoleForm();

  const roleNameSubLabel = roleNameError || 'Required';

  return (
    <Card>
      <SectionTitle>Role Info</SectionTitle>
      <CustomTextInput
        required
        label="Name"
        value={roleName}
        name="roleName"
        onChange={handleTextChange}
        placeholder="Enter role name"
        subLabel={roleNameSubLabel}
        error={!!roleNameError}
      />
      <CustomTextArea
        name="description"
        value={description}
        onChange={handleTextChange}
        label="Description"
        placeholder="Enter role description"
        rows={4}
      />
      <RoleTabs roleId={roleId} />
    </Card>
  );
};

export default RoleGeneralInfo;

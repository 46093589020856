import React from 'react';
import DoneIcon from '@material-ui/icons/Done';
import AutorenewIcon from '@material-ui/icons/Autorenew';

import { Avatar, CustomButton, IconButton } from 'components/NewLyncworkUIKit';
import generateMediaUrl from 'utilities/generateMediaUrl';

import {
  Container,
  AppDetailContainer,
  AppName,
  AppDesc,
  StyledLink,
  AddedButton
} from './styles';

const AppStatus = ({ subscriptionStatus, link }) => {
  switch (subscriptionStatus) {
    case 'ACTIVE':
      return (
        <AddedButton>
          <DoneIcon fontSize="inherit" />
          Added
        </AddedButton>
      );
    case 'DELETION_IN_PROGRESS':
      return (
        <AddedButton>
          <AutorenewIcon fontSize="inherit" />
          Deletion In Progress
        </AddedButton>
      );
    default:
      return (
        <StyledLink to={link}>
          <CustomButton size="small">View</CustomButton>
        </StyledLink>
      );
  }
};

const ApplicationCard = ({ app }) => {
  const {
    appLogo,
    appName,
    id,
    appDesc,
    subscriptionAppId,
    subscriptionStatus
  } = app;
  const src = generateMediaUrl(appLogo);
  const link = `/admin-applications/view/${id}`;
  const isAdded = subscriptionAppId !== null;
  return (
    <Container>
      <Avatar src={src} name={appName} objectFit={'contain'} colorKey={id} />
      <AppDetailContainer>
        <AppName>{appName}</AppName>
        <AppDesc>{appDesc}</AppDesc>
      </AppDetailContainer>
      <AppStatus subscriptionStatus={subscriptionStatus} link={link} />
    </Container>
  );
};

export default ApplicationCard;

import React, { useState, useEffect } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import LightboxPrompt from 'components/LightboxPrompt';

const PageTransitionBlock = ({ when }) => {
  const history = useHistory();
  const [blockingState, setBlockingState] = useState({
    modalVisible: false,
    lastLocation: null,
    confirmedNavigation: false
  });

  const closeModal = () => {
    setBlockingState(prevState => ({ ...prevState, modalVisible: false }));
  };
  const openModal = (lastLocation = null) => {
    setBlockingState(prevState => ({
      ...prevState,
      modalVisible: true,
      lastLocation
    }));
  };

  const handleBlockNavigation = newLocation => {
    const { confirmedNavigation } = blockingState;
    const nextLocation = newLocation?.pathname || null;
    if (when && !confirmedNavigation) {
      openModal(nextLocation);
      return false;
    }
    return true;
  };

  const confirmNavigation = () => {
    setBlockingState(prevState => ({
      ...prevState,
      confirmedNavigation: true
    }));
  };

  useEffect(() => {
    const { lastLocation, confirmedNavigation } = blockingState;
    if (lastLocation && confirmedNavigation) {
      history.push(lastLocation);
    }
  }, [blockingState]);

  return (
    <>
      <Prompt when={when} message={handleBlockNavigation} />
      <LightboxPrompt
        onClickOutside={closeModal}
        isOpen={blockingState.modalVisible}
        cancelButtonAction={closeModal}
        confirmButtonAction={confirmNavigation}
        confirmButtonText="Discard"
      />
    </>
  );
};

export default PageTransitionBlock;

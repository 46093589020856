import { useState, useEffect } from 'react';
import { DropboxService } from 'services';
import { downloadBlob } from 'services/hellosign.service';
import imageDocuments from 'assets/images/Onboarding/Documents.svg';
import pdfIcon from 'assets/images/adminOnboarding/documents/pdfIcon.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import Pagination from 'components/Pagination';
import {
  Container,
  HeaderContainer,
  DocumentsContainer,
  DocumentCard,
  EmptyImage,
  EmptyMessage,
  DocumentIcon,
  DocumentInfoSection,
  DocumentTitle,
  DocumentInfo,
  LoadingContainer
} from './styles';
import { load } from 'webfontloader';

const Document = ({ file }) => {
  const { signingTitle, description, dropboxFileId, fileName, fileCategory } =
    file;

  const downloadFile = async () => {
    try {
      const response = await DropboxService.downloadUser(dropboxFileId);
      const blob = response.data;
      downloadBlob(blob, fileName);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DocumentCard>
      <DocumentIcon src={pdfIcon} alt="icon" />
      <DocumentInfoSection>
        <DocumentTitle onClick={() => downloadFile()}>
          {signingTitle}
        </DocumentTitle>
        <DocumentInfo>{description}</DocumentInfo>
      </DocumentInfoSection>
    </DocumentCard>
  );
};

const Documents = () => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  useEffect(() => {
    let mounted = true;
    const getFiles = async () => {
      setLoading(true);
      try {
        const response = await DropboxService.findDropboxFileByJWT(page);
        setTotalPages(response.data.totalPages);

        if (mounted) {
          setFiles(response.data.content);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        mounted && setLoading(false);
      }
    };
    getFiles();
    return () => {
      mounted = false;
    };
  }, [page]);

  const changePage = page => {
    setPage(page - 1);
  };

  return (
    <Container>
      <HeaderContainer>
        <h1>Documents</h1>
      </HeaderContainer>
      <DocumentsContainer>
        {loading ? (
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        ) : files.length === 0 ? (
          <>
            <EmptyImage alt="no-tasks-upcoming" src={imageDocuments} />
            <EmptyMessage>You have no documents to view.</EmptyMessage>
          </>
        ) : (
          <>
            {files.map((file, i) => {
              return <Document key={i} file={file} />;
            })}
          </>
        )}
      </DocumentsContainer>
      <Pagination
        totalPages={totalPages}
        currentPage={page + 1}
        changePage={changePage}
      />
    </Container>
  );
};

export default Documents;

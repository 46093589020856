import { CustomSearch } from 'components/NewLyncworkUIKit';

import ListTable from 'components/ListTable';
import { TableHeader } from './TableHeader';
import { UserCard } from './UserCard';
import Pagination from 'components/Pagination';
import { AsyncSelect } from 'components/NewLyncworkUIKit';
import { DropdownService } from 'services';

import {
  ContentRow,
  SelectContainer,
  FilterContainer,
  SearchSection
} from './styles';

const areAllVisibleUsersSelected = ({
  selectedUserIds,
  isLoading,
  visibleUsers
}) => {
  if (isLoading) {
    return false;
  }
  const totalUsers = visibleUsers.length;
  if (!totalUsers > 0) {
    return false;
  }
  const visibleUserIds = visibleUsers.map(user => user.id);
  const isEveryVisibleSelected = visibleUserIds.every(id =>
    selectedUserIds.includes(id)
  );
  return isEveryVisibleSelected;
};

const Filter = ({ onSelect, label, name }) => {
  const paramName = label.includes('Roles') ? 'roles' : 'teams';
  const loadOptions = async inputValue => {
    try {
      const response = await DropdownService[paramName]({ name: inputValue });
      return response.data.content;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FilterContainer>
      <span>{label}</span>
      <SelectContainer>
        <AsyncSelect
          size="small"
          placeholder="---"
          onChange={onSelect}
          loadOptions={loadOptions}
          defaultOptions
          isClearable
        />
      </SelectContainer>
    </FilterContainer>
  );
};

const UserSelection = ({
  setSearchParams,
  data,
  setSelectedUsers,
  selectedUserIds,
  pageInfo,
  isLoading,
  search,
  setSearch,
  handleParamChange,
  handlePageChange
}) => {
  const checkUser = user => {
    const { id: userId } = user;
    if (selectedUserIds.includes(userId)) {
      setSelectedUsers(old => old.filter(user => user.id !== userId));
    } else {
      setSelectedUsers(old => [...old, user]);
    }
  };

  const handleSelectAll = e => {
    const { checked } = e.target;
    const visibleUserIds = data.map(user => user.id);
    if (!checked) {
      setSelectedUsers(oldSelection => {
        const removeVisible = oldSelection.filter(
          user => !visibleUserIds.includes(user.id)
        );
        return removeVisible;
      });
    } else {
      setSelectedUsers(oldSelection => {
        const selectedUserIds = oldSelection.map(user => user.id);
        const unselected = data.filter(
          user => !selectedUserIds.includes(user.id)
        );
        return [...oldSelection, ...unselected];
      });
    }
  };

  const allSelectedChecked = areAllVisibleUsersSelected({
    selectedUserIds,
    isLoading,
    visibleUsers: data
  });

  return (
    <>
      <CustomSearch
        placeholder="Search Users"
        value={search}
        onChange={e => setSearch(e.target.value)}
        onSearch={handleParamChange('name')}
      />
      <SearchSection>
        <Filter
          onSelect={handleParamChange('roleId')}
          name="Roles"
          label="By Roles:"
        />
        <Filter
          onSelect={handleParamChange('teamId')}
          name="Teams"
          label="By Teams:"
        />
      </SearchSection>
      <ContentRow>
        <ListTable
          className="users-table"
          loading={isLoading}
          hasContent={data.length > 0}
          header={
            <TableHeader
              allSelectedChecked={allSelectedChecked}
              handleSelectAll={handleSelectAll}
            />
          }
        >
          {data.map(user => {
            const { id: userId } = user;
            const checked = selectedUserIds.includes(userId);

            return (
              <UserCard
                key={userId}
                user={user}
                checked={checked}
                handleChecked={checkUser}
              />
            );
          })}
        </ListTable>
      </ContentRow>
      <Pagination
        totalPages={pageInfo.totalPages}
        currentPage={pageInfo.currentPage}
        changePage={handlePageChange}
      />
    </>
  );
};

export default UserSelection;

import axios from 'axios';
import env from '../env';

const hellosignUrl = `${env.frontDoorDomain}/services/hellosign/api`;

export const signatureRequestDownloadable = 'signature_request_downloadable';
export const signatureRequestAllSigned = 'signature_request_all_signed';
export const signatureRequestSigned = 'signature_request_signed';

export const canFileBeViewed = eventType => {
  return (
    eventType === signatureRequestDownloadable ||
    eventType === signatureRequestAllSigned
  );
};

export const downloadBlob = (blob, fileName = 'myFile.pdf') => {
  const url = window.URL.createObjectURL(blob);
  var a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const HelloSignService = {
  getSignatureRequestByJWT: () => {
    return axios.get(`${hellosignUrl}/signature-requests/user-by-jwt?size=100`);
  },
  getFileBySignatureId: signatureRequestId => {
    return axios.get(
      `${hellosignUrl}/signature-requests/${signatureRequestId}`,
      { responseType: 'blob' }
    );
  },
  getSignUrl: signatureRequestId => {
    return axios.get(
      `${hellosignUrl}/signature-requests/${signatureRequestId}/sign-url`
    );
  },
  signDocument: file => {
    return axios.post(`${hellosignUrl}/signature-requests`, file);
  },
  getAllSignedDocuments: (name = '', page = '0', size = '10') => {
    return axios.get(
      `${hellosignUrl}/signature-requests/?name=${name}&page=${page}&size=${size}&eventType=signature_request_all_signed,signature_request_downloadable `
    );
  },
  deleteSignatureRequest: signatureRequestId => {
    return axios.delete(
      `${hellosignUrl}/signature-requests/${signatureRequestId}`
    );
  }
};

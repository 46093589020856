import React from 'react';

import generateMediaUrl from 'utilities/generateMediaUrl';
import Avatar from 'components/NewLyncworkUIKit/Avatar';
import Button from 'components/NewLyncworkUIKit/CustomButton';

import { CapabilityCardContainer } from './styles';

const CapabilityCard = ({ applicationCapability, added, toggleCapability }) => {
  const { capability: name, id, logo } = applicationCapability;
  const src = generateMediaUrl(logo);
  const buttonText = added ? 'Remove' : 'Add';
  const variant = added ? 'secondary' : 'primary';
  const onClick = () => toggleCapability(applicationCapability);
  return (
    <CapabilityCardContainer>
      <Avatar
        src={src}
        colorKey={id}
        name={name}
        className="icon"
        objectFit="contain"
      />
      <span>{name}</span>
      <Button size="small" variant={variant} onClick={onClick}>
        {buttonText}
      </Button>
    </CapabilityCardContainer>
  );
};

export default CapabilityCard;

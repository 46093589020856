import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useTrackPageView } from 'matomo/hooks';

import PageContainer from './components/PageContainer';

import Login from 'pages/Login';
import AdminSetup from 'pages/Onboarding/AdminSetup';
import UserOnboarding from 'pages/Onboarding/UserOnboarding';

const Routes = ({ user }) => {
  const { pathname } = useLocation();
  useTrackPageView(pathname);
  return (
    <Switch>
      <Route path="/login" exact component={Login} />
      <Route path="/admin-setup" component={AdminSetup} />
      <Route path="/user/welcome" component={UserOnboarding} />
      <Route path="/" component={PageContainer} />
    </Switch>
  );
};

export default Routes;

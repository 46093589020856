import React from 'react';

import SmallToast from './SmallToast';
import LargeToast from './LargeToast';

const Toast = ({
  title,
  message,
  icon,
  link,
  linkText,
  linkAction,
  size = 'large',
  dismissToast
}) => {
  const handleLinkClick = e => {
    linkAction(e);
    dismissToast();
  };
  if (size === 'large') {
    return (
      <LargeToast
        icon={icon}
        dismissToast={dismissToast}
        title={title}
        message={message}
        link={link}
        linkText={linkText}
        handleLinkClick={handleLinkClick}
      />
    );
  }

  return (
    <SmallToast
      dismissToast={dismissToast}
      icon={icon}
      link={link}
      message={message}
      linkText={linkText}
      handleLinkClick={handleLinkClick}
    />
  );
};

export default Toast;

import React, { useState } from 'react';
import styled from 'styled-components';
import { AsyncPaginate } from 'react-select-async-paginate';
import { ErrorP } from './styles';
import colors from 'designSystem/foundations/colors';
import { style as selectStyle } from 'components/NewLyncworkUIKit/CustomSelect';
import {
  colorOptions,
  errorColor,
  defaultBackground,
  disabledBackground,
  borderHoverColor,
  defaultBorderColor,
  Container,
  Label,
  SubLabel
} from './styles';

const StyledAsyncPaginate = styled(AsyncPaginate)`
  ${selectStyle}
`;

const AsyncDropdown = ({
  paginatedCallback,
  id,
  name,
  label,
  isMulti = false,
  onChange,
  DefaultValue,
  inputRef,
  minWidth = '100px',
  maxWidth,
  allValue,
  error = false,
  errorMessage,
  openUp = false,
  value,
  placeholder = label,
  minHeight = '42px',
  ...rest
}) => {
  const [realPage, setRealPage] = useState(0);
  async function loadOptions(search, loadedOptions, { page }) {
    //Paginated API must be passed for async loading.
    const { data } = await paginatedCallback(search, page - 1, 10);
    setRealPage(realPage + 1);

    const contentWithDefault = DefaultValue
      ? [
          {
            label: DefaultValue.label,
            value: DefaultValue.value,
            id: DefaultValue.id
          },
          ...data.content
        ]
      : [...data.content];
    const needsClearInOptions = isMulti ? data.content : contentWithDefault;
    const formattedContent = needsClearInOptions.map(item => {
      const label = item.firstName
        ? `${item.firstName} ${item.lastName}`
        : item.teamName
        ? item.teamName
        : item.roleName
        ? item.roleName
        : item.taskName
        ? item.taskName
        : item.appName
        ? item.appName
        : item.name
        ? item.name
        : item.label;

      return {
        ...item,
        value: label,
        label: label
      };
    });
    return {
      options: !!allValue ? [allValue, ...formattedContent] : formattedContent,
      hasMore: !data.last,
      additional: {
        page: page + 1
      }
    };
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <StyledAsyncPaginate
        isClearable
        onChange={onChange}
        value={value}
        isMulti={isMulti}
        id={id}
        name={name}
        label={label}
        loadOptions={loadOptions}
        defaultValue={DefaultValue}
        placeholder={placeholder}
        ref={inputRef}
        debounceTimeout={1000}
        classNamePrefix="lw-select"
        additional={{ page: 1 }}
        {...rest}
      />
      {error && (
        <ErrorP>{errorMessage ? errorMessage : `${label} is required`}</ErrorP>
      )}
    </div>
  );
};
export default AsyncDropdown;

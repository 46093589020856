import axios from 'axios';
import env from '../env';

const baseUrlCompany = `${env.storeDomain}/api/companies/teams`;
const basicTeams = `${env.storeDomain}/api/basic-teams`;
const baseUrl = `${env.storeDomain}/api/teams`;

export const TeamService = {
  create: data => {
    return axios.post(`${baseUrl}`, data);
  },

  update: data => {
    return axios.put(`${baseUrl}`, data);
  },

  find: id => {
    return axios.get(`${baseUrl}/${id}`);
  },
  checkDeletion: id => {
    return axios.get(`${baseUrl}/${id}/check-deletion`);
  },
  list: (name = '', page = '0', size = '10') => {
    return axios.get(`${baseUrl}?name=${name}&page=${page}&size=${size}`);
  },
  listWithUsers: (name = '', page = '0', size = '10') => {
    return axios.get(
      `${env.storeDomain}/api/teams-with-users?name=${name}&page=${page}&size=${size}`
    );
  },
  listLite: (name = '', page = '0', size = '10') => {
    return axios.get(
      `${baseUrlCompany}?name=${name}&page=${page}&size=${size}`
    );
  },
  listByIds: ids => {
    return axios.get(`${basicTeams}?id=${ids}`);
  },
  delete: id => {
    return axios.delete(`${baseUrl}/${id}`);
  }
};

const formatUserData = (header, userData) => {
  const keyValueList = userData
    .map((data, index) => {
      if (header[index] === null) {
        return null;
      }
      return { data, key: header[index].value };
    })
    .filter(a => !!a);

  const initialUserData = { provisionedBy: 'FILE' };
  const formattedUserData = keyValueList.reduce((prev, curr) => {
    const { key, data } = curr;
    return { ...prev, [key]: data };
  }, initialUserData);

  return formattedUserData;
};

export const convertCSVToUserArray = csvData => {
  if (!csvData) {
    return [];
  }

  const { header, selected } = csvData;
  return selected.map(userData => formatUserData(header, userData));
};

import styled, { css } from 'styled-components';

const regularColor = css`
  color: ${props => props.theme.colors.neutral.grey07};
`;
const errorColor = css`
  color: ${props => props.theme.colors.feedback.error};
`;

export const Label = styled.label`
  text-align: left;
  flex-basis: 100%;
  ${props => props.theme.typography.control};
  font-weight: 600;
  color: ${props => props.theme.colors.neutral.grey10};
  margin: 8px 0 0;
  span {
    color: ${props => props.theme.colors.feedback.error};
  }
`;

export const SubLabel = styled.p`
  text-align: left;
  flex-basis: 100%;
  ${props => props.theme.typography.metadata};
  ${props => (props.error ? errorColor : regularColor)};
  margin: 0 0 8px 0;
`;

SubLabel.defaultProps = { error: false };

export const SelectLabel = styled(Label)`
  ${regularColor};
  font-weight: 400;
  margin: 0;
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc((100% - 16px) / 3);
  .lw-select__menu-list {
    max-height: 400px !important;
  }
`;

const errorBorder = css`
  border-color: ${props => props.theme.colors.feedback.error};
`;
export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  gap: 8px;
  .lw-select__control {
    ${props => props.error && errorBorder}
  }
`;

export const HiddenInput = styled.input`
  display: none;
`;

import React from 'react';
import { format, isValid } from 'date-fns';

import UserInfoDetail, { KeyContact } from './UserInfoDetail';
import { Container, SectionContainer } from './styles';

const formatProvisionDate = provisionUserDate => {
  if (!provisionUserDate) {
    return 'No provision date found';
  }
  const dateObj = new Date(provisionUserDate.replace('-', '/'));
  const isDateValid = isValid(dateObj);
  if (!isDateValid) {
    return '';
  }
  return format(dateObj, 'MM/dd/yyyy');
};

const getRoleNames = roles => {
  const roleNameList = roles.map(role => role.roleName);
  const roleNames = roleNameList.join(', ') || 'No roles assigned';
  return roleNames;
};
const getTeamNames = teams => {
  const teamNameList = teams.map(team => team.teamName);
  const teamNames = teamNameList.join(', ') || 'No teams assigned';
  return teamNames;
};
const WorkInfoTab = ({ user }) => {
  const {
    username,
    provisionUserDate,
    emailPrimary,
    roles,
    teams,
    manager,
    mentor,
    itPrime,
    hrPrime
  } = user;
  const provisionDate = formatProvisionDate(provisionUserDate);
  const roleNames = getRoleNames(roles);
  const teamNames = getTeamNames(teams);
  const hasKeyContact = manager || hrPrime || mentor || itPrime;
  return (
    <Container>
      <SectionContainer>
        <UserInfoDetail label="Provision Date" text={provisionDate} />
        <UserInfoDetail label="Username" text={username} />
        <UserInfoDetail label="Company Email" text={emailPrimary} />
      </SectionContainer>
      <SectionContainer>
        <UserInfoDetail label="Roles" text={roleNames} />
        <UserInfoDetail label="Teams" text={teamNames} />
      </SectionContainer>
      {hasKeyContact && (
        <SectionContainer>
          <KeyContact label="Manager" contact={manager} />
          <KeyContact label="HR Prime" contact={hrPrime} />
          <KeyContact label="Mentor" contact={mentor} />
          <KeyContact label="IT Prime" contact={itPrime} />
        </SectionContainer>
      )}
    </Container>
  );
};

export default WorkInfoTab;

import React, { useRef } from 'react';

import { Container, TextArea } from './styles';

const setHeight = element => {
  if (!element) {
    return null;
  }

  element.style.height = `12px`;
  const { scrollHeight } = element;
  element.style.height = `${scrollHeight}px`;
};

const CommentTextArea = ({ value, handleChange, handleKeyPress, disabled }) => {
  const textAreaRef = useRef();
  setHeight(textAreaRef.current);
  return (
    <Container>
      <TextArea
        placeholder="Write a comment..."
        ref={textAreaRef}
        value={value}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        disabled={disabled}
      />
    </Container>
  );
};

export default CommentTextArea;

import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useButtonTrack } from 'matomo/hooks';
import useClickOutside from 'hooks/useClickOutside';

import { CustomTooltip } from 'components/NewLyncworkUIKit';
import { Cell, Row } from 'components/ListTable';

import {
  Container,
  Circle,
  TaskCircle,
  Button,
  ActionCell,
  MenuContainer
} from './styles';
import { journeyListPageEvents } from 'pages/Journeys/instrumentationEvents';

const getAssignmentInfo = ({ assignmentType, teams, roles }) => {
  const paramName = assignmentType === 'ROLE' ? 'roleName' : 'teamName';
  const list = assignmentType === 'ROLE' ? roles : teams;

  if (list.length === 0) {
    return {
      assignmentName: `All ${assignmentType.toLowerCase()}s`,
      remainingCount: 0
    };
  }

  const [firstAssigned] = list;
  const remainingCount = list.length - 1;
  const assignmentName = firstAssigned[paramName];
  return { assignmentName, remainingCount };
};

const Assignment = ({
  assignmentName,
  remainingCount,
  assignedToAll,
  assignmentType
}) => {
  if (assignedToAll) {
    return (
      <Cell flex={3}>All {assignmentType === 'ROLE' ? 'Roles' : 'Teams'}</Cell>
    );
  }
  return (
    <Cell flex={3}>
      {assignmentName}{' '}
      {remainingCount > 0 && <Circle>+{remainingCount}</Circle>}
    </Cell>
  );
};

const Tasks = ({ workflowTasks }) => {
  const totalLength = workflowTasks.length;
  const actionLength = workflowTasks.filter(
    workflowTask => workflowTask.task.taskType === 'ACTION'
  ).length;
  const eventLength = workflowTasks.length - actionLength;
  return (
    <Cell flex={4}>
      {actionLength > 0 && (
        <>
          <TaskCircle tasktype="ACTION" /> Action ({actionLength})
        </>
      )}
      {eventLength > 0 && (
        <>
          <TaskCircle tasktype="EVENT" /> Event ({eventLength})
        </>
      )}
    </Cell>
  );
};

const Duration = ({ startDay, endDay }) => {
  if (!startDay || !endDay) {
    return <Cell flex={3}>{null}</Cell>;
  }
  const text = `Day ${startDay} - Day ${endDay}`;
  return <Cell flex={3}>{text}</Cell>;
};

const Action = ({ id }) => {
  const trackButton = useButtonTrack();

  const history = useHistory();

  const menuRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const openTooltip = () => {
    trackButton('Open Journey Action Menu');
    setIsOpen(a => !a);
  };
  const closeTooltip = () => setIsOpen(false);
  useClickOutside(menuRef, closeTooltip);

  const handleEdit = () => {
    trackButton(journeyListPageEvents.edit_journey);
    history.push(`/admin-journeys/edit/${id}`);
  };
  const handleDuplicate = () => {
    trackButton(journeyListPageEvents.duplicate_journey);
    history.push(`/admin-journeys/duplicate/${id}`);
  };
  return (
    <ActionCell ref={menuRef}>
      {isOpen && (
        <MenuContainer>
          <button onClick={handleEdit}>Edit</button>
          <button onClick={handleDuplicate}>Duplicate</button>
        </MenuContainer>
      )}
      <Button onClick={openTooltip}>
        <MoreVertIcon />
      </Button>
    </ActionCell>
  );
};

const JourneysListCard = ({ journey }) => {
  const {
    id,
    workflowName: name,
    assignedToAll,
    assignmentType,
    teams,
    roles,
    workflowTasks,
    startDay,
    endDay
  } = journey;
  const { assignmentName, remainingCount } = getAssignmentInfo(journey);
  return (
    <Row>
      <Cell flex={3} className="list-row-name">
        {name}
      </Cell>
      <Assignment
        assignmentName={assignmentName}
        remainingCount={remainingCount}
        assignedToAll={assignedToAll}
        assignmentType={assignmentType}
      />
      <Tasks workflowTasks={workflowTasks} />
      <Duration startDay={startDay} endDay={endDay} />
      <Action id={id} />
    </Row>
  );
};

export default JourneysListCard;

export const LOGIN = '(AUTH) LOGIN';
export const GET_SESSION = '(AUTH) GET_SESSION';
export const LOGOUT = '(AUTH) LOGOUT';
export const CLEAR_AUTH = '(AUTH) CLEAR_AUTH';
export const ERROR_MESSAGE = '(AUTH) ERROR_MESSAGE';
export const GET_USER = '(AUTH) GET_USER';
export const SET_COMPANY = '(AUTH) SET_COMPANY';

const payloadAction = actionType => data => ({
  type: actionType,
  payload: data
});
export const setCompany = payloadAction(SET_COMPANY);
export const setLogin = payloadAction(LOGIN);
export const setGetSession = payloadAction(GET_SESSION);
export const setGetUser = payloadAction(GET_USER);
export const setLogout = payloadAction(LOGOUT);
export const setClearAuth = payloadAction(CLEAR_AUTH);
export const setErrorMessage = payloadAction(ERROR_MESSAGE);

import styled from 'styled-components';

import { multilineEllipsisFunc } from 'StyleVariables/StandardComponents';

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 80px;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.grey01};
  padding: 12px 0;
  position: relative;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h1`
  margin: 0;
  ${props => props.theme.typography.control};
  font-weight: 500;
  color: ${props => props.theme.colors.neutral.grey10};
  ${multilineEllipsisFunc(1)}
`;

export const TaskLength = styled.span`
  flex-shrink: 0;
  ${props => props.theme.typography.footnote};
  color: ${props => props.theme.colors.feedback.confirmation};
`;

export const Description = styled.p`
  margin: 0;
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey06};
  ${multilineEllipsisFunc(1)}
`;

import styled from 'styled-components';
import { UserHelpSection } from '../../styles';

export const TopicContainer = styled(UserHelpSection)`
  width: 100%;
  box-sizing: border-box;
  padding: 6% 5%;
  margin-top: 40px;
`;

export const DropdownContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  p {
    margin-right: 0.5em;
    color: #8998aa;
    font-size: 16px;
  }
`;

export const TopicHeaderContainer = styled.div`
  margin-top: 50px;
  padding-left: 5px;      
  padding-bottom: 20px;
  border-bottom: 1px solid #dcdde4;
  display: flex;
  align-items: center;
`;

export const TopicHeaderIcon = styled.div`
  width: 50px;
  height: 50px;
  filter: grayscale(1);
  img {
    width: 100%;
    height: auto;
  }
`;

export const TopicHeaderContent = styled.div`
  flex: 1;
  margin-left: 30px;
  color: #4d4d4d;
`;

export const TopicHeaderTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
`;

export const TopicHeaderDescription = styled.div`
  font-size: 14px;
  margin-top: 5px;
`;
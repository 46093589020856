import React from 'react';
import styled from 'styled-components';

const Span = styled.span`
  color: ${props => props.theme.colors.feedback.error};
`;

export const getOptionLabel = option => {
  if (option.required) {
    return (
      <>
        {option.label}
        <Span> * </Span>
      </>
    );
  }
  return option.label;
};

export const options = [
  {
    value: 'firstName',
    label: 'First Name',
    required: true
  },
  {
    value: 'lastName',
    label: 'Last Name',
    required: true
  },
  {
    value: 'username',
    label: 'Username',
    required: true
  },
  {
    value: 'emailPrimary',
    label: 'Company Email',
    required: true
  },
  {
    value: 'emailSecondary',
    label: 'Personal Email',
    required: true
  }
];

export const areRequiredOptionsSelected = header => {
  const requiredOptions = options.filter(option => option.required);
  const unselectedOptions = requiredOptions.filter(
    option => !header.includes(option)
  );

  return { unselectedOptions, isValid: unselectedOptions.length === 0 };
};

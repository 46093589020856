import React from 'react';

import LoadingButton from 'components/LoadingButton';
import DeleteButton from 'components/DeleteButton';
import {
  RoleGeneralInfo,
  RoleAuthorization,
  RoleApplications,
  RoleFormButtons
} from './components';
import { Grid, ButtonContainer } from './styles';

import RoleFormContext from './RoleFormContext';

const RoleForm = ({ roleDTO = null }) => {
  return (
    <RoleFormContext roleDTO={roleDTO}>
      <Grid>
        <RoleGeneralInfo />
        <RoleAuthorization />
        <RoleApplications />
      </Grid>
      <RoleFormButtons />
    </RoleFormContext>
  );
};

export default RoleForm;

import {
  Container,
  InfoContainer,
  SkillName,
  SliderContainer,
  SliderInput,
  Progress,
  ProgressDot,
  SkillLevelTextContainer,
  SkillLevelText,
  CardContainer,
  Header
} from './styles';

const Slider = ({ skillLevel }) => {
  const maxLevel = 4;
  const levels = new Array(maxLevel + 1).fill(0).map((_, i) => i);
  const levelText = ['', 'Novice', 'Intermediate', 'Advanced', 'Expert'];
  const width = `${(skillLevel.level.order * 100) / maxLevel}%`;
  return (
    <>
      <SliderContainer>
        <SliderInput
          min={0}
          max={maxLevel}
          value={skillLevel.level.order}
          readOnly
        />
        <Progress width={width}>
          {levels.map(level => (
            <ProgressDot
              key={level}
              isBefore={level <= skillLevel.level.order}
            />
          ))}
        </Progress>
      </SliderContainer>
      <SkillLevelTextContainer>
        {levelText.map((text, index) => (
          <SkillLevelText
            key={text}
            isSelected={index === skillLevel.level.order}
          >
            {text}
          </SkillLevelText>
        ))}
      </SkillLevelTextContainer>
    </>
  );
};

const SkillCard = ({ data }) => {
  const { skillLevel } = data;
  const { skill } = skillLevel;
  return (
    <CardContainer>
      <InfoContainer>
        <SkillName>{skill.name}</SkillName>
        <Slider skillLevel={skillLevel} />
      </InfoContainer>
    </CardContainer>
  );
};

const SkillSection = ({ userData }) => {
  const { skills } = userData;
  return (
    <Container>
      {skills.length > 0 ? (
        <div>
          <Header>{userData.firstName}'s skills</Header>
          {skills.map((skill, i) => {
            return <SkillCard key={i} data={skill} />;
          })}
        </div>
      ) : (
        'This user has no skills to display'
      )}
    </Container>
  );
};

export default SkillSection;

import { useState, useEffect, useCallback } from 'react';

import { ApplicationService, SubscriptionAppService } from 'services/';
import debounce from 'utilities/debounce';

export const initialState = {
  name: '',
  page: 0,
  size: 10,
  category: '',
  applications: [],
  loading: true,
  totalPages: 0
};

const getApplicationsWithSubIds = async applications => {
  const appIds = applications.map(({ id }) => id);
  const subscriptionAppsResponse = await SubscriptionAppService.listByAppIds(
    appIds
  );

  const subscriptionIds = {};
  const subscriptionStatuses = {};
  subscriptionAppsResponse.data.forEach(subscription => {
    const {
      id: subscriptionId,
      status: subscriptionStatus,
      application: { id: appId }
    } = subscription;
    subscriptionIds[appId] = subscriptionId;
    subscriptionStatuses[appId] = subscriptionStatus;
  });

  const applicationsWithSubIds = applications.map(application => {
    const { id } = application;
    const subscriptionAppId = subscriptionIds[id] || null;
    const subscriptionStatus = subscriptionStatuses[id] || null;
    return { ...application, subscriptionAppId, subscriptionStatus };
  });
  return applicationsWithSubIds;
};

const useApplications = ({
  initialInputValue = '',
  getSubscriptionId = false,
  initialSize = 10
}) => {
  const [inputValue, setInputValue] = useState(initialInputValue);
  const [state, setState] = useState({
    ...initialState,
    name: initialInputValue,
    size: initialSize
  });

  const { name, page, size, category, applications, loading, totalPages } =
    state;

  useEffect(() => {
    let mounted = true;
    const fetchApplications = async () => {
      const response = await ApplicationService.list(
        name,
        page,
        size,
        category
      );
      const { content: fetchedApplications, totalPages } = response.data;

      const hasApps = fetchedApplications.length > 0;
      const shouldGetSubscriptions = getSubscriptionId && hasApps;

      try {
        const applications = shouldGetSubscriptions
          ? await getApplicationsWithSubIds(fetchedApplications)
          : fetchedApplications;
        mounted &&
          setState(oldState => ({
            ...oldState,
            applications,
            totalPages,
            loading: false
          }));
      } catch (error) {
        console.log(error);
        setState(oldState => ({
          ...oldState,
          loading: false
        }));
      }
    };
    fetchApplications();
    return () => {
      mounted = false;
    };
  }, [name, page, size, category]);

  const changePage = newPage => {
    const newPageIndex = newPage - 1;
    setState(oldState => ({
      ...oldState,
      applications: [],
      page: newPageIndex,
      loading: true
    }));
  };

  const updateNameVariable = useCallback(
    debounce(value => {
      setState(oldState => {
        if (oldState.name === value) {
          return oldState;
        }
        return {
          ...oldState,
          applications: [],
          name: value,
          page: 0,
          totalPages: 0,
          loading: true
        };
      });
    }, 500),
    []
  );

  const handleInputChange = e => {
    const value = e.target.value;
    setInputValue(value);
    updateNameVariable(value);
  };

  const handleCategoryChange = ({ name }) => {
    const newCategory = name.toLowerCase() === 'all' ? '' : name;
    setState(oldState => {
      return {
        ...oldState,
        category: newCategory,
        application: [],
        page: 0,
        totalPages: 0,
        loading: true
      };
    });
  };

  return {
    applications,
    totalPages,
    page,
    inputValue,
    changePage,
    handleInputChange,
    category,
    handleCategoryChange,
    loading
  };
};

export default useApplications;

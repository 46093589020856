import { useReducer, useState } from 'react';
import { useHistory } from 'react-router';
import { useButtonTrack } from 'matomo/hooks';
import { JourneyService } from 'services/journeys.service';
import { useDialogue, useEscapePage } from 'hooks';
import { journeySinglePageEvents } from 'pages/Journeys/instrumentationEvents';
import { reducer } from './reducer';
import {
  TOGGLE_CALENDAR_NOTIFICATION,
  CHANGE_TEXT,
  CHANGE_ASSIGNMENT_TYPE,
  CHANGE_ASSIGNMENT,
  MODIFY_TASK,
  DELETE_TASK,
  CHANGE_TASK_DAY,
  SET_DATA,
  TOGGLE_TRIGGER,
  SELECT_PREREQUISITE
} from './actions';
import useTaskModal, { convertToTaskInfo } from './useTaskModal';
import { generateWorkflowTasks } from './utilities';

export { initialState } from './reducer';

const useJourneyForm = state => {
  const trackButton = useButtonTrack();
  const { showDialogue, closeDialogue } = useDialogue();
  const [journey, dispatch] = useReducer(reducer, state);
  const [spinnerLocation, setSpinnerLocation] = useState(null);
  const {
    taskInfo,
    validationMessage,
    editTaskParams,
    isValid,
    reset,
    openModal
  } = useTaskModal();
  const {
    id = null,
    workflowName,
    workflowDesc,
    notificationEnabled,
    assignmentType,
    teams,
    roles,
    workflowTasks,
    prerequisiteWorkflow
  } = journey;
  const [inputErrors, setInputErrors] = useState({
    workflowName: '',
    workflowDesc: ''
  });
  const { shouldEscape, escapePage } = useEscapePage('/admin-journeys');
  const [formModified, setFormModified] = useState(false);
  const blockTransition = formModified && !shouldEscape;

  const toggleTrigger = e => {
    const { checked, name } = e.target;
    dispatch({ type: TOGGLE_TRIGGER, checked, name });
  };

  const selectPrerequisite = prerequisiteWorkflow => {
    dispatch({ type: SELECT_PREREQUISITE, prerequisiteWorkflow });
  };

  const toggleCalendarNotification = e => {
    const { checked } = e.target;
    setFormModified(true);
    dispatch({ type: TOGGLE_CALENDAR_NOTIFICATION, checked });
  };

  const updateText = e => {
    const { name, value } = e.target;
    setInputErrors(oldError => ({ ...oldError, [name]: '' }));
    setFormModified(true);
    dispatch({ type: CHANGE_TEXT, name, value });
  };

  const changeAssignmentType = e => {
    const { value: assignmentType } = e.target;
    setFormModified(true);
    dispatch({ type: CHANGE_ASSIGNMENT_TYPE, assignmentType });
    trackButton(journeySinglePageEvents.change_assignment_type);
  };

  const changeAssignment = (name, value) => {
    setFormModified(true);
    dispatch({ type: CHANGE_ASSIGNMENT, name, value });
    trackButton(journeySinglePageEvents.change_assignments);
  };

  const openTaskModal = (selectedTask = null) => {
    if (!selectedTask) {
      trackButton(journeySinglePageEvents.add_task);
      openModal();
    } else {
      trackButton(journeySinglePageEvents.edit_task);
      const selectedTaskInfo = convertToTaskInfo(selectedTask);
      openModal(selectedTaskInfo);
    }
  };

  const saveTaskModal = () => {
    trackButton(journeySinglePageEvents.save_task);
    const isTaskInfoValid = isValid();
    if (!isTaskInfoValid) {
      return;
    }
    setFormModified(true);
    dispatch({ type: MODIFY_TASK, taskInfo });
    reset();
  };

  const deleteTask = order => {
    trackButton(journeySinglePageEvents.delete_task);

    const deleteConfirm = () => {
      closeDialogue();
      setFormModified(true);
      dispatch({ type: DELETE_TASK, order });
      reset();
    };

    showDialogue({
      title: 'Are you sure?',
      message: 'Are you sure you want to delete this task?',
      confirmButtonText: 'Yes',
      confirmButtonAction: deleteConfirm,
      cancelButtonText: 'No',
      cancelButtonAction: closeDialogue
    });
  };

  const assignmentInfoProps = {
    assignmentType,
    teams,
    roles,
    changeAssignmentType,
    changeAssignment
  };

  const journeyInfoProps = {
    workflowName,
    workflowDesc,
    inputErrors,
    updateText
  };

  const journeySettingsProps = {
    notificationEnabled,
    toggleCalendarNotification,
    repeatable: !!journey.repeatable,
    eventbased: !!journey.eventbased,
    toggleTrigger,
    prerequisiteWorkflow,
    selectPrerequisite
  };

  const handleSubmit = async () => {
    trackButton(journeySinglePageEvents.save_journey);
    if (!workflowName || !workflowDesc) {
      const nameError = workflowName ? '' : 'Name is required';
      const descError = workflowDesc ? '' : 'Description is required';
      setInputErrors({
        workflowName: nameError,
        workflowDesc: descError
      });
      return;
    }
    setSpinnerLocation('save');
    const requestMethod = id === null ? 'create' : 'update';
    const journeyData = { ...journey };
    try {
      const newWorkflowTasks = await generateWorkflowTasks(workflowTasks);
      journeyData.workflowTasks = newWorkflowTasks;
    } catch (error) {
      console.log(error);
      return;
    }
    dispatch({ type: SET_DATA, payload: journeyData });
    try {
      await JourneyService[requestMethod](journeyData);
      escapePage();
    } catch (error) {
      setSpinnerLocation(null);
      const title = error?.response?.data?.title;
      const errorKey = error?.response?.data?.errorKey;
      if (errorKey === 'uniqueconstraintviolation') {
        setInputErrors(old => {
          return { ...old, workflowName: title };
        });
      }
    }
  };

  const changeTaskDay = ({ order, startDay, endDay }) => {
    setFormModified(true);
    dispatch({ type: CHANGE_TASK_DAY, order, startDay, endDay });
  };

  const closeTaskModal = () => {
    trackButton(journeySinglePageEvents.discard_task_change);
    reset();
  };
  const journeyTasksProps = {
    taskInfo,
    validationMessage,
    editTaskParams,
    openTaskModal,
    saveTaskModal,
    closeTaskModal,
    changeTaskDay,
    deleteTask,
    journeyTasks: workflowTasks
  };

  const canDelete = id !== null;

  const confirmJourneyDeletion = async () => {
    trackButton(journeySinglePageEvents.delete_journey);
    closeDialogue();
    setSpinnerLocation('delete');
    try {
      await JourneyService.delete(id);
    } catch (error) {
      console.log(error);
      setSpinnerLocation(null);
    }
    escapePage();
  };
  const deleteJourney = () => {
    trackButton(journeySinglePageEvents.delete_journey_attempt);
    showDialogue({
      title: 'Are you sure?',
      message: 'Would you like to delete this journey?',
      confirmButtonText: 'Delete',
      confirmButtonAction: confirmJourneyDeletion,
      cancelButtonAction: closeDialogue
    });
  };

  return {
    deleteJourney,
    canDelete,
    journeyInfoProps,
    journeySettingsProps,
    assignmentInfoProps,
    journeyTasksProps,
    returnToListPage: escapePage,
    handleSubmit,
    spinnerLocation,
    blockTransition
  };
};

export default useJourneyForm;

import React, { useReducer, useEffect, useState } from 'react';

import useAuthentication from 'hooks/useAuthentication';
import { UserSkillService, SkillService } from 'services/skill.service';

import { fetchSkills, removeDuplicateSkills } from './utils';
import skillsReducer, * as skillsActions from './reducer';

import delay from 'utilities/delay';
const {
  ADD_SKILL,
  REMOVE_SKILL,
  UPDATE_SKILL,
  REORDER,
  INIT_SKILL
} = skillsActions;

const formatInitSkills = (skills, skillLevels) => {
  const skillLevelIds = skillLevels.map(({ id }) => id);
  const formattedSkills = skills.map(skill => {
    const { levels, ...rest } = skill;
    const currentLevel = levels.find(({ id }) => skillLevelIds.includes(id));
    return { currentLevel, levels, ...rest };
  });
  return formattedSkills;
};

const useSkills = ({ nextPage }) => {
  const {
    user: { roles: userRoles }
  } = useAuthentication();
  const roles = userRoles.map(({ id }) => id);
  const [loading, setLoading] = useState(true);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [oldSkills, setOldSkills] = useState([]);
  const [skills, dispatch] = useReducer(skillsReducer, []);

  useEffect(() => {
    const initSkills = async () => {
      try {
        const { data: userSkills } = await UserSkillService.getUserSkills();
        const {
          data: userSkillLevels
        } = await UserSkillService.getUserSkillLevels();
        const formattedInitSkills = formatInitSkills(
          userSkills,
          userSkillLevels
        );
        setOldSkills(formattedInitSkills);
        dispatch({ type: INIT_SKILL, skills: formattedInitSkills });
      } catch (error) {
        console.log(error);
      } finally {
        await delay(1000);
        setLoading(false);
      }
    };
    initSkills();
  }, []);

  const loadSkills = async inputValue => {
    const { data } = await SkillService.listSkills({
      name: inputValue,
      roles
    });
    return data.content;
  };

  const addSkill = skillToAdd =>
    dispatch({ type: ADD_SKILL, skillToModify: skillToAdd });
  const removeSkill = skillToRemove =>
    dispatch({ type: REMOVE_SKILL, skillToModify: skillToRemove });
  const updateSkill = skillToUpdate =>
    dispatch({ type: UPDATE_SKILL, skillToModify: skillToUpdate });
  const reorderSkills = ({ startIndex, endIndex }) =>
    dispatch({ type: REORDER, startIndex, endIndex });

  const createSkill = async name => {
    const { data: skillCreated } = await SkillService.createSkill({ name });
    addSkill(skillCreated);
  };

  const handleSubmit = async () => {
    const currentSkillIds = skills.map(({ id }) => id);
    const skillsToRemove = oldSkills.filter(
      ({ id }) => !currentSkillIds.includes(id)
    );
    const oldSkillLevelIds = oldSkills.map(
      ({ currentLevel }) => currentLevel.id
    );

    const skillIdsToRemove = skillsToRemove.map(({ id }) => id);

    const currentSkillLevels = skills.map(({ currentLevel }) => currentLevel);
    const updatedSkillLevels = currentSkillLevels.filter(
      ({ id }) => !oldSkillLevelIds.includes(id)
    );

    setButtonSpinner(true);
    try {
      await Promise.all(
        skillIdsToRemove.map(idToRemove =>
          UserSkillService.deleteUserSkill(idToRemove)
        )
      );
      if (updatedSkillLevels.length > 0) {
        await UserSkillService.updateUserSkills(updatedSkillLevels);
      }
      nextPage();
    } catch (error) {
      console.log(error);
      setButtonSpinner(false);
    }
  };

  return {
    loading,
    loadSkills,
    createSkill,
    skills,
    addSkill,
    removeSkill,
    updateSkill,
    reorderSkills,
    handleSubmit,
    buttonSpinner
  };
};

export default useSkills;

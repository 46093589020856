import LoadingScreen from 'components/LoadingScreen';
import { ArrowBack } from '@material-ui/icons';
import TrashIcon from 'assets/images/GenericIcons/trash.svg';
import NotFound404Page from 'pages/NotFound404Page';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { TeamService } from 'services/team.service';

import {
  Title,
  Container,
  BackButton,
  InfoContainer,
  TeamInfo,
  TeamMembers,
  Footer,
  TrashImg,
  UserDetails
} from './styles';
import {
  CustomButton,
  CustomTextInput,
  CustomTextArea,
  ImageCircle
} from 'components/NewLyncworkUIKit';
import Journeys from './Journeys';
import Members from './Members';
import PortalContainer from 'components/PortalContainer';
import AddMember from './AddMember';
import { CircularProgress } from '@material-ui/core';
import useDialogue from 'hooks/useDialogue';
import CloseIcon from '@material-ui/icons/Close';
const justIds = array => {
  if (array) {
    return array.map(x => ({ id: x.id }));
  } else {
    return [];
  }
};
import { useButtonTrack } from 'matomo/hooks';
import { newTeamPageEvents } from '../teamsInstrumentationEvents';
const { back, save_team, delete_team } = newTeamPageEvents;

const TeamsDetailsPage = () => {
  const trackButton = useButtonTrack();
  const history = useHistory();
  const { id } = useParams();
  const [team, setTeam] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const { showDialogue, closeDialogue } = useDialogue();

  const SubmitFunction = async () => {
    setSubmitted(true);
    const isError = !team.teamDesc || !team.teamName;
    if (isError) {
      return;
    }

    const teamData = {
      teamDesc: team.teamDesc,
      teamName: team.teamName,
      teamColor: team.teamColor,
      usersDeleted: justIds(team.usersDeleted),
      usersInserted: justIds(team.usersInserted)
    };

    if (id) {
      teamData.id = team.id;
    }
    const operation = id ? 'update' : 'create';

    try {
      setSubmitting(true);
      await TeamService[operation](teamData);
      history.push('/admin-teams');
    } catch (error) {
      setSubmitting(false);
      console.log(error);
    }
  };

  const getDetails = async () => {
    setLoading(true);
    try {
      const { data } = await TeamService.find(id);
      setTeam({ ...data, usersInserted: [] });
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (id) {
      getDetails();
    } else {
      setTeam({});
      setLoading(false);
    }
  }, []);

  const setAddedUsers = array => {
    const clearedDeleted = team.usersDeleted?.filter(
      insertedUser =>
        !team.usersInserted.map(x => x.id).includes(insertedUser.id)
    );
    setTeam(old => ({
      ...old,
      usersDeleted: clearedDeleted,
      usersInserted: old.usersInserted
        ? [...old.usersInserted, ...array]
        : array
    }));
  };

  const removeUser = user => {
    const filterArray = array =>
      array.filter(insertedUser => insertedUser.id !== user.id);
    setTeam(old => ({
      ...old,
      users: old.users ? filterArray(old.users) : [],
      usersInserted: filterArray(old.usersInserted),
      usersDeleted: old.usersDeleted?.length
        ? [...old.usersDeleted, user]
        : [user]
    }));
  };
  const handleInputChange = e => {
    const { name, value } = e.target;
    setTeam(oldTeam => ({ ...oldTeam, [name]: value }));
  };
  const allMembers = () => {
    if (id) {
      if (team.usersInserted) {
        return [...team.usersInserted, ...team.users];
      }
      return team.users;
    } else {
      if (team.usersInserted) {
        return team.usersInserted;
      }
      return [];
    }
  };

  const deleteTeam = async () => {
    setDeleting(true);

    await TeamService.delete(id); //remove from server
    setDeleting(false);
    history.push('/admin-teams');
  };

  const handleDelete = async () => {
    if (!team.canDelete) {
      const updateThenDelete = async () => {
        await TeamService.update({
          ...team,
          usersDeleted: team.users.map(x => x.id)
        });
        deleteTeam(team.id);
        closeDialogue();
      };
      showDialogue({
        title: 'Delete Team',
        message: `Are you sure? All members of this team will lose access to any journeys exclusive to this team.`,
        confirmButtonText: 'Delete',
        confirmButtonAction: updateThenDelete,
        cancelButtonAction: closeDialogue
      });
    } else {
      deleteTeam(team.id);
    }
  };
  if (error) return <NotFound404Page />;
  if (loading) return <LoadingScreen />;
  return (
    <Container>
      <BackButton
        variant="secondary"
        size="small"
        onClick={() => {
          trackButton(back);
          history.push('/admin-teams');
        }}
      >
        <ArrowBack fontSize="inherit" /> Back
      </BackButton>
      <InfoContainer>
        <TeamInfo>
          <Title>Create Team</Title>
          <CustomTextInput
            placeholder="Enter Team Name"
            label="Team Name"
            name="teamName"
            subLabel={submitted && !team.teamName && 'Required'}
            error={submitted && !team.teamName}
            value={team.teamName}
            onChange={handleInputChange}
            required
          />
          <CustomTextArea
            name="teamDesc"
            placeholder="Enter Team Description"
            label="Team Description"
            value={team.teamDesc}
            subLabel={submitted && !team.teamDesc && 'Required'}
            error={submitted && !team.teamDesc}
            onChange={handleInputChange}
            rows={4}
            required
          />
          <Title topMargin>Journeys</Title>
          <Journeys />
        </TeamInfo>
        <TeamMembers>
          <Title>Team Members</Title>

          <Members
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            allMembers={allMembers()}
            addedMembers={team.usersInserted}
            openModal={() => setIsOpen(true)}
            removeUser={removeUser}
          />
        </TeamMembers>
      </InfoContainer>
      <Footer>
        <CustomButton
          variant="secondary"
          size="small"
          onClick={() => {
            trackButton(delete_team);
            handleDelete();
          }}
        >
          {deleting ? (
            <CircularProgress size="15px" color="inherit" />
          ) : (
            <>
              <TrashImg src={TrashIcon} alt="trash icon" />
              Delete Team
            </>
          )}
        </CustomButton>
        <CustomButton
          onClick={() => {
            SubmitFunction();
            trackButton(save_team);
          }}
        >
          {submitting ? (
            <CircularProgress size="15px" color="inherit" />
          ) : (
            'Save'
          )}
        </CustomButton>
      </Footer>
      <PortalContainer isOpen={isOpen}>
        <AddMember
          closeModal={() => setIsOpen(false)}
          setAddedUsers={setAddedUsers}
          addedUsers={team.usersInserted ? team.usersInserted : []}
          existingUsers={team.users ? team.users : []}
        />
      </PortalContainer>
      {selectedUser && (
        <UserDetails>
          <CloseIcon color="inherit" onClick={() => setSelectedUser(null)} />
          <ImageCircle
            maxWidth="80"
            firstName={selectedUser.firstName}
            url={selectedUser.avatar?.formats?.thumbnail?.url}
          />
          <h1>
            {selectedUser.firstName} {selectedUser.lastName}
          </h1>
          <h2>Username</h2>
          <p>{selectedUser.username}</p>
          <h2>Company Email</h2>
          <p>{selectedUser.emailPrimary}</p>
          <h2>Provision Date</h2>
          <p>{selectedUser?.sysCreationDate?.split('T')[0] || '06/03/2021'}</p>
          <h2>{selectedUser?.roles?.length > 1 ? 'Roles' : 'Role'}</h2>
          <p>{selectedUser?.roles?.map(x => x.roleName).join(',')}</p>
          <h2>{selectedUser?.teams?.length > 1 ? 'Teams' : 'Team'}</h2>
          <p>{selectedUser?.teams.map(x => x.teamName).join(', ')}</p>
          {selectedUser?.manager && (
            <>
              <h2>Manager</h2>
              <p>
                {selectedUser.manager?.firstName}{' '}
                {selectedUser.manager?.lastName}
              </p>
            </>
          )}
          {selectedUser?.mentor && (
            <>
              <h2>Mentor</h2>
              <p>
                {selectedUser.mentor?.firstName} {selectedUser.mentor?.lastName}
              </p>
            </>
          )}
        </UserDetails>
      )}
    </Container>
  );
};

export default TeamsDetailsPage;

import styled, { css } from 'styled-components';
import colors from 'designSystem/foundations/colors';

export const colorOptions = [
  '#4a90e2',
  '#50e3c2',
  '#9013fe',
  '#f5a623',
  '#ff6b6b'
];

export const selectStyles = {
  control: (styles, { isFocused }) => ({
    //access the style props of control, isFocused is a prop, with the onFocus event listener
    //had to put control in here so I could specify the min width of control. sorry. legit tried every other way.
    ...styles,

    minHeight: '43px',

    backgroundColor: 'white',
    borderColor: isFocused
      ? error
        ? 'red'
        : '#ff6b6b'
      : error
      ? 'red'
      : '#dcdde4',
    boxShadow: ' 1px 1px 4px 0 rgba(137, 152, 170, 0.32)',
    ':hover': {
      borderColor: '#dcdde4'
    },
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.5px',
    color: '#788088',
    minWidth: minWidth,
    maxWidth: !!maxWidth && maxWidth
  }),
  menu: styles => {
    return {
      ...styles,
      position: 'absolute',
      zIndex: 21,
      textAlign: 'left',

      top: openUp && 'auto',
      bottom: openUp && '100%'
    };
  },
  singleValue: styles => {
    return {
      ...styles,
      padding: '0 8px',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.5px',
      color: '#788088',
      ':placeholder': {
        color: '#aab2bb'
      }
    };
  },
  valueContainer: styles => {
    return {
      ...styles,
      maxHeight: `${43 * 3}px`,
      overflowY: 'auto',
      '::-webkit-scrollbar': {
        width: '5px'
      },
      '::-webkit-scrollbar-track': {
        background: 'transparent'
      },
      ':hover': {
        '::-webkit-scrollbar-thumb': {
          backgroundColor: '#aab2bb',
          borderRadius: '5px'
        }
      },
      '::placeholder': {
        color: '#aab2bb'
      }
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //more event listeners
    return {
      ...styles,

      zIndex: 20,
      backgroundColor: 'white',
      color: '#aab2bb',
      cursor: isDisabled ? 'not-allowed' : 'default',
      transition: 'all .2s',
      ':hover': {
        backgroundColor: '#f4f5f7',
        color: '#8998aa'
      }
    };
  },

  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor:
        data.teamColor || colorOptions[data.id % colorOptions.length], //assigns a random color from the options, or the relevant color
      borderRadius: '4px',
      fontSize: '16px',
      ':hover': {
        borderColor:
          data.teamColor || colorOptions[data.id % colorOptions.length]
      }
    };
  },
  multiValueLabel: styles => ({
    ...styles,
    color: 'white'
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: 'white',
    transition: 'all .3s',
    ':hover': {
      backgroundColor: 'rgba(255,255,255, 0.3)'
    }
  })
};

export const errorColor = colors.feedback.error;
export const defaultBackground = colors.neutral.white;
export const disabledBackground = colors.neutral.offWhite02;
export const borderHoverColor = colors.neutral.grey07;
export const defaultBorderColor = colors.neutral.grey05;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const regularLabelColor = colors.neutral.grey07;

export const Label = styled.label`
  text-align: left;
  ${props => props.theme.typography.control};
  font-weight: 600;
  color: ${props => props.theme.colors.neutral.grey10};
  margin: ${props => props.margin};
  span {
    color: ${props => props.theme.colors.feedback.error};
  }
`;
Label.defaultProps = { margin: '8px 0' };

export const SubLabel = styled.p`
  text-align: left;
  ${props => props.theme.typography.metadata};
  color: ${props => (props.error ? errorColor : regularLabelColor)};
  margin: ${props => props.margin};
`;

SubLabel.defaultProps = { margin: '8px 0', error: false };

import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import emptyState from 'assets/images/GenericIcons/empty state.svg';
import { JourneyService } from 'services/journeys.service';
import JourneysForm from './JourneysForm';
import {
  PageContainer,
  BackButtonContainer,
  BackButton,
  BlankContainer
} from './styles';
import { formatJourneysAssignments } from './utils';

const LoadingScreen = () => (
  <BlankContainer>
    <CircularProgress />
  </BlankContainer>
);

const ErrorScreen = () => {
  return (
    <BlankContainer>
      <img src={emptyState} alt="empty state" />
      Oops, something went wrong
    </BlankContainer>
  );
};

const formatJourney = journey => {
  const { id, workflowTasks, ...rest } = journey;
  const modifiedWorkflowTasks = workflowTasks.map(workflowTask => {
    const { task, id, workflow, ...rest } = workflowTask;
    const { id: taskId, ...restTask } = task;
    return { ...rest, task: restTask, modifyMethod: 'create' };
  });
  const journeyData = {
    ...rest,
    workflowTasks: modifiedWorkflowTasks,
    id: null
  };
  return formatJourneysAssignments(journeyData);
};

const JourneysDuplicatePage = () => {
  const { id } = useParams();
  const history = useHistory();
  const [pageState, setPageState] = useState({
    journeyDTO: null,
    loading: true,
    error: false
  });
  const { journeyDTO, error, loading } = pageState;

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await JourneyService.find(id);
        const formattedJourney = formatJourney(response.data);
        setPageState({
          journeyDTO: formattedJourney,
          loading: false,
          error: false
        });
      } catch (error) {
        console.log(error);
        setPageState({ journeyDTO: null, loading: false, error: true });
      }
    };
    fetch();
  }, []);
  return (
    <PageContainer>
      {!journeyDTO && (
        <BackButtonContainer>
          <BackButton
            variant="secondary"
            size="small"
            onClick={() => history.push('/admin-journeys')}
          >
            Back
          </BackButton>
        </BackButtonContainer>
      )}
      {loading ? (
        <LoadingScreen />
      ) : error ? (
        <ErrorScreen />
      ) : (
        <JourneysForm journey={journeyDTO} />
      )}
    </PageContainer>
  );
};

export default JourneysDuplicatePage;

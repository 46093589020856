import React, { useState } from 'react';

import PortalContainer from 'components/PortalContainer';
import { CustomButton } from 'components/NewLyncworkUIKit';

import {
  Container,
  CloseButton,
  Title,
  ButtonContainer,
  TextInput
} from './styles';

const formatUserNameList = selectedUsers => {
  const usersCount = selectedUsers.length;
  const names = selectedUsers.map(
    ({ firstName, lastName }) => `${firstName} ${lastName}`
  );

  if (usersCount === 1) {
    return names[0];
  }
  if (usersCount > 5) {
    const remainingUsers = usersCount - 5;
    return names.slice(0, 5).join(', ') + ` and ${remainingUsers} more users`;
  }

  const finalName = names[usersCount - 1];
  return names.slice(0, usersCount - 1).join(', ') + ` and ${finalName}`;
};

const ModalContent = ({ closeModal, selectedUsers, handleConfirm }) => {
  const [inputValue, setInputValue] = useState('');
  const userNameList = formatUserNameList(selectedUsers);

  const handleInputChange = e => setInputValue(e.target.value);

  const disabled = inputValue !== 'DELETE';
  return (
    <Container onClick={e => e.stopPropagation()}>
      <CloseButton onClick={closeModal} />
      <Title>Delete Users?</Title>
      <p>Are you sure you want to delete {userNameList} ?</p>
      <p>All accounts associated with these users will be also deleted.</p>
      <p>
        This is <strong>PERMANENT</strong> and <strong>IRREVERSIBLE</strong>
      </p>
      <p>To confirm, please type out "DELETE" (all uppercase)</p>
      <TextInput
        width="200px"
        value={inputValue}
        onChange={handleInputChange}
      />
      <ButtonContainer>
        <CustomButton variant="secondary" size="medium" onClick={closeModal}>
          Cancel
        </CustomButton>
        <CustomButton size="medium" disabled={disabled} onClick={handleConfirm}>
          DELETE
        </CustomButton>
      </ButtonContainer>
    </Container>
  );
};

const BatchDeletionModal = ({
  isOpen,
  closeModal,
  selectedUsers,
  handleConfirm
}) => {
  return (
    <PortalContainer isOpen={isOpen} handleClickOutside={closeModal}>
      <ModalContent
        closeModal={closeModal}
        selectedUsers={selectedUsers}
        handleConfirm={handleConfirm}
      />
    </PortalContainer>
  );
};

export default BatchDeletionModal;

import styled from 'styled-components';

export const BreadcrumbsContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

export const BreadcrumbsItem = styled.div`
  font-weight: ${props => props.isLast ? 500 : 400};
  color: ${props => props.isLast ? '#4d4d4d' : '#66707f'};
  cursor: ${props => props.isLast ? 'normal' : 'pointer'};
  &:hover {
    text-decoration: ${props => props.isLast ? 'none' : 'underline'};
  }
`;

export const BreadcrumbsSeparatorContainer = styled.div`
  width: 6px;
  height: 12px;
  position: relative;
  margin: 0 15px;
  overflow: hidden;
`;

export const BreadcrumbsSeparatorInner = styled.div`
  width: 6px;
  height: 6px;
  
  position: absolute;
  left: -4px;
  top: 2px;

  border: 1px solid #66707f;
  transform: rotate(45deg);
`;
import React, { useState } from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';

import CustomSelect from 'components/NewLyncworkUIKit/CustomSelect';

import { getOptionLabel, getOptionValue } from 'utilities/dropdown';

import { Container } from './styles';

const Filter = ({ applicationCategories, category, handleCategoryChange }) => {
  const value = applicationCategories.find(({ name }) => name === category);
  return (
    <Container>
      <FilterListIcon />
      <span> Filter</span>
      <CustomSelect
        value={value}
        onChange={handleCategoryChange}
        width="200px"
        options={applicationCategories}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
      />
    </Container>
  );
};

export default Filter;

import React from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs from 'components/Breadcrumbs';
import PostList from '../PostList';
import TopicHeader from './TopicHeader';
import { TopicContainer } from './styles';

import iconRocket from 'assets/images/icon-rocket.svg';
import iconFeatures from 'assets/images/icon-features.svg';
import iconTools from 'assets/images/icon-tools.svg';
import iconFolders from 'assets/images/icon-folders.svg';

const topics = {
  'getting-started': {
    icon: iconRocket,
    title: 'Getting Started',
    description: 'Get started and learn the basics of Lyncwork.',
  },
  'features': {
    icon: iconFeatures,
    title: 'Features',
    description: 'Learn details on all the features available on Lyncwork.',
  },
  'applications': {
    icon: iconTools,
    title: 'Applications',
    description: 'Learn how to connect third party integrations with Lyncwork.',
  },
  'use-cases': {
    icon: iconFolders,
    title: 'Use Cases',
    description: 'Learn specific use case examples for different industries.',
  },
}

const Topic = () => {
  const { topicId } = useParams();

  const posts = [
    {
      title: 'General Guide to Adding Applications',
      description: 'Learn the basics on how to add a application onto Lyncwork.',
      updatedAt: '01/01/2020',
      path: '/help/post/1',
    },
    {
      title: 'G Suite Integration',
      description: 'This document explains how to set up your Google Cloud account to integrate with the LyncWork Application.',
      updatedAt: '01/01/2020',
      path: '/help/post/2',
    },
    {
      title: 'Dropbox Integration',
      description: 'Step-by-step guide on how to integrate your Dropbox account into Lyncwork.',
      updatedAt: '01/01/2020',
      path: '/help/post/3',
    },
    {
      title: 'Removing Applications',
      description: 'Remove an application.',
      updatedAt: '01/01/2020',
      path: '/help/post/4',
    },
    {
      title: 'Admin Guide to Applications',
      description: 'General guide to adding and assigning applications to users.',
      updatedAt: '01/01/2020',
      path: '/help/post/5',
    },
  ];

  return (
    <TopicContainer>
      <Breadcrumbs items={[{
          text: 'Help',
          path: '/help',
        }, {
          text: topics[topicId].title,
        }]}
      />
      <TopicHeader {...topics[topicId]} />
      <PostList items={posts} viewMore={() => {}}/>
    </TopicContainer>
  );
};

export default Topic;

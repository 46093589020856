import styled from 'styled-components';
import Card from 'components/Card';
import { colors } from 'designSystem/foundations';

const {
  neutral: { grey10, grey03, grey07 },
  feedback: { success, error }
} = colors;

export const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  min-height: 250px;
  overflow: hidden;

  grid-row: 2/4;
  grid-column: 2/3;
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 0 24px 0;
  h1 {
    margin: 0;
    ${props => props.theme.typography.header2};
    color: ${props => props.theme.colors.neutral.grey10};
    font-weight: 600;
  }

  a {
    text-decoration: none;
  }
`;

export const DocumentsContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 300px;
  width: 100%;
  .MuiCircularProgress-root {
    margin: 36px;
  }

  .empty-message {
    margin: 36px;
    ${props => props.theme.typography.body};
    font-weight: 600;
    color: ${grey10};
  }
`;

export const EmptyImage = styled.img`
  max-height: 40%;
  max-width: 40%;
  margin: auto;
  object-fit: contain;
`;

export const EmptyMessage = styled.span`
  margin: 0 auto;
  width: 80%;
  max-width: 200px;
  text-align: center;
  color: ${grey07};
`;

export const DocumentCard = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 10px;
  border-bottom: 1px solid ${grey03};
`;

export const DocumentIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const DocumentInfoSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DocumentTitle = styled.p`
  font-size: 12px;
  margin: 5px 0 5px 5px;

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const DocumentInfo = styled.p`
  font-size: 10px;
  margin: 5px 0 5px 5px;
  color: ${grey07};
`;

export const LoadingContainer = styled.div`
  align-self: center;
`;

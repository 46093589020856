import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Card from 'components/Card';
import { colors } from 'designSystem/foundations';

const {
  neutral: { grey10, grey08, grey07 },
  brand: { primary },
  feedback: { confirmation }
} = colors;

export const Container = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 24px 24px;
  grid-row: 3/4;
  grid-column: 3/4;
  ${props => props.theme.typography.control};
  color: ${grey10};
  overflow: auto;
  .MuiCircularProgress-root {
    align-self: center;
    margin: auto;
    justify-self: center;
  }
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.medium}) {
    max-height: 320px;
  }
`;

export const EmptyImage = styled.img`
  max-height: 40%;
  max-width: 40%;
  margin: auto;
  object-fit: contain;
`;

export const EmptyMessage = styled.span`
  margin: 0 auto;
  width: 80%;
  max-width: 200px;
  text-align: center;
  color: ${grey07};
`;

export const Header = styled.h2`
  position: sticky;
  flex-shrink: 0;
  z-index: 1;
  top: 0;
  padding: 24px 0 0;
  margin: 0;
  ${props => props.theme.typography.header2};
  font-weight: 600;
  background: white;
`;

export const DayLabel = styled.span`
  ${props => props.theme.typography.metadata};
  color: ${grey08};
  line-height: 1.25rem;
  font-weight: 500;
  margin: 16px 0 6px;
`;

export const TaskCardContainer = styled(Link)`
  position: relative;
  margin: 4px 0;
  box-sizing: border-box;
  padding: 0 0 0 16px;
  display: flex;
  justify-content: space-between;
  ${props => props.theme.typography.metadata};
  line-height: 1.25rem;
  color: ${grey07};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: ${grey10};
  }
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 2px;
    height: 100%;
    background: ${props => (props.$taskDue ? primary : confirmation)};
    border-radius: 1px;
  }
`;

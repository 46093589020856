import styled from 'styled-components';
import { CustomTextInput } from 'components/NewLyncworkUIKit';

const mobileBreakPoint = '900px';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 424px;
`;

export const Header = styled.h3`
  ${props => props.theme.typography.header2};
  font-weight: 600;
  margin: 0;
`;

export const Description = styled.span`
  ${props => props.theme.typography.header3};
  margin: 8px 0 40px;
`;

export const InputGroupContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const CustomInput = styled(CustomTextInput)`
  margin: 4px 0;
`;

import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import Welcome from './pages/Welcome';
import { Container, ContentContainer } from './styles';
import Header from './Header';
import PersonalInfo from './pages/PersonalInfo';
import RootPage from './pages/RootPage';
import DocumentsPage from './pages/DocumentsPage';
import EngagementPage from './pages/EngagementPage';

const AdminOnboarding = () => {
  return (
    <Container>
      <Header />
      <ContentContainer>
        <Switch>
          <Route path="/admin-onboarding" exact>
            <RootPage />
          </Route>
          <Route path="/admin-onboarding/welcome">
            <Welcome />
          </Route>
          <Route path="/admin-onboarding/personal-information">
            <PersonalInfo />
          </Route>
          <Route path="/admin-onboarding/documents">
            <DocumentsPage />
          </Route>
          <Route path="/admin-onboarding/engagement">
            <EngagementPage />
          </Route>
        </Switch>
      </ContentContainer>
    </Container>
  );
};

export default AdminOnboarding;

import React, { useEffect, useState } from 'react';
import {
  Container,
  ToggleContainer,
  ToggleP,
  PointFooter,
  Points,
  Engagement,
  ToggleSpacer,
  Arrow,
  Title,
  StyledLogo
} from './styles';

import NavItem from '../../components/NavItem';
import { adminMenuItems, userMenuItems } from '../../config';
import useAuthentication from 'hooks/useAuthentication';
import ArrowRight from './arrow-right.svg';
import Logo from 'assets/images/navIcons/Logo.svg';
import { useHistory, useLocation } from 'react-router';

// this might be ripped out later
import useEngagement from 'hooks/useEngagement';

const showDemoFeature = hostname => {
  if (hostname === 'localhost') {
    return true;
  }
  return hostname.startsWith('demo');
};

const SideNav = () => {
  const isEngagementAvailable = useEngagement();
  const hiddenPages = isEngagementAvailable
    ? []
    : ['Reward Points', 'Engagement Analytics'];
  const [userView, setUserView] = useState(true);
  const { user } = useAuthentication();
  const location = useLocation();
  const history = useHistory();
  const permissions = user.permissions;
  let permittedAdminItems = adminMenuItems.filter(x => {
    const hasPermission = permissions.includes(
      x.permission.toLocaleLowerCase()
    );
    const isHidden = hiddenPages.includes(x.title);
    return hasPermission && !isHidden;
  });

  useEffect(() => {
    const isAdminView = location.pathname.includes('admin');
    setUserView(!isAdminView);
  });
  const toggleUserView = () => {
    setUserView(true);
    history.push('/dashboard');
  };
  const toggleAdminView = () => {
    setUserView(false);
    history.push('/admin-dashboard');
  };

  const isDemo = showDemoFeature(window.location.hostname);
  return (
    <Container>
      <Title>
        <StyledLogo src={Logo} />
        Lyncwork
      </Title>
      {user.permissions?.includes('admin') ? (
        <ToggleContainer>
          <ToggleP selected={userView} onClick={() => toggleUserView(true)}>
            Employee
          </ToggleP>
          <ToggleP selected={!userView} onClick={() => toggleAdminView(false)}>
            Admin
          </ToggleP>
        </ToggleContainer>
      ) : (
        <ToggleSpacer />
      )}

      {userView
        ? userMenuItems.map((item, index) => {
            if (hiddenPages.includes(item.title)) {
              return null;
            }
            return (
              <NavItem
                key={index}
                activeIcon={item.activeIcon}
                inactiveIcon={item.inactiveIcon}
                title={item.title}
                href={item.href}
              />
            );
          })
        : permittedAdminItems.map((item, index) => (
            <NavItem
              key={index}
              activeIcon={item.activeIcon}
              inactiveIcon={item.inactiveIcon}
              title={item.title}
              href={item.href}
            />
          ))}
      {userView && isDemo && (
        <PointFooter>
          <Points>142</Points>
          <Engagement>
            Engagement Points
            {/* <Arrow src={ArrowRight} /> */}
          </Engagement>
        </PointFooter>
      )}
    </Container>
  );
};

export default SideNav;

import styled, { css } from 'styled-components';
import {
  multilineEllipsis,
  scrollBar
} from 'StyleVariables/StandardComponents';
import { IconButton } from 'components/NewLyncworkUIKit/CustomButton';

export const Container = styled.div`
  position: relative;
  padding: 48px;
  background-color: ${props => props.theme.colors.neutral.white};
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

export const Title = styled.h2`
  color: ${props => props.theme.colors.neutral.grey10};
  ${props => props.theme.typography.header2};
  font-weight: 600;
  margin: 0;
`;

export const SubheadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey07};
`;

export const ButtonContainer = styled.div`
  margin: 32px 0 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

export const ChevronButton = styled(IconButton)`
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  font-size: 24px;
  transition: color 0.1s ease-in;
  cursor: default;
  &:enabled {
    cursor: pointer;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
`;

import styled, { css } from 'styled-components';
import { scrollBar } from 'StyleVariables/StandardComponents';
export const mobileBreakpoint = '900px';

export const Container = styled.div`
  display: flex;

  position: relative;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  @media only screen and (max-width: ${mobileBreakpoint}) {
    flex-direction: column;
  }
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  overflow-y: auto;
  width: 100%;
  @media only screen and (max-width: ${mobileBreakpoint}) {
    height: calc(100vh - 105px);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  width: 100%;
  flex: 1;
  background-color: ${props => props.theme.colors.neutral.offWhite01};

  @media only screen and (max-width: ${mobileBreakpoint}) {
    height: 2000px;
    z-index: 1;
    background-color: ${props => props.theme.colors.neutral.white};
  }
  @media only screen and (min-width: ${mobileBreakpoint}) {
    overflow-y: scroll;
    height: 2000px;
    ${scrollBar}
  }
`;

const getRotation = css`
  ${props => (props.isOpen ? '180deg' : 0)};
`;
export const ProgressButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  color: ${props => props.theme.colors.neutral.grey07};
  &:focus-visible {
    outline: 2px solid #0078d4;
  }
  .MuiSvgIcon-root {
    color: inherit;
    transform: rotate(${props => (props.isOpen ? '180deg' : 0)});
    transition: all 0.3s ease-in-out;
  }
`;

export const SideBarMobileHeader = styled.div`
  display: none;
  @media only screen and (max-width: ${mobileBreakpoint}) {
    display: flex;
    justify-content: space-between;
    position: sticky;

    top: 0;
    width: 100%;
    height: 60px;
    background-color: ${props => props.theme.colors.neutral.offWhite02};
    border-bottom: 1px solid ${props => props.theme.colors.neutral.grey02};
    z-index: 30;
    box-sizing: border-box;
    padding: 0 20px;
  }
`;
export const SideBarLogo = styled.img`
  width: 150px;
`;

import styled from 'styled-components';
import Avatar from 'components/NewLyncworkUIKit/Avatar';

import Card from 'components/Card';

export const Container = styled(Card)`
  padding: 32px 0;
  display: flex;
  box-sizing: border-box;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 660px;
  padding: 0 32px;
  gap: 56px;
  border-left: 1px solid ${props => props.theme.colors.neutral.grey02};
`;

export const SectionContainer = styled.div`
  display: flex;
  gap: 56px;
`;

export const SectionDescription = styled.div`
  flex-basis: 200px;
  flex-grow: 0;
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  gap: 12px;

  h2 {
    ${props => props.theme.typography.header3};
    color: ${props => props.theme.colors.neutral.grey10};
    font-weight: 600;
    margin: 0;
  }

  p {
    margin: 0;
    ${props => props.theme.typography.body};
    color: ${props => props.theme.colors.neutral.grey07};
  }
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledAvatar = styled(Avatar)`
  width: 96px;
  height: 96px;
`;

export const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
  color: ${props => props.theme.colors.neutral.grey07};
  ${props => props.theme.typography.body};
  strong {
    color: ${props => props.theme.colors.neutral.grey10};
    ${props => props.theme.typography.control};
    font-weight: 600;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin: 32px 0 0;
`;

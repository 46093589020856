import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { CMSService } from 'services/cms.service';
import LoadingScreen from 'components/LoadingScreen';
import NotFound404Page from 'pages/NotFound404Page';
import PostParser from '../PostParser';
import { PostContainer, Category, EditContainer, H2, P } from './styles';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectUser } from 'redux/authentication/authentication.selector';
import CreateIcon from '@material-ui/icons/Create';
import { CustomButton } from 'components/NewLyncworkUIKit';
import PostHeader from './PostHeader';
import Comments from './Comments';

const Post = ({ user }) => {
  let { id } = useParams();
  const [postData, setPostData] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchPost = async () => {
      try {
        const { data } = await CMSService.getPostById(id);
        let newContent = data.content;
        const content = data.content;
        data.medias.forEach(media => {
          const isIncluded = content.includes(`lw-img-id:${media.id}`);
          // console.log('content includes:', media.id, isIncluded, newContent);
          //replace all uploaded image src's with the correct url
          newContent = newContent.replace(`lw-img-id:${media.id}`, media.url);
        });
        setPostData({ ...data, content: newContent });
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    fetchPost();
  }, []);
  if (loading) {
    return <LoadingScreen />;
  }
  if (error) {
    return <NotFound404Page />;
  }
  const { content } = postData;
  return (
    <PostContainer className="post-container">
      <PostHeader data={postData} />
      <PostParser content={content} />
      <Comments
        allComments={postData.comments}
        initialReactionPosts={postData.reaction_posts}
        commentsAllowed={postData.comments_allowed}
        reactionsAllowed={postData.reactions_allowed}
      />
    </PostContainer>
  );
};
const mapStateToProps = createStructuredSelector({
  user: selectUser
});
export default connect(mapStateToProps)(Post);

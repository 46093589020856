import React from 'react';
import { Description, RedDescription } from '../styles';

const DocumentsDescription = () => (
  <>
    <Description>
      Please go over your contract and other documents.
    </Description>
    <RedDescription>
      You must fill out and sign the required documents to proceed to the next
      step.
    </RedDescription>
  </>
);

export default DocumentsDescription;
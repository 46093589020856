import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  width: 100vw;
  position: relative;
  background-color: #f8f8f8;
  height: 100%;
`;

export const PageContainerContent = styled.div`
  top: 60px;
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 2vw 4vw;
  flex: 1;
  position: relative;
  height: calc(100vh - 60px);
  background-color: #f8f8f8;
  overflow-y: auto;
  overflow-x: hidden;

  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.small}) {
    height: calc(100% - 134px);
  }
`;

import React, { useState } from 'react';
import PortalContainer from 'components/PortalContainer';
import { IconButton as CloseButton } from 'components/NewLyncworkUIKit';

import { Container, HeaderContainer } from './styles';

import DeprovisionMenu from './DeprovisionMenu';
import ImmediateDeprovision from './ImmediateDeprovision';
import MonitoredDeprovision from './MonitoredDeprovision';

const ModalScreen = ({
  currentScreen,
  setCurrentScreen,
  deleteUser,
  monitorUser,
  userData
}) => {
  const returnToMenu = () => setCurrentScreen('menu');
  const { firstName, lastName } = userData;
  const fullName = `${firstName} ${lastName}`;

  switch (currentScreen) {
    case 'immediate':
      return (
        <ImmediateDeprovision
          deleteUser={deleteUser}
          fullName={fullName}
          returnToMenu={returnToMenu}
        />
      );
    case 'monitor':
      return (
        <MonitoredDeprovision
          userData={userData}
          returnToMenu={returnToMenu}
          monitorUser={monitorUser}
        />
      );
    default:
      return (
        <DeprovisionMenu
          setCurrentScreen={setCurrentScreen}
          fullName={fullName}
        />
      );
  }
};

const ModalContent = ({ closeModal, userData, deleteUser, monitorUser }) => {
  const [currentScreen, setCurrentScreen] = useState('menu');
  return (
    <Container onClick={e => e.stopPropagation()}>
      <HeaderContainer>
        <h1>Deprovision User</h1>
        <CloseButton onClick={closeModal} />
      </HeaderContainer>
      <ModalScreen
        currentScreen={currentScreen}
        setCurrentScreen={setCurrentScreen}
        userData={userData}
        deleteUser={deleteUser}
        monitorUser={monitorUser}
      />
    </Container>
  );
};

const DeprovisionModal = ({
  isOpen,
  closeModal,
  userData,
  deleteUser,
  monitorUser
}) => {
  return (
    <PortalContainer isOpen={isOpen} handleClickOutside={closeModal}>
      <ModalContent
        closeModal={closeModal}
        userData={userData}
        deleteUser={deleteUser}
        monitorUser={monitorUser}
      />
    </PortalContainer>
  );
};

export default DeprovisionModal;

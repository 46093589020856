export const journeyListPageEvents = {
  open_journey_action_menu: 'open journey action menu',
  edit_journey: 'edit journey',
  duplicate_journey: 'duplicate journey',
  add_journey: 'add journey',
  change_page: `admin journeys change page number`,
  change_assignment_type: `admin journeys change assignment filter`
};

export const journeySinglePageEvents = {
  change_assignment_type: 'journey change assignment type',
  change_assignment: 'journey change assignment',
  add_task: 'journey add task',
  edit_task: 'journey edit task',
  save_task: 'journey save task',
  delete_task: 'journey delete task',
  discard_task_change: 'journey discard task change',

  resize_task: 'journey resize task',
  drag_task: 'journey drag task',

  save_journey: 'journey save',
  delete_journey: 'journey delete',
  delete_journey_attempt: 'journey deletion attempt',
  return: 'return to admin/journeys',
  discard_journey_change: 'discard journey changes'
};

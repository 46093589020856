import styled from 'styled-components';
export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 56px;
  box-sizing: border-box;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.grey03};
`;

export const Cell = styled.div`
  flex: ${props => props.flex};
  box-sizing: border-box;
  margin: 0 0 0 24px;
  ${props => props.theme.typography.control};
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

Cell.defaultProps = { flex: 1 };

export const AssignmentCell = styled(Cell)`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.neutral.grey07};
`;

const colors = {
  ACTION: '#409CFF',
  EVENT: '#9440E8'
};
export const TaskCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 8px;
  &:first-child {
    margin-left: 0;
  }
  background-color: ${props => colors[props.tasktype]};
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${props => props.theme.colors.neutral.grey01};
`;

export const Button = styled.button`
  color: ${props => props.theme.colors.neutral.grey05};
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-left: auto;
  appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: transform 0.2s;
  &:focus-visible {
    outline: 2px solid #0078d4;
  }
  &:hover {
    background-color: ${props => props.theme.colors.neutral.grey01};
  }
`;

export const ActionCell = styled.div`
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 40px;
  box-sizing: border-box;
  margin: 0 24px;
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey07};
`;

export const MenuContainer = styled.div`
  box-sizing: border-box;
  position: absolute;
  right: 50px;
  width: 120px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  button {
    box-sizing: border-box;
    ${props => props.theme.typography.control};
    font-weight: 500;
    color: ${props => props.theme.colors.neutral.grey06};
    text-align: left;
    height: 28px;
    margin: 4px;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: transparent;
  }
  button:focus-visible {
    outline: 2px solid #0078d4;
  }
  button:hover {
    background-color: ${props => props.theme.colors.neutral.grey01};
  }
`;

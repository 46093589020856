export const SET_USER_INFO = '(ACCOUNT_SETUP) SET_USER_INFO';
export const SET_COMPANY_INFO = '(ACCOUNT_SETUP) SET_COMPANY_INFO';
export const SET_USER_AVATAR = '(ACCOUNT_SETUP) SET_USER_AVATAR';
export const SET_COMPANY_LOGO = '(ACCOUNT_SETUP) SET_COMPANY_LOGO';

const payloadAction = actionType => data => ({
  type: actionType,
  payload: data
});

export const setUserInfo = payloadAction(SET_USER_INFO);
export const setCompanyInfo = payloadAction(SET_COMPANY_INFO);
export const setUserAvatar = payloadAction(SET_USER_AVATAR);
export const setCompanyLogo = payloadAction(SET_COMPANY_LOGO);

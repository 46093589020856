import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CMSService } from 'services';

import {
  Container,
  Title,
  AnnouncementContainer,
  AnnouncementTitle,
  AnnouncementDate,
  RedDot
} from './styles';

const Announcement = ({ announcement }) => {
  const { title } = announcement;
  return (
    <AnnouncementContainer>
      <RedDot />
      <div>
        <AnnouncementTitle>{title}</AnnouncementTitle>
        {/* <AnnouncementDate>Mar 12, 2022</AnnouncementDate> */}
      </div>
    </AnnouncementContainer>
  );
};

const CompanyAnnouncements = () => {
  const [{ loading, announcements }, setState] = useState({
    loading: true,
    announcements: []
  });

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const { data } = await CMSService.community();
        const announcements = data.featureds?.slice(0, 5) || [];
        setState({ loading: false, announcements });
      } catch (error) {
        console.log(error);
        setState({ loading: false, announcements: [] });
      }
    };
    fetchAnnouncements();
  }, []);

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container>
      <Title>Announcements</Title>
      {announcements.map(announcement => {
        return (
          <Announcement announcement={announcement} key={announcement.id} />
        );
      })}
    </Container>
  );
};

export default CompanyAnnouncements;

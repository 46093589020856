import React from 'react';
import {
  HeaderRowContainer,
  HeaderLabel,
  JourneyName,
  JourneyProgressLabel,
  JourneyDue
} from './styles';

const JourneyTableHeader = () => {
  return (
    <HeaderRowContainer>
      <JourneyName>Name</JourneyName>
      <JourneyProgressLabel>Progress</JourneyProgressLabel>
      <JourneyDue>Due</JourneyDue>
    </HeaderRowContainer>
  );
};

export default JourneyTableHeader;

import { useState, useCallback } from 'react';

import debounce from 'utilities/debounce';
import { JourneyService } from 'services/journeys.service';

const size = 5;
const teams = '';

const initialFetchState = {
  name: '',
  page: 0,
  hasMore: true,
  loading: false,
  journeys: []
};

const useJourneys = roleId => {
  const [fetchState, setFetchState] = useState(initialFetchState);
  const [searchQuery, setSearchQuery] = useState('');
  const { name, page, journeys, hasMore, loading } = fetchState;

  const updateSearchQuery = useCallback(
    debounce(
      value =>
        setFetchState({
          name: value,
          page: 0,
          hasMore: true,
          loading: false,
          journeys: []
        }),
      500
    ),
    []
  );

  const loadMore = async () => {
    if (loading) {
      return;
    }
    setFetchState(oldState => ({ ...oldState, loading: true }));

    try {
      const response = await JourneyService.list({
        name,
        page,
        size,
        teams,
        roles: roleId
      });
      const { data } = response;
      const { content: journeys, last } = data;
      setFetchState(oldState => {
        const { journeys: oldJourneys } = oldState;
        return {
          name,
          page: page + 1,
          hasMore: !last,
          loading: false,
          journeys: [...oldJourneys, ...journeys]
        };
      });
    } catch (error) {
      setFetchState(oldState => ({ ...oldState, loading: false }));
      console.log(error);
    }
  };

  const handleInputChange = e => {
    const value = e.target.value;
    setSearchQuery(value);
    updateSearchQuery(value);
  };
  return {
    searchQuery,
    name,
    page,
    journeys,
    hasMore,
    loadMore,
    loading,
    handleInputChange
  };
};

export default useJourneys;

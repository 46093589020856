import styled from 'styled-components';

import { colors } from 'designSystem/foundations';
import { scrollBar } from 'StyleVariables/StandardComponents';

const {
  feedback: { confirmation },
  neutral: { offWhite01, offWhite02, grey02, white }
} = colors;

export const TableContainer = styled.div`
  width: 100%;
  min-height: 600px;
  overflow-x: scroll;
  ${scrollBar}
  &::-webkit-scrollbar {
    width: 0;
    height: 6px;
  }
  display: flex;
  flex-direction: column;
`;

const selectedColor = `${confirmation}30`;

export const HeaderRow = styled.div`
  height: 54px;
  display: flex;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${offWhite02};
  width: min-content;
`;

export const Row = styled(HeaderRow)`
  border-radius: 0;
  background-color: ${props =>
    props.selected ? selectedColor : 'transparent'};
  background-clip: content-box;
  border-bottom: 1px solid ${grey02};
  border-color: ${props => (props.selected ? 'transparent' : grey02)};
  &:hover {
    background-color: ${props => (props.selected ? selectedColor : offWhite01)};
  }
`;

export const Cell = styled.div`
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  width: ${props => (props.size === 'small' ? '160px' : '300px')};
  height: 100%;
  padding: 12px 24px;
  gap: 4px;
  ${props => props.theme.typography.control};
  &.checkbox {
    width: 54px;
  }

  .lw-select {
    width: 100%;
  }

  .hover-info {
    color: ${props => props.theme.colors.neutral.grey07};
  }
`;

export const HeaderCell = styled(Cell)`
  background: ${offWhite01};
`;

export const CellData = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

import React from 'react';

import { CustomTextInput, CustomTextArea } from 'components/NewLyncworkUIKit';
import { Card, SectionTitle } from './styles';

const JourneyGeneralInfo = ({ journeyInfoProps }) => {
  const { workflowName, workflowDesc, inputErrors, updateText } =
    journeyInfoProps;
  const { workflowName: nameError, workflowDesc: descriptionError } =
    inputErrors;
  return (
    <Card>
      <SectionTitle>Journey Info</SectionTitle>
      <CustomTextInput
        required
        value={workflowName}
        name="workflowName"
        label="Name"
        placeholder="Enter name"
        error={!!nameError}
        subLabel={nameError}
        onChange={updateText}
      />
      <CustomTextArea
        required
        value={workflowDesc}
        name="workflowDesc"
        label="Description"
        placeholder="Enter journey description"
        rows={4}
        error={!!descriptionError}
        subLabel={descriptionError}
        onChange={updateText}
      />
    </Card>
  );
};

export default JourneyGeneralInfo;

import React, { useState, useEffect } from 'react';

import { getOptionValue } from 'utilities/dropdown';

import useAha from 'hooks/useAha';
import { CustomSelect, CustomButton } from 'components/NewLyncworkUIKit';
import { useRoleForm } from 'pages/Roles/components/RoleForm/RoleFormContext';

import {
  initRoleSelection,
  formatRoleSelection,
  closeMenuOnScroll,
  getOptionLabel
} from './utils';
import { ContentContainer, ProductCard, ButtonContainer } from './styles';

const AhaProductCard = ({ ahaProduct, ahaRoles, roleSelection, onChange }) => {
  const { id: productId, name } = ahaProduct;
  const selectedRole = roleSelection[productId];

  const selectId = `role-${productId}`;

  return (
    <ProductCard>
      <span>{name}</span>
      <CustomSelect
        className="role-select"
        menuPosition="fixed"
        value={selectedRole}
        onChange={(...a) => console.log(a)}
        isClearable={false}
        closeMenuOnScroll={closeMenuOnScroll}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        options={ahaRoles}
        onChange={onChange}
      />
    </ProductCard>
  );
};

const AhaRoleSelection = ({ application, ahaProducts, closeModal }) => {
  const { applicationCapabilities: ahaRoles } = application;
  const [roleSelection, setRoleSelection] = useState(
    initRoleSelection(ahaProducts, ahaRoles)
  );
  const { addAha } = useRoleForm();
  const handleSave = () => {
    const subscriptionAppCapabilityProperties = formatRoleSelection(
      roleSelection
    );
    addAha(application, subscriptionAppCapabilityProperties);
    closeModal();
  };

  return (
    <>
      <ContentContainer>
        <h2>Select Roles</h2>
        {ahaProducts.map(ahaProduct => {
          const { id: productId } = ahaProduct;
          const onChange = role =>
            setRoleSelection(oldSelection => ({
              ...oldSelection,
              [productId]: role
            }));
          return (
            <AhaProductCard
              key={productId}
              ahaProduct={ahaProduct}
              ahaRoles={ahaRoles}
              roleSelection={roleSelection}
              onChange={onChange}
            />
          );
        })}
      </ContentContainer>
      <ButtonContainer>
        <CustomButton size="medium" onClick={handleSave}>
          Add Application
        </CustomButton>
      </ButtonContainer>
    </>
  );
};

const AhaDetail = ({ application, closeModal }) => {
  const { products: ahaProducts } = useAha();

  if (ahaProducts.length < 1) {
    return null;
  }
  return (
    <AhaRoleSelection
      application={application}
      ahaProducts={ahaProducts}
      closeModal={closeModal}
    />
  );
};

export default AhaDetail;

import React, { useState } from 'react';
import {
  ChatBubbleOutline,
  ThumbUpAlt,
  ThumbDownAltOutlined,
  ThumbUpAltOutlined
} from '@material-ui/icons';
import { CustomButton } from 'components/NewLyncworkUIKit';
import { SingleComment, AddComment } from './CommentCard';
import useComments from './useComments';
import {
  Container,
  IconContainer,
  CommentListContainer,
  TextButton,
  LikeText,
  Button,
  TotalCommentCount,
  CommentOffText
} from './styles';

const getLikeText = reactionCount => {
  switch (reactionCount) {
    case 0:
      return ' ';
    case 1:
      return '1 Like';
    default:
      return `${reactionCount} likes`;
  }
};
const getCommentText = totalComments => {
  switch (totalComments) {
    case 0:
      return ' ';
    case 1:
      return '1 Comment';
    default:
      return `${totalComments} Comments`;
  }
};

const ToolBar = ({
  reactionsAllowed,
  commentsAllowed,
  toggleReaction,
  hasReacted,
  toggleCommentSection,
  commentText
}) => {
  return (
    <>
      {reactionsAllowed && (
        <Button onClick={toggleReaction} hasReacted={hasReacted}>
          {hasReacted ? <ThumbUpAlt /> : <ThumbUpAltOutlined />} Like
        </Button>
      )}
      {commentsAllowed && (
        <>
          <Button onClick={toggleCommentSection}>
            <ChatBubbleOutline /> Comment
          </Button>
          <TotalCommentCount>{commentText}</TotalCommentCount>
        </>
      )}
    </>
  );
};

const CommentWrapper = ({ commentsAllowed, children }) => {
  if (!commentsAllowed) {
    return <CommentOffText>Comments are turned off.</CommentOffText>;
  }
  return children;
};

const Comments = ({
  allComments,
  initialReactionPosts,
  commentsAllowed,
  reactionsAllowed
}) => {
  const {
    totalComments,
    visibleComments,
    showMoreComments,
    hasMoreComments,
    toggleCommentSection,
    showComments,
    newComment,
    loading,
    handleChange,
    handleKeyPress,
    addCommentReaction,
    deleteCommentReaction,
    hasReacted,
    toggleReaction,
    reactionCount,
    authors,
    deleteComment
  } = useComments(allComments, initialReactionPosts);
  const likeText = getLikeText(reactionCount);
  const commentText = getCommentText(totalComments);
  return (
    <Container>
      <LikeText>{likeText} </LikeText>
      <IconContainer>
        <ToolBar
          reactionsAllowed={reactionsAllowed}
          commentsAllowed={commentsAllowed}
          toggleReaction={toggleReaction}
          hasReacted={hasReacted}
          toggleCommentSection={toggleCommentSection}
          commentText={commentText}
        />
      </IconContainer>
      <CommentWrapper commentsAllowed={commentsAllowed}>
        {showComments && (
          <CommentListContainer>
            {hasMoreComments && (
              <div>
                <TextButton onClick={showMoreComments}>
                  Show More Comments
                </TextButton>
              </div>
            )}
            {visibleComments.map(comment => {
              const { id } = comment;
              return (
                <SingleComment
                  key={id}
                  deleteComment={deleteComment}
                  authors={authors}
                  commentData={comment}
                  addCommentReaction={addCommentReaction}
                  deleteCommentReaction={deleteCommentReaction}
                />
              );
            })}
            <AddComment
              newComment={newComment}
              handleChange={handleChange}
              handleKeyPress={handleKeyPress}
              loading={loading}
            />
          </CommentListContainer>
        )}
      </CommentWrapper>
    </Container>
  );
};

export default Comments;

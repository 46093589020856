import React from 'react';
import { useLocation } from 'react-router';
import { Container, StyledLink, NavTitle } from './styles';

import navMenuItems from './navMenuItems';

const NavLink = ({ navItem }) => {
  const { pathname } = useLocation();
  const { to, title, icon } = navItem;
  const isActive = pathname.startsWith(to);
  return (
    <StyledLink to={to} $isActive={isActive} data-tutorial-id={to}>
      {icon}
      <NavTitle>{title}</NavTitle>
    </StyledLink>
  );
};

const MobileNav = () => {
  return (
    <Container data-tutorial-id="navigation">
      {navMenuItems.map(navItem => (
        <NavLink navItem={navItem} key={navItem.to} />
      ))}
    </Container>
  );
};

export default MobileNav;

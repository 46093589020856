import React, { useState, useEffect } from 'react';

import { CompanyService } from 'services';
import useAuthentication from './useAuthentication';

const blankCompanyInfo = {
  id: null,
  companyName: '',
  phonePrimary: '',
  website: '',
  instagramUrl: '',
  linkedinUrl: '',
  mediumUrl: '',
  twitterUrl: '',
  addresses: []
};

const initialState = {
  loading: true,
  error: false,
  companyInfo: blankCompanyInfo
};
const useCompany = () => {
  const { user } = useAuthentication();
  const [{ loading, error, companyInfo }, setState] = useState(initialState);

  const updateCompany = async info => {
    const response = await CompanyService.update(info);
    setState({ companyInfo: response.data, loading: false, error: false });
  };

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      const companyId = user?.company?.id;
      if (!companyId) {
        setState({
          loading: false,
          error: true,
          companyInfo: blankCompanyInfo
        });
      }
      try {
        const response = await CompanyService.getCompany(companyId);
        setState({
          loading: false,
          error: false,
          companyInfo: response.data
        });
      } catch (error) {
        console.log(error);
        setState({
          loading: false,
          error: true,
          companyInfo: blankCompanyInfo
        });
      }
    };
    fetchCompanyInfo();
  }, []);

  return { loading, error, companyInfo, updateCompany };
};

export default useCompany;

import { format, isValid } from 'date-fns';

export const formatEmailPrimary = emailPrimary => {
  if (!emailPrimary) {
    return emailPrimary;
  }
  return emailPrimary.split('@')[0];
};

export const convertDateToString = date => {
  if (isValid(date)) {
    return format(date, 'MM/dd/yyyy');
  }
  return '';
};

export const convertToUTC = date => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const dayOfMonth = date.getDate();
  const UTCDateInt = Date.UTC(year, month, dayOfMonth);
  const UTCDate = new Date(UTCDateInt);
  return UTCDate;
};

// takes a date e.g. 2021-May-15T00:00 UTC and converts it to 2021-May-15T00:00 local time
export const convertFromUTC = date => {
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth();
  const dayOfMonth = date.getUTCDate();
  return new Date(year, month, dayOfMonth);
};

export const getUserListDifference = (oldList, newList) => {
  const oldIds = oldList.map(user => user.id);
  const newIds = newList.map(user => user.id);
  const inserted = newList.filter(user => !oldIds.includes(user.id));
  const deleted = oldList.filter(user => !newIds.includes(user.id));
  return { inserted, deleted };
};

const formatToDropdown = (list, key) => {
  return list.map(item => ({ ...item, name: item[key] }));
};

const formatUser = user => {
  if (!user) {
    return null;
  }
  const { firstName, lastName } = user;
  const name = `${firstName} ${lastName}`;
  return { ...user, name };
};

export const initializeUserInfo = newUserInfo => {
  const { emailPrimary, provisionUserDate } = newUserInfo;
  const formattedEmailPrimary = formatEmailPrimary(emailPrimary);
  const provisionDate = provisionUserDate
    ? convertFromUTC(new Date(provisionUserDate))
    : null;
  const jobTitle = newUserInfo.jobTitle;
  const teams = formatToDropdown(newUserInfo.teams, 'teamName');
  const roles = formatToDropdown(newUserInfo.roles, 'roleName');
  const mentor = formatUser(newUserInfo.mentor);
  const manager = formatUser(newUserInfo.manager);
  const itPrime = formatUser(newUserInfo.itPrime);
  const hrPrime = formatUser(newUserInfo.hrPrime);
  const directReports = newUserInfo.directReports.map(formatUser);
  const mentee = newUserInfo.mentee.map(formatUser);
  return {
    ...newUserInfo,
    emailPrimary: formattedEmailPrimary,
    provisionUserDate: provisionDate,
    jobTitle,
    teams,
    roles,
    mentor,
    manager,
    itPrime,
    hrPrime,
    directReports,
    mentee
  };
};

import React from 'react';
import { useLocation } from 'react-router-dom';

import UnderlineLink from 'components/UnderlineLink';

import { Container } from './styles';

const tabs = [
  { tabPath: 'work-details', name: 'Work Details' },
  { tabPath: 'personal-info', name: 'Personal Information' },
  { tabPath: 'documents', name: 'Documents' },
  { tabPath: 'application-status', name: 'Application Status' },
  { tabPath: 'skills', name: 'Skills' }
];

const NavBar = ({ id }) => {
  const { pathname } = useLocation();
  const basePath = `/admin-users/edit/${id}`;
  const workDetailsPath = `basePath/work-details`;
  const personalInfo = `basePath/personal-info`;
  return (
    <Container>
      {tabs.map(({ tabPath, name }) => {
        const path = `${basePath}/${tabPath}`;
        const selected = pathname.includes(tabPath);
        return (
          <UnderlineLink to={path} key={name} selected={selected}>
            {name}
          </UnderlineLink>
        );
      })}
    </Container>
  );
};

export default NavBar;

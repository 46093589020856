import React from 'react';
import engagementRibbon from 'assets/images/engagement-ribbon.svg';

import {
  Container,
  CurrentPointsCardContainer,
  CurrentPointsContainer,
  Title,
  Points,
  TotalEarned,
  Ribbon
} from './styles';

const fakePoints = 1234;
const CurrentPointsCard = ({ points = fakePoints }) => {
  const formattedPoints = points.toLocaleString('en-US');
  return (
    <Container>
      <CurrentPointsCardContainer>
        <Ribbon src={engagementRibbon} alt="ribbon" />
        <CurrentPointsContainer>
          <Title>Current Points</Title>
          <Points color="#FF6B6B">{formattedPoints}</Points>
          <TotalEarned>
            Total Earned: <span>{formattedPoints}</span>
          </TotalEarned>
        </CurrentPointsContainer>
      </CurrentPointsCardContainer>
    </Container>
  );
};

export default CurrentPointsCard;

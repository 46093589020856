import styled from 'styled-components';
import { offGrey, grey } from 'StyleVariables/LyncworkColors';
// changed margin to auto and width 80% for post container
export const PostContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  width: 80%;
  margin: 0 auto;
  height: fit-content;
  box-shadow: 1px 1px 4px rgba(137, 152, 170, 0.315982);
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: -10px 9px 21px #f4f5f7;
  border-radius: 4px;
  padding: 32px 50px;
  border: 1px solid transparent;
`;
export const Category = styled.div`
  padding: 0.35em;
  margin-left: 32px;
  margin-bottom: 16px;
  z-index: 2;
  color: white;
  border-radius: 4px;
  width: fit-content;
  background-color: ${props =>
    props.colorOptions[props.id % props.colorOptions.length]};
`;
export const EditContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 32px;
  right: 32px;
  cursor: pointer;

  border-radius: 16px;
  transition: all 0.3s;
  &:hover {
    background-color: ${grey};
  }
`;
export const H2 = styled.h2`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 53px;
  margin: 0;
  color: #000000;
  padding: 0 32px;
`;
export const P = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */
  margin: 0;
  padding: 0 32px;
  letter-spacing: 0.777778px;
  margin-bottom: 2em;
  color: #000000;
`;

import React from 'react';

import { Row, Cell } from 'components/ListTable';
import { Avatar, CustomButton } from 'components/NewLyncworkUIKit';
import HiddenCounter from 'components/HiddenCounter';

import formatDate from 'utilities/formatDate';
import generateMediaUrl from 'utilities/generateMediaUrl';

import { AppName, ServiceAvatar, StyledLink } from './styles';
import { AppStatusCircle } from './styles';

const ServiceColumn = ({ applicationCapabilities }) => {
  const firstFiveServices = applicationCapabilities.slice(0, 5);
  const remainingServices = applicationCapabilities.length - 5;
  return (
    <>
      {firstFiveServices.map(service => {
        const { logo, capability: name, id } = service;
        const src = generateMediaUrl(logo);
        return (
          <ServiceAvatar
            key={id}
            colorKey={id}
            src={src}
            name={name}
            size="24px"
          />
        );
      })}
      {remainingServices > 0 && (
        <HiddenCounter>+{remainingServices}</HiddenCounter>
      )}
    </>
  );
};

const AppStatus = ({ subscriptionStatus }) => {
  switch (subscriptionStatus) {
    case 'ACTIVE':
      return 'Active';
    case 'DELETION_IN_PROGRESS':
      return 'Deletion in Progress';
    default:
      return 'No Status';
  }
};

const SubscriptionListCard = ({ subscriptionApp }) => {
  const {
    id,
    application: { appName, applicationCapabilities, appLogo, id: appId },
    sysUpdateDate,
    status
  } = subscriptionApp;
  const dateUpdated = formatDate(sysUpdateDate);
  const subscriptionAppLink = `/admin-applications/my-apps/${id}`;
  return (
    <Row>
      <Cell flex={1}>
        <Avatar
          name={appName}
          objectFit={'contain'}
          src={generateMediaUrl(appLogo)}
          colorKey={appId}
          size="24px"
        />
        <AppName className="list-row-name">{appName}</AppName>
      </Cell>
      <Cell flex={2}>
        <ServiceColumn applicationCapabilities={applicationCapabilities} />
      </Cell>
      <Cell flex={1}>
        <AppStatusCircle subscriptionStatus={status} />
        <AppStatus subscriptionStatus={status} />
      </Cell>
      <Cell flex={1}>{dateUpdated}</Cell>
      <Cell flex={1}>
        <StyledLink to={subscriptionAppLink}>
          <CustomButton variant="secondary" size="small">
            Edit
          </CustomButton>
        </StyledLink>
      </Cell>
    </Row>
  );
};

export default SubscriptionListCard;

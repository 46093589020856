import React from 'react';
import { Container } from './styles';
import PlaceholderImg from './Engagement (1).svg';

const EngagementAnalytics = () => {
  return (
    <Container>
      <img src={PlaceholderImg} alt="placeholder" />
    </Container>
  );
};

export default EngagementAnalytics;

import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import TextInput from 'components/NewLyncworkUIKit/CustomTextInput';
import Button from 'components/NewLyncworkUIKit/CustomButton';

import { Form, FieldContainer, ButtonContainer } from './styles';

const primaryEmailProvider = 'primary_email_provider';

const getFormData = applicationProperties => {
  const formData = {};
  applicationProperties.forEach(({ name }) => {
    if (name === primaryEmailProvider) {
      formData[name] = true;
      return;
    }
    formData[name] = '';
  });
  return formData;
};
const getFormError = applicationProperties => {
  const formError = {};
  applicationProperties.forEach(({ name }) => {
    if (name === primaryEmailProvider) {
      formError[name] = false;
      return;
    }
    formError[name] = '';
  });
  return formError;
};

const validate = ({ applicationProperties, formData, formError }) => {
  const newFormError = {};
  const missingFields = applicationProperties.filter(capability => {
    const { name, label } = capability;
    const errorMessage = `${label} is required`;
    if (formData[name]) {
      return false;
    }
    newFormError[name] = errorMessage;
    return true;
  });
  return { newFormError, hasMissingFields: missingFields.length > 0 };
};

const AppForm = ({ app, cancelSelection, integrateApplication }) => {
  const { applicationProperties } = app;
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(getFormData(applicationProperties));
  const [formError, setFormError] = useState(
    getFormError(applicationProperties)
  );
  const handleFieldChange = e => {
    const { name, value } = e.target;
    setFormData(oldForm => ({ ...oldForm, [name]: value }));
    setFormError(oldForm => ({ ...oldForm, [name]: '' }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const { newFormError, hasMissingFields } = validate({
      applicationProperties,
      formData,
      formError
    });
    setFormError(newFormError);
    if (hasMissingFields) {
      return;
    }
    try {
      setLoading(true);
      const properties = applicationProperties.map(property => {
        const { name, label, application } = property;
        const key = name;
        const value = formData[name];
        return { name, label, application, key, value };
      });
      const requestData = { application: app, properties };
      await integrateApplication(requestData);
      console.log(requestData);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FieldContainer>
        <h2>Application Integration</h2>
        {applicationProperties.map(capability => {
          const { name, label } = capability;
          if (name === primaryEmailProvider) {
            return null;
          }
          const value = formData[name];
          const errorMessage = formError[name];
          return (
            <TextInput
              key={name}
              label={label}
              name={name}
              required
              onChange={handleFieldChange}
              value={value}
              error={!!errorMessage}
              subLabel={errorMessage}
            />
          );
        })}
      </FieldContainer>
      <ButtonContainer>
        <Button variant="secondary" width="140px" onClick={cancelSelection}>
          Cancel
        </Button>
        <Button width="140px" type="submit">
          {!loading ? (
            'Verify'
          ) : (
            <CircularProgress color="inherit" size="24px" />
          )}
        </Button>
      </ButtonContainer>
    </Form>
  );
};

export default AppForm;

import React from 'react';

import Button from 'components/NewLyncworkUIKit/CustomButton';
import { AvatarUploadContainer, StyledAvatar } from './styles';

const AvatarUpload = ({ src, name, colorKey, changeAvatar }) => {
  return (
    <AvatarUploadContainer>
      <StyledAvatar
        objectFit="contain"
        name={name}
        colorKey={colorKey}
        src={src}
      />
      <Button size="medium" variant="secondary">
        <input type="file" tabIndex="-1" onChange={changeAvatar} />
        Upload Company Logo
      </Button>
      <p>You can also upload later in the company info page</p>
    </AvatarUploadContainer>
  );
};

export default AvatarUpload;

import axios from 'axios';
import env from '../env';

const baseUrl = `${env.frontDoorDomain}/client/companies`;
const baseUrl2 = `${env.storeDomain}/api/companies`;

export const CompanyService = {
  provisionCompany: data => {
    return axios.post(`${baseUrl}/provision`, data);
  },
  companyByToken: token => {
    return axios.get(`${baseUrl2}/${token}`);
  },
  getCompany: id => {
    return axios.get(`${baseUrl2}/${id}`);
  },

  update: company => {
    return axios.put(`${baseUrl2}`, company);
  }
};

import React from 'react';

import HoverInfo from 'components/HoverInfo';
import CustomToggle from 'components/NewLyncworkUIKit/CustomToggle';
import {
  Container,
  DetailContainer,
  HeaderContainer,
  LicenseName
} from './styles';

const LicenceDetail = ({ selectedLicence, toggleLicense, licenceInfo }) => {
  if (!selectedLicence) {
    return null;
  }
  const {
    applicationLicenseInformation: { licenseName, licenseId },
    defaultLicense
  } = selectedLicence;
  const licenceDetail = licenceInfo[licenseId];

  if (!licenceDetail) {
    return null;
  }

  const { roles, accountLicensesUsed } = licenceDetail;

  const name = licenseName.toLowerCase();
  const roleNames = roles.map(({ roleName }) => roleName).join(', ') || 'None';

  const onToggleChange = e => {
    const { checked } = e.target;
    toggleLicense(licenseId, checked);
  };

  return (
    <Container>
      <HeaderContainer>
        <LicenseName>{name}</LicenseName>
        <CustomToggle
          label="Set as default"
          bold
          checked={defaultLicense}
          onChange={onToggleChange}
        />
        <HoverInfo title="What should I put here?" />
      </HeaderContainer>
      <DetailContainer>
        <span>
          Licences Used: <strong>{accountLicensesUsed}</strong>
        </span>
        <div className="roles">
          <span>Assigned Roles: {roleNames}</span>
          <HoverInfo title="Licences for individual roles can be assigned from the Roles page" />
        </div>
      </DetailContainer>
    </Container>
  );
};

export default LicenceDetail;

import styled, { css } from 'styled-components';
import Button from 'components/NewLyncworkUIKit/CustomButton';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  max-width: 1600px;
`;

export const ContentContainer = styled.div`
  margin: 80px 0 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.h3`
  ${props => props.theme.typography.title3};
  font-weight: 600;
  margin: 0;
`;

export const Tagline = styled.h3`
  ${props => props.theme.typography.header3};
  margin: 4px 0;
  max-width: 50%;
  text-align: center;
`;

export const ScoreContainer = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ScoreNumber = styled.span`
  font-weight: 600;
  font-size: 4.75rem;
  line-height: 5.25rem;
  color: ${props => props.theme.colors.brand.primary};
`;

export const ScoreText = styled.span`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;
  color: ${props => props.theme.colors.brand.primary};
`;

export const ButtonContainer = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CustomButton = styled(Button)`
  width: 160px;
  margin: 0 8px;
`;

export const NonEngagementText = styled.span`
  ${props => props.theme.typography.title1};
  font-weight: 600;
  margin-bottom: 20px;
  color: ${props => props.theme.colors.brand.primary};
`;

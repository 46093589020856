import React from 'react';

import {
  Container,
  Column,
  Description,
  AppContainer,
  StyledAvatar
} from './styles';

import generateMediaUrl from 'utilities/generateMediaUrl';

const App = ({ application }) => {
  const { appName, appLogo, id } = application;
  const src = generateMediaUrl(appLogo);

  return (
    <AppContainer>
      <StyledAvatar src={src} name={appName} colorKey={id} />
      <span>{appName}</span>
    </AppContainer>
  );
};

const AppsAffected = ({ applications }) => {
  const monitoredApps = applications.filter(app => app.appMonitorEnabled);
  const deletedApps = applications.filter(app => !app.appMonitorEnabled);
  return (
    <Container>
      <Column>
        <Description>
          {monitoredApps.length} accounts will be set to a monitor state:
        </Description>
        {monitoredApps.map(application => (
          <App key={application.id} application={application} />
        ))}
      </Column>
      <Column>
        <Description>
          {deletedApps.length} accounts will be immediately deleted:
        </Description>
        {deletedApps.map(application => (
          <App key={application.id} application={application} />
        ))}
      </Column>
    </Container>
  );
};

export default AppsAffected;

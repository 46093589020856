import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import CustomButton from 'components/NewLyncworkUIKit/CustomButton';
import PageTransitionBlock from 'components/PageTransitionBlock';
import DeleteButton from 'components/DeleteButton';
import PortalContainer from 'components/PortalContainer';

import { ProvisionData, Assignments, KeyContacts } from './FormSections';
import SavePreflight from './SavePreflight';
import useWorkDetails from './useWorkDetails';
import { FormContainer, ButtonContainer } from './styles';

const isUserMonitored = userData => {
  if (!userData) {
    return false;
  }
  const {
    lyncWorkUserStatus: { status }
  } = userData;
  return status === 'MONITORED';
};

const WorkDetailForm = ({
  userData,
  domain,
  handleSubmit,
  handleDelete,
  canDelete,
  deletingUser
}) => {
  const {
    workDetails,
    handleFieldChange,
    handleSave,
    blockTransition,
    formErrors,
    loading,
    handleCancel,
    applicationChanges,
    isSavePreflightOpen,
    closeSavePreflight,
    confirmSave
  } = useWorkDetails(userData, handleSubmit);
  const usersToIgnore = userData ? [userData] : [];
  const isMonitoring = isUserMonitored(userData);
  return (
    <>
      <PageTransitionBlock when={blockTransition && !deletingUser} />
      <PortalContainer isOpen={isSavePreflightOpen}>
        <SavePreflight
          closeSavePreflight={closeSavePreflight}
          applicationChanges={applicationChanges}
          confirmSave={confirmSave}
        />
      </PortalContainer>
      <FormContainer>
        <ProvisionData
          disabled={isMonitoring}
          domain={domain}
          workDetails={workDetails}
          handleFieldChange={handleFieldChange}
          formErrors={formErrors}
        />
        <Assignments
          disabled={isMonitoring}
          workDetails={workDetails}
          handleFieldChange={handleFieldChange}
        />
        <KeyContacts
          disabled={isMonitoring}
          workDetails={workDetails}
          handleFieldChange={handleFieldChange}
          usersToIgnore={usersToIgnore}
        />
      </FormContainer>
      {!isMonitoring && (
        <ButtonContainer>
          {canDelete && (
            <DeleteButton
              className="delete-button"
              onClick={handleDelete}
              loading={deletingUser}
            />
          )}
          <CustomButton variant="secondary" onClick={handleCancel}>
            Cancel
          </CustomButton>
          <CustomButton onClick={handleSave}>
            {loading ? (
              <CircularProgress color="inherit" size="24px" />
            ) : (
              'Save'
            )}
          </CustomButton>
        </ButtonContainer>
      )}
    </>
  );
};

export default WorkDetailForm;

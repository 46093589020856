import React from 'react';
import { CustomButton } from 'components/NewLyncworkUIKit';
import {
  Container,
  JourneyTitle,
  Description,
  Row,
  MetaData,
  ProgressBar
} from './styles';
import { formatDate } from './utilities';

const JourneyListCard = ({ journey }) => {
  const {
    workflowDesc,
    workflowName,
    taskWork: { lastDueDate, completedTasks, total }
  } = journey;
  const dueDate = formatDate(lastDueDate);
  const barWidth = `${Math.floor((completedTasks * 100) / total)}%`;
  return (
    <Container data-tutorial-id="single-journey-desc">
      <JourneyTitle isJourney>{workflowName}</JourneyTitle>
      <Description isJourney>{workflowDesc}</Description>
      <Row>
        <MetaData>Task Completed</MetaData>
        <MetaData>
          {completedTasks} of {total}
        </MetaData>
      </Row>
      <ProgressBar barWidth={barWidth} />
      <MetaData className="bold">Due On {dueDate}</MetaData>
    </Container>
  );
};

export default JourneyListCard;

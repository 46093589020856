import React, { useState } from 'react';

import CustomButton from 'components/NewLyncworkUIKit/CustomButton';
import LoadingButton from 'components/LoadingButton';

import { UserImportService } from 'services/userImport.service';
import { useButtonTrack } from 'matomo/hooks';

import { userAppImportPageEvents } from 'pages/Users/UsersListPage/instrumentationEvents';

import useDialogue from 'hooks/useDialogue';

import importImage from '../../assets/importImage.svg';
import { getAppUsers } from '../utils';

import {
  Container,
  Title,
  ImportImage,
  ButtonContainer,
  AppCardContainer,
  ApplicationContainer
} from './styles';

const SelectApplication = ({
  applications,
  previousPage,
  nextPage,
  selectedApp,
  setSelectedApp,
  setData
}) => {
  const selectedAppAPIName = selectedApp.appAPIName;
  const [loading, setLoading] = useState(false);
  const trackButton = useButtonTrack();
  const { showDialogue, closeDialogue } = useDialogue();
  const handleNext = async () => {
    setLoading(true);
    showDialogue({
      title: 'Loading Users...',
      message: 'This could take a while',
      confirmButtonText: 'Okay',
      confirmButtonAction: closeDialogue
    });
    try {
      const { users, nextPageParams } = await getAppUsers(selectedAppAPIName);
      if (users.length < 1) {
        showDialogue({
          title: 'No Users Available',
          message: `No Users are available to import for this application, please choose another one`,
          confirmButtonText: 'Okay',
          confirmButtonAction: closeDialogue
        });
        setLoading(false);
        return;
      }
      setData({ users, selected: {}, nextPageParams });
      closeDialogue();
      nextPage();
    } catch (error) {
      showDialogue({
        title: 'Oops, something went wrong',
        message: 'Please try again later',
        confirmButtonText: 'Okay',
        confirmButtonAction: closeDialogue
      });
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <>
      <Container>
        <ImportImage alt=" " src={importImage} />
        <Title>Where are you uploading the users from?</Title>
        <ApplicationContainer>
          {applications.map(application => {
            const { appName, id, appAPIName, logoUrl } = application;
            const handleClick = () => {
              trackButton(userAppImportPageEvents.select_app);
              setSelectedApp(application);
            };
            return (
              <AppCardContainer
                key={id}
                onClick={handleClick}
                selected={selectedAppAPIName === appAPIName}
              >
                <img src={logoUrl} alt={appName} />
                {appName}
              </AppCardContainer>
            );
          })}
        </ApplicationContainer>
      </Container>
      <ButtonContainer>
        <CustomButton variant="secondary" onClick={previousPage}>
          Cancel
        </CustomButton>
        <LoadingButton
          loading={loading}
          disabled={!selectedAppAPIName}
          onClick={handleNext}
        >
          Next
        </LoadingButton>
      </ButtonContainer>
    </>
  );
};

export default SelectApplication;

import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const useEscapePage = (defaultPath = '/') => {
  const history = useHistory();
  const [{ shouldEscape, pathTo }, setEscapeState] = useState({
    shouldEscape: false,
    pathTo: defaultPath
  });
  useEffect(() => {
    if (shouldEscape) {
      history.push(pathTo);
    }
  }, [shouldEscape]);

  const escapePage = (newPath = defaultPath) => {
    setEscapeState({ shouldEscape: true, pathTo: newPath });
  };

  return { shouldEscape, escapePage };
};

export default useEscapePage;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { UserService } from 'services';

import { CustomButton, Avatar } from 'components/NewLyncworkUIKit';
import {
  Container,
  HeaderContainer,
  ListContainer,
  UserCardContainer
} from './styles';

const UserCard = ({ user }) => {
  const { id, firstName, lastName, teams, roles, avatar } = user;
  const name = `${firstName} ${lastName}`;
  const src = avatar?.url;

  const detail = teams?.[0]?.teamName || roles?.[0]?.roleName;
  return (
    <UserCardContainer>
      <Avatar name={name} className="avatar" colorKey={id} src={src} />
      <div className="text-container">
        <span>{name}</span>
        <span className="detail">{detail}</span>
      </div>
    </UserCardContainer>
  );
};

const People = () => {
  const [{ users, loading }, setState] = useState({ loading: true, users: [] });

  useEffect(() => {
    let mounted = true;
    const fetch = async () => {
      const name = '';
      const page = '0';
      const size = 6;
      const arr = ['id', 'firstName', 'lastName', 'roles', 'teams', 'avatar'];
      const teamId = '';
      const roleId = '';
      const status = 'active';
      const sort = 'id,desc';
      try {
        const response = await UserService.list(
          name,
          page,
          size,
          arr,
          teamId,
          roleId,
          status,
          sort
        );
        const fetchedUsers = response.data.content;
        mounted && setState({ loading: false, users: fetchedUsers });
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
    return () => {
      mounted = false;
    };
  }, []);
  return (
    <Container>
      <HeaderContainer>
        <h1>New Users</h1>
        <Link to="/admin-users">
          <CustomButton tabIndex="-1" variant="secondary" size="small">
            View All
          </CustomButton>
        </Link>
      </HeaderContainer>
      {loading ? (
        <CircularProgress />
      ) : (
        <ListContainer>
          {users.map(user => (
            <UserCard user={user} key={user.id} />
          ))}
        </ListContainer>
      )}
    </Container>
  );
};

export default People;

import React from 'react';
import HoverInfo from 'components/HoverInfo';
import { LocaleService } from 'services/locale.service';

const keys = ['companyName', 'phonePrimary', 'domain', 'website'];
const addressKeys = [
  'lineOne',
  'lineTwo',
  'city',
  'state',
  'postalCode',
  'country'
];

const domainTitle = (
  <span>
    Enter the domain associated with your company email, this can be found after
    the @ at the end of emails, i.e. johnsmith@
    <strong style={{ color: '#FFB340' }}>lyncwork.com</strong>
  </span>
);

export const inputLabels = {
  domain: (
    <>
      Email Domain <span>*</span>
      <HoverInfo title={domainTitle} />
    </>
  ),

  companyName: 'Company Name',
  phonePrimary: 'Primary Phone',
  website: 'Website',
  lineOne: 'Line One',
  lineTwo: 'Line Two',
  city: 'City',
  state: 'State',
  postalCode: 'Postal Code',
  country: 'Country'
};

export const formatCompanyInfo = companyInfo => {
  const {
    companyName,
    phonePrimary,
    website,
    instagramUrl,
    linkedinUrl,
    mediumUrl,
    twitterUrl,
    addresses
  } = companyInfo;
  const firstAddress = addresses?.[0] || {};
  const { lineOne, lineTwo, city, state, postalCode, country, id } =
    firstAddress;

  const data = {};
  keys.forEach(key => {
    data[key] = companyInfo[key] || '';
  });
  addressKeys.forEach(key => {
    data[key] = firstAddress[key] || '';
  });

  data.country = data.country || 'United States';
  data.state = data.state || LocaleService.states(data.country)[0] || '';

  return data;
};

export const formatFormInfo = formData => {
  const companyInfo = {};
  keys.forEach(key => {
    companyInfo[key] = formData[key] || '';
  });
  const firstAddress = {};
  addressKeys.forEach(key => {
    firstAddress[key] = formData[key] || '';
  });
  companyInfo.addresses = [firstAddress];
  return companyInfo;
};

const requiredKeys = [
  'companyName',
  'phonePrimary',
  'domain',
  'lineOne',
  'city',
  'postalCode'
];

export const findMissingFields = formData => {
  const missingFields = requiredKeys.filter(key => !formData[key]);
  return missingFields;
};

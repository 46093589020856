import React from 'react';

import DeleteButton from 'components/DeleteButton';

import { Container, Header, Desc, ButtonContainer } from './styles';

import ApplicationCard from './ApplicationCard';
import MonitoredMessage from './MonitoredMessage';

const ApplicationStatus = ({
  userData,
  deletingUser,
  canDelete,
  handleDelete
}) => {
  const {
    applications,
    lyncWorkUserStatus: { status: userStatus },
    activationToken,
    emailPrimary
  } = userData;
  const isMonitoring = userStatus === 'MONITORED';
  const filteredApplications = applications.filter(app => {
    const { id, appMonitorEnabled } = app;
    if (app.id === 6) {
      return false;
    }
    if (isMonitoring) {
      return appMonitorEnabled;
    }
    return true;
  });
  return (
    <>
      <Container>
        <Header>Accounts and Applications</Header>
        <Desc>
          Applications are assigned to individual users via their role.
        </Desc>
        {isMonitoring && <MonitoredMessage />}
        {filteredApplications.map(application => (
          <ApplicationCard
            key={application.id}
            application={application}
            userStatus={userStatus}
            activationToken={activationToken}
            emailPrimary={emailPrimary}
          />
        ))}
      </Container>
      {!isMonitoring && (
        <ButtonContainer>
          {canDelete && (
            <DeleteButton loading={deletingUser} onClick={handleDelete} />
          )}
        </ButtonContainer>
      )}
    </>
  );
};

export default ApplicationStatus;

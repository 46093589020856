import styled from 'styled-components';

import { CustomCheckbox, Avatar } from 'components/NewLyncworkUIKit';

export const StyledCheckbox = styled(CustomCheckbox)`
  margin: 0 0 0 24px;
`;

export const StyledAvatar = styled(Avatar)`
  width: 28px;
  height: 28px;
  margin: 0 8px 0 0;
`;

export const UserName = styled.span`
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${props => props.theme.typography.control};
  font-weight: 500;
`;

import React from 'react';
import Button from 'components/NewLyncworkUIKit/CustomButton';

import { Container, ButtonContainer } from './styles';

import { AppCard } from './components';

const EmailAppSelection = ({ prevPage, apps, selectApp }) => {
  return (
    <>
      <Container>
        <h1>Choose Your Email Service Provider</h1>
        {apps.map(app => {
          const handleSelection = () => selectApp(app);
          return (
            <AppCard key={app.id} app={app} handleSelection={handleSelection} />
          );
        })}
      </Container>
      <ButtonContainer>
        <Button onClick={prevPage} variant="secondary" width="160px">
          Back
        </Button>
      </ButtonContainer>
    </>
  );
};

export default EmailAppSelection;

import React from 'react';
import { CustomButton } from 'components/NewLyncworkUIKit';

import { Container, NavOption } from './styles';

const ToolBar = () => {
  return (
    <Container>
      <NavOption to="/admin-community" activeClassName="active" exact>
        Posts
      </NavOption>
      <NavOption to="/admin-community/drafts" activeClassName="active">
        Drafts
      </NavOption>
      <NavOption to="/admin-community/posts/add" activeClassName="active">
        <CustomButton variant="secondary" size="small">
          Add a Post
        </CustomButton>
      </NavOption>
    </Container>
  );
};
export default ToolBar;

import styled from 'styled-components';
import { CustomButton } from 'components/NewLyncworkUIKit';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Grid = styled.div`
  width: 100%;
  height: 700px;
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: 1fr 4fr;
  gap: 16px;
  margin: 24px 0;
`;

import React, { useState } from 'react';

import { formatCompanyInfo } from './utils';

const useCompanyInfo = ({ companyInfo }) => {
  const [formData, setFormData] = useState(formatCompanyInfo(companyInfo));
  const [formErrors, setFormErrors] = useState({ addresses: [] });
  const { companyLogo } = companyInfo;
  const [companyAvatar, setCompanyAvatar] = useState({
    file: null,
    url: companyLogo?.url
  });

  const updateFormField = ({ name, value }) => {
    setFormData(oldData => ({ ...oldData, [name]: value }));
    setFormErrors(oldErrors => ({ ...oldErrors, [name]: '' }));
  };

  const updateFormError = newErrors =>
    setFormErrors(oldErrors => ({ ...oldErrors, ...newErrors }));
  return { formData, formErrors, updateFormField, updateFormError };
};

export default useCompanyInfo;

import styled, { css } from 'styled-components';
import Button from 'components/NewLyncworkUIKit/CustomButton';
import { buttonSizes } from 'components/NewLyncworkUIKit/CustomButton/styles';
import colors from 'designSystem/foundations/colors';
import { multilineEllipsis } from 'StyleVariables/StandardComponents';

const mobileBreakpoint = '900px';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: space-between;
  height: 134px;
  padding: 24px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  margin: 4px 0;
  border-radius: 10px;
  @media only screen and (max-width: ${mobileBreakpoint}) {
    height: 134px;
    flex-direction: row;
  }
`;

export const ButtonContainer = styled.div`
  margin-left: auto;
  flex-basis: 100%;
  display: flex;
  @media only screen and (max-width: ${mobileBreakpoint}) {
    flex-basis: unset;
  }
`;

export const CustomButton = styled(Button)`
  width: 80px;
  align-self: center;
  @media only screen and (max-width: ${mobileBreakpoint}) {
    ${buttonSizes.small};
  }
`;

CustomButton.defaultProps = {
  size: 'medium'
};

export const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 100px);
  margin-bottom: 14px;
  @media only screen and (max-width: ${mobileBreakpoint}) {
    flex-basis: 100%;
    width: 100%;
  }
`;

export const Title = styled.h1`
  width: 100%;
  ${props => props.theme.typography.body};
  font-weight: 600;
  margin: 0;
  ${multilineEllipsis}
`;

Title.defaultProps = { lineClamp: 1 };

export const Description = styled.span`
  color: ${props => props.theme.colors.neutral.grey06};
  ${props => props.theme.typography.metadata};
  ${multilineEllipsis}
`;

Description.defaultProps = { lineClamp: 1 };

const checkedState = css`
  border: 2px solid ${colors.feedback.success};
  background-color: ${colors.feedback.success};
`;
const uncheckedState = css`
  border: 2px solid ${colors.feedback.error};
`;

export const CheckMarkContainer = styled.div`
  width: 12px;
  height: 12px;
  margin-right: 4px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => (props.checked ? checkedState : uncheckedState)};

  img {
    width: 60%;
    height: 60%;
    object-fit: contain;
  }
`;

CheckMarkContainer.defaultProps = { checked: true };

export const CheckedContainer = styled.div`
  display: flex;
  align-items: center;
  ${props => props.theme.typography.metadata};
  font-weight: 600;
  color: ${props => props.theme.colors.neutral.grey05};
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${props => props.theme.colors.neutral.grey10};
  ${props => props.theme.typography.control};
  font-weight: 500;
  & > :first-child {
    margin: 0 4px 0;
    border-radius: 0;
    img {
      object-fit: contain;
    }
  }
`;

import React from 'react';
import { ReactComponent as DashboardIcon } from 'assets/images/navIcons/Dashboard - Active.svg';
import { ReactComponent as CommunityIcon } from 'assets/images/navIcons/Community - Active.svg';
import { ReactComponent as JourneysIcon } from 'assets/images/navIcons/Journeys - Active.svg';
import { ReactComponent as CompanyIcon } from 'assets/images/navIcons/Company Info - Active.svg';
import { ReactComponent as DirectoryIcon } from 'assets/images/navIcons/Directory - Active.svg';

const navMenuItems = [
  {
    title: 'Dashboard',
    to: '/dashboard',
    icon: <DashboardIcon />
  },
  {
    title: 'Community',
    to: '/community',
    icon: <CommunityIcon />
  },
  {
    title: 'Journeys',
    to: '/user-journeys',
    icon: <JourneysIcon />
  },
  {
    title: 'Directory',
    to: '/company-directory',
    icon: <DirectoryIcon />
  },
  {
    title: 'Company Info',
    to: '/company-info',
    icon: <CompanyIcon />
  }
];

export default navMenuItems;

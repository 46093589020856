import styled from 'styled-components';
import { Link } from 'react-router-dom';

import CustomButton from 'components/NewLyncworkUIKit/CustomButton';
import { multilineEllipsis } from 'StyleVariables/StandardComponents';

export const Container = styled.div`
  display: flex;
  width: calc(50% - 34px);
  align-items: center;
  height: 72px;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.grey02};
  padding: 0 0 0 4px;
  gap: 12px;

  &:nth-last-child(1),
  &:nth-last-child(2) {
    border-color: transparent;
  }
`;

export const AppDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
  max-width: 40%;
  span {
    ${multilineEllipsis}
  }
`;

export const AppName = styled.span`
  ${props => props.theme.typography.body};
  font-weight: 600;
`;
export const AppDesc = styled.span`
  ${props => props.theme.typography.metadata};
  color: ${props => props.theme.colors.neutral.grey06};
`;

AppDetailContainer.defaultProps = { lineClamp: 1 };

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const AddedButton = styled(CustomButton)`
  color: ${props => props.theme.colors.neutral.grey07};
  padding: 0 8px;
  &:disabled {
    color: ${props => props.theme.colors.neutral.grey07};
    cursor: unset;
  }
`;
AddedButton.defaultProps = {
  size: 'small',
  variant: 'tertiary',
  disabled: true
};

import styled from 'styled-components';
import { multilineEllipsis } from 'StyleVariables/StandardComponents';

const customBreakPoint = '800px';

export const TableContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 80px;
  width: 100%;
  .MuiCircularProgress-root {
    margin: 36px;
  }
`;

export const EmptyMessage = styled.div`
  margin: 36px;
  ${props => props.theme.typography.body};
  font-weight: 600;
  color: ${props => props.theme.colors.neutral.grey10};
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 0 12px;
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey10};
  @media only screen and (max-width: ${customBreakPoint}) {
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

export const HeaderRowContainer = styled(RowContainer)`
  ${props => props.theme.typography.metadata};
  font-weight: 500;
  color: ${props => props.theme.colors.neutral.grey07};
`;

export const JourneyName = styled.div`
  ${multilineEllipsis}
  flex-basis:30%;
  flex-grow: 0;
  flex-shrink: 0;
  @media only screen and (max-width: ${customBreakPoint}) {
    flex-basis: calc(100% - 100px);
  }
`;
JourneyName.defaultProps = { lineClamp: 1 };

export const JourneyDue = styled.div`
  color: ${props => props.theme.colors.neutral.grey07};
  flex-basis: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  @media only screen and (max-width: ${customBreakPoint}) {
    text-align: right;
  }
`;

export const JourneyProgress = styled.div`
  display: flex;
  align-items: center;

  flex-grow: 1;
  margin: 0 36px 0 0;
  span {
    width: 32px;
    ${props => props.theme.typography.metadata};
    color: ${props => props.theme.colors.neutral.grey05};
  }
  @media only screen and (max-width: ${customBreakPoint}) {
    order: 3;
    margin: 8px 0 0;
    width: 100%;
    flex-basis: 100%;
    span {
      text-align: right;
    }
  }
`;

export const JourneyProgressLabel = styled.div`
  display: flex;
  align-items: center;

  flex-grow: 1;
  @media only screen and (max-width: ${customBreakPoint}) {
    display: none;
  }
`;

export const ProgressBar = styled.div`
  position: relative;
  height: 8px;
  flex-grow: 1;
  border-radius: 4px;
  margin: 0 12px 0 0;
  background-color: ${props => props.theme.colors.neutral.grey02};

  &:after {
    content: '';
    position: absolute;
    border-radius: 4px;
    height: 100%;
    width: ${props => props.barWidth};
    background-color: ${props => props.theme.colors.brand.primary};
  }
`;

export const OverdueNotice = styled.div`
  display: flex;
  height: 23px;
  width: 80px;
  flex-direction: column;
  align-items: center;
  
  font-weight: 550;
  color: #E84740;
  background-color: #FDF0F0;
  border-radius: 5px;
 
`;

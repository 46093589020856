import React, { useState } from 'react';

import {
  CustomTextInput,
  CustomButton,
  Label
} from 'components/NewLyncworkUIKit';

import {
  SectionContainer,
  Description,
  InputWrapper,
  ButtonContainer,
  SectionTitle
} from './styles';

import AppsAffected from './AppsAffected';

const DaySelection = ({
  fullName,
  monitorDuration,
  handleDurationChange,
  returnToMenu,
  changeStep
}) => {
  const isDurationValid =
    typeof monitorDuration === 'number' && monitorDuration > 0;
  return (
    <>
      <SectionContainer>
        <Description>
          Monitored deprovisioning will attempt to set {fullName}’s associated
          accounts to a monitor state.
        </Description>
        <Description>
          The user’s credentials will be immediately be revoked, and temporary
          account monitoring credentials will be provided to you. These accounts
          will be fully deleted after 60 days.
        </Description>
        {/* <InputWrapper>
          <Label>Monitoring duration in days</Label>
          <CustomTextInput
            type="number"
            value={monitorDuration}
            onChange={handleDurationChange}
            width="60px"
          />
        </InputWrapper> */}
        <Description>
          Your licences for monitered accounts will remain active in the
          meantime, and you will still be charged for those licences until full
          deletion.
        </Description>
        <Description>
          Accounts which cannot be set to a moniter state will be deleted
          immediately instead.
        </Description>
      </SectionContainer>
      <ButtonContainer>
        <CustomButton variant="secondary" size="medium" onClick={returnToMenu}>
          Back
        </CustomButton>
        <CustomButton
          size="medium"
          // disabled={!isDurationValid}
          onClick={changeStep}
        >
          Next
        </CustomButton>
      </ButtonContainer>
    </>
  );
};

const ConfirmDeprovision = ({
  fullName,
  applications,
  changeStep,
  monitorUser
}) => {
  const [inputValue, setInputValue] = useState('');
  const onChange = e => setInputValue(e.target.value);
  const label = `To proceed, type in the user’s name (${fullName})`;
  const disableDeprovisionButton = fullName !== inputValue;

  return (
    <>
      <SectionContainer className="bottom-divider">
        <AppsAffected applications={applications} />
      </SectionContainer>
      <SectionContainer>
        <span>{label}</span>
        <CustomTextInput value={inputValue} onChange={onChange} width="340px" />
        <SectionTitle>This action is permanent and irreversible.</SectionTitle>
      </SectionContainer>
      <ButtonContainer>
        <CustomButton variant="secondary" size="medium" onClick={changeStep}>
          Back
        </CustomButton>
        <CustomButton
          size="medium"
          disabled={disableDeprovisionButton}
          onClick={monitorUser}
        >
          Next
        </CustomButton>
      </ButtonContainer>
    </>
  );
};

const MonitoredDeprovision = ({ userData, returnToMenu, monitorUser }) => {
  const [showDaySelection, setShowDaySelection] = useState(true);
  const [monitorDuration, setMonitorDuration] = useState(7);
  const { firstName, lastName, applications } = userData;
  const fullName = `${firstName} ${lastName}`;

  const handleDurationChange = e => {
    const value = e.target.value;
    const parsedValue = parseInt(value);
    const finalValue = isNaN(parsedValue) ? '' : parsedValue;
    setMonitorDuration(finalValue);
  };

  const changeStep = () => setShowDaySelection(a => !a);

  return (
    <>
      {showDaySelection ? (
        <DaySelection
          fullName={fullName}
          monitorDuration={monitorDuration}
          handleDurationChange={handleDurationChange}
          returnToMenu={returnToMenu}
          changeStep={changeStep}
        />
      ) : (
        <ConfirmDeprovision
          fullName={fullName}
          changeStep={changeStep}
          monitorUser={monitorUser}
          applications={applications}
        />
      )}
    </>
  );
};

export default MonitoredDeprovision;

import styled, { css } from 'styled-components';
import colors from 'designSystem/foundations/colors';

import {
  multilineEllipsisFunc,
  scrollBar
} from 'StyleVariables/StandardComponents';

import { Link } from 'react-router-dom';

const {
  neutral: { white, offWhite01, offWhite02, grey10, grey04, grey05 },
  feedback: { confirmation }
} = colors;

export const NotificationPanel = styled.div`
  position: absolute;
  height: 500px;
  width: 360px;
  top: 62px;
  right: 0;
  overflow: hidden;
  background-color: white;
  box-shadow: -2px 5px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  z-index: 100;

  h2 {
    margin: 0;
    font-weight: 600;
    padding-left: 24px;
    margin-top: 16px;
    padding-bottom: 16px;
    font-size: 18px;
    border-bottom: 1px solid ${grey04};
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.small}) {
    width: 100%;
    // 62px - top nav height
    // 76px - bottom nav height
    height: calc(100% - 62px - 76px);
    box-shadow: none;
  }
`;

export const ListContainer = styled.div`
  position: relative;
  flex: 1;
  height: 400px;
  width: 360px;
  overflow: auto;
  background-color: white;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  ${scrollBar}
  a {
    text-decoration: none;
  }
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.small}) {
    width: 100%;
    height: 100%;
  }
`;

const blueDot = css`
  background-color: ${offWhite01};
  &:after {
    content: '';
    position: absolute;
    top: 22px;
    right: 16px;
    width: 10px;
    height: 10px;
    border-radius: 15px;
    background-color: ${confirmation};
  }
`;

export const NotificationContainer = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  text-decoration: none;
  min-height: 130px;
  width: 100%;
  padding: 1em;
  padding-left: 24px;
  border-bottom: 1px solid ${grey05};
  transition: all 0.3s;
  background-color: ${white};

  ${props => !props.$linkClicked && blueDot};
    

  &:hover {
    background-color: ${offWhite02};
  }

  h3 {
    font-weight: 600;
    text-transform: capitalize;
    margin: 0;
    margin-bottom: 4px;
    font-size: 14px;
    ${multilineEllipsisFunc(1)}
        color: ${grey10};

  }
  p {
    margin: 0;
    ${multilineEllipsisFunc(2)}
    padding-right:16px;
    color: ${grey10};
  }
  span {
    margin-top: 8px;
    color: ${grey05};
  }
`;

export const ToggleButton = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  transition: all 0.3s;
  background-color: transparent;
  outline: none;
  border: none;

  cursor: pointer;
  color: ${grey10};
  &:hover {
    background-color: ${offWhite02};
  }
`;

export const UnseenCounter = styled.div`
  position: absolute;
  top: 5px;
  left: 30px;
  display: flex;
  flex-direction: row;
  padding: 0.25em 0.5em;
  background-color: red;
  color: white;
  font-size: 10px;
  border-radius: 1em;
  width: fit-content;
`;

import React, { useState } from 'react';

import CustomTextArea from 'components/NewLyncworkUIKit/CustomTextArea';
import LoadingButton from 'components/LoadingButton';

import { AnnouncementContainer, ButtonContainer } from './styles';

const Toolbar = () => {
  return (
    <ButtonContainer>
      <LoadingButton size="small" variant="secondary">
        Cancel
      </LoadingButton>
      <LoadingButton size="small" spinnerSize="16px">
        Save
      </LoadingButton>
    </ButtonContainer>
  );
};

const AnnouncementEdit = ({
  removeError,
  errorText,
  toggleEdit,
  createAnnouncement,
  submitting
}) => {
  const error = !!errorText;
  const [value, setValue] = useState('');
  const onChange = e => {
    removeError();
    setValue(e.target.value);
  };

  const handleSave = () => createAnnouncement(value);
  return (
    <>
      <AnnouncementContainer>
        <CustomTextArea
          placeholder="Type in here"
          onChange={onChange}
          subLabel={errorText}
          error={error}
        />
      </AnnouncementContainer>
      <ButtonContainer>
        <LoadingButton size="small" variant="secondary" onClick={toggleEdit}>
          Cancel
        </LoadingButton>
        <LoadingButton
          size="small"
          spinnerSize="16px"
          onClick={handleSave}
          loading={submitting}
        >
          Save
        </LoadingButton>
      </ButtonContainer>
    </>
  );
};

export default AnnouncementEdit;

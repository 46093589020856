import React from 'react';

import { CustomButton } from 'components/NewLyncworkUIKit';
import {
  Container,
  InfoContainer,
  SkillName,
  SliderContainer,
  SliderInput,
  Progress,
  ProgressDot,
  SkillLevelTextContainer,
  SkillLevelText
} from './styles';

const maxLevel = 4;
const levels = new Array(maxLevel + 1).fill(0).map((_, i) => i);
const levelText = ['', 'Novice', 'Intermediate', 'Advanced', 'Expert'];

const Slider = ({ skillLevel, handleLevelChange }) => {
  const width = `${(skillLevel * 100) / maxLevel}%`;
  return (
    <>
      <SliderContainer>
        <SliderInput
          min={0}
          max={maxLevel}
          value={skillLevel}
          onChange={handleLevelChange}
        />
        <Progress width={width}>
          {levels.map(level => (
            <ProgressDot key={level} isBefore={level <= skillLevel} />
          ))}
        </Progress>
      </SliderContainer>
      <SkillLevelTextContainer>
        {levelText.map((text, index) => (
          <SkillLevelText key={text} isSelected={index === skillLevel}>
            {text}
          </SkillLevelText>
        ))}
      </SkillLevelTextContainer>
    </>
  );
};

const SkillCard = ({ skill, removeSkill, updateSkill, index }) => {
  const {
    name,
    currentLevel: {
      level: { order: skillLevel }
    },
    levels
  } = skill;
  const handleRemove = () => removeSkill(skill);
  const handleLevelChange = e => {
    const newLevel = parseInt(e.target.value);
    const currentLevel = levels.find(
      skillLevel => skillLevel.level.order === newLevel
    );
    updateSkill({ ...skill, currentLevel });
  };
  return (
    <Container>
      <InfoContainer>
        <SkillName>{name}</SkillName>
        <Slider skillLevel={skillLevel} handleLevelChange={handleLevelChange} />
        <CustomButton variant="secondary" size="medium" onClick={handleRemove}>
          Remove Skill
        </CustomButton>
      </InfoContainer>
    </Container>
  );
};

export default SkillCard;

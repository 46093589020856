import styled from 'styled-components';
import { IconButton } from 'components/NewLyncworkUIKit';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${props => props.theme.colors.neutral.white};
  color: ${props => props.theme.colors.neutral.grey10};
  ${props => props.theme.typography.body};
  width: 640px;
  padding: 24px;
  border-radius: 8px;
  @media only screen and (max-width: 770px) {
    max-width: 80vw;
  }
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 16px;
  height: 16px;
  font-size: 1.5rem;
`;

export const Title = styled.h1`
  ${props => props.theme.typography.header3};
  font-weight: 600;
  margin: 0 0 16px;
`;

export const ContentContainer = styled.div`
  /* padding-right: 60px; */
  p {
    margin-top: 0;
    strong {
      color: ${props => props.theme.colors.feedback.error};
    }
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  button:nth-child(1) {
    margin-right: 16px;
  }
`;

export const AppListContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0 8px 0;
`;

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4px 8px;
  span {
    margin: 4px 0 0;
  }
`;

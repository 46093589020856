import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import checkmark from 'assets/images/Onboarding/checkmark.svg';

import {
  canFileBeViewed,
  signatureRequestSigned
} from 'services/hellosign.service';

import {
  Container,
  CustomButton,
  CardInfoContainer,
  Title,
  Description,
  CheckMarkContainer,
  ButtonContainer,
  CheckedContainer
} from './styles';

const DocumentButton = ({
  readyForView,
  handleViewFile,
  awaitingPDF,
  handleSignDocuments
}) => {
  if (readyForView) {
    return (
      <CustomButton variant="secondary" onClick={handleViewFile}>
        Review
      </CustomButton>
    );
  }

  if (awaitingPDF) {
    return (
      <CustomButton variant="secondary" disabled>
        <CircularProgress size="20px" />
      </CustomButton>
    );
  }
  return (
    <CustomButton variant="primary" onClick={handleSignDocuments}>
      Open
    </CustomButton>
  );
};

const CheckedStatus = ({ checked }) => {
  return (
    <CheckedContainer>
      <CheckMarkContainer checked={checked}>
        {checked && <img src={checkmark} />}
      </CheckMarkContainer>
      {checked ? 'Complete' : 'Signature Required'}
    </CheckedContainer>
  );
};

const DocumentCard = ({
  awaitingPDF,
  readyForView,
  title,
  description,
  handleViewFile,
  handleSignDocuments
}) => {
  const checked = readyForView || awaitingPDF;
  return (
    <Container>
      <CardInfoContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </CardInfoContainer>
      <CheckedStatus checked={checked} />
      <ButtonContainer>
        <DocumentButton
          readyForView={readyForView}
          handleViewFile={handleViewFile}
          awaitingPDF={awaitingPDF}
          handleSignDocuments={handleSignDocuments}
        />
      </ButtonContainer>
    </Container>
  );
};

export default DocumentCard;

export const ADD_SKILL = 'ADD_SKILL';
export const REMOVE_SKILL = 'REMOVE_SKILL';
export const UPDATE_SKILL = 'UPDATE_SKILL';
export const INIT_SKILL = 'INIT_SKILL';
export const REORDER = 'REORDER';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const skillsReducer = (skills = [], action) => {
  const { type, skillToModify } = action;

  switch (type) {
    case REORDER:
      const { startIndex, endIndex } = action;
      return reorder(skills, startIndex, endIndex);
    case INIT_SKILL:
      return action.skills;
    default:
      break;
  }

  const { id: idToModify } = skillToModify;
  const existingSkillIds = skills.map(skill => skill.id);
  const equalsSkillToModify = skill => skill.id === idToModify;

  switch (type) {
    case ADD_SKILL:
      if (existingSkillIds.includes(idToModify)) {
        return skills;
      }
      const { levels } = skillToModify;
      const skillToAdd = { ...skillToModify, currentLevel: levels[0] };
      return [skillToAdd, ...skills];
    case REMOVE_SKILL:
      return skills.filter(skill => !equalsSkillToModify(skill));
    case UPDATE_SKILL:
      const updatedSkills = skills.map(skill => {
        if (equalsSkillToModify(skill)) {
          return skillToModify;
        }
        return skill;
      });
      return updatedSkills;
    default:
      return skill;
  }
};

export default skillsReducer;

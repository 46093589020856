import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import PageTransitionBlock from 'components/PageTransitionBlock';

import { PageTitle, Container, LoadingContainer } from './styles';
import { AccountDetailsForm, AccountInfo } from './components';

import useAccountDetails from './useAccountDetails';

const AccountDetailsPage = () => {
  const { loading, formModified, ...formProps } = useAccountDetails();
  const { avatarData, changeAvatar } = formProps;
  if (loading) {
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    );
  }
  return (
    <>
      <PageTransitionBlock when={formModified} />
      <PageTitle>Account Details</PageTitle>
      <Container>
        <AccountDetailsForm formProps={formProps} />
        <AccountInfo avatarData={avatarData} changeAvatar={changeAvatar} />
      </Container>
    </>
  );
};

export default AccountDetailsPage;

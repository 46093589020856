import React from 'react';

import { Container, Checkbox, CheckboxToggle, Label } from './styles';

const CustomToggle = ({
  className,
  name = '',
  value,
  checked,
  onChange,
  disabled = false,
  label = '',
  bold = false
}) => {
  return (
    <Container className={className} disabled={disabled}>
      <Checkbox
        checked={checked}
        onChange={onChange}
        value={value}
        name={name}
        disabled={disabled}
      />
      <CheckboxToggle />
      {label && <Label bold={bold}>{label}</Label>}
    </Container>
  );
};

export default CustomToggle;

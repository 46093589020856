import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  gap: 32px;
  height: auto;
  box-sizing: border-box;
  margin-bottom: -2vw;
  width: 100%;
  box-sizing: border-box;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 32px - 344px);
  flex-grow: 1;
  flex-shrink: 1;
  padding-bottom: 32px;
  h1 {
    ${props => props.theme.typography.title3};
    font-weight: 600;
    color: ${props => props.theme.colors.neutral.grey10};
    margin: 0 auto 0 0;
    @media only screen and (max-width: ${props =>
        props.theme.breakPoints.small}) {
      display: none;
    }
  }
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.small}) {
    width: 100%;
    flex-shrink: 0;

    &.hide {
      display: none;
    }
  }
`;

export const EmployeeInfoColumn = styled.div`
  position: relative;
  align-self: flex-start;
  height: calc(100% + 2vw);
  flex-shrink: 0;
  flex-basis: 344px;
  margin: -2vw -4vw 0 0;
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.small}) {
    flex-basis: 100%;
  }
`;

export const StickyContainer = styled.div`
  position: sticky;
  background: white;
  z-index: 5;
  top: -2vw;
  margin: 0 0 0 0;
  padding: 32px;
  box-sizing: border-box;
  height: calc(100vh - 60px);
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 5px;
  }
`;

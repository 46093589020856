import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 28px 0;

  h1 {
    ${props => props.theme.typography.title3};
    font-weight: 600;
    color: ${props => props.theme.colors.neutral.grey10};
    margin: 0 auto 0 0;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  margin-left: 16px;
`;

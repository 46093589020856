import React from 'react';

import Button from 'components/NewLyncworkUIKit/CustomButton';
import { useButtonTrack } from 'matomo/hooks';
import { usersListPageEvents } from 'pages/Users/UsersListPage/instrumentationEvents';

import {
  Container,
  StyledAvatar,
  UserDetail,
  StatusIndicator,
  StyledLink,
  StatusDescContainer,
  StatusDesc
} from './styles';

const getStatusDesc = status => {
  switch (status) {
    case 'PENDING_ACTIVATION':
      return 'An activation email has been sent to the user’s inbox. Their user status will be “Active” after completing the email.';
    case 'PENDING_EMAIL_NOTIFICATION':
      return 'The activation email will be sent to the user’s inbox on their provision date.';
    default:
      return '';
  }
};

const getStatusName = status => {
  switch (status) {
    case 'PENDING_EMAIL_NOTIFICATION':
      return 'Pending Email';
    default:
      return status.toLowerCase().replaceAll('_', ' ');
  }
};

const Overview = ({ user }) => {
  const trackButton = useButtonTrack();

  const {
    firstName,
    lastName,
    avatar,
    id,
    lyncWorkUserStatus: { status: userStatus }
  } = user;
  const src = avatar?.url;
  const fullName = `${firstName} ${lastName}`;

  const showStatusDesc =
    userStatus === 'PENDING_ACTIVATION' ||
    userStatus === 'PENDING_EMAIL_NOTIFICATION';
  const statusName = getStatusName(userStatus);
  const statusDesc = getStatusDesc(userStatus);
  return (
    <>
      <Container>
        <StyledAvatar src={src} colorKey={id} name={firstName} key={id} />
        <UserDetail>
          <h2>{fullName}</h2>
          {!showStatusDesc && (
            <StatusIndicator status={userStatus}>{statusName}</StatusIndicator>
          )}
          <StyledLink to={`/admin-users/edit/${id}/work-details`}>
            <Button
              variant="secondary"
              size="small"
              onClick={() => trackButton(usersListPageEvents.edit_user)}
            >
              Edit
            </Button>
          </StyledLink>
        </UserDetail>
      </Container>
      {showStatusDesc && (
        <StatusDescContainer>
          <StatusIndicator status={userStatus}>{statusName}</StatusIndicator>
          <StatusDesc>{statusDesc}</StatusDesc>
        </StatusDescContainer>
      )}
    </>
  );
};

export default Overview;

import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import ErrorFallback from './ErrorFallback';

const ErrorWrapper = ({ children }) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={error => console.log(error)}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorWrapper;

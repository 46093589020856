import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { colors } from 'designSystem/foundations';

const {
  brand: { primary },
  neutral: { grey06 }
} = colors;

export const Container = styled.div`
  display: none;
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.small}) {
    display: flex;
  }
  position: absolute;
  z-index: 2;
  bottom: 0;
  background: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1),
    inset 0px 1px 0px rgba(0, 0, 0, 0.15);
  height: 76px;
  width: 100%;

  /* ... */
`;

export const NavTitle = styled.span`
  text-align: center;
`;

const getNavColor = ({ $isActive }) => ($isActive ? primary : grey06);
const getBorderColor = ({ $isActive }) => ($isActive ? primary : 'transparent');

export const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 20%;
  height: 100%;
  text-decoration: none;
  ${props => props.theme.typography.metadata};
  color: ${getNavColor};
  cursor: pointer;
  &:hover {
    color: ${primary};
    svg {
      border-color: ${primary};
      * {
        fill: ${primary};
      }
    }
  }
  svg {
    box-sizing: content-box;
    border-top: 3px solid transparent;
    border-color: ${getBorderColor};
    padding: 12px 0 0;
    margin: 0 0 4px 0;
    width: 24px;
    height: 24px;
    * {
      fill: ${getNavColor};
    }
  }
`;

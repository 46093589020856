import Spotify from './fakeDataImages/Spotify.svg';
import Starbucks from './fakeDataImages/Starbucks.svg';
import Lululemon from './fakeDataImages/lululemon.svg';
import Netflix from './fakeDataImages/Netflix.svg';
import Apple from './fakeDataImages/Apple.svg';

export const fakeData = [
  {
    id: 0,
    image: Spotify,
    title: 'Spotify'
  },
  {
    id: 1,
    image: Starbucks,
    title: 'Starbucks'
  },
  {
    id: 2,
    image: Lululemon,
    title: 'Lululemon'
  },
  {
    id: 3,
    image: Netflix,
    title: 'Netflix'
  },
  {
    id: 4,
    image: Apple,
    title: 'Apple Music'
  }
];

import React, { useState } from 'react';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';

import { AnnouncementService } from 'services/announcement.service';

import useAnnouncements from 'hooks/useAnnouncements';

import AnnouncementView from './AnnouncementView';
import AnnouncementEdit from './AnnouncementEdit';

import {
  AnnouncementHeading,
  AnnouncementContainer,
  AnnouncementDate,
  ButtonContainer
} from './styles';

const Announcement = () => {
  const { loading, announcements, setAnnouncements } = useAnnouncements({
    size: 1
  });
  const announcement = announcements[0];

  const [editing, setEditing] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errorText, setErrorText] = useState('');

  const removeError = () => setErrorText(false);

  const createAnnouncement = async content => {
    if (!content) {
      setErrorText('Announcements cannot be empty');
      return;
    }
    try {
      setSubmitting(true);
      const response = await AnnouncementService.create({
        title: 'announcement',
        content
      });
      setAnnouncements([response.data]);
      setEditing(false);
    } catch (error) {
      setErrorText('Oops, something went wrong');
    } finally {
      setSubmitting(false);
    }
  };

  const toggleEdit = () => setEditing(a => !a);

  return (
    <>
      <AnnouncementHeading>
        <RecordVoiceOverIcon />
        Company Announcements
      </AnnouncementHeading>
      {editing ? (
        <AnnouncementEdit
          errorText={errorText}
          removeError={removeError}
          toggleEdit={toggleEdit}
          createAnnouncement={createAnnouncement}
          submitting={submitting}
        />
      ) : (
        <AnnouncementView
          loading={loading}
          announcement={announcement}
          toggleEdit={toggleEdit}
        />
      )}
    </>
  );
};

export default Announcement;

import React from 'react';

import {
  SliderContainer,
  AppContainer,
  AppDesc,
  AppName,
  StyledLink
} from './styles';

const AppCard = ({ id, appName, description, src, colors }) => {
  const viewAppLink = `/admin-applications/view/${id}`;
  const {
    background,
    descColor = '#FFFFFF85',
    appNameColor = '#FFFFFF',
    linkBackground = '#FFFFFF',
    linkText
  } = colors;
  return (
    <AppContainer background={background}>
      <img alt={appName} src={src} />
      <AppDesc color={descColor}>{description}</AppDesc>
      <AppName color={appNameColor}>{appName}</AppName>
      <StyledLink to={viewAppLink} background={linkBackground} color={linkText}>
        View Application
      </StyledLink>
    </AppContainer>
  );
};

export default AppCard;

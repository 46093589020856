import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import useApplicationCategories from 'hooks/useApplicationCategories';

import ApplicationBrowse from './ApplicationBrowse';
import ApplicationSearch from './ApplicationSearch';
import ApplicationView from './ApplicationView';

import MyApplications from './MyApplications';
import SingleSubscriptionApp from './SingleSubscriptionApp';

const ApplicationPage = () => {
  const applicationCategories = useApplicationCategories();
  const allAppCategories = [{ id: -1, name: 'All' }, ...applicationCategories];

  return (
    <Switch>
      <Route path="/admin-applications" exact>
        <Redirect to="/admin-applications/browse" />
      </Route>
      <Route path="/admin-applications/browse" exact>
        <ApplicationBrowse applicationCategories={allAppCategories} />
      </Route>
      <Route path="/admin-applications/search" exact>
        <ApplicationSearch applicationCategories={allAppCategories} />
      </Route>
      <Route path="/admin-applications/view/:id">
        <ApplicationView />
      </Route>
      <Route path="/admin-applications/my-apps" exact>
        <MyApplications />
      </Route>
      <Route path="/admin-applications/my-apps/:id">
        <SingleSubscriptionApp />
      </Route>
    </Switch>
  );
};

export default ApplicationPage;

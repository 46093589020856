import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomRadio, CustomButton } from 'components/NewLyncworkUIKit';
import importImage from './assets/importImage.svg';
import {
  PageContainer,
  Header,
  Container,
  ImportImage,
  Title,
  RadioSubLabel,
  ButtonContainer
} from './styles';

const Option = ({ subLabel, ...rest }) => {
  return (
    <>
      <CustomRadio {...rest} bold />
      <RadioSubLabel>{subLabel}</RadioSubLabel>
    </>
  );
};

const fileImport = 'file';
const appImport = 'apps';

const UsersImportPageMain = () => {
  const history = useHistory();
  const [importOption, setImportOption] = useState(null);
  const handleCheck = e => {
    setImportOption(e.target.value);
  };

  const handleCancel = () => history.push('/admin-users');
  const handleNext = () => {
    if (!importOption) {
      return;
    }
    history.push(`/admin-users/import/${importOption}`);
  };

  return (
    <PageContainer>
      <Header>Import Users</Header>
      <Container>
        <Title>How would you like to import your users?</Title>
        <Option
          label="File Upload"
          subLabel="Import users from a CSV file"
          value={fileImport}
          checked={importOption === fileImport}
          onChange={handleCheck}
        />
        <Option
          label="Application Integration"
          subLabel="Connect to an existing application service to import a list of users."
          value={appImport}
          checked={importOption === appImport}
          onChange={handleCheck}
        />
        <ImportImage src={importImage} />
      </Container>
      <ButtonContainer>
        <CustomButton variant="secondary" onClick={handleCancel}>
          Cancel
        </CustomButton>
        <CustomButton disabled={!importOption} onClick={handleNext}>
          Next
        </CustomButton>
      </ButtonContainer>
    </PageContainer>
  );
};

export default UsersImportPageMain;

import React from 'react';
import { HeaderRow, HeaderCell } from 'components/ListTable';
const TableHeader = () => {
  return (
    <HeaderRow>
      <HeaderCell flex={1}>Name</HeaderCell>
      <HeaderCell flex={2}>Services</HeaderCell>
      <HeaderCell flex={1}>Status</HeaderCell>
      <HeaderCell flex={1}>Date Modified</HeaderCell>
      <HeaderCell flex={1} />
    </HeaderRow>
  );
};

export default TableHeader;

import { layoutActionTypes } from './layout.types';

const initialLayoutState = {
  sideNavOpen: true,
  userDropdownOpen: false,
  sideNavAdminView: false
};
const layoutReducer = (state = initialLayoutState, action) => {
  switch (action.type) {
    case layoutActionTypes.TOGGLE_SIDENAV_HIDDEN:
      return {
        ...state,
        sideNavOpen: !state.sideNavOpen
      };
    case layoutActionTypes.TOGGLE_USER_DROPDOWN_HIDDEN:
      return {
        ...state,
        userDropdownOpen: !state.userDropdownOpen
      };
    case layoutActionTypes.TOGGLE_SIDENAV_ADMIN_VIEW:
      return {
        ...state,
        sideNavAdminView: !state.sideNavAdminView
      };
    default:
      return state;
  }
};
export default layoutReducer;

import React, { useState, useEffect } from 'react';
import { UserService } from 'services';

const name = '';
const page = 0;
const size = 1000;
const teamId = '';
const arr = ['id', 'firstName', 'lastName', 'roles', 'username', 'avatar'];

const useUserByRole = roleId => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await UserService.list(
          name,
          page,
          size,
          arr,
          teamId,
          roleId
        );
        setUsers(response.data.content);
      } catch (error) {
        console.log(error);
      }
    };
    if (roleId !== null) {
      fetchUsers();
    }
  }, []);
  return users;
};

export default useUserByRole;

import styled from 'styled-components';

export const Column = styled.div`
  width: 240px;
  min-width: 240px;
  position: sticky;
  left: 0;
  z-index: 40;
  background: white;
  border-right: 1px solid ${props => props.theme.colors.neutral.offWhite03};
`;

export const Cell = styled.div`
  height: 56px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  ${props => props.theme.typography.control};
  font-weight: 500;
  color: ${props => props.theme.colors.neutral.grey10};
  cursor: pointer;
`;

export const HeaderCell = styled(Cell)`
  height: 74px;
  position: sticky;
  top: 0;
  color: ${props => props.theme.colors.neutral.grey07};
  ${props => props.theme.typography.metadata};
  font-weight: 600;
  background-color: ${props => props.theme.colors.neutral.offWhite01};
  border-bottom: 1px solid ${props => props.theme.colors.neutral.offWhite03};
`;

import React from 'react';

import { useMobileTutorial } from 'context/MobileTutorialContext';
import CustomButton from 'components/NewLyncworkUIKit/CustomButton';

import { Overlay, ExitContainer } from './styles';

const TutorialExit = () => {
  const { confirmExit, cancelExit } = useMobileTutorial();
  return (
    <Overlay>
      <ExitContainer>
        <h3>Exit Tour</h3>
        <p>Are you sure you want to skip the introductory tour?</p>
        <p>You can start the tour again at a later time.</p>
        <div className="button-container ">
          <CustomButton size="medium" variant="secondary" onClick={cancelExit}>
            Cancel
          </CustomButton>
          <CustomButton size="medium" onClick={confirmExit}>
            Exit Tour
          </CustomButton>
        </div>
      </ExitContainer>
    </Overlay>
  );
};

export default TutorialExit;

import React from 'react';
import { Row, Cell } from 'components/ListTable';
import {
  StyledCheckbox,
  CategoryPill,
  StyledAvatar,
  UserName,
  EditButton
} from './styles';

const getCategory = fileCategory => {
  switch (fileCategory) {
    case 'SIGNABLE_ALL':
      return 'Signature Required';
    case 'ALL':
      return 'Read Only';
    default:
      return fileCategory.toLowerCase();
  }
};

const getUserInfo = user => {
  if (!user) {
    return { avatarUrl: '', fullName: '' };
  }

  const { firstName, lastName, avatar } = user;
  const avatarUrl = avatar?.url;
  const fullName = `${firstName} ${lastName}`;
  return { avatarUrl, fullName };
};

const formatLocation = externalGroupId => {
  if (!externalGroupId) {
    return '';
  }
  const charArray = externalGroupId.split('');
  const modifiedCharArray = charArray.map((char, index) => {
    if (index === 0) {
      return char.toUpperCase();
    }
    return char.toLowerCase();
  });
  return modifiedCharArray.join('');
};

const DropboxRow = ({ item, handleSelectFile, selectedFiles, handleEdit }) => {
  const {
    signingTitle,
    fileCategory,
    externalGroupId,
    user,
    sysCreationDate
  } = item;
  const category = getCategory(fileCategory);
  const location = formatLocation(externalGroupId);

  const { avatarUrl, fullName } = getUserInfo(user);
  const uploadedDate = sysCreationDate?.split('T')[0];
  return (
    <Row>
      <StyledCheckbox
        name={signingTitle}
        onChange={handleSelectFile}
        checked={selectedFiles.includes(item)}
      />
      <Cell flex={2} className="list-row-name">
        {signingTitle}
      </Cell>
      <Cell flex={2}>
        <CategoryPill fileCategory={fileCategory}>{category}</CategoryPill>
      </Cell>
      <Cell flex={2}>{location}</Cell>
      <Cell flex={2}>
        <StyledAvatar src={avatarUrl} colorKey={user?.id} name={fullName} />
        <UserName>{fullName}</UserName>
      </Cell>
      <Cell flex={2}>{uploadedDate}</Cell>
      <Cell>
        <EditButton onClick={handleEdit}>Edit</EditButton>
      </Cell>
    </Row>
  );
};

export default DropboxRow;

import React from 'react';

import Button from 'components/NewLyncworkUIKit/CustomButton';

import {
  Container,
  ListContainer,
  NumberContainer,
  StyledLink
} from './styles';

const ListItem = ({ number, text }) => {
  return (
    <li>
      <NumberContainer>{number}</NumberContainer>
      <div>{text}</div>
    </li>
  );
};

const AdminSetupDone = ({ nextPage }) => {
  return (
    <>
      <Container>
        <h1>Your Next Steps</h1>
        <ListContainer>
          <ListItem number={1} text="Lyncwork Walkthrough" />
          <ListItem
            number={2}
            text="Explore Lyncwork and when ready, remove prefilled data"
          />
          <ListItem
            number={3}
            text="Go to Journeys and complete tasks in the onboarding journey"
          />
        </ListContainer>
      </Container>
      <StyledLink to="/dashboard">
        <Button>Start Using Lyncwork</Button>
      </StyledLink>
    </>
  );
};

export default AdminSetupDone;

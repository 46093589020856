import styled from 'styled-components';
import { colors } from 'designSystem/foundations';
const {
  neutral: { offWhite01, grey07, grey10 }
} = colors;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 32px 30px 76px;
  margin-bottom: 20px;
  gap: 24px;

  box-sizing: border-box;
  background: ${offWhite01};
  ${props => props.theme.typography.control};
  color: ${grey07};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: -44px;
  gap: 20px;
  color: ${grey10};
  font-weight: 600;
`;

export const Description = styled.p`
  margin: 0;
`;

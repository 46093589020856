import React, { useRef } from 'react';
import ReactDom from 'react-dom';

import styled from 'styled-components';

const Overlay = styled.div`
  width: 100%;
  min-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.226);
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PortalContainer = ({
  isOpen,
  children,
  handleClickOutside = () => {}
}) => {
  const isMouseDown = useRef(false);
  const blockClick = useRef(false);

  const handleMouseMove = () => {
    if (isMouseDown.current) {
      blockClick.current = true;
    }
  };

  const handleMouseDown = () => {
    isMouseDown.current = true;
    blockClick.current = false;
  };
  const handleClick = () => {
    isMouseDown.current = false;
    if (blockClick.current) {
      blockClick.current = false;
    } else {
      handleClickOutside();
    }
  };

  if (!isOpen) {
    return null;
  }
  return ReactDom.createPortal(
    <Overlay
      onClick={handleClick}
      onMouseMove={handleMouseMove}
      onMouseDown={handleMouseDown}
    >
      {children}
    </Overlay>,
    document.getElementById('portal')
  );
};

export default PortalContainer;

const blankRoleInfo = {
  id: null,
  roleName: '',
  roleDesc: '',
  applications: [],
  permissions: [],
  capabilities: [],
  oldApplications: [],
  subscriptionAppCapabilityProperties: [],
  licenseManagements: [],
  adminAccess: false
};

export const getRoleInfo = roleDTO => {
  if (!roleDTO) {
    return blankRoleInfo;
  }

  const {
    id,
    roleName,
    description = '',
    applicationCapabilities,
    applications: oldApplications,
    subscriptionAppCapabilityProperties = [],
    licenseManagements = []
  } = roleDTO;
  const permissions = applicationCapabilities.filter(
    capability => capability.application.id === 6
  );
  const capabilities = applicationCapabilities.filter(capability => {
    const { appAPIName } = capability.application;
    switch (appAPIName) {
      case 'lyncwork':
      case 'aha':
        return false;
      default:
        return true;
    }
  });
  const applications = oldApplications.filter(app => app.id !== 6);
  const adminAccess = permissions.length > 0;

  return {
    id,
    roleName,
    description: description || '',
    permissions,
    capabilities,
    applications,
    oldApplications: [...applications],
    subscriptionAppCapabilityProperties,
    licenseManagements,
    adminAccess
  };
};

export const getDeletedApps = (oldApplications, applications) => {
  const newAppIds = applications.map(app => app.id);
  const deletedApps = oldApplications.filter(
    app => !newAppIds.includes(app.id)
  );

  return deletedApps;
};

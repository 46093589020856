import React from 'react';

import { CustomButton, CustomSearch } from 'components/NewLyncworkUIKit';
import ListTable from 'components/ListTable';
import Pagination from 'components/Pagination';

import { useButtonTrack } from 'matomo/hooks';

import { teamsListPageEvents } from '../teamsInstrumentationEvents';

import { Container, HeaderContainer, StyledLink, ActionRow } from './styles';

import useTeamsList from './useTeamsList';
import TableHeader from './TableHeader';
import TeamCard from './TeamCard';

const { add_team } = teamsListPageEvents;

const TeamsListPage = () => {
  const trackButton = useButtonTrack();
  const {
    teams,
    totalPages,
    page,
    inputValue,
    changePage,
    handleInputChange,
    loading,
    error
  } = useTeamsList();
  const handleNewTeamClick = () => trackButton(add_team);
  const hasContent = teams.length > 0;
  return (
    <Container>
      <HeaderContainer>
        <h1>Teams</h1>
        <ActionRow>
          <CustomSearch
            placeholder="Search Teams"
            value={inputValue}
            onChange={handleInputChange}
          />
          <StyledLink to="/admin-teams/add">
            <CustomButton size="medium" onClick={handleNewTeamClick}>
              + New Team
            </CustomButton>
          </StyledLink>
        </ActionRow>
      </HeaderContainer>
      <ListTable
        header={<TableHeader />}
        loading={loading}
        error={error}
        hasContent={hasContent}
      >
        {teams.map(team => (
          <TeamCard key={team.id} team={team} />
        ))}
      </ListTable>
      <Pagination
        totalPages={totalPages}
        currentPage={page + 1}
        changePage={changePage}
      />
    </Container>
  );
};

export default TeamsListPage;

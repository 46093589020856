export const closeMenuOnScroll = e => {
  const { className: targetClassName = '' } = e.target;
  const isSelect = targetClassName.includes('lw-select');
  if (isSelect) {
    e.stopPropagation();
    return false;
  }
  return true;
};

export const getOptionLabel = option => option.capability.replace('_', ' ');

export const initRoleSelection = (ahaProducts, ahaRoles) => {
  const roleSelection = {};
  const noneRole = ahaRoles.find(role => role.value === 'none');

  ahaProducts.forEach(product => {
    const productId = String(product.id);
    roleSelection[productId] = noneRole;
  });
  return roleSelection;
};

export const formatRoleSelection = roleSelection => {
  const subAppCapabilities = Object.entries(roleSelection).map(
    ([productId, applicationCapability]) => {
      const subCapability = {
        key: 'Product',
        value: productId,
        applicationCapabilityProperty: {
          name: 'Product',
          applicationCapability
        }
      };
      return subCapability;
    }
  );
  return subAppCapabilities;
};

import axios from 'axios';
import env from '../env';

const baseUrl = `${env.storeDomain}/api/application-categories`;

export const ApplicationCategoryService = {
  list: () => {
    return axios.get(`${baseUrl}`);
  }
};

import { useEffect, useState, useCallback } from 'react';
import { gql } from '@apollo/client';
import { useLazyQuery } from '@apollo/client';

import { useButtonTrack } from 'matomo/hooks';
import { rolesListPageEvents } from '../instrumentationEvents';

import debounce from 'utilities/debounce';

const { change_page } = rolesListPageEvents;

const GET_ROLES = gql`
  query GetRoles($name: String, $page: Int, $size: Int = 10) {
    roles(name: $name, page: $page, size: $size) {
      content {
        id
        roleName
        adminAccess
        applications {
          id
          appName
          appLogo
        }
        totalApplications
        users {
          id
          firstName
          lastName
          avatar {
            id
            url
            thumbnail
          }
        }
        totalUsers
      }
      totalPages
    }
  }
`;

const initialVariables = {
  name: '',
  page: 0
};

const defaultData = {
  roles: {
    content: [],
    totalPages: null
  }
};

const useRolesList = () => {
  const trackButton = useButtonTrack();
  const [variables, setVariables] = useState(initialVariables);
  const [inputValue, setInputValue] = useState('');
  const [totalPages, setTotalPages] = useState(0);

  const [
    getRoles,
    { loading, data = defaultData, error }
  ] = useLazyQuery(GET_ROLES, { fetchPolicy: 'network-only' });
  useEffect(() => {
    getRoles({ variables });
  }, [variables]);

  useEffect(() => {
    /**
     * when fetch is in progress, data gets reset to undefined and totalPages will be null.
     * thus we need another state to keep track of totalPages to prevent the pagination
     * component from disappearing
     */
    const { totalPages } = data.roles;
    if (totalPages !== null) {
      setTotalPages(data.roles.totalPages);
    }
  }, [data]);

  const { content: roles } = data.roles;

  const changePage = newPage => {
    trackButton(change_page);
    const newPageIndex = newPage - 1;
    setVariables(oldVar => ({ ...oldVar, page: newPageIndex }));
  };

  const updateNameVariable = useCallback(
    debounce(value => {
      setVariables(oldVar => {
        if (oldVar.name === value) {
          return oldVar;
        }
        setTotalPages(0);
        return { page: 0, name: value };
      });
    }, 500),
    []
  );

  const handleInputChange = e => {
    const value = e.target.value;
    setInputValue(value);
    updateNameVariable(value);
  };

  return {
    roles,
    totalPages,
    page: variables.page,
    inputValue,
    changePage,
    handleInputChange,
    loading,
    error
  };
};

export default useRolesList;

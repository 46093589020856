import React, { useState, useEffect } from 'react';

import { PageSettingService } from 'services';

import { Container } from './styles';

import {
  Overview,
  TabNavigation,
  WorkInfoTab,
  PersonalInfoTab
} from './components';

const workTabName = 'work';
const personalTabName = 'personal';

const UserDetailCard = ({ user }) => {
  const [activeTab, setActiveTab] = useState(workTabName);
  const [customFields, setCustomFields] = useState({
    LyncWorkUser: [],
    EmergencyContact: []
  });

  useEffect(() => {
    const getOtherInfo = async () => {
      try {
        const { data } = await PageSettingService.personalInfo();
        const { entitiesSettings } = data;
        const optionalFieldInfo = {};
        entitiesSettings.forEach(({ name, companyFields }) => {
          if (name === 'EmergencyContact') {
            const defaultEmergencyContactFields = [
              'firstName',
              'lastName',
              'phoneNumber'
            ];
            const emergencyContactFields = companyFields.filter(
              ({ name }) => !defaultEmergencyContactFields.includes(name)
            );
            optionalFieldInfo[name] = emergencyContactFields;
            return;
          }
          optionalFieldInfo[name] = companyFields;
        });
        setCustomFields(optionalFieldInfo);
      } catch (error) {
        console.log(error);
      }
    };

    getOtherInfo();
  }, []);
  const toggleTab = () => {
    setActiveTab(oldTab => {
      if (oldTab === workTabName) {
        return personalTabName;
      }
      return workTabName;
    });
  };

  if (!user) {
    return <Container />;
  }
  return (
    <Container>
      <Overview user={user} />
      <TabNavigation activeTab={activeTab} toggleTab={toggleTab} />
      {activeTab === workTabName ? (
        <WorkInfoTab user={user} />
      ) : (
        <PersonalInfoTab user={user} customFields={customFields} />
      )}
    </Container>
  );
};

export default UserDetailCard;

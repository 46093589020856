import { css } from 'styled-components';

// variants

const primary = css`
  color: ${props => props.theme.colors.neutral.white};
  background-color: ${props => props.theme.colors.neutral.grey08};
  &:hover:enabled {
    background-color: ${props => props.theme.colors.neutral.grey07};
  }
  &:active {
    background-color: ${props => props.theme.colors.neutral.grey10};
  }
  &:disabled {
    color: ${props => props.theme.colors.neutral.grey04};
    background-color: ${props => props.theme.colors.neutral.offWhite03};
  }
`;
const secondary = css`
  color: ${props => props.theme.colors.neutral.grey07};
  background-color: transparent;
  border-color: ${props => props.theme.colors.neutral.grey03};
  &:hover:enabled {
    background-color: ${props => props.theme.colors.neutral.offWhite01};
  }
  &:active {
    background-color: ${props => props.theme.colors.neutral.offWhite03};
  }
  &:disabled {
    color: ${props => props.theme.colors.neutral.grey04};
    background-color: ${props => props.theme.colors.neutral.offWhite03};
    border-color: transparent;
  }
`;

const tertiary = css`
  color: ${props => props.theme.colors.neutral.grey10};
  background-color: transparent;
  &:hover:enabled {
    background-color: ${props => props.theme.colors.neutral.offWhite03};
  }
  &:active {
    background-color: ${props => props.theme.colors.neutral.grey02};
  }
  &:disabled {
    color: ${props => props.theme.colors.neutral.grey04};
  }
`;

export const buttonVariants = {
  primary,
  secondary,
  tertiary
};

// sizes
const extraLarge = css`
  ${props => props.theme.typography.header3};
  font-weight: 600;
  height: 56px;
  padding: 16px 50px;
`;
const large = css`
  ${props => props.theme.typography.control};
  font-weight: 600;
  height: 48px;
  padding: 14px 46px;
`;
const medium = css`
  ${props => props.theme.typography.control};
  font-weight: 600;
  height: 36px;
  padding: 8px 24px;
`;
const small = css`
  ${props => props.theme.typography.metadata};
  font-weight: 600;
  height: 28px;
  padding: 6px 16px;
`;

export const buttonSizes = { extraLarge, large, medium, small };

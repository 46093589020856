import axios from 'axios';
import env from '../env';
const baseUrl = `${env.serviceDomain}/notification/api/notifications`;

export const NotificationService = {
  list: (name = '', page = '0', size = '20') => {
    return axios.get(
      `${baseUrl}?name=${name}&page=${page}&size=${size}&sort=id%2Cdesc`
    );
  },
  setClicked: id => {
    return axios.put(`${baseUrl}/clicked/${id}`);
  },
  setSeen: ids => {
    const searchParams = new URLSearchParams({
      ids
    });
    const queryString = searchParams.toString();

    return axios.put(`${baseUrl}/seen/?${queryString}`);
  }
};

import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import colors from 'designSystem/foundations/colors';

export const HiddenCounter = styled.div`
  width: 24px;
  height: 24px;
  margin: 0 0 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.theme.typography.control};
  background-color: ${colors.neutral.offWhite03};
  border-radius: 50%;
  color: ${colors.neutral.grey07};
`;

const featuredStyle = css`
  background-color: ${colors.feedback.confirmation}20;
  color: ${colors.feedback.confirmation};
`;
export const FeaturedStatus = styled.div`
  width: 40px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  background-color: ${colors.neutral.grey01};
  color: ${colors.neutral.grey05};
  ${props => props.theme.typography.control};
  font-weight: 500;
  ${props => props.featured && featuredStyle};
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  margin: 0 24px 0 auto;
`;

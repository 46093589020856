import styled from 'styled-components';
import { UserHelpSection } from '../../styles';

export const PopularTopicsContainer = styled(UserHelpSection)`
  width: 100%;
  box-sizing: border-box;
  padding: 6% 5%;
  margin-top: 40px;
`;

export const TopicItemContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding: 20px 30px;
  border: 1px solid #aab2bb;
  border-radius: 4px;
  cursor: pointer;
`;

export const TopicItemIcon = styled.div`
  filter: grayscale(1);
  width: 60px;
  & img {
    width: 100%;
  }
`;

export const TopicItemContent = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-left: 30px;
`;

export const TopicItemTitle = styled.h4`
  color: #ff6b6b;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
`;

export const TopicItemDescription = styled.p`
  color: #4d4d4d;
  margin: 10px 0 0 0;
`;

export const TopicItemUpdatedAt = styled.p`
  color: #aab2bb;
  margin-top: 5px;
  margin: 10px 0 0 0;
`;

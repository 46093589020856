import styled, { css } from 'styled-components';

const getBackgroundImage = strokeColor => {
  const sanitizeStrokeColor = strokeColor.replace('#', '%23');
  return `data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='${sanitizeStrokeColor}' stroke-width='2' stroke-dasharray='12' stroke-dashoffset='12' stroke-linecap='round'/%3e%3c/svg%3e`;
};
const backgroundImg = css`
  background-image: url("${props =>
    getBackgroundImage(props.theme.colors.brand.primary01)}");
`;

export const Container = styled.div`
  flex-basis: 55%;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  margin-top: 0;
  ${props => props.theme.typography.header2};
  font-weight: 600;
`;

export const ZoneContainer = styled.div`
  ${({ noSetHeight }) => (!noSetHeight ? 'height:300px;' : 'height:100%;')}
  display: flex;
  justify-content: center;
  align-items: center;
  ${backgroundImg}
`;

export const DropZoneContainer = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey06};
  ${({ noSetHeight }) => noSetHeight && '  padding: 1em 0;'}

  img {
    width: 72px;
    height: auto;
    margin-bottom: 12px;
  }

  &:hover {
    background-color: ${props => props.theme.colors.brand.primary01}20;
  }
`;

export const AcceptedFileType = styled.span`
  margin-top: 8px;
  align-self: center;
  ${props => props.theme.typography.footnote};
  color: ${props => props.theme.colors.neutral.grey06};
`;

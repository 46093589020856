import styled from 'styled-components';
import { grey, white, orange } from 'StyleVariables/LyncworkColors';
export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const Row = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(${({ colWidth }) => (colWidth ? colWidth : '200px')}, 1fr)
  );
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: 1em;
  width: 100%;
  color: #8998aa;
  margin-top: ${props => props.topMargin && '0.5em'};
  .MuiCheckbox-root {
    color: #8998aa;
  }
`;
export const Flex = styled.div`
  flex: 1;
  /* margin-right: ${props => (props.noMargin ? '0' : '1em')}; */
`;
export const Title = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.2px;
  margin: 0;
  padding-bottom: 1em;
  color: #8998aa;
`;
export const Label = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.2px;
  margin: 0;
  padding-top: 2em;
  color: #8998aa;
`;
export const DDLabel = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.2px;
  margin: 0;
  padding-bottom: 0.25em;
  color: ${orange};
`;

import React from 'react';
import useQuery from 'hooks/useSearchQuery';
import PostList from '../PostList';
import SearchHeader from './SearchHeader';
import { SearchContainer } from './styles';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import { TopicContainer } from '../Topic/styles';

const Search = () => {
  const query = useQuery();

  const posts = [
    {
      title: 'G Suite Integration',
      description:
        'This document explains how to set up your Google Cloud account to integrate with the LyncWork Application.',
      updatedAt: '01/01/2020',
      path: '/help/post/1'
    },
    {
      title: 'Dropbox Integration',
      description:
        'Step-by-step guide on how to integrate your Dropbox account into Lyncwork.',
      updatedAt: '01/01/2020',
      path: '/help/post/2'
    }
  ];

  return (
    <SearchContainer>
      <Breadcrumbs
        items={[
          {
            text: 'Help',
            path: '/help'
          },
          {
            text: 'Search Results'
          }
        ]}
      />
      <SearchHeader term={query.get('q')} />
      <PostList items={posts} viewMore={() => {}} />
    </SearchContainer>
  );
};

export default Search;

import styled from 'styled-components';
import Card from 'components/Card';
import { multilineEllipsis } from 'StyleVariables/StandardComponents';

export const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  min-height: 250px;

  grid-row: 2/4;
  grid-column: 1/2;
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 0 24px 0;
  h1 {
    margin: 0;
    ${props => props.theme.typography.header2};
    color: ${props => props.theme.colors.neutral.grey10};
    font-weight: 600;
  }

  a {
    text-decoration: none;
  }
`;

export const JourneysContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 80px;
  width: 100%;
  .MuiCircularProgress-root {
    margin: 36px;
  }

  .empty-message {
    margin: 36px;
    ${props => props.theme.typography.body};
    font-weight: 600;
    color: ${props => props.theme.colors.neutral.grey10};
  }
`;

import React from 'react';

import { format, isValid, isBefore, isAfter } from 'date-fns';

import {
  RowContainer,
  JourneyName,
  JourneyProgress,
  JourneyDue,
  ProgressBar,
  OverdueNotice
} from './styles';

const formatDate = date => {
  const convertedDate = new Date(date);
  if (!isValid(convertedDate)) {
    return '';
  }
  const dateFormat = `MMM d, yyyy`;
  return format(convertedDate, dateFormat);
};

const JourneyRow = ({ journey }) => {
  const {
    workflowName,
    taskWork: { completedTasks, total, lastDueDate }
  } = journey;

  const barWidth = `${Math.floor((completedTasks * 100) / total)}%`;

  const date = formatDate(lastDueDate);
  const currentDate = new Date();
  const isPastDue = isBefore(currentDate, new Date(lastDueDate));

  return (
    <RowContainer>
      <JourneyName>{workflowName}</JourneyName>
      <JourneyProgress>
        <ProgressBar barWidth={barWidth} />
        <span>
          {completedTasks}/{total}
        </span>
      </JourneyProgress>
      <JourneyDue>{ isPastDue ? date : <OverdueNotice> Past Due </OverdueNotice>}</JourneyDue>
    </RowContainer>
  );
};

export default JourneyRow;

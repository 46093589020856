import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CustomButton } from 'components/NewLyncworkUIKit';
import {
  Container,
  TaskTitle,
  Description,
  Row,
  MetaData,
  ProgressBar,
  StyledCheckMark,
  CompletedBox
} from './styles';
import { getDateInfo } from './utilities';

const Completion = ({ completed, disabled, loading, handleClick }) => {
  return (
    <CompletedBox>
      {completed ? (
        <>
          <StyledCheckMark /> Completed
        </>
      ) : (
        <CustomButton
          size="medium"
          width="120px"
          disabled={disabled || loading}
          onClick={handleClick}
          data-tutorial-id="complete-task-button"
        >
          {loading ? (
            <CircularProgress size="20px" color="inherit" />
          ) : (
            'Complete'
          )}
        </CustomButton>
      )}
    </CompletedBox>
  );
};

const TaskListCard = ({ task, taskIdInProgress, completeTask }) => {
  const {
    id,
    task: { taskEventName, taskDesc },
    schedDueDate,
    schedStartDate,
    actualCompleteDate
  } = task;
  const completed = !!actualCompleteDate;

  const { dateText, canStart } = getDateInfo({ schedDueDate, schedStartDate });

  const otherTaskInProgress = taskIdInProgress !== null;

  const disabled = otherTaskInProgress || !canStart;
  const loading = taskIdInProgress === id;

  const handleClick = () => completeTask(id);
  return (
    <Container data-task-id={id} data-tutorial-id="journey-task-card">
      <Row>
        <TaskTitle>{taskEventName}</TaskTitle>
        <MetaData className="bold">{dateText}</MetaData>
      </Row>
      <Description>{taskDesc}</Description>
      <Completion
        completed={completed}
        disabled={disabled}
        loading={loading}
        handleClick={handleClick}
      />
    </Container>
  );
};

export default TaskListCard;

import React from 'react';
import AdminSetupCompanyInfo from 'pages/Onboarding/AdminSetup/pages/CompanyInfo';
import AdminSetupDone from 'pages/Onboarding/AdminSetup/pages/Done';
import AdminSetupEmailIntegration from 'pages/Onboarding/AdminSetup/pages/EmailIntegration';
import AdminSetupPersonalInfo from 'pages/Onboarding/AdminSetup/pages/PersonalInfo';
import AdminSetupWelcome from 'pages/Onboarding/AdminSetup/pages/Welcome';
import imageEndOfOnboarding from 'assets/images/Onboarding/EndOfOnboarding.svg';
import imageIntro from 'assets/images/Onboarding/Intro.svg';
import imagePasswordSetup from 'assets/images/Onboarding/PasswordSetup.svg';
import {
  DocumentsDescription,
  WelcomeDescription,
  EmailDescription
} from 'pages/Onboarding/common/SideBarDescriptions';

const getPageData = user => [
  {
    pathName: '/admin-setup',
    Component: AdminSetupWelcome,
    progress: {
      title: 'Welcome'
    },
    sideBar: {
      title: `Hey ${user.firstName || 'Valued Employee'}!`,
      imgSrc: imageIntro,
      description: <WelcomeDescription />
    }
  },
  {
    pathName: '/admin-setup/personal-information',
    Component: AdminSetupPersonalInfo,
    progress: {
      title: 'Personal Information'
    },
    sideBar: {
      title: 'Please Fill Out Your Personal Information',
      imgSrc: imagePasswordSetup,
      description:
        'Provide the following information to complete your personal profile.'
    }
  },
  {
    pathName: '/admin-setup/company-information',
    Component: AdminSetupCompanyInfo,
    progress: {
      title: 'Company Information'
    },
    sideBar: {
      title: 'Please Fill Out Your Company Information',
      imgSrc: imagePasswordSetup,
      description:
        'Provide the following information to complete your company profile.'
    }
  },
  {
    pathName: '/admin-setup/email-integration',
    Component: AdminSetupEmailIntegration,
    progress: {
      title: 'Email Integration'
    },
    sideBar: {
      title: 'Integrate Your Email Service with Lyncwork',
      description: <EmailDescription />
    }
  },
  {
    pathName: '/admin-setup/done',
    Component: AdminSetupDone,
    progress: {
      title: 'Complete'
    },
    sideBar: {
      title: 'All Done!',
      imgSrc: imageEndOfOnboarding,
      description:
        'Great job on completing your onboarding with us! You are now ready to start using Lyncwork. We look forward to do amazing work with you!'
    }
  }
];

export default getPageData;

import React from 'react';
import { Label, SubLabel, CustomSelect } from 'components/NewLyncworkUIKit';

import { useAccountDetailsForm } from '../accountDetailsFormContext';
import { SelectContainer } from './styles';

const SelectField = ({ name, label, required, options }) => {
  const { formInfo, updateForm, formError } = useAccountDetailsForm();
  const value = formInfo[name];
  const subLabel = formError[name];
  const error = !!subLabel;
  const selectedOption = options.find(a => a.value === value) || null;
  const handleChange = selectedOption => {
    const newValue = selectedOption?.value || '';
    updateForm(name, newValue);
  };

  return (
    <SelectContainer>
      <Label>
        {label}
        {required && <span>*</span>}
      </Label>
      <CustomSelect
        name={name}
        onChange={handleChange}
        value={selectedOption}
        options={options}
      />
      {subLabel && <SubLabel error={error}>{subLabel}</SubLabel>}
    </SelectContainer>
  );
};
export default SelectField;

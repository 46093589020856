import styled from 'styled-components';

import Card from 'components/Card';

export const Container = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 20px;
  padding: 24px;
  width: 660px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0;
  h1 {
    ${props => props.theme.typography.header3};
    font-weight: 600;
    margin: 0;
  }
  button {
    font-size: 1.5rem;
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  &.bottom-divider {
    padding: 0 0 20px;
    border-bottom: 1px solid ${props => props.theme.colors.neutral.grey02};
  }
`;

export const Description = styled.p`
  margin: 0;
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.neutral.grey08};
`;

export const SectionTitle = styled.span`
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.neutral.grey08};
  font-weight: 600;
`;

export const OptionContainer = styled.button`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background: white;
  gap: 4px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.neutral.grey05};

  cursor: pointer;

  .option-title {
    ${props => props.theme.typography.body};
    color: ${props => props.theme.colors.neutral.grey08};
    font-weight: 500;
  }

  .option-recommended {
    ${props => props.theme.typography.metadata};
    color: ${props => props.theme.colors.neutral.grey06};
    font-weight: 600;
  }

  .option-description {
    flex-basis: 100%;
    text-align: left;
    ${props => props.theme.typography.control};
    color: ${props => props.theme.colors.neutral.grey07};
  }
`;

export const StyledList = styled.ul`
  margin: 0;
  padding: 0 0 0 20px;
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.neutral.grey08};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

import React from 'react';
import Button from 'components/NewLyncworkUIKit/CustomButton';
import { HeaderRow, HeaderCell, Row, Cell } from 'components/ListTable';

import { HiddenCounter, FeaturedStatus, StyledLink } from './styles';

export const TableHeader = () => {
  return (
    <HeaderRow>
      <HeaderCell flex={3}>Article Title</HeaderCell>
      <HeaderCell flex={1}>Featured</HeaderCell>
      <HeaderCell flex={2}>Category</HeaderCell>
      <HeaderCell flex={2}>Team Audience</HeaderCell>
      <HeaderCell flex={2}>Role Audience</HeaderCell>
      <HeaderCell flex={1} />
    </HeaderRow>
  );
};

const PostPermission = ({ list, name = 'Roles' }) => {
  const length = list.length;
  if (length < 1) {
    return `All ${name}`;
  }

  const key = name === 'Roles' ? 'roleName' : 'teamName';
  const nameOfFirstItem = list[0][key];

  return (
    <>
      {nameOfFirstItem}{' '}
      {length > 1 && <HiddenCounter>+{length - 1}</HiddenCounter>}
    </>
  );
};

const PostCard = ({ post }) => {
  const { title, category, role_permissions, team_permissions, featured, id } =
    post;
  const categoryName = category?.name;

  return (
    <Row>
      <Cell flex={3} className="list-row-name">
        {title}
      </Cell>
      <Cell flex={1}>
        <FeaturedStatus featured={featured}>
          {featured ? 'On' : 'Off'}
        </FeaturedStatus>
      </Cell>
      <Cell flex={2}>{categoryName}</Cell>
      <Cell flex={2}>
        <PostPermission list={team_permissions} name="Teams" />
      </Cell>
      <Cell flex={2}>
        <PostPermission list={role_permissions} name="Roles" />
      </Cell>
      <Cell flex={1}>
        <StyledLink to={`/admin-community/posts/edit/${id}`}>
          <Button variant="secondary" size="small">
            Edit
          </Button>
        </StyledLink>
      </Cell>
    </Row>
  );
};

export default PostCard;

import axios from 'axios';

export const registerAuthInterceptor = store => {
  axios.interceptors.request.use(config => {
    const state = store.getState();
    const authToken = state.authentication.idToken;
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  });
};

import styled from 'styled-components';
import { CustomButton } from 'components/NewLyncworkUIKit';
import Card from 'components/Card';

export const Container = styled(Card)`
  padding: 32px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 16px;
`;

export const SectionDescription = styled.div`
  flex-basis: 470px;
  flex-grow: 0;
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  gap: 12px;

  h2 {
    ${props => props.theme.typography.header3};
    color: ${props => props.theme.colors.neutral.grey10};
    font-weight: 600;
    margin: 0;
  }

  p {
    margin: 0;
    ${props => props.theme.typography.body};
    color: ${props => props.theme.colors.neutral.grey07};
  }
`;

export const DocumentContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin-right: 200px;
  width: 400px;
  height: 520px;
`;

export const DocumentTitle = styled.p`
  align-items: center;
  margin-left: 3px;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const DocumentInfo = styled.div`
  display: flex;
  margin: 5px;
`;

export const SectionContainer = styled.div`
  display: flex;
  padding: 32px 0;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.grey02};
  gap: 56px;
  justify-content: space-between;
  &:last-child {
    border-bottom: none;
  }
`;

export const DocumentsContainer = styled.div`
  height: 100%;
`;
export const PaginationContainer = styled.div`
  margin-top: 50px;
`;

export const Header = styled.h2`
  ${props => props.theme.typography.header3};
  font-weight: 600;
  margin: 0;
`;

export const Desc = styled.p`
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey07};
  margin: 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin: 32px 0 32px;
`;

export const DocumentsButton = styled(CustomButton)`
  height: 20px;
  width: 160px;
`;

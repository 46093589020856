import React from 'react';

import imageIceBreaker from 'assets/images/Onboarding/IceBreaker.svg';
import { CustomButton } from 'components/NewLyncworkUIKit';

import { Container, StyledLink } from './styles';

const Community = () => {
  return (
    <Container>
      <span>Check out the latest community posts and articles!</span>
      <StyledLink to="/community">
        <CustomButton size="small">Go</CustomButton>
      </StyledLink>
      <img src={imageIceBreaker} />
    </Container>
  );
};

export default Community;

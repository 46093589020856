import React from 'react';
import { Route, Switch } from 'react-router-dom';

import TeamsDetailsPage from './TeamDetails';
import TeamsListPage from './TeamsListPage';

const TeamsPage = () => {
  return (
    <Switch>
      <Route path="/admin-teams" exact>
        <TeamsListPage />
      </Route>
      <Route path="/admin-teams/edit/:id">
        <TeamsDetailsPage />
      </Route>
      <Route path="/admin-teams/add">
        <TeamsDetailsPage />
      </Route>
    </Switch>
  );
};

export default TeamsPage;

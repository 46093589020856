import { useState, useCallback } from 'react';

import debounce from 'utilities/debounce';
import { UserService } from 'services/user.service';

const arr = ['id', 'firstName', 'lastName', 'roles', 'username', 'avatar'];

const size = 5;
const teamId = '';

const initialFetchState = {
  name: '',
  page: 0,
  hasMore: true,
  loading: false,
  users: []
};

const useUsers = roleId => {
  const [fetchState, setFetchState] = useState(initialFetchState);
  const [searchQuery, setSearchQuery] = useState('');
  const { name, page, users, hasMore, loading } = fetchState;

  const updateSearchQuery = useCallback(
    debounce(
      value =>
        setFetchState({
          name: value,
          page: 0,
          hasMore: true,
          loading: false,
          users: []
        }),
      500
    ),
    []
  );

  const loadMore = async () => {
    if (loading) {
      return;
    }
    setFetchState(oldState => ({ ...oldState, loading: true }));

    try {
      const response = await UserService.list(
        name,
        page,
        size,
        arr,
        teamId,
        roleId,
        ''
      );
      const { data } = response;
      const { content: users, last } = data;
      setFetchState(oldState => {
        const { users: oldUsers } = oldState;
        return {
          name,
          page: page + 1,
          hasMore: !last,
          loading: false,
          users: [...oldUsers, ...users]
        };
      });
    } catch (error) {
      setFetchState(oldState => ({ ...oldState, loading: false }));
      console.log(error);
    }
  };

  const handleInputChange = e => {
    const value = e.target.value;
    setSearchQuery(value);
    updateSearchQuery(value);
  };
  return {
    searchQuery,
    name,
    page,
    users,
    hasMore,
    loadMore,
    loading,
    handleInputChange
  };
};

export default useUsers;

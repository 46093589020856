import React from 'react';
import { HeaderRow, HeaderCell } from 'components/ListTable';
import { StyledCheckbox } from './styles';

const TableHeader = ({ checked, handleSelectAll }) => (
  <HeaderRow>
    <StyledCheckbox
      name="allFiles"
      onChange={handleSelectAll}
      checked={checked}
    />
    <HeaderCell flex={2}>Name</HeaderCell>
    <HeaderCell flex={2}>Uploaded By</HeaderCell>
    <HeaderCell flex={3}>Signed Date</HeaderCell>
  </HeaderRow>
);

export default TableHeader;

import styled from 'styled-components';
import { multilineEllipsis } from 'StyleVariables/StandardComponents';
import { colors } from 'designSystem/foundations';
import { Avatar } from 'components/NewLyncworkUIKit';
import Card from 'components/Card';

const {
  neutral: { offWhite02, offWhite03, grey10, grey07, grey06, grey03, grey02 }
} = colors;

export const TableContainer = styled(Card)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  box-sizing: border-box;
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.small}) {
    padding: 0 16px;
    box-sizing: border-box;
  }
`;

const itemsPerPage = 7;
export const ListContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(96px * ${itemsPerPage});
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.small}) {
    height: unset;
  }
`;

export const BlankContainer = styled(ListContainer)`
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 24px;
  img {
    width: 150px;
    height: 150px;
    object-fit: contain;
    margin-bottom: 16px;
  }
  p {
    margin: 0;
    max-width: 240px;
    text-align: center;
  }
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.small}) {
    height: 200px;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  height: 96px;
  width: 100%;
  ${props => props.theme.typography.control};
  color: ${grey10};
  border-bottom: 1px solid ${grey02};
  cursor: pointer;
  &:last-child {
    border-color: transparent;
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.small}) {
    display: none;
  }
`;

export const HeaderRow = styled(Row)`
  height: 44px;
  border-radius: 8px;
  background: ${offWhite02};
  font-weight: 500;
  color: ${grey07};
  cursor: unset;
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.small}) {
    display: none;
  }
`;

export const Cell = styled.div`
  box-sizing: border-box;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:first-child {
    flex: 4;
    width: 40%;
    gap: 16px;
  }
  &:nth-child(2) {
    flex: 3;
    width: 30%;
    gap: 8px;
  }
  &:last-child {
    flex: 3;
    width: 30%;
  }
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.small}) {
    display: none;
  }
`;

export const UserMainInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  .user-name {
    color: ${grey10};
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .user-roles {
    display: flex;
    align-items: center;
    gap: 8px;
    ${props => props.theme.typography.metadata};
  }
`;

export const StyledAvatar = styled(Avatar)`
  width: 48px;
  height: 48px;
`;

export const MobileRow = styled.div`
  display: none;
  width: 100%;
  height: 116px;

  ${props => props.theme.typography.control};
  color: ${grey07};
  border-top: 1px solid ${grey02};
  cursor: pointer;
  align-items: center;
  gap: 16px;

  &:first-child {
    border-color: transparent;
  }
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.small}) {
    display: flex;
  }
`;

export const MobileRowDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  strong {
    color: ${props => props.theme.colors.neutral.grey08};
  }
`;

export const MobileUserDetail = styled.span`
  strong {
    color: ${props => props.theme.colors.neutral.grey08};
  }
  ${multilineEllipsis}
`;

MobileUserDetail.defaultProps = { lineClamp: 1 };

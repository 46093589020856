import styled from 'styled-components';
import colors from 'designSystem/foundations/colors';

import Card from 'components/Card';

const {
  neutral: { grey01, grey07, grey10 }
} = colors;

export const Container = styled(Card)`
  position: relative;
  box-sizing: border-box;
  width: 760px;
  height: 720px;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
`;

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 240px;
  flex-shrink: 0;
  height: 100%;
  border-right: 1px solid ${props => props.theme.colors.neutral.grey02};
  h2 {
    ${props => props.theme.typography.header2};
    font-weight: 600;
    margin: 24px 0 32px 24px;
    color: ${props => props.theme.colors.neutral.grey10};
  }
`;

export const ApplicationCategoryButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 52px;
  padding-left: 24px;
  color: ${props => (props.selected ? grey10 : grey07)};
  background-color: ${props => (props.selected ? grey01 : 'transparent')};
  ${props => props.theme.typography.control};
  font-weight: 600;
  &:hover,
  &:focus-visible {
    color: ${grey10};
    background-color: ${grey01};
  }
`;

export const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  box-sizing: border-box;
  height: 100%;
  flex-direction: column;
  align-items: stretch;
  padding: 24px;
`;

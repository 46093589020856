import React, { createContext, useContext } from 'react';
import PageTransitionBlock from 'components/PageTransitionBlock';

import useRoleFormEdit from './useRoleFormEdit';

const formContext = createContext();

export const useRoleForm = () => useContext(formContext);

const RoleFormContext = ({ roleDTO, children }) => {
  const value = useRoleFormEdit(roleDTO);
  const { blockTransition } = value;
  return (
    <formContext.Provider value={value}>
      <PageTransitionBlock when={blockTransition} />
      {children}
    </formContext.Provider>
  );
};

export default RoleFormContext;

import React from 'react';
import parse from 'html-react-parser';
import { P, Category, H2 } from './styles';
const colorOptions = ['#4a90e2', '#50e3c2', '#f5a623', '#9013fe', '#ff6b6b'];

const getTitle = content => {
  //this includes style attributes
  const firstIncompleteTag = content.split('>')[0];

  //e.g <h2 width="50px">
  const openingTag = `${firstIncompleteTag}>`;

  //e.g <h2
  const firstTagName = firstIncompleteTag.split(' ')[0];

  //<h2>
  const firstTag = `${firstTagName}>`;
  const updatedBy = '<br/> <strong>Updated By: </strong>';
  const firstClosingTag = firstTag.replace('<', '</');
  const titleWithOpeningTag = content.split(firstClosingTag)[0] || '';
  const title = titleWithOpeningTag
    .replace(openingTag, '')
    .replaceAll('<br>', '');
  return title;
};

const PostHeader = ({ data }) => {
  const { content } = data;
  const title = getTitle(content);
  return (
    <>
      {data.category && (
        <Category colorOptions={colorOptions} id={data.category.id}>
          {data.category.name}
        </Category>
      )}
      {title && <H2>{parse(title)}</H2>}
      {data.created_at && data.updated_at && data.author && (
        //conditionally render update date to posts with changes
        <P>
          <strong>Posted By: {`${data.author} ·`} </strong>{' '}
          {`${data.created_at.split('T')[0]}`}
          {data.updated_at !== data.created_at ? (
            <div>Last Updated · {data.updated_at.split('T')[0]}</div>
          ) : (
            ''
          )}
        </P>
      )}
    </>
  );
};
export default PostHeader;

import styled from 'styled-components';
import {
  textBlack,
  white,
  borderGrey,
  orange,
  grey
} from 'StyleVariables/LyncworkColors';

export const Container = styled.div`
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 10px 12px;
  color: #aeaeb1;
  border: 1px solid #aeaeb1;
  border-radius: 4px;
  background-color: ${props =>
    props.disabled ? props.theme.colors.neutral.offWhite02 : '#ffffff'};
`;

const hoverGrey = `${grey}30`;

export const CalendarIcon = styled.button`
  appearance: none;
  border: none;
  outline: none;
  cursor: ${props => !props.disabled && 'pointer'};
  color: ${grey};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border-radius: 50%;
  &:hover,
  &:focus-visible {
    background-color: ${props => !props.disabled && hoverGrey};
  }
`;

export const StyledInput = styled.input`
  border: none;
  outline: none;
  font-family: Arial;
  font-size: 14px;
  color: ${textBlack};
  flex-grow: 1;
`;

export const ErrorMessage = styled.span`
  color: ${orange};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  margin: 5px;
`;

import React from 'react';
import iconPerson from 'assets/images/page-icons/icon-person.png';

import {
  Container,
  BoardTitle,
  WinnerContainer,
  Avatar,
  AvatarContainer,
  WinnerName,
  WinnerPoints,
  LeaderListContainer,
  LeaderContainer,
  LeaderName
} from './styles';
const fakeData = [
  {
    firstName: 'Eduardo',
    lastName: 'Pinheiro',
    points: 110
  },
  {
    firstName: 'Justin',
    lastName: 'Seto',
    points: 412
  },
  {
    firstName: 'Lucas',
    lastName: 'Barroso',
    points: 250
  },
  {
    firstName: 'Varinder',
    lastName: 'Singh',
    points: 250
  },
  {
    firstName: 'Ash',
    lastName: 'Kumar',
    points: 999
  }
];

const Winner = ({ winner }) => {
  const { firstName, lastName, points, avatar } = winner;
  const name = `${firstName} ${lastName}`;

  return (
    <WinnerContainer>
      <Avatar src={avatar || iconPerson} alt="avatar" />
      <WinnerName>{name}</WinnerName>
      <WinnerPoints>{points} points</WinnerPoints>
    </WinnerContainer>
  );
};

const Leader = ({ leader, rank }) => {
  const { firstName, lastName, points } = leader;
  const name = `${firstName} ${lastName}`;
  return (
    <LeaderContainer>
      <span>{rank}. </span> <LeaderName>{name}</LeaderName>
      <span> {points} points</span>
    </LeaderContainer>
  );
};

const RewardsLeaderBoard = ({ data = fakeData }) => {
  if (!data.length > 0) return null;
  const sortedData = data.sort((a, b) => {
    return b.points - a.points;
  });

  const [winner, ...otherLeaders] = sortedData;
  return (
    <Container>
      <BoardTitle>Leaderboard</BoardTitle>
      <Winner winner={winner} />
      <LeaderListContainer>
        {otherLeaders.map((leader, index) => {
          const rank = index + 2;
          return <Leader key={index} rank={rank} leader={leader} />;
        })}
      </LeaderListContainer>
    </Container>
  );
};

export default RewardsLeaderBoard;

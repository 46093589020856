import React from 'react';
import LyncworkLogo from 'assets/images/Onboarding/lyncworklogo.svg';
import SideBarContent from 'pages/Onboarding/common/SideBarContent';
import {
  SideBar,
  SideBarHeader,
  SideBarLogo,
  SideBarContentContainer
} from './styles';

const SideBarDesktop = ({ pageData }) => {
  return (
    <SideBar>
      <SideBarHeader>
        <SideBarLogo src={LyncworkLogo} />
      </SideBarHeader>
      <SideBarContentContainer>
        <SideBarContent pageData={pageData} />
      </SideBarContentContainer>
    </SideBar>
  );
};
export default SideBarDesktop;

import { useState } from 'react';

const blankTaskInfo = {
  order: null,
  taskEventName: '',
  taskDesc: '',
  taskType: 'EVENT',
  startDay: 1,
  endDay: 1
};

const initialValidationMessage = {
  taskEventName: '',
  taskDesc: '',
  date: ''
};

export const convertToTaskInfo = task => {
  const {
    order,
    task: { taskEventName, taskDesc, taskType, startDay, endDay }
  } = task;
  return {
    order,
    taskEventName,
    taskDesc,
    taskType,
    startDay,
    endDay
  };
};

const generateDateMessage = (startDay, endDay) => {
  const formattedStart = Number(startDay);
  const formattedEnd = Number(endDay);
  if (!formattedStart > 0 || !formattedEnd > 0) {
    return 'Please provide valid days.';
  }

  if (formattedEnd < formattedStart) {
    return 'End day must be before start day.';
  }
  return '';
};

const generateValidationMessage = taskInfo => {
  const { taskEventName, taskDesc, startDay, endDay } = taskInfo;
  const nameMessage = !taskEventName ? 'Name is required.' : '';
  const descriptionMessage = !taskDesc ? 'Description is required.' : '';
  const dateMessage = generateDateMessage(startDay, endDay);
  const validationMessage = {
    taskEventName: nameMessage,
    taskDesc: descriptionMessage,
    date: dateMessage
  };
  const hasError = nameMessage || descriptionMessage || dateMessage;
  return { hasError, validationMessage };
};

const useTaskModal = () => {
  const [taskInfo, setTaskInfo] = useState(null);
  const [validationMessage, setValidationMessage] = useState(
    initialValidationMessage
  );
  const editTaskParams = (value, paramName) => {
    setTaskInfo(oldInfo => ({ ...oldInfo, [paramName]: value }));
    switch (paramName) {
      case 'taskEventName':
      case 'taskDesc':
        setValidationMessage(oldMsg => ({ ...oldMsg, [paramName]: '' }));
        return;
      case 'startDay':
      case 'endDay':
        setValidationMessage(oldMsg => ({ ...oldMsg, date: '' }));
        return;
      default:
        return;
    }
  };

  const isValid = () => {
    const validation = generateValidationMessage(taskInfo);
    if (validation.hasError) {
      setValidationMessage(validation.validationMessage);
      return false;
    }
    return true;
  };

  const reset = () => {
    setTaskInfo(null);
    setValidationMessage(initialValidationMessage);
  };

  const openModal = (newTaskInfo = blankTaskInfo) => setTaskInfo(newTaskInfo);

  return {
    taskInfo,
    validationMessage,
    editTaskParams,
    isValid,
    reset,
    openModal
  };
};

export default useTaskModal;

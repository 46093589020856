import axios from 'axios';
import env from '../env';

const baseUrl = `${env.storeDomain}/api/subscription-apps`;

export const SubscriptionAppService = {
  list: (name = '', page = '0', size = '10', category = '') => {
    return axios.get(
      `${baseUrl}?name=${name}&page=${page}&size=${size}&category=${category}`
    );
  },

  listByAppIds: applicationids => {
    const searchParams = new URLSearchParams({ applicationids }).toString();
    return axios.get(`${baseUrl}/byapplicationids?${searchParams}`);
  },
  create: data => {
    return axios.post(`${baseUrl}`, data);
  },

  update: data => {
    return axios.put(`${baseUrl}`, data);
  },

  delete: id => {
    return axios.delete(`${baseUrl}/${id}`);
  },

  find: id => {
    return axios.get(`${baseUrl}/${id}`);
  },
  getAll: () => axios.get(`${baseUrl}`)
};

import React from 'react';

import getTimeDifference from 'utilities/getTimeDifference';

import {
  NotificationPanel,
  ListContainer,
  NotificationContainer
} from './styles';

const getUrlPathname = link => {
  try {
    const { pathname } = new URL(link);
    return pathname;
  } catch (error) {
    console.log(error);
    return '';
  }
};

const Notification = ({ notification, onClick }) => {
  const { link, clicked, type, description, sysCreationDate } = notification;
  const pathname = getUrlPathname(notification.link);

  const notificationType = type.replace('_', ' ').toLocaleLowerCase();

  const timeDifference = sysCreationDate
    ? getTimeDifference(sysCreationDate)
    : null;

  return (
    <NotificationContainer
      to={pathname}
      $linkClicked={clicked}
      onClick={onClick}
    >
      <h3>{notificationType}</h3>
      <p>{description}</p>
      <span>{timeDifference}</span>
    </NotificationContainer>
  );
};

const NotificationList = ({ notifications, clickNotification }) => {
  return (
    <NotificationPanel>
      <h2>Notifications</h2>
      <ListContainer>
        {notifications.map(notification => {
          const { id: notificationId } = notification;
          const onClick = () => clickNotification(notificationId);
          return (
            <Notification
              key={notificationId}
              notification={notification}
              onClick={onClick}
            />
          );
        })}
      </ListContainer>
    </NotificationPanel>
  );
};

export default NotificationList;

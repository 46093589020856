import styled from 'styled-components';
import Card from 'components/Card';
import { Avatar } from 'components/NewLyncworkUIKit';

export const Container = styled.div`
  grid-row: 1/3;
  grid-column: 4/5;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;

export const AppContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 16px;
  padding: 4px;
`;

export const StyledAppLink = styled.a`
  width: 40px;
  height: 40px;
  margin: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.1s ease-in-out;
  &:hover {
    background: ${props => props.theme.colors.neutral.grey02};
  }
`;

export const StyledAvatar = styled(Avatar)`
  overflow: visible;
`;

import React from 'react';
import { useLocation } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import imageDocuments from 'assets/images/Onboarding/Documents.svg';
import useApplications from 'hooks/useApplications';
import Pagination from 'components/Pagination';

import { NavBar, Filter, ApplicationCard } from './components';

import {
  BreadCrumb,
  Container,
  ContentContainer,
  ListContainer,
  BlankListContainer
} from './styles';

const AppList = ({ loading, applications }) => {
  if (loading) {
    return (
      <BlankListContainer>
        <CircularProgress />
      </BlankListContainer>
    );
  }

  if (applications.length <= 0) {
    return (
      <BlankListContainer>
        <img src={imageDocuments} alt="empty state" />
        <span className="message-title">No results found</span>
        <p>
          Try adjusting your search or filters to find what you’re looking for.
        </p>
      </BlankListContainer>
    );
  }

  return (
    <ListContainer>
      {applications.map(app => (
        <ApplicationCard key={app.id} app={app} />
      ))}
    </ListContainer>
  );
};

const ApplicationSearch = ({ applicationCategories }) => {
  const {
    state: { searchValue = '' }
  } = useLocation();
  const {
    applications,
    totalPages,
    page,
    inputValue,
    changePage,
    handleInputChange,
    category,
    handleCategoryChange,
    loading
  } = useApplications({
    initialInputValue: searchValue,
    getSubscriptionId: true
  });
  return (
    <Container>
      <BreadCrumb>Applications</BreadCrumb>
      <NavBar value={inputValue} onChange={handleInputChange} />
      <ContentContainer>
        <Filter
          applicationCategories={applicationCategories}
          category={category}
          handleCategoryChange={handleCategoryChange}
        />
        <AppList loading={loading} applications={applications} />
        <Pagination
          totalPages={totalPages}
          currentPage={page + 1}
          changePage={changePage}
        />
      </ContentContainer>
    </Container>
  );
};

export default ApplicationSearch;

export const initialState = {
  name: '',
  page: 0,
  size: '10',
  teams: '',
  roles: '',
  assignmentType: '',
  journeys: [],
  loading: true,
  totalPages: 0,
  key: false
};

export const CHANGE_PARAMS = 'CHANGE_PARAMS';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const CHANGE_ASSIGNMENT_TYPE = 'CHANGE_ASSIGNMENT_TYPE';
export const SET_DATA = 'SET_DATA';

export const journeysReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case CHANGE_PAGE:
      const { page } = action;
      const { key } = state;
      return { ...state, page, journeys: [], loading: true, key: !key };
    case CHANGE_ASSIGNMENT_TYPE:
      const { assignmentType } = action;
      return {
        ...state,
        assignmentType,
        journeys: [],
        teams: '',
        roles: '',
        loading: true,
        totalPages: 0
      };
    case CHANGE_PARAMS:
      const { newParams } = action;
      return {
        ...state,
        ...newParams,
        journeys: [],
        loading: true,
        totalPages: 0
      };

    case SET_DATA:
      const { journeys, totalPages } = action;
      return {
        ...state,
        journeys,
        totalPages,
        loading: false
      };

    default:
      return state;
  }
};

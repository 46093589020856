import styled from 'styled-components';

export const PreviewContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 1em;
  cursor: pointer;
  z-index: 1;
  transition: all 1s;
  border-radius: 4px;
  box-shadow: 5px 5px 10px #00000050;
`;

export const Mask = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-image: linear-gradient(to bottom, #3a3a3b30 21%, #1c1c1e);
`; //LITERALLLY nothing else worked. I could not get the hover effect to change with ArticlePreview's props.
// so here is two identical components.
export const InfoHorizontal = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: all 0.3s ease-out;
  transform: translateX(0px);
  z-index: 2;
  color: white;
  overflow: hidden;
  width: calc(100% - 2em);
  ${PreviewContainer}:hover & {
    transform: translateX(10px);
  }
`;

export const InfoVertical = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in;
  transform: translateX(0px);
  z-index: 2;
  color: white;
  overflow: hidden;
  width: calc(100% - 2em);
  ${PreviewContainer}:hover & {
    transform: translateY(-10px);
  }
`;

export const Article = styled.div`
  height: 271px;
  min-width: 240px;
  max-width: 240px;
  margin-left: ${props => props.index > 0 && '1em'};
`;
export const BGImageContainer = styled.div`
  position: absolute;
  width: calc(100% + 2em);
  height: 100%;
  background-image: url(${p => p.img});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 20s;
  left: -1em;
`;
export const Category = styled.div`
  padding: 0.35em;
  z-index: 2;
  color: white;
  border-radius: 4px;
  width: fit-content;
  background-color: ${props =>
    props.colorOptions[props.id % props.colorOptions.length]};
`;

import React from 'react';
import { HeaderRow, HeaderCell } from 'components/ListTable';

const TableHeader = () => (
  <HeaderRow>
    <HeaderCell>Name</HeaderCell>
    <HeaderCell>Admin Access</HeaderCell>
    <HeaderCell flex={2}>Applications</HeaderCell>
    <HeaderCell flex={2}>Users</HeaderCell>
    {/* for edit button */}
    <HeaderCell />
  </HeaderRow>
);

export default TableHeader;

import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Card from 'components/Card';

export const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
`;

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  width: calc(50% - 8px);
`;
export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 0 0 24px 0;
  h2 {
    ${props => props.theme.typography.header2};
    margin: 0;
    font-weight: 600;
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  h3 {
    ${props => props.theme.typography.body};
    font-weight: 600;
    margin: 0;
  }
  p {
    margin: 0;
    ${props => props.theme.typography.control};
    color: ${props => props.theme.colors.neutral.grey07};
  }
`;

export const ServiceContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  width: 100%;
  .half-width {
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
  }
`;

export const StyledLink = styled(Link)`
  margin: 0 0 0 auto;
  text-decoration: none;
`;

export const CredentialContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey07};

  strong {
    color: ${props => props.theme.colors.neutral.grey10};
    font-weight: 600;
  }
`;

export const CredentialValue = styled.span`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

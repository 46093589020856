import styled, { css } from 'styled-components';
import { colors } from 'designSystem/foundations';

const {
  brand: { primary },
  neutral: { grey10, grey06 }
} = colors;

export const GuideContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 0 24px 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
`;

export const StickyContainer = styled.div`
  padding: 24px 0 0;
  position: sticky;
  background: white;
  z-index: 1;
  top: 0;
  display: flex;
  flex-direction: column;
`;

export const CredentialContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const buttonPseudoStyle = css`
  position: absolute;
  content: '';
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  border-radius: 1px;
  height: 2px;
  background-color: ${primary};
`;

const selectedStyle = css`
  color: ${grey10};
  :after {
    ${buttonPseudoStyle}
  }
`;

export const CredentialButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0 0 4px 0;
  margin: 0 24px 8px 0;

  ${props => props.theme.typography.control};
  font-weight: 600;
  color: ${grey06};
  ${props => props.selected && selectedStyle};
  &:hover {
    ${selectedStyle}
  }

  &:focus-visible {
    outline: 2px solid #0078d4;
  }
`;

export const CredentialTitle = styled.h2`
  ${props => props.theme.typography.header2};
  font-weight: 600;
  margin: 0 0 16px;
  color: ${grey10};
`;

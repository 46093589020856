import React, { useEffect, useRef, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';

import pdfIcon from 'assets/images/adminOnboarding/documents/pdfIcon.svg';
import docIcon from 'assets/images/adminOnboarding/documents/docIcon.svg';

import {
  CustomTextArea,
  CustomCheckbox,
  CustomSelect
} from 'components/NewLyncworkUIKit';

import { useButtonTrack } from 'matomo/hooks';
import { documentModalEvents } from 'pages/Documents/instrumentationEvents';
import { DropboxService } from 'services/dropbox.service';

import {
  Container,
  Title,
  Form,
  InputWrapper,
  ErrorMessage,
  CustomInput,
  InputLabel,
  CloseButton,
  SaveButton,
  FileContainer,
  FileName,
  ButtonContainer,
  DeleteButton,
  DownloadButton,
  RedText,
  AcceptedFiles,
  SelectFileButton,
  SubContainer,
  FileDetailSection,
  UserSelectionSection,
  TooltipContainer
} from './styles';

const { close_modal, save_documents, delete_document } = documentModalEvents;

const values = [
  {
    label: 'Requires Signature',
    value: 'SIGNABLE_ALL'
  },
  {
    label: 'Company Resource',
    value: 'ALL'
  },
  {
    label: 'Draft',
    value: 'DRAFT'
  }
];

const getInitialState = file => {
  const fileCategory = file.fileCategory || '';
  const description = file.description || '';
  const signingTitle = file.signingTitle || '';
  {
    fileCategory, description, signingTitle;
  }
  return { fileCategory, description, signingTitle };
};

const initialErrorMessage = {
  description: false,
  signingTitle: false
};

const FileEditContent = ({
  file,
  fileState,
  setFileState,
  fileSizeError,
  setFileSizeError,
  handleFileInputChange,
  closeEditModal,
  updateMetadata,
  downloadFile,
  deleteFile,
  handleNextStage
}) => {
  const trackButton = useButtonTrack();
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [group, setGroup] = useState(file?.externalGroupId === 'ONBOARDING');
  const [draft, setDraft] = useState(getInitialState(file));
  const [error, setError] = useState(initialErrorMessage);
  const inputRef = useRef();
  const { fileCategory, description, signingTitle } = draft;
  const defaultValue = values.find(option => option.value === fileCategory);

  useEffect(() => {
    if (file) {
      setFileState(file);
    }
  }, []);

  const updateInput = e => {
    let { name, value } = e.target;
    name === 'externalGroupId' &&
      (value ? (value = 'ONBOARDING') : (value = 'general'));

    setDraft(oldDraft => ({ ...oldDraft, [name]: value }));
    setError(oldError => ({ ...oldError, [name]: false }));
  };

  const handleSelect = selection => {
    setDraft(oldDraft => ({ ...oldDraft, fileCategory: selection.value }));
  };

  const uploadFile = async selectedFile => {
    try {
      const response = await DropboxService.upload(selectedFile);
      const responseFile = response.data;
      return responseFile;
    } catch (error) {
      console.log(error);
      throw 'something went wrong';
    }
  };
  const handleSubmit = async e => {
    e.preventDefault();
    trackButton(save_documents);
    if (!description || !signingTitle || !fileState.fileName) {
      setError(oldError => ({
        description: !description,
        signingTitle: !signingTitle,
        file: !fileState.fileName
      }));
      return;
    } else {
      setLoading(true);

      const responseFile = fileState.id
        ? fileState
        : await uploadFile(fileState.file);
      let externalGroupId;
      !group ? (externalGroupId = 'GENERAL') : (externalGroupId = 'ONBOARDING');
      const metaData = {
        ...responseFile,
        fileCategory,
        description,
        signingTitle,
        externalGroupId,
        signingMessage: description,
        signingSubject: signingTitle,
        dropboxFileSharers: []
      };

      await updateMetadata(metaData);
      setLoading(false);
      closeEditModal();
    }
  };

  const handleNext = () => {
    if (!description || !signingTitle || !fileState.fileName) {
      setError(oldError => ({
        description: !description,
        signingTitle: !signingTitle,
        file: !fileState.fileName
      }));
      return;
    } else {
      handleNextStage(draft, group);
    }
  };

  const handleUpload = event => {
    inputRef.current.click();
  };

  const handleDelete = async () => {
    trackButton(delete_document);
    try {
      setDeleting(true);
      await deleteFile(file);
      closeEditModal();
    } catch (error) {
      console.log(error);
      setDeleting(false);
    }
  };
  const fileName = file.fileName;
  const isPdf = fileName?.includes('.pdf');

  const handleClose = () => {
    trackButton(close_modal);
    closeEditModal();
  };

  return (
    <Container onClick={e => e.stopPropagation()}>
      <CloseButton onClick={handleClose}>
        <CloseIcon />
      </CloseButton>
      <Title>{fileState.signingTitle ? 'Edit File' : 'New File'}</Title>
      <Form onSubmit={handleSubmit}>
        <InputWrapper>
          <InputLabel>
            Title<RedText>*</RedText>
          </InputLabel>
          <CustomInput
            placeholder="Name your document"
            value={signingTitle}
            name="signingTitle"
            onChange={updateInput}
          />
          {error.signingTitle && (
            <ErrorMessage>Document name is required</ErrorMessage>
          )}
        </InputWrapper>
        <InputWrapper>
          <InputLabel>
            Category<RedText>*</RedText>
          </InputLabel>
          <CustomSelect
            options={values}
            value={defaultValue}
            onChange={handleSelect}
          />
        </InputWrapper>
        <InputWrapper fullWidth>
          <InputLabel>
            Description<RedText>*</RedText>
          </InputLabel>
          <CustomTextArea
            placeholder="Write about what this document is"
            value={description}
            name="description"
            onChange={updateInput}
            rows={4}
          />
          {error.description && (
            <ErrorMessage>Description is required</ErrorMessage>
          )}
        </InputWrapper>
        <InputWrapper fullWidth>
          <InputLabel marginBottom>
            Upload File<RedText>*</RedText>
          </InputLabel>
          <FileContainer>
            {fileState.fileName && (
              <img src={isPdf ? pdfIcon : docIcon} alt="icon" />
            )}
            {fileState.fileName && <FileName>{fileState.fileName}</FileName>}
            {fileState.fileName ? (
              <DownloadButton onClick={() => downloadFile(file)}>
                Download File
              </DownloadButton>
            ) : (
              <div>
                <input
                  type="file"
                  hidden
                  ref={inputRef}
                  onChange={handleFileInputChange}
                  accept=".pdf,.doc"
                  name="file"
                />

                <SelectFileButton onClick={handleUpload}>
                  {uploading ? (
                    <CircularProgress size="16px" color="inherit" />
                  ) : (
                    'Select File'
                  )}
                </SelectFileButton>
                {error.file && <ErrorMessage>file is required</ErrorMessage>}
              </div>
            )}
          </FileContainer>
          <AcceptedFiles>Accepted file types: PDF, Doc only</AcceptedFiles>
          <AcceptedFiles error={fileSizeError}>
            Maximum Size: 25MB
          </AcceptedFiles>
          <CustomCheckbox
            name="externalGroupId"
            label=" Add this file to onboarding"
            onChange={() => setGroup(!group)}
            value={group ? 'ONBOARDING' : 'GENERAL'}
            checked={group}
          />
        </InputWrapper>
        <ButtonContainer>
          <DeleteButton type="button" disabled={loading} onClick={handleDelete}>
            {deleting ? (
              <CircularProgress size="24px" color="inherit" />
            ) : (
              'Delete'
            )}
          </DeleteButton>
          {group ? (
            <SaveButton type="submit">Save</SaveButton>
          ) : (
            <SaveButton type="button" onClick={() => handleNext()}>
              Next
            </SaveButton>
          )}
        </ButtonContainer>
      </Form>
    </Container>
  );
};

export default FileEditContent;

import React, { useState, useContext } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { LocaleService, CMSService } from 'services';

import Button from 'components/NewLyncworkUIKit/CustomButton';
import CustomTextInput from 'components/NewLyncworkUIKit/CustomTextInput';
import CustomSelect from 'components/NewLyncworkUIKit/CustomSelect';

import { ButtonContainer } from 'pages/Onboarding/common/pages/styles';
import SelectInput from 'pages/Onboarding/common/SelectInput';
import { FormContainer, Heading } from './styles';

import AvatarUpload from './AvatarUpload';
import useCompanyInfo from './useCompanyInfo';
import { inputLabels, formatFormInfo, findMissingFields } from './utils';

const FormContext = React.createContext();
const useFormContext = () => useContext(FormContext);

const TextInput = ({ name, required }) => {
  const { formData, formErrors, updateFormField } = useFormContext();
  const value = formData[name];
  const label = inputLabels[name];
  const errorMessage = formErrors[name];
  const onChange = e => {
    const { name, value } = e.target;
    updateFormField({ name, value });
  };
  return (
    <CustomTextInput
      required={required}
      value={value}
      label={label}
      name={name}
      onChange={onChange}
      error={!!errorMessage}
      subLabel={errorMessage}
    />
  );
};

const Select = ({ name, required }) => {
  const { formData, updateFormField } = useFormContext();
  const value = formData[name];
  const label = inputLabels[name];

  const selectedOption = { label: value, value };

  const options =
    name === 'country'
      ? LocaleService.countries()
      : LocaleService.states(formData.country);
  const formattedOptions = options.map(name => ({ label: name, value: name }));

  const handleChange = selectedOption => {
    const newValue = selectedOption?.value || '';
    updateFormField({ name, value: newValue });
  };
  return (
    <SelectInput
      label={label}
      required={required}
      name={name}
      value={selectedOption}
      options={formattedOptions}
      onChange={handleChange}
    />
  );
};

const CompanyInfoForm = ({
  companyInfo,
  updateCompany,
  prevPage,
  nextPage
}) => {
  const { companyLogo, companyName, id } = companyInfo;
  const [avatarData, setAvatarData] = useState({
    src: companyLogo?.url,
    file: null
  });
  const [loading, setLoading] = useState(false);
  const { formData, formErrors, updateFormField, updateFormError } =
    useCompanyInfo({
      companyInfo
    });

  const changeAvatar = e => {
    const [file] = e.target.files;
    const src = URL.createObjectURL(file);
    setAvatarData({ file, src });
  };

  const onSubmit = async e => {
    e.preventDefault();
    const missingFields = findMissingFields(formData);
    if (missingFields.length > 0) {
      const newErrors = {};
      missingFields.forEach(key => {
        const label = inputLabels[key];
        newErrors[key] = `${label} is required`;
      });
      updateFormError(newErrors);
      return;
    }

    const data = formatFormInfo(formData);
    const addressId = companyInfo?.addresses?.[0].id;
    if (addressId) {
      data.addresses[0].id = addressId;
    }
    const newCompanyInfo = { ...companyInfo, ...data };
    try {
      setLoading(true);
      if (avatarData.file) {
        const avatarResponse = await CMSService.updateAvatar(avatarData.file);
        newCompanyInfo.companyLogo = avatarResponse.data[0];
      }
      await updateCompany(newCompanyInfo);
      nextPage();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <FormContainer onSubmit={onSubmit}>
      <Heading>Company Info</Heading>
      <AvatarUpload
        src={avatarData.src}
        name={companyName}
        colorKey={id}
        changeAvatar={changeAvatar}
      />
      <FormContext.Provider value={{ formData, formErrors, updateFormField }}>
        <TextInput required name="companyName" />
        <TextInput required name="phonePrimary" />
        <TextInput name="domain" />
        <TextInput name="website" />

        <Select name="country" required />
        <TextInput name="lineOne" required />
        <TextInput name="lineTwo" />
        <TextInput name="city" required />
        <Select name="state" required />
        <TextInput name="postalCode" required />
      </FormContext.Provider>
      <ButtonContainer>
        <Button width="160px" variant="secondary" onClick={prevPage}>
          Back
        </Button>
        <Button width="160px" type="submit">
          {loading ? (
            <CircularProgress color="secondary" size="20px" />
          ) : (
            'Next'
          )}
        </Button>
      </ButtonContainer>
    </FormContainer>
  );
};

export default CompanyInfoForm;

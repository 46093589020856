import React from 'react';
import { Add } from '@material-ui/icons';

import emptyState from 'assets/images/Onboarding/ApplicationSetup.svg';
import CustomButton from 'components/NewLyncworkUIKit/CustomButton';

import { EmptyContainer } from './styles';

export const NoAppsFound = () => {
  return (
    <EmptyContainer>
      <img src={emptyState} alt="not found" />
      <p>We couldn't find anything related to your search</p>
    </EmptyContainer>
  );
};

export const NoApps = ({ openSubscriptionModal }) => {
  return (
    <EmptyContainer>
      <img src={emptyState} alt="not found" />
      <p>There are no applications added to this role.</p>
      <CustomButton size="medium" onClick={openSubscriptionModal}>
        <Add fontSize="inherit" />
        Add Application
      </CustomButton>
    </EmptyContainer>
  );
};

import React from 'react';
import HiddenCounter from 'components/HiddenCounter';
import { AvatarContainer, StyledAvatar } from './styles';

const TeamUsers = ({ users }) => {
  const remainingCount = users?.length - 5;
  return (
    <AvatarContainer>
      {users.map(user => {
        const { avatar, firstName, id } = user;
        const src = avatar?.url;
        return (
          <StyledAvatar key={id} colorKey={id} src={src} name={firstName} />
        );
      })}
      {remainingCount > 0 && <HiddenCounter>+{remainingCount}</HiddenCounter>}
    </AvatarContainer>
  );
};
export default TeamUsers;

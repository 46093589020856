import { createSelector } from 'reselect';
// provides a way to select specific parts of a slice of state
const authenticationSetup = state => state.authentication;

export const selectLoading = createSelector(
  [authenticationSetup],
  item => item.loading
);
export const selectIsAuthenticated = createSelector(
  [authenticationSetup],
  item => item.isAuthenticated
);
export const selectLoginSuccess = createSelector(
  [authenticationSetup],
  item => item.loginSuccess
);
export const selectLoginError = createSelector(
  [authenticationSetup],
  item => item.loginError
);
export const selectShowModalLogin = createSelector(
  [authenticationSetup],
  item => item.showModalLogin
);
export const selectAccount = createSelector(
  [authenticationSetup],
  item => item.account
);
export const selectUser = createSelector(
  [authenticationSetup],
  item => item.user
);
export const selectCompany = createSelector(
  [authenticationSetup],
  item => item.user.company
);
export const selectErrorMessage = createSelector(
  [authenticationSetup],
  item => item.errorMessage
);
export const selectRedirectMessage = createSelector(
  [authenticationSetup],
  item => item.redirectMessage
);
export const selectSessionHasBeenFetched = createSelector(
  [authenticationSetup],
  item => item.sessionHasBeenFetched
);
export const selectIdToken = createSelector(
  [authenticationSetup],
  item => item.idToken
);
export const selectLogoutUrl = createSelector(
  [authenticationSetup],
  item => item.logoutUrl
);

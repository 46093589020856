import { useState, useEffect } from 'react';
import { AnnouncementService } from 'services/announcement.service';

const initialState = {
  page: 0,
  announcements: [],
  loading: true,
  error: false,
  totalPages: 0
};

const useAnnouncements = ({ size = 10 } = {}) => {
  const [{ page, loading, totalPages, announcements, error }, setFetchState] =
    useState(initialState);
  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await AnnouncementService.list({
          page,
          size
        });
        const { content: announcements, totalPages } = response.data;
        setFetchState(oldState => ({
          ...oldState,
          loading: false,
          announcements,
          totalPages
        }));
      } catch (error) {
        setFetchState(oldState => ({
          ...oldState,
          loading: false,
          error: true
        }));
        console.log(error);
      }
    };

    fetchAnnouncements();
  }, [page, size]);

  const changePage = newPage => {
    const newPageIndex = newPage - 1;
    setFetchState(oldVar => ({ ...oldVar, page: newPageIndex, loading: true }));
  };

  const setAnnouncements = announcements =>
    setFetchState(oldState => ({ ...oldState, announcements }));

  return {
    page,
    loading,
    totalPages,
    announcements,
    error,
    changePage,
    setAnnouncements
  };
};

export default useAnnouncements;

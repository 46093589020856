import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { isBefore } from 'date-fns';

import useSearchQuery from 'hooks/useSearchQuery';
import { UserJourneyService } from 'services/userJourneys.services';
import { CustomButton, CustomSearch } from 'components/NewLyncworkUIKit';
import {
  SingleJourneyPageContainer,
  StyledLink,
  SearchBar,
  TasksListTitle,
  Search,
  ListContainer
} from './styles';
import {
  JourneyListCard,
  TaskListCard,
  JourneyTaskNavigation
} from './components';

const sortTask = (taskA, taskB) => {
  const dateA = Date.parse(taskA.schedStartDate);
  const dateB = Date.parse(taskB.schedStartDate);
  return dateA - dateB;
};

const doTask = () => {
  return new Promise(resolve =>
    setTimeout(() => {
      resolve();
    }, 5000)
  );
};

const fetchData = async (workflowId, repeatableGroup) => {
  const journeyResponse = await UserJourneyService.findById(
    workflowId,
    repeatableGroup
  );
  const response = await UserJourneyService.listTask({
    workflowId,
    repeatableGroup
  });
  const taskList = response.data.content;
  const sortedTasks = taskList.sort(sortTask);
  return {
    journey: journeyResponse.data,
    tasks: sortedTasks
  };
};

const SingleJourneyPage = () => {
  const { id: workflowId } = useParams();
  const searchQuery = useSearchQuery();
  const repeatableGroup = searchQuery.get('repeatableGroup') || 1;

  const [{ journey, tasks }, setData] = useState({ journey: null, tasks: [] });
  const [taskIdInProgress, setTaskIdInProgress] = useState(null);
  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    let mounted = true;
    const fetch = async () => {
      try {
        const data = await fetchData(workflowId, repeatableGroup);
        mounted && setData(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
    return () => {
      mounted = false;
    };
  }, []);

  const completeTask = async id => {
    try {
      setTaskIdInProgress(id);
      const response = await UserJourneyService.completeTask(id);
      const fetchedTask = response.data;
      setData(oldData => {
        const { journey: oldJourney, tasks: oldTasks } = oldData;
        const tasks = oldTasks.map(task => {
          if (task.id === fetchedTask.id) {
            return fetchedTask;
          }
          return task;
        });
        const { taskWork } = oldJourney;
        const { completedTasks } = taskWork;
        const journey = {
          ...oldJourney,
          taskWork: { ...taskWork, completedTasks: completedTasks + 1 }
        };
        return { journey, tasks };
      });
    } catch (error) {
      console.log(error);
    } finally {
      setTaskIdInProgress(null);
    }
  };

  const handleChange = e => setInputValue(e.target.value);
  const filteredTasks = tasks.filter(task => {
    const {
      task: { taskEventName, taskDesc }
    } = task;
    const formattedInput = inputValue.toLowerCase();
    const formattedTaskName = taskEventName.toLowerCase();
    return formattedTaskName.includes(formattedInput);
  });

  return (
    <SingleJourneyPageContainer>
      <ListContainer>
        <StyledLink to="/user-journeys">
          <CustomButton size="medium" variant="secondary">
            Back
          </CustomButton>
        </StyledLink>
        {journey && <JourneyListCard journey={journey} />}
        {tasks.length > 0 && (
          <SearchBar>
            <TasksListTitle>Tasks</TasksListTitle>
            <Search
              placeholder="Search"
              value={inputValue}
              onChange={handleChange}
            />
          </SearchBar>
        )}
        {filteredTasks.map(task => {
          const { id } = task;
          return (
            <TaskListCard
              key={id}
              task={task}
              taskIdInProgress={taskIdInProgress}
              completeTask={completeTask}
            />
          );
        })}
      </ListContainer>
      <JourneyTaskNavigation
        tasks={tasks}
        journey={journey}
        inputValue={inputValue}
        setInputValue={setInputValue}
      />
    </SingleJourneyPageContainer>
  );
};

export default SingleJourneyPage;

import styled from 'styled-components';

export const Title = styled.h3`
  ${props => props.theme.typography.title3};
  font-weight: 600;
  margin: 0;
`;

export const Tagline = styled.span`
  ${props => props.theme.typography.header3};
  text-align: center;
  width: 100%;
  max-width: 600px;
  margin: 8px 0 40px;
`;

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { UserService } from 'services/user.service';
import { useButtonTrack } from 'matomo/hooks';
import { useDialogue } from 'hooks';

import { singleUserPageEvents } from 'pages/Users/UsersListPage/instrumentationEvents';

import { initializeUserInfo } from '../../utils';

import useRolesChange from './useRolesChange';

const initialUserData = {
  firstName: '',
  lastName: '',
  emailPrimary: '',
  emailSecondary: '',
  username: '',
  jobTitle: null,
  provisionUserDate: new Date(),
  roles: [],
  teams: [],
  mentor: null,
  manager: null,
  itPrime: null,
  hrPrime: null,
  directReports: [],
  mentee: []
};

const requiredFields = [
  'firstName',
  'lastName',
  'emailPrimary',
  'emailSecondary',
  'username'
];

const formLabels = {
  firstName: 'First Name',
  lastName: 'Last Name',
  emailPrimary: 'Company Email',
  emailSecondary: 'Personal Email',
  username: ' Username'
};

const { discard_change } = singleUserPageEvents;

const useWorkDetails = (userData, handleSubmit) => {
  const trackButton = useButtonTrack();
  const {
    shouldDisplayPreflight,
    applicationChanges,
    isSavePreflightOpen,
    openSavePreflight,
    closeSavePreflight
  } = useRolesChange(userData);

  const history = useHistory();
  const isEditing = !!userData;
  const { showDialogue, closeDialogue, updateDialogue } = useDialogue();
  const [workDetails, setWorkDetails] = useState(
    userData ? initializeUserInfo(userData) : initialUserData
  );
  const [formModified, setFormModified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [redirect, setRedirect] = useState(false);

  const currentRoles = workDetails.roles;

  useEffect(() => {
    if (redirect) {
      history.push('/admin-users');
    }
  }, [redirect]);

  const handleFieldChange = (name, value) => {
    setFormModified(true);
    setWorkDetails(oldData => ({ ...oldData, [name]: value }));
    setFormErrors(oldErrors => ({ ...oldErrors, [name]: '' }));
  };

  const isValid = () => {
    let newErrors = {};
    const formErrors = requiredFields.filter(key => {
      const isEmpty = !workDetails[key];
      const label = formLabels[key];
      const message = `${label} is required`;
      newErrors[key] = isEmpty ? message : '';
      return isEmpty;
    });

    setFormErrors(newErrors);
    return formErrors.length < 1;
  };

  const confirmSave = async () => {
    setLoading(true);
    setFormModified(false);
    try {
      const newUserData = await handleSubmit(workDetails);
      if (isEditing) {
        setLoading(false);
        setWorkDetails(initializeUserInfo(newUserData));
      } else {
        history.push('/admin-users');
      }
    } catch (error) {
      console.log({ error });
      showDialogue({
        title: 'Oops, something went wrong',
        message: error.response.data.title,
        confirmButtonAction: closeDialogue,
        confirmButtonText: 'Okay'
      });
      setLoading(false);
      setFormModified(true);
    }
  };

  const handleSave = async e => {
    if (!isValid()) {
      return;
    }
    setLoading(true);
    try {
      const displayPreflight = await shouldDisplayPreflight(workDetails.roles);
      if (displayPreflight) {
        setLoading(false);
        openSavePreflight();
        return;
      }
    } catch (error) {
      setLoading(false);
      return;
    }

    confirmSave();
  };

  const handleCancel = () => {
    trackButton(discard_change);
    if (isEditing) {
      setWorkDetails(initializeUserInfo(userData));
      setFormModified(false);
    } else {
      setRedirect(true);
    }
  };

  const blockTransition = formModified && !redirect;

  return {
    workDetails,
    formErrors,
    handleFieldChange,
    blockTransition,
    isValid,
    handleSave,
    loading,
    handleCancel,
    applicationChanges,
    isSavePreflightOpen,
    confirmSave,
    closeSavePreflight
  };
};

export default useWorkDetails;

import React from 'react';
import { useButtonTrack } from 'matomo/hooks';
import Pagination from 'components/Pagination';
import { CustomSearch, CustomButton } from 'components/NewLyncworkUIKit';
import ListTable from 'components/ListTable';
import useJourneysList from './useJourneysList/useJourneysList';
import {
  Container,
  Header,
  Toolbar,
  StyledLink,
  Table,
  TableBody
} from './styles';

import { Filter, JourneysListHeader, JourneysListCard } from './Components';
import { journeyListPageEvents } from '../instrumentationEvents';

const JourneysListPage = () => {
  const trackButton = useButtonTrack();
  const {
    name,
    page,
    journeys,
    loading,
    totalPages,
    changePage,
    searchQuery,
    handleInputChange,
    teams,
    roles,
    assignmentType,
    handleAssignmentChange,
    changeParams
  } = useJourneysList();
  const handleNewJourney = () => trackButton(journeyListPageEvents.add_journey);
  return (
    <Container>
      <Header>Journeys</Header>
      <Toolbar>
        <Filter
          teams={teams}
          roles={roles}
          assignmentType={assignmentType}
          handleAssignmentChange={handleAssignmentChange}
          changeParams={changeParams}
        />
        <CustomSearch
          placeholder="Search Journeys"
          value={searchQuery}
          onChange={handleInputChange}
        />
        <StyledLink to="/admin-journeys/add">
          <CustomButton size="medium" tabIndex="-1" onClick={handleNewJourney}>
            + New Journey
          </CustomButton>
        </StyledLink>
      </Toolbar>
      <ListTable
        header={<JourneysListHeader />}
        loading={loading}
        hasContent={journeys.length > 0}
      >
        {journeys.map(journey => (
          <JourneysListCard key={journey.id} journey={journey} />
        ))}
      </ListTable>
      {/* <Table>
        <JourneysListHeader />
        <JourneysList loading={loading} journeys={journeys} />
      </Table> */}
      <Pagination
        totalPages={totalPages}
        currentPage={page + 1}
        changePage={changePage}
      />
    </Container>
  );
};

export default JourneysListPage;

import styled from 'styled-components';
export const CommunityContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  top: -2vw;
  padding-top: 50px;
`;
export const Features = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 1em;
  width: 100%;
  height: 400px;
  margin-top: 10px;
`;

export const Featured = styled.div`
  grid-row-start: span ${props => (props.index > 0 ? '1' : '2')};
`;
export const Message = styled.p`
  font-size: 14px;
`;

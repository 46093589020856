import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { scrollBar } from 'StyleVariables/StandardComponents';
import { CustomButton } from 'components/NewLyncworkUIKit';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  h1 {
    ${props => props.theme.typography.title3};
    font-weight: 600;
    color: ${props => props.theme.colors.neutral.grey10};
    margin: 0 auto 0 0;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  margin-left: 16px;
`;

export const TableBody = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const Table = styled.div`
  box-sizing: border-box;
  margin: 28px 0;
  background: ${props => props.theme.colors.neutral.white};
  border: none;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 600px;
  overflow: hidden;
`;

export const BlankTable = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.neutral.grey07};
  img {
    width: 100px;
    height: auto;
    object-fit: contain;
    margin-bottom: 16px;
  }
`;

export const SingleRolePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const BackButton = styled(CustomButton)`
  .MuiSvgIcon-root {
    margin-right: 4px;
  }
`;

export const BlankContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.neutral.white};
  padding: 24px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 24px;

  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.neutral.grey07};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    margin-bottom: 24px;
  }
`;

export const PaginationContainer = styled.div`
  height: 32px;
  display: flex;
  justify-content: center;
`;

import { format, isBefore, isValid } from 'date-fns';

export const formatDate = date => {
  const convertedDate = new Date(date);
  if (!isValid(convertedDate)) {
    return '';
  }
  const dateFormat = `MMM d, yyyy`;
  return format(convertedDate, dateFormat);
};
export const getDateInfo = ({ schedDueDate, schedStartDate }) => {
  const startDate = new Date(schedStartDate);
  const dueDate = new Date(schedDueDate);
  const currentDate = new Date();

  const canStart = isBefore(startDate, currentDate);

  const dateText = canStart
    ? `Due On ${formatDate(dueDate)}`
    : `Start On ${formatDate(startDate)}`;

  return { dateText, canStart };
};

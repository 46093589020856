import React, { useState } from 'react';

const getInitialCapabilities = application => {
  const { applicationCapabilities, multiCapabilitiesAllowed } = application;
  if (!multiCapabilitiesAllowed) {
    return [applicationCapabilities[0]];
  }
  return applicationCapabilities;
};

const defaultSave = (
  application,
  selectedCapabilities,
  selectedLicense = null
) => {
  console.log({ application, selectedCapabilities, selectedLicense });
};

const tabs = {
  capabilities: 'capabilities',
  licenses: 'licenses'
};

const useRegularSubscription = ({
  subscription,
  backToList,
  addApplication,
  closeModal
}) => {
  const { application, licenseManagements } = subscription;
  const { multiCapabilitiesAllowed } = application;
  const hasLicenseInfo = licenseManagements.length > 0;
  const [currentTab, setCurrentTab] = useState(tabs.capabilities);
  const [selectedLicense, setSelectedLicense] = useState(null);
  const [selectedCapabilities, setSelectedCapabilities] = useState(
    getInitialCapabilities(application)
  );

  const toggleCapability = capability => {
    setSelectedCapabilities(oldCapabilities => {
      const oldIds = oldCapabilities.map(({ id }) => id);
      if (oldIds.includes(capability.id)) {
        return oldCapabilities.filter(({ id }) => id !== capability.id);
      }
      if (!multiCapabilitiesAllowed) {
        return [capability];
      }
      return [...oldCapabilities, capability];
    });
  };

  const handleBack = () => {
    switch (currentTab) {
      case tabs.licenses:
        setCurrentTab('capabilities');
        return;
      default:
        backToList();
    }
  };

  const handleNext = () => {
    if (currentTab === tabs.licenses || !hasLicenseInfo) {
      addApplication(application, selectedCapabilities, selectedLicense);
      closeModal();
      return;
    }
    setCurrentTab(tabs.licenses);
  };

  const showLicensesTab = currentTab === tabs.licenses;

  return {
    hasLicenseInfo,
    showLicensesTab,
    selectedCapabilities,
    selectedLicense,
    chooseLicense: setSelectedLicense,
    toggleCapability,
    handleBack,
    handleNext
  };
};

export default useRegularSubscription;

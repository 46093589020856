import React from 'react';
import { Container, Cell, Label, Days, DayContainer } from './styles';

const generateAscendingArray = length => {
  return new Array(length).fill(1).map((_, index) => index);
};

const dayOffset = generateAscendingArray(7);

const Week = ({ weekIndex }) => {
  const startingDay = weekIndex * 7 + 1;
  return (
    <Cell>
      <Label>Week {weekIndex + 1}</Label>
      <Days>
        {dayOffset.map(offset => {
          return (
            <DayContainer key={offset}>
              <span>DAY</span>
              <div>{offset + startingDay}</div>
            </DayContainer>
          );
        })}
      </Days>
    </Cell>
  );
};

const HeaderRow = ({ numberOfWeeks }) => {
  const weekIndices = generateAscendingArray(numberOfWeeks);
  return (
    <Container>
      {weekIndices.map(weekIndex => {
        return <Week key={weekIndex} weekIndex={weekIndex} />;
      })}
    </Container>
  );
};

export default HeaderRow;

import React from 'react';

import LoadingScreen from 'components/LoadingScreen';

import { useCompany } from 'hooks';

import CompanyInfoForm from './CompanyInfoForm';

const AdminSetupCompanyInfo = ({ prevPage, nextPage }) => {
  const { loading, updateCompany, companyInfo } = useCompany();

  if (loading) {
    return <LoadingScreen />;
  }
  return (
    <CompanyInfoForm
      companyInfo={companyInfo}
      updateCompany={updateCompany}
      prevPage={prevPage}
      nextPage={nextPage}
    />
  );
};

export default AdminSetupCompanyInfo;

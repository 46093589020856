import React from 'react';

import {
  CardContainer,
  CustomButton,
  ButtonContainer,
  CardInfoContainer,
  Title,
  Description
} from './styles';

const CompanyResourceCard = ({ title, description }) => {
  return (
    <CardContainer isCompanyResource>
      <CardInfoContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </CardInfoContainer>
      <ButtonContainer>
        <CustomButton>Open</CustomButton>
      </ButtonContainer>
    </CardContainer>
  );
};

export default CompanyResourceCard;

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { CustomButton } from 'components/NewLyncworkUIKit';

import useSkills from './useSkills';
import { MySkills, AddSkills } from './components';
import { Container, Title, ButtonContainer } from './styles';

const Skills = ({ prevPage, nextPage }) => {
  const {
    loading,
    loadSkills,
    createSkill,
    skills,
    addSkill,
    removeSkill,
    updateSkill,
    handleSubmit,
    buttonSpinner
  } = useSkills({ nextPage });

  return (
    <Container>
      <Title>Skills</Title>
      <AddSkills
        skills={skills}
        loadSkills={loadSkills}
        addSkill={addSkill}
        createSkill={createSkill}
      />

      <MySkills
        skills={skills}
        removeSkill={removeSkill}
        updateSkill={updateSkill}
        loading={loading}
      />

      {!loading && (
        <ButtonContainer>
          <CustomButton variant="secondary" onClick={prevPage}>
            Back
          </CustomButton>
          <CustomButton onClick={handleSubmit}>
            {buttonSpinner ? (
              <CircularProgress color="inherit" size="28px" />
            ) : (
              'Next'
            )}
          </CustomButton>
        </ButtonContainer>
      )}
    </Container>
  );
};

export default Skills;

import styled from 'styled-components';
import colors from 'designSystem/foundations/colors';
import { Avatar, CustomButton } from 'components/NewLyncworkUIKit';

const { grey07, grey02 } = colors.neutral;
const { confirmation } = colors.feedback;
const { primary } = colors.brand;

export const Container = styled.div`
  display: flex;
  margin: 8px 0;
`;

export const CommentInfo = styled.div`
  flex-grow: 1;
`;

export const CommentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 8px 12px;
  border-radius: 8px;
  min-height: 48px;
  box-sizing: border-box;
  background-color: ${props => props.theme.colors.neutral.grey01};
`;

export const CommentEditButton = styled.button`
  ${props => props.theme.typography.header3};
  position: absolute;
  padding: 0;
  top: 8px;
  right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${grey07};
  appearance: none;
  border-radius: 50%;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  &:hover {
    background: ${grey02};
  }
  &:focus-visible {
    outline: 2px solid #0078d4;
  }
  .MuiSvgIcon-root {
    font-size: inherit;
  }
`;

export const StyledAvatar = styled(Avatar)`
  margin: 8px 8px 0 0;
`;

export const TimeDifference = styled.div`
  margin: 0 0 0 12px;
  ${props => props.theme.typography.footnote};
  color: ${props => props.theme.colors.neutral.grey07};
`;

export const Author = styled.div`
  display: flex;
  align-items: center;
  ${props => props.theme.typography.metadata};
  color: ${props => props.theme.colors.neutral.grey10};
  font-weight: 500;
`;

export const CommentBody = styled.div`
  margin: 8px 0 0 0;
  white-space: pre-line;
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey10};
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.neutral.grey07};
`;

export const TextButton = styled(CustomButton)`
  color: ${props => (props.hasLiked ? primary : grey07)};
  padding: 0;
  &:hover:enabled {
    background-color: transparent;
  }
`;

export const Divider = styled.div`
  width: 1px;
  height: 0.75rem;
  background-color: ${props => props.theme.colors.neutral.grey07};
  margin: 0 8px;
`;

TextButton.defaultProps = {
  variant: 'tertiary',
  size: 'small'
};

export const LikeCount = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(50%);
  ${props => props.theme.typography.footnote};
  color: ${grey07};
  height: 1rem;
  padding: 0 0.25rem;
  border-radius: 0.5rem;
  background-color: ${grey02};
  .MuiSvgIcon-root {
    margin-right: 4px;
    font-size: inherit;
    color: ${primary};
  }
`;

import { useSelector, useDispatch } from 'react-redux';

import {
  LOGIN,
  GET_SESSION,
  LOGOUT,
  CLEAR_AUTH,
  ERROR_MESSAGE,
  GET_USER,
  SET_COMPANY
} from 'redux/authentication/authentication.actions';

export const useAuthentication = () => useSelector(state => state.authentication);

export const useAuthenticationActions = () => {
  const dispatch = useDispatch();

  const setCompany = data => dispatch({ type: SET_COMPANY, payload: data });
  const setLogin = data => dispatch({ type: LOGIN, payload: data });
  const setGetSession = data => dispatch({ type: GET_SESSION, payload: data });
  const setGetUser = data => dispatch({ type: GET_USER, payload: data });
  const setLogout = data => dispatch({ type: LOGOUT, payload: data });
  const setClearAuth = data => dispatch({ type: CLEAR_AUTH, payload: data });
  const setErrorMessage = data =>
    dispatch({ type: ERROR_MESSAGE, payload: data });

  return {
    setCompany,
    setLogin,
    setGetSession,
    setGetUser,
    setLogout,
    setClearAuth,
    setErrorMessage
  };
};

export default useAuthentication;

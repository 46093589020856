import styled from 'styled-components';

export const Card = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.neutral.white};
  padding: 24px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  grid-column: 2 / 3;
  overflow-y: hidden;
`;

export const SectionTitle = styled.h1`
  margin: 0 0 16px 0;
  display: flex;
  align-items: center;
  ${props => props.theme.typography.header2};
  font-weight: 600;
`;

import axios from 'axios';
import env from '../env';

const skillsUrl = `${env.storeDomain}/api/skills`;
const accountDetailUrl = `${env.storeDomain}/api/account-detail`;

export const UserSkillService = {
  getUserSkills: () => {
    return axios.get(`${accountDetailUrl}/skills`);
  },
  getUserSkillLevels: () => {
    return axios.get(`${accountDetailUrl}/skill-levels`);
  },
  deleteUserSkill: id => {
    return axios.delete(`${accountDetailUrl}/skills/${id}`);
  },
  // ONLY INCLUDE THINGS THAT HAVE CHANGED
  updateUserSkills: skillLevels => {
    return axios.post(`${accountDetailUrl}/skill-levels`, skillLevels);
  }
};

export const SkillService = {
  listSkills: ({
    approved = true,
    name = '',
    page = 0,
    size = 10,
    roles = []
  } = {}) => {
    const searchParams = new URLSearchParams({
      approved,
      name,
      page,
      size,
      roles
    });
    const queryString = searchParams.toString();
    return axios.get(`${skillsUrl}?${queryString}`);
  },
  createSkill: ({ name }) => {
    return axios.post(`${skillsUrl}`, { name });
  }
};

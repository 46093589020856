import { useState, useEffect } from 'react';

import { useButtonTrack } from 'matomo/hooks';
import { adminOnboardingDocumentsPageEvents } from 'pages/AdminOnboarding/instrumentationEvents';
import { DropboxService } from 'services/dropbox.service';
import { downloadBlob } from 'services/hellosign.service';
import { Edit } from '@material-ui/icons';

const {
  open_preview,
  close_preview,
  add_documents,
  delete_document,
  delete_document_confirm,
  edit_document,
  save_document,
  download_document
} = adminOnboardingDocumentsPageEvents;

const useDocumentsPage = () => {
  const trackButton = useButtonTrack();
  const [showPreview, setShowPreview] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileToEdit, setFileToEdit] = useState(null);
  const [fileToDelete, setFileToDelete] = useState(null);
  useEffect(() => {
    let mounted = true;
    const getFiles = async () => {
      try {
        const response = await DropboxService.listFile();
        const files = response.data.content;
        const onboardingFiles = files.filter(
          file => file.externalGroupId === 'ONBOARDING'
        );
        mounted && setFiles(onboardingFiles);
      } catch (error) {
        console.log(error);
      }
    };
    getFiles();
    return () => {
      mounted = false;
    };
  }, []);

  const uploadFile = async file => {
    trackButton(add_documents);
    try {
      const response = await DropboxService.upload(file);
      const metaData = { ...response.data, externalGroupId: 'ONBOARDING' };
      const responseWithMetaData = await DropboxService.updateFileMetadata(
        metaData
      );
      const responseFileWithMetaData = response.data;
      setFiles(list => {
        return [responseFileWithMetaData, ...list];
      });
    } catch (error) {
      console.log(error);
      throw 'something went wrong';
    }
  };

  const editFile = file => {
    trackButton(edit_document);
    setFileToEdit(file);
  };
  const closeEditModal = () => setFileToEdit(null);
  const updateMetadata = async metaData => {
    trackButton(edit_document);
    try {
      const response = await DropboxService.updateFileMetadata(metaData);
      const responseFile = response.data;
      const { dropboxFileId: responseDropboxId } = responseFile;
      setFiles(oldFiles => {
        const newFiles = oldFiles.map(file => {
          const { dropboxFileId } = file;
          if (dropboxFileId === responseDropboxId) {
            return responseFile;
          }
          return file;
        });
        return newFiles;
      });
    } catch (error) {
      console.log(error);
    }
  };

  const downloadFile = async file => {
    trackButton(download_document);
    const { dropboxFileId, fileName } = file;
    try {
      const response = await DropboxService.downloadAdmin(dropboxFileId);
      const blob = response.data;
      downloadBlob(blob, fileName);
    } catch (error) {
      console.log(error);
    }
  };

  const openDeletePrompt = file => {
    trackButton(delete_document);
    setFileToDelete(file);
  };
  const closeDeletePrompt = () => setFileToDelete(null);

  const deleteFile = async file => {
    trackButton(delete_document_confirm);
    try {
      const { dropboxFileId } = file;
      await DropboxService.deleteFile(dropboxFileId);
      setFiles(oldFiles => {
        return oldFiles.filter(file => file.dropboxFileId !== dropboxFileId);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const openPreview = () => {
    trackButton(open_preview);
    setShowPreview(true);
  };
  const closePreview = () => {
    trackButton(close_preview);
    setShowPreview(false);
  };

  return {
    files,
    fileToEdit,
    uploadFile,
    editFile,
    closeEditModal,
    updateMetadata,
    downloadFile,
    fileToDelete,
    openDeletePrompt,
    closeDeletePrompt,
    deleteFile,
    showPreview,
    openPreview,
    closePreview
  };
};

export default useDocumentsPage;

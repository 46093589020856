import {
  TOGGLE_CALENDAR_NOTIFICATION,
  CHANGE_TEXT,
  CHANGE_ASSIGNMENT_TYPE,
  CHANGE_ASSIGNMENT,
  MODIFY_TASK,
  DELETE_TASK,
  CHANGE_TASK_DAY,
  SET_DATA,
  TOGGLE_TRIGGER,
  SELECT_PREREQUISITE
} from './actions';

const createTask = ({
  order,
  taskInfo,
  modifyMethod = 'create',
  id = null
}) => {
  const { taskEventName, taskType, taskDesc, startDay, endDay } = taskInfo;
  const task = {
    order,
    type: 'TASK',
    modifyMethod,
    task: {
      id,
      taskEventName,
      taskType,
      taskDesc,
      startDay,
      endDay,
      taskEventIndicator: null,
      taskEventOrder: null,
      taskAttribute: 'MANUAL_WORKFLOW_ONLY'
    }
  };
  return task;
};

export const initialState = {
  workflowName: '',
  workflowDesc: '',
  notificationEnabled: false,
  repeatable: false,
  eventbased: false,
  prerequisiteWorkflow: null,
  active: true,
  assignmentType: 'ROLE',
  teams: [],
  roles: [],
  lyncWorkUsers: [],
  workflowTasks: [],
  startDay: null,
  endDay: null
};

const createWorkflowTasks = (taskInfo, workflowTasks) => {
  const { order } = taskInfo;
  if (order === null) {
    const allOrders = workflowTasks.map(a => a.order);
    const newOrder = Math.max(...allOrders, 0) + 1;
    const newTask = createTask({ order: newOrder, taskInfo });
    return [...workflowTasks, newTask];
  }

  const newWorkflowTasks = workflowTasks.map(workflowTask => {
    if (order !== workflowTask.order) {
      return workflowTask;
    }
    const { modifyMethod = 'update' } = workflowTask;
    return createTask({
      order,
      taskInfo,
      modifyMethod,
      id: workflowTask.task.id
    });
  });
  return newWorkflowTasks;
};

const repeatableName = 'repeatable';
const eventbasedName = 'eventbased';

export const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case TOGGLE_CALENDAR_NOTIFICATION: {
      const { checked } = action;
      return { ...state, notificationEnabled: checked };
    }
    case TOGGLE_TRIGGER: {
      const { name, checked } = action;
      const newState = {
        ...state,
        repeatable: false,
        eventbased: false,
        [name]: checked,
        prerequisiteWorkflow: null
      };
      return newState;
    }
    case SELECT_PREREQUISITE: {
      const { prerequisiteWorkflow } = action;
      return { ...state, prerequisiteWorkflow };
    }
    case CHANGE_TEXT:
    case CHANGE_ASSIGNMENT:
      const { name, value } = action;
      return { ...state, [name]: value };
    case CHANGE_ASSIGNMENT_TYPE:
      const { assignmentType } = action;
      return {
        ...state,
        assignmentType,
        teams: [],
        roles: [],
        lyncWorkUsers: []
      };
    case MODIFY_TASK:
      const { taskInfo } = action;
      const workflowTasks = createWorkflowTasks(taskInfo, state.workflowTasks);
      return {
        ...state,
        workflowTasks
      };
    case DELETE_TASK:
      const { order } = action;
      return {
        ...state,
        workflowTasks: state.workflowTasks.filter(a => a.order !== order)
      };
    case CHANGE_TASK_DAY: {
      const { order } = action;
      const workflowTasks = state.workflowTasks.map(workflowTask => {
        if (order !== workflowTask.order) {
          return workflowTask;
        }
        const { startDay, endDay } = action;
        const { task, modifyMethod = 'update' } = workflowTask;
        return {
          ...workflowTask,
          modifyMethod,
          task: { ...task, startDay, endDay }
        };
      });
      return { ...state, workflowTasks };
    }
    case SET_DATA: {
      return action.payload;
    }
    default:
      return state;
  }
};

import styled from 'styled-components';
import colors from 'designSystem/foundations/colors';
export const Container = styled.div`
  position: relative;
  height: 100%;
  box-sizing: border-box;
  height: calc(100% - calc(61px + 3.55em));
`;

export const GroupActionContainer = styled.div`
  position: absolute;
  z-index: 3;
  top: -90px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;

  height: 82px;
`;
export const ActionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;
`;
export const BatchBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background-color: transparent;
  border-radius: 4px;
  color: ${colors.neutral.grey10};
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 8px 0;
  transition: background-color 0.3s;
  min-width: 124px;
  box-sizing: border-box;
  ${({ noHover }) =>
    !noHover &&
    ` cursor: pointer;
     &:hover {
    background-color: ${colors.neutral.grey02};
  }`}
`;
export const Icon = styled.img`
  margin-right: 8px;
`;

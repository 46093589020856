import axios from 'axios';
import env from '../env';

const baseUrl = `${env.storeDomain}/api/lync-work-user-journey`;
const taskUrl = `${env.storeDomain}/api/task-works`;

export const UserJourneyService = {
  list: ({ name = '', page = '0', size = '10', completed = '', sort = '' }) => {
    const searchParams = new URLSearchParams({
      name,
      page,
      size,
      completed,
      sort
    });
    const queryString = searchParams.toString();
    return axios.get(`${baseUrl}?${queryString}`);
  },
  findById: (id, repeatableGroup) => {
    return axios.get(`${baseUrl}/${id}?repeatableGroup=${repeatableGroup}`);
  },
  listTask: ({
    workflowId = '',
    size = 50,
    startDate = '',
    endDate = '',
    status = '',
    repeatableGroup = 1
  }) => {
    const searchParams = new URLSearchParams({
      workflowId,
      size,
      startDate,
      endDate,
      status,
      repeatableGroup
    });
    const queryString = searchParams.toString();
    return axios.get(`${taskUrl}/lync-work-user?${queryString}`);
  },
  completeTask: id => {
    return axios.put(`${taskUrl}/${id}/status/completed`);
  }
};

import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import TrashIcon from 'assets/images/GenericIcons/trash.svg';
import DownloadIcon from 'assets/images/GenericIcons/Download.svg';
import emptyStatePic from 'assets/images/GenericIcons/empty state.svg';

import ListTable from 'components/ListTable';
import Pagination from 'components/Pagination';
import { CustomButton, CustomSearch } from 'components/NewLyncworkUIKit';

import { DropboxService } from 'services/dropbox.service';
import { UserService } from 'services/user.service';
import { downloadBlob } from 'services/hellosign.service';

import { useButtonTrack } from 'matomo/hooks';

import { useDialogue } from 'hooks';

import {
  Container,
  GroupActionContainer,
  BatchBox,
  ActionRow,
  Icon
} from './styles';

import FileEditModal from './DocModal/Portal';
import { TableHeader, DropboxRow } from './DropboxTable';
import { documentsPageEvents } from '../../instrumentationEvents';

const {
  add_file,
  edit_file,
  change_page,
  select_file,
  select_all_files,
  batch_download,
  batch_deletion
} = documentsPageEvents;

const deleteFile = async file => {
  await DropboxService.deleteFile(file.dropboxFileId);
};

const DropBoxDocs = ({ fileToEdit, setFileToEdit }) => {
  const trackButton = useButtonTrack();
  const [uploadedData, setUploadedData] = useState([]);

  const [searchParam, setSearchParam] = useState('');
  const [deleting, setDeleting] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const { showDialogue, closeDialogue } = useDialogue();

  const downloadFile = async file => {
    const { dropboxFileId, fileName } = file;
    try {
      const response = await DropboxService.downloadAdmin(dropboxFileId);
      const blob = response.data;
      downloadBlob(blob, fileName);
    } catch (e) {
      console.log(e);
      showDialogue({
        title: 'Oops, something went wrong!',
        message: 'Looks like a file could not be downloaded',
        confirmButtonText: 'Okay',
        confirmButtonAction: closeDialogue
      });
    }
  };

  const handleSearch = async () => {
    setUploadedData([]);
    setLoading(true);
    let userIds = [];
    const { data } = await DropboxService.listFile(searchParam, page);
    data.content.forEach(x => userIds.push(x.fileOwnerLyncWorkUserId));
    const userRequest = await UserService.listByIds(userIds);

    const filesWithUsers = data.content.map(x => ({
      ...x,
      user: userRequest?.data?.find(y => y.id === x.fileOwnerLyncWorkUserId)
    }));
    setUploadedData(filesWithUsers);
    setTotalPages(data.totalPages);
    setLoading(false);
  };
  const closeEditModal = () => setFileToEdit(null);

  useEffect(() => {
    handleSearch();
  }, [page]);

  const updateDocument = async metaData => {
    let { data } = await DropboxService.updateFileMetadata(metaData);
    const userResponse = await UserService.find(data.fileOwnerLyncWorkUserId);
    data = { ...data, user: userResponse.data };
    closeEditModal();
    handleSearch();
  };

  const handleBatchDownload = async () => {
    trackButton(batch_download);
    setDownloading(true);
    try {
      for (const file of selectedFiles) {
        await downloadFile(file);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDownloading(false);
      setSelectedFiles([]);
    }
  };

  const handleBatchDeletion = async () => {
    trackButton(batch_deletion);
    setDeleting(true);
    try {
      for (const file of selectedFiles) {
        await deleteFile(file);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDeleting(false);
      page > 0 ? setPage(0) : handleSearch();
      setSelectedFiles([]);
    }
  };
  const handleSelect = value => {
    if (selectedFiles.includes(value)) {
      setSelectedFiles(oldFiles =>
        oldFiles.filter(file => file.id !== value.id)
      );
    } else {
      setSelectedFiles(oldFiles => [...oldFiles, value]);
    }
  };

  const handleModalFileDeletion = async file => {
    await deleteFile(file);
    page > 0 ? setPage(0) : handleSearch();
  };

  const handleAddFile = () => {
    setFileToEdit({});
    trackButton(add_file);
  };

  const changePage = page => {
    trackButton(change_page);
    setPage(page - 1);
  };

  const handleSelectAll = () => {
    trackButton(select_all_files);
    return selectedFiles === uploadedData
      ? setSelectedFiles([])
      : setSelectedFiles(uploadedData);
  };
  return (
    <Container>
      <GroupActionContainer>
        <ActionRow>
          <CustomSearch
            placeholder="Search files"
            value={searchParam}
            onChange={e => setSearchParam(e.target.value)}
            onSearch={handleSearch}
          />
          <CustomButton size="medium" onClick={handleAddFile}>
            + New File
          </CustomButton>
        </ActionRow>
        <ActionRow>
          <BatchBox noHover>Selected({selectedFiles.length})</BatchBox>
          <BatchBox onClick={handleBatchDownload}>
            <Icon src={DownloadIcon} />
            {downloading ? (
              <CircularProgress size="18px" color="inherit" />
            ) : (
              'Download'
            )}
          </BatchBox>
          <BatchBox onClick={handleBatchDeletion}>
            <Icon src={TrashIcon} />
            {deleting ? (
              <CircularProgress size="18px" color="inherit" />
            ) : (
              'Delete'
            )}
          </BatchBox>
        </ActionRow>
      </GroupActionContainer>
      <ListTable
        loading={loading}
        header={
          <TableHeader
            checked={selectedFiles === uploadedData}
            handleSelectAll={handleSelectAll}
          />
        }
        hasContent={uploadedData.length > 0}
      >
        {uploadedData.map(item => {
          const handleEdit = () => {
            trackButton(edit_file);
            setFileToEdit(item);
          };
          const handleSelectFile = () => {
            trackButton(select_file);
            handleSelect(item);
          };
          return (
            <DropboxRow
              key={item.id}
              item={item}
              handleSelectFile={handleSelectFile}
              selectedFiles={selectedFiles}
              handleEdit={handleEdit}
            />
          );
        })}
      </ListTable>
      <Pagination
        totalPages={totalPages}
        currentPage={page + 1}
        changePage={changePage}
      />
      <FileEditModal
        fileToEdit={fileToEdit}
        closeEditModal={closeEditModal}
        updateMetadata={updateDocument}
        downloadFile={downloadFile}
        deleteFile={handleModalFileDeletion}
      />
    </Container>
  );
};
export default DropBoxDocs;

import styled from 'styled-components';
import Card from 'components/Card';

export const Container = styled(Card)`
  display: flex;
  align-items: center;
  padding: 24px;

  grid-row: 1/2;
  grid-column: 1/3;

  img {
    margin: auto 0 auto 32px;
    max-width: 220px;
    width: 36%;
    height: auto;
    @media only screen and (max-width: ${props =>
        props.theme.breakPoints.medium}) {
      display: none;
    }
  }
`;

export const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .announcement-text {
    display: flex;
    align-items: center;
    margin: 16px 0;
    color: ${props => props.theme.colors.brand.primary};
    ${props => props.theme.typography.control};
    .MuiSvgIcon-root {
      font-size: 16px;
      margin-right: 4px;
    }
  }
`;

export const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  ${props => props.theme.typography.title3};
  margin: 0;
  font-weight: 600;
`;

export const AnnouncementContainer = styled.div`
  ${props => props.theme.typography.control};
  line-height: 24px;
  font-weight: 500;
  color: ${props => props.theme.colors.neutral.grey10};
  background: ${props => props.theme.colors.neutral.offWhite02};
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const AnnouncementDate = styled.span`
  color: ${props => props.theme.colors.neutral.grey07};
  margin: 0 0 8px 0;
`;

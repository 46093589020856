import styled from 'styled-components';
import { IconButton, CustomTextInput } from 'components/NewLyncworkUIKit';
import Card from 'components/Card';

export const Container = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  width: 640px;
  padding: 24px;
  ${props => props.theme.typography.body};
  p {
    margin: 4px 0;
    strong {
      color: ${props => props.theme.colors.feedback.error};
    }
  }
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 16px;
  height: 16px;
  ${props => props.theme.typography.body};
`;

export const Title = styled.h1`
  ${props => props.theme.typography.header3};
  color: ${props => props.theme.colors.feedback.error};
  font-weight: 600;
  margin: 0 0 16px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 16px;
  button {
    margin: 0 8px;
  }
`;

export const TextInput = styled(CustomTextInput)`
  width: 200px;
  margin: 16px 0;
  align-self: center;
`;

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import PostListPage from './PostListPage';
import AddEditPost from './AddEditPosts';

const ApplicationCommunity = () => {
  return (
    <Switch>
      <Route path="/admin-community" exact>
        <PostListPage key="post" />
      </Route>
      <Route path="/admin-community/drafts" exact>
        <PostListPage draft key="draft" />
      </Route>
      <Route path="/admin-community/posts/add" exact>
        <AddEditPost />
      </Route>
      <Route path="/admin-community/posts/edit/:id">
        <AddEditPost />
      </Route>
    </Switch>
  );
};

export default ApplicationCommunity;

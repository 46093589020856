import { JobTitleService } from 'services/jobtitle.service';

const useJobTitle = () => {
  const getAllJobTitles = async () => {
    const { data } = await JobTitleService.getAllJobTitles();
    return data;
  };

  const createJobTitle = async title => {
    const { data } = await JobTitleService.create({ title });
    return data;
  };

  const searchJobTitles = async title => {
    const { data } = await JobTitleService.getJobTitlesByTitle(title);
    return data;
  };

  return { getAllJobTitles, createJobTitle, searchJobTitles };
};
export default useJobTitle;

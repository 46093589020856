import React from 'react';
import { TableContainer, ContentContainer } from './styles';
import NameColumn from './NameColumn';
import TaskColumn from './TaskColumn';
const TaskTable = ({ journeyTasks, openTaskModal, changeTaskDay }) => {
  return (
    <TableContainer>
      <ContentContainer>
        <NameColumn journeyTasks={journeyTasks} openTaskModal={openTaskModal} />
        <TaskColumn
          journeyTasks={journeyTasks}
          openTaskModal={openTaskModal}
          changeTaskDay={changeTaskDay}
        />
      </ContentContainer>
    </TableContainer>
  );
};

export default TaskTable;

import React, { useState } from 'react';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import CategoryList from './components/CategoryList';
import PopularTopics from './components/PopularTopics';
import Topic from './components/Topic';
import Post from './components/Post';
import Search from './components/Search';
import { UserHelpContainer, UserHelpHeader, UserHelpSearch } from './styles';

const UserHelp = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const [ searchInput, setSearchInput ] = useState('');

  const handleSearchInputChange = (event) => setSearchInput(event.target.value);

  const handleSearch = () => history.push(`/help/search?q=${encodeURIComponent(searchInput)}`)

  return (
    <UserHelpContainer>
      <UserHelpHeader>
        I need help with:
      </UserHelpHeader>
      <UserHelpSearch
        placeholder="Search"
        value={searchInput}
        onChange={handleSearchInputChange}
        onSearch={handleSearch}
      />
      <Switch>
        <Route path={`${url}/topic/:topicId`}>
          <Topic />
        </Route>
        <Route path={`${url}/post/:postId`}>
          <Post />
        </Route>
        <Route path={`${url}/search`}>
          <Search />
        </Route>
        <Route path="/">
          <>
            <CategoryList />
            <PopularTopics />
          </>
        </Route>
      </Switch>
    </UserHelpContainer>
  );
};

export default UserHelp;

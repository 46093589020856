import React from 'react';
import { TableContainer, HeaderRow, Row, Cell, CellData } from '../UserTable';

import { getLicenseInfo } from '../utils';

const TableHeader = ({ hasLicenseInfo }) => (
  <HeaderRow>
    <Cell size="small">First Name</Cell>
    <Cell size="small">Last Name</Cell>
    <Cell>Company Email</Cell>
    <Cell>Personal Email</Cell>
    <Cell>Username</Cell>
    {hasLicenseInfo && (
      <>
        <Cell>License</Cell>
        <Cell>Lyncwork Role</Cell>
      </>
    )}
  </HeaderRow>
);

const UserLicenseCells = ({ userData }) => {
  const { roles, licenseManagements } = userData;
  const roleName = roles[0]?.roleName || 'None';
  const { licenseName } = getLicenseInfo(licenseManagements);
  return (
    <>
      <Cell>
        <CellData>{licenseName}</CellData>
      </Cell>
      <Cell>
        <CellData>{roleName}</CellData>
      </Cell>
    </>
  );
};

const UserRow = ({ userData, hasLicenseInfo }) => {
  const { firstName, lastName, emailPrimary, emailSecondary, username } =
    userData;
  return (
    <Row>
      <Cell size="small">
        <CellData>{firstName}</CellData>
      </Cell>
      <Cell size="small">
        <CellData>{lastName}</CellData>
      </Cell>
      <Cell>
        <CellData>{emailPrimary}</CellData>
      </Cell>
      <Cell>
        <CellData>{emailSecondary}</CellData>
      </Cell>
      <Cell>
        <CellData>{username}</CellData>
      </Cell>
      {hasLicenseInfo && <UserLicenseCells userData={userData} />}
    </Row>
  );
};

const Table = ({ userArray, hasLicenseInfo }) => {
  return (
    <TableContainer>
      <TableHeader hasLicenseInfo={hasLicenseInfo} />
      {userArray.map((userData, index) => (
        <UserRow userData={userData} key={index} />
      ))}
    </TableContainer>
  );
};

export default Table;

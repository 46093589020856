import React from 'react';
import { CustomButton } from 'components/NewLyncworkUIKit';
import { SectionTitle, Card, ToolBar } from './styles';
import TaskTable from './TaskTable';
import TaskModal from './TaskModal';
const JourneyTasks = ({ journeyTasksProps }) => {
  const {
    taskInfo,
    validationMessage,
    editTaskParams,
    openTaskModal,
    saveTaskModal,
    closeTaskModal,
    changeTaskDay,
    deleteTask,
    journeyTasks
  } = journeyTasksProps;
  return (
    <>
      <TaskModal journeyTasksProps={journeyTasksProps} />
      <Card>
        <ToolBar>
          <SectionTitle>Journey Tasks</SectionTitle>
          <CustomButton size="medium" onClick={() => openTaskModal()}>
            + New Task
          </CustomButton>
        </ToolBar>
        <TaskTable
          journeyTasks={journeyTasks}
          openTaskModal={openTaskModal}
          changeTaskDay={changeTaskDay}
        />
      </Card>
    </>
  );
};

export default JourneyTasks;

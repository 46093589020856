import styled from 'styled-components';
import Card from 'components/Card';

export const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 772px;
  height: 660px;
  padding: 24px;
  gap: 20px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    color: ${props => props.theme.colors.neutral.grey10};
    font-size: 1.5rem;
  }
  h3 {
    ${props => props.theme.typography.header3};
    font-weight: 600;
    margin: 0;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  p {
    margin: 0;
    ${props => props.theme.typography.body};
    color: ${props => props.theme.colors.neutral.grey08};
  }
  gap: 20px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.grey02};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

export const ContentContainer = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 0;
  }

  .sticky-top {
    position: sticky;
    top: 0;
  }
`;

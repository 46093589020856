import styled from 'styled-components';
import Card from 'components/Card';
import Avatar from 'components/NewLyncworkUIKit/Avatar';

export const Container = styled(Card)`
  display: flex;
  align-items: center;
  gap: 16px;
  height: 56px;
  width: 100%;
  padding: 8px 16px;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.colors.neutral.grey02};
  ${props => props.theme.typography.header3};
  font-weight: 500;
  color: ${props => props.theme.colors.neutral.grey10};
  button {
    margin: 0 0 0 auto;
  }
`;

export const StyledAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;
  margin: 8px;
`;

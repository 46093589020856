import React from 'react';
import ArticlePreview from '../ArticlePreview';
import { CategoryContainer, Carousel, Article, Heading } from './styles';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { selectSideNavOpen } from 'redux/layout/layout.selector';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

function Arrow(props) {
  const { className, style, onClick, back } = props;
  return (
    <div
      className={className}
      style={{ display: 'block', color: '#4d4d4d', fontSize: '0px' }}
      onClick={onClick}
    >
      {back ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
    </div>
  );
}

const ArticleCatagories = ({ category, sideNavOpen }) => {
  const settings = {
    className: 'center',
    infinite: true,
    centerPadding: '60px',
    slidesToShow: 4,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    speed: 1500,
    nextArrow: <Arrow />,
    prevArrow: <Arrow back />,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1980,
        settings: {
          slidesToShow: sideNavOpen ? 4 : 5,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1680,
        settings: {
          slidesToShow: sideNavOpen ? 3 : 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: sideNavOpen ? 2 : 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: sideNavOpen ? 1 : 2,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <CategoryContainer className="CategoryContainer">
      <Heading className="Heading">{category.name}</Heading>
      {/* <Carousel className="Carousel"> */}
      <Slider {...settings}>
        {category.posts.map((article, index) => (
          <Article className="Article" key={index} index={index}>
            <ArticlePreview data={article} hover="vertical" />
          </Article>
        ))}
      </Slider>
      {/* </Carousel> */}
    </CategoryContainer>
  );
};
const mapStateToProps = createStructuredSelector({
  sideNavOpen: selectSideNavOpen
});
export default connect(mapStateToProps)(ArticleCatagories);

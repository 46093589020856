import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';
import Logo from 'assets/images/navIcons/Logo.svg';
import {
  Container,
  StyledLogo,
  Title,
  ToolBar,
  BackButton,
  PageTitle
} from './styles';
import { NotificationContext, NotificationToggle } from './Notifications';
import UserPortrait from './UserPortrait';
import pathData from './pathData';

const autoLogoutRoute = ['login', 'forgot-password', 'reset-password'];

const getCurrentPageData = pathname => {
  const currentPageData = pathData.find(data =>
    pathname.startsWith(data.pathname)
  );
  if (!currentPageData) {
    return { title: '', canGoBack: false };
  }
  const { title } = currentPageData;
  if (title === 'Dashboard') {
    return { title, canGoBack: false };
  }

  const canGoBack = pathname !== currentPageData.pathname;
  return { title, canGoBack };
};

const TopNav = ({ isTransparent }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const isAutoLogout = autoLogoutRoute.some(route => pathname.includes(route));
  const isDashboard = pathname.startsWith('/dashboard');

  const { title, canGoBack } = getCurrentPageData(pathname);
  const goBack = () => history.goBack();

  return (
    <NotificationContext shouldFetch={!isAutoLogout}>
      <Container isAutoLogout={isAutoLogout} isTransparent={isTransparent}>
        {canGoBack && (
          <BackButton onClick={goBack}>
            <ArrowBack /> Back
          </BackButton>
        )}
        <Title isDashboard={isDashboard} isAutoLogout={isAutoLogout}>
          <StyledLogo src={Logo} />
          Lyncwork
        </Title>
        {!isDashboard && <PageTitle>{title}</PageTitle>}

        {!isAutoLogout && (
          <ToolBar>
            <NotificationToggle />
            <UserPortrait />
          </ToolBar>
        )}
      </Container>
    </NotificationContext>
  );
};

export default TopNav;

import styled from 'styled-components';
import colors from 'designSystem/foundations/colors';
import { CustomTextInput } from 'components/NewLyncworkUIKit';
const maxWidth = '668px';
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: ${maxWidth};
  margin-top: 32px;
  @media only screen and (max-width: 700px) {
    width: 80%;
  }
`;
export const FormContainer = styled.form`
  display: flex;
  max-width: 448px;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;
export const ImageCircleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 137px;
`;
export const GroupTitle = styled.p`
  width: 100%;
  padding-bottom: 8px;
  border-bottom: 1px solid #8e8e93;
  text-align: left;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* or 137% */

  /* Off Black */

  color: #1d1d20;
`;
export const GroupWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const TextInput = styled(CustomTextInput)`
  width: ${({ inputData }) =>
    inputData.name === 'firstName' || inputData.name === 'lastName'
      ? 'calc(50% - 4px)'
      : '100%'};
  margin-right: ${({ inputData }) => inputData.name === 'firstName' && '8px'};
`;
export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 208px;
`;
export const ButtonSpacer = styled.div`
  width: 16px;
`;
export const Heading = styled.h2`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  margin: 8px 0;
  margin-top: 0px;
  /* identical to box height, or 129% */

  text-align: center;
  @media only screen and (max-width: 700px) {
    padding: 0 1em;
  }
`;
export const Paragraph = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 129% */

  text-align: center;
  margin: 0;
  margin-bottom: 32px;
  @media only screen and (max-width: 700px) {
    padding: 0 1em;
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const InputLabel = styled.label`
  text-align: left;
  ${props => props.theme.typography.control};
  font-weight: 600;
  color: ${props => props.theme.colors.neutral.grey10};
  margin: 8px 0;
  span {
    color: ${props => props.theme.colors.feedback.error};
  }
`;

const errorColor = colors.feedback.error;
const regularLabelColor = colors.neutral.grey07;

export const InputSubLabel = styled.p`
  text-align: left;
  ${props => props.theme.typography.metadata};
  color: ${props => (props.error ? errorColor : regularLabelColor)};
  margin: 8px 0;
`;

InputSubLabel.defaultProps = { error: false };

import React from 'react';
import { useLocation } from 'react-router-dom';
import { useCompany } from 'hooks';
import { Grid } from './styles';
import {
  CompanyBasicInfo,
  CompanyAnnouncements,
  CompanyResources,
  CompanyAbout
} from './components';
const CompanyInfo = () => {
  const { pathname } = useLocation();
  const { companyInfo, loading, error, updateCompany } = useCompany();
  const canEdit = pathname.includes('admin');
  return (
    <Grid>
      <CompanyBasicInfo
        companyInfo={companyInfo}
        loading={loading}
        error={error}
        updateCompany={updateCompany}
        canEdit={canEdit}
      />
      <CompanyAnnouncements />
      <CompanyAbout
        companyInfo={companyInfo}
        loading={loading}
        updateCompany={updateCompany}
        canEdit={canEdit}
      />
      <CompanyResources />
    </Grid>
  );
};

export default CompanyInfo;

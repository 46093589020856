import React from 'react';

import generateMediaUrl from 'utilities/generateMediaUrl';

import { Avatar, CustomButton } from 'components/NewLyncworkUIKit';

import { SubscriptionItem, SubscriptionDetail, AddedStatus } from './styles';

const SubscriptionCard = ({ subscription, added, selectSubscription }) => {
  const {
    application: { id: appId, appName, appDesc, appLogo }
  } = subscription;

  const src = generateMediaUrl(appLogo);
  const handleClick = () => selectSubscription(subscription);
  return (
    <SubscriptionItem>
      <Avatar src={src} name={appName} colorKey={appId} />
      <SubscriptionDetail>
        <span className="name">{appName}</span>
      </SubscriptionDetail>
      {added ? (
        <AddedStatus>Added</AddedStatus>
      ) : (
        <CustomButton size="small" onClick={handleClick}>
          Select
        </CustomButton>
      )}
    </SubscriptionItem>
  );
};

export default SubscriptionCard;

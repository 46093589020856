import styled from 'styled-components';
import Card from 'components/Card';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 0 24px;
  box-sizing: border-box;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: #3a3a3b90;
`;

export const KeyStep = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  gap: 8px;
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.neutral.grey08};

  h3 {
    margin: 0 0 16px 0;
    ${props => props.theme.typography.header3};
    color: ${props => props.theme.colors.neutral.grey10};
    font-weight: 600;
  }
  img {
    width: 80%;
    height: 200px;
    object-fit: contain;
  }
  span {
    margin: 16px 0;
    text-align: center;
  }
`;

export const ExitContainer = styled(KeyStep)`
  h3 {
    align-self: flex-start;
  }
  p {
    align-self: flex-start;
    margin: 0;
  }

  .button-container {
    display: flex;
    gap: 4px;
    margin: 12px 0 0;
    align-self: flex-end;
  }
`;

import React from 'react';
import { Container, Cell } from './styles';
const RoleListHeader = () => {
  return (
    <Container>
      <Cell>Name</Cell>
      <Cell>Admin Access</Cell>
      <Cell flex={2}>Applications</Cell>
      <Cell flex={2}>Users</Cell>
      {/* for edit button */}
      <Cell />
    </Container>
  );
};

export default RoleListHeader;

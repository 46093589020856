import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  width: 80%;
`;

export const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.h3`
  ${props => props.theme.typography.header2};
  font-weight: 600;
  margin: 0;
`;

export const Tagline = styled.h3`
  ${props => props.theme.typography.control};
  margin: 4px 120px;
  text-align: center;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin: 32px 0 64px;

  width: 100%;
`;

import React from 'react';
import { Container, WeekContainer, DayContainer } from './styles';

const generateAscendingArray = length => {
  return new Array(length).fill(1).map((_, index) => index);
};

const Week = () => {
  return (
    <WeekContainer>
      <DayContainer />
      <DayContainer />
      <DayContainer />
      <DayContainer />
      <DayContainer />
      <DayContainer />
      <DayContainer />
    </WeekContainer>
  );
};

const TimeLineGrid = ({ numberOfWeeks }) => {
  const weekIndices = generateAscendingArray(numberOfWeeks);

  return (
    <Container>
      {weekIndices.map(weekIndex => (
        <Week key={weekIndex} />
      ))}
    </Container>
  );
};

export default TimeLineGrid;

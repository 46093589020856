import React, { useEffect, useState } from 'react';
import { Wizard } from 'pages/Onboarding/common';
import getPageData from './getPageData';
import useAuthentication from 'hooks/useAuthentication';
import { useCookies } from 'react-cookie';
import { AuthenticationService } from 'services/authentication.service';
import { UserService } from 'services/user.service';
import {
  setGetSession,
  setGetUser,
  setLogin
} from 'redux/authentication/authentication.actions';
import translatePermissions from 'utilities/translatePermissions';
import { connect } from 'react-redux';
import jwt_decode from 'jwt-decode';
import LoadingScreen from 'components/LoadingScreen';

// will be removed later
import useEngagement from 'hooks/useEngagement';

const UserOnboarding = ({ setLogin, setGetSession, setGetUser }) => {
  const { user } = useAuthentication();
  const isEngagementAvailable = useEngagement();
  const [pageData, setPageData] = useState(
    getPageData(user, isEngagementAvailable)
  );

  return <Wizard pageData={pageData} onPageDataChange={setPageData} />;
};

export default UserOnboarding;

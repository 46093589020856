import styled, { css } from 'styled-components';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { getOptionLabel, getOptionValue } from 'utilities/dropdown';
import { colors } from 'designSystem/foundations';

const {
  neutral: { grey03, grey05, grey10, offWhite02 }
} = colors;

const widthCSS = css`
  width: ${props => props.width}!important;
`;

const large = css`
  .lw-select__control {
    min-height: 40px;
  }
`;

const small = css`
  .lw-select__control {
    min-height: 20px;
  }
  .lw-select__indicator {
    padding: 0 10px 0 6px;
  }
  .lw-select__value-container {
    padding: 0;
  }
`;

export const dropdownMenuSize = { large, small };

export const style = css`
  ${props => props.theme.typography.control};
  color: ${grey10};
  ${props => props.width && widthCSS};
  ${props => dropdownMenuSize[props.size]};
  .lw-select__control {
    border: 1px solid ${grey05};
    border-radius: 4px;
  }
  .lw-select__control--is-focused {
    box-shadow: none;
  }
  .lw-select__control--is-focused:hover {
    border-color: ${grey05};
  }
  .lw-select__option--is-focused,
  .lw-select__option--is-selected {
    color: ${grey10};
    background-color: ${offWhite02};
  }
  .lw-select__option {
    text-align: left;
  }
  .lw-select__option:active {
    background-color: ${offWhite02};
  }
  .lw-select__menu {
    z-index: 10000;
    box-shadow: 0 4px 11px hsl(0deg 0% 0% / 10%);
    border: 1px solid ${grey03};
  }
  .lw-select__menu-list {
    /* max-height: unset; */
    max-height: 412px;
  }
  .lw-select__value-container {
    padding-left: 12px;
    padding-right: 12px;
    font-family: 'Work Sans', sans-serif;
  }

  .lw-select__single-value {
    font-family: 'Work Sans', sans-serif;
    margin-right: 0;
  }

  .lw-select__multi-value {
    height: 24px;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 0 0 0 8px;
    margin: 1px 4px;
    background-color: ${grey03};
    .lw-select__multi-value__remove {
      height: 100%;
    }
  }
`;

const StyledSelect = styled(Select)`
  ${style}
`;

export const defaultProps = {
  classNamePrefix: 'lw-select',
  menuPlacement: 'auto'
};

StyledSelect.defaultProps = defaultProps;

export const StyledAsyncSelect = styled(AsyncSelect)`
  ${style}
`;

export const asyncDefaultProps = {
  ...defaultProps,
  defaultOptions: true,
  getOptionLabel,
  getOptionValue
};

StyledAsyncSelect.defaultProps = asyncDefaultProps;

export default StyledSelect;

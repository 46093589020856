const env = {
  frontDoorDomain:
    process.env.REACT_APP_FRONT_DOOR_DOMAIN || 'http://api.lync.work',
  storeDomain:
    process.env.REACT_APP_STORE_DOMAIN ||
    ' http://api.lync.work/services/store',
  mediaDomain: process.env.REACT_APP_MEDIA_DOMAIN || 'https://media.lync.work',
  serviceDomain:
    process.env.REACT_APP_SERVICE_DOMAIN || 'http://localhost:8080/services',
  matomoUrlBase:
    process.env.REACT_APP_MATOMO_URL || 'https://lyncwork.matomo.cloud/',
  matomoSiteId: process.env.REACT_APP_MATOMO_SITE_ID || '1',
  environment: process.env.REACT_APP_ENVIRONMENT || ''
};

export const isDev = env.environment === 'dev';

export default env;

import React from 'react';

import HoverInfo from 'components/HoverInfo';

import {
  StickyContainer,
  Title,
  AppDetailContainer,
  LicenseIssueContainer,
  AppListContainer
} from './styles';

import { ApplicationDetail } from './ApplicationLicenseChange';

const getLicenseName = license => {
  if (!license) {
    return null;
  }
  const name = license.applicationLicenseInformation.licenseName;
  return name.toLowerCase();
};

const getActionText = license => {
  const licenseName = getLicenseName(license);
  if (!licenseName) {
    return `Action: Proceed Without License`;
  }
  return `Action: Replace with ${licenseName}`;
};

const ToolTipTitle = ({ licenseInfo }) => {
  const {
    newIntendedLicense,
    oldLicense,
    assignedLicense,
    defaultLicense
  } = licenseInfo;
  const intendedLicenseName = getLicenseName(newIntendedLicense);
  const oldLicenseName = getLicenseName(oldLicense);
  const assignedLicenseName = getLicenseName(assignedLicense);
  const defaultLicenseName = getLicenseName(defaultLicense);

  if (!assignedLicenseName) {
    return (
      <span>
        Both the preferred licence (<strong>{intendedLicenseName}</strong>) and
        the default licence (<strong>{defaultLicenseName}</strong>) for this
        application are are unavailable.
      </span>
    );
  }

  return (
    <span>
      The preferred licence (<strong>{intendedLicenseName}</strong>), thus the
      default license (<strong>{defaultLicenseName}</strong>) for this
      application will be assigned.
    </span>
  );
};

const LicenseIssue = ({ licenseInfo }) => {
  const { application, assignedLicense } = licenseInfo;
  const actionText = getActionText(assignedLicense);
  return (
    <LicenseIssueContainer>
      <AppDetailContainer>
        <ApplicationDetail
          application={application}
          license={assignedLicense}
        />
      </AppDetailContainer>
      <div className="license-issue-detail">
        <span className="warning">Usage Limit Reached</span>
        <span className="action">
          {actionText}{' '}
          <HoverInfo
            className="action-tooltip"
            title={<ToolTipTitle licenseInfo={licenseInfo} />}
          />
        </span>
      </div>
    </LicenseIssueContainer>
  );
};

const ApplicationLicenseIssues = ({ appsWithLicenseIssues }) => {
  return (
    <>
      <StickyContainer>
        <Title>These licences will not be assigned as usual: </Title>
      </StickyContainer>
      <AppListContainer>
        {appsWithLicenseIssues.map(licenseInfo => {
          const key = licenseInfo.application.id;
          return <LicenseIssue key={key} licenseInfo={licenseInfo} />;
        })}
      </AppListContainer>
    </>
  );
};

export default ApplicationLicenseIssues;

import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  Content,
  ProgressButton,
  Right,
  SideBarLogo,
  SideBarMobileHeader
} from './styles';
import {
  ProgressDesktop,
  ProgressMobile,
  SideBarDesktop,
  SideBarMobile
} from 'pages/Onboarding/common';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useButtonTrack } from 'matomo/hooks';
import imageLyncworkLogo from 'assets/images/Onboarding/lyncworklogo.svg';

const stepsCompleted = (pathname, data) => {
  const currentIndex = data.indexOf(data.find(x => x.pathName === pathname));
  const currentStep = currentIndex + 1;
  const totalSteps = data.length;
  return `Step ${currentStep}/${totalSteps}`;
};

const Wizard = ({ pageData, onPageDataChange }) => {
  const trackButton = useButtonTrack();
  const [navOpen, setNavOpen] = useState(false);
  const [completedPaths, setCompletePaths] = useState([]);

  const { pathname } = useLocation();
  const history = useHistory();
  const contentRef = useRef();
  const rightSectionRef = useRef();

  useEffect(() => {
    contentRef.current.scrollTop = 0;
    rightSectionRef.current.scrollTop = 0;
    setNavOpen(false);
  }, [pathname]);

  const toggleNav = () => {
    setNavOpen(open => !open);
  };

  const isAdminSetup = pathname.startsWith('/admin-setup');
  const matomoPrefix = isAdminSetup ? 'admin setup' : 'onboarding';
  const nextPage = () => {
    const currentIndex = pageData.indexOf(
      pageData.find(x => x.pathName === pathname)
    );
    const {
      pathName,
      progress: { title }
    } = pageData[currentIndex + 1];
    trackButton(`${matomoPrefix} next button -> ${title}`);
    history.push(pathName);
    setCompletePaths(oldPaths =>
      oldPaths.includes(pathname) ? oldPaths : [...oldPaths, pathname]
    );
  };

  const prevPage = () => {
    const currentIndex = pageData.indexOf(
      pageData.find(x => x.pathName === pathname)
    );
    const {
      pathName,
      progress: { title }
    } = pageData[currentIndex - 1];
    trackButton(`${matomoPrefix} prev button -> ${title}`);
    history.push(pathName);
  };

  return (
    <Container>
      <SideBarDesktop pageData={pageData} />
      <SideBarMobileHeader>
        <SideBarLogo src={imageLyncworkLogo} />
        <ProgressButton onClick={toggleNav} isOpen={navOpen}>
          {stepsCompleted(pathname, pageData)}
          <KeyboardArrowDownIcon />
        </ProgressButton>
      </SideBarMobileHeader>
      <ProgressMobile
        pageData={pageData}
        isOpen={navOpen}
        completedPaths={completedPaths}
      />
      <Right className="RIGHT" ref={rightSectionRef}>
        <SideBarMobile pageData={pageData} isOpen={navOpen} />
        <ProgressDesktop pageData={pageData} completedPaths={completedPaths} />
        <Content ref={contentRef}>
          <Switch>
            {pageData.map((data, i) => {
              const { pathName, Component } = data;
              return (
                <Route key={pathName} path={pathName} exact>
                  <Component
                    prevPage={prevPage}
                    nextPage={nextPage}
                    completedPaths={completedPaths}
                    pageData={data}
                    onPageDataChange={data => {
                      pageData[i] = data;
                      onPageDataChange([...pageData]);
                    }}
                  />
                </Route>
              );
            })}
          </Switch>
        </Content>
      </Right>
    </Container>
  );
};

export default Wizard;

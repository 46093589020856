import React from 'react';
import Keycloak from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web';

const getRealm = () => {
  const hostname = window.location.hostname;
  if (hostname === 'localhost') {
    return 'hugoposh';
  }
  const realm = hostname.split('.')[0];
  return realm;
};

const realm = getRealm();

const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID
});

const KeyCloakWrapper = ({ children }) => {
  return (
    <ReactKeycloakProvider authClient={keycloak}>
      {children}
    </ReactKeycloakProvider>
  );
};

export default KeyCloakWrapper;

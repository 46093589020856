import React from 'react';

import imageEngagement from 'assets/images/Onboarding/Engagement.svg';

import { useMobileTutorial } from 'context/MobileTutorialContext';
import CustomButton from 'components/NewLyncworkUIKit/CustomButton';
import { KeyStep, Overlay } from './styles';

const TutorialFinish = () => {
  const { confirmExit } = useMobileTutorial();
  return (
    <Overlay>
      <KeyStep>
        <h3>That's all for now!</h3>
        <img src={imageEngagement} alt="illustration" />
        <span>
          Congratulations!!! You have completed the Lyncwork introductory tour!
        </span>
        <CustomButton size="medium" onClick={confirmExit}>
          Okay
        </CustomButton>
      </KeyStep>
    </Overlay>
  );
};

export default TutorialFinish;

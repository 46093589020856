const oneHourInMilliseconds = 3600 * 1000;

const now = new Date(Date.now());
const inAnHour = new Date(Date.now() + oneHourInMilliseconds);

export const exampleJourney = {
  id: 'tutorial',
  workflowName: 'Tutorial',
  workflowDesc: 'Tutorial',
  taskWork: {
    completedTasks: 0,
    total: 1,
    lastDueDate: inAnHour
  }
};

export const exampleTask = {
  id: 'test',
  schedStartDate: now,
  schedDueDate: inAnHour,
  actualStartDate: null,
  actualCompleteDate: null,
  task: {
    id: 79,
    taskEventName: 'CalendarEVent',
    taskEventIndicator: null,
    taskType: 'EVENT',
    taskDesc: 'CalendarEVent',
    taskEventOrder: null,
    startDay: 1,
    endDay: 2,
    taskProperties: {},
    canDelete: false,
    taskAttribute: 'MANUAL_WORKFLOW_ONLY'
  }
};

export const steps = [
  {
    stepNumber: 1,
    prompt: {
      title: 'Dashboard',
      body: 'This is your dashboard. Here you can find everything you need at a glance!',
      placement: 'default'
    },
    unmaskedElement: null
  },
  {
    stepNumber: 2,
    prompt: {
      title: 'Announcements',
      body: 'On top of the dashboard you can find announcements from your company.',
      placement: 'bottom'
    },
    unmaskedElement: 'overview'
  },
  {
    stepNumber: 3,
    prompt: {
      title: 'Daily Agenda',
      body: 'Your agenda shows all of your ongoing tasks for the day.',
      placement: 'bottom'
    },
    unmaskedElement: 'today-agenda'
  },
  {
    stepNumber: 4,
    prompt: {
      title: 'Upcoming Tasks',
      body: 'You can find your upcoming tasks here.',
      placement: 'top'
    },
    unmaskedElement: 'upcoming-tasks'
  },
  {
    stepNumber: 5,
    prompt: {
      title: 'Navigation',
      body: 'Your agenda shows all of your ongoing tasks for the day.',
      placement: 'default'
    },
    unmaskedElement: 'navigation'
  },
  {
    stepNumber: 6,
    prompt: {
      title: 'Navigation',
      body: 'As you can see, “Dashboard” is currently highlighted. You can return to this page by clicking on “Dashboard” anytime!',
      placement: 'default'
    },
    unmaskedElement: '/dashboard'
  },
  {
    stepNumber: 7,
    prompt: {
      title: 'Navigation',
      body: 'Try clicking on “Journeys” ',
      placement: 'default'
    },
    clickElement: true,
    unmaskedElement: '/user-journeys',
    nextLink: '/user-journeys'
  },
  {
    stepNumber: 8,
    prompt: {
      title: 'Journeys',
      body: 'This is the Journeys page! Journeys are a list of tasks that are assigned to you by your manager.',
      placement: 'default'
    }
  },
  {
    stepNumber: 9,
    prompt: {
      title: 'Journeys',
      body: 'We have made a Journey to help you get started.',
      placement: 'bottom'
    },
    unmaskedElement: 'journey-list-card'
  },
  {
    stepNumber: 10,
    prompt: {
      title: 'Click here to view',
      body: 'Click the “View” button to open the Journey!',
      placement: 'bottom'
    },
    clickElement: true,
    unmaskedElement: 'view-button',
    nextLink: '/user-journeys/tutorial'
  },
  {
    stepNumber: 11,
    prompt: {
      title: 'Journeys',
      body: 'Below is your first task for this Journey.',
      placement: 'top'
    },
    unmaskedElement: 'journey-task-card'
  },
  {
    stepNumber: 12,
    prompt: {
      title: 'Journeys',
      body: 'Click on the “Complete” button to mark the first task as complete.',
      placement: 'bottom'
    },
    clickElement: true,
    unmaskedElement: 'complete-task-button',
    nextLink: '/user-journeys/tutorial?completed=true'
  },
  {
    stepNumber: 13,
    prompt: {
      title: 'Journeys',
      body: 'Fantastic! As you can see, you have completed a task for this Journey.',
      placement: 'bottom'
    },
    unmaskedElement: 'single-journey-desc'
  },
  {
    stepNumber: 14,
    prompt: {
      title: 'Notifications',
      body: 'You can checkout your notifications here!',
      placement: 'bottom'
    },
    unmaskedElement: 'notification-button'
  }
];

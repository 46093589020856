import React from 'react';

import PortalContainer from 'components/PortalContainer';
import useModal from 'hooks/useModal';
import useApplicationCategories from 'hooks/useApplicationCategories';

import { Card, SectionTitle } from './styles';
import ApplicationList from './ApplicationList';
import SubscriptionModal from './SubscriptionModal';

const RoleApplications = () => {
  const applicationCategories = useApplicationCategories();
  const { isOpen, openModal, closeModal } = useModal();

  return (
    <Card>
      <PortalContainer isOpen={isOpen} handleClickOutside={closeModal}>
        <SubscriptionModal
          applicationCategories={applicationCategories}
          closeModal={closeModal}
        />
      </PortalContainer>
      <SectionTitle>Applications</SectionTitle>
      <ApplicationList openSubscriptionModal={openModal} />
    </Card>
  );
};

export default RoleApplications;

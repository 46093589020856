import { Link } from 'react-router-dom';
import styled from 'styled-components';
import colors from 'designSystem/foundations/colors';
import {
  multilineEllipsisFunc,
  scrollBar
} from 'StyleVariables/StandardComponents';
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  height: 100%;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 28px 0;
  h1 {
    ${props => props.theme.typography.title3};
    font-weight: 600;
    color: ${props => props.theme.colors.neutral.grey10};
    margin: 0 auto 0 0;
  }
  button {
    margin: 0 0 0 16px;
  }
`;

export const ActionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  margin-left: 16px;
`;

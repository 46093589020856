import jwt_decode from 'jwt-decode';

const translatePermissions = permissions => {
  //translates the notation to an array of useful strings. if they add a new feature, add it here.
  let translatedPermissions = [];

  permissions.forEach(permission => {
    switch (permission) {
      case 'ROLE_ADMIN':
        translatedPermissions.push('admin');
        break;
      case 'ROLE_LYNCWORK_APPLICATIONS':
        translatedPermissions.push('applications');
        break;
      case 'ROLE_LYNCWORK_CMS':
        translatedPermissions.push('cms');
        break;
      case 'ROLE_LYNCWORK_DOCUMENTS':
        translatedPermissions.push('documents');
        break;
      case 'ROLE_LYNCWORK_ROLES':
        translatedPermissions.push('roles');
        break;
      case 'ROLE_LYNCWORK_TASKS':
        translatedPermissions.push('tasks');
        break;
      case 'ROLE_LYNCWORK_TEAMS':
        translatedPermissions.push('teams');
        break;
      case 'ROLE_LYNCWORK_USERS':
        translatedPermissions.push('users');
        break;
      case 'ROLE_LYNCWORK_WORKFLOWS':
        translatedPermissions.push('workflows');
        break;
      case 'ROLE_USER':
        translatedPermissions.push('user');
        break;
      default:
        return;
    }
  });
  return translatedPermissions;
};

const getJWTPermissions = token => {
  const { roles } = jwt_decode(token);
  return translatePermissions(roles);
};

export default getJWTPermissions;

import React from 'react';
import { useForm } from 'react-hook-form';
import { CMSService } from 'services/cms.service';

import useAuthentication, {
  useAuthenticationActions
} from 'hooks/useAuthentication';

import { KeyContacts, SocialInfo } from './components';
import { Container, Title, ButtonContainer, CustomButton } from './styles';

const getUserFormData = user => {
  const { linkedinUrl, mediumUrl } = user;
  const descriptions = user.userDescription?.descriptions || [];
  const [word1 = '', word2 = '', word3 = ''] = descriptions;
  const formData = {
    linkedinUrl,
    mediumUrl,
    word1,
    word2,
    word3
  };
  return formData;
};

const UserWelcomeTeam = ({ prevPage, nextPage }) => {
  const { user } = useAuthentication();
  const { setGetUser } = useAuthenticationActions();
  const { register, handleSubmit, formState } = useForm({
    defaultValues: getUserFormData(user)
  });
  const { isDirty: isFormModified } = formState;

  const onSubmit = async (formData, e) => {
    e.preventDefault();
    if (!isFormModified) {
      nextPage();
      return;
    }
    const { linkedinUrl, mediumUrl } = formData;
    try {
      const response = await CMSService.updateUser({
        ...user,
        linkedinUrl,
        mediumUrl
      });
      const userResponse = response.data;
      const updatedUserObject = {
        ...user,
        ...userResponse
      };
      setGetUser(updatedUserObject);
      nextPage();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Title>Meet Your Team</Title>
      <KeyContacts user={user} />
      <SocialInfo register={register} />
      <ButtonContainer>
        <CustomButton variant="secondary" onClick={prevPage} type="button">
          Back
        </CustomButton>
        <CustomButton type="submit">Next</CustomButton>
      </ButtonContainer>
    </Container>
  );
};

export default UserWelcomeTeam;

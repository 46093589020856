import styled from 'styled-components';
import Card from 'components/Card';
import Avatar from 'components/NewLyncworkUIKit/Avatar';

const maxWidth = '448px';
export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: ${maxWidth};
  margin-top: 32px;
  gap: 8px;
  @media only screen and (max-width: 700px) {
    width: 80%;
  }
`;

export const Heading = styled.h2`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  margin: 8px 0;
  margin-top: 0px;
  /* identical to box height, or 129% */

  text-align: center;
  @media only screen and (max-width: 700px) {
    padding: 0 1em;
  }
`;

export const AvatarUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 200px;
  button {
    position: relative;
    input[type='file'] {
      cursor: pointer;
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
  p {
    margin: 0;
    ${props => props.theme.typography.control};
    color: ${props => props.theme.colors.neutral.grey06};
  }
`;

export const StyledAvatar = styled(Avatar)`
  width: 136px;
  height: 136px;
  font-size: 48px;
`;

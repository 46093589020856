import styled from 'styled-components';
import { CustomButton } from 'components/NewLyncworkUIKit';

export const BlankContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 24px;
  border-radius: 8px;
  margin-top: 24px;

  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.neutral.grey07};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    margin-bottom: 24px;
  }
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const BackButton = styled(CustomButton)`
  .MuiSvgIcon-root {
    margin-right: 4px;
  }
`;

import React from 'react';
import styled from 'styled-components';
import Info from '@material-ui/icons/Info';

import Tooltip from 'components/NewLyncworkUIKit/CustomTooltip';

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.neutral.grey05};
  cursor: pointer;
`;

const HoverInfo = ({ title, placement = 'top', className }) => {
  return (
    <Tooltip arrow title={title} placement={placement}>
      <IconContainer className={className}>
        <Info fontSize="inherit" />
      </IconContainer>
    </Tooltip>
  );
};

export default HoverInfo;

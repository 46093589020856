import styled from 'styled-components';
import { multilineEllipsis } from 'StyleVariables/StandardComponents';

const mobileBreakPoint = '900px';

export const Title = styled.h3`
  ${props => props.theme.typography.title3};
  font-weight: 600;
  margin: 48px 0 0;
  text-align: center;
`;

export const Tagline = styled.span`
  ${props => props.theme.typography.header3};
  text-align: center;
  width: 100%;
  max-width: 600px;
  margin: 8px 0 40px;
`;

export const AppListContainers = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 600px;
  justify-content: space-between;

  @media only screen and (max-width: 1100px) {
    justify-content: center;
  }
`;

export const AppContainer = styled.div`
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 292px;
  height: 160px;
  margin: 8px 0;
  padding: 32px;
  display: flex;
  @media only screen and (max-width: ${mobileBreakPoint}) {
    width: 100%;
  }
`;

export const AppLogoPlaceholder = styled.div`
  ${props => props.theme.typography.header2};
  background-color: ${props => props.backgroundColor};
  color: ${props => props.theme.colors.neutral.grey10};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

export const AppLogoContainer = styled.div`
  width: 48px;
  height: 48px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const AppTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AppName = styled.span`
  ${props => props.theme.typography.body};
  font-weight: 600;
`;
export const AppDesc = styled.span`
  ${multilineEllipsis}
  ${props => props.theme.typography.metadata};
`;

import styled from 'styled-components';
import { CustomRadio, AsyncSelect } from 'components/NewLyncworkUIKit';

export const Container = styled.div`
  margin: 0 auto 0 0;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.neutral.grey07};
  ${props => props.theme.typography.control};
  .MuiSvgIcon-root {
    font-size: 1rem;
  }
`;

export const FilterIconLabel = styled.span`
  margin: 0 8px 0 4px;
`;

export const RadioButton = styled(CustomRadio)`
  margin-right: 12px;
`;

export const StyledSelect = styled(AsyncSelect)`
  width: 200px;
`;

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import formatDate from 'utilities/formatDate';

import CustomButton from 'components/NewLyncworkUIKit/CustomButton';
import {
  AnnouncementContainer,
  AnnouncementDate,
  ButtonContainer
} from './styles';

const AnnouncementContent = ({ loading, announcement }) => {
  if (loading) {
    return <CircularProgress />;
  }

  if (!announcement) {
    return 'There are no announcements.';
  }

  const { content, updated_at } = announcement;
  const date = formatDate(updated_at, 'MMM d, yyyy');
  return (
    <>
      <AnnouncementDate>{date}</AnnouncementDate>
      {content}
    </>
  );
};

const AnnouncementView = ({ loading, announcement, toggleEdit }) => {
  return (
    <>
      <AnnouncementContainer>
        <AnnouncementContent loading={loading} announcement={announcement} />
      </AnnouncementContainer>
      <ButtonContainer>
        <CustomButton size="small" onClick={toggleEdit}>
          Add Announcement
        </CustomButton>
      </ButtonContainer>
    </>
  );
};

export default AnnouncementView;

import React from 'react';

import DeleteButton from 'components/DeleteButton';

import {
  Container,
  ColumnContainer,
  SectionContainer,
  SectionDescription,
  FieldContainer,
  StyledAvatar,
  UserInfoContainer,
  ButtonContainer
} from './styles';

const UserInfo = ({ label, value }) => (
  <UserInfoContainer>
    <span>
      <strong>{label}</strong>
    </span>
    <span>{value || 'N/A'}</span>
  </UserInfoContainer>
);

const ProfilePhoto = ({ userData }) => {
  const { firstName, avatar, id } = userData;
  const src = avatar?.url;
  return (
    <SectionContainer>
      <SectionDescription>
        <h2>Profile Photo</h2>
      </SectionDescription>
      <FieldContainer>
        <StyledAvatar src={src} name={firstName} colorKey={id} />
      </FieldContainer>
    </SectionContainer>
  );
};

const BasicInfo = ({ userData, optionalFields }) => {
  const { preferredName, dob, phonePrimary, phoneSecondary } = userData;
  return (
    <SectionContainer>
      <SectionDescription>
        <h2>Basic Information</h2>
      </SectionDescription>
      <FieldContainer>
        <UserInfo label="Preferred Name" value={preferredName} />
        <UserInfo label="Date of Birth" value={dob} />
        <UserInfo label="Primary Phone" value={phonePrimary} />
        <UserInfo label="Secondary Phone" value={phoneSecondary} />
        {optionalFields.map(({ order, label, name }) => (
          <UserInfo key={order} label={label} value={userData[name]} />
        ))}
      </FieldContainer>
    </SectionContainer>
  );
};
const EmergencyContact = ({ emergencyContact, optionalFields }) => {
  const { firstName, lastName, phoneNumber } = emergencyContact;
  const name = `${firstName} ${lastName}`;
  return (
    <SectionContainer>
      <SectionDescription>
        <h2>Emergency Contact</h2>
      </SectionDescription>
      <FieldContainer>
        <UserInfo label="Name" value={name} />
        <UserInfo label="Phone Number" value={phoneNumber} />
        {optionalFields.map(({ order, label, name }) => {
          if (label !== 'Relationship') {
            return null;
          }
          const value = emergencyContact[name];
          return <UserInfo key={order} label={label} value={value} />;
        })}
      </FieldContainer>
    </SectionContainer>
  );
};

const Address = ({ address }) => {
  const { lineOne, lineTwo, city, state, country, postalCode } = address;

  return (
    <SectionContainer>
      <SectionDescription>
        <h2>Address</h2>
      </SectionDescription>
      <FieldContainer>
        <UserInfo label="Line One" value={lineOne} />
        <UserInfo label="Line Two" value={lineTwo} />
        <UserInfo label="City" value={city} />
        <UserInfo label="State" value={state} />
        <UserInfo label="Zip Code" value={postalCode} />
        <UserInfo label="Country" value={country} />
      </FieldContainer>
    </SectionContainer>
  );
};
const PersonalInfo = ({
  userData,
  customUserFields,
  canDelete,
  handleDelete,
  deletingUser
}) => {
  const emergencyContact = userData.emergencyContact || {};
  const address = userData.addresses[0] || {};

  const {
    lyncWorkUserStatus: { status }
  } = userData;

  return (
    <>
      <Container>
        <ColumnContainer>
          <ProfilePhoto userData={userData} />
          <BasicInfo
            userData={userData}
            optionalFields={customUserFields.LyncWorkUser}
          />
        </ColumnContainer>
        <ColumnContainer>
          <EmergencyContact
            emergencyContact={emergencyContact}
            optionalFields={customUserFields.EmergencyContact}
          />
          <Address address={address} />
        </ColumnContainer>
      </Container>
      {status !== 'MONITORED' && (
        <ButtonContainer>
          {canDelete && (
            <DeleteButton loading={deletingUser} onClick={handleDelete} />
          )}
        </ButtonContainer>
      )}
    </>
  );
};

export default PersonalInfo;

const brand = {
  primary: '#E84740',
  primary01: '#f2dcdc',
  primaryLite: '#FDF0F0',
  secondary: '#3a3a3b'
};

const neutral = {
  black: '#000000',
  grey10: '#1c1c1e',
  grey09: '#2c2c2f',
  grey08: '#3a3a3b',
  grey07: '#6D6D70',
  grey06: '#8e8e93',
  grey05: '#aeaeb1',
  grey04: '#c6c7cc',
  grey03: '#d1d1d6',
  grey02: '#E7E7EB',
  grey01: '#F5F5FA',
  offWhite03: '#f2f2f2',
  offWhite02: '#f7f7f7',
  offWhite01: '#fbfbfb',
  white: '#ffffff'
};

const feedback = {
  success: '#30DB5B',
  error: '#FF5147',
  confirmation: '#409CFF',
  warning02: '#FFB340',
  warning01: '#FFD426'
};

const colors = {
  brand,
  neutral,
  feedback
};

export default colors;

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Edit } from '@material-ui/icons';

import { useModal } from 'hooks';
import { useButtonTrack } from 'matomo/hooks';
import { companyAboutEvents } from 'pages/CompanyInfo/instrumentationEvents';

import CompanyAboutEditModal from './CompanyAboutEditModal';
import { Container, SectionTitle, Paragraph, StyledIconButton } from './styles';

const { open_edit_modal, close_edit_modal } = companyAboutEvents;
const CompanyAbout = ({ companyInfo, loading, updateCompany, canEdit }) => {
  const trackButton = useButtonTrack();
  const { openModal, isOpen, closeModal } = useModal();

  if (loading) {
    return canEdit ? (
      <Container>
        <CircularProgress />
      </Container>
    ) : null;
  }

  const { companyDesc } = companyInfo;
  const { about, ourMission } = companyDesc || {};
  const hasContent = about || ourMission;
  if (!canEdit && !hasContent) {
    return null;
  }

  const handleEdit = () => {
    trackButton(open_edit_modal);
    openModal();
  };

  const handleModalClose = () => {
    trackButton(close_edit_modal);
    closeModal();
  };
  return (
    <>
      <CompanyAboutEditModal
        isOpen={isOpen}
        closeModal={handleModalClose}
        companyInfo={companyInfo}
        updateCompany={updateCompany}
      />
      <Container>
        <SectionTitle>About</SectionTitle>
        <Paragraph>{about}</Paragraph>
        <SectionTitle>Our Mission</SectionTitle>
        <Paragraph>{ourMission}</Paragraph>
        {canEdit && (
          <StyledIconButton onClick={handleEdit}>
            <Edit />
          </StyledIconButton>
        )}
      </Container>
    </>
  );
};

export default CompanyAbout;

import React, { useState } from 'react';
import { isValid, startOfDay } from 'date-fns';
import { CustomButton } from 'components/NewLyncworkUIKit';
import TrapFocus from 'components/TrapFocus';
import {
  Container,
  ButtonContainer,
  CloseButtonContainer,
  CloseModalButton
} from './styles';
import CalendarPicker from './CalendarPicker';

const sanitizeDate = date => {
  if (isValid(date)) {
    return date;
  }
  return startOfDay(new Date());
};

const Calendar = ({ date, onChange, closeModal, minDate, maxDate }) => {
  const [internalDate, setInternalDate] = useState(sanitizeDate(date));
  const handleSave = () => {
    onChange(internalDate);
    closeModal();
  };
  return (
    <TrapFocus>
      <Container onClick={e => e.stopPropagation()}>
        <CloseButtonContainer>
          <CloseModalButton onClick={closeModal} />
        </CloseButtonContainer>
        <CalendarPicker
          date={internalDate}
          onChange={setInternalDate}
          minDate={minDate}
          maxDate={maxDate}
        />
        <ButtonContainer>
          <CustomButton onClick={closeModal} size="large" variant="secondary">
            Cancel
          </CustomButton>
          <CustomButton size="large" onClick={handleSave}>
            Apply
          </CustomButton>
        </ButtonContainer>
      </Container>
    </TrapFocus>
  );
};

export default Calendar;

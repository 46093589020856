import styled from 'styled-components';
import { scrollBar } from 'StyleVariables/StandardComponents';

export const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  overflow-x: scroll;
  overflow-y: auto;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  z-index: 1;
`;

import React from 'react';
import {
  SideBarMobileContent,
} from './styles';
import SideBarContent from 'pages/Onboarding/common/SideBarContent';

const SideBarMobile = ({ pageData }) => {
  return (
    <SideBarMobileContent>
      <SideBarContent mobile pageData={pageData} />
    </SideBarMobileContent>
  );
};
export default SideBarMobile;

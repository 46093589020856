import React, { useCallback } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { toast } from 'react-hot-toast';
import { Toast } from 'components/toasts';

const defaultIcon = <CheckCircleIcon />;

const useToast = () => {
  const dismissToast = useCallback(toast.dismiss, [toast]);
  const createToast = ({
    title,
    message,
    link = '#',
    linkText,
    linkAction = () => {},
    icon = defaultIcon,
    size = 'large',
    options
  } = {}) => {
    const id = toast(
      t => (
        <Toast
          dismissToast={() => dismissToast(t.id)}
          title={title}
          message={message}
          icon={icon}
          link={link}
          linkText={linkText}
          linkAction={linkAction}
          size={size}
        />
      ),
      options
    );
    return id;
  };
  return { createToast, dismissToast };
};

export default useToast;

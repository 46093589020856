import React, { createContext, useState } from 'react';

import LightboxPrompt from 'components/LightboxPrompt';

export const DialogueContext = createContext();

const initialProp = { isOpen: false };
const DialogueContextProvider = ({ children }) => {
  const [dialogueProp, setDialogueProp] = useState(initialProp);

  const closeDialogue = () => setDialogueProp(initialProp);

  const showDialogue = ({
    title,
    message,
    onClickOutside = closeDialogue,
    confirmButtonText,
    cancelButtonText,
    confirmButtonAction,
    cancelButtonAction
  } = {}) => {
    setDialogueProp({
      isOpen: true,
      title,
      message,
      onClickOutside,
      confirmButtonText,
      cancelButtonText,
      confirmButtonAction,
      cancelButtonAction
    });
  };

  const updateDialogue = newProps =>
    setDialogueProp(oldProps => ({ ...oldProps, ...newProps }));

  const value = {
    showDialogue,
    closeDialogue,
    updateDialogue
  };
  return (
    <DialogueContext.Provider value={value}>
      {children}
      <LightboxPrompt {...dialogueProp} />
    </DialogueContext.Provider>
  );
};

export default DialogueContextProvider;

import styled from 'styled-components';
import CustomSearch from '../../components/NewLyncworkUIKit/CustomSearch';

export const UserHelpContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const UserHelpHeader = styled.h2`
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #8998aa;
`;

export const UserHelpSearch = styled(CustomSearch)`
  width: 40%;
  max-width: 550px;
`;

export const UserHelpSection = styled.div`
  box-shadow: 1px 1px 4px rgba(137, 152, 170, 0.331946);
  border-radius: 4px;
  background-color: #fff;
`;

export const UserHelpSectionHeader = styled.h3`
  font-size: 20px;
  font-weight: 500;
`;

export const Highlights = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 1em;
  width: 100%;
  height: 400px;
`;

export const Highlight = styled.div`
  grid-row-start: span ${props => (props.index > 0 ? '1' : '2')};
`;

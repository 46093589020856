import React, { useState } from 'react';

import DeleteButton from 'components/DeleteButton';
import CustomButton, {
  IconButton as CloseButton
} from 'components/NewLyncworkUIKit/CustomButton';

import { useRoleForm } from 'pages/Roles/components/RoleForm/RoleFormContext';

import CapabilityCard from './CapabilityCard';
import AhaModal from './AhaModal';

import {
  Container,
  TitleContainer,
  ContentContainer,
  ScrollContainer,
  CapabilityContainer,
  ButtonContainer,
  LicenseContainer,
  LicenseDetail
} from './styles';

const LicenseInfo = ({ license }) => {
  const name = license.applicationLicenseInformation.licenseName.toLowerCase();
  return (
    <LicenseContainer>
      <h2>License</h2>
      <LicenseDetail>{name}</LicenseDetail>
    </LicenseContainer>
  );
};

const Capabilities = ({
  applicationCapabilities,
  selectedCapabilities,
  toggleCapability
}) => {
  const selectedCapabilityIds = selectedCapabilities.map(({ id }) => id);
  const otherCapabilities = applicationCapabilities.filter(
    ({ id }) => !selectedCapabilityIds.includes(id)
  );
  return (
    <ScrollContainer>
      <CapabilityContainer>
        <h2>Selected Products</h2>
        {selectedCapabilities.map(applicationCapability => (
          <CapabilityCard
            key={applicationCapability.id}
            applicationCapability={applicationCapability}
            toggleCapability={toggleCapability}
            added
          />
        ))}
      </CapabilityContainer>
      {otherCapabilities.length > 0 && (
        <CapabilityContainer className="unselected-capabilities">
          <h2>Other associated products</h2>
          {otherCapabilities.map(applicationCapability => (
            <CapabilityCard
              key={applicationCapability.id}
              applicationCapability={applicationCapability}
              toggleCapability={toggleCapability}
            />
          ))}
        </CapabilityContainer>
      )}
    </ScrollContainer>
  );
};

const RegularApplicationModal = ({ selectedApplication, closeModal }) => {
  const {
    formState: { capabilities, licenseManagements },
    editApplicationCapability,
    removeApplication
  } = useRoleForm();

  const {
    appName,
    applicationCapabilities,
    id: appId,
    multiCapabilitiesAllowed
  } = selectedApplication;
  const [selectedCapabilities, setSelectedCapabilities] = useState(
    capabilities.filter(({ application }) => application.id === appId)
  );

  const toggleCapability = capability => {
    setSelectedCapabilities(oldCapabilities => {
      const oldIds = oldCapabilities.map(({ id }) => id);
      if (oldIds.includes(capability.id)) {
        return oldCapabilities.filter(({ id }) => id !== capability.id);
      }
      if (!multiCapabilitiesAllowed) {
        return [capability];
      }
      return [...oldCapabilities, capability];
    });
  };

  const capabilitySelected = selectedCapabilities.length > 0;

  const handleSave = () => {
    closeModal();
    editApplicationCapability(selectedApplication, selectedCapabilities);
  };

  const handleRemove = () => {
    closeModal();
    removeApplication(selectedApplication);
  };

  const license = licenseManagements.find(
    license => license.applicationLicenseInformation.application.id === appId
  );
  return (
    <Container onClick={e => e.stopPropagation()}>
      <TitleContainer>
        <h3>{appName}</h3>
        <CloseButton onClick={closeModal} />
        <h2>Edit Application Permissions</h2>
      </TitleContainer>
      <ContentContainer>
        <Capabilities
          applicationCapabilities={applicationCapabilities}
          selectedCapabilities={selectedCapabilities}
          toggleCapability={toggleCapability}
        />
        {license && <LicenseInfo license={license} />}
      </ContentContainer>
      <ButtonContainer>
        <DeleteButton size="small" onClick={handleRemove}>
          Remove from Role
        </DeleteButton>
        <CustomButton disabled={!capabilitySelected} onClick={handleSave}>
          Save
        </CustomButton>
      </ButtonContainer>
    </Container>
  );
};

const ApplicationModal = ({ selectedApplication, closeModal }) => {
  const { appAPIName } = selectedApplication;
  if (appAPIName !== 'aha') {
    return (
      <RegularApplicationModal
        selectedApplication={selectedApplication}
        closeModal={closeModal}
      />
    );
  }

  return <AhaModal application={selectedApplication} closeModal={closeModal} />;
};

export default ApplicationModal;

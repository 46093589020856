import styled from 'styled-components';
import { offGrey, white } from 'StyleVariables/LyncworkColors';
import colors from 'designSystem/foundations/colors';
import { CustomButton } from 'components/NewLyncworkUIKit';

const colorOptions = ['#4a90e2', '#50e3c2', '#9013fe', '#f5a623', '#ff6b6b'];
export const ImageContainer = styled.div`
  position:relative;
  justify-content:center;
   display: flex;
   background-image:url('${props => props.src}');
   background-position:center;
   background-size:cover;
   background-repeat:no-repeat;
   height:${props => props.height}px;
   max-width:${props => props.maxWidth}px;
   border: none;
   border-radius: 50%;
   width:100%;
   transition:all .3s;
   background-color:${props =>
     !props.src && colorOptions[props.firstName.length % colorOptions.length]};
`;
export const EditDiv = styled.div`
  position: absolute;
  top: -5px;
  right: -10px;

  padding: 6px;
  z-index: 4;
  width: 40px;
  height: 40px;
  input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-size: 0;
    cursor: pointer;
    opacity: 0;
    width: 100%;
    z-index: 5;
  }
`;
export const UploadErrorText = styled.p`
  color: red;
  font-size: 12px;
`;

export const UploadButton = styled(CustomButton)`
  position: relative;
  margin-top: 16px;
  input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-size: 0;
    cursor: pointer;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    box-sizing: border-box;
    border-radius: 100px;
  }
`;
UploadButton.defaultProps = { variant: 'secondary', size: 'medium' };

export const Placeholder = styled.h1`
  display: flex;
  align-items: center;
  font-size: ${props => props.fontSize || 'inherit'};
  font-weight: normal;
  color: ${colors.neutral.white};
`;
export const UploadInput = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid #f2dcdc;
  box-sizing: border-box;
  border-radius: 100px;
  width: 179px;
  height: 48px;
`;

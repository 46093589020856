import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import PortalContainer from 'components/PortalContainer';
import getPageData from 'pages/Onboarding/UserOnboarding/getPageData';
import TrapFocus from 'components/TrapFocus';
import useAuthentication from 'hooks/useAuthentication';

import {
  Container,
  TopBar,
  ContentContainer,
  RightSection,
  CloseButtonContainer
} from './styles';

import SideBar from './SideBar';
import Progress from './Progress';

const PreviewModal = ({
  pathName,
  isOpen,
  handleClose,
  previewTitle,
  children
}) => {
  const { user } = useAuthentication();
  const data = getPageData(user);
  const { sideBar: sideBarData } = data.find(
    item => item.pathName === pathName
  );

  return (
    <PortalContainer isOpen={isOpen}>
      <TrapFocus>
        <Container>
          <TopBar>
            {previewTitle}
            <CloseButtonContainer onClick={handleClose}>
              <CloseIcon />
            </CloseButtonContainer>
          </TopBar>
          <ContentContainer>
            <SideBar sideBarData={sideBarData} />
            <RightSection>
              <Progress pathName={pathName} data={data} />
              {children}
            </RightSection>
          </ContentContainer>
        </Container>
      </TrapFocus>
    </PortalContainer>
  );
};

export default PreviewModal;

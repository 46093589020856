import React from 'react';

import homeImage from './assets/images/page-icons/icon-home.png';

import dashboardA from './assets/images/navIcons/Dashboard - Active.svg';
import dashboardI from './assets/images/navIcons/Dashboard - Inactive.svg';

import teamsA from './assets/images/navIcons/Teams - Active.svg';
import teamsI from './assets/images/navIcons/Teams - Inactive.svg';

import rolesA from './assets/images/navIcons/Roles - Active.svg';
import rolesI from './assets/images/navIcons/Roles - Inactive.svg';

import usersA from './assets/images/navIcons/Users - Active.svg';
import usersI from './assets/images/navIcons/Users - Inactive.svg';

import journeysA from './assets/images/navIcons/Journeys - Active.svg';
import journeysI from './assets/images/navIcons/Journeys - Inactive.svg';

import applicationsA from './assets/images/navIcons/Applications - Active.svg';
import applicationsI from './assets/images/navIcons/Applications - Inactive.svg';

import documentsA from './assets/images/navIcons/Document - Active.svg';
import documentsI from './assets/images/navIcons/Documents - Inactive.svg';

import companyA from './assets/images/navIcons/Company Info - Active.svg';
import companyI from './assets/images/navIcons/Company Info - Inactive.svg';

import engagementA from './assets/images/navIcons/Engagement - Active.svg';
import engagementI from './assets/images/navIcons/Engagement - Inactive.svg';

import communityA from './assets/images/navIcons/Community - Active.svg';
import communityI from './assets/images/navIcons/Community - Inactive.svg';

import directoryA from './assets/images/navIcons/Directory - Active.svg';
import directoryI from './assets/images/navIcons/Directory - Inactive.svg';

import onboardingA from './assets/images/navIcons/Onboarding - Active.svg';
import onboardingI from './assets/images/navIcons/Onboarding - Inactive.svg';

import { isDev } from 'env';

export const configuredLayout = {
  currentLayout: 'classic',
  notificationsOpen: false
};

const pathToOmit = isDev
  ? []
  : ['/admin-engagement-analytics', '/reward-points'];

const shouldKeep = item => !pathToOmit.includes(item.href);

export const adminMenuItems = [
  {
    title: 'Dashboard',
    href: '/admin-dashboard',
    permission: 'admin',
    activeIcon: (
      <img src={dashboardA} style={{ height: 14 }} alt={'Dashboard'} />
    ),
    inactiveIcon: (
      <img src={dashboardI} style={{ height: 14 }} alt={'Dashboard'} />
    )
  },
  {
    title: 'Community',
    href: '/admin-community',
    permission: 'cms',
    activeIcon: (
      <img src={communityA} style={{ height: 14 }} alt={'Dashboard'} />
    ),
    inactiveIcon: (
      <img src={communityI} style={{ height: 14 }} alt={'Dashboard'} />
    )
  },
  {
    title: 'Teams',
    href: '/admin-teams',
    permission: 'teams',
    activeIcon: <img src={teamsA} style={{ height: 14 }} alt={'Dashboard'} />,
    inactiveIcon: <img src={teamsI} style={{ height: 14 }} alt={'Dashboard'} />
  },
  {
    title: 'Roles',
    href: '/admin-roles',
    permission: 'roles',
    activeIcon: <img src={rolesA} style={{ height: 14 }} alt={'Dashboard'} />,
    inactiveIcon: <img src={rolesI} style={{ height: 14 }} alt={'Dashboard'} />
  },
  {
    title: 'Users',
    href: '/admin-users',
    permission: 'users',
    activeIcon: <img src={usersA} style={{ height: 14 }} alt={'Dashboard'} />,
    inactiveIcon: <img src={usersI} style={{ height: 14 }} alt={'Dashboard'} />
  },
  {
    title: 'Journeys',
    href: '/admin-journeys',
    permission: 'workflows',
    activeIcon: (
      <img src={journeysA} style={{ height: 14 }} alt={'Dashboard'} />
    ),
    inactiveIcon: (
      <img src={journeysI} style={{ height: 14 }} alt={'Dashboard'} />
    )
  },
  {
    title: 'Onboarding',
    href: '/admin-onboarding',
    permission: 'cms',
    activeIcon: (
      <img src={onboardingA} style={{ height: 14 }} alt={'Dashboard'} />
    ),
    inactiveIcon: (
      <img src={onboardingI} style={{ height: 14 }} alt={'Dashboard'} />
    )
  },
  {
    title: 'Applications',
    href: '/admin-applications',
    permission: 'applications',
    activeIcon: (
      <img src={applicationsA} style={{ height: 14 }} alt={'Dashboard'} />
    ),
    inactiveIcon: (
      <img src={applicationsI} style={{ height: 14 }} alt={'Dashboard'} />
    )
  },
  {
    title: 'Company Info',
    href: '/admin-company-info',
    permission: 'admin',
    activeIcon: <img src={companyA} style={{ height: 14 }} alt={'Dashboard'} />,
    inactiveIcon: (
      <img src={companyI} style={{ height: 14 }} alt={'Dashboard'} />
    )
  },
  {
    title: 'Documents',
    href: '/admin-documents',
    permission: 'documents',
    activeIcon: (
      <img src={documentsA} style={{ height: 14 }} alt={'Dashboard'} />
    ),
    inactiveIcon: (
      <img src={documentsI} style={{ height: 14 }} alt={'Dashboard'} />
    )
  },
  {
    title: 'Engagement Analytics',
    href: '/admin-engagement-analytics',
    permission: 'admin',
    activeIcon: (
      <img src={engagementA} style={{ height: 14 }} alt={'Dashboard'} />
    ),
    inactiveIcon: (
      <img src={engagementI} style={{ height: 14 }} alt={'Dashboard'} />
    )
  }
].filter(shouldKeep);

export const userMenuItems = [
  {
    title: 'Dashboard',
    href: '/dashboard',
    activeIcon: <img src={dashboardA} alt={'Dashboard'} />,
    inactiveIcon: (
      <img src={dashboardI} style={{ height: 14 }} alt={'Dashboard'} />
    )
  },
  {
    title: 'Community',
    href: '/community',
    activeIcon: (
      <img src={communityA} style={{ height: 14 }} alt={'Dashboard'} />
    ),
    inactiveIcon: (
      <img src={communityI} style={{ height: 14 }} alt={'Dashboard'} />
    )
  },
  {
    title: 'Journeys',
    href: '/user-journeys',
    activeIcon: (
      <img src={journeysA} style={{ height: 14 }} alt={'Dashboard'} />
    ),
    inactiveIcon: (
      <img src={journeysI} style={{ height: 14 }} alt={'Dashboard'} />
    )
  },
  {
    title: 'Reward Points',
    href: '/reward-points',
    activeIcon: (
      <img src={engagementA} style={{ height: 14 }} alt={'Dashboard'} />
    ),
    inactiveIcon: (
      <img src={engagementI} style={{ height: 14 }} alt={'Dashboard'} />
    )
  },

  {
    title: 'Company Directory',
    href: '/company-directory',
    activeIcon: (
      <img src={directoryA} style={{ height: 14 }} alt={'Dashboard'} />
    ),
    inactiveIcon: (
      <img src={directoryI} style={{ height: 14 }} alt={'Dashboard'} />
    )
  },
  {
    title: 'Company Info',
    href: '/company-info',
    anyPermission: [
      'teams',
      'roles',
      'users',
      'tasks',
      'workflows',
      'applications'
    ],
    activeIcon: <img src={companyA} style={{ height: 14 }} alt={'Dashboard'} />,
    inactiveIcon: (
      <img src={companyI} style={{ height: 14 }} alt={'Dashboard'} />
    )
  }
].filter(shouldKeep);

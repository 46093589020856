import React from 'react';
import CodeIcon from '@material-ui/icons/Code';
import { Draggable } from 'react-beautiful-dnd';

import { LicenceCardContainer, DragHandle, DefaultStatus } from './styles';

const LicenceCard = ({ licence, index, selectLicence, selected }) => {
  const {
    id,
    applicationLicenseInformation: { licenseName },
    defaultLicense
  } = licence;
  const name = licenseName.toLowerCase();
  const draggableId = String(id);
  return (
    <Draggable draggableId={draggableId} index={index}>
      {provided => (
        <LicenceCardContainer
          ref={provided.innerRef}
          {...provided.draggableProps}
          onClick={selectLicence}
          selected={selected}
        >
          <DragHandle {...provided.dragHandleProps}>
            <CodeIcon />
          </DragHandle>
          {name}
          {defaultLicense && <DefaultStatus>Default</DefaultStatus>}
        </LicenceCardContainer>
      )}
    </Draggable>
  );
};

export default LicenceCard;

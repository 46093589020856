import React from 'react';
import { useParams } from 'react-router-dom';
import { PostContainer, PostContent } from './styles';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import { TopicContainer } from '../Topic/styles';

const Search = () => {
  const { postId } = useParams();

  return (
    <PostContainer>
      <Breadcrumbs items={[
        {
          text: 'Help',
          path: '/help',
        },
          {
          text: 'Applications',
          path: '/help/topic/applications',
        },
          {
          text: 'G Suite Integration',
        }
      ]} />
      <PostContent>Post Content</PostContent>
    </PostContainer>
  );
};

export default Search;

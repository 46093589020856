import styled from 'styled-components';
import colors from 'designSystem/foundations/colors';
import SearchIcon from '@material-ui/icons/Search';
export const Input = styled.input`
  height: 100%;
  flex: 1;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  margin: 0;
  padding: 0;
  color: ${colors.neutral.grey10};
  ::placeholder {
    color: ${colors.neutral.grey06};
  }
`;
export const Search = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: ${props => props.width};
  height: 40px;
  border-radius: 4px;
  border: solid 1px
    ${({ selected }) => (selected ? colors.neutral.grey05 : 'transparent')};
  background-color: ${({ offWhite }) =>
    offWhite ? colors.neutral.grey01 : colors.neutral.white};
  padding: 0 18px;
  ${Input}:focus & {
    border-color: #ff6b6b;
    color: ${colors.neutral.grey06};
  }
  box-sizing: border-box;
`;
export const IconSearch = styled(SearchIcon)`
  color: ${({ selected }) =>
    selected ? colors.neutral.grey07 : colors.neutral.grey05};
  margin-right: 8px;
`;

import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Info from '@material-ui/icons/Info';

import { JourneyService } from 'services/journeys.service';

import {
  CustomToggle,
  CustomTooltip,
  AsyncSelect,
  Label
} from 'components/NewLyncworkUIKit';

import { Card, SectionTitle, IconContainer, ToggleContainer } from './styles';

const getOptionLabel = journey => journey.workflowName;

const loadJourneysOmittingId = idToOmit => {
  const loadJourneys = async inputValue => {
    const response = await JourneyService.list({
      name: inputValue,
      repeatable: false
    });
    const journeys = response.data.content.filter(
      ({ id }) => id !== Number(idToOmit)
    );
    return journeys;
  };
  return loadJourneys;
};

const ToggleTooltip = ({ title }) => {
  return (
    <CustomTooltip arrow title={title} placement="top">
      <IconContainer>
        <Info fontSize="inherit" />
      </IconContainer>
    </CustomTooltip>
  );
};

const SelectPrerequisite = ({ prerequisiteWorkflow, selectPrerequisite }) => {
  const { id } = useParams();
  const loadJourneys = useCallback(loadJourneysOmittingId(id), [id]);
  return (
    <div>
      <Label>Prerequisite Journey</Label>
      <AsyncSelect
        placeholder="Type to search..."
        loadOptions={loadJourneys}
        defaultOptions
        value={prerequisiteWorkflow}
        onChange={selectPrerequisite}
        getOptionLabel={getOptionLabel}
      />
    </div>
  );
};

const JourneySettings = ({ journeySettingsProps }) => {
  const {
    notificationEnabled,
    toggleCalendarNotification,
    repeatable,
    eventbased,
    toggleTrigger,
    prerequisiteWorkflow,
    selectPrerequisite
  } = journeySettingsProps;
  return (
    <Card>
      <SectionTitle>Journey Settings</SectionTitle>
      <ToggleContainer>
        <CustomToggle
          label="Generate Calendar Events"
          checked={notificationEnabled}
          onChange={toggleCalendarNotification}
        />
        <ToggleTooltip title="When enabled, this Journey and its Tasks will automatically show up as events on your calendar service." />
      </ToggleContainer>
      <ToggleContainer>
        <CustomToggle
          label="Repeatable"
          checked={repeatable}
          name="repeatable"
          onChange={toggleTrigger}
        />
        <ToggleTooltip title="When enabled, this Journey and its Tasks will be repeatable." />
      </ToggleContainer>
      <ToggleContainer>
        <CustomToggle
          label="Triggered"
          checked={eventbased}
          name="eventbased"
          onChange={toggleTrigger}
        />
        <ToggleTooltip title="When enabled, this Journey will only be assigned when the selected Journey below is completed." />
      </ToggleContainer>
      {eventbased && (
        <SelectPrerequisite
          prerequisiteWorkflow={prerequisiteWorkflow}
          selectPrerequisite={selectPrerequisite}
        />
      )}
    </Card>
  );
};

export default JourneySettings;

import React from 'react';
import { EditorContainer } from './styles';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import uploadAdapter from './uploadAdapter';
import { Editor } from 'ckeditor5-custom-build/build/ckeditor';
import './styles.css';
const CustomRTE = ({
  data,
  callback,
  placeholder = 'Write your post here',
  addImage
}) => {
  return (
    <EditorContainer className="editor-container">
      <CKEditor
        editor={Editor}
        data={data}
        config={{
          removePlugins: ['Title'],
          mediaEmbed: {
            previewsInData: true
          },
          height: '100%',
          extraPlugins: [uploadAdapter(addImage)],
          entities: true,
          placeholder: placeholder,
          toolbar: {
            viewportTopOffset: 71,
            items: [
              'heading',
              '|',
              'fontColor',
              'fontSize',
              'bold',
              'italic',
              'underline',
              'link',
              '|',
              'alignment',
              'numberedList',
              'bulletedList',
              'blockQuote',

              '|',
              'imageUpload',
              'mediaEmbed',

              'insertTable',
              'undo',
              'redo'
            ]
          },
          language: 'en',
          image: {
            toolbar: [
              'imageTextAlternative',
              'imageStyle:full',
              'imageStyle:side',
              'imageAlignLeft',
              'imageAlignNone',
              'imageAlignRight'
            ]
          },
          table: {
            contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
          },
          title: {
            // placeholder: 'sdlfkjsldfkj'
          }
        }}
        onChange={(event, editor) => {
          const editorOutput = editor.getData();
          callback(editorOutput);
        }}
      />
    </EditorContainer>
  );
};

export default CustomRTE;

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Container,
  Card,
  ImageContainer,
  Description,
  SwipeInstruction
} from './styles';

const MobileCarousel = ({ data }) => {
  return (
    <Container>
      <Swiper slidesPerView="auto">
        {data.map(reward => {
          const { id, image, title } = reward;
          return (
            <SwiperSlide key={id}>
              <Card>
                <ImageContainer src={image}>
                  <img src={image} alt={title} />
                </ImageContainer>
                <Description>{title}</Description>
              </Card>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <SwipeInstruction> - Swipe to see more -</SwipeInstruction>
    </Container>
  );
};

export default MobileCarousel;

import styled from 'styled-components';
import { multilineEllipsis } from 'StyleVariables/StandardComponents';

import { Avatar } from 'components/NewLyncworkUIKit';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 24px;
  box-sizing: border-box;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 24px;
  padding: 24px 0;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.grey02};
  &:last-child {
    border-bottom: none;
  }
  h2 {
    margin: 0;
    ${props => props.theme.typography.header3};
  }
`;

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey07};

  flex-basis: ${props => (props.halfWidth ? '50%' : '100%')};

  strong {
    font-weight: 600;
    color: ${props => props.theme.colors.neutral.grey10};
  }
`;

export const KeyContactAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;
`;

export const KeyContactDetail = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const KeyContactName = styled.span`
  ${multilineEllipsis}
`;

KeyContactName.defaultProps = { lineClamp: 1 };

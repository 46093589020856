import React, { useEffect, useState } from 'react';
import DesktopCarousel from './DesktopCarousel';
import MobileCarousel from './MobileCarousel';
import {
  ContentStyles,
  ButtonContainer,
  ButtonSpacer,
  Card,
  CardContainer,
  Paragraph,
  ImageBackground,
  Bottom,
  Image,
  ImageContainer,
  Heading,
  Grid,
  GridCard,
  GridP,
  GridImage,
  Line,
  Value,
  CardHeading
} from './styles';
import LoadingScreen from 'components/LoadingScreen';
import Button from 'components/NewLyncworkUIKit/CustomButton';
import { fakeData } from './fakeData';
import HeartCal from 'assets/images/GenericIcons/HeartCal.svg';
import SpeechBubble from 'assets/images/GenericIcons/SpeechBubble.svg';
import SuitAndTie from 'assets/images/GenericIcons/SuitAndTie.svg';
import ThumbsUp from 'assets/images/GenericIcons/ThumbsUp.svg';

const Engagement = ({ nextPage, prevPage }) => {
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState();
  //   useEffect(() => {
  //     (async () => {
  //when you can call for rewards data, add it here, just saved you some time
  //       const { data } = await CMSService.getCustomOnboarding();
  //       setPageData(data);
  //       setLoading(false);
  //     })();
  //   }, []);
  if (loading) {
    return <LoadingScreen />;
  } else {
    return (
      <ContentStyles>
        <Heading>Collect Engagment Rewards</Heading>
        <Paragraph>Collecting engagement rewards is easy and fun! </Paragraph>
        <Grid>
          <GridCard>
            <GridImage src={SpeechBubble} />
            <GridP>Attend a company social</GridP>
          </GridCard>
          <GridCard>
            <GridImage src={ThumbsUp} />
            <GridP>Give Kudos to an employee</GridP>
          </GridCard>
          <GridCard>
            <GridImage src={SuitAndTie} />
            <GridP>Log into Lyncwork everyday</GridP>
          </GridCard>
          <GridCard>
            <GridImage src={HeartCal} />
            <GridP>Attend an employee coffee chat!</GridP>
          </GridCard>
        </Grid>
        <Heading>Use Engagement Rewards </Heading>
        <Paragraph>
          Browse large selection of items and use your rewards to get awesome
          perks!
        </Paragraph>
        <DesktopCarousel data={fakeData} />
        <MobileCarousel data={fakeData} />
        <ButtonContainer>
          <Button onClick={prevPage} variant="secondary" width="160px">
            Back
          </Button>
          <ButtonSpacer />
          <Button onClick={nextPage} width="160px">
            Next
          </Button>
        </ButtonContainer>
      </ContentStyles>
    );
  }
};
export default Engagement;

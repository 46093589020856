import styled from 'styled-components';
import { UserHelpSection } from '../../styles';

export const SearchContainer = styled(UserHelpSection)`
  width: 100%;
  box-sizing: border-box;
  padding: 6% 5%;
  margin-top: 40px;
`;

export const SearchHeaderContainer = styled.div`
  margin-top: 50px;
  padding-left: 5px;      
  padding-bottom: 20px;
  border-bottom: 1px solid #dcdde4;
  display: flex;
  align-items: center;
`;

export const SearchHeaderTitle = styled.div`
  font-weight: 400;
  font-size: 18px;
  color: #66707f;
  & strong {
    font-weight: 500;
    color: #000;
  }
`;

import React from 'react';
import UnderlineLink from 'components/UnderlineLink';

import { exampleJourney } from 'context/MobileTutorialContext/constants';

import {
  Container,
  Header,
  ButtonContainer,
  JourneysListContainer,
  GridItem,
  LoaderContainer
} from './styles';
import { JourneyGridCard } from './components';

const UserJourneyListPageTutorial = () => {
  return (
    <Container>
      <Header>Journeys</Header>
      <ButtonContainer>
        <UnderlineLink selected to="#">
          In Progress
        </UnderlineLink>
        <UnderlineLink to="#">Completed</UnderlineLink>
      </ButtonContainer>
      <JourneysListContainer>
        <JourneyGridCard journey={exampleJourney} />
      </JourneysListContainer>
    </Container>
  );
};

export default UserJourneyListPageTutorial;

import React from 'react';
import useAuthentication from 'hooks/useAuthentication';

import { CustomButton } from 'components/NewLyncworkUIKit';

import { StyledAvatar, InfoContainer, UserName, RoleName } from './styles';

const AvatarSection = ({ avatarData, changeAvatar }) => {
  const { src } = avatarData;
  const { user } = useAuthentication();
  const { id, firstName, lastName, roles } = user;
  const fullName = `${firstName} ${lastName}`;
  const roleName = roles?.[0]?.roleName;
  return (
    <>
      <StyledAvatar colorKey={id} name={fullName} src={src} />
      <InfoContainer>
        <UserName>{fullName}</UserName>
        <RoleName>{roleName}</RoleName>
        <CustomButton variant="secondary" size="medium">
          <input type="file" tabIndex="-1" onChange={changeAvatar} /> Change
          Picture
        </CustomButton>
      </InfoContainer>
    </>
  );
};

export default AvatarSection;

import styled from 'styled-components';
import Card from 'components/Card';
import { CustomButton } from 'components/NewLyncworkUIKit';
import { colors } from 'designSystem/foundations';

const confirmation = colors.feedback.confirmation;

export const StyledCard = styled(Card)`
  height: 190px;
  flex-basis: 300px;
  flex-grow: 1;
  flex-shrink: 1;
  h2 {
    margin: 0 0 0 5px;
  }

  p {
    margin: 5px 0 0 5px;
  }
`;

export const LogoContainer = styled.div`
  height: 60%;
  width: 100%;
  display: flex;
  justify-content: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: ${props => props.backgroundColor};
  img {
    height: 100px;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Information = styled.div``;

export const RedeemButton = styled(CustomButton)`
  border-radius: 5px;
  background-color: transparent;
  color: ${confirmation};
  border: 1px solid ${confirmation};
  padding: 0 10px 0 10px;
  height: 36px;
  margin-top: 25px;
  margin-right: 10px;

  &:hover:enabled {
    background-color: transparent;
  }
`;

import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Edit, Delete } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';

import { useButtonTrack } from 'matomo/hooks';

import {
  CustomButton,
  CustomSearch,
  AsyncSelect
} from 'components/NewLyncworkUIKit';
import Pagination from 'components/Pagination';
import PortalContainer from 'components/PortalContainer';
import ListTable from 'components/ListTable';

import { useModal } from 'hooks';
import useDialogue from 'hooks/useDialogue';
import { UserService, DropdownService } from 'services';
import AssignModal from '../components/AssignModal';

import BatchDeletionModal from './BatchDeletionModal';
import UserDetailCard from './UserDetailCard';

import { usersListPageEvents } from './instrumentationEvents';
import {
  Container,
  Header,
  SelectContainer,
  FilterRow,
  FilterContainer,
  BatchActionContainer,
  ContentRow
} from './styles';
import TableHeader from './TableHeader';
import UserCard from './UserCard';
const {
  change_page,
  change_filter,
  select_user,
  select_all_user,
  add_user,
  import_user,
  open_assign_modal,
  close_assign_modal,
  open_delete_modal,
  close_delete_modal,
  confirm_batch_deletion
} = usersListPageEvents;

const initialSearchParams = {
  name: '',
  page: 0,
  size: 10,
  arr: [
    'id',
    'firstName',
    'lastName',
    'username',
    'roles',
    'teams',
    'manager',
    'mentor',
    'emailPrimary',
    'userStatus',
    'avatar',
    'hrPrime',
    'itPrime',
    'provisionUserDate'
  ],
  teamId: '',
  roleId: '',
  status: ''
};

const areAllVisibleUsersSelected = ({
  selectedUserIds,
  isLoading,
  visibleUsers
}) => {
  if (isLoading) {
    return false;
  }
  const totalUsers = visibleUsers.length;
  if (!totalUsers > 0) {
    return false;
  }
  const visibleUserIds = visibleUsers.map(user => user.id);
  const isEveryVisibleSelected = visibleUserIds.every(id =>
    selectedUserIds.includes(id)
  );
  return isEveryVisibleSelected;
};

const deleteUser = async user => {
  const { firstName, lastName } = user;
  const name = `${firstName} ${lastName}`;
  try {
    await UserService.delete(user.id);
    return { name, errorKey: null };
  } catch (error) {
    const errorKey = error.response?.data?.errorKey || 'general';
    return { name, errorKey };
  }
};

const batchDeleteUser = async users => {
  const response = await Promise.all(users.map(user => deleteUser(user)));
  return response;
};

const getBatchDeletionFailureMessage = failedUsers => {
  const getNameList = users => {
    const nameList = users.map(user => user.name).join(', ');
    return nameList;
  };
  //users that are managers/mentors/it prime/hr prime
  const assignmentFailure = failedUsers.filter(
    user => user.errorKey === 'cannotdelete'
  );
  const otherFailure = failedUsers.filter(
    user => user.errorKey !== 'cannotdelete'
  );

  const assignmentMessageTitle =
    'Please make sure the following users are not the manager, mentor, it prime or hr prime of other users before deleting.';

  const otherFailureMessageTitle =
    'Something went wrong when deleting the following users, please try again later';

  const assignmentFailureMessage = (
    <>
      <p>{assignmentMessageTitle}</p>
      <p>{getNameList(assignmentFailure)}</p>
    </>
  );
  const otherFailureMessage = (
    <>
      <p>{otherFailureMessageTitle}</p>
      <p>{getNameList(otherFailure)}</p>
    </>
  );
  return (
    <>
      {assignmentFailure.length > 0 && assignmentFailureMessage}
      {otherFailure.length > 0 && otherFailureMessage}
    </>
  );
};

const Filter = ({ onSelect, label, name }) => {
  const paramName = label.includes('Roles') ? 'roles' : 'teams';
  const loadOptions = async inputValue => {
    const response = await DropdownService[paramName]({ name: inputValue });
    return response.data.content;
  };

  return (
    <FilterContainer>
      <span>{label}</span>
      <SelectContainer>
        <AsyncSelect
          size="small"
          placeholder="---"
          onChange={onSelect}
          loadOptions={loadOptions}
          defaultOptions
          isClearable
        />
      </SelectContainer>
    </FilterContainer>
  );
};
const UsersListPage = () => {
  const trackButton = useButtonTrack();
  const {
    isOpen: isDeletionModalOpen,
    openModal: openDeletionModal,
    closeModal: closeDeletionModal
  } = useModal();
  const history = useHistory();
  const [searchParams, setSearchParams] = useState(initialSearchParams);
  const [data, setData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const selectedUserIds = selectedUsers.map(user => user.id);
  const [pageInfo, setPageInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [assignOpen, setAssignOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [search, setSearch] = useState('');

  const { showDialogue, closeDialogue } = useDialogue();

  const fetchUsers = async searchParams => {
    const { name, page, size, arr, teamId, roleId, status } = searchParams;
    const response = await UserService.list(
      name,
      page,
      size,
      arr,
      teamId,
      roleId,
      status
    );
    return response;
  };
  useEffect(() => {
    setData([]);
    const getUsers = async () => {
      setIsLoading(true);
      let userIds = [];
      const response = await fetchUsers(searchParams);
      [...response.data.content].forEach(user => {
        user.mentor && userIds.push(user.mentor.id);
        user.manager && userIds.push(user.manager.id);
        user.hrPrime && userIds.push(user.hrPrime.id);
        user.itPrime && userIds.push(user.itPrime.id);
      });

      const userRequest = await UserService.listByIds(userIds);
      let withAvatars = [...response.data.content];
      withAvatars = withAvatars.map(user => {
        return {
          ...user,
          itPrime: userRequest?.data.find(x => x.id === user.itPrime?.id),
          hrPrime: userRequest?.data.find(x => x.id === user.hrPrime?.id),
          manager: userRequest?.data.find(x => x.id === user.manager?.id),
          mentor: userRequest?.data.find(x => x.id === user.mentor?.id)
        };
      });

      setData(withAvatars);
      setSelectedUser(withAvatars[0]);
      setPageInfo({
        totalPages: response.data.totalPages,
        currentPage: response.data.number + 1
      });
      setIsLoading(false);
    };
    getUsers();
  }, [searchParams]);
  const handleParamChange = key => {
    const updateParams = value => {
      setSelectedUsers([]);
      switch (key) {
        case 'name':
          setSearchParams(old => ({
            ...old,
            name: search,
            page: 0
          }));
          return;
        case 'roleId':
        case 'teamId':
          trackButton(change_filter);
          const id = value?.id || '';
          setSearchParams(old => ({ ...old, [key]: id, page: 0 }));
          return;
        default:
          return;
      }
    };
    return updateParams;
  };

  const checkUser = user => {
    trackButton(select_user);
    const { id: userId } = user;
    if (selectedUserIds.includes(userId)) {
      setSelectedUsers(old => old.filter(user => user.id !== userId));
    } else {
      setSelectedUsers(old => [...old, user]);
    }
  };

  const handleBatchDeletion = async () => {
    setDeleting(true);
    trackButton(confirm_batch_deletion);
    showDialogue({
      title: 'Deletion In Progress',
      message: '',
      confirmButtonText: <CircularProgress color="inherit" size="12px" />,
      confirmButtonAction: () => {},
      cancelButtonAction: closeDialogue
    });
    const response = await batchDeleteUser(selectedUsers);
    setDeleting(false);

    const afterDeletion = () => {
      setSelectedUsers([]);
      setSearchParams(oldParams => ({ ...oldParams, page: 0 }));
      closeDialogue();
    };
    const failedUsers = response.filter(user => user.errorKey);
    if (failedUsers.length > 0) {
      const message = getBatchDeletionFailureMessage(failedUsers);
      showDialogue({
        title: 'Deletion Failed',
        message,
        confirmButtonText: 'Okay',
        confirmButtonAction: afterDeletion
      });
      return;
    }

    afterDeletion();
  };

  const allSelectedChecked = areAllVisibleUsersSelected({
    selectedUserIds,
    isLoading,
    visibleUsers: data
  });
  const handleSelectAll = e => {
    const { checked } = e.target;
    trackButton(select_all_user);
    const visibleUserIds = data.map(user => user.id);
    if (!checked) {
      setSelectedUsers(oldSelection => {
        const removeVisible = oldSelection.filter(
          user => !visibleUserIds.includes(user.id)
        );
        return removeVisible;
      });
    } else {
      setSelectedUsers(oldSelection => {
        const selectedUserIds = oldSelection.map(user => user.id);
        const unselected = data.filter(
          user => !selectedUserIds.includes(user.id)
        );
        return [...oldSelection, ...unselected];
      });
    }
  };

  const handlePageChange = x => {
    trackButton(change_page);
    setSearchParams(old => ({ ...old, page: x - 1 }));
  };

  const handleDeleteModalOpen = () => {
    if (selectedUsers.length < 1) {
      return;
    }
    trackButton(open_delete_modal);
    openDeletionModal();
  };

  const handleDeletionModalClose = () => {
    trackButton(close_delete_modal);
    closeDeletionModal();
  };

  const handleDeletion = () => {
    handleBatchDeletion();
    closeDeletionModal();
  };

  return (
    <Container>
      <BatchDeletionModal
        isOpen={isDeletionModalOpen}
        closeModal={handleDeletionModalClose}
        selectedUsers={selectedUsers}
        handleConfirm={handleDeletion}
      />
      <Header>
        <h1>Users</h1>
        <CustomSearch
          placeholder="Search Users"
          value={search}
          onChange={e => setSearch(e.target.value)}
          onSearch={handleParamChange('name')}
        />
        <CustomButton
          size="medium"
          variant="secondary"
          onClick={() => {
            trackButton(import_user);
            history.push('/admin-users/import');
          }}
        >
          Import Users
        </CustomButton>
        <CustomButton
          size="medium"
          onClick={() => {
            trackButton(add_user);
            history.push('/admin-users/add');
          }}
        >
          Add User
        </CustomButton>
      </Header>
      <FilterRow>
        <Filter
          onSelect={handleParamChange('roleId')}
          name="Roles"
          label="By Roles:"
        />
        <Filter
          onSelect={handleParamChange('teamId')}
          name="Teams"
          label="By Teams:"
        />
        <BatchActionContainer hasSelectedUsers={!!selectedUsers.length}>
          <span>Selected({selectedUsers.length})</span>
          <div
            className="batch-action-button"
            onClick={() => {
              trackButton(open_assign_modal);
              setAssignOpen(true);
            }}
          >
            <Edit /> Assign
          </div>
          {/* <div className="batch-action-button" onClick={handleDeleteModalOpen}>
            {' '}
            <Delete color="inherit" size="20px" />
            {deleting ? (
              <CircularProgress size="24px" color="inherit" />
            ) : (
              'Delete'
            )}
          </div> */}
        </BatchActionContainer>
      </FilterRow>
      <ContentRow>
        <ListTable
          className="users-table"
          loading={isLoading}
          hasContent={data.length > 0}
          header={
            <TableHeader
              allSelectedChecked={allSelectedChecked}
              handleSelectAll={handleSelectAll}
            />
          }
        >
          {data.map(user => {
            const { id: userId } = user;
            const checked = selectedUserIds.includes(userId);
            const selectUser = user => {
              trackButton(select_user);
              setSelectedUser(user);
            };
            return (
              <UserCard
                key={userId}
                setSelectedUser={selectUser}
                user={user}
                checked={checked}
                handleChecked={checkUser}
                selected={user === selectedUser}
              />
            );
          })}
        </ListTable>
        <UserDetailCard user={selectedUser} />
      </ContentRow>
      <Pagination
        totalPages={pageInfo.totalPages}
        currentPage={pageInfo.currentPage}
        changePage={handlePageChange}
      />
      <PortalContainer isOpen={assignOpen}>
        <AssignModal
          users={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          closeModal={() => {
            trackButton(close_assign_modal);
            setAssignOpen(false);
          }}
          setData={setData}
        />
      </PortalContainer>
    </Container>
  );
};

export default UsersListPage;

import React from 'react';
import { useHistory } from 'react-router-dom';

import { CustomButton, CustomSearch } from 'components/NewLyncworkUIKit';
import useSearchQuery from 'hooks/useSearchQuery';

import {
  exampleJourney,
  exampleTask
} from 'context/MobileTutorialContext/constants';

import {
  SingleJourneyPageContainer,
  StyledLink,
  SearchBar,
  TasksListTitle,
  Search,
  ListContainer
} from './styles';
import {
  JourneyListCard,
  TaskListCard,
  JourneyTaskNavigation
} from './components';

const getJourney = completed => {
  const { taskWork } = exampleJourney;
  const completedTasks = completed ? 1 : 0;
  const newTaskWork = { ...taskWork, completedTasks };
  return { ...exampleJourney, taskWork: newTaskWork };
};

const getTask = completed => {
  const actualCompleteDate = completed ? new Date() : null;
  return { ...exampleTask, actualCompleteDate };
};

const SingleJourneyPageTutorial = () => {
  const history = useHistory();
  const query = useSearchQuery();
  const completed = query.get('completed');

  const journey = getJourney(completed);
  const task = getTask(completed);
  const completeTask = () => {
    history.push('/user-journeys/tutorial?completed=true');
  };

  return (
    <SingleJourneyPageContainer>
      <ListContainer>
        <StyledLink to="#">
          <CustomButton size="medium" variant="secondary">
            Back
          </CustomButton>
        </StyledLink>
        <JourneyListCard journey={journey} />
        <SearchBar>
          <TasksListTitle>Tasks</TasksListTitle>
          <SearchBar placeholder="Search" />
        </SearchBar>
        <TaskListCard
          task={task}
          taskIdInProgress={null}
          completeTask={completeTask}
        />
      </ListContainer>
    </SingleJourneyPageContainer>
  );
};

export default SingleJourneyPageTutorial;

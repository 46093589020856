import hellosignEmbedded from 'hellosign-embedded';
import React from 'react';
import { Link } from 'react-router-dom';
import { IconButton as CloseButton } from 'components/NewLyncworkUIKit/CustomButton';

import {
  CloseButtonContainer,
  Container,
  MainInfoContainer,
  StyledAvatar,
  TeamChip,
  SectionContainer,
  DetailContainer,
  DescriptionContainer,
  KeyContactDetails,
  StickyContainer
} from './styles';

const getRoleNames = roles => {
  if (roles.length < 1) {
    return 'No Roles Assigned';
  }

  const roleNames = roles.map(({ roleName }) => roleName).join(', ');
  return roleNames;
};

const UserDetail = ({ label, info }) => {
  return (
    <DetailContainer>
      <label>{label}</label>
      <span>{info}</span>
    </DetailContainer>
  );
};

const KeyContact = ({ label, user }) => {
  const { avatar, id, firstName, lastName } = user;
  const src = avatar?.url;
  const fullName = `${firstName} ${lastName}`;
  return (
    <DetailContainer>
      <label>{label}</label>
      <KeyContactDetails>
        <StyledAvatar
          colorKey={id}
          src={src}
          name={fullName}
          className="small"
        />
        {fullName}
      </KeyContactDetails>
    </DetailContainer>
  );
};

const DescriptiveWords = ({ label, words }) => {
  return (
    <DescriptionContainer>
      <label>{label}</label>
      <ul>
        {words.map((word, id) => (
          <li key={id}>{word}</li>
        ))}
      </ul>
    </DescriptionContainer>
  );
};

const EmployeeInfo = ({ selectedUser }) => {
  if (!selectedUser) {
    return null;
  }
  const {
    id,
    avatar,
    firstName,
    lastName,
    roles,
    teams,
    emailPrimary,
    phonePrimary,
    manager,
    userDescription
  } = selectedUser;
  const fullName = `${firstName} ${lastName}`;
  const src = avatar?.url;
  const roleNames = getRoleNames(roles);
  const hasTeams = teams.length > 0;
  const words = userDescription?.descriptions || [];
  const nonEmptyWords = words.filter(a => !!a);

  return (
    <StickyContainer>
      <Container>
        <Link to="/company-directory">
          <CloseButtonContainer>
            <CloseButton />
          </CloseButtonContainer>
        </Link>
        <MainInfoContainer>
          <StyledAvatar src={src} colorKey={id} name={fullName} key={id} />
          <h2>{fullName}</h2>
          <p className="employee-info-roles">{roleNames}</p>
          {hasTeams && (
            <div className="employee-info-teams-container">
              {teams.map(({ id, teamName }) => (
                <TeamChip key={id}>{teamName}</TeamChip>
              ))}
            </div>
          )}
        </MainInfoContainer>
        <SectionContainer>
          <UserDetail label="Email" info={emailPrimary} />
          {phonePrimary && <UserDetail label="Phone" info={phonePrimary} />}
        </SectionContainer>
        {manager && (
          <SectionContainer>
            <KeyContact label="Manager" user={manager} />
          </SectionContainer>
        )}
        {nonEmptyWords.length > 0 && (
          <SectionContainer>
            <DescriptiveWords
              label={'Words That Describe ' + firstName}
              words={nonEmptyWords}
            />
          </SectionContainer>
        )}
      </Container>
    </StickyContainer>
  );
};

export default EmployeeInfo;

import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Card from 'components/Card';

export const Container = styled(Card)`
  padding: 24px;
  gap: 32px;
  display: flex;
  flex-direction: column;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  h2 {
    ${props => props.theme.typography.header2};
    margin: 0;
    font-weight: 600;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  margin: 0 0 0 auto;
`;

export const AppDetailContainer = styled.div`
  display: flex;
  gap: 48px;
`;
export const AppSectionContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 8px;
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey07};
  h3 {
    margin: 0 0 8px;
    ${props => props.theme.typography.body};
    font-weight: 600;
    color: ${props => props.theme.colors.neutral.grey10};
  }
`;

export const DescriptionSection = styled(AppSectionContainer)`
  flex-basis: 616px;
  p {
    margin: 0;
  }
`;

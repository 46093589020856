import React from 'react';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { Container, Message, StyledLink } from './styles';

const SmallToast = ({
  icon,
  link,
  message,
  linkText,
  dismissToast,
  handleLinkClick
}) => {
  return (
    <Container>
      {icon}
      <Message>{message}</Message>
      {linkText && (
        <StyledLink to={link} onClick={handleLinkClick}>
          {linkText}
        </StyledLink>
      )}
    </Container>
  );
};

export default SmallToast;

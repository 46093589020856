import React, { useState, useEffect } from 'react';
import { LicenceService, RoleService } from 'services/';

const useAppLicenceInfo = (appAPIName, licenseManagements = []) => {
  const [licenceInfo, setLicenceInfo] = useState({});
  useEffect(() => {
    if (licenseManagements.length < 1 || !appAPIName) {
      return;
    }
    let mounted = true;
    const getLicenceInfo = async () => {
      try {
        const newLicenceInfo = {};
        const licenseInfoList = await Promise.all(
          licenseManagements.map(async license => {
            const licenseId = license.applicationLicenseInformation.licenseId;
            const { data: usageInfo } = await LicenceService.getDetail(
              appAPIName,
              licenseId
            );
            const { data: roles } = await RoleService.findByLicenceId(
              licenseId
            );
            return { licenseId, roles, ...usageInfo };
          })
        );
        licenseInfoList.forEach(info => {
          newLicenceInfo[info.licenseId] = info;
        });
        mounted && setLicenceInfo(newLicenceInfo);
      } catch (error) {
        console.log(error);
      }
    };
    getLicenceInfo();
    return () => {
      mounted = false;
    };
  }, [appAPIName, licenseManagements]);
  return licenceInfo;
};

export default useAppLicenceInfo;

import React from 'react';

import BackButton from 'components/BackButton';
import { IconButton as CloseButton } from 'components/NewLyncworkUIKit/CustomButton';

import { Toolbar, AppName } from './styles';

const Header = ({ appName, handleBack, closeModal }) => {
  return (
    <>
      <Toolbar>
        <BackButton onClick={handleBack} />
        <CloseButton className="close-icon" onClick={closeModal} />
      </Toolbar>
      <AppName>{appName}</AppName>
    </>
  );
};

export default Header;

import React, { useEffect, useState } from 'react';
import Pagination from 'components/Pagination';
import { useHistory } from 'react-router-dom';

import pdfIcon from 'assets/images/adminOnboarding/documents/pdfIcon.svg';
import { downloadBlob } from 'services/hellosign.service';
import { DropboxService } from 'services/dropbox.service';
import { useDialogue } from 'hooks';
import {
  Container,
  Header,
  Desc,
  ButtonContainer,
  DocumentsButton,
  SectionContainer,
  SectionDescription,
  DocumentContainer,
  DocumentInfo,
  DocumentTitle,
  DocumentsContainer,
  PaginationContainer
} from './styles';

const Document = ({ file, downloadFile }) => {
  const { signingTitle } = file;
  return (
    <DocumentInfo>
      <img src={pdfIcon} />
      <DocumentTitle onClick={() => downloadFile(file)}>
        {signingTitle}
      </DocumentTitle>
    </DocumentInfo>
  );
};

const DocumentSection = ({ userData }) => {
  const [usersFiles, setUsersFiles] = useState([]);
  const [allFiles, setAllFiles] = useState([]);
  const [pageStandard, setPageStandard] = useState(0);
  const [totalPagesStandard, setTotalPagesStandard] = useState(0);
  const [pageIndividual, setPageIndividual] = useState(0);
  const [totalPagesIndividual, setTotalPagesIndividual] = useState(0);
  const { showDialogue, closeDialogue } = useDialogue();
  const history = useHistory();

  useEffect(() => {
    const fetchAllFiles = async () => {
      const response = await DropboxService.listFile('', pageStandard);
      const files = response.data.content;

      const allFiles = files.filter(
        file =>
          file.externalGroupId !== null &&
          (file.fileCategory === 'ALL' || file.fileCategory === 'SIGNABLE_ALL')
      );
      setAllFiles(allFiles);
      setTotalPagesStandard(response.data.totalPages);
    };
    const fetchFiles = async () => {
      const response = await DropboxService.findDropboxFileByJWT(
        pageIndividual
      );
      const files = response.data.content;
      setUsersFiles(files);
      setTotalPagesIndividual(response.data.totalPages);
    };
    fetchAllFiles();
    fetchFiles();
  }, [pageStandard, pageIndividual]);

  const downloadFile = async file => {
    const { dropboxFileId, fileName } = file;
    try {
      const response = await DropboxService.downloadAdmin(dropboxFileId);
      const blob = response.data;
      downloadBlob(blob, fileName);
    } catch (e) {
      console.log(e);
      showDialogue({
        title: 'Oops, something went wrong!',
        message: 'Looks like that file could not be downloaded.',
        confirmButtonText: 'Okay',
        confirmButtonAction: closeDialogue
      });
    }
  };

  const changePageStandard = page => {
    setPageStandard(page - 1);
  };

  const changePageIndividual = page => {
    setPageIndividual(page - 1);
  };

  const {
    lyncWorkUserStatus: { status: userStatus },
    activationToken,
    emailPrimary
  } = userData;

  return (
    <>
      <Container>
        <SectionContainer>
          <SectionDescription>
            <Header>Standard Documents</Header>
            <Desc>
              Documents assigned to all users will show up here. These documents
              are standarized, and can be modified from the <b>Documents</b>{' '}
              page.
            </Desc>
            <ButtonContainer>
              <DocumentsButton onClick={() => history.push('/admin-documents')}>
                Go To Documents
              </DocumentsButton>
            </ButtonContainer>
          </SectionDescription>
          <DocumentsContainer>
            <DocumentContainer>
              {allFiles.map((file, i) => {
                return (
                  <Document key={i} file={file} downloadFile={downloadFile} />
                );
              })}
            </DocumentContainer>
            <PaginationContainer>
              <Pagination
                totalPages={totalPagesStandard}
                currentPage={pageStandard + 1}
                changePage={changePageStandard}
              />
            </PaginationContainer>
          </DocumentsContainer>
        </SectionContainer>
        <SectionContainer>
          <SectionDescription>
            <Header>Individual Documents</Header>
            <Desc>
              These are documents which have been specifically assigned to this
              user. To assign a document to this user, select or upload a new
              document from the Documents page. Select the By Users option for
              document assignment, and add this user. Removing this user from a
              document’s assignment will unassigned the user.
            </Desc>
          </SectionDescription>
          <DocumentsContainer>
            <DocumentContainer>
              {usersFiles.map((file, i) => {
                return (
                  <Document key={i} file={file} downloadFile={downloadFile} />
                );
              })}
            </DocumentContainer>
            <PaginationContainer>
              <Pagination
                totalPages={totalPagesIndividual}
                currentPage={pageIndividual + 1}
                changePage={changePageIndividual}
              />
            </PaginationContainer>
          </DocumentsContainer>
        </SectionContainer>
      </Container>
    </>
  );
};
export default DocumentSection;

import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  CategoryCardContainer,
  CategoryCardIcon,
  CategoryCardTitle,
  CategoryCardDescription,
  CategoryCardContent, CategoryCardStretcher
} from './styles';

const CategoryCard = ({ icon, title, description, path }) => {
  const history = useHistory();
  const onClick = () => history.push(path);

  return (
    <CategoryCardContainer onClick={onClick}>
      <CategoryCardStretcher>
        <CategoryCardContent>
          <CategoryCardIcon><img src={icon} /></CategoryCardIcon>
          <CategoryCardTitle>{title}</CategoryCardTitle>
          <CategoryCardDescription>{description}</CategoryCardDescription>
        </CategoryCardContent>
      </CategoryCardStretcher>
    </CategoryCardContainer>
  );
}

export default CategoryCard;

import React, { useRef, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { AuthenticationService } from 'services';

import useAuthentication from 'hooks/useAuthentication';
import arrow from '../arrow.svg';

import {
  PortraitContainer,
  Portrait,
  NameContainer,
  Name,
  Role,
  DropMenu,
  MenuItem,
  ArrowContainer
} from './styles';
import { useKeycloak } from '@react-keycloak/web';

const autoLogoutRoute = ['login', 'forgot-password', 'reset-password'];

const UserPortrait = () => {
  const { keycloak } = useKeycloak();
  const { user } = useAuthentication();
  const [open, setOpen] = useState(false);
  const node = useRef();
  const portrait = useRef();
  const history = useHistory();
  const { pathname } = useLocation();
  const isAutoLogout = autoLogoutRoute.some(route => pathname.includes(route));

  const routeAndClose = x => {
    history.push(`/${x}`);
    setOpen(false);
  };

  const signOut = () => {
    AuthenticationService.logout();
    keycloak.logout();
  };

  const handleClick = e => {
    if (node?.current?.contains(e.target)) {
      return;
    }
    if (portrait?.current?.contains(e.target)) {
      return;
    }
    setOpen(false);
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const { avatar, firstName = '', lastName = '', roles = [] } = user;
  const fullName = `${firstName} ${lastName}`;
  const firstRoleName = roles?.[0]?.roleName;

  return (
    <>
      <PortraitContainer ref={portrait} onClick={() => setOpen(!open)}>
        <Portrait src={avatar?.url} />
        <NameContainer>
          <Name>{fullName}</Name>
          <Role>{firstRoleName}</Role>
        </NameContainer>
        <ArrowContainer open={open}>
          <img src={arrow} />
        </ArrowContainer>
      </PortraitContainer>
      {!isAutoLogout && open && (
        <DropMenu open={open} ref={node}>
          <MenuItem onClick={() => routeAndClose('account-details')}>
            Account Details
          </MenuItem>
          <MenuItem onClick={signOut}>Sign Out</MenuItem>
        </DropMenu>
      )}
    </>
  );
};

export default UserPortrait;

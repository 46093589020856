export const formatAddress = address => {
  if (!address) {
    return '';
  }
  const { lineOne, lineTwo, city, state, country } = address;
  const nonEmptyAddressInfo = [lineOne, lineTwo, city, state, country].filter(
    str => !!str
  );
  return nonEmptyAddressInfo.join(', ');
};

import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  CardContainer,
  CustomButton,
  CardInfoContainer,
  Title,
  Description
} from './styles';
const CompanyResourceCard = ({ title, description, downloadFile }) => {
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    try {
      setLoading(true);
      await downloadFile();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <CardContainer>
      <CardInfoContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </CardInfoContainer>
      <CustomButton onClick={handleClick} disabled={loading}>
        {loading ? <CircularProgress size="20px" color="inherit" /> : 'Open'}
      </CustomButton>
    </CardContainer>
  );
};

export default CompanyResourceCard;

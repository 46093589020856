import styled from 'styled-components';

export const Breadcrumb = styled.h1`
  text-transform: capitalize;
  font-weight: 600;
  color: ${props => props.theme.colors.neutral.grey10};
  font-size: 20px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  margin: 8px 0 16px;
`;

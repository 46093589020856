import React from 'react';

import { CustomButton, Avatar } from 'components/NewLyncworkUIKit';
import generateMediaUrl from 'utilities/generateMediaUrl';

import ServiceCard from '../ServiceCard';
import {
  Container,
  HeaderContainer,
  StyledLink,
  AppDetailContainer,
  AppSectionContainer,
  DescriptionSection
} from './styles';

const ApplicationOverview = ({ app }) => {
  const { appLogo, appName, id, appOverview, applicationCapabilities } = app;
  const src = generateMediaUrl(appLogo);
  const addAppLink = `/admin-applications/view/${id}/add`;
  return (
    <Container>
      <HeaderContainer>
        <Avatar name={appName} colorKey={id} src={src} size="64px" />
        <h2>{appName}</h2>
        <StyledLink to={addAppLink}>
          <CustomButton size="medium">Add to Lyncwork</CustomButton>
        </StyledLink>
      </HeaderContainer>
      <AppDetailContainer>
        <DescriptionSection>
          <h3>Description</h3>
          <p>{appOverview}</p>
        </DescriptionSection>
        <AppSectionContainer>
          <h3>Services</h3>
          {applicationCapabilities.map(service => (
            <ServiceCard key={service.id} service={service} />
          ))}
        </AppSectionContainer>
      </AppDetailContainer>
    </Container>
  );
};

export default ApplicationOverview;

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import UnderlineLink from 'components/UnderlineLink';
import { CustomSearch } from 'components/NewLyncworkUIKit';

import { Container } from './styles';

const NavBar = ({ value, onChange }) => {
  const { pathname } = useLocation();
  const isMyApps = pathname.includes('my-apps');
  return (
    <Container>
      <UnderlineLink to="/admin-applications/browse" selected={!isMyApps}>
        Browse
      </UnderlineLink>
      <UnderlineLink to="/admin-applications/my-apps" selected={isMyApps}>
        My Applications
      </UnderlineLink>
      <CustomSearch
        placeholder="search applications"
        className="search-input"
        value={value}
        onChange={onChange}
      />
    </Container>
  );
};

export default NavBar;

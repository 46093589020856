import React from 'react';
import PersonalInfo from 'pages/Onboarding/UserOnboarding/pages/PersonalInfo';

const AdminSetupPersonalInfo = ({
  nextPage = () => {},
  prevPage = () => {},
  pageData,
  onPageDataChange = () => {}
}) => {
  return (
    <PersonalInfo
      nextPage={nextPage}
      prevPage={prevPage}
      pageData={pageData}
      onPageDataChange={onPageDataChange}
    />
  );
};

export default AdminSetupPersonalInfo;

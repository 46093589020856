import React from 'react';
import LyncworkLogo from 'assets/images/Onboarding/lyncworklogo.svg';

import {
  Container,
  LogoContainer,
  ContentContainer,
  Title,
  Description,
  SideBarImage
} from './styles';

const SideBar = ({ sideBarData }) => {
  const { title, description, imgSrc } = sideBarData;
  return (
    <Container>
      <LogoContainer>
        <img src={LyncworkLogo} alt="logo" />
      </LogoContainer>
      <ContentContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <SideBarImage src={imgSrc} alt="sidebar illustration" />
      </ContentContainer>
    </Container>
  );
};

export default SideBar;

import styled, { css } from 'styled-components';

import HoverInfo from 'components/HoverInfo';
import { colors } from 'designSystem/foundations';

const {
  feedback: { warning02 }
} = colors;

export const WarnHoverInfo = styled(HoverInfo)`
  color: ${warning02};
`;

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  .close-icon {
    font-size: 1.2rem;
    width: 20px;
    height: 20px;
  }
`;

export const AppName = styled.span`
  margin: 24px 0 8px;
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey07};
  font-weight: 500;
`;

export const ContentContainer = styled.div`
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: scroll;
  gap: 16px;
  h2 {
    display: flex;
    align-items: center;
    gap: 8px;
    ${props => props.theme.typography.header2};
    font-weight: 600;
    margin: 0 0 8px;
  }

  button.save-button {
    align-self: flex-end;
  }
`;

const selectedStyle = css`
  border-color: ${props => props.theme.colors.neutral.grey06};
  background: ${props => props.theme.colors.neutral.offWhite01};
`;

export const CapabilityContainer = styled.button`
  border-radius: 8px;
  flex-shrink: 0;
  height: 68px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
  background: white;
  gap: 16px;
  border: 1px solid ${props => props.theme.colors.neutral.grey03};
  ${props => props.selected && selectedStyle};
  cursor: pointer;
  .capability-avatar {
    border-radius: 0;
    img {
      object-fit: contain;
    }
  }

  .capability-name {
    ${props => props.theme.typography.body};
    font-weight: 600;
    margin-right: auto;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const LicenseCardContainer = styled.div`
  flex-shrink: 0;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background: white;
  gap: 16px;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.grey03};
  ${props => props.theme.typography.body};
  font-weight: 500;
  text-transform: capitalize;
`;

export const SelectedContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey07};
  font-weight: 600;
  padding: 0 8px;
  .MuiSvgIcon-root {
    font-size: 1.2rem;
  }
`;

import styled from 'styled-components';
import { UserHelpSection } from '../../styles';

export const PostContainer = styled(UserHelpSection)`
  width: 100%;
  box-sizing: border-box;
  padding: 6% 5%;
  margin-top: 40px;
`;

export const PostContent = styled.div`
  margin-top: 50px;
`;

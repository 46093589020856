import React from 'react';
import { Container, Divider, Step } from './styles';

const Progress = ({ completedStepCount, steps }) => {
  return (
    <Container>
      {steps.map((step, index) => {
        const mark = index <= completedStepCount;
        const isFirst = index === 0;
        return (
          <React.Fragment key={step}>
            {!isFirst && <Divider mark={mark} />}
            <Step mark={mark}>{step}</Step>
          </React.Fragment>
        );
      })}
    </Container>
  );
};

export default Progress;

export const white = '#FFFFFF';
export const lightGrey = '#f8f8f8';
export const paleGrey = '#f4f5f7';
export const grey = '#aab2bb';
export const lightOffGrey = `#66707f`;
export const offGrey = '#8998aa';
export const black = '#000000';
export const orange = '#FF6B6B';
export const textBlack = '#4d4d4d';
export const borderGrey = `#dcdde4`;

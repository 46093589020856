import React from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import env from 'env';

import { store } from '../redux/store.js';

const { storeDomain } = env;

export const uri = `${storeDomain}/graphql`;

const httpLink = createHttpLink({
  uri
});

const authLink = setContext((_, context) => {
  // get the authentication token from redux if it exists
  const { headers } = context;
  const state = store.getState();
  const authToken = state.authentication.idToken;
  return {
    headers: {
      ...headers,
      authorization: authToken ? `Bearer ${authToken}` : ''
    }
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

const ApolloWrapper = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloWrapper;

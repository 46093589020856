import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin: 56px 0 0;
  height: 88px;
  gap: 16px;
`;

import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Card from 'components/Card';

export const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 548px;

  box-sizing: border-box;
  margin: 32px 0 0;
  padding: 32px;

  h1 {
    ${props => props.theme.typography.header1};
    font-weight: 600;
    margin: 0;
  }
`;

export const ListContainer = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    width: 100%;
    display: flex;
    gap: 8px;
    margin: 0 0 16px 0;
    ${props => props.theme.typography.body};
    list-style: none;
    :last-child {
      margin: 0;
    }
  }
`;

export const NumberContainer = styled.div`
  margin: 2px 0 0;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${props => props.theme.colors.feedback.error};
  ${props => props.theme.typography.control};
  font-weight: 600;
  color: white;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  margin: 56px auto 0;
`;

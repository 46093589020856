import styled from 'styled-components';
export const HTMLContainer = styled.div`
  width: 100%;
  ${({ onboarding }) => !onboarding && 'height: 100%;'}
  box-sizing: border-box;
  padding: 0 var(--ck-spacing-standard);
  iframe {
    width: 100% !important;
  }
  .image-style-side {
    float: right;
    margin-left: 1.5em !important;
    max-width: 50%;
  }

  .image_resized {
    max-width: 100%;
    display: block;
    box-sizing: border-box;
  }

  .image {
    display: table;
    clear: both;
    text-align: center;
    margin: 1em auto;
  }

  figure {
    img {
      width: 100%;
    }
  }

  table {
    margin: 0 auto;
    border-collapse: collapse;
    td {
      min-width: 2em;
      padding: 0.4em;
      border: 1px solid #bfbfbf;
    }
  }

  blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: 5px solid #ccc;
  }
`;

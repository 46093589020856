import React from 'react';

import Button from 'components/NewLyncworkUIKit/CustomButton';
import generateMediaUrl from 'utilities/generateMediaUrl';

import { Container, StyledAvatar } from './styles';

const AppCard = ({ app, handleSelection }) => {
  const { appName, appLogo, id } = app;
  const src = generateMediaUrl(appLogo);
  return (
    <Container>
      <StyledAvatar src={src} name={appName} colorKey={id} />
      <span>Integrate with {appName}</span>
      <Button size="small" onClick={handleSelection}>
        Connect
      </Button>
    </Container>
  );
};

export default AppCard;

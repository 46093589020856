import styled from 'styled-components';

import Card from 'components/Card';
import { Avatar } from 'components/NewLyncworkUIKit';

export const Container = styled(Card)`
  box-sizing: border-box;
  width: 316px;
  padding: 32px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.medium}) {
    width: 100%;
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  gap: 24px;
  padding: 24px 0;
  border-top: 1px solid ${props => props.theme.colors.neutral.grey02};
  &:nth-child(2) {
    border-top: none;
    padding: 0 0 24px;
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.medium}) {
    &:nth-child(2) {
      border-top: 1px solid ${props => props.theme.colors.neutral.grey02};
      padding: 24px 0;
    }
  }
`;

export const InfoDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey06};
`;

export const InfoLabel = styled.span`
  color: ${props => props.theme.colors.neutral.grey10};
  font-weight: 600;
`;

export const KeyPersonnelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const KeyPersonnelAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;
`;
export const AppLogo = styled(Avatar)`
  width: 40px;
  height: 40px;
`;

export const ApplicationContainer = styled(Card)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-self: flex-start;
  gap: 8px;
  padding: 8px;
`;

export const AvatarSectionContainer = styled(SectionContainer)`
  flex-direction: row;
  gap: 32px;
  padding: 0 0 32px 0;
  display: none;
  border-top: none;
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.medium}) {
    display: flex;
  }
`;

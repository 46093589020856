import axios from 'axios';
import env from '../env';
const baseUrlCompany = `${env.storeDomain}/api/companies/roles`;
const basicRoles = `${env.storeDomain}/api/basic-roles`;
const baseUrl = `${env.storeDomain}/api/roles`;
const listWithUsersUrl = `${env.storeDomain}/api/roles-with-users`;
export const RoleService = {
  create: role => {
    return axios.post(`${baseUrl}`, role);
  },

  update: role => {
    return axios.put(`${baseUrl}`, role);
  },

  find: id => {
    return axios.get(`${baseUrl}/${id}`);
  },
  findByRole: (role, page = '0', name = '') => {
    return axios.get(`${baseUrl}/${role}/users?page=${page}&name=${name}`);
  },
  getUsers: async id => {
    const res = await axios.get(`${baseUrl}/${id}/users`);
    return res.data;
  },
  usersExist: id => {
    return axios.get(`${baseUrl}/${id}/users-exists`);
  },
  listByIds: ids => {
    return axios.get(`${basicRoles}?id=${ids}`);
  },
  list: (name = '', page = '0', size = '10') => {
    return axios.get(`${baseUrl}?name=${name}&page=${page}&size=${size}`);
  },
  listWithUsers: (name = '', page = '0', size = '10') => {
    return axios.get(
      `${listWithUsersUrl}?name=${name}&page=${page}&size=${size}`
    );
  },

  listLite: (name = '', page = '0', size = '10') => {
    return axios.get(
      `${baseUrlCompany}?name=${name}&page=${page}&size=${size}`
    );
  },
  delete: id => {
    return axios.delete(`${baseUrl}/${id}`);
  },
  findByLicenceId: licenseId => {
    return axios.get(`${baseUrl}/bylicenseid?licenseid=${licenseId}`);
  }
};

import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 200px;
  padding: 35px 0;
  box-shadow: -10px 9px 21px #f4f5f7;
  border-radius: 4px;
  background-color: #ffffff;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CurrentPointsCardContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const Ribbon = styled.img`
  margin-right: 30px;
  width: auto;
  object-fit: contain;
`;

export const CurrentPointsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const PointsRedeemedContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export const Title = styled.h1`
  padding: 0;
  margin: 0;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: #4d4d4d;
`;

export const Points = styled.h2`
  padding: 0;
  margin: 0;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 47px;
  letter-spacing: 0.10505px;
  color: ${props => props.color || '#66707F'};
`;

export const TotalEarned = styled.span`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  letter-spacing: 0.10505px;
  color: #4d4d4d;
  span {
    font-weight: 400;
  }
`;

import React from 'react';

import {
  Container,
  HeaderContainer,
  Title,
  TaskLength,
  Description
} from './styles';

const JourneyCard = ({ journey }) => {
  const { workflowName, workflowDesc, workflowTasks } = journey;
  return (
    <Container>
      <HeaderContainer>
        <Title>{workflowName}</Title>
        <TaskLength>{workflowTasks.length} Tasks</TaskLength>
      </HeaderContainer>
      <Description>{workflowDesc}</Description>
    </Container>
  );
};

export default JourneyCard;

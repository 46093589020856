import React from 'react';
import styled from 'styled-components';
import { Label, AsyncSelect } from 'components/NewLyncworkUIKit';
import DatePicker from 'components/DatePicker';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: flex-start;
`;

export const DateInput = ({ className, ...rest }) => {
  const earliestProvisionDate = new Date();

  return (
    <InputContainer className={className}>
      <Label>
        Provision Date <span>*</span>
      </Label>
      <DatePicker
        {...rest}
        minDate={earliestProvisionDate}
        defaultModalDate={earliestProvisionDate}
      />
    </InputContainer>
  );
};
export const Select = ({ className, label, ...rest }) => {
  return (
    <InputContainer className={className}>
      <Label>{label}</Label>
      <AsyncSelect {...rest} />
    </InputContainer>
  );
};

import styled from 'styled-components';

import colors from 'designSystem/foundations/colors';
import { Avatar, CustomCheckbox } from 'components/NewLyncworkUIKit';
import { Row } from 'components/ListTable';

const {
  neutral: { grey07, offWhite03 },
  feedback: { confirmation, warning02, success }
} = colors;

export const StyledAvatar = styled(Avatar)`
  width: 28px;
  height: 28px;
`;

export const StyledCheckbox = styled(CustomCheckbox)`
  margin: 0 0 0 24px;
`;

const getStatusColor = ({ status }) => {
  switch (status) {
    case 'PENDING_ACTIVATION':
    case 'PENDING_EMAIL_NOTIFICATION':
      return confirmation;
    case 'SUSPENDED':
    case 'MONITORED':
      return warning02;
    default:
      return success;
  }
};

export const StatusCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${getStatusColor};
`;

export const StatusText = styled.span`
  text-transform: capitalize;
`;

export const StyledRow = styled(Row)`
  cursor: pointer;
`;

import React from 'react';

import { AppSetupContainer } from './styles';

import { AppInfo, AppForm } from './components';

const ApplicationSetup = ({
  appSelected,
  cancelSelection,
  integrateApplication
}) => {
  return (
    <AppSetupContainer>
      <AppInfo app={appSelected} />
      <AppForm
        app={appSelected}
        cancelSelection={cancelSelection}
        integrateApplication={integrateApplication}
      />
    </AppSetupContainer>
  );
};

export default ApplicationSetup;

import styled, { css } from 'styled-components';
import Button from 'components/NewLyncworkUIKit/CustomButton';
export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
  padding: 20px 15% 32px;
`;

export const AppName = styled.h1`
  ${props => props.theme.typography.header1};
  font-weight: 600;
  margin: 0;
`;

export const LogoContainer = styled.div`
  display: flex;
  height: 52px;
  width: 100px;
  justify-content: center;
  margin: 32px 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const Step = styled.span`
  ${props => props.theme.typography.body};
  font-weight: 600;
  margin: 16px 0 8px 0;
`;

export const Text = styled.span`
  ${props => props.theme.typography.header3};
  margin: 16px 0;
`;

export const Email = styled.span`
  ${props => props.theme.typography.body};
  margin: 8px 0;
`;

export const PasswordInput = styled.input`
  min-width: 150px;
  border: none;
  outline: none;
  margin-right: 24px;
  height: 24px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  &[type='password'] {
    font-family: caption;
    font-size: 24px;
  }
`;

export const HiddenPasswordField = styled.input`
  position: absolute;
  opacity: 0;
`;

export const PasswordButton = styled.button`
  appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:focus-visible {
    outline: 2px solid #5e9ed6;
  }
`;

const copiedStyle = css`
  background-color: ${props => props.theme.colors.feedback.success}!important;
  border-color: ${props => props.theme.colors.feedback.success}!important;
  color: white !important;
  .MuiSvgIcon-root {
    border-radius: 50%;
    color: ${props => props.theme.colors.feedback.success};
    background-color: white;
    margin-right: 8px;
    font-size: inherit;
  }
`;
export const CopyPasswordButton = styled(Button)`
  box-sizing: border-box;
  margin: 8px 0;
  min-width: 112px;
  padding: 0 16px;
  transition: all 0.2s ease-in-out;
  .MuiSvgIcon-root {
    margin-right: 8px;
    font-size: inherit;
  }

  ${props => props.copied && copiedStyle}
`;

CopyPasswordButton.defaultProps = {
  variant: 'secondary',
  size: 'small'
};

export const ExternalLink = styled.a`
  appearance: none;
  text-decoration: none;
`;

export const ErrorMessage = styled(Text)`
  margin-top: 1em;
  color: ${props => props.theme.colors.feedback.error};
`;

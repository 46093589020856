import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {
  CustomButton,
  CustomModal,
  CustomTextArea,
  CustomTextInput
} from 'components/NewLyncworkUIKit';
import { AsyncSelect, SubLabel } from 'components/NewLyncworkUIKit';

import { DropdownService, CMSService } from 'services';
import { RoleService } from 'services/role.service';
import { TeamService } from 'services/team.service';

import { Container, Row, Flex, Label } from './styles';

const getDropdownLoadOptions = key => {
  const fetch = async inputValue => {
    const response = await DropdownService[key]({
      name: inputValue
    });
    return response.data.content;
  };
  return fetch;
};

const loadTeams = getDropdownLoadOptions('teams');
const loadRoles = getDropdownLoadOptions('roles');

const loadCategories = async inputValue => {
  const response = await CMSService.getAllCategories();
  return response.data.content;
};

const MetaData = ({ data, setMetaData, submitted }) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [key, setKey] = useState(0);
  const [realCategory, setRealCategory] = useState(null);
  const [newCategory, setNewCategory] = useState('');
  useEffect(() => {
    if (data.category) {
      setRealCategory({
        ...data.category,
        label: data.category.name,
        value: data.category.name
      });
    }
  }, [data]);
  const addTeamPerm = teams =>
    setMetaData({
      ...data,
      team_permissions: teams
    });
  const addRolePerm = roles =>
    setMetaData({
      ...data,
      role_permissions: roles
    });
  const addCategory = addedCategory => {
    setMetaData({ ...data, category: addedCategory });
    setRealCategory({
      ...addedCategory,
      label: addedCategory.name,
      value: addedCategory.name
    });
    setNewCategory('');
  };
  const createAndAddCategory = async x => {
    const addedCategory = await CMSService.createCategory(x);
    addCategory(addedCategory.data);
    setOpen(false);
    setKey(a => a + 1);
    return {
      ...addedCategory,
      label: addedCategory.name,
      value: addedCategory.name
    };
  };
  const onCheckboxClick = (checkType, state) => {
    setMetaData({ ...data, [checkType]: state });
  };
  const categoryError = submitted && !realCategory;
  return (
    <>
      <Container>
        <Label>Categories</Label>
        <Row topMargin>
          <Flex>
            <AsyncSelect
              loadOptions={loadCategories}
              placeholder="Choose a category"
              defaultOptions
              value={realCategory}
              onChange={addCategory}
            />
            {categoryError && <SubLabel error>Category is required</SubLabel>}
          </Flex>
          <Flex>
            <CustomButton
              variant="secondary"
              size="medium"
              onClick={() => setOpen(true)}
            >
              Create New Category
            </CustomButton>
          </Flex>
        </Row>
        <Label>Audience</Label>
        <Row className="ROW" topMargin>
          <Flex className="flex">
            {/* <DDLabel>Roles</DDLabel> */}
            <AsyncSelect
              value={data.role_permissions}
              isMulti
              isClearable
              placeholder="All roles"
              minWidth="200px"
              loadOptions={loadRoles}
              defaultOptions
              onChange={addRolePerm}
            />
          </Flex>
          <Flex className="flex" noMargin>
            {/* <DDLabel>Teams</DDLabel> */}
            <AsyncSelect
              value={data.team_permissions}
              isMulti
              isClearable
              placeholder="All teams"
              minWidth="200px"
              loadOptions={loadTeams}
              defaultOptions
              onChange={addTeamPerm}
            />
          </Flex>
        </Row>

        <Row colWidth="150px">
          <FormControlLabel
            label="Feature Post"
            control={
              <Checkbox
                checked={data.featured}
                onChange={e => onCheckboxClick('featured', e.target.checked)}
              />
            }
          />
          <FormControlLabel
            label="Comments"
            control={
              <Checkbox
                checked={data.comments_allowed}
                onChange={e =>
                  onCheckboxClick('comments_allowed', e.target.checked)
                }
              />
            }
          />
          <FormControlLabel
            label="Likes"
            control={
              <Checkbox
                checked={data.reactions_allowed}
                onChange={e =>
                  onCheckboxClick('reactions_allowed', e.target.checked)
                }
              />
            }
          />
        </Row>
      </Container>
      <CustomModal
        isOpen={open}
        buttonFunctions={{
          cancelButton: () => setOpen(false),
          saveButton: () => createAndAddCategory(newCategory),
          deleteButton: {
            canDelete: false
          }
        }}
        dimensions={{ maxHeight: '80vh', minWidth: '484px', maxWidth: '484px' }}
      >
        <p>New Category Title</p>
        <CustomTextInput
          value={newCategory.title}
          onChange={e =>
            setNewCategory({ ...newCategory, name: e.target.value })
          }
        />
        <p>New Category Description</p>
        <CustomTextArea
          value={newCategory.description}
          onChange={e =>
            setNewCategory({ ...newCategory, description: e.target.value })
          }
        />
      </CustomModal>
    </>
  );
};
export default MetaData;

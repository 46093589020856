import styled from 'styled-components';
import Card from 'components/Card';

export * from 'pages/Onboarding/common/pages/styles';

export const Container = styled.div`
  width: 100%;
  max-width: 708px;
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.colors.neutral.grey10};
`;

export const ContentContainer = styled.div`
  width: 100%;
  padding: 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
  h2 {
    margin: 0;
    ${props => props.theme.typography.title2};
    font-weight: 600;
  }
  p {
    margin: 0 0 24px;
    ${props => props.theme.typography.header3};
  }

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`;

import React from 'react';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import env, { isDev } from '../env';

const { matomoUrlBase: urlBase, matomoSiteId: siteId } = env;

const instance = createInstance({
  urlBase,
  siteId
});

const MatomoWrapper = ({ children }) => {
  if (isDev) {
    return children;
  }
  return <MatomoProvider value={instance}>{children}</MatomoProvider>;
};

export default MatomoWrapper;

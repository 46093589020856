import React, { useState } from 'react';

import CustomButton, {
  IconButton as CloseButton
} from 'components/NewLyncworkUIKit/CustomButton';

import {
  ApplicationChangeOverview,
  ApplicationLicenseChange,
  ApplicationLicenseIssues
} from './PreflightScreens';

import {
  Container,
  HeaderContainer,
  Description,
  ButtonContainer,
  ContentContainer
} from './styles';

const overview = 'overview';
const licenseChange = 'licenseChange';
const licenseIssue = 'licenseIssue';

const getTotalSteps = applicationChanges => {
  const {
    applicationsDeleted,
    applicationsAdded,
    appsWithLicenseChanged,
    appsWithLicenseIssues
  } = applicationChanges;

  const hasAppsDeleted = applicationsDeleted.length > 0;
  const hasAppsAdded = applicationsAdded.length > 0;
  const hasAppsModified = appsWithLicenseChanged.length > 0;
  const hasLicenseIssues = appsWithLicenseIssues.length > 0;

  const steps = [];
  if (hasAppsDeleted || hasAppsAdded) {
    steps.push(overview);
  }

  if (hasAppsModified) {
    steps.push(licenseChange);
  }
  if (hasLicenseIssues) {
    steps.push(licenseIssue);
  }
  return steps;
};

const PreflightStep = ({ stepName, applicationChanges }) => {
  const { appsWithLicenseChanged, appsWithLicenseIssues } = applicationChanges;

  switch (stepName) {
    case overview:
      return (
        <ApplicationChangeOverview applicationChanges={applicationChanges} />
      );
    case licenseChange:
      return (
        <ApplicationLicenseChange
          appsWithLicenseChanged={appsWithLicenseChanged}
        />
      );
    case licenseIssue:
      return (
        <ApplicationLicenseIssues
          appsWithLicenseIssues={appsWithLicenseIssues}
        />
      );
    default:
      return null;
  }
};

const SavePreflight = ({
  closeSavePreflight,
  applicationChanges,
  confirmSave
}) => {
  const [step, setStep] = useState(0);
  const { appsWithLicenseChanged, appsWithLicenseIssues } = applicationChanges;

  const totalSteps = getTotalSteps(applicationChanges);
  const totalPages = totalSteps.length;
  const currentPage = step + 1;
  const stepName = totalSteps[step];
  const stepText = totalPages === 1 ? '' : `(${currentPage}/${totalPages})`;

  const isFirstStep = step === 0;
  const isLastStep = step === totalPages - 1;

  const backButtonText = isFirstStep ? 'Cancel' : 'Back';
  const handleBack = () => {
    if (isFirstStep) {
      closeSavePreflight();
      return;
    }
    setStep(a => a - 1);
  };

  const nextButtonText = isLastStep ? 'Save & Proceed' : 'Next';
  const handleNext = () => {
    if (isLastStep) {
      closeSavePreflight();
      confirmSave();
      return;
    }
    setStep(a => a + 1);
  };

  return (
    <Container onClick={e => e.stopPropagation()}>
      <HeaderContainer>
        <h3>User Application Changes {stepText}</h3>
        <CloseButton onClick={closeSavePreflight} />
      </HeaderContainer>
      <Description>
        <p>
          As dictated by the user role assignment changes, the following will
          occur to this user’s applications.
        </p>
        <p>
          Licence management for indiviudal roles, including application access,
          licence hiearchy, and default licence selection can be set from the
          Roles page.
        </p>
      </Description>
      <ContentContainer>
        <PreflightStep
          stepName={stepName}
          applicationChanges={applicationChanges}
        />
      </ContentContainer>
      <ButtonContainer>
        <CustomButton size="medium" variant="secondary" onClick={handleBack}>
          {backButtonText}
        </CustomButton>
        <CustomButton size="medium" onClick={handleNext}>
          {nextButtonText}
        </CustomButton>
      </ButtonContainer>
    </Container>
  );
};

export default SavePreflight;

import styled from 'styled-components';
const breakPoint = '770px';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${props => props.theme.colors.neutral.white};
  color: ${props => props.theme.colors.neutral.grey10};
  ${props => props.theme.typography.body};
  width: 640px;
  padding: 24px;
  border-radius: 8px;
  @media only screen and (max-width: ${breakPoint}) {
    max-width: 80vw;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  button:nth-last-child(1) {
    margin-left: 16px;
  }
`;

export const Title = styled.h1`
  ${props => props.theme.typography.header3};
  font-weight: 600;
  margin: 0 0 16px;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  color: ${props => props.theme.colors.neutral.grey10};
  width: 16px;
  height: 16px;
  appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: transform 0.2s;
  &:focus-visible {
    outline: 2px solid #0078d4;
  }
`;

import React, { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';

import LoadingScreen from 'components/LoadingScreen';

import { AuthenticationService } from 'services';
import getJWTPermissions from 'utilities/getJWTPermissions';
import useAuthentication, {
  useAuthenticationActions
} from 'hooks/useAuthentication';
import useKeycloakRefresh from 'hooks/useKeycloakRefresh';

import Routes from './routes';

const getRedirectPathname = () => {
  const { search, pathname } = window.location;
  const params = new URLSearchParams(search);
  const paramPath = params.get('r');
  if (paramPath && pathname === '/login') {
    return decodeURIComponent(paramPath);
  }
  return pathname;
};

const getRedirectUri = () => {
  const pathname = getRedirectPathname();

  const domain = window.location.origin.toString();
  switch (pathname) {
    case null:
    case '':
    case '/':
    case '/login':
      return `${domain}/dashboard`;
    default:
      return `${domain}${pathname}`;
  }
};

const App = () => {
  const { initialized, keycloak } = useKeycloak();
  const { token, login } = keycloak;
  const { isAuthenticated } = useAuthentication();
  const { setGetUser, setGetSession } = useAuthenticationActions();

  const loading = !initialized || !isAuthenticated;

  useKeycloakRefresh();

  useEffect(() => {
    if (!initialized) {
      return;
    }

    const gotoLogin = () => {
      const redirectUri = getRedirectUri();
      login({ redirectUri });
    };

    const handleLogin = async token => {
      AuthenticationService.setToken(token);
      try {
        const accountDetailsResponse =
          await AuthenticationService.authenticateByToken();
        const permissions = getJWTPermissions(token);
        const userWithPermissions = {
          ...accountDetailsResponse.data,
          permissions
        };
        setGetUser(userWithPermissions);
        setGetSession({ activated: true });
      } catch (error) {
        console.log(error);
      }
    };

    if (token) {
      handleLogin(token);
    } else {
      gotoLogin();
    }
  }, [initialized, token, login]);

  if (loading) {
    return <LoadingScreen />;
  }

  return <Routes />;
};

export default App;

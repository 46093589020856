import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useButtonTrack } from 'matomo/hooks';

import { userFileImportPageEvents } from 'pages/Users/UsersListPage/instrumentationEvents';

const { add_file, remove_file } = userFileImportPageEvents;

const useImportSteps = steps => {
  const trackButton = useButtonTrack();
  const [completedStepCount, setCompletedStepCount] = useState(0);
  const history = useHistory();

  const nextPage = () => {
    if (completedStepCount < 3) {
      trackButton(`user import navigate to ${steps[completedStepCount + 1]}`);
      setCompletedStepCount(a => a + 1);
      return;
    }
    history.push('/admin-users');
  };
  const previousPage = () => {
    if (completedStepCount === 0) {
      history.push('/admin-users/import');
      return;
    }
    trackButton(`user import navigate to ${steps[completedStepCount - 1]}`);
    setCompletedStepCount(a => a - 1);
  };

  return { previousPage, nextPage, completedStepCount };
};

export default useImportSteps;

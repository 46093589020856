import React from 'react';
import styled, { css } from 'styled-components';
import { buttonVariants, buttonSizes } from './styles';
import CloseIcon from '@material-ui/icons/Close';

export const BaseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  cursor: pointer;
  outline: none;
  gap: 8px;
  transition: all 0.3s, outline 1ms;
  ${props => props.width && `width:${props.width};`}
  ${props => buttonSizes[props.size]};
  &:focus-visible {
    outline: 2px solid #0078d4;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

BaseButton.defaultProps = {
  variant: 'primary',
  size: 'large',
  type: 'button'
};

const Button = styled(BaseButton)`
  border-radius: 100px;
  ${props => buttonVariants[props.variant]};
`;

const widthCss = css`
  width: ${props => props.width};
`;
const heightCss = css`
  height: ${props => props.height};
`;

export const IconButton = styled.button`
  width: 16px;
  height: 16px;
  color: ${props => props.theme.colors.neutral.grey07};
  appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: transform 0.2s;

  ${props => props.width && widthCss};
  ${props => props.height && heightCss};

  &:focus-visible {
    outline: 2px solid #0078d4;
  }
  .MuiSvgIcon-root {
    font-size: inherit;
  }
`;

IconButton.defaultProps = { children: <CloseIcon />, type: 'button' };

export default Button;

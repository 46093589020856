import styled, { css } from 'styled-components';
import { CustomSelect } from 'components/NewLyncworkUIKit';
import {
  borderGrey,
  offGrey,
  paleGrey,
  grey,
  white,
  orange,
  black
} from 'StyleVariables/LyncworkColors';

export const Month = styled.span`
  color: ${black};
  font-size: 25px;
  font-weight: 500;
  width: 50px;
`;

export const Select = styled(CustomSelect)`
  margin-right: 10px;
  .lw-select__control {
    border: none;
    width: 120px;
  }
  .lw-select__indicator-separator {
    background-color: transparent;
  }
  .lw-select__placeholder {
    color: ${black};
    font-size: 25px;
    font-weight: 500;
  }
  .lw-select__value-container {
    padding: 5px 12px 5px 12px;
  }
  .lw-select__single-value {
    font-size: 25px;
    color: ${black};
    font-weight: 500;
  }
`;

export const Container = styled.div`
  // width: 280px;
  // height: 350px;
  width: 100%;
  height: 380px;
  outline: none;
`;

export const Header = styled.div`
  width: 100%;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DateTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${black};
  h2 {
    margin: 5px;
  }
`;

export const Icon = styled.button`
  appearance: none;
  border: 1px solid ${offGrey};
  outline: none;
  cursor: pointer;
  color: ${black};
  margin: 0 5px 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 50%;
  &:hover,
  &:focus-visible {
    background-color: ${grey}30;
  }
  &:disabled {
    cursor: not-allowed;
    &:hover {
      background-color: unset;
    }
  }
`;

export const MonthDateGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

export const WeekLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 0;
  color: ${black};
`;

export const CalendarCellContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const normalDay = css`
  outline: none;
  background-color: transparent;
  color: ${black};
`;
const otherDay = css`
  outline: none;
  background-color: transparent;
  color: ${offGrey};
`;
const selectedDay = css`
  outline: none;
  background-color: ${orange};
  color: ${white};
`;
const currentDay = css`
  outline: 1px solid ${orange};
  background-color: transparent;
  color: ${orange};
`;
const renderStyle = props => {
  const { renderState } = props;
  switch (renderState) {
    case 'normal':
      return normalDay;
    case 'other':
      return otherDay;
    case 'selected':
      return selectedDay;
    case 'current':
      return currentDay;
    default:
      return normalDay;
  }
};
const strikeThrough = css`
  linear-gradient(
    to left top,
    transparent 47.75%,
    currentColor 49.5%,
    currentColor 50.5%,
    transparent 52.25%
  );
`;

export const CalendarCellDate = styled.button`
  appearance: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border: none;
  ${renderStyle}
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &:focus-visible {
    background-color: ${orange};
    color: ${white};
  }
  &:disabled {
    outline: 1px solid
      ${props => (props.renderState == 'selected' ? orange : 'none')};
    opacity: 0.5;
    cursor: not-allowed;
    background: ${props =>
      props.renderState == 'selected' ? orange : strikeThrough};
    padding: 0 0.15em;
    &:hover {
      background-color: ${props =>
        props.renderState == 'selected' ? orange : 'transparent'};
      color: ${props => (props.renderState == 'selected' ? white : offGrey)};
    }
  }
`;

export const PreHeader = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${offGrey};
  padding-bottom: 20px;
`;

export const SelectedDate = styled.span`
  font-size: 12px;
  font-weight: 500;
  float: left;
`;

export const DateDisplay = styled.p`
  font-size: 13px;
  font-weight: 500;
  text-decoration: underline;
  float: left;
  margin: 0;
`;

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import emptyState from 'assets/images/GenericIcons/empty state.svg';
import error from 'assets/images/error.svg';

import { TableContainer, ContentContainer, BlankContainer } from './styles';

const defaultEmptyMessage = (
  <>
    <img src={emptyState} alt="empty state" />
    Sorry, we couldn't find anything
  </>
);

const Loading = () => (
  <BlankContainer>
    <CircularProgress />
  </BlankContainer>
);

const Error = () => {
  return (
    <BlankContainer>
      <img src={error} alt="empty state" />
      Oops, something went wrong
    </BlankContainer>
  );
};

const TableContent = ({
  loading,
  error,
  emptyMessage,
  hasContent,
  children
}) => {
  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  if (!hasContent) {
    return <BlankContainer>{emptyMessage}</BlankContainer>;
  }

  return children;
};

const ListTable = ({
  header = null,
  emptyMessage = defaultEmptyMessage,
  loading,
  error,
  hasContent,
  children,
  className
}) => {
  return (
    <TableContainer className={className}>
      {header}
      <ContentContainer>
        <TableContent
          loading={loading}
          error={error}
          hasContent={hasContent}
          emptyMessage={emptyMessage}
        >
          {children}
        </TableContent>
      </ContentContainer>
    </TableContainer>
  );
};

export default ListTable;

import styled from 'styled-components';
const mobileBreakpoint = '900px';

export const SideBar = styled.div`
  display: flex;
  position: relative;
  top: 0px;
  flex-direction: column;
  width: 440px;
  padding: 0 32px;
  box-sizing: border-box;
  flex-shrink: 0;
  /* height: 100vh; */
  display: flex;
  position: sticky;
  box-shadow: 2px 0px 10px rgba(223, 226, 233, 0.5);

  background-color: white;
  z-index: 2;
  @media only screen and (max-width: ${mobileBreakpoint}) {
    display: none;
  }
`;
export const SideBarHeader = styled.div`
  display: flex;
  position: sticky;
  height: 80px;
  box-sizing: border-box;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 2;
`;
export const SideBarContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 320px;
  flex: 1;
  z-index: 1;
`;
export const SideBarLogo = styled.img`
  width: 150px;
`;

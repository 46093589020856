import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 74px;
  bottom: 0;
  left: 0;
  z-index: 101;
  width: 100%;
`;
export const Backdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.3;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Modal = styled.div`
  position: relative;
  z-index: 101;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 0.5em;
  padding: 2em;
  background: white;
`;
export const Children = styled.div`
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: #aab2bb;
      border-radius: 5px;
    }
  }
`;
export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
`;
export const SaveCancel = styled.div`
  display: flex;
  gap: 8px;

  width: ${props => !props.id && '100%'};
  justify-content: ${props => !props.id && 'flex-end'};
`;
export const DeleteSuspend = styled.div`
  display: flex;
`;

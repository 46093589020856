import React from 'react';

import Avatar from 'components/NewLyncworkUIKit/Avatar';
import HoverInfo from 'components/HoverInfo';
import generateMediaUrl from 'utilities/generateMediaUrl';

import { Container } from './styles';

const ServiceCard = ({ service, className }) => {
  const { logo, capability: name, id, description } = service;
  const src = generateMediaUrl(logo);
  return (
    <Container className={className}>
      <Avatar colorKey={id} src={src} name={name} size="32px" />
      {name}
      <HoverInfo title={description} />
    </Container>
  );
};

export default ServiceCard;

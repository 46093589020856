import React from 'react';

import ListTable from 'components/ListTable';
import Pagination from 'components/Pagination';
import useSubscriptionApps from 'hooks/useSubscriptionApps';

import { NavBar, TableHeader, SubscriptionListCard } from './components';
import { NoResultsFound, NoAppsFound } from './components/EmptyList';
import { Container, BreadCrumb } from './styles';

const MyApplications = () => {
  const {
    name,
    loading,
    handleSearchInputChange,
    searchQuery,
    changePage,
    subscriptionApps,
    page,
    totalPages
  } = useSubscriptionApps();

  return (
    <Container>
      <BreadCrumb>Applications</BreadCrumb>
      <NavBar value={searchQuery} onChange={handleSearchInputChange} />
      <ListTable
        header={<TableHeader />}
        emptyMessage={name ? <NoResultsFound /> : <NoAppsFound />}
        loading={loading}
        hasContent={subscriptionApps.length > 0}
      >
        {subscriptionApps.map(subscriptionApp => (
          <SubscriptionListCard
            key={subscriptionApp.id}
            subscriptionApp={subscriptionApp}
          />
        ))}
      </ListTable>
      <Pagination
        totalPages={totalPages}
        currentPage={page + 1}
        changePage={changePage}
      />
    </Container>
  );
};

export default MyApplications;

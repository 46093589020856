import styled from 'styled-components';

export const Title = styled.h2`
  margin: 0;
  ${props => props.theme.typography.body};
  font-weight: 600;
  color: ${props => props.theme.colors.neutral.grey08};
`;

export const AppListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AppCardContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  ${props => props.theme.typography.body};
`;

export const StickyContainer = styled.div`
  position: sticky;
  z-index: 1;
  top: 0;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 0 16px;
`;

export const LicenseAssignmentTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    ${props => props.theme.typography.body};
    font-weight: 600;
    color: ${props => props.theme.colors.neutral.grey08};

    flex-grow: 0;
    flex-basis: 290px;
  }
  gap: 24px;
`;

export const LicenseComparisonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AppDetailContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 18px 0;
  box-sizing: border-box;
  border-radius: 8px;
  flex-grow: 0;
  height: 76px;
  .detail {
    display: flex;
    flex-direction: column;
    .app-name {
      ${props => props.theme.typography.body};
    }
    .license-name {
      text-transform: capitalize;
      ${props => props.theme.typography.control};
      color: ${props => props.theme.colors.neutral.grey07};
    }
  }
`;

export const ApplicationLicenseCard = styled(AppDetailContainer)`
  padding: 18px 16px;
  flex-basis: 290px;
  border: 1px solid ${props => props.theme.colors.neutral.grey03};
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  ${props => props.theme.typography.metadata};
  color: ${props => props.theme.colors.neutral.grey08};
`;

export const LicenseIssueContainer = styled.div`
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  padding: 12px 0 28px;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.grey02};

  .license-issue-detail {
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    padding-left: 40px;
    ${props => props.theme.typography.control};

    .warning {
      color: ${props => props.theme.colors.feedback.error};
      font-weight: 500;
    }

    .action {
      display: flex;
      align-items: center;
      gap: 4px;
      color: ${props => props.theme.colors.neutral.grey08};
    }
    .action-tooltip {
      color: ${props => props.theme.colors.neutral.grey08};
    }
  }
`;

export const permissionConstants = [
  {
    id: 13,
    name: 'Teams'
  },
  {
    id: 14,
    name: 'Roles'
  },
  {
    id: 15,
    name: 'Users'
  },
  {
    id: 16,
    name: 'Tasks'
  },
  {
    id: 17,
    name: 'Journeys'
  },
  {
    id: 18,
    name: 'Applications'
  },
  {
    id: 19,
    name: 'CMS'
  },
  {
    id: 26,
    name: 'Documents'
  }
];

import styled from 'styled-components';

import Card from 'components/Card';

export const Container = styled(Card)`
  padding: 32px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 700px;
  gap: 16px;
`;

export const Header = styled.h2`
  ${props => props.theme.typography.header3};
  font-weight: 600;
  margin: 0;
`;

export const Desc = styled.p`
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey07};
  margin: 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin: 32px 0 0;
`;

import React from 'react';
import parse from 'html-react-parser';

import { HTMLContainer } from './styles';

const findFirstTag = string => {
  const firstIncompleteTag = string.split('>')[0];
  // removes any attribute
  const tagWithOnlyOpening = firstIncompleteTag.split(' ')[0];
  return `${tagWithOnlyOpening}>`;
};

const getBody = content => {
  const firstTag = findFirstTag(content);
  const firstClosingTag = firstTag.replace('<', '</');
  const [title, ...bodyArray] = content.split(firstClosingTag);

  return bodyArray.join(firstClosingTag);
};

const PostParser = ({ content, onboarding }) => {
  if (!content) {
    return null;
  }

  const htmlString = onboarding ? content : getBody(content);

  return (
    <HTMLContainer onboarding={onboarding}>{parse(htmlString)}</HTMLContainer>
  );
};
export default PostParser;

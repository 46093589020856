import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CustomButton } from 'components/NewLyncworkUIKit';
import { journeySinglePageEvents } from 'pages/Journeys/instrumentationEvents';
import { Grid, ButtonContainer } from './styles';
import BackButton from 'components/BackButton';
import {
  JourneyGeneralInfo,
  JourneyAssignment,
  JourneyTasks,
  JourneySettings
} from './Components';
import useJourneyForm, { initialState } from './useJourneyForm';
import { useButtonTrack } from 'matomo/hooks';
import PageTransitionBlock from 'components/PageTransitionBlock';

const JourneysForm = ({ journey = initialState }) => {
  const trackButton = useButtonTrack();
  const {
    deleteJourney,
    canDelete,
    journeyInfoProps,
    journeySettingsProps,
    assignmentInfoProps,
    journeyTasksProps,
    returnToListPage,
    handleSubmit,
    spinnerLocation,
    blockTransition
  } = useJourneyForm(journey);
  const isDeleting = spinnerLocation === 'delete';
  const isSaving = spinnerLocation === 'save';
  const disableButton = isDeleting || isSaving;

  const handleDiscard = () => {
    trackButton(journeySinglePageEvents.discard_journey_change);
    returnToListPage();
  };
  return (
    <>
      <PageTransitionBlock when={blockTransition} />
      <ButtonContainer>
        <BackButton link="/admin-journeys" />
      </ButtonContainer>
      <Grid>
        <JourneyGeneralInfo journeyInfoProps={journeyInfoProps} />
        <JourneySettings journeySettingsProps={journeySettingsProps} />
        <JourneyAssignment assignmentInfoProps={assignmentInfoProps} />
        <JourneyTasks journeyTasksProps={journeyTasksProps} />
      </Grid>
      <ButtonContainer>
        {canDelete && (
          <CustomButton
            variant="secondary"
            onClick={deleteJourney}
            disabled={disableButton}
          >
            {isDeleting ? (
              <CircularProgress color="inherit" size="12px" />
            ) : (
              'Delete'
            )}
          </CustomButton>
        )}
        <CustomButton
          className="discard"
          variant="secondary"
          onClick={handleDiscard}
          disabled={disableButton}
        >
          Discard
        </CustomButton>
        <CustomButton
          onClick={handleSubmit}
          disabled={disableButton}
          width="128px"
        >
          {isSaving ? <CircularProgress color="inherit" size="18px" /> : 'Save'}
        </CustomButton>
      </ButtonContainer>
    </>
  );
};

export default JourneysForm;

import React, { useEffect, useState } from 'react';
import {
  CustomButton,
  CustomSearch,
  ImageCircle
} from 'components/NewLyncworkUIKit';

import {
  Container,
  UserDetials,
  UsersContainer,
  UserContainer,
  UserName,
  UserEmail,
  ActionContainer,
  AddButton,
  InfoContainer,
  EmptyContainer,
  Empty
} from './styles';
import empty from 'assets/images/GenericIcons/empty state.svg';
const Members = ({
  allMembers,
  openModal,
  addedMembers,
  removeUser,
  selectedUser,
  setSelectedUser
}) => {
  const [searchParam, setSearchParam] = useState('');

  let filteredMembers = allMembers;
  if (searchParam) {
    filteredMembers = filteredMembers.filter(mem =>
      `${mem.firstName} ${mem.lastName}`
        .toLocaleLowerCase()
        .includes(searchParam.toLocaleLowerCase())
    );
  }

  return (
    <Container>
      <ActionContainer>
        <CustomSearch
          placeholder="Search Members"
          value={searchParam}
          offWhite
          name="journeys"
          width="100%"
          onChange={e => setSearchParam(e.target.value)}
        />
        <AddButton size="medium" onClick={openModal}>
          + Add
        </AddButton>
      </ActionContainer>

      <UsersContainer>
        {!filteredMembers.length ? (
          <EmptyContainer>
            <Empty src={empty} />
            <p>
              There are no users add to this team. Click “+ Add” to get started!
            </p>
          </EmptyContainer>
        ) : (
          filteredMembers.map(member => (
            <UserContainer key={member.id} isSelected={selectedUser === member}>
              <div onClick={() => setSelectedUser(member)}>
                <ImageCircle
                  maxWidth="48"
                  firstName={member.firstName}
                  url={member.avatar?.formats?.thumbnail?.url}
                />
              </div>

              <InfoContainer onClick={() => setSelectedUser(member)}>
                <UserName>
                  {member.firstName} {member.lastName}
                </UserName>
                <UserEmail>{member.emailPrimary}</UserEmail>
              </InfoContainer>
              {addedMembers.includes(member) && <p>Just Added</p>}
              <CustomButton
                size="small"
                variant="secondary"
                onClick={() => {
                  setSelectedUser(null);
                  removeUser(member);
                }}
              >
                Remove
              </CustomButton>
            </UserContainer>
          ))
        )}
      </UsersContainer>
    </Container>
  );
};
export default Members;

import { useState } from 'react';
import {
  getFormValue,
  getFormInfo,
  primary_email_provider,
  reorder
} from './utils';

const orderLicenses = licenses => {
  const fixNullValue = licenses.map(license => ({
    ...license,
    defaultLicense: Boolean(license.defaultLicense)
  }));
  const sortedLicenses = fixNullValue.sort(
    (a, b) => b.licenseOrder - a.licenseOrder
  );
  return sortedLicenses;
};

const useIntegrationDetails = ({
  application,
  properties = [],
  handleSave,
  licenseManagements
}) => {
  const { applicationProperties, id: appId } = application;

  const [licenses, setLicenses] = useState(orderLicenses(licenseManagements));
  const canManageLicenses = licenses.length > 0;
  const [showLicensesTab, setShowLicensesTab] = useState(false);
  const switchTab = () => setShowLicensesTab(a => !a);

  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState(
    getFormValue(applicationProperties, properties)
  );
  const { formLabels, fieldNames, enableEmailProviderToggle } = getFormInfo(
    applicationProperties,
    properties
  );

  const onInputChange = e => {
    const { name, value } = e.target;
    setFormValue(oldValues => ({ ...oldValues, [name]: value }));
    setFormError(oldErrors => ({ ...oldErrors, [name]: '' }));
  };

  const handleToggle = e => {
    const { name, checked } = e.target;
    setFormValue(oldValues => ({ ...oldValues, [name]: checked }));
  };

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    setLicenses(oldLicenses =>
      reorder(oldLicenses, result.source.index, result.destination.index)
    );
  };

  const toggleLicense = (licenseId, checked) => {
    setLicenses(oldLicenses => {
      const newLicenses = oldLicenses.map(license => {
        const currentLicenseId =
          license.applicationLicenseInformation.licenseId;
        const defaultLicense = currentLicenseId === licenseId ? checked : false;
        console.log({ currentLicenseId, licenseId, checked, defaultLicense });
        return { ...license, defaultLicense };
      });
      return newLicenses;
    });
  };

  const onSubmit = async e => {
    e.preventDefault();
    const missingFields = fieldNames.filter(name => !formValue[name]);
    if (missingFields.length > 0) {
      const newErrors = {};
      missingFields.forEach(name => {
        const label = formLabels[name];
        newErrors[name] = `${label} is required.`;
      });
      setFormError(newErrors);
      setShowLicensesTab(false);
      return;
    }
    setLoading(true);
    try {
      await handleSave(formValue, licenses);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return {
    loading,
    formError,
    formValue,
    formLabels,
    fieldNames,
    enableEmailProviderToggle,
    licenses,
    canManageLicenses,
    showLicensesTab,
    switchTab,
    onDragEnd,
    toggleLicense,
    onInputChange,
    handleToggle,
    onSubmit
  };
};

export default useIntegrationDetails;

import styled from 'styled-components';

export const Tagline = styled.h1`
  ${props => props.theme.typography.header1};
  font-weight: 600;
  margin: 32px 0 0;
`;

export const Description = styled.p`
  margin: 16px 0 0;
  ${props => props.theme.typography.header3};
  line-height: 1.75rem;
`;

export const RedDescription = styled(Description)`
  margin-top: 8px;
  color: ${props => props.theme.colors.feedback.error};
`;

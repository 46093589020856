import styled from 'styled-components';
import { Avatar } from 'components/NewLyncworkUIKit';

export const StyledAvatar = styled(Avatar)`
  width: 48px;
  height: 48px;
  margin: 0 8px 0 0;
`;

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.grey01};
`;

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Name = styled.h1`
  margin: 0;
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.neutral.grey10};
  font-weight: 500;
`;

export const RolesContainer = styled.div`
  height: 24px;
  flex-shrink: 0;
  display: flex;
`;

export const RoleTag = styled.div`
  ${props => props.theme.typography.metadata};
  color: ${props => props.theme.colors.feedback.error};
  background-color: ${props => props.theme.colors.brand.primaryLite};
  height: 100%;
  box-sizing: border-box;
  padding: 4px 8px;
  margin: 0 8px 0 0;
  border: none;
  border-radius: 12px;
`;

import styled from 'styled-components';

import Card from 'components/Card';

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

export const StyledCard = styled(Card)`
  display: flex;
  height: 560px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  gap: 24px;
  border-right: 1px solid ${props => props.theme.colors.neutral.grey02};
  width: 388px;
  flex-shrink: 0;
  h2 {
    ${props => props.theme.typography.header2};
    margin: 0;
    font-weight: 600;
  }

  .toggled-off {
    span {
      color: ${props => props.theme.colors.neutral.grey05};
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

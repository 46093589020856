import React from 'react';

import generateMediaUrl from 'utilities/generateMediaUrl';
import Avatar from 'components/NewLyncworkUIKit/Avatar';
import { Title, AppListContainer, AppCardContainer } from './styles';

const ApplicationCard = ({ application }) => {
  const { appName, appLogo, id } = application;
  const src = generateMediaUrl(appLogo);
  return (
    <AppCardContainer>
      <Avatar src={src} name={appName} colorKey={id} />
      <span>{appName}</span>
    </AppCardContainer>
  );
};

const ApplicationList = ({ title, applications }) => {
  if (applications.length < 1) {
    return null;
  }
  return (
    <>
      <Title>{title}</Title>
      {applications.map(application => (
        <ApplicationCard key={application.id} application={application} />
      ))}
    </>
  );
};

const ApplicationChangeOverview = ({ applicationChanges }) => {
  const { applicationsAdded, applicationsDeleted } = applicationChanges;
  return (
    <AppListContainer>
      <ApplicationList
        title="Gain Application Access"
        applications={applicationsAdded}
      />
      <ApplicationList
        title="Lose Application Access"
        applications={applicationsDeleted}
      />
    </AppListContainer>
  );
};

export default ApplicationChangeOverview;

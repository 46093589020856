import styled from 'styled-components';

import Card from 'components/Card';
import AsyncCreatableSelect from 'react-select/async-creatable';
import {
  style as selectStyle,
  asyncDefaultProps
} from 'components/NewLyncworkUIKit/CustomSelect';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const JobTitleSelect = styled(AsyncCreatableSelect)`
  ${selectStyle}
`;
JobTitleSelect.defaultProps = asyncDefaultProps;

export const Label = styled.label`
  text-align: left;
  ${props => props.theme.typography.control};
  font-weight: 500;
  color: ${props => props.theme.colors.neutral.grey10};
  margin: 8px 0;
`;

export const FormContainer = styled(Card)`
  padding: 0 32px;
  display: flex;
  flex-direction: column;
`;

export const SectionContainer = styled.div`
  display: flex;
  padding: 32px 0;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.grey02};
  gap: 56px;
  &:last-child {
    border-bottom: none;
  }
`;

export const SectionDescription = styled.div`
  flex-basis: 360px;
  flex-grow: 0;
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  gap: 12px;

  h2 {
    ${props => props.theme.typography.header3};
    color: ${props => props.theme.colors.neutral.grey10};
    font-weight: 600;
    margin: 0;
  }

  p {
    margin: 0;
    ${props => props.theme.typography.body};
    color: ${props => props.theme.colors.neutral.grey07};
  }
`;

export const FieldContainer = styled.div`
  display: flex;
  align-self: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  flex-basis: 100%;
  .half-width {
    flex-basis: calc(50% - 8px);
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 32px;
  margin: 32px 0 0;

  button.delete-button {
    margin: 0 auto 0 0;
  }
`;

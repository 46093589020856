import React from 'react';

import SelectField from './SelectField';
import TextField from './TextField';

const formatOption = name => ({ label: name, value: name });

const shirtSize = [
  'XS Extra-Small',
  'S Small',
  'M Medium',
  'L Large',
  'XL Extra-Large'
].map(formatOption);
const dietaryRestrictions = [
  'Vegan',
  'Vegetarian',
  'Kosher',
  'No Restrictions'
].map(formatOption);

const selectOptions = {
  shirtSize,
  dietaryRestrictions
};

const CustomField = ({ field }) => {
  const { name, label, required } = field;
  switch (name) {
    case 'dietaryRestrictions':
    case 'shirtSize':
      const options = selectOptions[name];
      return (
        <SelectField
          name={name}
          label={label}
          required={required}
          options={options}
        />
      );
    default:
      return <TextField name={name} label={label} required={required} />;
  }
};
export default CustomField;

import React from 'react';
import { CustomCheckbox, CustomSelect } from 'components/NewLyncworkUIKit';
import HoverInfo from 'components/HoverInfo';

import { getOptionValue } from 'utilities/dropdown';

import { HeaderRow, Row, Cell, CellData } from '../UserTable';
import { getOptionLabel, getLicenseInfo, closeMenuOnScroll } from '../utils';

const tooltipTitle = (
  <span>
    The roles displayed in this column are the roles{' '}
    <strong style={{ textDecoration: 'underline' }}>currently</strong>{' '}
    associated with each license type as defined in the roles page. Selecting no
    role will mean this user will be imported with no role assigned, you can
    always edit their role later in the users page.
  </span>
);

export const TableHeader = ({
  handleSelectAll,
  isAllSelected,
  hasLicenseInfo
}) => {
  return (
    <HeaderRow>
      <Cell className="checkbox">
        <CustomCheckbox checked={isAllSelected} onChange={handleSelectAll} />
      </Cell>
      <Cell size="small">First Name</Cell>
      <Cell size="small">Last Name</Cell>
      <Cell>Work Email</Cell>
      <Cell>Personal Email</Cell>
      <Cell>Username</Cell>
      {hasLicenseInfo && (
        <>
          <Cell>License</Cell>
          <Cell>
            Lyncwork Role{' '}
            <HoverInfo title={tooltipTitle} className="hover-info" />
          </Cell>
        </>
      )}
    </HeaderRow>
  );
};

const RoleCell = ({ role, roleOptions, handleRoleChange }) => {
  if (roleOptions.length < 1) {
    return <Cell />;
  }
  return (
    <Cell onClick={e => e.stopPropagation()}>
      <CustomSelect
        onClick={e => e.stopPropagation()}
        className="lw-select"
        placeholder="Click to Select"
        menuPosition="fixed"
        value={role}
        onChange={handleRoleChange}
        options={roleOptions}
        closeMenuOnScroll={closeMenuOnScroll}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
      />
    </Cell>
  );
};

export const LicenseRoleSelection = ({
  licenseName,
  role,
  roleOptions,
  handleRoleChange
}) => {
  return (
    <>
      <Cell>
        <CellData>{licenseName}</CellData>
      </Cell>
      <RoleCell
        role={role}
        roleOptions={roleOptions}
        handleRoleChange={handleRoleChange}
      />
    </>
  );
};

const stopPropagation = e => e.stopPropagation();

export const TableRow = ({
  userData,
  userSelected,
  toggleUser,
  licenseInfo,
  handleRoleChange
}) => {
  const {
    firstName,
    lastName,
    emailPrimary,
    emailSecondary,
    username,
    roles,
    licenseManagements
  } = userData;
  const showLicenses = Object.keys(licenseInfo).length > 0;
  const { licenseName, licenseId } = getLicenseInfo(licenseManagements);
  const role = roles?.[0] || null;
  const roleOptions = licenseInfo[licenseId]?.roles || [];
  return (
    <Row onClick={toggleUser} selected={userSelected}>
      <Cell className="checkbox">
        <CustomCheckbox
          checked={userSelected}
          onChange={toggleUser}
          onClick={stopPropagation}
        />
      </Cell>
      <Cell size="small">
        <CellData>{firstName}</CellData>
      </Cell>
      <Cell size="small">
        <CellData>{lastName}</CellData>
      </Cell>
      <Cell>
        <CellData>{emailPrimary}</CellData>
      </Cell>
      <Cell>
        <CellData>{emailSecondary}</CellData>
      </Cell>
      <Cell>
        <CellData>{username}</CellData>
      </Cell>
      {showLicenses && (
        <LicenseRoleSelection
          handleRoleChange={handleRoleChange}
          licenseName={licenseName}
          roleOptions={roleOptions}
          role={role}
        />
      )}
    </Row>
  );
};

export { TableContainer } from '../UserTable';

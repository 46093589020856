import React, { useState } from 'react';
import { format, parse, isValid, addYears } from 'date-fns';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import PortalContainer from 'components/PortalContainer';

import { Container, StyledInput, ErrorMessage, CalendarIcon } from './styles';
import Calendar from './Calendar';

const formatDateInput = inputValue => {
  const removeNonNumeric = inputValue.replace(/\D/g, '');
  const numberSegments = [
    removeNonNumeric.slice(0, 2),
    removeNonNumeric.slice(2, 4),
    removeNonNumeric.slice(4, 8)
  ];
  const formattedValue = numberSegments.filter(str => !!str).join('-');
  return formattedValue;
};

const convertDateToString = date => {
  if (isValid(date)) {
    return format(date, 'MM/dd/yyyy');
  }
  return '';
};

const DatePicker = ({
  defaultModalDate = new Date(),
  name,
  date,
  onChange,
  errorMessage = '',
  inputRef,
  minDate = addYears(new Date(), -100),
  maxDate = addYears(new Date(), 100),
  disabled
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const inputValue = convertDateToString(date);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  return (
    <>
      <Container hasError={errorMessage} disabled={disabled}>
        <StyledInput
          type="text"
          name={name}
          placeholder="mm/dd/yyyy"
          value={inputValue}
          onChange={openModal}
          onClick={openModal}
          ref={inputRef}
          disabled={disabled}
        />
        <CalendarIcon onClick={openModal} type="button" disabled={disabled}>
          <CalendarTodayIcon />
        </CalendarIcon>
      </Container>
      <PortalContainer isOpen={isModalOpen} handleClickOutside={closeModal}>
        <Calendar
          date={date || defaultModalDate}
          onChange={onChange}
          closeModal={closeModal}
          minDate={minDate}
          maxDate={maxDate}
        />
      </PortalContainer>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};

export default DatePicker;

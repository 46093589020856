import React, { useRef } from 'react';

import { useButtonTrack } from 'matomo/hooks';
import { journeySinglePageEvents } from 'pages/Journeys/instrumentationEvents';

import { Container, TaskSpan } from './styles';
const enable = { right: true, left: true };

const resizeHandleClasses = {
  left: 'resize-handler',
  right: 'resize-handler'
};

const Row = ({ journeyTask, editTask, changeTaskDay }) => {
  const trackButton = useButtonTrack();
  const isResizing = useRef(false);
  const isDragging = useRef(false);

  const {
    order,
    task: { startDay, endDay, taskType }
  } = journeyTask;
  const dayDifference = endDay - startDay;
  const onClick = e => {
    if (isResizing.current || isDragging.current) {
      return;
    }
    editTask();
  };

  const onDrag = () => {
    isDragging.current = true;
  };

  const onResize = () => {
    isResizing.current = true;
  };

  const onResizeStop = (e, direction, ref, delta, position) => {
    if (!isResizing.current) {
      return;
    }
    trackButton(journeySinglePageEvents.resize_task);
    const width = ref.style.width;
    const modifiedWidth = parseInt(width);
    const computedStartDay = Math.floor(position.x / 40) + 1;
    const newStartDay = Math.max(computedStartDay, 1);
    const newDayDifference = Math.floor(modifiedWidth / 40);
    changeTaskDay({
      order,
      startDay: newStartDay,
      endDay: newStartDay + newDayDifference
    });
    setTimeout(() => {
      isResizing.current = false;
    }, 100);
  };
  const onDragStop = (e, d) => {
    if (!isDragging.current) {
      return;
    }
    trackButton(journeySinglePageEvents.drag_task);
    const formattedOffset = Math.max(d.x, 0);
    const computedStartDay = Math.ceil(formattedOffset / 40);
    const newStartDay = Math.max(computedStartDay, 1);
    changeTaskDay({
      order,
      startDay: newStartDay,
      endDay: newStartDay + dayDifference
    });
    setTimeout(() => {
      isDragging.current = false;
    }, 100);
  };

  const width = (endDay - startDay + 1) * 40;
  const x = (startDay - 1) * 40;
  return (
    <Container>
      <TaskSpan
        position={{ x, y: 12 }}
        size={{ width, height: 32 }}
        enableResizing={enable}
        dragAxis={'x'}
        onDrag={onDrag}
        onResize={onResize}
        onDragStop={onDragStop}
        onResizeStop={onResizeStop}
        onClick={onClick}
        tasktype={taskType}
        resizeHandleClasses={resizeHandleClasses}
      />
    </Container>
  );
};

export default Row;

import React from 'react';
import PostList from '../PostList';
import { PopularTopicsContainer } from './styles';
import { UserHelpSectionHeader } from '../../styles';
import iconRocket from 'assets/images/icon-rocket.svg';
import iconFeatures from 'assets/images/icon-features.svg';

const PopularTopics = () => {
  const topics = [
    {
      icon: iconRocket,
      title: 'Getting Started',
      description: 'Dive in and learn the essentials of the features offered through Lyncwork.',
      updatedAt: '01/01/2020',
      path: '/help/topic/getting-started',
    },
    {
      icon: iconFeatures,
      title: 'Workflows & Tasks',
      description: 'Learn what Workflows and Tasks are and how they work.',
      updatedAt: '01/01/2020',
      path: '/help/topic/features',
    },
  ];
  return (
    <PopularTopicsContainer>
      <UserHelpSectionHeader>Popular Topics</UserHelpSectionHeader>
      <PostList items={topics} />
    </PopularTopicsContainer>
  );
};

export default PopularTopics;

import React from 'react';
import Pagination from 'components/Pagination';

import useCompanyDirectory, { useDisplayedUser } from './useCompanyDirectory';
import {
  Container,
  ContentContainer,
  EmployeeInfoColumn,
  StickyContainer
} from './styles';
import { SearchBar, Table, TableRow, EmployeeInfo } from './components';

const CompanyDirectory = () => {
  const {
    users,
    loading,
    searchQuery,
    handleInputChange,
    team,
    role,
    changeParams,
    page,
    totalPages,
    changePage
  } = useCompanyDirectory();
  const { displayedUser, selectUser } = useDisplayedUser(users, loading);
  const hasUsers = users.length > 0;
  const contentContainerClassName = displayedUser && 'hide';

  return (
    <>
      <Container>
        <ContentContainer className={contentContainerClassName}>
          <h1>Company Directory</h1>
          <SearchBar
            searchQuery={searchQuery}
            handleInputChange={handleInputChange}
            team={team}
            role={role}
            changeParams={changeParams}
          />
          <Table hasUsers={hasUsers} loading={loading}>
            {users.map(user => {
              const handleSelect = () => selectUser(user);
              return (
                <TableRow
                  user={user}
                  key={user.id}
                  handleSelect={handleSelect}
                />
              );
            })}
          </Table>
          <Pagination
            totalPages={totalPages}
            currentPage={page + 1}
            changePage={changePage}
          />
        </ContentContainer>
        <EmployeeInfoColumn>
          <EmployeeInfo selectedUser={displayedUser} />
        </EmployeeInfoColumn>
      </Container>
    </>
  );
};

export default CompanyDirectory;

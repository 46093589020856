import styled from 'styled-components';

export const ContentRow = styled.div`
  display: flex;
  gap: 16px;
  .users-table {
    height: 320px;
    width: 700px;
    margin-top: 10px;
  }
`;

export const SearchSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

export const SelectContainer = styled.div`
  width: 200px;
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.neutral.grey07};

  span {
    margin: 0 4px 0 0;
  }
`;

import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ArrowBack from '@material-ui/icons/ArrowBack';

import Button from 'components/NewLyncworkUIKit/CustomButton';

export const StyledButton = styled(Button)`
  justify-self: flex-start;
  align-self: flex-start;
  gap: 4px;
  width: 76px;
  .MuiSvgIcon-root {
    font-size: inherit;
  }
`;
StyledButton.defaultProps = { variant: 'secondary', size: 'small' };
const BackButton = ({ link, onClick, ...rest }) => {
  const history = useHistory();
  const handleClickDefault = () => {
    if (link) {
      history.push(link);
    } else {
      history.goBack();
    }
  };
  const handleClick = onClick || handleClickDefault;
  return (
    <StyledButton onClick={handleClick} {...rest}>
      <ArrowBack />
      Back
    </StyledButton>
  );
};

export default BackButton;

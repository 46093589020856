import styled from 'styled-components';
import { Link } from 'react-router-dom';
import colors from 'designSystem/foundations/colors';

import { Avatar } from 'components/NewLyncworkUIKit';

export const Container = styled.div`
  display: flex;
  padding: 32px 24px 16px;
  gap: 24px;
`;

export const StyledAvatar = styled(Avatar)`
  width: 96px;
  height: 96px;
  font-size: 48px;
`;

export const UserDetail = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    margin: 0;
    ${props => props.theme.typography.header2};
    font-weight: 600;
  }
  button {
    margin: 12px 0 0;
  }
`;

const getStatusColor = ({ status }) => {
  switch (status) {
    case 'PENDING_ACTIVATION':
    case 'PENDING_EMAIL_NOTIFICATION':
      return colors.feedback.confirmation;
    case 'SUSPENDED':
    case 'MONITORED':
      return colors.feedback.warning02;
    default:
      return colors.feedback.success;
  }
};

export const StatusIndicator = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  ${props => props.theme.typography.control};
  color: ${colors.neutral.grey08};
  font-weight: 500;
  &::before {
    flex-shrink: 0;
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-right: 8px;
    background-color: ${getStatusColor};
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const StatusDescContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 24px 0;
  box-sizing: border-box;
  background-color: ${colors.neutral.offWhite01};
  border-radius: 8px;
  padding: 16px;
`;

export const StatusDesc = styled.div`
  ${props => props.theme.typography.control};
  font-weight: 400;
  color: ${colors.neutral.grey07};
  margin: 8px 0 0;
`;

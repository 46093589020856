import styled from 'styled-components';

import { Link } from 'react-router-dom';
import colors from 'designSystem/foundations/colors';

import { Cell } from 'components/ListTable';
import { Avatar } from 'components/NewLyncworkUIKit';

const {
  neutral: { grey01, grey05, grey10, offWhite03 },
  feedback: { confirmation }
} = colors;

const backgroundColor = {
  enabled: `${confirmation}20`,
  disabled: grey01
};
const textColor = {
  enabled: confirmation,
  disabled: grey05
};

export const AdminAccess = styled.div`
  width: 76px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  background-color: ${props => backgroundColor[props.adminAccessStatus]};
  color: ${props => textColor[props.adminAccessStatus]};
  ${props => props.theme.typography.control};
  font-weight: 500;
`;

AdminAccess.defaultProps = { adminAccessStatus: 'disabled' };

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
`;

export const StyledAvatar = styled(Avatar)`
  width: 28px;
  height: 28px;
  margin: 0 8px 0 0;
`;

StyledAvatar.defaultProps = { objectFit: 'contain' };

export const StyledLink = styled(Link)`
  text-decoration: none;
  margin: 0 24px 0 auto;
`;

import React from 'react';
import SortByDropdown from './SortByDropdown';
import {
  TopicHeaderContainer,
  TopicHeaderContent,
  TopicHeaderDescription,
  TopicHeaderIcon,
  TopicHeaderTitle
} from './styles';

const TopicHeader = ({ icon, title, description }) => {
  return (
    <TopicHeaderContainer>
      <TopicHeaderIcon><img src={icon} alt="topic icon" /></TopicHeaderIcon>
      <TopicHeaderContent>
        <TopicHeaderTitle>{title}</TopicHeaderTitle>
        <TopicHeaderDescription>{description}</TopicHeaderDescription>
      </TopicHeaderContent>
      <SortByDropdown />
    </TopicHeaderContainer>
  );
};

export default TopicHeader;

import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Avatar from 'components/NewLyncworkUIKit/Avatar';
import colors from 'designSystem/foundations/colors';

const {
  feedback: { warning02, success }
} = colors;

export const AppName = styled.span`
  margin: 0 0 0 8px;
`;

export const ServiceAvatar = styled(Avatar)`
  width: 32px;
  height: 32px;
  margin: 0 4px 0;
  border-radius: 0;
  img {
    object-fit: contain;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  margin: 0 24px 0 auto;
`;

const getAppStatusColor = ({ subscriptionStatus }) => {
  switch (subscriptionStatus) {
    case 'ACTIVE':
      return success;
    case 'DELETION_IN_PROGRESS':
      return warning02;
    default:
      return 'transparent';
  }
};

export const AppStatusCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${getAppStatusColor};
`;

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CMSService } from 'services/cms.service';
import { UserService } from 'services/user.service';
import { lowercaseComparison } from 'utilities/stringComparison';
import useAuthentication from 'hooks/useAuthentication';

const commentsPerPage = 5;

const useComments = (allComments, initialReactionPosts) => {
  const { id: postId } = useParams();
  const [authors, setAuthors] = useState([]);
  const [reactionPosts, setReactionPosts] = useState(initialReactionPosts);
  const {
    user: { username }
  } = useAuthentication();

  useEffect(() => {
    const getAuthors = async () => {
      const uniqueAuthors = allComments.reduce(
        (usernames, currentComment) => {
          const { author } = currentComment;
          if (usernames.includes(author)) {
            return usernames;
          }
          return [...usernames, author];
        },
        [username]
      );

      try {
        const response = await UserService.listByIds(uniqueAuthors, 'user');
        setAuthors(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAuthors();
  }, []);
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState(allComments);
  const [page, setPage] = useState(1);
  const totalComments = comments.length;
  const firstCommentIndex = Math.max(0, totalComments - page * commentsPerPage);
  const visibleComments = comments.slice(firstCommentIndex, totalComments);
  const hasMoreComments = totalComments > visibleComments.length;

  const toggleCommentSection = () => {
    setPage(oldPage => {
      const newPage = oldPage > 0 ? 0 : 1;
      return newPage;
    });
  };
  const showMoreComments = () => setPage(oldPage => oldPage + 1);
  const showComments = page > 0;

  const createComment = async () => {
    try {
      setLoading(true);
      const response = await CMSService.createComment({
        comment: newComment,
        post: postId
      });
      setComments(oldComments => [...oldComments, response.data]);
      setNewComment('');
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const deleteComment = async commentId => {
    try {
      setComments(oldComments =>
        oldComments.filter(({ id }) => id !== commentId)
      );
      const response = await CMSService.deleteComment(commentId);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = e => setNewComment(e.target.value);
  const handleKeyPress = e => {
    const { key, shiftKey } = e;

    if (shiftKey || !newComment) {
      return;
    }

    if (e.key === 'Enter') {
      e.preventDefault();
      createComment();
    }
  };

  const addCommentReaction = async commentId => {
    try {
      const response = await CMSService.createReactionComment({
        comment: commentId,
        post: postId
      });
      const { author, id } = response.data;
      setComments(oldComments => {
        const newComments = oldComments.map(comment => {
          if (comment.id !== commentId) {
            return comment;
          }
          const { reaction_comments } = comment;
          return {
            ...comment,
            reaction_comments: [...reaction_comments, { id, author }]
          };
        });
        return newComments;
      });
    } catch (error) {
      console.log(error);
    }
  };
  const deleteCommentReaction = async commentId => {
    try {
      await CMSService.deleteReactionComment({
        comment: commentId,
        post: postId
      });
      setComments(oldComments => {
        const newComments = oldComments.map(comment => {
          if (comment.id !== commentId) {
            return comment;
          }
          const { reaction_comments } = comment;
          const newReactionComments = reaction_comments.filter(
            ({ author }) => !lowercaseComparison(author, username)
          );
          return {
            ...comment,
            reaction_comments: newReactionComments
          };
        });
        return newComments;
      });
    } catch (error) {
      console.log(error);
    }
  };

  const addPostReaction = async () => {
    try {
      const response = await CMSService.createReactionPost({
        post: postId
      });
      const { id, author } = response.data;
      setReactionPosts(oldReactionPosts => [
        ...oldReactionPosts,
        { id, author }
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteReactionPost = async () => {
    try {
      await CMSService.deleteReactionPost(postId);
      setReactionPosts(oldReactionPosts =>
        oldReactionPosts.filter(reaction => reaction.author !== username)
      );
    } catch (error) {
      console.log(error);
    }
  };

  const reactionCount = reactionPosts.length;
  const hasReacted = !!reactionPosts.find(
    reaction => reaction.author === username
  );
  const toggleReaction = () => {
    if (hasReacted) {
      deleteReactionPost();
      return;
    }
    addPostReaction();
  };
  return {
    authors,
    showComments,
    visibleComments,
    deleteComment,
    totalComments,
    toggleCommentSection,
    hasMoreComments,
    showMoreComments,
    newComment,
    loading,
    handleChange,
    handleKeyPress,
    addCommentReaction,
    deleteCommentReaction,
    hasReacted,
    toggleReaction,
    reactionCount
  };
};

export default useComments;

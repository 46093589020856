import styled from 'styled-components';
import { UserHelpSection } from '../../styles';

export const CategoryListContainer = styled.div`
  width: 100%;      
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
`;

export const CategoryCardContainer = styled(UserHelpSection)`
  width: calc((100% - 10%) / 4);
  cursor: pointer;
`;

export const CategoryCardStretcher = styled(UserHelpSection)`
  padding-top: 100%;
  position: relative;
`;

export const CategoryCardContent = styled.div`
  position: absolute;
  top: 15%;
  left: 0;
  right: 0;
  color: #4d4d4d;
`;

export const CategoryCardIcon = styled.div`
  display: flex;
  justify-content: center;
  height: 33%;
`;

export const CategoryCardTitle = styled.h3`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin: 10% 0 0 0;
`;

export const CategoryCardDescription = styled.p`
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  margin: 10px 0 0 0;
  padding: 0 18%;
`;
import React from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { LinkContainer, StyledLink, ChevronWrapper } from './styles';

const IndividualLink = ({ text, link, isFirst = false }) => {
  const hasChevronIcon = !isFirst;
  return (
    <>
      {hasChevronIcon && (
        <ChevronWrapper>
          <ChevronRightIcon />
        </ChevronWrapper>
      )}
      <StyledLink to={link}>{text}</StyledLink>
    </>
  );
};

const NestedLink = ({ linkInfoList = [] }) => {
  return (
    <LinkContainer>
      {linkInfoList.map((linkInfo, index) => {
        const { id, text, link } = linkInfo;
        const isFirst = index === 0;
        return (
          <IndividualLink isFirst={isFirst} key={id} text={text} link={link} />
        );
      })}
    </LinkContainer>
  );
};

export default NestedLink;

import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';

import PortalContainer from 'components/PortalContainer';

import { Container, Title, CloseButton, Message, DeleteButton } from './styles';

const FileDeleteContent = ({ fileToDelete, closeDeletePrompt, deleteFile }) => {
  const fileName = fileToDelete?.fileName;
  const [loading, setLoading] = useState(false);

  const handleDeleteClick = async () => {
    try {
      setLoading(true);
      await deleteFile(fileToDelete);
      closeDeletePrompt();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <Container onClick={e => e.stopPropagation()}>
      <CloseButton onClick={closeDeletePrompt}>
        <CloseIcon />
      </CloseButton>
      <Title>Delete Document</Title>
      <Message>Are you sure you want to delete {fileName}?</Message>
      <DeleteButton disabled={loading} onClick={handleDeleteClick}>
        {loading ? <CircularProgress size="24px" color="inherit" /> : 'Delete'}
      </DeleteButton>
    </Container>
  );
};

const FileDeletePrompt = ({ fileToDelete, closeDeletePrompt, deleteFile }) => {
  return (
    <PortalContainer
      isOpen={!!fileToDelete}
      handleClickOutside={closeDeletePrompt}
    >
      <FileDeleteContent
        closeDeletePrompt={closeDeletePrompt}
        fileToDelete={fileToDelete}
        deleteFile={deleteFile}
      />
    </PortalContainer>
  );
};

export default FileDeletePrompt;

import React from 'react';
import CheckIcon from '@material-ui/icons/Check';

import HoverInfo from 'components/HoverInfo';
import CustomButton from 'components/NewLyncworkUIKit/CustomButton';

import {
  Toolbar,
  ContentContainer,
  ButtonContainer,
  LicenseCardContainer,
  SelectedContainer,
  WarnHoverInfo
} from './styles';

const Selected = () => {
  return (
    <SelectedContainer>
      <CheckIcon />
      Selected
    </SelectedContainer>
  );
};

const LicenseCard = ({ license, added, onClick }) => {
  const name = license.applicationLicenseInformation.licenseName.toLowerCase();
  return (
    <LicenseCardContainer>
      <span>{name}</span>
      {added ? (
        <Selected />
      ) : (
        <CustomButton size="small" onClick={onClick}>
          Select
        </CustomButton>
      )}
    </LicenseCardContainer>
  );
};

const SubscriptionLicense = ({
  licenseManagements,
  selectedLicense,
  chooseLicense,
  handleNext
}) => {
  return (
    <>
      <ContentContainer>
        <h2>
          Select Products
          <WarnHoverInfo title="Licenses cannot be changed when the Application is added to a Role." />
        </h2>
        {licenseManagements.map(license => {
          const added = selectedLicense === license;
          const onClick = () => chooseLicense(license);
          return (
            <LicenseCard
              key={license.id}
              license={license}
              added={added}
              onClick={onClick}
            />
          );
        })}
      </ContentContainer>
      <ButtonContainer>
        <CustomButton
          size="medium"
          className="save-button"
          disabled={!selectedLicense}
          onClick={handleNext}
        >
          Add Application
        </CustomButton>
      </ButtonContainer>
    </>
  );
};

export default SubscriptionLicense;

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { CMSService } from 'services/cms.service';
import useAuthentication from 'hooks/useAuthentication';

import { Container, StyledAvatar } from './styles';

import CommentTextArea from './CommentTextArea';
const AddComment = ({ newComment, handleChange, handleKeyPress, loading }) => {
  const {
    user: { avatar, firstName, id }
  } = useAuthentication();
  const src = avatar?.url;

  return (
    <Container>
      <StyledAvatar colorKey={id} src={src} name={firstName} />
      <CommentTextArea
        value={newComment}
        handleChange={handleChange}
        handleKeyPress={handleKeyPress}
        disabled={loading}
      />
    </Container>
  );
};

export default AddComment;

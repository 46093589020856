import { createSelector } from 'reselect';
// provides a way to select specific parts of a slice of state
const selectLayout = state => state.layout;

export const selectSideNavOpen = createSelector(
  [selectLayout],
  layout => layout.sideNavOpen
);
export const selectUserDropdownHidden = createSelector(
  [selectLayout],
  layout => layout.userDropdownOpen
);
export const selectSideNavAdminView = createSelector(
  [selectLayout],
  layout => layout.sideNavAdminView
);

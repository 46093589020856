import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { UserImportService } from 'services/userImport.service';

import UsersImportMainPage from './UsersImportMainPage';
import UsersImportFilePage from './UsersImportFilePage';
import UsersImportAppPage from './UsersImportAppPage';

const UsersImportPage = () => {
  const [applications, setApplications] = useState([]);
  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const result = await UserImportService.listApps();
        setApplications(result);
      } catch (error) {
        console.log(error);
      }
    };
    fetchApplications();
  }, []);

  return (
    <Switch>
      <Route path="/admin-users/import" exact>
        <UsersImportMainPage />
      </Route>
      <Route path="/admin-users/import/file">
        <UsersImportFilePage />
      </Route>
      <Route path="/admin-users/import/apps">
        <UsersImportAppPage applications={applications} />
      </Route>
    </Switch>
  );
};

export default UsersImportPage;

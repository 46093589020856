import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import JourneyTableHeader from './JourneyTableHeader';
import JourneyRow from './JourneyRow';

import { TableContainer, EmptyMessage } from './styles';

const JourneyTable = ({ loading, journeys }) => {
  const hasJourneys = journeys.length > 0;
  return (
    <TableContainer>
      <JourneyTableHeader />
      {loading ? (
        <CircularProgress />
      ) : hasJourneys ? (
        journeys.map(journey => (
          <JourneyRow key={journey.id} journey={journey} />
        ))
      ) : (
        <EmptyMessage>You don't have any Journeys coming up.</EmptyMessage>
      )}
    </TableContainer>
  );
};

export default JourneyTable;

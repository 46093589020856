import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  margin: 0 auto;
  grid-template-columns: 3fr 2fr;
  grid-auto-rows: auto;
  grid-gap: 16px;
  width: 1200px;
  max-width: 100%;
`;

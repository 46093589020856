const debounce = (func, delay) => {
  let timeout;
  const callback = (...params) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...params), delay);
  };
  return callback;
};

export default debounce;

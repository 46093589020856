import styled from 'styled-components';
import Button from 'components/NewLyncworkUIKit/CustomButton';
import { buttonSizes } from 'components/NewLyncworkUIKit/CustomButton/styles';
import { multilineEllipsis } from 'StyleVariables/StandardComponents';

const mobileBreakpoint = '900px';

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 90px;
  padding: 24px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  margin: 4px 0;
  border-radius: 10px;
  flex-wrap: wrap;
  @media only screen and (max-width: ${mobileBreakpoint}) {
    height: 134px;
  }
`;

export const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: calc(100% - 100px);
  @media only screen and (max-width: ${mobileBreakpoint}) {
    margin-bottom: 14px;
    flex-basis: 100%;
  }
`;

export const Title = styled.h1`
  width: 100%;
  ${props => props.theme.typography.body};
  font-weight: 600;
  margin: 0;
  ${multilineEllipsis}
`;

Title.defaultProps = { lineClamp: 1 };

export const Description = styled.span`
  color: ${props => props.theme.colors.neutral.grey06};
  ${props => props.theme.typography.metadata};
  ${multilineEllipsis}
`;

Description.defaultProps = { lineClamp: 1 };

export const CustomButton = styled(Button)`
  width: 80px;
  margin-left: auto;
  ${buttonSizes.medium};
  @media only screen and (max-width: ${mobileBreakpoint}) {
    ${buttonSizes.small};
  }
`;

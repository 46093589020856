import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import { StyledNavLink, LinkContainer, Breadcrumb } from './styles';
import DropBoxDocs from './components/DropboxDocs';
import HelloSignDocs from './components/HelloSignDocs';
const NavItem = ({ title, href, styles }) => {
  return (
    <StyledNavLink
      to={href}
      className="onboarding-nav-link"
      activeClassName="onboarding-nav-link-active"
      style={styles}
    >
      <div className="active-div">
        <p>{title}</p>
      </div>
    </StyledNavLink>
  );
};

const Documents = () => {
  const { pathname } = useLocation();
  const [fileToEdit, setFileToEdit] = useState(null);
  return (
    <>
      <Breadcrumb>Documents</Breadcrumb>
      <LinkContainer>
        <NavItem title="Uploaded" href="/admin-documents/uploaded" />
        <NavItem title="Signed" href="/admin-documents/signed" />
      </LinkContainer>
      <Switch>
        <Route path="/admin-documents" exact>
          <Redirect to="/admin-documents/uploaded" />
        </Route>
        <Route path="/admin-documents/uploaded">
          <DropBoxDocs fileToEdit={fileToEdit} setFileToEdit={setFileToEdit} />
        </Route>
        <Route path="/admin-documents/signed">
          <HelloSignDocs
            fileToEdit={fileToEdit}
            setFileToEdit={setFileToEdit}
          />
        </Route>
      </Switch>
    </>
  );
};
export default Documents;

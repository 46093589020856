import styled from 'styled-components';

export const TableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const TableCell = styled.div`
  box-sizing: border-box;
  width: 192px;
  height: 24px;
  flex-shrink: 0;
  flex-grow: 0;
`;

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4px 8px;
  span {
    margin: 4px 0 0;
  }
`;

export const AppListContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

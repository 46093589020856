import React from 'react';
import {
  DocumentsDescription,
  WelcomeDescription
} from 'pages/Onboarding/common/SideBarDescriptions';
import {
  UserWelcome,
  UserWelcomeDone,
  Engagement,
  Documents,
  Team,
  IceBreaker,
  Applications,
  PersonalInfo,
  Skills
} from 'pages/Onboarding/UserOnboarding/pages';

import imagePersonalInformation from 'assets/images/Onboarding/PersonalInformation.svg';
import imageDocuments from 'assets/images/Onboarding/Documents.svg';
import imageEngagement from 'assets/images/Onboarding/Engagement.svg';
import imageIntro from 'assets/images/Onboarding/Intro.svg';
import imageMeetTheTeam from 'assets/images/Onboarding/MeetTheTeam.svg';
import imageIceBreaker from 'assets/images/Onboarding/IceBreaker.svg';
import imageApplicationSetup from 'assets/images/Onboarding/ApplicationSetup.svg';
import imageEndOfOnboarding from 'assets/images/Onboarding/EndOfOnboarding.svg';

const getPageData = (user, isEngagementAvailable = true) => {
  const titlesToHide = isEngagementAvailable ? [] : ['Engagement'];
  const defaultPageData = [
    {
      pathName: '/user/welcome',
      Component: UserWelcome,
      progress: {
        title: 'Welcome'
      },
      sideBar: {
        title: `Hey ${user.firstName || 'Valued Employee'}!`,
        imgSrc: imageIntro,
        description: <WelcomeDescription />
      }
    },
    {
      entitiesSettings: [],
      pathName: '/user/welcome/personal-information',
      Component: PersonalInfo,
      progress: {
        title: 'Personal Info'
      },
      sideBar: {
        title: 'Please fill out your personal information',
        imgSrc: imagePersonalInformation,
        description:
          'We filled out some of your information for you. Please provide whatever information is missing. If there is anything that’s entered wrong from our end, please feel free to correct it!'
      }
    },
    {
      pathName: '/user/welcome/skills',
      Component: Skills,
      progress: {
        title: 'Skills'
      },
      sideBar: {
        title: 'Fill out your skills',
        imgSrc: imagePersonalInformation,
        description: 'Showcase your skills by adding them to your profile here.'
      }
    },

    {
      pathName: '/user/welcome/documents',
      Component: Documents,
      progress: {
        title: 'Documents'
      },
      sideBar: {
        title: 'Your Contracts and Documents',
        imgSrc: imageDocuments,
        description: <DocumentsDescription />
      }
    },
    {
      pathName: '/user/welcome/applications',
      Component: Applications,
      progress: {
        title: 'Applications'
      },
      sideBar: {
        title: 'Application setup',
        imgSrc: imageApplicationSetup,
        description:
          'We know how important it is for you to have all the tools you need to be successful. So, let’s setup all the applications you need for you to hit the ground running.'
      }
    },
    {
      pathName: '/user/welcome/engagement',
      Component: Engagement,
      progress: {
        title: 'Engagement'
      },
      sideBar: {
        title: 'Engagement Program',
        imgSrc: imageEngagement,
        description:
          'We love seeing every individual grow and participate in their own development and the development of others. Here’s how we give back!'
      }
    },
    {
      pathName: '/user/welcome/team',
      Component: Team,
      progress: {
        title: 'Team'
      },
      sideBar: {
        title: 'Welcome to the Team!',
        imgSrc: imageMeetTheTeam,
        description:
          'We are so excited to have you join us! Look around and connect with your peers. There’s also a fun activity for you to share with us a little about yourself.'
      }
    },
    {
      pathName: '/user/welcome/ice-breaker',
      Component: IceBreaker,
      progress: {
        title: 'Ice Breaker'
      },
      sideBar: {
        title: 'Ice Breaker Activity',
        imgSrc: imageIceBreaker,
        description:
          'Let’s break some ice! Here is a fun activity to tell us little bit more about yourself. No pressure though! While, this is a safe space where you can express yourself, if you don’t want to participate, that’s completely okay!'
      }
    },
    {
      pathName: '/user/welcome/done',
      Component: UserWelcomeDone,
      progress: {
        title: 'Complete'
      },
      sideBar: {
        title: 'You’re all done!',
        imgSrc: imageEndOfOnboarding,
        description:
          'Great job on completing your onboarding with us! You are now ready to start using Lyncwork. We look forward to do amazing work with you!'
      }
    }
  ];

  return defaultPageData.filter(
    ({ progress: { title } }) => !titlesToHide.includes(title)
  );
};

export default getPageData;

import styled from 'styled-components';
import { CustomTextInput } from 'components/NewLyncworkUIKit';
const mobileBreakPoint = '900px';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 80px 0;
`;

export const Title = styled.h3`
  ${props => props.theme.typography.title3};
  font-weight: 600;
  margin: 0 0 16px 0;
  @media only screen and (max-width: ${mobileBreakPoint}) {
    width: 100%;
    ${props => props.theme.typography.header2};
    font-weight: 600;
  }
`;

export const InputGroupContainer = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 1100px) {
    flex-direction: column;
  }
  @media only screen and (max-width: ${mobileBreakPoint}) {
    width: 100%;
  }
`;

export const CustomInput = styled(CustomTextInput)`
  margin: 0 8px;
`;

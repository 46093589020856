import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-shrink: 0;
  height: 560px;
  justify-content: center;
  align-items: center;
`;

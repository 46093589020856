export const documentsPageEvents = {
  add_file: 'admin documents add file',
  edit_file: 'admin documents edit file',
  change_page: 'admin documents change page',
  select_file: 'admin documents select/deselect single file',
  select_all_files: 'admin documents select/deselect all files',
  batch_download: 'admin documents batch download',
  batch_deletion: 'admin documents batch deletion'
};

export const documentModalEvents = {
  close_modal: 'admin documents close modal',
  save_documents: 'admin documents save document',
  delete_document: 'admin documents delete single document'
};

import React from 'react';
import { Container } from './styles';
import noUsers from 'assets/images/GenericIcons/no users.svg';

const BlankScreen = ({ message }) => {
  return (
    <Container>
      <img src={noUsers} alt="art" />
      <span>{message}</span>
    </Container>
  );
};

export default BlankScreen;

import styled from 'styled-components';
import colors from 'designSystem/foundations/colors';

import Button from 'components/NewLyncworkUIKit/CustomButton';

const {
  neutral: { grey04, grey07, grey08, offWhite01 },
  feedback: { confirmation, warning02, success }
} = colors;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 24px 32px;
  gap: 20px;
  border: 1px solid ${grey04};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const AppName = styled.span`
  ${props => props.theme.typography.body};
  font-weight: 600;
`;

const getStatusColor = ({ status }) => {
  switch (status) {
    case 'PENDING_ACTIVATION':
    case 'PENDING_EMAIL_NOTIFICATION':
      return confirmation;
    case 'SUSPENDED':
    case 'MONITORED':
      return warning02;
    default:
      return success;
  }
};

export const StatusCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${getStatusColor};
`;

export const AppStatus = styled.span`
  position: relative;
  margin-left: auto;
  ${props => props.theme.typography.control};
  color: ${grey08};
  display: flex;
  align-items: center;
  gap: 8px;
  text-transform: capitalize;
`;

export const TemporaryCredentialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  min-height: 104px;
  gap: 8px;
  justify-content: space-around;

  box-sizing: border-box;
  background: ${offWhite01};
  ${props => props.theme.typography.control};
  color: ${grey07};
`;

export const TempCredentialText = styled.div`
  margin: auto;
`;

export const CredentialContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const CopyButton = styled(Button)`
  box-sizing: border-box;
  min-width: 112px;
  transition: all 0.2s ease-in-out;
  .MuiSvgIcon-root {
    margin-right: 8px;
    font-size: inherit;
  }
`;

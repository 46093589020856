import styled from 'styled-components';
import { CustomTextInput } from 'components/NewLyncworkUIKit';

export const TextInput = styled(CustomTextInput)`
  flex-basis: 100%;
  label {
    font-weight: 500;
  }
  &.half {
    flex-basis: calc(50% - 12px);
  }
  &.third {
    flex-basis: calc((100% - 48px) / 3);
  }
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.small}) {
    flex-basis: 100%;
    &.half {
      flex-basis: 100%;
    }
    &.third {
      flex-basis: 100%;
    }
  }
`;

TextInput.defaultProps = { width: '30%' };
export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
`;

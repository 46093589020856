import {
  SET_COMPANY_INFO,
  SET_COMPANY_LOGO,
  SET_USER_AVATAR,
  SET_USER_INFO
} from './accountSetup.actions';

const initialAccountSetup = {
  adminUser: {
    id: null,
    emailPrimary: null,
    firstName: null,
    lastName: null,
    username: null,
    userPassword: null
  },
  company: {
    id: null,
    companyDesc: null,
    companyName: null,
    companyStatus: null,
    phonePrimary: null,
    address: {
      streetNum: null,
      streetName: null,
      city: null,
      state: null,
      postalCode: null,
      country: null
    }
  },

  images: {
    userAvatar: null,
    companyLogo: null
  }
};

const accountSetupReducer = (accountSetup = initialAccountSetup, action) => {
  switch (action.type) {
    case SET_USER_INFO:
      return {
        ...accountSetup,
        adminUser: action.payload
      };

    case SET_USER_AVATAR:
      return {
        ...accountSetup,
        images: {
          ...accountSetup.images,
          userAvatar: action.payload
        }
      };

    case SET_COMPANY_INFO:
      return {
        ...accountSetup,
        company: action.payload
      };

    case SET_COMPANY_LOGO:
      return {
        ...accountSetup,
        images: {
          ...accountSetup.images,
          companyLogo: action.payload
        }
      };

    default:
      return accountSetup;
  }
};

export default accountSetupReducer;

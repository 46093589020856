import React, { useState } from 'react';

import { useRoleForm } from 'pages/Roles/components/RoleForm/RoleFormContext';

import useRegularSubscription from './useRegularSubscription';

import AhaDetail from './AhaDetail';

import SubscriptionCapabilities from './SubscriptionCapabilities';
import SubscriptionLicense from './SubscriptionLicense';
import Header from './Header';

const getInitialCapabilities = application => {
  const { applicationCapabilities, multiCapabilitiesAllowed } = application;
  if (!multiCapabilitiesAllowed) {
    return applicationCapabilities[0];
  }
  return applicationCapabilities;
};

const RegularSubscription = ({ subscription, backToList, closeModal }) => {
  const {
    application: { appName, applicationCapabilities },
    licenseManagements
  } = subscription;
  const { addApplication } = useRoleForm();
  const {
    showLicensesTab,
    hasLicenseInfo,
    selectedCapabilities,
    selectedLicense,
    chooseLicense,
    toggleCapability,
    handleBack,
    handleNext
  } = useRegularSubscription({
    subscription,
    backToList,
    addApplication,
    closeModal
  });
  return (
    <>
      <Header
        appName={appName}
        handleBack={handleBack}
        closeModal={closeModal}
      />
      {showLicensesTab ? (
        <SubscriptionLicense
          licenseManagements={licenseManagements}
          selectedLicense={selectedLicense}
          chooseLicense={chooseLicense}
          handleNext={handleNext}
        />
      ) : (
        <SubscriptionCapabilities
          hasLicenseInfo={hasLicenseInfo}
          selectedCapabilities={selectedCapabilities}
          applicationCapabilities={applicationCapabilities}
          toggleCapability={toggleCapability}
          handleNext={handleNext}
        />
      )}
    </>
  );
};

const SubscriptionDetail = ({ subscription, backToList, closeModal }) => {
  const { appAPIName, appName } = subscription.application;
  if (appAPIName !== 'aha') {
    return (
      <RegularSubscription
        subscription={subscription}
        backToList={backToList}
        closeModal={closeModal}
      />
    );
  }
  return (
    <>
      <Header
        appName={appName}
        handleBack={backToList}
        closeModal={closeModal}
      />
      <AhaDetail
        application={subscription.application}
        closeModal={closeModal}
      />
    </>
  );
};

export default SubscriptionDetail;

import React, { useState, useEffect } from 'react';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import GroupIcon from '@material-ui/icons/Group';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import CircularProgress from '@material-ui/core/CircularProgress';

import { DashboardService } from 'services';
import useAnnouncements from 'hooks/useAnnouncements';

import Announcement from './Announcement';

import { Container, Title, StatsContainer, IndividualStat } from './styles';

const CompanyOverview = () => {
  const [{ totalRoles, totalTeams, totalLyncWorkUsers }, setData] = useState({
    totalRoles: null,
    totalTeams: null,
    totalLyncWorkUsers: null
  });
  useEffect(() => {
    let mounted = true;
    const fetch = async () => {
      try {
        const response = await DashboardService.list();
        mounted && setData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Container>
      <Title>Company Overview</Title>
      <StatsContainer>
        <IndividualStat>
          <AccountCircleIcon /> Users <span>{totalLyncWorkUsers}</span>
        </IndividualStat>
        <IndividualStat>
          <AssignmentIndIcon /> Roles <span>{totalRoles}</span>
        </IndividualStat>
        <IndividualStat>
          <GroupIcon /> Teams <span>{totalTeams}</span>
        </IndividualStat>
      </StatsContainer>
      <Announcement />
    </Container>
  );
};

export default CompanyOverview;

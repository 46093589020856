import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {
  ContentContainer,
  Title,
  PageDescription,
  LinkContainer,
  StyledNavLink
} from './styles';
import colors from 'designSystem/foundations/colors';

const routeInfoList = [
  {
    title: 'Welcome',
    path: '/admin-onboarding/welcome',
    text: 'Use this page to create a Welcome page introducing your company. '
  },
  {
    title: 'Personal Info',
    path: '/admin-onboarding/personal-information',
    text:
      'Select the form fields you’d like your employee to complete, and indicate ‘mandatory’ fields'
  },
  {
    title: 'Documents',
    path: '/admin-onboarding/documents',
    text: 'Upload necessary documents for your employee to view and sign.'
  },
  {
    title: 'Engagement',
    path: '/admin-onboarding/engagement',
    text: 'placeholder text for engagement'
  }
];
const NavItem = ({ title, href, styles }) => {
  return (
    <StyledNavLink
      to={href}
      className="onboarding-nav-link"
      activeClassName="onboarding-nav-link-active"
      style={styles}
    >
      <div className="active-div">
        <p>{title}</p>
      </div>
    </StyledNavLink>
  );
};
const PageHeader = ({ routeInfo }) => {
  if (!routeInfo) {
    return null;
  }
  return (
    <>
      <LinkContainer>
        {routeInfoList.map(link => (
          <NavItem
            styles={{
              color: colors.brand.primary
            }}
            key={link.title}
            title={link.title}
            href={link.path}
          >
            {link.title}
          </NavItem>
        ))}
      </LinkContainer>
      <ContentContainer>
        <PageDescription>
          <span>
            <ErrorOutlineIcon fontSize="small" />
          </span>
          {routeInfo.text}
        </PageDescription>
      </ContentContainer>
    </>
  );
};

const Header = () => {
  const { pathname } = useLocation();
  const routeInfo = routeInfoList.find(info => pathname.includes(info.path));
  return (
    <Switch>
      <Route path="/admin-onboarding" exact>
        <Title>Onboarding</Title>
      </Route>
      <Route path="/admin-onboarding/engagement" exact>
        <LinkContainer>
          {routeInfoList.map(link => (
            <NavItem
              styles={{
                color: colors.brand.primary
              }}
              key={link.title}
              title={link.title}
              href={link.path}
            >
              {link.title}
            </NavItem>
          ))}
        </LinkContainer>
      </Route>
      <Route path="/admin-onboarding/*">
        <PageHeader routeInfo={routeInfo} />
      </Route>
    </Switch>
  );
};

export default Header;

import React, { useState } from 'react';
import generateMediaUrl from 'utilities/generateMediaUrl';
import getRandomColor from 'utilities/getRandomColor';

import {
  Title,
  Tagline,
  AppListContainers,
  AppContainer,
  AppLogoContainer,
  AppLogoPlaceholder,
  AppTextContainer,
  AppName,
  AppDesc
} from './styles';

const SingleApp = ({ app }) => {
  const { appLogo, appName, appDesc, id } = app;
  const src = generateMediaUrl(appLogo);
  const firstInitial = appName.toUpperCase()[0];
  const [showLogo, setShowLogo] = useState(!!src);
  const handleImageError = () => setShowLogo(false);
  const backgroundColor = getRandomColor(id);
  return (
    <AppContainer>
      <AppLogoContainer>
        {showLogo ? (
          <img
            src={generateMediaUrl(appLogo)}
            alt={appName}
            onError={handleImageError}
          />
        ) : (
          <AppLogoPlaceholder backgroundColor={backgroundColor}>
            {firstInitial}
          </AppLogoPlaceholder>
        )}
      </AppLogoContainer>
      <AppTextContainer>
        <AppName>{appName}</AppName>
        <AppDesc lineClamp={3}>{appDesc}</AppDesc>
      </AppTextContainer>
    </AppContainer>
  );
};

const RecommendedApps = ({ recommendedApps }) => {
  return (
    <>
      <Title>Other Applications for Setup</Title>
      <Tagline>
        Please check your company email and set up the following applications.
      </Tagline>
      <AppListContainers>
        {recommendedApps.map(app => (
          <SingleApp key={app.id} app={app} />
        ))}
      </AppListContainers>
    </>
  );
};

export default RecommendedApps;

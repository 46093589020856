import styled from 'styled-components';
import colors from 'designSystem/foundations/colors';

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 104px;
`;
export const ButtonSpacer = styled.div`
  width: 16px;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
export const RTEContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;
export const ErrorP = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */
  margin: 24px 0;

  letter-spacing: 0.777778px;

  color: red;
`;

import React, { useState } from 'react';
import { Add } from '@material-ui/icons';

import emptyState from 'assets/images/Onboarding/ApplicationSetup.svg';

import PortalContainer from 'components/PortalContainer';
import CustomButton from 'components/NewLyncworkUIKit/CustomButton';

import { useRoleForm } from '../../../RoleFormContext';

import { Container, OverflowContainer } from './styles';

import SearchBar from './SearchBar';
import ApplicationCard from './ApplicationCard';
import ApplicationModal from './ApplicationModal';
import { NoApps, NoAppsFound } from './EmptyScreen';

const AppFilterList = ({ applications, selectApplication }) => {
  const hasResult = applications.length > 0;
  if (!hasResult) {
    return <NoAppsFound />;
  }
  return (
    <OverflowContainer>
      {applications.map(application => {
        const onClick = () => selectApplication(application);
        return (
          <ApplicationCard
            key={application.id}
            application={application}
            onClick={onClick}
          />
        );
      })}
    </OverflowContainer>
  );
};

const ApplicationList = ({ openSubscriptionModal }) => {
  const {
    formState: { applications }
  } = useRoleForm();
  const [value, setValue] = useState('');
  const hasApplications = applications.length > 0;
  const [selectedApplication, setSelectedApplication] = useState(null);

  const onInputChange = e => setValue(e.target.value);
  const filteredApplications = applications.filter(({ appName }) => {
    const formattedAppName = appName.toLowerCase();
    const formattedValue = value.toLowerCase();
    return formattedAppName.includes(formattedValue);
  });

  const closeModal = () => setSelectedApplication(null);
  const selectApplication = application => setSelectedApplication(application);

  if (!hasApplications) {
    return <NoApps openSubscriptionModal={openSubscriptionModal} />;
  }
  const isOpen = selectedApplication !== null;
  return (
    <Container>
      <PortalContainer isOpen={isOpen} handleClickOutside={closeModal}>
        <ApplicationModal
          selectedApplication={selectedApplication}
          closeModal={closeModal}
        />
      </PortalContainer>
      <SearchBar
        value={value}
        onInputChange={onInputChange}
        onClick={openSubscriptionModal}
      />
      <AppFilterList
        applications={filteredApplications}
        selectApplication={selectApplication}
      />
    </Container>
  );
};

export default ApplicationList;

import {
  CHANGE_TEXT_VALUE,
  HANDLE_PERMISSION_CHANGE,
  TOGGLE_ADMIN_ACCESS,
  EDIT_APPLICATION_CAPABILITY,
  REMOVE_APPLICATION,
  ADD_APPLICATION
} from './roleFormActions';

const handleTextChange = (state, action) => {
  const { name, value } = action;
  return { ...state, [name]: value };
};

const handlePermissionChange = (state, action) => {
  const { id, checked } = action;
  const { permissions: oldPermissions } = state;
  const otherPermissions = oldPermissions.filter(item => item.id !== id);
  const permissionModified = { id, application: { id: 6 } };
  const permissions = checked
    ? [...otherPermissions, permissionModified]
    : otherPermissions;
  return { ...state, permissions };
};

const toggleAdminAccess = (state, action) => {
  const { checked } = action;
  return { ...state, adminAccess: checked };
};

const editApplicationCapability = (state, action) => {
  const { application, selectedCapabilities } = action;
  const { id: appId, appAPIName } = application;
  const { capabilities: oldCapabilities } = state;
  const capabilitiesFromOtherApps = oldCapabilities.filter(
    ({ application }) => application.id !== appId
  );

  if (appAPIName === 'aha') {
    const capabilities = [
      ...capabilitiesFromOtherApps,
      ...application.applicationCapabilities
    ];
    return {
      ...state,
      capabilities,
      subscriptionAppCapabilityProperties:
        action.subscriptionAppCapabilityProperties
    };
  }

  const capabilities = [...capabilitiesFromOtherApps, ...selectedCapabilities];
  return { ...state, capabilities };
};

const removeApplication = (state, action) => {
  const {
    application: { id: appId, appAPIName }
  } = action;
  const applications = state.applications.filter(({ id }) => id !== appId);
  const capabilities = state.capabilities.filter(
    ({ application }) => application.id !== appId
  );
  const licenseManagements = state.licenseManagements.filter(
    license => license.applicationLicenseInformation.application.id !== appId
  );

  const subscriptionAppCapabilityProperties =
    appAPIName === 'aha' ? [] : state.subscriptionAppCapabilityProperties;

  return {
    ...state,
    capabilities,
    applications,
    licenseManagements,
    subscriptionAppCapabilityProperties
  };
};

const addApplication = (state, action) => {
  const { application } = action;
  const applications = [...state.applications, application];
  if (application.appAPIName === 'aha') {
    const { subscriptionAppCapabilityProperties } = action;
    return {
      ...state,
      applications,
      capabilities: [
        ...state.capabilities,
        ...application.applicationCapabilities
      ],
      subscriptionAppCapabilityProperties
    };
  }

  const { selectedCapabilities, selectedLicense } = action;
  const capabilities = [...state.capabilities, ...selectedCapabilities];
  const licenseManagements = selectedLicense
    ? [...state.licenseManagements, selectedLicense]
    : state.licenseManagements;
  return {
    ...state,
    applications,
    capabilities,
    licenseManagements
  };
};

const roleFormReducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case CHANGE_TEXT_VALUE:
      return handleTextChange(state, action);
    case HANDLE_PERMISSION_CHANGE:
      return handlePermissionChange(state, action);
    case TOGGLE_ADMIN_ACCESS:
      return toggleAdminAccess(state, action);
    case EDIT_APPLICATION_CAPABILITY:
      return editApplicationCapability(state, action);
    case REMOVE_APPLICATION:
      return removeApplication(state, action);
    case ADD_APPLICATION:
      return addApplication(state, action);
    default:
      return state;
  }
};

export default roleFormReducer;

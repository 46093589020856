import { useState, useEffect } from 'react';
import { ApplicationService, SubscriptionAppService } from 'services';

const officeID = 8;
const gsuiteID = 1;

import delay from 'utilities/delay';
const useEmailSetup = ({ pageData, onPageDataChange }) => {
  const [integratedApp, setIntegratedApp] = useState(
    pageData.integratedApp || null
  );
  const [appSelected, setAppSelected] = useState(null);
  const [{ loading, apps }, setAppData] = useState({
    loading: true,
    apps: []
  });

  useEffect(() => {
    const fetchApps = async () => {
      try {
        const apps = await Promise.all(
          [gsuiteID, officeID].map(async appId => {
            const response = await ApplicationService.find(appId);
            return response.data;
          })
        );
        setAppData({ loading: false, apps });
      } catch (error) {
        setAppData({ loading: false, apps: [] });
        console.log(error);
      }
    };
    fetchApps();
  }, []);

  const cancelSelection = () => setAppSelected(null);

  const integrateApplication = async requestData => {
    // await delay(1000);
    await SubscriptionAppService.create(requestData);
    const { appName } = requestData.application;
    setIntegratedApp(appName);
    setAppSelected(null);
    onPageDataChange({ ...pageData, integratedApp: appName });
  };
  return {
    integratedApp,
    loading,
    apps,
    appSelected,
    selectApp: setAppSelected,
    cancelSelection,
    integrateApplication
  };
};

export default useEmailSetup;

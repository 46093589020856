import { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { UserService } from 'services';
import debounce from 'utilities/debounce';

export const useDisplayedUser = (users, loading) => {
  const { id } = useParams();
  const history = useHistory();
  const userFromParam = users.find(user => user.id === Number(id)) || null;

  const displayedUser = loading ? null : userFromParam;
  const selectUser = user => {
    history.push(`/company-directory/${user.id}`);
  };

  return { displayedUser, selectUser };
};

const initialFetchState = {
  name: '',
  page: 0,
  size: '10',
  team: null,
  role: null,
  users: [],
  loading: true,
  totalPages: 0,
  key: false
};

const useCompanyDirectory = () => {
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState('');
  const [fetchState, setFetchState] = useState(initialFetchState);
  const {
    name,
    page,
    size,
    team,
    role,
    users,
    loading,
    totalPages,
    key
  } = fetchState;
  const teamId = team?.id || '';
  const roleId = role?.id || '';
  useEffect(() => {
    history.push(`/company-directory/`);

    const fetchUsers = async () => {
      setFetchState(oldState => ({
        ...oldState,
        users: [],
        loading: true
      }));
      try {
        const response = await UserService.companyDirectory({
          name,
          page,
          teamId,
          roleId,
          size: 7
        });
        const { content: users, totalPages } = response.data;
        setFetchState(oldState => ({
          ...oldState,
          users,
          loading: false,
          totalPages
        }));
      } catch (error) {
        console.log(error);
        setFetchState(oldState => ({ ...oldState, loading: false }));
      }
    };
    fetchUsers();
  }, [name, page, teamId, roleId, key]);

  const changePage = newPage => {
    const newPageIndex = newPage - 1;
    setFetchState(oldState => ({ ...oldState, page: newPageIndex }));
  };

  const changeName = useCallback(
    debounce(
      name => setFetchState(oldState => ({ ...oldState, name, totalPages: 0 })),
      500
    ),
    []
  );

  const handleInputChange = e => {
    const value = e.target.value;
    setSearchQuery(value);
    changeName(value);
  };

  const changeParams = (newParams = {}) => {
    setFetchState(oldState => ({
      ...oldState,
      ...newParams,
      totalPages: 0,
      page: 0
    }));
  };

  return {
    loading,
    searchQuery,
    handleInputChange,
    team,
    role,
    changeParams,
    users,
    page,
    totalPages,
    changePage
  };
};

export default useCompanyDirectory;

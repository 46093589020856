import styled, { css } from 'styled-components';

const sizeCSS = css`
  width: ${props => props.size};
  height: ${props => props.size};
`;

export const Container = styled.div`
  position: relative;
  margin: 0;
  background-color: ${props => props.backgroundColor};
  width: 32px;
  height: 32px;
  ${props => props.size && sizeCSS};
  font-size: ${props => props.fontSize};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 600;
  color: white;
  overflow: hidden;
  flex-shrink: 0;
`;

export const StyledImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: ${props => props.objectFit};
  display: ${props => (props.showAvatar ? 'unset' : 'none')};
`;

Container.defaultProps = {
  objectFit: 'cover',
  fontSize: '14px'
};

import styled from 'styled-components';
import Card from 'components/Card';

export const Container = styled(Card)`
  padding: 24px;

  grid-row: 1/2;
  grid-column: 1/2;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .announcement-text {
    display: flex;
    align-items: center;
    margin: 16px 0;
    color: ${props => props.theme.colors.brand.primary};
    ${props => props.theme.typography.control};
    .MuiSvgIcon-root {
      font-size: 16px;
      margin-right: 4px;
    }
  }
`;

export const Title = styled.h1`
  ${props => props.theme.typography.title3};
  margin: 0;
  font-weight: 600;
`;

export const StatsContainer = styled.div`
  margin: 12px 0 0;
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.grey05};
`;

export const IndividualStat = styled.div`
  width: 140px;
  height: 24px;
  display: flex;
  align-items: center;
  ${props => props.theme.typography.control};
  font-weight: 500;
  color: ${props => props.theme.colors.neutral.grey07};

  span {
    ${props => props.theme.typography.body};
    font-weight: 600;
    margin-left: auto;
  }

  .MuiSvgIcon-root {
    font-size: 20px;
    margin-right: 4px;
  }
`;

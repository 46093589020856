import { TaskService } from 'services/task.service';

const modifyTask = async workflowTask => {
  const { modifyMethod, ...journeyTaskData } = workflowTask;
  if (!modifyMethod) {
    return journeyTaskData;
  }
  const response = await TaskService[modifyMethod](journeyTaskData.task);
  return { ...journeyTaskData, task: response.data };
};

export const generateWorkflowTasks = async workflowTasks => {
  const syncedWorkflowTasks = await Promise.all(workflowTasks.map(modifyTask));
  return syncedWorkflowTasks;
};

import styled from 'styled-components';

import colors from 'designSystem/foundations/colors';
import { Avatar, CustomCheckbox } from 'components/NewLyncworkUIKit';
import { Row } from 'components/ListTable';

const {
  neutral: { grey07, offWhite03 },
  feedback: { confirmation, warning02, success }
} = colors;

export const StyledAvatar = styled(Avatar)`
  width: 28px;
  height: 28px;
`;

export const StyledCheckbox = styled(CustomCheckbox)`
  margin: 0 0 0 24px;
`;

export const StyledRow = styled(Row)`
  cursor: pointer;
`;

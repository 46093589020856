import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  padding: 48px;
  background-color: ${props => props.theme.colors.neutral.white};
  height: 500px;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

export const ImportImage = styled.img`
  position: absolute;
  right: 48px;
  bottom: 0;
`;

export const Title = styled.h2`
  color: ${props => props.theme.colors.neutral.grey10};
  ${props => props.theme.typography.header2};
  font-weight: 600;
  margin: 0 0 56px;
`;

export const ButtonContainer = styled.div`
  margin: 32px 0 0 0;
  display: flex;
  justify-content: flex-end;
  button {
    margin: 0 0 0 16px;
  }
`;

export const ApplicationContainer = styled.div`
  display: flex;
  width: 432px;
  flex-wrap: wrap;
`;

export const AppCardContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 16px;
  width: 200px;
  height: 56px;
  border: 1px solid transparent;
  border-color: ${props =>
    props.selected ? props.theme.colors.brand.primary : 'transparent'};
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
  border-radius: 4px;
  padding: 16px;
  box-sizing: border-box;
  color: ${props => props.theme.colors.neutral.grey07};
  img {
    margin-right: 8px;
    height: 20px;
    width: 20px;
    object-fit: contain;
  }
`;

import styled from 'styled-components';
import { scrollBar } from 'StyleVariables/StandardComponents';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const TopBar = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.neutral.grey07};
  color: ${props => props.theme.colors.neutral.white};
  ${props => props.theme.typography.header3};
  font-weight: 600;
  padding: 32px 0;
  text-align: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  background-color: ${props => props.theme.colors.neutral.offWhite01};

  border-radius: 10px;
  width: 1200px;
  max-width: calc(100% - 48px);
  height: 720px;
  max-height: calc(100% - 168px);
  margin: 36px auto;
  overflow: hidden;
`;

export const RightSection = styled.div`
  flex-grow: 1;
  margin-left: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  background-color: ${props => props.theme.colors.neutral.offWhite01};
  ${scrollBar}
`;

export const CloseButtonContainer = styled.button`
  appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.neutral.white};
  background-color: transparent;
  top: 50%;
  right: 0;
  margin-right: 32px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  &:focus-visible {
    outline: 2px solid #0078d4;
  }
`;

import React, { useState } from 'react';

import { Container } from './styles';

import LicenceOverview from './LicenceOverview';
import LicenceDetail from './LicenceDetail';

const ApplicationLicences = ({
  licenses,
  onDragEnd,
  toggleLicense,
  licenceInfo
}) => {
  const [selectedLicenceId, setSelectedLicenceId] = useState(null);
  const selectedLicence = licenses.find(
    license =>
      license.applicationLicenseInformation.licenseId === selectedLicenceId
  );
  return (
    <Container>
      <LicenceOverview
        licenses={licenses}
        onDragEnd={onDragEnd}
        setSelectedLicenceId={setSelectedLicenceId}
        selectedLicenceId={selectedLicenceId}
      />
      <LicenceDetail
        selectedLicence={selectedLicence}
        toggleLicense={toggleLicense}
        licenceInfo={licenceInfo}
      />
    </Container>
  );
};

export default ApplicationLicences;

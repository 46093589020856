import styled from 'styled-components';

export const ContentContainer = styled.div`
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: scroll;
  gap: 16px;
  h2 {
    display: flex;
    align-items: center;
    gap: 8px;
    ${props => props.theme.typography.header2};
    font-weight: 600;
    margin: 0 0 8px;
  }
`;

export const ProductCard = styled.div`
  border-radius: 8px;
  flex-shrink: 0;
  height: 68px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
  background: white;
  gap: 8px;
  border: 1px solid ${props => props.theme.colors.neutral.grey03};
  ${props => props.theme.typography.body};
  font-weight: 600;
  .role-select {
    flex-shrink: 0;
    width: 200px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

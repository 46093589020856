import styled, { css } from 'styled-components';
import Button from 'components/NewLyncworkUIKit/CustomButton';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 80px 0;
  width: calc(100% - 40px);
  width: 100%;

  max-width: 600px;
`;

export const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;
`;

export const Title = styled.h3`
  ${props => props.theme.typography.title3};
  font-weight: 600;
  margin: 0;
`;

export const Tagline = styled.h3`
  width: 80%;
  ${props => props.theme.typography.header3};
  text-align: center;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 32px 0 64px;

  width: 100%;
  max-width: 600px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CustomButton = styled(Button)`
  width: 160px;
  margin: 0 8px;
`;

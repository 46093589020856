import { css } from 'styled-components';

const largeTitle = css`
  font-weight: 400;
  font-size: 4.25rem;
  line-height: 4.75rem;
  strong {
    font-weight: 600;
  }
`;

const title1 = css`
  font-weight: 400;
  font-size: 2.625rem;
  line-height: 3.25rem;
  strong {
    font-weight: 600;
  }
`;

const title2 = css`
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.5rem;
  strong {
    font-weight: 600;
  }
`;

const title3 = css`
  font-weight: 400;
  font-size: 1.75rem;
  line-height: 2.25rem;
  strong {
    font-weight: 600;
  }
`;

const header1 = css`
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2rem;
  strong {
    font-weight: 600;
  }
`;

const header2 = css`
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.75rem;
  strong {
    font-weight: 600;
  }
`;

const header3 = css`
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5rem;
  strong {
    font-weight: 600;
  }
`;

const body = css`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
  strong {
    font-weight: 600;
  }
`;

const control = css`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  strong {
    font-weight: 600;
  }
`;

const metadata = css`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  strong {
    font-weight: 600;
  }
`;

const footnote = css`
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 0.875rem;
  strong {
    font-weight: 600;
  }
`;

const typography = {
  largeTitle,
  title1,
  title2,
  title3,
  header1,
  header2,
  header3,
  body,
  control,
  metadata,
  footnote
};

export default typography;

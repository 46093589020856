import React, { useState, useEffect } from 'react';

import PreviewModal from 'pages/AdminOnboarding/PreviewModal';

import useDocumentsPage from './useDocumentsPage';
import {
  UploadArea,
  DocumentList,
  FileEditModal,
  FileDeletePrompt,
  DocumentsPagePreview
} from './components';

import { Container, ContentContainer, ListArea, CustomButton } from './styles';

const DocumentsPage = () => {
  const {
    files,
    fileToEdit,
    uploadFile,
    editFile,
    closeEditModal,
    updateMetadata,
    downloadFile,
    fileToDelete,
    openDeletePrompt,
    closeDeletePrompt,
    deleteFile,
    showPreview,
    openPreview,
    closePreview
  } = useDocumentsPage();

  return (
    <Container>
      <PreviewModal
        isOpen={showPreview}
        handleClose={closePreview}
        previewTitle="Onboarding Documents Page"
        pathName="/user/welcome/documents"
      >
        <DocumentsPagePreview files={files} />
      </PreviewModal>
      <FileDeletePrompt
        fileToDelete={fileToDelete}
        closeDeletePrompt={closeDeletePrompt}
        deleteFile={deleteFile}
      />
      <FileEditModal
        fileToEdit={fileToEdit}
        closeEditModal={closeEditModal}
        updateMetadata={updateMetadata}
        downloadFile={downloadFile}
      />
      <ContentContainer>
        <UploadArea uploadFile={uploadFile} />
        <DocumentList
          files={files}
          editFile={editFile}
          openDeletePrompt={openDeletePrompt}
        />
      </ContentContainer>
      <CustomButton onClick={openPreview}>Preview Page</CustomButton>
    </Container>
  );
};

export default DocumentsPage;

import styled from 'styled-components';
import Input from 'components/NewLyncworkUIKit/CustomTextInput';
import Button from 'components/NewLyncworkUIKit/CustomButton';
import colors from 'designSystem/foundations/colors';
export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.neutral.white};
  width: 321px;
  padding: 24px;
  border-radius: 8px;
`;

export const Title = styled.h2`
  ${props => props.theme.typography.header2};
  font-weight: 600;
  margin-bottom: 32px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: ${props => (props.fullWidth ? '100%' : 'calc(50% - 4px)')};
  margin-bottom: 32px;
`;

InputWrapper.defaultProps = {
  fullWidth: false
};

export const CustomInput = styled(Input)`
  height: 44px;
  border-radius: 4px;
`;

export const InputLabel = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${props => props.theme.typography.body};
  font-weight: 500;
  margin-bottom: 8px;
`;

export const ErrorMessage = styled.span`
  color: ${props => props.theme.colors.feedback.error};
  ${props => props.theme.typography.metadata};
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  margin-left: 4px;
  border-radius: 50%;
  background-color: transparent;
  color: ${props => props.theme.colors.neutral.black};

  &:hover {
    background-color: ${props => props.theme.colors.neutral.offWhite03};
  }
  &:focus-visible {
    outline: 2px solid blue;
  }
`;

export const FileContainer = styled.div`
  box-sizing: border-box;
  height: 24px;
  padding: 0px;
  display: flex;
  align-items: center;

  border-radius: 4px;
`;

export const FileName = styled.div`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  /* Neutral/Grey 10 */

  color: ${colors.neutral.grey10};
  margin: 0 auto 0 8px;
`;

export const DownloadButton = styled(Button)`
  width: 120px;
  height: 28px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  /* Neutral/Grey 07 */

  color: ${colors.neutral.grey07};
  background-color: ${props => props.theme.colors.neutral.white};
  &:hover {
    background-color: ${props => props.theme.colors.neutral.offWhite02};
  }
`;
DownloadButton.defaultProps = {
  variant: 'secondary'
};
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const RedText = styled.p`
  color: ${colors.feedback.error};
  margin: 0;
`;
export const AcceptedFiles = styled.p`
  /* Footnote (10px) */

  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;

  color: ${colors.neutral.grey06};
`;

import styled, { css } from 'styled-components';
import { colors } from 'designSystem/foundations';
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${props => props.theme.colors.neutral.grey02};
  width: 460px;
  flex-shrink: 0;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;

  padding: 24px 0;
  margin: 0 32px;
  gap: 4px;
  ${props => props.theme.typography.control};
  font-weight: 400;
  color: ${props => props.theme.colors.neutral.grey07};
  border-bottom: 1px solid ${props => props.theme.colors.neutral.grey02};

  h2 {
    ${props => props.theme.typography.header2};
    font-weight: 600;
    color: ${props => props.theme.colors.neutral.grey10};
    margin: 0;
  }

  .description {
    display: flex;
    gap: 4px;
  }
`;

export const ScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0px;
  }
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar-track {
    position: absolute;
    background: transparent;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      border-radius: 0px;
    }
  }
`;

export const DragHandle = styled.div`
  transform-origin: center center;
  transform: rotate(90deg);
`;

const {
  neutral: { white, offWhite01, grey01 }
} = colors;

export const LicenceCardContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px;
  gap: 12px;
  height: 68px;
  text-transform: capitalize;
  background-color: ${props => (props.selected ? grey01 : white)};
  cursor: pointer;
  &:hover {
    background-color: ${props => (props.selected ? grey01 : offWhite01)};
  }
`;

export const DefaultStatus = styled.div`
  margin-left: auto;
  display: flex;
  padding: 4px 8px;
  ${props => props.theme.typography.control};
  font-weight: 500;
  color: ${props => props.theme.colors.feedback.confirmation};
  background: #f0f7ff;
  border-radius: 100px;
`;

import useAuthentication from 'hooks/useAuthentication';
import React, { useEffect } from 'react';
import {
  ImageContainer,
  UploadErrorText,
  UploadButton,
  Placeholder
} from './styles';

const ImageCircle = ({
  url,
  maxWidth = '',
  editMode = false,
  uploadCallback,
  sizeCap,
  firstName = '',
  fontSize
}) => {
  const [pictureHeight, setPictureHeight] = React.useState(0);
  const [uploadError, setUploadError] = React.useState(false);
  const [src, setSrc] = React.useState('');
  const { user } = useAuthentication();
  const determineSize = (formats, desired) => {
    const formatNames = Object.keys(formats);
    if (formatNames.length) {
      if (formatNames.includes(desired)) {
        return formats[desired].url;
      } else {
        return formats[formatNames[0]].url;
      }
    } else {
      return null;
    }
  };

  const img = React.useRef();

  useEffect(() => {
    const width = img.current.clientWidth;
    const small = 334;
    const medium = 501;
    const large = 668;
    if (url) {
      switch (typeof url) {
        case 'string':
          url.length && setSrc(url);
          break;
        case 'object':
          {
            const formats = url.formats;

            if (url.formats) {
              large < width
                ? setSrc(determineSize(formats, 'large'))
                : medium < width < large
                ? setSrc(determineSize(formats, 'medium'))
                : small < width < medium
                ? setSrc(determineSize(formats, 'small'))
                : setSrc(formats.thumbnail.url);
            } else {
              const imgSrc = url.url;
              if (imgSrc) {
                setSrc(imgSrc);
              }
            }
          }
          break;
        default: {
          return;
        }
      }
    } else {
      setSrc(null);
    }
    setPictureHeight(width);
  }, [url]);
  const onSelect = event => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    const filePreview = URL.createObjectURL(file);
    if (file.size < sizeCap) {
      uploadCallback(file);
      setSrc(filePreview);
    } else {
      setUploadError(true);
    }
  };

  return (
    <>
      {
        <ImageContainer
          user={user}
          src={src}
          height={pictureHeight}
          maxWidth={maxWidth}
          className="img-circle"
          ref={img}
          firstName={firstName}
        >
          {!src && (
            <Placeholder fontSize={fontSize}>
              {firstName ? firstName[0] : '?'}
            </Placeholder>
          )}
        </ImageContainer>
      }
      {editMode && (
        <UploadButton>
          Upload Picture
          <input
            type="file"
            accept="image/png, image/jpeg,image/jpeg"
            onChange={event => onSelect(event)}
          />
        </UploadButton>
      )}
      {uploadError && (
        <UploadErrorText>The image you chose is too large</UploadErrorText>
      )}
    </>
  );
};

export default ImageCircle;

import styled from 'styled-components';
import Card from 'components/Card';
import { multilineEllipsis } from 'StyleVariables/StandardComponents';

export const Container = styled(Card)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
  .MuiCircularProgress-root {
    margin: auto;
  }
`;

export const Title = styled.h2`
  margin: 0;
  ${props => props.theme.typography.header2};
  font-weight: 600;
`;

export const AnnouncementContainer = styled.div`
  display: flex;
  margin: 16px 0 0 0;

  & > div:nth-child(2) {
    display: flex;
    flex-direction: column;
  }
`;
AnnouncementContainer.defaultProps = { lineClamp: 1 };

export const AnnouncementTitle = styled.h3`
  margin: 0;
  ${props => props.theme.typography.control};
  font-weight: 600;
  color: ${props => props.theme.colors.neutral.grey10};
  ${multilineEllipsis}
`;

AnnouncementTitle.defaultProps = { lineClamp: 1 };

export const RedDot = styled.div`
  flex-shrink: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #f54766;
  margin: 6px 8px 0 0;
`;

export const AnnouncementDate = styled.span`
  ${props => props.theme.typography.metadata};
  font-weight: 500;
  color: ${props => props.theme.colors.neutral.grey07};
`;

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { multilineEllipsis } from 'StyleVariables/StandardComponents';
export const Container = styled.div`
  box-sizing: border-box;
  width: 340px;
  height: 260px;
  background: white;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 24px;

  display: flex;
  flex-direction: column;
  a {
    text-decoration: none;
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.medium}) {
    width: unset;
  }
`;

export const Title = styled.h1`
  margin: 0 0 4px 0;
  width: fit-content;
  ${props => props.theme.typography.header3};
  font-weight: 600;
  color: ${props => props.theme.colors.brand.primary};
  cursor: pointer;
  ${multilineEllipsis}
`;
Title.defaultProps = { lineClamp: 1 };

export const Description = styled.p`
  ${props => props.theme.typography.control};
  ${multilineEllipsis}
  margin: 0 0 24px;
  height: 60px;
  /* ... */
`;

Description.defaultProps = { lineClamp: 3 };

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MetaData = styled.span`
  ${props => props.theme.typography.metadata};
  color: ${props => props.theme.colors.neutral.grey05};
  font-weight: 500;
  &.bold {
    color: ${props => props.theme.colors.neutral.grey07};
    font-weight: 600;
  }
`;

export const ProgressBar = styled.div`
  position: relative;
  width: 100%;
  height: 8px;
  border-radius: 4px;
  margin: 4px 0 24px;
  background-color: ${props => props.theme.colors.neutral.grey02};

  &:after {
    content: '';
    position: absolute;
    border-radius: 4px;
    height: 100%;
    width: ${props => props.barWidth};
    background-color: ${props => props.theme.colors.brand.primary};
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

import React, { useState } from 'react';

import { useButtonTrack } from 'matomo/hooks';
import { userAppImportPageEvents } from 'pages/Users/UsersListPage/instrumentationEvents';

import useDialogue from 'hooks/useDialogue';

import { getAppUsers, defaultPageSize } from '../utils';

const { select_all_users, select_user } = userAppImportPageEvents;

const useSelectUsers = ({ nextPage, data, setData, appAPIName }) => {
  const { showDialogue, closeDialogue } = useDialogue();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const trackButton = useButtonTrack();
  const { users, selected, nextPageParams } = data;
  const totalSelectedUsers = users.filter(({ username }) => selected[username]);
  const totalSelectedCount = totalSelectedUsers.length;
  const startIndex = (page - 1) * defaultPageSize;
  const endIndex = page * defaultPageSize;

  const nextPageUsersFetched = endIndex < users.length;

  const visibleUsers = users.slice(startIndex, endIndex);

  const areAllVisibleUsersSelected = visibleUsers.every(
    ({ username }) => selected[username]
  );

  const handleNextStep = () => {
    if (totalSelectedCount < 1) {
      showDialogue({
        title: 'Please select at least one user',
        message: '',
        confirmButtonText: 'Okay',
        confirmButtonAction: closeDialogue
      });
      return;
    }
    nextPage();
  };

  const handleSelectAll = e => {
    const { checked } = e.target;
    trackButton(select_all_users);
    setData(oldData => {
      visibleUsers.forEach(({ username }) => {
        oldData.selected[username] = checked;
      });
      return { ...oldData };
    });
  };

  const handleUserToggle = username => {
    trackButton(select_user);
    setData(oldData => {
      const isSelected = oldData.selected[username];
      oldData.selected[username] = !isSelected;
      return { ...oldData };
    });
  };

  const changeRole = (username, role) => {
    setData(oldData => {
      const { users: oldUsers } = oldData;
      const users = oldUsers.map(user => {
        if (user.username !== username) {
          return user;
        }
        return { ...user, roles: [role] };
      });
      return { ...oldData, users };
    });
  };

  const showNextPageUsers = async () => {
    if (nextPageUsersFetched) {
      setPage(a => a + 1);
      return;
    }

    try {
      setLoading(true);
      const newResult = await getAppUsers(appAPIName, nextPageParams);
      setData(oldData => {
        const newUsers = [...oldData.users, ...newResult.users];
        return {
          ...oldData,
          users: newUsers,
          nextPageParams: newResult.nextPageParams
        };
      });
      setLoading(false);
      if (newResult.users.length < 1) {
        showDialogue({
          title: 'There are no more users',
          message: '',
          confirmButtonText: 'Okay',
          confirmButtonAction: closeDialogue
        });
        return;
      }
      setPage(a => a + 1);
    } catch (error) {
      setLoading(false);
      showDialogue({
        title: 'Oops, something went wrong',
        message: 'Please try again later',
        confirmButtonText: 'Okay',
        confirmButtonAction: closeDialogue
      });
    }
  };

  const showPrevPageUsers = () => setPage(a => a - 1);

  return {
    totalSelectedCount,
    visibleUsers,
    selected,
    areAllVisibleUsersSelected,
    nextPageUsersFetched,
    loading,
    page,
    showNextPageUsers,
    showPrevPageUsers,
    handleNextStep,
    handleSelectAll,
    handleUserToggle,
    changeRole
  };
};

export default useSelectUsers;

import React, { useRef, useEffect, useState } from 'react';
import { Container, NavLink, NavTitle } from './styles';

const smoothScroll = id => {
  const element = document.querySelector(`[data-task-id="${id}"]`);
  if (!element) {
    return;
  }
  const container = document.getElementById('page-container-content');
  const top = element.getBoundingClientRect().top;
  container.scrollTo({
    top: top + container.scrollTop - 100,
    left: 0,
    behavior: 'smooth'
  });
};

const findVisibleTaskId = () => {
  const elements = [...document.querySelectorAll('[data-task-id]')];
  const firstVisibleElement = elements.find(element => {
    const { top, bottom } = element.getBoundingClientRect();
    return top <= 200 && bottom >= 200;
  });
  if (!firstVisibleElement) {
    return null;
  }
  const taskId = firstVisibleElement.getAttribute('data-task-id');
  return Number(taskId);
};

const JourneyTaskNavigation = ({
  tasks,
  journey,
  inputValue,
  setInputValue
}) => {
  const taskIdRef = useRef(null);
  const [currentTaskId, setCurrentTaskId] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const visibleTaskId = findVisibleTaskId();
      setCurrentTaskId(visibleTaskId);
    };
    const container = document.getElementById('page-container-content');
    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const taskIdToScroll = taskIdRef.current;
    if (inputValue || !taskIdToScroll) {
      return;
    }
    smoothScroll(taskIdToScroll);
    taskIdRef.current = null;
  }, [inputValue]);

  if (!journey) {
    return null;
  }

  const { workflowName } = journey;
  return (
    <Container>
      <NavTitle>{workflowName}</NavTitle>
      {tasks.map(task => {
        const {
          id,
          task: { taskEventName }
        } = task;
        const onClick = () => {
          if (!inputValue) {
            smoothScroll(id);
            return;
          }
          taskIdRef.current = id;
          setInputValue('');
        };
        return (
          <NavLink
            href="#"
            key={id}
            onClick={onClick}
            isCurrent={currentTaskId === id}
          >
            {taskEventName}
          </NavLink>
        );
      })}
    </Container>
  );
};

export default JourneyTaskNavigation;

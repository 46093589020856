export const primary_email_provider = 'primary_email_provider';

export const getFormValue = (applicationProperties, properties = []) => {
  const formValue = {};

  applicationProperties.forEach(({ name }) => {
    formValue[name] = '';
  });

  if (properties.length < 1) {
    return formValue;
  }
  properties.forEach(({ key, value }) => {
    if (key === primary_email_provider) {
      formValue[key] = value === String(true);
    } else {
      formValue[key] = value;
    }
  });
  return formValue;
};

export const getFormInfo = (applicationProperties, properties) => {
  const formLabels = {};
  applicationProperties.forEach(({ name, label }) => {
    formLabels[name] = label;
  });

  const enableEmailProviderToggle = properties.some(({ key, value }) => {
    return key === primary_email_provider && value !== String(true);
  });

  const fieldNames = applicationProperties
    .map(({ name }) => name)
    .filter(name => name !== primary_email_provider);

  return { formLabels, fieldNames, enableEmailProviderToggle };
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

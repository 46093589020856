import React from 'react';
import { Row, Cell } from 'components/ListTable';
import { StyledCheckbox, StyledAvatar, UserName } from './styles';

const getUserInfo = user => {
  if (!user) {
    return { avatarUrl: '', fullName: '' };
  }

  const { firstName, lastName, avatar } = user;
  const avatarUrl = avatar?.url;
  const fullName = `${firstName} ${lastName}`;
  return { avatarUrl, fullName };
};

const HelloSignRow = ({ item, handleSelect, selectedFiles }) => {
  const { title, user, signedDate } = item;

  const { avatarUrl, fullName } = getUserInfo(user);
  const uploadedDate = signedDate?.split('T')[0];

  const onChange = () => handleSelect(item);
  const checked = selectedFiles.includes(item);
  return (
    <Row>
      <StyledCheckbox name={title} onChange={onChange} checked={checked} />
      <Cell flex={2} className="list-row-name">
        {title}
      </Cell>
      <Cell flex={2}>
        <StyledAvatar src={avatarUrl} colorKey={user?.id} name={fullName} />
        <UserName>{fullName}</UserName>
      </Cell>
      <Cell flex={3}>{uploadedDate}</Cell>
    </Row>
  );
};

export default HelloSignRow;

import styled from 'styled-components';
import Card from 'components/Card';

export const Container = styled(Card)`
  display: flex;
  height: 560px;
`;

export const LicenceOverview = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${props => props.theme.colors.neutral.grey02};
  width: 460px;
  flex-shrink: 0;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  gap: 4px;
  ${props => props.theme.typography.control};
  font-weight: 400;
  color: ${props => props.theme.colors.neutral.grey07};
  h2 {
    ${props => props.theme.typography.header2};
    font-weight: 600;
    color: ${props => props.theme.colors.neutral.grey10};
    margin: 0;
  }
`;

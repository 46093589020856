import styled, { keyframes } from 'styled-components';
import colors from 'designSystem/foundations/colors';
export const LoadingState = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 29px;
  border-top: 1px solid ${colors.neutral.grey02};
  padding: 24px;
  box-sizing: border-box;
`;
const osculate = keyframes`
0%{
  transform:translateY(0px);
  
}

15%{
transform:translateY(-10px);
}
20%{
transform:translateY(0px);
}
100%{
transform:translateY(0px);
}
`;
export const HoppingCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin: 0 5px;
  background-color: ${colors.neutral.grey05};
  animation: ${osculate} 1.8s ease-in-out infinite;
  animation-delay: ${({ delay }) => delay * 0.2}s;
`;

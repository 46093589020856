import styled from 'styled-components';
import Avatar from 'components/NewLyncworkUIKit/Avatar';

export const Container = styled.div`
  display: flex;
  gap: 16px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  gap: 16px;
  &:last-child {
    padding-left: 24px;
    border-left: 1px solid ${props => props.theme.colors.neutral.grey02};
  }
`;

export const Description = styled.div`
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.neutral.grey08};
  margin: 0 0 8px;
`;

export const AppContainer = styled.div`
  display: flex;
  gap: 8px;
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey07};
`;

export const StyledAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 24px;
  gap: 8px;
  padding: 0 0 24px;

  border-bottom: 1px solid ${props => props.theme.colors.neutral.grey02};

  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey07};
  span {
    font-weight: 600;
    margin: 0 4px 0 0;
  }
`;

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useButtonTrack } from 'matomo/hooks';

import { UserService } from 'services/user.service';

import {
  Container,
  ContentContainer,
  Title,
  Tagline,
  ScoreContainer,
  ScoreNumber,
  ScoreText,
  ButtonContainer,
  CustomButton,
  NonEngagementText
} from './styles';

import canvasConfetti from './canvasConfetti';
import useEngagement from 'hooks/useEngagement';

const EngagementContent = () => {
  return (
    <ContentContainer>
      <Title>Onboarding Complete</Title>
      <Tagline>
        Awesome job! You’ve gained engagement points for completing the
        onboarding process.
      </Tagline>
      <ScoreContainer>
        <ScoreNumber>200</ScoreNumber>
        <ScoreText>POINTS</ScoreText>
      </ScoreContainer>
    </ContentContainer>
  );
};
const NonEngagementContent = () => {
  return (
    <ContentContainer>
      <NonEngagementText>CONGRATULATIONS</NonEngagementText>
      <Title>Onboarding Complete</Title>
      <Tagline>Awesome job! We can't wait to get you started!</Tagline>
    </ContentContainer>
  );
};

const UserWelcomeDone = () => {
  const isEngagementAvailable = useEngagement();
  const trackButton = useButtonTrack();
  const history = useHistory();
  const goBack = () => history.push('/user/welcome/team');
  const goToDashboard = () => {
    trackButton('onboarding complete -> go to dashboard');
    history.push('/dashboard');
  };
  useEffect(() => {
    canvasConfetti.start();
    UserService.completeOnboarding();
    setTimeout(() => {
      canvasConfetti.stop();
    }, 2000);
  }, []);
  return (
    <Container>
      {isEngagementAvailable ? <EngagementContent /> : <NonEngagementContent />}
      <ButtonContainer>
        <CustomButton variant="secondary" onClick={goBack}>
          Back
        </CustomButton>
        <CustomButton onClick={goToDashboard}>Next</CustomButton>
      </ButtonContainer>
    </Container>
  );
};

export default UserWelcomeDone;

import React, { useEffect, useState } from 'react';

import { RoleService } from 'services/role.service';
import {
  Container,
  SidebarContainer,
  ErrorText,
  Title,
  Roles,
  Role
} from './styles';
import LoadingScreen from 'components/LoadingScreen';
import UsersComponent from './Users';
const allRoles = { roleName: 'AllRoles', id: '' };

const AddMember = ({
  closeModal,
  setAddedUsers,
  addedUsers,
  existingUsers
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState();

  const getRoles = async () => {
    setLoading(true);

    try {
      const { data } = await RoleService.listWithUsers('', '0', 50);
      setRoles(data.content.filter(x => x.usersId.length));
      setSelectedRole(allRoles);
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    getRoles();
  }, []);

  if (error)
    return (
      <Container>
        <ErrorText>Something has gone wrong </ErrorText>
      </Container>
    );
  if (loading)
    return (
      <Container>
        <LoadingScreen />
      </Container>
    );
  return (
    <Container>
      <SidebarContainer>
        <Title>Add Users</Title>
        <Roles>
          <Role
            selected={selectedRole.id === allRoles.id}
            onClick={() => {
              setSelectedRole(allRoles);
            }}
          >
            All Roles
          </Role>
          {roles?.map(role => (
            <Role
              key={role.id}
              onClick={() => {
                setSelectedRole(role);
              }}
              selected={selectedRole.id === role.id}
            >
              {role.roleName}
            </Role>
          ))}
        </Roles>
      </SidebarContainer>
      <UsersComponent
        selectedRole={selectedRole}
        setAddedUsers={setAddedUsers}
        addedUsers={addedUsers}
        existingUsers={existingUsers}
        closeModal={closeModal}
      />
    </Container>
  );
};
export default AddMember;

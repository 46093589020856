import styled from 'styled-components';
import { CustomSearch } from 'components/NewLyncworkUIKit';
import { Link } from 'react-router-dom';
import { breakPoints } from 'designSystem/foundations';

const { medium, small } = breakPoints;

export const Container = styled.div`
  max-width: 760px;
  display: flex;
  flex-direction: column;
`;

export const SingleJourneyPageContainer = styled.div`
  display: flex;
  width: 1200px;
  max-width: 100%;
`;

export const ListContainer = styled.div`
  width: 760px;
  max-width: 70%;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: ${medium}) {
    width: 100%;
    max-width: unset;
  }
`;

export const Header = styled.h1`
  ${props => props.theme.typography.title3};
  font-weight: 600;
  color: ${props => props.theme.colors.neutral.grey10};
  margin: 0 0 24px;
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.small}) {
    display: none;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 16px 0;
  gap: 16px;

  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.small}) {
    justify-content: center;
    gap: 24px;
  }
`;

export const JourneysListContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 340px);
  gap: 16px;
  @media only screen and (max-width: ${medium}) {
    display: flex;
    flex-direction: column;
  }
`;

export const FetchStateContainer = styled.div`
  width: 100%;
  margin: 20px;
  display: flex;
  justify-content: center;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  align-self: flex-start;
  @media only screen and (max-width: ${small}) {
    display: none;
  }
`;

export const TasksListTitle = styled.h2`
  ${props => props.theme.typography.header3};
  font-weight: 600;
  margin: 0;
  color: ${props => props.theme.colors.neutral.grey10};
`;

export const SearchBar = styled.div`
  display: flex;
  margin: 8px 0;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: ${small}) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const Search = styled(CustomSearch)`
  @media only screen and (max-width: ${small}) {
    width: 100%;
  }
`;

import styled from 'styled-components';

const breakPoint = '1000px';

export const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 60px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 35vh 0 0 100px;
  @media only screen and (max-width: ${breakPoint}) {
    padding: 0;
    align-items: center;
    justify-content: center;
  }
`;

export const Title = styled.h1`
  margin: 0;
  color: ${props => props.theme.colors.neutral.grey10};
  ${props => props.theme.typography.title1};
  font-weight: 600;

  span {
    color: ${props => props.theme.colors.brand.primary};
  }
  @media only screen and (max-width: ${breakPoint}) {
    max-width: 400px;
    text-align: center;
  }
`;

export const Triangle = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 120%;
  height: 100vmax;
  transform: rotate(-20deg);
  transform-origin: top left;
  background: linear-gradient(265.83deg, white 5%, #ffc5c5 20%, #e94a41 50%);
`;

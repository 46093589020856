import React, { useState } from 'react';
import { format, isSameMonth, isSameDay, setYear } from 'date-fns';
import { CustomButton } from 'components/NewLyncworkUIKit';
import {
  ChevronLeft,
  ChevronRight,
  FirstPage,
  LastPage
} from '@material-ui/icons/';
import {
  Container,
  Header,
  DateTitleContainer,
  Icon,
  MonthDateGrid,
  WeekLabel,
  CalendarCellContainer,
  CalendarCellDate,
  Select,
  PreHeader,
  Month,
  SelectedDate,
  DateDisplay,
  TodayButton
} from './styles';
import {
  getCalendarDays,
  getPickerButtonDisabledStatus,
  isCellDisabled,
  getPickerButtonCallback,
  getYears
} from './utilities';
import { daysOfWeek, prevSVG, nextSVG } from './constants';

const CalendarCell = ({
  date,
  selectedDate,
  onChange,
  disabled,
  showDate = true,
  displayedDate
}) => {
  const today = new Date();
  const current = isSameDay(today, date);
  const dayOfMonth = format(date, 'd');
  const selected = isSameDay(date, selectedDate);
  const otherDay = !isSameMonth(date, displayedDate);
  const renderState = () => {
    if (current && !selected) {
      return 'current';
    }
    if (selected) {
      return 'selected';
    }
    if (otherDay) {
      return 'other';
    }
    return 'normal';
  };
  return (
    <CalendarCellContainer>
      {showDate && (
        <CalendarCellDate
          onClick={() => onChange(date)}
          disabled={disabled}
          renderState={renderState()}
        >
          {dayOfMonth}
        </CalendarCellDate>
      )}
    </CalendarCellContainer>
  );
};

const CalendarPicker = ({ date, onChange, minDate, maxDate }) => {
  const [displayedDate, setDisplayedDate] = useState(date);
  const selectedYear = format(displayedDate, 'yyyy');
  const selectedMonth = format(displayedDate, 'MMM');
  const calendarDays = getCalendarDays(displayedDate);
  const {
    prevMonthDisabled,
    nextMonthDisabled
  } = getPickerButtonDisabledStatus(displayedDate, minDate, maxDate);
  const { goToPreviousMonth, goToNextMonth } = getPickerButtonCallback(
    displayedDate,
    setDisplayedDate
  );
  return (
    <Container tabIndex="0">
      <PreHeader>
        <SelectedDate>
          Selected Date: <br />
          <DateDisplay>{format(date, 'EEEE, MMMM do yyyy')}</DateDisplay>
        </SelectedDate>
        <CustomButton
          size="small"
          variant="secondary"
          onClick={() => setDisplayedDate(new Date())}
        >
          Today
        </CustomButton>
      </PreHeader>
      <Header>
        <DateTitleContainer>
          <Month>{selectedMonth}</Month>
          <Select
            size="small"
            placeholder={selectedYear}
            onChange={e => setDisplayedDate(setYear(displayedDate, e.value))}
            options={getYears(minDate, maxDate)}
            value={{ value: selectedYear, label: selectedYear }}
            isSearchable={false}
          />
        </DateTitleContainer>
        <Icon onClick={goToPreviousMonth} disabled={prevMonthDisabled}>
          <ChevronLeft />
        </Icon>
        <Icon onClick={goToNextMonth} disabled={nextMonthDisabled}>
          <ChevronRight />
        </Icon>
      </Header>
      <MonthDateGrid>
        {daysOfWeek.map(day => (
          <WeekLabel key={day}>{day}</WeekLabel>
        ))}
        {calendarDays.map(day => {
          const dateString = format(day, 'yyyy-MM-dd');
          const disabled = isCellDisabled(day, minDate, maxDate);
          return (
            <CalendarCell
              key={dateString}
              date={day}
              selectedDate={date}
              onChange={onChange}
              disabled={disabled}
              displayedDate={displayedDate}
            />
          );
        })}
      </MonthDateGrid>
    </Container>
  );
};

export default CalendarPicker;

import styled, { css } from 'styled-components';
import { CustomButton } from 'components/NewLyncworkUIKit';
import colors from 'designSystem/foundations/colors';

const boxStyles = css`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 1em;
  background: ${colors.neutral.white};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

export const Title = styled.h1`
  margin: ${({ topMargin }) => (topMargin ? '1em 0' : '0 0 1em 0')};
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  display: flex;
  align-items: center;

  /* Neutral/Grey 10 */

  color: ${colors.neutral.grey10};
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;
export const BackButton = styled(CustomButton)`
  .MuiSvgIcon-root {
    margin-right: 4px;
  }
`;
export const InfoContainer = styled.div`
  margin: 1em 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
`;
export const TeamInfo = styled.div`
  width: 388px;
  margin-right: 1em;
  ${boxStyles}
`;
export const TeamMembers = styled.div`
  flex: 1;
  ${boxStyles}
`;
export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  width: 100%;
`;
export const TrashImg = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;
export const UserDetails = styled.div`
  position: absolute;
  right: -4vw;
  top: -2vw;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 328px;
  height: calc(100% + 4vw);
  background-color: white;
  color: ${colors.neutral.grey05};
  padding: 24px;
  h1 {
    margin-top: 16px;
    margin-bottom: 24px;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */

    /* Neutral/Grey 10 */

    color: ${colors.neutral.grey10};
  }
  svg {
    position: absolute;
    top: 1em;
    right: 1em;
    cursor: pointer;
  }
  h2 {
    margin: 0;
    margin-bottom: 4px;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Neutral/Grey 10 */

    color: ${colors.neutral.grey10};
  }
  p {
    text-align: left;
    margin: 0;
    margin-bottom: 24px;
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    /* Neutral/Grey 07 */

    color: ${colors.neutral.grey07};
  }
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
`;

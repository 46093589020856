export const rolesListPageEvents = {
  add_role: 'admin roles add role',
  edit_role: 'admin roles edit role',
  change_page: 'admin roles change page'
};

export const roleFormEvents = {
  delete_role: 'delete role (pending confirmation)',
  delete_role_confirm: 'delete role confirmed',
  discard_role_change: 'discard role change',
  save_role_change: 'save role change',
  edit_admin_permissions: 'edit role admin permissions',

  change_application_capability: 'edit role application capability',
  remove_application: 'edit role remove application'
};

export const roleFormApplicationModalEvents = {
  open_application_modal: 'role open application modal',
  close_application_modal: 'role close application modal',
  select_app: 'role select/deselect applications',
  change_app_category: 'role application modal change category',
  add_selected_apps: 'role add selected apps'
};

import styled, { css } from 'styled-components';
import { CustomButton } from 'components/NewLyncworkUIKit';
import colors from 'designSystem/foundations/colors';

export const Container = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  gap: 12px;

  align-items: center;
  height: 60px;
  width: 100%;
  background-color: ${colors.neutral.white};
  background-color: ${({ isTransparent }) =>
    isTransparent ? 'transparent' : colors.neutral.white};
  ${({ isAutoLogout }) =>
    !isAutoLogout && 'box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1)'};
  padding: 0 16px;
  box-sizing: border-box;
  z-index: 100;
`;
export const StyledLogo = styled.img`
  margin-right: 8px;
`;

const showLogoCSS = css`
  display: flex;
  position: absolute;
  top: 21px;
  left: 100px;
`;

const mobileLogoDisplay = ({ isAutoLogout, isDashboard }) => {
  const showLogo = isAutoLogout || isDashboard;
  return showLogo ? 'flex' : 'none';
};
export const Title = styled.div`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  display: none;
  align-items: center;

  color: ${colors.brand.primary};
  ${props => props.isAutoLogout && showLogoCSS};
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.small}) {
    display: ${mobileLogoDisplay};
    top: unset;
    left: unset;
    position: relative;
  }
`;

export const ToolBar = styled.div`
  display: flex;
  margin: 0 0 0 auto;
  align-items: center;
  gap: 12px;
`;

export const BackButton = styled(CustomButton)`
  margin-right: auto;
  .MuiSvgIcon-root {
    font-size: inherit;
    margin-right: 4px;
  }
`;
BackButton.defaultProps = { variant: 'secondary', size: 'small' };

export const PageTitle = styled.h1`
  ${props => props.theme.typography.header2};
  font-weight: 600;
  color: ${colors.neutral.grey10};
  display: none;
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.small}) {
    display: unset;
  }
`;

import React, { useState } from 'react';

import { useButtonTrack } from 'matomo/hooks';
import TeamMemberCard from './TeamMemberCard';
import {
  Container,
  Title,
  TeamContainer,
  CustomButton,
  CountText
} from './styles';

const membersPerPage = 6;

const WhoSaidWhat = ({ user, teamMembers }) => {
  const trackButton = useButtonTrack();
  const [page, setPage] = useState(1);
  const [flippedIds, setFlippedIds] = useState([]);
  const teamMemberCount = teamMembers.length;
  const hasMore = page * membersPerPage < teamMemberCount;
  const currentCount = Math.min(page * membersPerPage, teamMemberCount);

  const toggleFlip = id => {
    trackButton('onboarding team member card flipped');
    setFlippedIds(oldIds => {
      if (oldIds.includes(id)) {
        return oldIds.filter(currentId => currentId !== id);
      }
      return [...oldIds, id];
    });
  };

  return (
    <Container>
      <Title>Check out who said what!</Title>
      <TeamContainer>
        {teamMembers.map((user, index) => {
          if (index >= page * membersPerPage) {
            return null;
          }
          const flip = flippedIds.includes(user.id);
          return (
            <TeamMemberCard
              user={user}
              key={user.id}
              toggleFlip={toggleFlip}
              flip={flip}
            />
          );
        })}
      </TeamContainer>
      <CountText>
        Showing {currentCount} of {teamMemberCount}
      </CountText>

      {hasMore && (
        <CustomButton onClick={() => setPage(a => a + 1)} type="button">
          Show More
        </CustomButton>
      )}
    </Container>
  );
};

export default WhoSaidWhat;

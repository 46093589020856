import { LocaleService } from 'services';

const keys = [
  'companyName',
  'phonePrimary',
  'website',
  'instagramUrl',
  'linkedinUrl',
  'mediumUrl',
  'twitterUrl'
];
const addressKeys = [
  'lineOne',
  'lineTwo',
  'city',
  'state',
  'postalCode',
  'country'
];

export const formatCompanyInfo = companyInfo => {
  const {
    companyName,
    phonePrimary,
    website,
    instagramUrl,
    linkedinUrl,
    mediumUrl,
    twitterUrl,
    addresses
  } = companyInfo;
  const firstAddress = addresses?.[0] || {};
  const {
    lineOne,
    lineTwo,
    city,
    state,
    postalCode,
    country,
    id
  } = firstAddress;

  const data = {};
  keys.forEach(key => {
    data[key] = companyInfo[key] || '';
  });
  addressKeys.forEach(key => {
    data[key] = firstAddress[key] || '';
  });

  return data;
};

const requiredKeys = [
  'companyName',
  'phonePrimary',
  'website',
  'lineOne',
  'country',
  'city',
  'state',
  'postalCode'
];

const labels = {
  companyName: 'Name',
  phonePrimary: 'Phone',
  website: 'Website',
  lineOne: 'Line One',
  lineTwo: 'Line Two',
  city: 'City',
  state: 'State',
  country: 'Country',
  postalCode: 'Postal/Zip Code',
  instagramUrl: 'Instagram',
  linkedinUrl: 'LinkedIn',
  mediumUrl: 'Medium',
  twitterUrl: 'Twitter'
};

export const getOtherInputProps = key => {
  const required = requiredKeys.includes(key);
  const label = labels[key];
  const fullWidth = key === 'lineOne' || key === 'lineTwo';
  return { required, label, fullWidth };
};

export const countryOptions = LocaleService.countries().map(name => ({
  label: name,
  value: name
}));

export const getStateOptions = country => {
  const stateList = LocaleService.states(country) || [];
  const stateOptions = stateList.map(state => ({ label: state, value: state }));
  return stateOptions;
};

export const validate = tempInfo => {
  const country = tempInfo.country;
  const stateOptions = getStateOptions(country);
  const unfilledKeys = requiredKeys.filter(key => { 
    if(key !== 'state' || stateOptions.length > 0){
      return !tempInfo[key];
    }
    return false;
  });
  const errors = unfilledKeys.reduce((prev, key) => {
    const message = `${labels[key]} is required`;
    return { ...prev, [key]: message };
  }, {});
  const hasError = unfilledKeys.length > 0;
  return { errors, hasError };
};

export const formatWebsite = url => {
  const removeHttps = url.replace('https://', '').replace('http://');
  return `https://${removeHttps}`;
};

export const formatTempInfo = (tempInfo, companyInfo) => {
  const newCompanyInfo = { ...companyInfo };
  keys.forEach(key => {
    const newValue = tempInfo[key];
    if (key === 'website') {
      newCompanyInfo[key] = formatWebsite(newValue);
      return;
    }
    newCompanyInfo[key] = newValue;
  });
  const newAddress = addressKeys.reduce((prev, key) => {
    prev[key] = tempInfo[key];
    return prev;
  }, {});

  const addressId = companyInfo?.addresses?.[0]?.id;
  if (addressId) {
    newAddress.id = addressId;
  }
  newCompanyInfo.addresses = [newAddress];
  return newCompanyInfo;
};

import styled from 'styled-components';
import colors from 'designSystem/foundations/colors';
const mobileBreakpoint = '900px';

export const SideBarMobileProgress = styled.div`
  display: none;
  @media only screen and (max-width: ${mobileBreakpoint}) {
    background-color: ${props => props.theme.colors.neutral.white};
    border-bottom: 1px solid ${props => props.theme.colors.neutral.grey04};
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    width: 100vw;
    background-color: white;
    z-index: 2;
    box-sizing: border-box;
    padding: 20px;
    transform: translateY(${props => (!props.isOpen ? '-100%' : '0')});
    transition: transform 0.3s ease-in-out;
  }
`;

export const Header = styled.h1`
  margin: 0 0 16px;
  ${props => props.theme.typography.header1};
  font-weight: 600;
`;

const {
  brand: { primary },
  neutral: { grey06, white }
} = colors;

export const StepContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  ${props => props.theme.typography.body};
  font-weight: 600;
  color: ${props => (props.reached ? primary : grey06)};
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    opacity: ${props => (props.isFirstStep ? 0 : 1)};

    right: 7.5px;
    width: 1px;
    height: 100%;
    background: ${props => (props.reached ? primary : grey06)};
    transform: translate(-0.5px, -50%);
  }
`;

export const GreyCircle = styled.div`
  position: relative;
  width: 8px;
  height: 8px;
  margin-right: 4px;
  border-radius: 50%;
  background-color: ${grey06};
`;
export const ReachedCircle = styled.div`
  position: relative;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${primary};
  background-color: ${props => (props.done ? primary : white)};
  img {
    width: 12px;
    height: 12px;
    object-fit: contain;
  }
`;

import React, { useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import LoadingDots from 'components/NewLyncworkUIKit/LoadingDots';
import { Container, ListContainer, SearchInput } from './styles';
const InfiniteScrollList = ({
  children,
  hasMore,
  loadMore,
  searchQuery,
  placeholder,
  handleInputChange
}) => {
  const scrollRefParent = useRef();

  const getScrollParent = () => scrollRefParent.current;
  return (
    <Container>
      <SearchInput
        value={searchQuery}
        onChange={handleInputChange}
        placeholder={placeholder}
      />
      <ListContainer ref={scrollRefParent}>
        <InfiniteScroll
          loadMore={loadMore}
          hasMore={hasMore}
          pageStart={0}
          useWindow={false}
          getScrollParent={getScrollParent}
          loader={<LoadingDots key="loader" />}
        >
          {children}
        </InfiniteScroll>
      </ListContainer>
    </Container>
  );
};

export default InfiniteScrollList;

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Phone, LocationOn, Language, Edit } from '@material-ui/icons';

import { IconButton } from 'components/NewLyncworkUIKit';
import { useModal } from 'hooks';
import { useButtonTrack } from 'matomo/hooks';
import { companyBasicInfoEvents } from 'pages/CompanyInfo/instrumentationEvents';
import { formatAddress, formatUrl } from 'utilities';
import { CMSService } from 'services';

import { instagramIcon, linkedinIcon, mediumIcon, twitterIcon } from './assets';
import {
  Container,
  Banner,
  InfoContainer,
  CompanyName,
  DetailContainer,
  SocialMediaLinkContainer,
  StyledIconButton
} from './styles';
import AvatarUpload from './AvatarUpload';
import BannerUpload from './BannerUpload';
import CompanyInfoEditModal from './CompanyInfoEditModal';

const SocialMediaLinks = ({ companyInfo }) => {
  const { twitterUrl, mediumUrl, linkedinUrl, instagramUrl } = companyInfo;
  return (
    <SocialMediaLinkContainer>
      {instagramUrl && (
        <a href={formatUrl(instagramUrl)}>
          <img alt="twitter" src={instagramIcon} />
        </a>
      )}
      {linkedinUrl && (
        <a href={formatUrl(linkedinUrl)}>
          <img alt="twitter" src={linkedinIcon} />
        </a>
      )}
      {mediumUrl && (
        <a href={formatUrl(mediumUrl)}>
          <img alt="twitter" src={mediumIcon} />
        </a>
      )}
      {twitterUrl && (
        <a href={formatUrl(twitterUrl)}>
          <img alt="twitter" src={twitterIcon} />
        </a>
      )}
    </SocialMediaLinkContainer>
  );
};

const { open_edit_modal, close_edit_modal } = companyBasicInfoEvents;
const CompanyBasicInfo = ({ canEdit, companyInfo, loading, updateCompany }) => {
  const trackButton = useButtonTrack();
  const { openModal, isOpen, closeModal } = useModal();

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  const {
    companyName,
    addresses,
    phonePrimary,
    domain,
    companyLogo,
    id,
    website,
    companyBanner
  } = companyInfo;
  const address = addresses?.[0];
  const addressText = formatAddress(address);

  const updateCompanyMedia = async (file, name) => {
    try {
      const logoResponse = await CMSService.uploadMedia(file);
      const newLogo = logoResponse.data[0];
      const newCompanyInfo = { ...companyInfo, [name]: newLogo };
      await updateCompany(newCompanyInfo);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = () => {
    trackButton(open_edit_modal);
    openModal();
  };

  const handleModalClose = () => {
    trackButton(close_edit_modal);
    closeModal();
  };

  return (
    <>
      <CompanyInfoEditModal
        isOpen={isOpen}
        closeModal={handleModalClose}
        companyInfo={companyInfo}
        updateCompany={updateCompany}
      />
      <Container>
        <AvatarUpload
          updateCompanyMedia={updateCompanyMedia}
          companyInfo={companyInfo}
          id={id}
          canEdit={canEdit}
        />
        <BannerUpload
          companyBanner={companyBanner}
          updateCompanyMedia={updateCompanyMedia}
          canEdit={canEdit}
        />
        <InfoContainer>
          <CompanyName>{companyName}</CompanyName>
          {canEdit && (
            <StyledIconButton onClick={handleEdit}>
              <Edit />
            </StyledIconButton>
          )}
          <DetailContainer>
            <LocationOn />
            <strong>Address: </strong>
            <span>{addressText}</span>
          </DetailContainer>
          <DetailContainer>
            <Phone />
            <strong>Phone: </strong>
            <span>{phonePrimary}</span>
          </DetailContainer>
          <DetailContainer>
            <Language />
            <strong>Website: </strong>
            <a href={website}>
              <span>{website}</span>
            </a>
            <SocialMediaLinks companyInfo={companyInfo} />
          </DetailContainer>
        </InfoContainer>
      </Container>
    </>
  );
};

export default CompanyBasicInfo;

import React, { useCallback, useEffect, useState } from 'react';
import {
  Drop,
  Hints,
  Img,
  Preview,
  DeleteContainer,
  PreviewContainer,
  RelativeContainer,
  PromptContainer,
  Title
} from './styles.js';
import { useDropzone } from 'react-dropzone';
import UploadImg from './upload.svg';
import { CMSService } from 'services/cms.service.js';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';

const Thumbnail = ({ thumbnail, addThumbnail, removeThumbnail }) => {
  const [imageLoading, setImageLoading] = useState(false);

  const onDrop = useCallback(acceptedFiles => {
    console.log({ getRootProps });
    if (!acceptedFiles.length) {
      return;
    }
    const file = acceptedFiles[0];
    const img = {
      url: URL.createObjectURL(file),
      file
    };
    addThumbnail(img);
  }, []);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/x-png,image/gif,image/jpeg,image/png'
    // minSize: 0.01
    // maxFiles: 1,
    // init: function() {
    //   this.on('maxfilesexceeded', function(file) {
    //     this.removeAllFiles();
    //     this.addFile(file);
    //   });
    // }
  });

  if (thumbnail?.url && !imageLoading) {
    return (
      <>
        <Title>Thumbnail</Title>
        <PreviewContainer>
          <Preview src={thumbnail.url} />

          <DeleteContainer onClick={() => removeThumbnail(thumbnail?.id)}>
            <CloseIcon color="primary" size={20} />
          </DeleteContainer>
        </PreviewContainer>
      </>
    );
  } else {
    return (
      <>
        <Title>Thumbnail</Title>
        <RelativeContainer>
          <Drop {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />{' '}
            <Hints>
              {imageLoading ? (
                <CircularProgress size="30px" color="primary" />
              ) : (
                <PromptContainer>
                  <Img src={UploadImg} />
                  Drag and drop, or click to upload
                </PromptContainer>
              )}
            </Hints>
          </Drop>
        </RelativeContainer>
      </>
    );
  }
};
export default Thumbnail;

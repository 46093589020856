import React, { useEffect, useState } from 'react';

import { CMSService } from 'services/cms.service';
import LoadingScreen from 'components/LoadingScreen';

import { Container, ButtonContainer, CustomButton, Title } from './styles';
import CoreAppsSetup from './CoreAppsSetup';
import RecommendedApps from './RecommendedApps';

const NonCoreApps = ({ coreApp, recommendedApps }) => {
  if (recommendedApps.length > 0) {
    return <RecommendedApps recommendedApps={recommendedApps} />;
  }

  if (!coreApp) {
    <Title>No Applications Added</Title>;
  }
  return null;
};

const UserWelcomeApplications = ({ prevPage, nextPage }) => {
  const [coreApp, setCoreApp] = useState(null);
  const [recommendedApps, setRecommendedApps] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [fetchState, setFetchState] = useState({
    loading: true,
    hasError: false
  });
  const { loading, hasError } = fetchState;
  useEffect(() => {
    const fetchCoreApp = async () => {
      try {
        const res = await CMSService.accountEmailProvider();
        setCoreApp(res.data);
        return res.data;
      } catch (error) {
        console.log(error);
        return null;
      }
    };

    const fetchData = async () => {
      try {
        const coreApp = await fetchCoreApp();
        const allAppsResponse = await CMSService.applications();
        const coreAppName = coreApp?.applicationName;
        const filteredApps = allAppsResponse.data.filter(({ appAPIName }) => {
          switch (appAPIName) {
            case 'google':
            case 'office365':
              return false;
            default:
              return true;
          }
        });
        setRecommendedApps(filteredApps);
        setFetchState({ loading: false, hasError: false });
      } catch (error) {
        console.log(error);
        setFetchState({ loading: false, hasError: true });
      }
    };

    fetchData();
  }, []);

  const handleNextButtonClick = async () => {
    try {
      const response = await CMSService.firstLogin();
      const hasLoggedIn = response.data;
      if (!hasLoggedIn) {
        setErrorMessage('Please log into your email before proceeding');
        return;
      }
      nextPage();
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return <LoadingScreen />;
  }
  if (hasError) {
    return (
      <Container>
        <Title>No Applications Added</Title>
        <ButtonContainer>
          <CustomButton variant="secondary" onClick={prevPage}>
            Back
          </CustomButton>
          <CustomButton onClick={nextPage}>Next</CustomButton>
        </ButtonContainer>
      </Container>
    );
  }
  return (
    <Container>
      <CoreAppsSetup coreApp={coreApp} errorMessage={errorMessage} />
      <NonCoreApps coreApp={coreApp} recommendedApps={recommendedApps} />
      <ButtonContainer>
        <CustomButton variant="secondary" onClick={prevPage}>
          Back
        </CustomButton>
        <CustomButton onClick={handleNextButtonClick}>Next</CustomButton>
      </ButtonContainer>
    </Container>
  );
};

export default UserWelcomeApplications;

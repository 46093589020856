import React, { useState } from 'react';
import { parse } from 'papaparse';

import { useButtonTrack } from 'matomo/hooks';
import { userFileImportPageEvents } from 'pages/Users/UsersListPage/instrumentationEvents';

import useImportSteps from './useImportSteps';
import {
  Progress,
  FileUpload,
  MapData,
  Review,
  ImportComplete
} from './components';
import { PageContainer, Header, Container } from './styles';
import { convertCSVToUserArray } from './utilities';

const { add_file, remove_file } = userFileImportPageEvents;

const steps = ['File Upload', 'Map Data', 'Review', 'Import'];

const ImportFileContent = ({
  completedStepCount,
  file,
  handleFileRemove,
  onDrop,
  nextPage,
  previousPage,
  csvData,
  setCSVData,
  userArray
}) => {
  switch (completedStepCount) {
    case 0:
      return (
        <FileUpload
          file={file}
          onDrop={onDrop}
          handleFileRemove={handleFileRemove}
          nextPage={nextPage}
          previousPage={previousPage}
        />
      );
    case 1:
      return (
        <MapData
          csvData={csvData}
          setCSVData={setCSVData}
          nextPage={nextPage}
          previousPage={previousPage}
        />
      );
    case 2:
      return (
        <Review
          nextPage={nextPage}
          previousPage={previousPage}
          userArray={userArray}
        />
      );
    case 3:
      return <ImportComplete userArray={userArray} nextPage={nextPage} />;
    default:
      return null;
  }
};

const UsersImportFilePage = () => {
  const trackButton = useButtonTrack();
  const { previousPage, nextPage, completedStepCount } = useImportSteps(steps);
  const [file, setFile] = useState(null);
  const [csvData, setCSVData] = useState(null);

  const onDrop = async files => {
    const fileUploaded = files?.[0];
    if (!fileUploaded) {
      return;
    }
    trackButton(add_file);
    const complete = result => {
      setFile(fileUploaded);
      const { data = [] } = result;
      const [header = [], ...users] = data;
      const defaultHeader = header.map(a => null);
      setCSVData({ header: defaultHeader, users, selected: [] });
    };
    const parseConfig = { complete, skipEmptyLines: true };
    parse(fileUploaded, parseConfig);
  };

  const handleFileRemove = () => {
    setFile(null);
    setCSVData(null);
    trackButton(remove_file);
  };
  const userArray = convertCSVToUserArray(csvData);
  return (
    <PageContainer>
      <Header>Import Users</Header>
      <Progress completedStepCount={completedStepCount} steps={steps} />
      <ImportFileContent
        completedStepCount={completedStepCount}
        file={file}
        onDrop={onDrop}
        handleFileRemove={handleFileRemove}
        csvData={csvData}
        setCSVData={setCSVData}
        nextPage={nextPage}
        previousPage={previousPage}
        userArray={userArray}
      />
    </PageContainer>
  );
};

export default UsersImportFilePage;

import React from 'react';

import Avatar from 'components/NewLyncworkUIKit/Avatar';
import generateMediaUrl from 'utilities/generateMediaUrl';

import {
  AppInfoContainer,
  GeneralInfo,
  AppDetailContainer,
  CapabilityContainer
} from './styles';

const Capability = ({ capability }) => {
  const { logo, capability: capabilityName, id } = capability;
  const name = capabilityName.replaceAll('_', ' ');
  const src = generateMediaUrl(logo);

  return (
    <CapabilityContainer>
      <Avatar src={src} colorKey={id} name={name} className="avatar" /> {name}
    </CapabilityContainer>
  );
};

const AppInfo = ({ app }) => {
  const { id, appLogo, appName, appDesc, applicationCapabilities } = app;
  const src = generateMediaUrl(appLogo);
  return (
    <AppInfoContainer>
      <GeneralInfo>
        <Avatar src={src} name={appName} colorKey={id} className="avatar" />
        {appName}
      </GeneralInfo>
      <AppDetailContainer>
        <h2>Description</h2>
        <p>{appDesc}</p>
      </AppDetailContainer>
      <AppDetailContainer>
        <h2>Services</h2>
        {applicationCapabilities.map(capability => (
          <Capability capability={capability} key={capability.id} />
        ))}
      </AppDetailContainer>
    </AppInfoContainer>
  );
};

export default AppInfo;

import styled from 'styled-components';

export const PageTitle = styled.h1`
  ${props => props.theme.typography.title3};
  font-weight: 600;
  color: ${props => props.theme.colors.neutral.grey10};
  margin: 0 0 24px;
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.small}) {
    display: none;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  max-width: 1000px;
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.medium}) {
    flex-direction: column-reverse;
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

import {
  format,
  startOfDay,
  endOfDay,
  startOfMonth,
  startOfWeek,
  endOfMonth,
  endOfWeek,
  eachDayOfInterval,
  isWithinInterval,
  addYears,
  addMonths,
  isBefore,
  getYear
} from 'date-fns';
import { monthOptions } from './constants';

export const getCalendarDays = date => {
  const start = startOfWeek(startOfMonth(date));
  const end = endOfWeek(endOfMonth(date));
  const days = eachDayOfInterval({ start, end });
  return days;
};

export const getPickerButtonDisabledStatus = (date, minDate, maxDate) => {
  const start = minDate || addYears(date, -10);
  const end = maxDate || addYears(date, 10);

  if (isBefore(end, start)) {
    return {
      prevYearDisabled: true,
      prevMonthDisabled: true,
      nextMonthDisabled: true,
      nextYearDisabled: true
    };
  }

  const endOfPrevMonth = endOfDay(endOfMonth(addMonths(date, -1)));
  const endOfPrevYear = endOfDay(endOfMonth(addYears(date, -1)));
  const startOfNextMonth = startOfDay(startOfMonth(addMonths(date, 1)));
  const startOfNextYear = startOfDay(startOfMonth(addYears(date, 1)));
  const dateInterval = { start, end };
  return {
    prevYearDisabled: !isWithinInterval(endOfPrevYear, dateInterval),
    prevMonthDisabled: !isWithinInterval(endOfPrevMonth, dateInterval),
    nextMonthDisabled: !isWithinInterval(startOfNextMonth, dateInterval),
    nextYearDisabled: !isWithinInterval(startOfNextYear, dateInterval)
  };
};

export const isCellDisabled = (date, minDate, maxDate) => {
  const start = startOfDay(minDate || date);
  const end = endOfDay(maxDate || date);
  if (isBefore(end, start)) {
    return true;
  }
  return !isWithinInterval(date, { start, end });
};

export const getPickerButtonCallback = (displayedDate, setDisplayedDate) => {
  const prevYear = addYears(displayedDate, -1);
  const prevMonth = addMonths(displayedDate, -1);
  const nextMonth = addMonths(displayedDate, 1);
  const nextYear = addYears(displayedDate, 1);
  return {
    goToPreviousYear: () => setDisplayedDate(prevYear),
    goToPreviousMonth: () => setDisplayedDate(prevMonth),
    goToNextMonth: () => setDisplayedDate(nextMonth),
    goToNextYear: () => setDisplayedDate(nextYear)
  };
};

export const getYears = (minDate, maxDate) => {
  const minYear = getYear(minDate);
  const maxYear = getYear(maxDate);
  const years = Array(maxYear - minYear)
    .fill()
    .map((_, idx) => ({ value: minYear + idx, label: minYear + idx }));
  return years;
};

export const getMonths = year => {
  const currentDate = new Date();
  const removeMonths = year === currentDate.getFullYear().toString();
  if (removeMonths) {
    return monthOptions.slice(currentDate.getMonth());
  }
  return monthOptions;
};

import React from 'react';

import generateMediaUrl from 'utilities/generateMediaUrl';
import HoverInfo from 'components/HoverInfo';

import BackButton from 'components/BackButton';
import {
  CustomButton,
  IconButton as CloseButton,
  Avatar
} from 'components/NewLyncworkUIKit';

import {
  Toolbar,
  ContentContainer,
  CapabilityContainer,
  ButtonContainer
} from './styles';

const title = (
  <span>
    Applications and Licenses can be managed from the{' '}
    <strong>Applications</strong> page
  </span>
);

const CapabilityCard = ({ capability, selected, toggleCapability }) => {
  const { logo, id, capability: name } = capability;
  const src = generateMediaUrl(logo);
  const onClick = () => toggleCapability(capability);
  return (
    <CapabilityContainer onClick={onClick} selected={selected}>
      <Avatar
        src={src}
        name={name}
        colorKey={id}
        className="capability-avatar"
      />
      <span className="capability-name">{name}</span>
    </CapabilityContainer>
  );
};

const SubscriptionCapabilities = ({
  hasLicenseInfo,
  applicationCapabilities,
  selectedCapabilities,
  toggleCapability,
  handleNext
}) => {
  const selectedIds = selectedCapabilities.map(({ id }) => id);
  const canProceed = selectedCapabilities.length > 0;
  const saveButtonText = hasLicenseInfo ? 'Next' : 'Add Application';
  return (
    <>
      <ContentContainer>
        <h2>
          Select Products
          <HoverInfo title={title} />
        </h2>
        {applicationCapabilities.map(capability => {
          const selected = selectedIds.includes(capability.id);
          return (
            <CapabilityCard
              key={capability.id}
              capability={capability}
              selected={selected}
              toggleCapability={toggleCapability}
            />
          );
        })}
      </ContentContainer>
      <ButtonContainer>
        <CustomButton
          size="medium"
          className="save-button"
          disabled={!canProceed}
          onClick={handleNext}
        >
          {saveButtonText}
        </CustomButton>
      </ButtonContainer>
    </>
  );
};

export default SubscriptionCapabilities;

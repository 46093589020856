import styled from 'styled-components';
import { colors } from 'designSystem/foundations';

import { underlineCSS } from 'components/UnderlineLink';

const {
  neutral: { grey02, grey05, grey10 }
} = colors;

export const Container = styled.div`
  position: sticky;
  top: 0;
  background: white;
  display: flex;
  padding: 16px 24px;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.grey02};
  gap: 24px;
  width: 100%;
  box-sizing: border-box;
`;

export const Tab = styled.button`
  padding: 0 0 6px;
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  text-align: center;
  ${props => props.theme.typography.body};
  font-weight: 600;
  color: ${props => (props.selected ? grey10 : grey05)};
  &:after {
    ${props => props.selected && underlineCSS}
  }

  &:hover {
    color: ${grey10};
    :after {
      ${underlineCSS}
    }
  }
`;

Tab.defaultProps = { role: 'tab' };

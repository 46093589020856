import axios from 'axios';
import env from '../env';

const baseUrl = `${env.storeDomain}/api/tasks`;

export const TaskService = {
  create: data => {
    return axios.post(`${baseUrl}`, data);
  },

  update: data => {
    return axios.put(`${baseUrl}`, data);
  },

  find: id => {
    return axios.get(`${baseUrl}/${id}`);
  },

  list: (name = '', page = '0', size = '10') => {
    return axios.get(`${baseUrl}?name=${name}&page=${page}&size=${size}`);
  },
  delete: id => {
    return axios.delete(`${baseUrl}/${id}`);
  }
};

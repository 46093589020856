import styled from 'styled-components';

const singleWidth = 280;

export const Column = styled.div`
  width: ${props => singleWidth * props.numberOfWeeks}px;
  min-width: ${props => singleWidth * props.numberOfWeeks}px;
`;
Column.defaultProps = {
  numberOfWeeks: 8
};

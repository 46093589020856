import styled from 'styled-components';

import { CustomSearch, AsyncSelect } from 'components/NewLyncworkUIKit';

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  min-height: 40px;
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.small}) {
    justify-content: center;
    flex-basis: 100%;
    min-height: unset;
  }
`;
export const SearchBarContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 8px 0 18px;
  min-height: 76px;
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.small}) {
    width: 100%;
    flex-wrap: wrap;
    gap: 12px;
  }
`;

export const Select = styled(AsyncSelect)`
  width: 200px;
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.small}) {
    flex-grow: 1;
  }
`;
export const SearchInput = styled(CustomSearch)`
  margin: 0 auto 0 0;
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.small}) {
    width: 100%;
  }
`;

export const DropdownContainer = styled.div`
  margin: 8px 0 0;
  display: flex;
  gap: 16px;
  width: 100%;
`;

import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 320px;
  grid-auto-rows: auto;
  grid-gap: 16px;
  width: 1600px;
  max-width: 100%;

  & > div {
    :nth-child(1) {
      max-height: 340px;
    }
    :nth-child(2) {
      max-height: 340px;
    }
  }
  @media only screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
    & > div {
      :nth-child(1) {
        order: 1;
      }
      :nth-child(3) {
        order: 2;
      }
      :nth-child(2) {
        order: 3;
      }
      :nth-child(4) {
        order: 4;
      }
    }
  }
`;

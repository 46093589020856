export const getRoleSelection = (
  ahaProducts,
  subscriptionAppCapabilityProperties
) => {
  const roleSelection = {};
  ahaProducts.forEach(product => {
    const productId = String(product.id);
    const roleInfo = subscriptionAppCapabilityProperties.find(
      property => property.value === productId
    );
    if (roleInfo) {
      const role = roleInfo.applicationCapabilityProperty.applicationCapability;
      roleSelection[productId] = role;
    }
  });
  return roleSelection;
};

export const closeMenuOnScroll = e => {
  const { className: targetClassName = '' } = e.target;
  const isSelect = targetClassName.includes('lw-select');
  if (isSelect) {
    e.stopPropagation();
    return false;
  }
  return true;
};

export const getOptionLabel = option => option.capability.replace('_', ' ');

export const formatRoleSelection = roleSelection => {
  const subAppCapabilities = Object.entries(roleSelection).map(
    ([productId, applicationCapability]) => {
      const subCapability = {
        key: 'Product',
        value: productId,
        applicationCapabilityProperty: {
          name: 'Product',
          applicationCapability
        }
      };
      return subCapability;
    }
  );
  return subAppCapabilities;
};

import React from 'react';
import {
  SectionContainer,
  Description,
  SectionTitle,
  OptionContainer
} from './styles';

const Option = ({ recommended = false, title, description, name, onClick }) => {
  return (
    <OptionContainer name={name} onClick={onClick}>
      <span className="option-title">{title}</span>
      {recommended && <span className="option-recommended">Recommended</span>}
      <span className="option-description">{description}</span>
    </OptionContainer>
  );
};

const DeprovisionMenu = ({ setCurrentScreen, fullName }) => {
  const navigate = e => setCurrentScreen(e.currentTarget.name);
  return (
    <>
      <SectionContainer className="bottom-divider">
        <Description>
          Deleting {fullName} will permanently remove the user from Lyncwork.
          All user profile data will be deleted.
        </Description>
      </SectionContainer>
      <SectionContainer>
        <SectionTitle>To proceed, please select an option:</SectionTitle>
        <Option
          name="monitor"
          onClick={navigate}
          title="Monitored Deprovision"
          description="Revokes user access, but keeps associated accounts available for monitoring"
          recommended
        />
        <Option
          name="immediate"
          onClick={navigate}
          title="Immediate Deprovision"
          description="Terminate all associated accounts immediately "
        />
      </SectionContainer>
    </>
  );
};

export default DeprovisionMenu;

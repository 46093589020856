import styled, { css } from 'styled-components';
import {
  multilineEllipsis,
  scrollBar
} from 'StyleVariables/StandardComponents';

export const Container = styled.div`
  position: relative;
  padding: 48px;
  background-color: ${props => props.theme.colors.neutral.white};
  height: 500px;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

export const ImportImage = styled.img`
  position: absolute;
  right: 48px;
  bottom: 0;
`;

export const Title = styled.h2`
  color: ${props => props.theme.colors.neutral.grey10};
  ${props => props.theme.typography.header2};
  font-weight: 600;
  margin: 0;
`;

export const Paragraph = styled.p`
  max-width: 600px;
  margin: 16px 0;
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey07};

  strong {
    color: ${props => props.theme.colors.feedback.error};
  }
`;

export const ImportCount = styled(Paragraph)`
  color: ${props => props.theme.colors.feedback.error};
`;

export const TableContainer = styled.div`
  width: 100%;
  min-height: 200px;
  overflow: scroll;
  ${scrollBar}
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  display: flex;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  margin: 32px 0 0 0;
  display: flex;
  justify-content: flex-end;
  button {
    margin: 0 0 0 16px;
  }
`;

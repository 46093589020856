import React from 'react';

import generateMediaUrl from 'utilities/generateMediaUrl';
import Avatar from 'components/NewLyncworkUIKit/Avatar';
import {
  TableContainer,
  TableCell,
  AppContainer,
  AppListContainer
} from './styles';

const UsersTable = ({ users }) => {
  if (users.length < 1) {
    return null;
  }

  return (
    <>
      <p>The following users will be affected:</p>
      <TableContainer>
        {users.map(user => {
          const { firstName, lastName, id } = user;
          const fullName = `${firstName} ${lastName}`;
          return <TableCell key={id}>{fullName}</TableCell>;
        })}
      </TableContainer>
    </>
  );
};

const AppInfo = ({ app }) => {
  const { appName, appLogo, id } = app;
  const src = generateMediaUrl(appLogo);
  return (
    <AppContainer>
      <Avatar name={appName} src={src} colorKey={id} objectFit="contain" />
      <span>{appName}</span>
    </AppContainer>
  );
};

const AppMessage = ({ applications, message }) => {
  if (applications.length < 1) {
    return null;
  }
  return (
    <>
      <p>{message}</p>
      <AppListContainer>
        {applications.map(app => (
          <AppInfo key={app.id} app={app} />
        ))}
      </AppListContainer>
    </>
  );
};

const saveMessage =
  'You have removed the following applications, users assigned to this role might have those accounts deleted.';
const deleteMessage =
  'users assigned to this role might have those accounts for the following applications deleted.';

export const SavePreflight = ({ applications, users }) => {
  return (
    <>
      <AppMessage applications={applications} message={saveMessage} />
      <UsersTable users={users} />
    </>
  );
};
export const DeletePreflight = ({ applications, users }) => {
  return (
    <>
      <p>
        Doing so will remove all data associated with this role. This is{' '}
        <strong>PERMANENT</strong> and <strong>IRREVERSIBLE</strong>
      </p>
      {users.length > 0 && (
        <AppMessage applications={applications} message={deleteMessage} />
      )}
      <UsersTable users={users} />
    </>
  );
};

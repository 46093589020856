import { useEffect, useState } from 'react';

import { UserService } from 'services/user.service';

const useUser = id => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getUserInfo = async userId => {
      try {
        setLoading(true);
        const { data: newUserInfo } = await UserService.find(userId);
        const { data: applications } = await UserService.getApplications(
          userId
        );
        setUserData({ ...newUserInfo, applications });
        setLoading(false);
      } catch (error) {
        console.log({ error });
        const newErrorMessage =
          error.response?.data?.message || 'oops, something went wrong';
        setError(newErrorMessage);
      }
    };
    getUserInfo(id);
  }, [id]);

  return { loading, userData, error, setUserData };
};

export default useUser;

import styled, { css } from 'styled-components';

const regularColor = css`
  color: ${props => props.theme.colors.neutral.grey07};
`;
const errorColor = css`
  color: ${props => props.theme.colors.feedback.error};
`;

export const Label = styled.label`
  display: flex;
  gap: 4px;
  text-align: left;
  ${props => props.theme.typography.control};
  font-weight: 500;
  color: ${props => props.theme.colors.neutral.grey10};
  margin: ${props => props.margin};
  span {
    color: ${props => props.theme.colors.feedback.error};
  }
`;
Label.defaultProps = { margin: '8px 0' };

export const SubLabel = styled.p`
  text-align: left;
  ${props => props.theme.typography.metadata};
  ${props => (props.error ? errorColor : regularColor)};
  margin: ${props => props.margin};
`;

SubLabel.defaultProps = { margin: '8px 0', error: false };

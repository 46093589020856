import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 100vh;
  padding: 0 15%;
`;

export const ErrorImage = styled.img`
  height: 50%;
  width: auto;
  margin-right: 80px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 460px;
`;
export const Title = styled.h1`
  ${props => props.theme.typography.largeTitle};
  color: ${props => props.theme.colors.neutral.grey10};
  margin: 0;
`;
export const Description = styled.p`
  ${props => props.theme.typography.header2};
  margin: 8px 0 12px;
`;
export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0 0;
  button {
    margin-right: 12px;
  }
`;
export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  color: ${props => props.theme.colors.brand.primary};
  cursor: pointer;
  outline: none;
  ${props => props.theme.typography.header2};
  padding: 0;
  &:focus-visible {
    outline: 2px solid #0078d4;
  }
`;

export const LogoContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 40px;
  left: 100px;
  color: ${props => props.theme.colors.brand.primary};
  ${props => props.theme.typography.header1};
  font-weight: 900;
  height: 24px;
`;

export const Logo = styled.img`
  height: 100%;
  width: auto;
  margin: 0 8px 0 0;
`;

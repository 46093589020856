import styled, { css } from 'styled-components';
import colors from 'designSystem/foundations/colors';
import { scrollBar } from 'StyleVariables/StandardComponents';
const errorColor = colors.feedback.error;
const defaultBackground = colors.neutral.white;
const disabledBackground = colors.neutral.offWhite02;
const borderHoverColor = colors.neutral.grey07;
const defaultBorderColor = colors.neutral.grey05;

export const TextArea = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  padding: 10px 12px;
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey10};
  background-color: ${({ disabled }) =>
    disabled ? disabledBackground : defaultBackground};
  border: 1px solid ${props => (props.error ? errorColor : defaultBorderColor)};
  border-radius: 4px;
  outline: none;
  resize: none;
  font-family: Arial;
  &::placeholder {
    color: ${props => props.theme.colors.neutral.grey06};
  }

  &:focus {
    border-${props => (props.error ? errorColor : borderHoverColor)};
  }
  ${scrollBar}
`;

TextArea.defaultProps = {
  error: false,
  disabled: false
};

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.width};
`;

InputContainer.defaultProps = {
  width: '100%'
};

const regularLabelColor = colors.neutral.grey10;
export const Label = styled.label`
  ${props => props.theme.typography.control};
  font-weight: 600;
  color: ${props => props.theme.colors.neutral.grey10};
  margin: ${props => props.margin};
  span {
    color: ${({ theme }) => theme.colors.feedback.error};
  }
`;
Label.defaultProps = { margin: '8px 0' };

export const SubLabel = styled.p`
  ${props => props.theme.typography.metadata};
  color: ${props => (props.error ? errorColor : regularLabelColor)};
  margin: ${props => props.margin};
`;

SubLabel.defaultProps = { margin: '8px 0', error: false };

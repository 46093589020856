import React from 'react';
import { HeaderRow, HeaderCell } from 'components/ListTable';
import { ActionCell } from './styles';
const JourneysListHeader = () => {
  return (
    <HeaderRow>
      <HeaderCell flex={3}>Name</HeaderCell>
      <HeaderCell flex={3}>Assigned</HeaderCell>
      <HeaderCell flex={4}>Tasks</HeaderCell>
      <HeaderCell flex={3}>Duration</HeaderCell>
      <ActionCell>Action</ActionCell>
    </HeaderRow>
  );
};

export default JourneysListHeader;

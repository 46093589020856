import styled, { css } from 'styled-components';
import colors from 'designSystem/foundations/colors';

const defaultBackground = colors.neutral.white;
const disabledBackground = colors.neutral.offWhite02;
const borderHoverColor = colors.neutral.grey07;

const getColor = ({ success, error }) => {
  if (success) {
    return colors.feedback.success;
  }
  if (error) {
    return colors.feedback.error;
  }
  return colors.neutral.grey05;
};

export const InputWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 10px 12px;
  color: ${props => getColor(props)};
  border: 1px solid ${props => getColor(props)};
  border-radius: 4px;
  background-color: ${({ disabled }) =>
    disabled ? disabledBackground : defaultBackground};

  &:focus-within {
    ${({ success, error }) =>
      !success && !error && `border-color: ${borderHoverColor}`};
  }

  input {
    flex: 1;
    min-width: 0;
    display: block;
    border: none;
    outline: none;
    background-color: transparent;
    font-family: 'Work Sans', sans-serif;
    ${props => props.theme.typography.control};
    color: ${props => props.theme.colors.neutral.grey10};
  }
  input::placeholder {
    color: ${props => props.theme.colors.neutral.grey06};
  }
`;

InputWrapper.defaultProps = {
  success: false,
  error: false,
  disabled: false
};

export const IconContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  margin-left: 8px;
  flex-shrink: 0;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: ${props => props.width};
`;

InputContainer.defaultProps = {
  width: '100%'
};

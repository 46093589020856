import styled from 'styled-components';

import { Avatar } from 'components/NewLyncworkUIKit';

export const StyledAvatar = styled(Avatar)`
  width: 112px;
  height: 112px;
  font-size: 48px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  button {
    position: relative;
    margin-top: auto;
    align-self: flex-start;
    input {
      cursor: pointer;
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
`;

export const UserName = styled.span`
  ${props => props.theme.typography.header1};
  color: ${props => props.theme.colors.neutral.grey10};
  font-weight: 600;
`;
export const RoleName = styled.span`
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey06};
  margin: 4px 0 0;
`;

import React from 'react';
import styled from 'styled-components';
import { HeaderRow, HeaderCell } from 'components/ListTable';
import { CustomCheckbox } from 'components/NewLyncworkUIKit';

export const StyledCheckbox = styled(CustomCheckbox)`
  margin: 0 0 0 24px;
`;

const TableHeader = ({ allSelectedChecked, handleSelectAll }) => (
  <HeaderRow>
    <StyledCheckbox
      name="all"
      onChange={handleSelectAll}
      checked={allSelectedChecked}
    />
    <HeaderCell flex={6}>Name</HeaderCell>
    <HeaderCell flex={6}>Roles</HeaderCell>
    <HeaderCell flex={6}>Teams</HeaderCell>
  </HeaderRow>
);

export default TableHeader;

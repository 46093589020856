import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { SliderContainer } from './styles';
import { appPackList, sliderSetting } from './constants';

import AppCard from './AppCard';

const FeaturedApps = () => {
  return (
    <SliderContainer>
      <Slider {...sliderSetting}>
        {appPackList.map(appPack => (
          <AppCard key={appPack.id} {...appPack} />
        ))}
      </Slider>
    </SliderContainer>
  );
};

export default FeaturedApps;

import React from 'react';
import { TextArea, InputContainer, Label, SubLabel } from './styles';

const CustomTextArea = ({
  disabled = false,
  error = false,
  name = '',
  value,
  placeholder = '',
  label = '',
  subLabel = '',
  inputRef,
  wrapperRef,
  width = '100%',
  onFocus = () => {},
  onChange = () => {},
  onBlur = () => {},
  className = '',
  rows,
  cols,
  required
}) => {
  return (
    <InputContainer error={error} className={className} wrapperRef={wrapperRef}>
      {label && (
        <Label>
          {label}
          {required && <span>*</span>}
        </Label>
      )}
      <TextArea
        rows={rows}
        cols={cols}
        ref={inputRef}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        error={error}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />

      {subLabel && <SubLabel error={error}>{subLabel}</SubLabel>}
    </InputContainer>
  );
};

export default CustomTextArea;

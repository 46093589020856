import React from 'react';
import { format, isValid } from 'date-fns';

import generateMediaUrl from 'utilities/generateMediaUrl';

import useAuthentication from 'hooks/useAuthentication';

import AvatarSection from '../AvatarSection';

import {
  Container,
  SectionContainer,
  InfoDetailContainer,
  InfoLabel,
  KeyPersonnelContainer,
  KeyPersonnelAvatar,
  ApplicationContainer,
  AppLogo,
  AvatarSectionContainer
} from './styles';

const formatDate = date => {
  const dateObj = new Date(date.replaceAll('-', '/'));
  const isDateValid = isValid(dateObj);
  if (!isDateValid) {
    return '';
  }
  return format(dateObj, 'MMMM dd yyyy');
};

const getListNames = user => {
  const { roles, teams } = user;
  const roleNames =
    roles.map(({ roleName }) => roleName).join(', ') || 'No Roles Assigned';
  const teamNames =
    teams.map(({ teamName }) => teamName).join(', ') || 'No Teams Assigned';
  return { roleNames, teamNames };
};

const GeneralInfo = ({ user }) => {
  const { id, provisionUserDate, emailPrimary } = user;

  const formattedProvisionDate = formatDate(provisionUserDate);
  const { roleNames, teamNames } = getListNames(user);
  return (
    <>
      <SectionContainer>
        <InfoDetailContainer>
          <InfoLabel>Provision Date</InfoLabel>
          <span>{formattedProvisionDate}</span>
        </InfoDetailContainer>
        <InfoDetailContainer>
          <InfoLabel>Company Email</InfoLabel>
          <span>{emailPrimary}</span>
        </InfoDetailContainer>
      </SectionContainer>
      <SectionContainer>
        <InfoDetailContainer>
          <InfoLabel>Roles</InfoLabel>
          <span>{roleNames}</span>
        </InfoDetailContainer>
        <InfoDetailContainer>
          <InfoLabel>Teams</InfoLabel>
          <span>{teamNames}</span>
        </InfoDetailContainer>
      </SectionContainer>
    </>
  );
};

const KeyPersonnel = ({ user, label }) => {
  if (!user) {
    return null;
  }
  const { avatar, id, firstName, lastName } = user;
  const fullName = `${firstName} ${lastName}`;
  const src = avatar?.url;
  return (
    <InfoDetailContainer>
      <InfoLabel>{label}</InfoLabel>
      <KeyPersonnelContainer>
        <KeyPersonnelAvatar colorKey={id} name={fullName} src={src} />
        <span>{fullName}</span>
      </KeyPersonnelContainer>
    </InfoDetailContainer>
  );
};

const KeyPersonnelInfo = ({ user }) => {
  const { hrPrime, itPrime, mentor, manager } = user;
  if (!hrPrime && !itPrime && !mentor && !manager) {
    return null;
  }
  return (
    <SectionContainer>
      <KeyPersonnel user={manager} label="Manager" />
      <KeyPersonnel user={mentor} label="Mentor" />
      <KeyPersonnel user={itPrime} label="It Prime" />
      <KeyPersonnel user={hrPrime} label="Hr Prime" />
    </SectionContainer>
  );
};

const Applications = ({ user }) => {
  const { applications } = user;
  const filteredApplications = applications.filter(
    ({ appName }) => appName !== 'LyncWork'
  );

  if (filteredApplications.length < 1) {
    return null;
  }
  return (
    <SectionContainer>
      <InfoLabel>Applications</InfoLabel>
      <ApplicationContainer>
        {filteredApplications.map(app => {
          const { id, appName, appLogo } = app;
          const src = generateMediaUrl(appLogo);
          return <AppLogo src={src} key={id} colorKey={id} name={appName} />;
        })}
      </ApplicationContainer>
    </SectionContainer>
  );
};

const AccountInfo = ({ avatarData, changeAvatar }) => {
  const { user } = useAuthentication();
  const { id, provisionUserDate, emailPrimary } = user;

  const formattedProvisionDate = formatDate(provisionUserDate);
  const { roleNames, teamNames } = getListNames(user);

  return (
    <Container>
      <AvatarSectionContainer>
        <AvatarSection avatarData={avatarData} changeAvatar={changeAvatar} />
      </AvatarSectionContainer>
      <GeneralInfo user={user} />
      <KeyPersonnelInfo user={user} />
      <Applications user={user} />
    </Container>
  );
};

export default AccountInfo;

import axios from 'axios';
import env from '../env';

const baseUrl = env.serviceDomain;

export const LicenceService = {
  getDetail: (appAPIName, licenseId) => {
    const searchParams = new URLSearchParams({
      skuid: licenseId
    }).toString();
    return axios.get(
      `${baseUrl}/${appAPIName}/api/licensedetails?${searchParams}`
    );
  }
};

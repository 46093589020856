import styled from 'styled-components';
import Card from 'components/Card';

export const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin: 56px 0 0;
  max-width: 548px;

  height: 88px;
  gap: 16px;
`;

export const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 548px;

  box-sizing: border-box;
  margin: 32px 0 0;
  padding: 32px;

  h1 {
    ${props => props.theme.typography.header1};
    font-weight: 600;
    margin: 0 0 8px;
  }
`;

export const AppSetupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 716px;
`;

export const SetupCompleteContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 24px;
  width: 100%;
  max-width: 548px;

  box-sizing: border-box;
  margin: 32px 0 0;
  padding: 32px;

  h1 {
    ${props => props.theme.typography.header1};
    font-weight: 600;
    margin: 0;
  }

  p {
    ${props => props.theme.typography.header3};
    margin: 0;
  }

  .check-mark {
    font-size: 48px;
  }
`;

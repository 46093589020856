import { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { isDev } from 'env';
const useTrackPageViewDev = () => {};
const useTrackPageViewProd = pathname => {
  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView();
  }, [pathname]);
};
const useButtonTrackDev = () => {
  const trackButton = event => {
    //uncomment the line below to see the event firing (only when developing instrumentation)
    console.log(event);
  };
  return trackButton;
};
const useButtonTrackProd = () => {
  const { trackEvent } = useMatomo();

  const trackButton = (name = 'button event') => {
    trackEvent({
      category: 'button',
      action: 'click',
      name
    });
  };
  return trackButton;
};

export const useTrackPageView = isDev
  ? useTrackPageViewDev
  : useTrackPageViewProd;

export const useButtonTrack = isDev ? useButtonTrackDev : useButtonTrackProd;

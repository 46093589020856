import styled from 'styled-components';
import colors from 'designSystem/foundations/colors';
import Input from 'components/NewLyncworkUIKit/CustomTextInput';
import Button from 'components/NewLyncworkUIKit/CustomButton';
import { CustomDropdown } from 'components/NewLyncworkUIKit';

const {
  neutral: { offWhite02, white, offWhite03, black }
} = colors;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${white};
  width: 1000px;
  padding: 24px;
  border-radius: 8px;
`;

export const SubContainer = styled.div`
  justify-content: space-between;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

export const TooltipContainer = styled.div`
  margin-left: 5px;
`;

export const UserSelectionSection = styled.div`
  width: 60%;
`;

export const UserDetailSection = styled.div`
  width: 270px;
`;

export const UserInfo = styled.div`
  height: 410px;
  overflow: auto;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 10%);
`;

export const UserInfoCard = styled.p`
  margin-left: 15px;
  margin-right: 15px;
  font-size: 14px;
`;

export const InputLabel = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${props => props.theme.typography.body};
  font-weight: 500;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? '16px' : '8px')};
`;

export const DeleteButton = styled(Button)`
  width: 95px;
  height: 36px;
  background-color: ${white};
  &:hover {
    background-color: ${offWhite02};
  }
`;
DeleteButton.defaultProps = {
  variant: 'secondary',
  size: 'medium'
};

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 198px;
  align-self: flex-end;
`;

export const SaveButton = styled(Button)`
  width: 95px;
  margin: 0 0 0 auto;
  height: 36px;
`;
SaveButton.defaultProps = {
  size: 'medium'
};

export const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  margin-left: 4px;
  border-radius: 50%;
  background-color: transparent;
  color: ${black};

  &:hover {
    background-color: ${offWhite03};
  }
  &:focus-visible {
    outline: 2px solid blue;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import error from 'assets/images/error.svg';

import {
  CustomSearch,
  IconButton as CloseButton
} from 'components/NewLyncworkUIKit/';
import Pagination from 'components/Pagination';

import SubscriptionCard from './SubscriptionCard';

import { SearchBarContainer, BlankContainer, ListContainer } from './styles';

const List = ({ loading, hasResults, children }) => {
  if (loading) {
    return (
      <BlankContainer>
        <CircularProgress />
      </BlankContainer>
    );
  }
  if (!hasResults) {
    return (
      <BlankContainer>
        <img src={error} alt="empty state" />
        <h3>No Results Found</h3>
        <span>
          Try adjusting your search or filters to find what you’re looking for
        </span>
      </BlankContainer>
    );
  }
  return <ListContainer>{children}</ListContainer>;
};

const SubscriptionList = ({
  selectSubscription,
  closeModal,
  applications,
  loading,
  handleSearchInputChange,
  searchQuery,
  changePage,
  subscriptionApps,
  page,
  totalPages
}) => {
  const hasResults = subscriptionApps.length > 0;
  return (
    <>
      <SearchBarContainer>
        <CustomSearch
          className="search-input"
          placeholder="Search All Applications"
          value={searchQuery}
          onChange={handleSearchInputChange}
          offWhite
        />
        <CloseButton className="close-icon" onClick={closeModal} />
      </SearchBarContainer>
      <List loading={loading} hasResults={hasResults}>
        {subscriptionApps.map(subscription => {
          const added = !!applications.find(
            ({ id }) => id === subscription.application.id
          );
          return (
            <SubscriptionCard
              key={subscription.id}
              subscription={subscription}
              added={added}
              selectSubscription={selectSubscription}
            />
          );
        })}
      </List>
      <Pagination
        totalPages={totalPages}
        currentPage={page + 1}
        changePage={changePage}
      />
    </>
  );
};

export default SubscriptionList;

import React from 'react';
import parse from 'html-react-parser';

import { H1, P } from 'StyleVariables/StandardComponents';
import {
  PreviewContainer,
  Mask,
  BGImageContainer,
  Category,
  InfoVertical,
  InfoHorizontal
} from './styles';
import Skyline from 'assets/images/a-city-skyline-bring-ravaged-by-winter-elements.png';
import Brainstorm from 'assets/images/team-brainstorm-meeting-in-bright-sunny-office.png';
import ProductivityA from 'assets/images/productivity-1.jpg';
import ProductivityB from 'assets/images/productivity-2.jpg';
import BuildingA from 'assets/images/building-1.jpg';
import BuildingB from 'assets/images/building-2.jpg';
import { useHistory } from 'react-router-dom';

const placeholderPics = [
  Skyline,
  Brainstorm,
  ProductivityA,
  ProductivityB,
  BuildingA,
  BuildingB
];

const ArticlePreview = ({
  data,
  hover = 'horizontal',
  routeOnClick = true
}) => {
  const history = useHistory();
  const { title, date, img, category, transition } = data;
  const medias = !!data.medias.length
    ? data.medias[0]?.formats?.large?.url
    : placeholderPics[placeholderPics.length % data.id];

  let imgIdArray = [];

  data.medias.forEach(media => {
    const isIncluded = data.content.includes(`lw-img-id:${media.id}`);
    isIncluded && imgIdArray.push(media.id);
  });
  const thumbnail = data.medias.find(img => !imgIdArray.includes(img.id));

  const colorOptions = ['#4a90e2', '#50e3c2', '#f5a623', '#9013fe', '#ff6b6b'];
  const routeFunction = () =>
    routeOnClick ? history.push(`/community/posts/${data.id}`) : null;

  return (
    <PreviewContainer className={` ${transition}`} onClick={routeFunction}>
      <Mask></Mask>

      {hover === 'horizontal' ? (
        <InfoHorizontal>
          {category && (
            <Category colorOptions={colorOptions} id={category.id}>
              {category.name}
            </Category>
          )}
          <H1 clamp={2}>{parse(title)}</H1>

          <P>{date}</P>
        </InfoHorizontal>
      ) : (
        <InfoVertical>
          {category && (
            <Category colorOptions={colorOptions} id={category.id}>
              {category.name}
            </Category>
          )}
          <H1 clamp={2}>{parse(title)}</H1>

          <P>{date}</P>
        </InfoVertical>
      )}

      <BGImageContainer
        img={
          thumbnail
            ? thumbnail.url
            : placeholderPics[data.id % placeholderPics.length]
        }
      ></BGImageContainer>
    </PreviewContainer>
  );
};
export default ArticlePreview;

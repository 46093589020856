import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress';

import TrashIcon from 'assets/images/GenericIcons/trash.svg';
import DownloadIcon from 'assets/images/GenericIcons/Download.svg';
import emptyStatePic from 'assets/images/GenericIcons/empty state.svg';

import Pagination from 'components/Pagination';
import { CustomButton, CustomSearch } from 'components/NewLyncworkUIKit';

import { useButtonTrack } from 'matomo/hooks';

import { UserService } from 'services/user.service';
import { downloadBlob, HelloSignService } from 'services/hellosign.service';
import ListTable from 'components/ListTable';

import {
  Container,
  GroupActionContainer,
  BatchBox,
  ActionRow,
  Icon
} from './styles';

import { TableHeader, HelloSignRow } from './HelloSignTable';
import { documentsPageEvents } from '../../instrumentationEvents';

const {
  add_file,
  change_page,
  select_file,
  select_all_files,
  batch_download,
  batch_deletion
} = documentsPageEvents;

const downloadFile = async doc => {
  const { data } = await HelloSignService.getFileBySignatureId(
    doc.signatureRequestInfo.signatureRequestId
  );
  downloadBlob(data, `${doc.title}.pdf`);
};

const deleteFile = async file => {
  const { signatureRequestId } = file.signatureRequestInfo;
  await HelloSignService.deleteSignatureRequest(signatureRequestId);
};

const DropBoxDocs = ({ setFileToEdit, fileToEdit }) => {
  const trackButton = useButtonTrack();
  const [uploadedData, setUploadedData] = useState([]);
  const [searchParam, setSearchParam] = useState('');
  const [deleting, setDeleting] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const history = useHistory();

  const handleSearch = async () => {
    setUploadedData([]);
    setLoading(true);
    let userIds = [];
    const { data } = await HelloSignService.getAllSignedDocuments(
      searchParam,
      page
    );
    data.content.forEach(x => userIds.push(x.lyncWorkUserId));
    const userRequest = await UserService.listByIds(userIds);
    const filesWithUsers = data.content.map(x => ({
      ...x,
      user: userRequest?.data?.find(y => y.id === x.lyncWorkUserId)
    }));
    setUploadedData(filesWithUsers);
    setTotalPages(data.totalPages);
    setLoading(false);
  };

  useEffect(() => {
    handleSearch();
  }, [page]);

  const handleSelect = value => {
    trackButton(select_file);
    if (selectedFiles.includes(value)) {
      setSelectedFiles(oldFiles =>
        oldFiles.filter(file => file.id !== value.id)
      );
    } else {
      setSelectedFiles(oldFiles => [...oldFiles, value]);
    }
  };

  const handleBatchDownload = async () => {
    trackButton(batch_download);
    setDownloading(true);
    try {
      for (const file of selectedFiles) {
        await downloadFile(file);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDownloading(false);
      setSelectedFiles([]);
    }
  };

  const handleBatchDeletion = async () => {
    trackButton(batch_deletion);
    setDeleting(true);
    try {
      for (const file of selectedFiles) {
        await deleteFile(file);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDeleting(false);
      page > 0 ? setPage(0) : handleSearch();
      setSelectedFiles([]);
    }
  };

  const routeToAddNewFile = () => {
    trackButton(add_file);
    setFileToEdit({});
    history.push('/admin-documents/uploaded');
  };

  const changePage = page => {
    trackButton(change_page);
    setPage(page - 1);
  };

  const handleSelectAll = () => {
    trackButton(select_all_files);
    return selectedFiles === uploadedData
      ? setSelectedFiles([])
      : setSelectedFiles(uploadedData);
  };
  return (
    <Container>
      <GroupActionContainer>
        <ActionRow>
          <CustomSearch
            placeholder="Search files"
            value={searchParam}
            onChange={e => setSearchParam(e.target.value)}
            onSearch={handleSearch}
          />
          <CustomButton size="medium" onClick={routeToAddNewFile}>
            + New File
          </CustomButton>
        </ActionRow>
        <ActionRow>
          <BatchBox noHover>Selected({selectedFiles.length})</BatchBox>
          <BatchBox onClick={handleBatchDownload}>
            <Icon src={DownloadIcon} />
            {downloading ? (
              <CircularProgress size="18px" color="inherit" />
            ) : (
              'Download'
            )}
          </BatchBox>
          <BatchBox onClick={handleBatchDeletion}>
            <Icon src={TrashIcon} />
            {deleting ? (
              <CircularProgress size="18px" color="inherit" />
            ) : (
              'Delete'
            )}
          </BatchBox>
        </ActionRow>
      </GroupActionContainer>
      <ListTable
        loading={loading}
        header={
          <TableHeader
            handleSelectAll={handleSelectAll}
            checked={selectedFiles === uploadedData}
          />
        }
        hasContent={uploadedData.length > 0}
      >
        {uploadedData.map(item => (
          <HelloSignRow
            key={item.id}
            handleSelect={handleSelect}
            item={item}
            selectedFiles={selectedFiles}
          />
        ))}
      </ListTable>
      <Pagination
        totalPages={totalPages}
        currentPage={page + 1}
        changePage={changePage}
      />
    </Container>
  );
};
export default DropBoxDocs;

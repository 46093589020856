import React, { useState, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';

//this component is necessary since keycloak wrapper does not automatically refresh
const useKeycloakRefresh = () => {
  const { initialized, keycloak } = useKeycloak();

  const [toggle, setToggle] = useState(true);
  useEffect(() => {
    if (!initialized) {
      return;
    }

    keycloak.onTokenExpired = () => {
      keycloak.updateToken(60).then(refresh => {
        if (refresh) {
          setToggle(a => !a);
        }
      });
    };
    return () => {
      keycloak.onTokenExpired = () => {};
    };
  }, [keycloak, initialized]);
};

export default useKeycloakRefresh;

import React from 'react';

import { BrowserRouter as Router } from 'react-router-dom';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import ErrorWrapper from 'components/ErrorWrapper';
import { Toaster } from 'components/toasts';

import DialogueContextProvider from 'context/DialogueContext';
import { GlobalThemeProvider } from 'designSystem';
import MatomoWrapper from 'matomo/MatomoWrapper';

import { store, persistor } from '../redux/store.js';
import { registerAuthInterceptor } from '../axios';

registerAuthInterceptor(store);

const AppContext = ({ children }) => {
  return (
    <GlobalThemeProvider>
      <DialogueContextProvider>
        <ErrorWrapper>
          <MatomoWrapper>
            <Router>
              <Toaster />
              <Provider store={store}>
                <PersistGate persistor={persistor}>{children}</PersistGate>
              </Provider>
            </Router>
          </MatomoWrapper>
        </ErrorWrapper>
      </DialogueContextProvider>
    </GlobalThemeProvider>
  );
};

export default AppContext;

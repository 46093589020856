export const adminOnboardingWelcomePageEvents = {
  save_post: 'onboarding control panel save post',
  open_preview: 'onboarding control panel preview post',
  close_preview: 'onboarding control panel close post preview'
};

export const adminOnboardingPersonalInfoPageEvents = {
  save_fields: 'onboarding control panel save personal info',
  open_preview: 'onboarding control panel preview personal info',
  close_preview: 'onboarding control panel close personal info preview',
  add_field: 'onboarding control panel add personal info field',
  remove_field: 'onboarding control panel remove personal info field',
  mark_field:
    'onboarding control panel mark personal info field optional/required'
};

export const adminOnboardingDocumentsPageEvents = {
  open_preview: 'onboarding control panel preview documents',
  close_preview: 'onboarding control panel close documents preview',
  add_documents: 'onboarding control panel add documents',
  delete_document: 'onboarding control panel delete documents',
  delete_document_confirm: 'onboarding control panel confirm document deletion',
  edit_document: 'onboarding control panel edit document',
  save_document: 'onboarding control panel save document changes',
  download_document: 'onboarding control panel download document'
};

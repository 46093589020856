import React from 'react';
import { useHistory } from 'react-router';
import { format, isValid } from 'date-fns';

import HiddenCounter from 'components/HiddenCounter';
import { Cell } from 'components/ListTable';

import {
  StyledAvatar,
  StyledCheckbox,
  StatusCircle,
  StatusText,
  StyledRow
} from './styles';

const formatDate = date => {
  const dateObj = new Date(date.replaceAll('-', '/'));
  const isDateValid = isValid(dateObj);
  if (!isDateValid) {
    return '';
  }
  return format(dateObj, 'MM/dd/yyyy');
};

const getStatusText = status => {
  switch (status) {
    case 'PENDING_EMAIL_NOTIFICATION':
      return 'pending email';
    default:
      return status.toLowerCase().replace('_', ' ');
  }
};

const UserCard = ({
  user,
  setSelectedUser,
  handleChecked,
  checked,
  selected
}) => {
  const history = useHistory();
  const {
    id,
    firstName,
    lastName,
    lyncWorkUserStatus: { status: userStatus },
    provisionUserDate
  } = user;
  const src = user.avatar?.url;
  const fullName = `${firstName} ${lastName}`;
  const roleName = user.roles[0]?.roleName || 'Unassigned';
  const teamName = user.teams[0]?.teamName || 'Unassigned';
  const remainingRolesCount = user.roles.length - 1;
  const remainingTeamsCount = user.teams.length - 1;
  const formattedProvisionDate = provisionUserDate
    ? formatDate(provisionUserDate)
    : '';

  const selectUser = () => setSelectedUser(user);
  const checkUser = () => handleChecked(user);
  const navigateToEditPage = () =>
    history.push(`admin-users/edit/${user.id}/work-details`);

  return (
    <StyledRow
      onClick={selectUser}
      onDoubleClick={navigateToEditPage}
      selected={selected}
    >
      <StyledCheckbox name={id} onChange={checkUser} checked={checked} />
      <Cell flex={6}>
        <StyledAvatar src={src} colorKey={id} name={firstName} />
        <span className="list-row-name">{fullName}</span>
      </Cell>
      <Cell flex={6}>
        <span className="overflow">{roleName} </span>
        {remainingRolesCount > 0 && (
          <HiddenCounter>+{remainingRolesCount}</HiddenCounter>
        )}
      </Cell>
      <Cell flex={6}>
        <span className="overflow">{teamName} </span>
        {remainingTeamsCount > 0 && (
          <HiddenCounter>+{remainingTeamsCount}</HiddenCounter>
        )}
      </Cell>
      <Cell flex={6}>
        <StatusCircle status={userStatus} />
        <StatusText>{getStatusText(userStatus)}</StatusText>
      </Cell>
      <Cell flex={6}>{formattedProvisionDate}</Cell>
    </StyledRow>
  );
};

export default UserCard;

import React, { useState } from 'react';
import { useParams, useHistory, Switch, Route } from 'react-router-dom';

import { UserService } from 'services';
import { useButtonTrack } from 'matomo/hooks';
import {
  useDialogue,
  useAuthentication,
  useUser,
  useCustomUserFields,
  useModal,
  useToast
} from 'hooks';

import BackButton from 'components/BackButton';

import { singleUserPageEvents } from 'pages/Users/UsersListPage/instrumentationEvents';

import {
  formatEmailPrimary,
  convertToUTC,
  getUserListDifference
} from './utils';

import {
  NavBar,
  WorkDetailForm,
  PersonalInfo,
  ApplicationStatus,
  UserDeletionModal,
  DeprovisionModal,
  StatusBanner
} from './components';
import DocumentSection from './components/DocumentSection/DocumentSection';
import SkillSection from './components/SkillSection/SkillSection';

import { Breadcrumb } from './styles';

const { save_user, delete_user } = singleUserPageEvents;

const UserEditPage = () => {
  const { createToast } = useToast();
  const { user: loggedInUser } = useAuthentication();
  const { domain } = loggedInUser.company;

  const { id } = useParams();
  const { loading, error, userData, setUserData } = useUser(id);
  const customUserFields = useCustomUserFields();

  const history = useHistory();
  const { showDialogue, closeDialogue } = useDialogue();
  const {
    isOpen: isDeprovisionModalOpen,
    openModal: openDeprovisionModal,
    closeModal: closeDeprovisionModal
  } = useModal();
  const [deletingUser, setDeletingUser] = useState(false);
  const trackButton = useButtonTrack();

  const handleSubmit = async workDetails => {
    trackButton(save_user);
    const {
      emailPrimary,
      provisionUserDate,
      directReports,
      mentee
    } = workDetails;
    const formattedEmailPrimary = formatEmailPrimary(emailPrimary);
    const newEmailPrimary = `${formattedEmailPrimary}@${domain}`;
    const newProvisionUserDate = convertToUTC(provisionUserDate);
    const {
      inserted: directReportsInserted,
      deleted: directReportsDeleted
    } = getUserListDifference(userData.directReports, directReports);

    const {
      inserted: menteeInserted,
      deleted: menteeDeleted
    } = getUserListDifference(userData.mentee, mentee);

    const userInfo = {
      ...workDetails,
      provisionUserDate: newProvisionUserDate,
      emailPrimary: newEmailPrimary,
      directReportsInserted,
      directReportsDeleted,
      menteeInserted,
      menteeDeleted
    };
    const response = await UserService.update(userInfo);
    const newUserData = response.data;

    //remove primary email role
    const modifiedUserData = {
      ...newUserData,
      roles: newUserData.roles.filter(({ id }) => id)
    };
    createToast({
      title: 'User Edit Saved',
      message: 'Changes made to the user has been saved successfully.'
    });
    setUserData(modifiedUserData);
    return modifiedUserData;
  };

  const canDelete = id !== loggedInUser.id;

  const deleteUser = async () => {
    try {
      closeDeprovisionModal();
      setDeletingUser(true);
      trackButton(delete_user);
      await UserService.delete(userData.username);
      history.push('/admin-users');
    } catch (error) {
      console.log(error);
      const errorMessage =
        error.response.data?.title || 'oops, something went wrong';
      showDialogue({
        title: 'Oops, something went wrong',
        message: errorMessage,
        cancelButtonAction: null,
        confirmButtonText: 'Close',
        confirmButtonAction: closeDialogue
      });
      throw error;
    }
  };
  const monitorUser = async () => {
    const { firstName, lastName } = userData;
    try {
      closeDeprovisionModal();
      setDeletingUser(true);
      const response = await UserService.changeStatus(id, 'monitored');
      const newUserData = response.data;
      const modifiedUserData = {
        ...newUserData,
        roles: newUserData.roles.filter(({ id }) => id)
      };
      createToast({
        title: 'User Monitored Deprovisioned',
        message: `${firstName} ${lastName}’s access to all accounts have been revoked, and temporary monitoring credentials have been generated.`
      });
      setUserData(modifiedUserData);
      setDeletingUser(false);
    } catch (error) {
      console.log(error);
      const errorMessage =
        error.response.data?.title || 'oops, something went wrong';
      showDialogue({
        title: 'Oops, something went wrong',
        message: errorMessage,
        cancelButtonAction: null,
        confirmButtonText: 'Close',
        confirmButtonAction: closeDialogue
      });
    }
  };

  if (loading) {
    return null;
  }
  if (error) {
    return <div>{error}</div>;
  }
  const { lyncWorkUserStatus } = userData;

  return (
    <>
      <StatusBanner lyncWorkUserStatus={lyncWorkUserStatus} />
      <DeprovisionModal
        userData={userData}
        isOpen={isDeprovisionModalOpen}
        closeModal={closeDeprovisionModal}
        deleteUser={deleteUser}
        monitorUser={monitorUser}
      />
      <BackButton link="/admin-users" />
      <Breadcrumb>User Details</Breadcrumb>
      <NavBar id={id} />
      <Switch>
        <Route path="/admin-users/edit/:id/work-details" exact>
          <WorkDetailForm
            userData={userData}
            domain={domain}
            handleSubmit={handleSubmit}
            handleDelete={openDeprovisionModal}
            deletingUser={deletingUser}
            canDelete={canDelete}
          />
        </Route>
        <Route path="/admin-users/edit/:id/personal-info" exact>
          <PersonalInfo
            userData={userData}
            customUserFields={customUserFields}
            canDelete={canDelete}
            handleDelete={openDeprovisionModal}
            deletingUser={deletingUser}
          />
        </Route>
        <Route path="/admin-users/edit/:id/documents" exact>
          <DocumentSection userData={userData} />
        </Route>
        <Route path="/admin-users/edit/:id/application-status" exact>
          <ApplicationStatus
            userData={userData}
            deletingUser={deletingUser}
            canDelete={canDelete}
            handleDelete={openDeprovisionModal}
          />
        </Route>
        <Route path="/admin-users/edit/:id/skills" exact>
          <SkillSection userData={userData} />
        </Route>
      </Switch>
    </>
  );
};

export default UserEditPage;

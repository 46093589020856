import React from 'react';

import useCommunityPosts from 'hooks/useCommunityPosts';

import ListTable from 'components/ListTable';
import Pagination from 'components/Pagination';

import { ToolBar, PostCard, TableHeader } from './components';
import { PageContainer, ContentContainer } from './styles';

const PostListPage = ({ draft = false }) => {
  const { loading, error, posts, page, totalPages, changePage } =
    useCommunityPosts({ draft });
  const hasContent = posts.length > 0;

  return (
    <PageContainer>
      <ToolBar />
      <ContentContainer>
        <ListTable
          loading={loading}
          error={error}
          header={<TableHeader />}
          hasContent={hasContent}
        >
          {posts.map(post => (
            <PostCard key={post.id} post={post} />
          ))}
        </ListTable>
        <Pagination
          totalPages={totalPages}
          currentPage={page + 1}
          changePage={changePage}
        />
      </ContentContainer>
    </PageContainer>
  );
};

export default PostListPage;

import React, { useState } from 'react';
import { isValid, getDaysInMonth } from 'date-fns';
import { CustomSelect } from 'components/NewLyncworkUIKit';
import {
  Container,
  Label,
  SubLabel,
  SelectContainer,
  SelectLabel,
  HiddenInput
} from './styles';

export const validateDate = dateString => {
  const [year, month, day] = dateString.split('-');
  if (!year || !month || !day) return false;
  const canDateBeConstructed = isValid(new Date(dateString));
  if (!canDateBeConstructed) return false;

  const [parsedYear, parsedMonth, parsedDay] = [year, month, day].map(Number);

  const numOfDays = getDaysInMonth(new Date(parsedYear, parsedMonth - 1));
  return 1 <= parsedDay && parsedDay <= numOfDays;
};

const formatNumberString = num => {
  if (num >= 10) {
    return String(num);
  }
  return `0${num}`;
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const monthOptions = months.map((name, index) => ({
  label: name,
  value: formatNumberString(index + 1)
}));

const dayOptions = new Array(31).fill(0).map((_, index) => {
  const value = formatNumberString(index + 1);
  return { label: value, value };
});
const yearOptions = new Array(130).fill(0).map((_, index) => ({
  label: String(1900 + index),
  value: String(1900 + index)
}));

export const Select = ({ label, value, options, onChange }) => {
  const selectedOption = options.find(option => option.value === value);
  return (
    <SelectContainer>
      <SelectLabel>{label}</SelectLabel>
      <CustomSelect
        placeholder=""
        options={options}
        onChange={onChange}
        value={selectedOption}
        menuPlacement="bottom"
      />
    </SelectContainer>
  );
};

const DOBInput = ({ value, handleChange, errorMessage }) => {
  const [year, month, day] = value.split('-');
  const handleMonthChange = selectedOption => {
    const { value: month } = selectedOption;
    handleChange(year, month, day);
  };
  const handleDayChange = selectedOption => {
    const { value: day } = selectedOption;
    handleChange(year, month, day);
  };
  const handleYearChange = selectedOption => {
    const { value: year } = selectedOption;
    handleChange(year, month, day);
  };

  return (
    <Container data-input-id="dob" error={errorMessage}>
      <HiddenInput value={value} readOnly name="dob" />
      <Label>
        Date of Birth
        <span>*</span>
      </Label>
      <Select
        label="Month"
        options={monthOptions}
        value={month}
        onChange={handleMonthChange}
      />
      <Select
        label="Day"
        options={dayOptions}
        value={day}
        onChange={handleDayChange}
      />
      <Select
        label="Year"
        options={yearOptions}
        value={year}
        onChange={handleYearChange}
      />
      <SubLabel error={errorMessage}>{errorMessage}</SubLabel>
    </Container>
  );
};

export default DOBInput;

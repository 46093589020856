const dashboardData = {
  pathname: '/dashboard',
  title: 'Dashboard'
};
const communityData = {
  pathname: '/community',
  title: 'Community'
};
const userJourneysData = {
  pathname: '/user-journeys',
  title: 'Journeys'
};
const companyDirectoryData = {
  pathname: '/company-directory',
  title: 'Company Directory'
};
const accountDetailsData = {
  pathname: '/account-details',
  title: 'Account Details'
};
const companyInfoData = {
  pathname: '/company-info',
  title: 'Company Info'
};

const pathData = [
  dashboardData,
  communityData,
  userJourneysData,
  companyDirectoryData,
  accountDetailsData,
  companyInfoData
];

export default pathData;

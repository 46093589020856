import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import RolesListPage from './RolesListPage';
import RolesAddPage from './RolesAddPage';
import RolesEditPage from './RolesEditPage';
const RolesPage = () => {
  return (
    <Switch>
      <Route path="/admin-roles" exact>
        <RolesListPage />
      </Route>
      <Route path="/admin-roles/edit/:id">
        <RolesEditPage />
      </Route>
      <Route path="/admin-roles/add">
        <RolesAddPage />
      </Route>
    </Switch>
  );
};

export default RolesPage;

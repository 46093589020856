import styled from 'styled-components';
const mobileBreakpoint = '900px';
export const SideBarMobileHamburger = styled.img`
  width: 20px;
  cursor: pointer;
`;

export const SideBarMobileHeader = styled.div`
  display: none;
  @media only screen and (max-width: ${mobileBreakpoint}) {
    display: flex;
    justify-content: space-between;
    position: sticky;

    top: 0;
    width: 100%;
    background-color: white;
    z-index: 2;
    box-sizing: border-box;
    padding: 40px 24px;
  }
`;
export const SideBarMobileProgress = styled.div`
  display: none;
  @media only screen and (max-width: ${mobileBreakpoint}) {
    display: flex;
    flex-direction: column;
    position: absolute;
    height: calc(100vh - 105px);
    top: 105px;
    width: 100vw;
    background-color: white;
    z-index: 2;
    box-sizing: border-box;
    padding: 0 28px;
    transform: translateX(${props => (!props.isOpen ? '100vw' : '0vw')});
    transition: transform 0.3s ease-in-out;
  }
`;
export const SideBarMobileContent = styled.div`
  display: none;
  @media only screen and (max-width: ${mobileBreakpoint}) {
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 0 20px;

    z-index: 1;
  }
`;
export const SideBarLogo = styled.img`
  width: 150px;
`;

import styled from 'styled-components';
import AsyncCreatableSelect from 'react-select/async-creatable';

import {
  style as selectStyle,
  asyncDefaultProps,
  defaultProps
} from 'components/NewLyncworkUIKit/CustomSelect';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const Select = styled(AsyncCreatableSelect)`
  ${selectStyle}
`;
Select.defaultProps = asyncDefaultProps;

export const Label = styled.label`
  text-align: left;
  ${props => props.theme.typography.control};
  font-weight: 500;
  color: ${props => props.theme.colors.neutral.grey10};
  margin: 8px 0;
`;

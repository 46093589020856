export const teamsListPageEvents = {
  add_team: 'add team',
  edit_team: 'edit team',
  change_page: `admin team change page number`
};

export const singleTeamPageEvents = {
  back: 'back to teams list page',
  delete_team: 'delete team attempt',
  delete_team_confirm: 'team deletion confirm',
  add_selected_users: 'team add modal add selected users',
  add_selected_single_user: 'team add modal select single user',
  remove_user: 'team remove user',
  save_team: 'save team'
};

export const newTeamPageEvents = {
  back: 'back to teams list page',
  add_selected_users: 'add member(s) to newly created team',
  delete_team: 'delete team attempt',
  save_team: 'save newly created team'
};

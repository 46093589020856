import React from 'react';

import imageMeetTheTeam from 'assets/images/Onboarding/MeetTheTeam.svg';

import { useMobileTutorial } from 'context/MobileTutorialContext';
import CustomButton from 'components/NewLyncworkUIKit/CustomButton';
import { KeyStep, Overlay } from './styles';

const TutorialStart = () => {
  const { exitTutorial, startTutorial } = useMobileTutorial();

  return (
    <Overlay>
      <KeyStep>
        <h3>Welcome to Lyncwork</h3>
        <img src={imageMeetTheTeam} alt="illustration" />
        <span>
          We’ll take you through a quick tour to help you learn the ropes!
        </span>
        <CustomButton size="medium" onClick={startTutorial}>
          Start Tour
        </CustomButton>
        <CustomButton size="medium" variant="tertiary" onClick={exitTutorial}>
          Skip
        </CustomButton>
      </KeyStep>
    </Overlay>
  );
};

export default TutorialStart;

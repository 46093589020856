import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import PortalContainer from 'components/PortalContainer';
import { CustomButton } from 'components/NewLyncworkUIKit';
import {
  Container,
  ContentContainer,
  MessageContainer,
  ButtonContainer,
  Title,
  CloseButton
} from './styles';

const LightboxPrompt = ({
  onClickOutside,
  isOpen,
  title = 'Discard your changes?',
  message = 'Are you sure you want to discard the changes you have made?',
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  confirmButtonAction,
  cancelButtonAction
}) => {
  return (
    <PortalContainer isOpen={isOpen} handleClickOutside={onClickOutside}>
      <Container onClick={e => e.stopPropagation()}>
        <CloseButton>
          <CloseIcon onClick={onClickOutside} />
        </CloseButton>
        <Title>{title}</Title>
        <div>{message}</div>
        <ButtonContainer>
          {cancelButtonAction && (
            <CustomButton
              variant="secondary"
              size="medium"
              onClick={cancelButtonAction}
            >
              {cancelButtonText}
            </CustomButton>
          )}
          <CustomButton size="medium" onClick={confirmButtonAction}>
            {confirmButtonText}
          </CustomButton>
        </ButtonContainer>
      </Container>
    </PortalContainer>
  );
};

export default LightboxPrompt;

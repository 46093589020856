import styled from 'styled-components';
import Button from 'components/NewLyncworkUIKit/CustomButton';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 80px 0;
  width: calc(100% - 40px);
  max-width: 600px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`;

export const CustomButton = styled(Button)`
  width: 160px;
  margin: 0 8px;
`;

export const Title = styled.h3`
  ${props => props.theme.typography.title3};
  font-weight: 600;
  margin: 48px 0 0;
  text-align: center;
`;

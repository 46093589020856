import React, { useEffect, useState } from 'react';
import { CommunityContainer, Features, Featured, Message } from './styles';
import ArticlePreview from './components/ArticlePreview';
import ArticleCategories from './components/ArticleCategories';
import LoadingScreen from 'components/LoadingScreen';
import { CMSService } from 'services/cms.service';

const Community = () => {
  const [featured, setFeatured] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await CMSService.community();
        setFeatured(data.featureds?.slice(0, 3) || null);
        setCategories(data.categories);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  if (loading) {
    return (
      <CommunityContainer>
        <LoadingScreen />
      </CommunityContainer>
    );
  }
  if (!loading && !featured?.length && !categories?.length) {
    return (
      <CommunityContainer>
        <Message>
          Seems like there are no posts here, click "Add a Post" to change that!
        </Message>
      </CommunityContainer>
    );
  } else {
    return (
      <CommunityContainer>
        {!!featured?.length && (
          <Features>
            {featured?.map((article, index) => (
              <Featured key={index} index={index}>
                <ArticlePreview data={article} hover="horizontal" />
              </Featured>
            ))}
          </Features>
        )}

        {categories?.length ? (
          categories.map((category, index) => (
            <ArticleCategories key={index} category={category} />
          ))
        ) : (
          <Message>
            Seems like there are no posts here, click "Add a Post" to change
            that!
          </Message>
        )}
      </CommunityContainer>
    );
  }
};
export default Community;

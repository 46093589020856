import React from 'react';
import Button from 'components/NewLyncworkUIKit/CustomButton';

import placeholder from './placeholder.svg';
import { Container, Title, Tagline, ImageContainer } from './styles';

const WelcomeScreen = ({ setVisited }) => {
  const handleClick = () => setVisited(true);
  return (
    <Container>
      <Title>Customize Your Employees’ Experience</Title>
      <Tagline>Onboard your employees how you’d like to!</Tagline>
      <ImageContainer>
        <img src={placeholder} alt="placeholder" />
      </ImageContainer>
      <Button onClick={handleClick}>Get Started</Button>
    </Container>
  );
};

export default WelcomeScreen;

import styled from 'styled-components';
import colors from 'designSystem/foundations/colors';

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 208px;
`;
export const ButtonSpacer = styled.div`
  width: 16px;
`;
export const ContentStyles = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h2 {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
  }
`;
export const Card = styled.div`
  display: flex;
  text-align: center;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: 304px;
  height: 250px;
  border-radius: 10px;
  background-color: ${colors.neutral.white};
  overflow: hidden;
  box-shadow: 3.81237px 3.81237px 28.5928px rgba(158, 159, 164, 0.4);
`;
export const CardHeading = styled.h3`
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
  margin: 0;
  margin-top: 8px;
  /* identical to box height, or 217% */

  text-align: center;

  /* Off Black */

  color: #1d1d20;
`;
export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.neutral.grey02};
  margin: 8px 0;
`;
export const Value = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 15.2495px;
  line-height: 23px;
  /* identical to box height, or 150% */

  text-align: center;

  /* Grey 05 */

  color: ${colors.neutral.grey05};
  margin: 0;
`;
export const CardContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  box-shadow: none;
  background-color: transparent;
`;
export const ImageContainer = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;
export const ImageBackground = styled.div`
  z-index: 1;
  height: 110%;
  width: 130%;

  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transform: scale(1.1);
  filter: blur(8px);
`;
export const Image = styled.img`
  position: absolute;
  width: 100%;
  height: auto;
  z-index: 2;
  box-shadow: 0px 0px 40px 40px #00000000;
`;
export const Bottom = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  width: 100%;
  height: 25%;
  padding: 0 24px;
  box-sizing: border-box;
`;
export const Heading = styled.h2`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  margin: 8px 0;
  margin-top: 64px;
  /* identical to box height, or 129% */

  text-align: center;
  @media only screen and (max-width: 700px) {
    padding: 0 20px;
  }
`;
export const Paragraph = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 129% */

  text-align: center;
  margin: 0;
  margin-bottom: 32px;
  @media only screen and (max-width: 700px) {
    padding: 0 20px;
  }
`;
export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 232px;
  grid-gap: 1em;

  max-width: 900px;
  @media only screen and (max-width: 1350px) {
    max-width: 600px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 232px 232px;
    grid-gap: 1em;
    padding: 1em 20px;
  }
`;

export const GridCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 32px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
`;
export const GridImage = styled.img`
  width: 80px;
  height: 80px;
`;
export const GridP = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* or 137% */

  text-align: center;

  /* Grey 10 */

  color: #1c1c1e;
`;

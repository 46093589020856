import React from 'react';
import {
  SidebarContentContainer,
  SidebarContentDescription,
  SidebarContentTitle,
  SideBarImg
} from './styles';

import { useLocation } from 'react-router-dom';

const SidebarContent = ({ pageData, mobile }) => {
  const location = useLocation();
  const data = pageData.find(x => x.pathName === location.pathname);

  if (data) {
    return (
      <SidebarContentContainer mobile={mobile}>
        <SidebarContentTitle mobile={mobile}>
          {data.sideBar.title}
        </SidebarContentTitle>
        <SidebarContentDescription mobile={mobile}>
          {data.sideBar.description}
        </SidebarContentDescription>
        <SideBarImg mobile={mobile} src={data.sideBar.imgSrc} />
      </SidebarContentContainer>
    );
  } else {
    return (
      <SidebarContentContainer>
        <SidebarContentTitle>Sorry, we cant find that data</SidebarContentTitle>
      </SidebarContentContainer>
    );
  }
};
export default SidebarContent;

import React from 'react';
import useAuthentication from 'hooks/useAuthentication';
import { Tagline, Description } from '../styles';

const WelcomeDescription = () => {
  const { user } = useAuthentication();
  const companyName = user.company?.companyName || 'the team';
  return (
    <>
      <Tagline>Welcome to {companyName}</Tagline>
      <Description>
        We are so excited to have you on board. Please take some time to learn
        more about us!
      </Description>
    </>
  );
};

export default WelcomeDescription;
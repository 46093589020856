import React from 'react';
import {
  PostListItemContainer,
  PostListItemContent,
  PostListItemDescription,
  PostListItemIcon,
  PostListItemTitle,
  PostListItemUpdatedAt
} from './styles';

const PostListItem = ({ icon, title, description, updatedAt, path }) => {
  return (
    <PostListItemContainer to={path}>
      {icon &&
        <PostListItemIcon><img src={icon} alt="post list icon" /></PostListItemIcon>
      }
      <PostListItemContent>
        <PostListItemTitle>{title}</PostListItemTitle>
        <PostListItemDescription>{description}</PostListItemDescription>
        <PostListItemUpdatedAt>Updated: {updatedAt}</PostListItemUpdatedAt>
      </PostListItemContent>
    </PostListItemContainer>
  );
};

export default PostListItem;

import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useButtonTrack } from 'matomo/hooks';
import { CustomButton } from 'components/NewLyncworkUIKit';
import { userFileImportPageEvents } from 'pages/Users/UsersListPage/instrumentationEvents';

import useModal from 'hooks/useModal';
import importImage from '../../assets/importImage.svg';
import cloud from '../../assets/cloud.svg';
import importTemplate from './importTemplate.csv';
import FileCard from './FileCard';
import PreviewModal from './PreviewModal';

import {
  Container,
  ImportImage,
  Title,
  Paragraph,
  ButtonContainer,
  Button,
  ZoneContainer,
  DropZoneContainer
} from './styles';

const FileDropZone = ({ getRootProps, getInputProps }) => {
  return (
    <ZoneContainer>
      <DropZoneContainer {...getRootProps()}>
        <input {...getInputProps()} />
        <img src={cloud} alt="" />
        Drag and drop, or click to upload
      </DropZoneContainer>
    </ZoneContainer>
  );
};
const { see_example, download_template } = userFileImportPageEvents;

const FileUpload = ({
  onDrop,
  file,
  handleFileRemove,
  nextPage,
  previousPage
}) => {
  const trackButton = useButtonTrack();
  const { isOpen, openModal, closeModal } = useModal();
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.csv'
  });
  const handleSeeExample = () => {
    openModal();
    trackButton(see_example);
  };

  const onTemplateDownload = () => {
    trackButton(download_template);
  };
  return (
    <>
      <PreviewModal isOpen={isOpen} handleClose={closeModal} />
      <Container>
        <ImportImage src={importImage} alt=" " />
        <Title>File Requirements</Title>
        <Paragraph>
          Your CSV file must contain all of the mandatory fields marked by
          <strong> * </strong>. Please refer to our example below or{' '}
          <a
            href={importTemplate}
            download="import-template.csv"
            onClick={onTemplateDownload}
          >
            download import template
          </a>
        </Paragraph>
        <Button variant="secondary" size="medium" onClick={handleSeeExample}>
          See Example
        </Button>
        <Title>Upload Your CSV File</Title>
        {file ? (
          <FileCard file={file} handleFileRemove={handleFileRemove} />
        ) : (
          <FileDropZone
            getRootProps={getRootProps}
            getInputProps={getInputProps}
          />
        )}
      </Container>
      <ButtonContainer>
        <CustomButton variant="secondary" onClick={previousPage}>
          Cancel
        </CustomButton>
        <CustomButton disabled={!file} onClick={nextPage}>
          Next
        </CustomButton>
      </ButtonContainer>
    </>
  );
};

export default FileUpload;

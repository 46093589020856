import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import PortalContainer from 'components/PortalContainer';
import TrapFocus from 'components/TrapFocus';

import csvPreview from './csvPreview.png';

import {
  Container,
  TopBar,
  ContentContainer,
  RightSection,
  CloseButtonContainer
} from './styles';

const PreviewModal = ({ isOpen, handleClose }) => {
  return (
    <PortalContainer isOpen={isOpen}>
      <TrapFocus>
        <Container>
          <TopBar>
            Example Preview
            <CloseButtonContainer onClick={handleClose}>
              <CloseIcon />
            </CloseButtonContainer>
          </TopBar>
          <ContentContainer>
            <img src={csvPreview} alt="csv preview" />
          </ContentContainer>
        </Container>
      </TrapFocus>
    </PortalContainer>
  );
};

export default PreviewModal;

import React from 'react';

import {
  CardContainer,
  CustomButton,
  CardInfoContainer,
  Title,
  Description,
  CheckMarkContainer,
  CheckedContainer,
  ButtonContainer
} from './styles';

const DocumentCard = ({ title, description }) => {
  return (
    <CardContainer>
      <CardInfoContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </CardInfoContainer>
      <CheckedContainer>
        <CheckMarkContainer />
        Signature Required
      </CheckedContainer>
      <ButtonContainer>
        <CustomButton>Sign</CustomButton>
      </ButtonContainer>
    </CardContainer>
  );
};

export default DocumentCard;

import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ArrowBack } from '@material-ui/icons';
import emptyState from 'assets/images/GenericIcons/empty state.svg';

import { RoleService } from 'services/role.service';

import { RoleForm } from './components';
import {
  SingleRolePageContainer,
  BackButtonContainer,
  BackButton,
  BlankContainer
} from './styles';

const LoadingScreen = () => (
  <BlankContainer>
    <CircularProgress />
  </BlankContainer>
);

const ErrorScreen = () => {
  return (
    <BlankContainer>
      <img src={emptyState} alt="empty state" />
      Oops, something went wrong
    </BlankContainer>
  );
};

const RolesEditPage = () => {
  const { id } = useParams();
  const history = useHistory();
  const [pageState, setPageState] = useState({
    roleDTO: null,
    loading: true,
    error: false
  });
  const { roleDTO, error, loading } = pageState;
  useEffect(() => {
    const getRole = async () => {
      try {
        const response = await RoleService.find(id);
        setPageState({ roleDTO: response.data, loading: false, error: false });
      } catch (error) {
        console.log(error);
        setPageState({ roleDTO: null, loading: false, error: true });
      }
    };
    getRole();
  }, []);

  return (
    <SingleRolePageContainer>
      <BackButtonContainer>
        <BackButton
          variant="secondary"
          size="small"
          onClick={() => history.push('/admin-roles')}
        >
          <ArrowBack fontSize="inherit" /> Back
        </BackButton>
      </BackButtonContainer>
      {loading ? (
        <LoadingScreen />
      ) : error ? (
        <ErrorScreen />
      ) : (
        <RoleForm roleDTO={roleDTO} />
      )}
    </SingleRolePageContainer>
  );
};

export default RolesEditPage;

import styled from 'styled-components';
import { Avatar } from 'components/NewLyncworkUIKit';

export const Card = styled.div`
  position: relative;
  overflow: hidden;
  width: 176px;
  height: 246px;
  margin: 12px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.neutral.white};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
`;

export const StyledAvatar = styled(Avatar)`
  width: 80px;
  height: 80px;
  margin: 0;
`;

export const AvatarContainer = styled.div`
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.theme.typography.title2};
  font-weight: 600;
`;

export const Name = styled.h1`
  text-align: center;
  ${props => props.theme.typography.header3};
  font-weight: 600;
  margin: 16px 8px 8px;
`;

export const Role = styled.span`
  margin: 0;
  ${props => props.theme.typography.control};
  font-weight: 600;
  color: ${props => props.theme.colors.neutral.grey07};
`;

export const LinkContainer = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${props => props.theme.colors.neutral.grey02};
`;

export const CustomLink = styled.a`
  width: 24px;
  height: 24px;
  margin: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${props => props.src});
  background-size: contain;
  cursor: pointer;
`;

export const LinkedInLink = styled.a`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 24px;
  height: 24px;
  line-height: 0;
  border-radius: 50%;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

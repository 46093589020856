import React from 'react';

import DeleteButton from 'components/DeleteButton';
import Button from 'components/LoadingButton';

import { useRoleForm } from '../../RoleFormContext';

import { ButtonContainer } from './styles';

const RoleFormButtons = () => {
  const {
    roleId,
    loadingType,
    handleSave,
    handleDiscard,
    handleDelete
  } = useRoleForm();
  const canDelete = roleId !== null;
  const isDeleting = loadingType === 'delete';
  const isSaving = loadingType === 'save';
  return (
    <ButtonContainer>
      {canDelete && (
        <DeleteButton
          loading={isDeleting}
          size="small"
          onClick={handleDelete}
          spinnerSize="12px"
        >
          Delete Role
        </DeleteButton>
      )}
      <Button
        className="role-discard"
        variant="secondary"
        onClick={handleDiscard}
      >
        Discard
      </Button>
      <Button loading={isSaving} onClick={handleSave}>
        Save
      </Button>
    </ButtonContainer>
  );
};

export default RoleFormButtons;

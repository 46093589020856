import React from 'react';

import imageIntro from 'assets/images/Onboarding/Intro.svg';
import { IconButton as CloseButton } from 'components/NewLyncworkUIKit';
import { PromptContainer, Row, NextButton } from './styles';

const Prompt = ({
  clickElement,
  exitTutorial,
  handleNext,
  prompt,
  stepNumber,
  totalSteps,
  promptStyle
}) => {
  const { title, body } = prompt;
  return (
    <PromptContainer style={promptStyle}>
      {stepNumber === 1 && <img src={imageIntro} alt="123" />}
      <Row>
        <h3>{title}</h3>{' '}
        <CloseButton className="close-button" onClick={exitTutorial} />
      </Row>
      <p>{body}</p>
      <Row>
        <span className="step-counter">
          Step {stepNumber}/{totalSteps}
        </span>
        {!clickElement && (
          <NextButton size="small" onClick={handleNext}>
            Next
          </NextButton>
        )}
      </Row>
    </PromptContainer>
  );
};

export default Prompt;

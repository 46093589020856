import React from 'react';
import {
  Container,
  StyledAvatar,
  DetailContainer,
  Name,
  RolesContainer,
  RoleTag
} from './styles';

const UserCard = ({ user }) => {
  const { id, avatar, firstName, lastName, roles } = user;
  const src = avatar?.url;
  const name = `${firstName} ${lastName}`;
  const rolesToDisplay = roles.slice(0, 3);
  return (
    <Container>
      <StyledAvatar src={src} colorKey={id} name={firstName} />
      <DetailContainer>
        <Name>{name}</Name>
        <RolesContainer>
          {rolesToDisplay.map(role => (
            <RoleTag key={role.id}>{role.roleName}</RoleTag>
          ))}
        </RolesContainer>
      </DetailContainer>
    </Container>
  );
};

export default UserCard;

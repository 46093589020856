import styled from 'styled-components';

export const ActionCell = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 40px;
  box-sizing: border-box;
  margin: 0 24px;
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey07};
`;

import React from 'react';
import { SwiperSlide } from 'swiper/react';

import CustomCoverflow from './CoverFlow';
import {
  CardContainer,
  Card,
  ImageContainer,
  Image,
  ImageBackground,
  Bottom,
  CardHeading
} from './styles';

const DesktopCarousel = ({ data }) => {
  return (
    <CustomCoverflow>
      {data.map(reward => (
        <SwiperSlide key={reward.id}>
          <CardContainer>
            <Card className="Card">
              <ImageContainer>
                <Image src={reward.image} />
                <ImageBackground src={reward.image} />
              </ImageContainer>
              <Bottom>
                <CardHeading>{reward.title}</CardHeading>
              </Bottom>
            </Card>
          </CardContainer>
        </SwiperSlide>
      ))}
    </CustomCoverflow>
  );
};

export default DesktopCarousel;

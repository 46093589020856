import React from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import { DropdownService } from 'services';
import {
  Container,
  FilterIconLabel,
  RadioButton,
  StyledSelect
} from './styles';

const loadTeams = async inputValue => {
  const response = await DropdownService.teams({ name: inputValue });
  return response.data.content;
};
const loadRoles = async inputValue => {
  const response = await DropdownService.roles({ name: inputValue });
  return response.data.content;
};

const Select = ({ assignmentType, roles, teams, changeParams }) => {
  const isTeamSelected = assignmentType === 'TEAM';
  const value = isTeamSelected ? teams : roles;
  const loadOptions = isTeamSelected ? loadTeams : loadRoles;
  const paramName = isTeamSelected ? 'teams' : 'roles';
  const onChange = value => changeParams({ [paramName]: value });
  return (
    <StyledSelect
      isClearable
      loadOptions={loadOptions}
      defaultOptions
      value={value}
      onChange={onChange}
    />
  );
};

const Filter = ({
  teams,
  roles,
  assignmentType,
  handleAssignmentChange,
  changeParams
}) => {
  return (
    <Container>
      <FilterListIcon />
      <FilterIconLabel>Filter: </FilterIconLabel>
      <RadioButton
        label="All"
        onChange={handleAssignmentChange}
        checked={assignmentType === ''}
        value=""
      />
      <RadioButton
        label="By Roles"
        onChange={handleAssignmentChange}
        checked={assignmentType === 'ROLE'}
        value="ROLE"
      />
      <RadioButton
        label="By Teams"
        onChange={handleAssignmentChange}
        checked={assignmentType === 'TEAM'}
        value="TEAM"
      />
      {assignmentType && (
        <Select
          key={assignmentType}
          assignmentType={assignmentType}
          roles={roles}
          teams={teams}
          changeParams={changeParams}
        />
      )}
    </Container>
  );
};

export default Filter;

import styled from 'styled-components';

import { CustomTextInput } from 'components/NewLyncworkUIKit';
import Card from 'components/Card';

export const Container = styled(Card)`
  flex-grow: 1;
  box-sizing: border-box;
  padding: 32px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  button[type='submit'] {
    width: 200px;
    align-self: flex-start;
  }
`;

export const SectionTitle = styled.h2`
  flex-basis: 100%;
  ${props => props.theme.typography.header2};
  font-weight: 600;
  margin: 0 0 -8px;
`;

export const FormSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100%;
  gap: 24px;
  padding: 32px 0;
  border-top: 1px solid ${props => props.theme.colors.neutral.grey02};
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.medium}) {
    &:nth-child(2) {
      border-top: none;
      padding-top: 0;
    }
  }
`;

export const AvatarSectionContainer = styled(FormSection)`
  flex-direction: row;
  gap: 32px;
  padding: 0 0 32px 0;
  border-top: none;
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.medium}) {
    display: none;
  }
`;

export const ThreeWordsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  flex-basis: 100%;
  box-sizing: border-box;

  label.three-words-label {
    flex-basis: 100%;
    margin: 0;
  }
`;

export const ThreeWordsInput = styled(CustomTextInput)`
  label {
    font-weight: 400;
    margin: 4px 0;
    color: ${props => props.theme.colors.neutral.grey07};
  }
  flex-basis: calc((100% - 48px) / 3);
  @media only screen and (max-width: ${props =>
      props.theme.breakPoints.medium}) {
    flex-basis: 100%;
  }
`;

ThreeWordsInput.defaultProps = { width: '30%;' };

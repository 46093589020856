import styled from 'styled-components';
import { orange, white } from 'StyleVariables/LyncworkColors';
export const Drop = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #f4f5f7;
  border-radius: 4px;
  min-height: 150px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='grey' stroke-width='2' stroke-dasharray='12' stroke-dashoffset='12' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 4px;
  height: 100%;

  cursor: pointer;
  transition: all 0.3s;
  :hover {
    background-color: #e1e4ea;
  }
  :focus {
    outline: none;
  }
`;
export const Hints = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.155556px;
  height: 100%;
  color: #66707f;
`;
export const Img = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;
export const Preview = styled.div`
  width: 100%;
  height: 200px;
  border-radius: 4px;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
`;
export const PreviewContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  max-width: 450px;
  height: 200px;
`;
export const DeleteContainer = styled.div`
  display: flex;
  position: absolute;
  right: 10px;
  top: 10px;
  transition: all 0.3s;
  background-color: ${white};
  border: 1px solid ${orange};
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    transform: scale(1.1);
  }
`;
export const RelativeContainer = styled.div`
  position: relative;
  display: flex;
  z-index: 0;
  width: 100%;
  max-width: 450px;
  height: 200px;
  border-radius: 4px;
`;
export const PromptContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
`;
export const Title = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.2px;
  margin: 0;
  padding-bottom: 1em;
  color: #8998aa;
`;

export const companyBasicInfoEvents = {
  open_edit_modal: 'admin documents open basic info modal',
  close_edit_modal: 'admin documents close basic info modal',
  discard_changes: 'admin documents discard basic info changes',
  save_changes: 'admin documents save basic info',
  avatar_click: 'admin documents click avatar upload',
  banner_click: 'admin documents click banner upload'
};

export const companyAboutEvents = {
  open_edit_modal: 'admin documents open about edit modal',
  close_edit_modal: 'admin documents close about edit modal',
  discard_changes: 'admin documents discard about changes',
  save_changes: 'admin documents save about'
};

import styled from 'styled-components';

import { Link } from 'react-router-dom';

import colors from 'designSystem/foundations/colors';
import {
  buttonSizes,
  buttonVariants
} from 'components/NewLyncworkUIKit/CustomButton/styles';
import Card from 'components/Card';

export const SliderContainer = styled.div`
  position: relative;
  height: 200px;
  .slick-track {
    height: 170px !important;
    overflow-y: hidden;
  }
  .slick-prev:before,
  .slick-next:before {
    color: ${colors.neutral.grey07};
  }
`;

export const AppContainer = styled(Card)`
  position: relative;
  height: 170px;
  padding: 16px 24px;
  margin: 0 2px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: ${props => props.background};
  img {
    position: absolute;
    width: 300px;
    height: 300px;
    top: -30px;
    right: -32px;
  }
`;

export const AppDesc = styled.span`
  ${props => props.theme.typography.header2};
  font-weight: 600;
  color: ${props => props.color};
`;

export const AppName = styled.h2`
  ${props => props.theme.typography.title1};
  font-weight: 600;
  color: ${props => props.color};
  margin: 0;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  margin: auto 0 0;
  align-self: flex-start;

  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  cursor: pointer;
  outline: none;
  gap: 8px;
  border-radius: 100px;
  box-sizing: border-box;

  color: ${props => props.color};
  background-color: ${props => props.background};
  ${buttonSizes.small}
  &:focus-visible {
    outline: 2px solid #0078d4;
  }
`;

StyledLink.defaultProps = { background: '#FFFFFF' };

import generateMediaUrl from 'utilities/generateMediaUrl';
import officeLogo from './office.svg';

export const appPackList = [
  {
    id: 8,
    appName: 'Office 365',
    description: 'Number One Rated Productivity App.',
    src: officeLogo,
    colors: {
      background: '#5F2929',
      linkText: '#5F2929'
    }
  },
  {
    id: 2,
    appName: 'Slack',
    description: 'Communication Application of the Year',
    src: generateMediaUrl('slack.svg'),
    colors: {
      background: '#1E2F69',
      linkText: '#1E2F69'
    }
  },
  {
    id: 1,
    appName: 'G Suite',
    description: 'How teams of all sizes connect, create, and collaborate.',
    src: generateMediaUrl('gsuite.svg'),
    colors: {
      background: '#F0F7FF',
      descColor: '#409CFF',
      appNameColor: '#000000',
      linkText: '#FFFFFF',
      linkBackground: '#000000'
    }
  }
];

export const sliderSetting = {
  className: 'slider',
  dots: true,
  arrows: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1
};

import React, { useState } from 'react';
import Select, { components } from 'react-select'; //super easy way to do dropdowns in react
import CustomSelect from '../CustomSelect';
import colors from 'designSystem/foundations/colors';
import check from './assets/check.svg';

import {
  colorOptions,
  errorColor,
  defaultBackground,
  disabledBackground,
  borderHoverColor,
  defaultBorderColor,
  Container,
  Label,
  SubLabel
} from './styles';
const { Option } = components;
const IconOption = props => {
  return (
    <Option
      {...props}
      style={{
        display: 'flex',

        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      {props.data.label}
      {props.isSelected && (
        <img
          src={check}
          style={{
            position: 'absolute',
            width: 12,
            height: 12,
            right: '8px',

            alignSelf: 'center'
          }}
          alt={props.data.label}
        />
      )}
    </Option>
  );
};
const CustomDropdown = ({
  values,
  paginatedCallback,
  id,
  name,
  label = '',
  subLabel = '',
  placeholder = label,
  isMulti = false,
  onChange,
  DefaultValue,
  inputRef,
  minWidth = '100px',
  maxWidth,
  minHeight = '43px',
  error = false,
  openUp = false,
  required = false
}) => {
  const [page, setPage] = useState(0);
  async function loadOptions(search, loadedOptions, { page }) {
    //Paginated API must be passed for async loading.

    const { data } = await paginatedCallback(search, page, 5);
    setPage(page + 1);
    return {
      options: data.content,
      hasMore: !data.last,
      additional: {
        page: page + 1
      }
    };
  }
  let formattedValues = [];
  if (values[0] && values[0].value && values[0].label) {
    formattedValues = values;
  }
  if (values[0] && !!values[0].props) {
    values.forEach(item =>
      formattedValues.push({
        value: item.props.value,
        label: item.props.value
      })
    );
  } else if (values[0] && typeof values[0] === 'string') {
    values.forEach(item =>
      formattedValues.push({
        value: item,
        label: item
      })
    );
  } else if (values[0] && Object.keys(values[0]).includes('value')) {
    formattedValues = values;
  }
  return (
    <Container>
      {label && (
        <Label>
          {label}
          {required && <span>*</span>}
        </Label>
      )}
      <Select
        onChange={onChange}
        isMulti={isMulti}
        id={id}
        name={name}
        label={label}
        loadOptions={loadOptions}
        options={formattedValues}
        defaultValue={DefaultValue}
        placeholder={placeholder}
        ref={inputRef}
        components={{ Option: IconOption }}
        additional={{ page: 1 }}
      />
      {subLabel && <SubLabel error={error}>{subLabel}</SubLabel>}
    </Container>
  );
};
export default CustomDropdown;

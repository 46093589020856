import React from 'react';

import generateMediaUrl from 'utilities/generateMediaUrl';
import { Avatar, CustomButton } from 'components/NewLyncworkUIKit';

import { ApplicationContainer } from './styles';

const ApplicationCard = ({ application, onClick }) => {
  const { appName, id, appLogo } = application;
  const src = generateMediaUrl(appLogo);
  return (
    <ApplicationContainer>
      <Avatar src={src} name={appName} colorKey={id} size="32px" />
      <span>{appName}</span>
      <CustomButton variant="secondary" size="small" onClick={onClick}>
        Edit
      </CustomButton>
    </ApplicationContainer>
  );
};

export default ApplicationCard;

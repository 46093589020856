import React, { useState } from 'react';
import {
  linkedInIcon,
  mediumIcon,
  twitterIcon,
  flipIcon
} from 'assets/images/Onboarding/Team';

import {
  FlipCard,
  FlipCardInner,
  FlipCardFront,
  FlipCardBack,
  AvatarContainer,
  Name,
  Role,
  LinkContainer,
  CustomLink,
  FlipContainer,
  CardBackContentContainer,
  CardBackWords,
  CardBackWordsHeader,
  Quote
} from './styles';

const formatUrl = url => {
  const removeHttps = url.replace('https://', '').replace('http://');
  return `https://${removeHttps}`;
};

const Flip = ({ handleToggle }) => {
  return (
    <FlipContainer onClick={handleToggle} type="button">
      <img src={flipIcon} /> Click to flip
    </FlipContainer>
  );
};

const CardFront = ({ user, handleToggle }) => {
  const { firstName, lastName, avatar, linkedinUrl, mediumUrl, roles } = user;
  const avatarUrl = avatar?.url;
  const firstInitial = firstName.toUpperCase()[0];
  const name = `${firstName} ${lastName}`;
  const roleName = roles?.[0]?.roleName || '';
  return (
    <FlipCardFront>
      <AvatarContainer>
        {avatarUrl ? <img src={avatarUrl} /> : firstInitial}
      </AvatarContainer>
      <Name>{name}</Name>
      <Role>{roleName}</Role>
      <LinkContainer>
        {linkedinUrl && (
          <CustomLink
            onClick={e => e.stopPropagation()}
            src={linkedInIcon}
            href={formatUrl(linkedinUrl)}
            target="_blank"
            rel="noopener noreferrer"
          />
        )}
        {mediumUrl && (
          <CustomLink
            onClick={e => e.stopPropagation()}
            src={mediumIcon}
            href={formatUrl(mediumUrl)}
            target="_blank"
            rel="noopener noreferrer"
          />
        )}
      </LinkContainer>
      <Flip handleToggle={handleToggle} />
    </FlipCardFront>
  );
};

const CardBack = ({ descriptions, handleToggle }) => {
  return (
    <FlipCardBack>
      <CardBackContentContainer>
        <Quote />
        <CardBackWordsHeader>I am:</CardBackWordsHeader>
        {descriptions.map((word, index) => {
          return <CardBackWords key={index}>{word}</CardBackWords>;
        })}
        <Flip handleToggle={handleToggle} />
      </CardBackContentContainer>
    </FlipCardBack>
  );
};

const TeamMemberCard = ({ user, toggleFlip, flip }) => {
  const { id } = user;
  const descriptions = user.userDescription?.descriptions || [
    'word 1',
    'word 2',
    'word 3'
  ];

  const handleToggle = () => toggleFlip(id);
  return (
    <FlipCard>
      <FlipCardInner flip={flip}>
        <CardFront user={user} handleToggle={handleToggle} />
        <CardBack descriptions={descriptions} handleToggle={handleToggle} />
      </FlipCardInner>
    </FlipCard>
  );
};

export default TeamMemberCard;

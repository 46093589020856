import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { JourneyService } from 'services';
import { CustomButton } from 'components/NewLyncworkUIKit';
import {
  Container,
  HeaderContainer,
  JourneysContainer,
  TableRow,
  ProgressBar
} from './styles';

const JourneyTableHeader = () => {
  return (
    <TableRow className="header">
      <div className="name">Name</div>
      <div className="description">Description</div>
      <div className="view"></div>
    </TableRow>
  );
};

const JourneyRow = ({ journey }) => {
  const { workflowName, workflowDesc, id } = journey;
  const path = `/admin-journeys/edit/${id}`;
  return (
    <TableRow>
      <div className="name">{workflowName}</div>
      <div className="description">{workflowDesc}</div>
      <Link className="view" to={path}>
        <CustomButton tabIndex="-1" variant="secondary" size="small">
          View
        </CustomButton>
      </Link>
    </TableRow>
  );
};

const Journeys = () => {
  const [state, setState] = useState({ loading: true, journeys: [] });
  const { journeys, loading } = state;
  const hasJourneys = journeys.length > 0;

  useEffect(() => {
    let mounted = true;
    const fetch = async () => {
      try {
        const response = await JourneyService.list({
          size: 5
        });
        const fetchedJourneys = response.data.content;
        mounted && setState({ loading: false, journeys: fetchedJourneys });
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Container>
      <HeaderContainer>
        <h1>Journeys</h1>
        <Link to="/admin-journeys">
          <CustomButton tabIndex="-1" variant="secondary" size="small">
            Manage Journeys
          </CustomButton>
        </Link>
      </HeaderContainer>
      <JourneysContainer>
        <JourneyTableHeader />
        {loading ? (
          <CircularProgress />
        ) : hasJourneys ? (
          journeys.map(journey => (
            <JourneyRow key={journey.id} journey={journey} />
          ))
        ) : (
          <div className="empty-message">
            You don't have any Journeys coming up.
          </div>
        )}
      </JourneysContainer>
    </Container>
  );
};

export default Journeys;

import React from 'react';
import { LocaleService } from 'services';

import { useAccountDetailsForm } from '../accountDetailsFormContext';

import SelectField from './SelectField';
import TextField from './TextField';

const countryOptions = LocaleService.countries().map(name => ({
  label: name,
  value: name
}));
const getStateOptions = countryName => {
  const stateOptions = LocaleService.states(countryName).map(name => ({
    label: name,
    value: name
  }));
  return stateOptions;
};

const fieldLabels = {
  emailSecondary: 'Personal Email',
  phonePrimary: 'Primary Phone',
  phoneSecondary: 'Secondary Phone',
  country: 'Country',
  lineOne: 'Address Line One',
  lineTwo: 'Address Line Two',
  state: 'State',
  city: 'City',
  postalCode: 'Postal Code',
  linkedinUrl: 'LinkedIn',
  mediumUrl: 'Medium'
};

const FormField = ({ name, className, required }) => {
  const { formInfo, updateForm, formError } = useAccountDetailsForm();

  const label = fieldLabels[name];
  if (name === 'country') {
    const options = countryOptions;
    return <SelectField name={name} label={label} options={options} required />;
  }
  if (name === 'state') {
    const country = formInfo.country || 'United States';
    const options = getStateOptions(country);
    return <SelectField name={name} label={label} options={options} required />;
  }

  return (
    <TextField
      name={name}
      label={label}
      className={className}
      required={required}
    />
  );
};
export default FormField;

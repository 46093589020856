import axios from 'axios';
import env from '../env';
import {
  setGetUser,
  setClearAuth,
  setLogin
} from '../redux/authentication/authentication.actions';
import { store } from '../redux/store';
const baseUrl = `${env.frontDoorDomain}`;

export const AUTH_TOKEN_KEY = 'lw-authentication';

export const AuthenticationService = {
  authorize: data => {
    AuthenticationService.removeToken();
    const result = axios.post(`${baseUrl}/api/authenticate`, data).then(res => {
      AuthenticationService.setToken(res.data.id_token);
      return res;
    });
    return result;
  },

  authenticateByToken: () => {
    return axios.post(`${env.storeDomain}/api/authenticate/token`);
  },

  updatePassword: newPassword =>
    axios.post(`${baseUrl}/api/account/change-password`, {
      newPassword
    }),

  forgotPassword: email =>
    axios.post(`${baseUrl}/client/account/reset-password/init`, {
      email
    }),

  resetPassword: newPassword =>
    axios.post(`${baseUrl}/client/account/reset-password/finish`, {
      newPassword
    }),

  setToken: token => {
    store.dispatch(setLogin({ id_token: token }));
    sessionStorage.setItem(AUTH_TOKEN_KEY, token);
  },
  getToken: () => sessionStorage.getItem(AUTH_TOKEN_KEY),
  removeToken: () => sessionStorage.removeItem(AUTH_TOKEN_KEY),
  logout: () => {
    AuthenticationService.removeToken();
    store.dispatch(setGetUser({}));
    store.dispatch(setClearAuth());
  }
};

import axios from 'axios';
import env from '../env';
import generateMediaUrl from 'utilities/generateMediaUrl';
const { serviceDomain, storeDomain } = env;

const integrationList = ['google', 'office365'];

export const UserImportService = {
  upload: users => {
    return axios.post(`${serviceDomain}/user-import/api/users`, users);
  },
  listApps: async () => {
    const response = await axios.get(
      `${env.storeDomain}/api/subscription-apps?page=0&size=100`
    );
    const allSubscriptions = response.data.content;
    const validSubscriptions = allSubscriptions.filter(({ application }) => {
      return integrationList.includes(application.appAPIName);
    });
    const applications = validSubscriptions.map(subscription => {
      const {
        application: { appAPIName, appLogo, id, appName },
        licenseManagements
      } = subscription;
      return {
        appAPIName,
        id,
        logoUrl: generateMediaUrl(appLogo),
        appName,
        licenseManagements
      };
    });
    return applications;
  },
  google: params => {
    const paramString = new URLSearchParams(params).toString();
    return axios.get(`${storeDomain}/api/google/users?${paramString}`);
  },
  office365: params => {
    const paramString = new URLSearchParams(params).toString();
    return axios.get(`${serviceDomain}/office365/api/users?${paramString}`);
  },
  bamboohr: () => {
    return axios.get(`${serviceDomain}/bamboohr/api/users`);
  }
};

import styled, { css } from 'styled-components';
import backgroundClip from './background-clip.svg';
import Button from 'components/NewLyncworkUIKit/CustomButton';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  &:before {
    position: absolute;
    right: -24px;
    width: calc(100% + 48px);
    height: 100%;
    background-image: url(${backgroundClip});
    background-size: contain;
    background-repeat: no-repeat;
    content: '';
  }
`;

export const ContentContainer = styled.div`
  position: relative;
  z-index: 2;
  padding: 48px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  ${props => props.theme.typography.header1};
  margin: 0;
  font-weight: 600;
  color: ${props => props.color || 'white'};
`;
export const Tagline = styled.h1`
  ${props => props.theme.typography.body};
  margin: 8px 0;
  color: ${props => props.color || 'white'};
`;

const alternateStyle = css`
  background-color: ${props => props.theme.colors.neutral.white};
  color: ${props => props.theme.colors.brand.primary};
  &:hover {
    background-color: ${props => props.theme.colors.neutral.white};
    box-shadow: 0px 3px 10px rgba(28, 28, 30, 0.3);
  }
`;

export const CustomButton = styled(Button)`
  ${alternateStyle};
  width: 128px;
  margin: 16px 0 24px;
`;

export const ImageContainer = styled.div`
  width: 800px;
  max-width: 80%;
  margin: 44px 0;
  box-shadow: 0px 2.875px 9.58333px rgba(186, 186, 186, 0.3);
  border-radius: 10px;
  line-height: 0;
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  width: 840px;
  max-width: 100%;
  margin: 44px 0;
  justify-content: space-around;
  flex-wrap: wrap;
`;

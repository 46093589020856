import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 180px;
  background-color: white;
  box-shadow: -10px 9px 21px #f4f5f7;
  border-radius: 4px;
  padding-top: 36px;
  margin-bottom: 30px;
`;

export const BoardTitle = styled.h1`
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  color: #4d4d4d;
`;

export const WinnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ff6b6b;
  color: white;
  padding: 0.8vw 0;
  box-sizing: border-box;
`;

export const Avatar = styled.img`
  margin-bottom: 10px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  background-color: white;
`;

export const WinnerName = styled.h2`
  font-weight: 500;
  margin: 0;
  font-size: 16px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const WinnerPoints = styled.span`
  text-align: center;
  font-weight: 300;
`;

export const LeaderListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 10px 20px;
`;

export const LeaderContainer = styled.div`
  padding: 15px 5%;
  flex: 1;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #4d4d4d;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #f4f5f7;
    span {
      font-weight: 600;
    }
  }
`;

export const LeaderName = styled.span`
  font-weight: 400;
  margin: 0 10px;
`;

import React, { useEffect, useState, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import {
  ErrorText,
  Users,
  RightSideBar,
  UserContainer,
  UserName,
  InfoContainer,
  UserEmail,
  StyledButton,
  AddSelectedButton,
  Loader,
  Added,
  ExitButton,
  Header
} from './styles';
import { CustomSearch, ImageCircle } from 'components/NewLyncworkUIKit';
import X from 'assets/images/GenericIcons/X.svg';
import { UserService } from 'services/user.service';
import { useButtonTrack } from 'matomo/hooks';

import { newTeamPageEvents } from 'pages/Teams/teamsInstrumentationEvents';

const arr = [
  'id',
  'firstName',
  'lastName',
  'roles',
  'teams',
  'manager',
  'mentor',
  'emailPrimary',

  'avatar'
];
const UsersComponent = ({
  selectedRole,
  setAddedUsers,
  addedUsers,
  existingUsers,
  closeModal
}) => {
  const trackButton = useButtonTrack();

  const [error, setError] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [searchParam, setSearchParam] = useState('');
  const [hasMore, setHasMore] = useState(false);
  const pageRef = useRef(0);
  const loadFunc = page => {
    pageRef.current++;
    getUsersByRole(selectedRole, pageRef.current);
  };
  const getUsersByRole = async (
    role = selectedRole,
    page = 0,
    search = searchParam
  ) => {
    try {
      const { data } = await UserService.list(
        search.trim(),
        page,
        10,
        arr,
        '',
        role.id
      );
      setUsers(old => [...old, ...data.content]);
      setHasMore(!data.last);
    } catch (err) {
      console.log(err);
      setError(err);
    }
  };
  useEffect(() => {
    setSearchParam('');
    setUsers([]);
    getUsersByRole(selectedRole);
    pageRef.current = 0;
  }, [selectedRole]);
  const handleOnSearch = () => {
    pageRef.current = 0;
    setUsers([]);
    getUsersByRole(selectedRole, pageRef.current);
  };
  const usersRef = useRef();

  const handleAddingUsers = () => {
    if (selectedUsers.length) {
      setAddedUsers(selectedUsers);
      trackButton(newTeamPageEvents.add_selected_users);
      closeModal();
    }
  };
  return (
    <RightSideBar>
      <Header>
        <CustomSearch
          placeholder="Search Users"
          offWhite
          width="100%"
          value={searchParam}
          onChange={e => setSearchParam(e.target.value)}
          onSearch={handleOnSearch}
        />
        <ExitButton onClick={closeModal} src={X} />
      </Header>

      {error && <ErrorText>Something has gone wrong</ErrorText>}

      <Users ref={usersRef}>
        <InfiniteScroll
          pageStart={0}
          loadMore={loadFunc}
          hasMore={hasMore}
          loader={<Loader key={0} color="primary" size="20px" />}
          useWindow={false}
          getScrollParent={() => usersRef.current}
          initialLoad
          threshold={100}
        >
          {users.map(member => {
            const existingUserIds = [
              ...existingUsers.map(x => x.id),
              ...addedUsers.map(x => x.id)
            ];
            const isMember = existingUserIds.includes(member.id);
            const selectedUserIds = selectedUsers.map(x => x.id);
            const selected = selectedUserIds.includes(member.id);
            return (
              <UserContainer key={member.id}>
                <ImageCircle
                  maxWidth="48"
                  firstName={member.firstName}
                  url={member.avatar?.formats?.thumbnail?.url}
                />
                <InfoContainer>
                  <UserName>
                    {member.firstName} {member.lastName}
                  </UserName>
                  <UserEmail>{member.emailPrimary}</UserEmail>
                </InfoContainer>
                {isMember ? (
                  <Added>Already a member</Added>
                ) : (
                  <StyledButton
                    size="small"
                    selected={selected}
                    variant={selected ? 'ternary' : 'primary'}
                    onClick={() =>
                      selected
                        ? setSelectedUsers(old =>
                            old.filter(x => x.id !== member.id)
                          )
                        : setSelectedUsers(old => [...old, member])
                    }
                  >
                    {selected ? (
                      <>
                        <img src={X} /> Deselect
                      </>
                    ) : (
                      'Select'
                    )}
                  </StyledButton>
                )}
              </UserContainer>
            );
          })}
        </InfiniteScroll>
      </Users>
      <AddSelectedButton onClick={handleAddingUsers}>
        Add Selected ({selectedUsers.length})
      </AddSelectedButton>
    </RightSideBar>
  );
};
export default UsersComponent;

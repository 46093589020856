import React from 'react';
import { Check, ErrorOutline } from '@material-ui/icons';
import { InputWrapper, IconContainer, InputContainer } from './styles';
import { Label, SubLabel } from './Labels';

const CustomTextInput = ({
  required = false,
  type = 'text',
  disabled = false,
  success = false,
  error = false,
  name = '',
  value,
  placeholder = '',
  label = '',
  subLabel = '',
  inputRef,
  wrapperRef,
  width = '100%',
  onFocus,
  onChange,
  onBlur,
  className = '',
  endAdornment = null,
  'data-testid': dataTestId,
  min
}) => {
  return (
    <InputContainer width={width} className={className}>
      {label && (
        <Label>
          {label}
          {required && <span>*</span>}
        </Label>
      )}
      <InputWrapper
        success={success}
        error={error}
        disabled={disabled}
        ref={wrapperRef}
      >
        <input
          data-testid={dataTestId}
          ref={inputRef}
          type={type}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          min={min}
        />
        {endAdornment}
      </InputWrapper>
      {subLabel && <SubLabel error={error}>{subLabel}</SubLabel>}
    </InputContainer>
  );
};

export default CustomTextInput;

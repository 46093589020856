import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import CustomButton from 'components/NewLyncworkUIKit/CustomButton';

import { UserJourneyService } from 'services/userJourneys.services';

import UnderlineLink from 'components/UnderlineLink';

import {
  Container,
  Header,
  ButtonContainer,
  JourneysListContainer,
  GridItem,
  FetchStateContainer
} from './styles';
import { JourneyGridCard } from './components';

const FetchingState = ({ loading, hasMore, loadMore }) => {
  if (!loading && !hasMore) {
    return null;
  }

  return (
    <FetchStateContainer>
      {loading ? (
        <CircularProgress />
      ) : (
        <CustomButton onClick={loadMore}>Load More</CustomButton>
      )}
    </FetchStateContainer>
  );
};

const size = 10;
const initialFetchState = {
  page: 0,
  completed: false,
  journeys: [],
  hasMore: true,
  loading: true
};

const UserJourneyListPage = () => {
  const [fetchState, setFetchState] = useState(initialFetchState);
  const { completed, page, loading, hasMore, journeys } = fetchState;

  const setCompletion = newStatus => {
    setFetchState({ ...initialFetchState, completed: newStatus });
  };

  useEffect(() => {
    const initialFetch = async () => {
      setFetchState(oldState => ({ ...oldState, loading: true }));
      try {
        const response = await UserJourneyService.list({ completed, page: 0 });
        const { data } = response;
        const { content: journeys, last } = data;
        setFetchState(oldState => {
          return {
            ...oldState,
            page: 1,
            hasMore: !last,
            loading: false,
            journeys
          };
        });
      } catch (error) {
        setFetchState(oldState => ({ ...oldState, loading: false }));
        console.log(error);
      }
    };
    initialFetch();
  }, [completed]);

  const loadMore = async () => {
    if (loading) {
      return;
    }
    setFetchState(oldState => ({ ...oldState, loading: true }));

    try {
      const response = await UserJourneyService.list({ completed, page });
      const { data } = response;
      const { content: journeys, last } = data;
      setFetchState(oldState => {
        const { journeys: oldJourneys, page: oldPage } = oldState;
        return {
          ...oldState,
          page: oldPage + 1,
          hasMore: !last,
          loading: false,
          journeys: [...oldJourneys, ...journeys]
        };
      });
    } catch (error) {
      setFetchState(oldState => ({ ...oldState, loading: false }));
      console.log(error);
    }
  };

  const showLoadMoreButton = !loading && hasMore;

  return (
    <Container>
      <Header>Journeys</Header>
      <ButtonContainer>
        <UnderlineLink
          selected={!completed}
          onClick={() => setCompletion(false)}
          to="#"
        >
          In Progress
        </UnderlineLink>
        <UnderlineLink
          selected={completed}
          onClick={() => setCompletion(true)}
          to="#"
        >
          Completed
        </UnderlineLink>
      </ButtonContainer>
      <JourneysListContainer>
        {journeys.length > 0
          ? journeys.map(journey => {
              return <JourneyGridCard key={journey.id} journey={journey} />;
            })
          : 'No Journeys ' + (completed ? 'completed' : 'in progress')}
      </JourneysListContainer>
      <FetchingState loading={loading} loadMore={loadMore} hasMore={hasMore} />
    </Container>
  );
};

export default UserJourneyListPage;

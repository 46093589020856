import styled from 'styled-components';
import colors from 'designSystem/foundations/colors';
import CustomSelect from 'components/NewLyncworkUIKit/CustomSelect';
const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const InputLabel = styled.label`
  text-align: left;
  display: flex;
  gap: 4px;
  ${props => props.theme.typography.control};
  font-weight: 500;
  color: ${props => props.theme.colors.neutral.grey10};
  margin: 8px 0;
  span {
    color: ${props => props.theme.colors.feedback.error};
  }
`;

const errorColor = colors.feedback.error;
const regularLabelColor = colors.neutral.grey07;

const InputSubLabel = styled.p`
  text-align: left;
  ${props => props.theme.typography.metadata};
  color: ${props => (props.error ? errorColor : regularLabelColor)};
  margin: 8px 0;
`;

const SelectInput = ({
  label,
  required,
  name,
  value,
  options,
  onChange,
  errorMessage,
  error
}) => {
  return (
    <InputContainer>
      <InputLabel>
        {label}
        {required && <span>*</span>}
      </InputLabel>
      <CustomSelect
        name={name}
        onChange={onChange}
        value={value}
        options={options}
      />
      {errorMessage && (
        <InputSubLabel error={error}>{errorMessage}</InputSubLabel>
      )}
    </InputContainer>
  );
};
export default SelectInput;

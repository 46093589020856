export const CHANGE_TEXT = 'CHANGE_TEXT';
export const TOGGLE_CALENDAR_NOTIFICATION = 'TOGGLE_CALENDAR_NOTIFICATION';
export const CHANGE_ASSIGNMENT_TYPE = 'CHANGE_ASSIGNMENT_TYPE';
export const CHANGE_ASSIGNMENT = 'CHANGE_ASSIGNMENT';
export const MODIFY_TASK = 'MODIFY_TASK';
export const DELETE_TASK = 'DELETE_TASK';
export const CHANGE_TASK_DAY = 'CHANGE_TASK_DAY';
export const SET_DATA = 'SET_DATA';

export const TOGGLE_TRIGGER = 'TOGGLE_TRIGGER';
export const SELECT_PREREQUISITE = 'SELECT_PREREQUISITE';

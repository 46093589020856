import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

import accountSetupReducer from './accountSetup/accountSetup.reducer';
import authenticationReducer from './authentication/authentication.reducer';
import layoutReducer from './layout/layout.reducer';
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authentication', 'accountSetup']
};

const rootReducer = combineReducers({
  accountSetup: accountSetupReducer,
  authentication: authenticationReducer,
  layout: layoutReducer
});
export default persistReducer(persistConfig, rootReducer);

import React from 'react';

import generateMediaUrl from 'utilities/generateMediaUrl';
import { useModal } from 'hooks';

import DeleteButton from 'components/DeleteButton';
import { CustomButton, Avatar } from 'components/NewLyncworkUIKit';

import ServiceCard from '../ServiceCard';

import DeleteApplicationModal from './DeleteApplicationModal';

import {
  Container,
  StyledCard,
  HeaderContainer,
  SectionContainer,
  StyledLink,
  CredentialContainer,
  CredentialValue,
  ServiceContainer
} from './styles';

const getCredentials = (properties, applicationProperties) => {
  const values = {};
  properties.forEach(({ key, value }) => {
    values[key] = value;
  });

  const credentials = applicationProperties.map(({ name, label }) => {
    const value = values[name];
    return { name, value, label };
  });

  const filteredCredentials = credentials.filter(
    ({ name }) => name !== 'primary_email_provider'
  );
  return filteredCredentials;
};

const CredentialDetail = ({ credential }) => {
  const { value, label } = credential;
  return (
    <CredentialContainer>
      <span>
        <strong>{label}</strong>
      </span>
      <CredentialValue>{value}</CredentialValue>
    </CredentialContainer>
  );
};

const SubscriptionOverview = ({ subscriptionApp, deleteIntegration }) => {
  const { isOpen, openModal, closeModal } = useModal();
  const {
    id,
    application: {
      appName,
      id: appId,
      appLogo,
      appOverview,
      applicationCapabilities,
      applicationProperties
    },
    properties
  } = subscriptionApp;
  const src = generateMediaUrl(appLogo);
  const editLink = `/admin-applications/my-apps/${id}/edit`;
  const credentials = getCredentials(properties, applicationProperties);
  return (
    <>
      <DeleteApplicationModal
        isOpen={isOpen}
        closeModal={closeModal}
        appName={appName}
        deleteIntegration={deleteIntegration}
      />
      <Container>
        <StyledCard>
          <HeaderContainer>
            <Avatar name={appName} colorKey={appId} src={src} size="64px" />
            <h2>{appName}</h2>
          </HeaderContainer>
          <SectionContainer>
            <h3>Description</h3>
            <p>{appOverview}</p>
          </SectionContainer>
          <SectionContainer>
            <h3>Services</h3>
            <ServiceContainer>
              {applicationCapabilities.map(service => (
                <ServiceCard
                  key={service.id}
                  service={service}
                  className="half-width"
                />
              ))}
            </ServiceContainer>
          </SectionContainer>
        </StyledCard>
        <StyledCard>
          <HeaderContainer>
            <h2>Integration Details</h2>
            <StyledLink to={editLink}>
              <CustomButton variant="secondary" size="small">
                Edit
              </CustomButton>
            </StyledLink>
          </HeaderContainer>
          <SectionContainer>
            {credentials.map(credential => (
              <CredentialDetail key={credential.name} credential={credential} />
            ))}
          </SectionContainer>
        </StyledCard>
      </Container>
      <DeleteButton size="medium" variant="secondary" onClick={openModal}>
        Delete Integration
      </DeleteButton>
    </>
  );
};

export default SubscriptionOverview;

import { useState, useEffect, useCallback } from 'react';

import { SubscriptionAppService } from 'services/subscriptionApp.service';
import debounce from 'utilities/debounce';
const initialSearchState = {
  name: '',
  category: '',
  page: 0,
  size: 10,
  loading: true,
  subscriptionApps: [],
  totalPages: 0,
  key: true
};

const useSubscriptionApps = (defaultParams = {}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchState, setSearchState] = useState({
    ...initialSearchState,
    ...defaultParams
  });
  const {
    name,
    page,
    size,
    loading,
    category,
    subscriptionApps,
    totalPages,
    key
  } = searchState;

  useEffect(() => {
    let mounted = true;
    const getData = async () => {
      try {
        const response = await SubscriptionAppService.list(
          name,
          page,
          size,
          category
        );
        const { content: subscriptionApps, totalPages } = response.data;
        mounted &&
          setSearchState(oldState => {
            return {
              ...oldState,
              subscriptionApps,
              totalPages,
              loading: false
            };
          });
      } catch (error) {
        console.log(error);
        mounted && setSearchState(initialSearchState);
      }
    };
    getData();
    return () => {
      mounted = false;
    };
  }, [name, page, size, category, key]);

  const updateSearchParams = useCallback(
    debounce(
      value =>
        setSearchState(oldSearchState => {
          if (oldSearchState.name === value) {
            return oldSearchState;
          }
          return { ...oldSearchState, name: value, page: 0, loading: true };
        }),
      500
    ),
    []
  );

  const handleSearchInputChange = e => {
    const { value } = e.target;
    setSearchQuery(value);
    updateSearchParams(value);
  };

  const changePage = newPage => {
    const newPageIndex = newPage - 1;
    setSearchState(oldParams => ({
      ...oldParams,
      page: newPageIndex,
      loading: true,
      key: !oldParams.key
    }));
  };

  const changeCategory = category => {
    setSearchState(oldParams => ({
      ...oldParams,
      page: 0,
      category,
      loading: true,
      key: !oldParams.key
    }));
  };

  return {
    name,
    category,
    changeCategory,
    loading,
    handleSearchInputChange,
    searchQuery,
    changePage,
    subscriptionApps,
    page,
    totalPages
  };
};

export default useSubscriptionApps;

import styled from 'styled-components';
import Card from 'components/Card';

export const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  overflow: auto;
  grid-row: 4;
  grid-column: 1/3;
`;

export const GridContainer = styled.div`
  overflow: auto;
`;

export const HeaderSection = styled.div`
  margin-bottom: 20px;
`;

export const Header = styled.p`
  font-size: 19px;
  font-weight: 600;
  margin: 0 0 5px 0;
`;

export const SubHeader = styled.p`
  margin: 0;
`;

import styled from 'styled-components';
import Button from 'components/NewLyncworkUIKit/CustomButton';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.neutral.white};
  width: 400px;
  padding: 30px 56px 30px 40px;
  border-radius: 8px;
`;

export const Title = styled.h2`
  ${props => props.theme.typography.header2};
  font-weight: 600;
  margin-bottom: 16px;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  margin-left: 4px;
  border-radius: 50%;
  background-color: transparent;
  color: ${props => props.theme.colors.neutral.black};

  &:hover {
    background-color: ${props => props.theme.colors.neutral.offWhite03};
  }
  &:focus-visible {
    outline: 2px solid blue;
  }
`;

export const Message = styled.span`
  ${props => props.theme.typography.body};
  font-weight: 500;
  margin-bottom: 8px;
`;

export const DeleteButton = styled(Button)`
  margin: 40px auto 0;
  width: 160px;
`;
DeleteButton.defaultProps = {
  variant: 'secondary'
};

import React, { useState } from 'react';

import { CustomDropdown } from 'components/NewLyncworkUIKit';

import { Container, HeaderContainer, Title } from './styles';
import CustomChart from './CustomChart';

const DefaultValue = {
  value: 'sixMonthData',
  label: 'Six Months'
};

const dropDownOptions = [
  {
    value: 'sixMonthData',
    label: 'Six Months'
  },
  {
    value: 'thisWeekData',
    label: 'This Month'
  },
  {
    value: 'thisMonthData',
    label: 'This Week'
  }
];

const sixMonthData = [
  { name: 'Jan', points: 20 },
  { name: 'Feb', points: 80 },
  { name: 'Mar', points: 50 },
  { name: 'Apr', points: 120 },
  { name: 'May', points: 125 },
  { name: 'Jun', points: 100 }
];

const thisMonthData = [
  { name: 'Jan 1', points: 5 },
  { name: 'Jan 8', points: 10 },
  { name: 'Jan 15', points: 13 },
  { name: 'Jan 23', points: 15 },
  { name: 'Jan 30', points: 10 }
];
const thisWeekData = [
  { name: 'Mon', points: 1 },
  { name: 'Tue', points: 1 },
  { name: 'Wed', points: 4 },
  { name: 'Thu', points: 2 },
  { name: 'Fri', points: 5 }
];

const dataSet = {
  sixMonthData,
  thisMonthData,
  thisWeekData
};

const EngagementChart = () => {
  const [dataChosen, setDataChosen] = useState('sixMonthData');
  const handleSelectChange = choice => setDataChosen(choice.value);
  const data = dataSet[dataChosen];
  return (
    <Container>
      <HeaderContainer>
        <Title>Engagement</Title>
        <CustomDropdown
          values={dropDownOptions}
          minWidth={150}
          onChange={handleSelectChange}
          DefaultValue={DefaultValue}
        />
      </HeaderContainer>
      <CustomChart data={data} />
    </Container>
  );
};

export default EngagementChart;

import React from 'react';
import { Card, CardContainer, CoverflowContainer } from './styles.js';
import './styles.css';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/effect-coverflow/effect-coverflow.min.css';
import 'swiper/components/navigation/navigation.min.css';
import SwiperCore, { EffectCoverflow, Navigation } from 'swiper';
SwiperCore.use([Navigation, EffectCoverflow]);

const CustomCoverflow = ({ array, children }) => {
  return (
    <CoverflowContainer>
      <Swiper
        spaceBetween={0}
        mousewheel={true}
        loop={true}
        centeredSlides={true}
        slidesPerView={2}
        grabCursor={true}
        navigation={true}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }}
        effect="coverflow"
        coverflowEffect={{
          rotate: 0,
          depth: 150,
          modifier: 1,
          slideShadows: false
        }}
        breakpoints={{
          700: {
            slidesPerView: 4,
            spaceBetween: 0
          }
        }}
      >
        {children}
      </Swiper>
      <div className="swiper-button-prev"></div>
      <div className="swiper-button-next "></div>
    </CoverflowContainer>
  );
};

export default CustomCoverflow;

//so, hello future frontend dev trying to use this component!
//I made some changes that increase the usability of this component,
//but it requires some explanation
//This is how this bad-boy should be used:

//you must import "SwiperSlide" and "CustomCoverflow"
//import CustomCoverflow from 'this directory'
//import { SwiperSlide } from 'swiper/react';

//from there, make sure that you are mapping whatever data you intend on using in the CHILDREN
//of the component.

//BUT remember to wrap each mapped item in a <SwiperSlide>.
//this tells the package that there is a slide

// <CustomCoverflow>
//   {exampleData.map(example => (
//     <SwiperSlide> <--------HERE
//       <CardContainer>
//         <Card className="Card">{example.title}</Card>
//       </CardContainer>
//     </SwiperSlide> <--------and HERE
//   ))}
// </CustomCoverflow>

import React, { useRef } from 'react';

import { useButtonTrack } from 'matomo/hooks';
import { companyBasicInfoEvents } from 'pages/CompanyInfo/instrumentationEvents';

import { Container, StyledAvatar, UploadContainer } from './styles';

const UploadInput = ({ updateCompanyMedia }) => {
  const trackButton = useButtonTrack();
  const fileInputRef = useRef();
  const handleChange = async e => {
    const {
      files: [file],
      name
    } = e.target;
    try {
      const logoResponse = await updateCompanyMedia(file, name);
    } catch (error) {
      console.log(error);
    } finally {
      fileInputRef.current.value = '';
    }
  };

  const handleInputClick = () => {
    trackButton(companyBasicInfoEvents.avatar_click);
  };
  return (
    <UploadContainer className="image-upload">
      <input
        title=""
        type="file"
        name="companyLogo"
        onChange={handleChange}
        onClick={handleInputClick}
        ref={fileInputRef}
        accept="image/x-png,image/gif,image/jpeg,image/png"
      />
      <span>Edit</span>
    </UploadContainer>
  );
};

const AvatarUpload = ({ id, companyInfo, updateCompanyMedia, canEdit }) => {
  const { companyLogo, companyName } = companyInfo;
  const src = companyLogo?.url;
  return (
    <Container>
      {canEdit && <UploadInput updateCompanyMedia={updateCompanyMedia} />}
      <StyledAvatar colorKey={id} name={companyName} src={src} objectFit="scale-down" />
    </Container>
  );
};

export default AvatarUpload;

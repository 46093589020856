import styled, { css } from 'styled-components';
import colors from 'designSystem/foundations/colors';

const { grey10, grey07 } = colors.neutral;

const selectedTab = css`
  color: ${grey10};
  border-color: ${grey10};
`;
export const Tab = styled.button`
  padding: 0;
  cursor: pointer;
  box-sizing: border-box;
  outline: none;
  appearance: none;
  background-color: transparent;
  color: ${grey07};
  border: none;
  border-bottom: 2px solid transparent;
  ${props => props.theme.typography.body};
  font-weight: 600;
  ${props => props.selected && selectedTab};
  margin-right: 16px;
  &:hover {
    ${selectedTab}
  }
  &:focus-visible {
    outline: 2px solid #0078d4;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;
`;

export const TabContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0;
`;

import styled from 'styled-components';

import colors from 'designSystem/foundations/colors';

const disabledColor = colors.neutral.grey05;
const regularTextColor = colors.neutral.grey10;
const confirmationColor = colors.feedback.confirmation;

const uncheckedColor = colors.neutral.grey06;

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 20px;
  padding: 0;
  margin: 8px 0;
  color: ${props => (props.disabled ? disabledColor : regularTextColor)};
`;

Container.defaultProps = { disabled: false };

export const Checkbox = styled.input`
  flex-shrink: 0;
  position: absolute;
  cursor: pointer;
  z-index: 5;
  height: 20px;
  width: 40px;
  margin: 0;
  padding: 0;
  opacity: 0;
  &:disabled {
    cursor: not-allowed;
  }
`;

Checkbox.defaultProps = { type: 'checkbox' };

export const CheckboxToggle = styled.div`
  position: relative;
  flex-shrink: 0;
  height: 20px;
  width: 40px;
  margin-right: 4px;
  box-sizing: border-box;
  border: 1px solid ${uncheckedColor};
  background-color: ${colors.neutral.white};
  border-radius: 10px;
  display: flex;
  align-items: center;
  transition: all 0.4s;

  &:after {
    content: '';
    width: 12px;
    height: 12px;
    transform: translateX(3px);
    border-radius: 6px;
    background-color: ${uncheckedColor};
    transition: all 0.4s;
  }

  ${Checkbox}:checked ~ & {
    border-color: ${confirmationColor};
    background-color: ${confirmationColor};
    :after {
      background-color: ${colors.neutral.white};
      transform: translateX(23px);
    }
  }
  ${Checkbox}:disabled ~ & {
    border-color: ${disabledColor};
    :after {
      background-color: ${disabledColor};
    }
  }
  ${Checkbox}:checked:disabled ~ & {
    background-color: ${disabledColor};
    :after {
      background-color: ${colors.neutral.white};
    }
  }
`;

export const Label = styled.span`
  ${props => props.theme.typography.control};
  font-weight: ${props => (props.bold ? 600 : 500)};
`;
Label.defaultProps = { bold: false };

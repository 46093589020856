import React, { useState } from 'react';

import generateMediaUrl from 'utilities/generateMediaUrl';

import Avatar from 'components/NewLyncworkUIKit/Avatar';
import {
  Container,
  TitleContainer,
  AppName,
  AppStatus,
  StatusCircle,
  TemporaryCredentialsContainer,
  TempCredentialText
} from './styles';

import Credential from './Credential';

const getStatusText = userStatus => {
  switch (userStatus) {
    case 'MONITORED':
      return 'Monitored';
    default:
      return 'Active';
  }
};

const TemporaryCredentials = ({ children, showCredentials, onClick }) => {
  return (
    <TemporaryCredentialsContainer onClick={onClick}>
      {showCredentials ? (
        children
      ) : (
        <TempCredentialText>
          Click to view temporary credentials
        </TempCredentialText>
      )}
    </TemporaryCredentialsContainer>
  );
};

const ApplicationCard = ({
  application,
  userStatus,
  activationToken,
  emailPrimary
}) => {
  const [showCredentials, setShowCredentials] = useState(false);
  const { id, appName, appLogo, appMonitorEnabled } = application;
  const src = generateMediaUrl(appLogo);
  const isMonitoring = userStatus === 'MONITORED';

  const displayCredentials = () => setShowCredentials(true);

  return (
    <Container>
      <TitleContainer>
        <Avatar colorKey={id} src={src} name={appName} size="32px" />
        <AppName>{appName}</AppName>
        <AppStatus>
          <StatusCircle status={userStatus} />
          {getStatusText(userStatus)}
        </AppStatus>
      </TitleContainer>

      {isMonitoring && (
        <TemporaryCredentials
          showCredentials={showCredentials}
          onClick={displayCredentials}
        >
          <Credential label="username" text={emailPrimary} />
          <Credential label="password" text={activationToken} />
        </TemporaryCredentials>
      )}
    </Container>
  );
};

export default ApplicationCard;

import React from 'react';

import KeyContactCard from './KeyContactCard';
import { Container, Header, Description, CardContainer } from './styles';

const KeyContacts = ({ user }) => {
  const { mentor, manager, itPrime, hrPrime } = user;
  const doRender = mentor || manager || itPrime || hrPrime;

  return (
    <Container className="LOOK OVER HERE" doRender={doRender}>
      {doRender && (
        <>
          <Header>Key Contacts</Header>
          <Description>
            If you need help with anything reach out to the following people!
          </Description>
          <CardContainer>
            {mentor && <KeyContactCard user={mentor} role="Mentor" />}
            {manager && <KeyContactCard user={manager} role="Manager" />}
            {itPrime && <KeyContactCard user={itPrime} role="IT Prime" />}
            {hrPrime && <KeyContactCard user={hrPrime} role="HR Prime" />}
          </CardContainer>
        </>
      )}
    </Container>
  );
};

export default KeyContacts;

import styled from 'styled-components';
import colors from 'designSystem/foundations/colors';
import { CustomButton } from 'components/NewLyncworkUIKit';

const { grey07 } = colors.neutral;
const { primary } = colors.brand;

export const Container = styled.div`
  margin-top: 32px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.colors.neutral.grey02};
  border-left: none;
  border-right: none;
`;

export const CommentListContainer = styled.div`
  box-sizing: border-box;
  width: 60%;
  margin: 8px 0;
  display: flex;
  flex-direction: column;
`;

export const LikeText = styled.span`
  margin-bottom: 8px;
  padding: 0 16px;
  ${props => props.theme.typography.metadata};
  height: 1rem;
  color: ${props => props.theme.colors.neutral.grey07};
  align-self: flex-start;
`;

export const TextButton = styled(CustomButton)`
  color: ${props => props.theme.colors.neutral.grey07};
  padding: 0;
  &:hover:enabled {
    background-color: transparent;
  }
`;

TextButton.defaultProps = {
  variant: 'tertiary',
  size: 'small'
};

export const Button = styled(CustomButton)`
  border-radius: 8px;
  margin: 4px 0;
  font-weight: 400;
  color: ${props => (props.hasReacted ? primary : grey07)};
  .MuiSvgIcon-root {
    margin-right: 8px;
    color: inherit;
    font-size: 1rem;
  }
`;

Button.defaultProps = {
  variant: 'tertiary',
  size: 'small'
};

export const TotalCommentCount = styled.div`
  ${props => props.theme.typography.metadata};
  color: ${props => props.theme.colors.neutral.grey07};
  font-weight: 600;
  margin-left: auto;
`;

export const CommentOffText = styled.span`
  ${props => props.theme.typography.body};
  margin: 12px 0 0;
`;

import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { grey, white } from 'StyleVariables/LyncworkColors';
export const Container = styled.div`
  position: sticky;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: calc(100% + 8vw);
  height: 50px;
  background-color: ${white};
  top: -2vw;
  right: 15px;
  opacity: 1;
  z-index: 2;
  border-top: 1px solid ${props => props.theme.colors.neutral.grey05};
  border-bottom: 1px solid ${props => props.theme.colors.neutral.grey05};
  padding: 0 4vw;
  box-sizing: border-box;

  gap: 2em;
`;
export const NavOption = styled(NavLink)`
  ${props => props.theme.typography.control};
  font-weight: 500;
  text-decoration: none;
  color: ${props => props.theme.colors.neutral.grey05};

  &.active {
    color: ${props => props.theme.colors.brand.primary};
  }
`;

import axios from 'axios';
import env from '../env';
const baseUrl = `${env.storeDomain}/api/job-title`;
export const JobTitleService = {
  create: data => {
    return axios.post(`${baseUrl}`, data);
  },

  update: data => {
    return axios.put(`${baseUrl}`, data);
  },

  getAllJobTitles: () => {
    return axios.get(`${baseUrl}`);
  },

  getJobTitlesByTitle: title => {
    return axios.get(`${baseUrl}?title=${title}`);
  },

  getJobTitleById: id => {
    return axios.get(`${baseUrl}/${id}`);
  }
};

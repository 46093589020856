import React, { useEffect, useState } from 'react';
import { CustomSearch } from 'components/NewLyncworkUIKit';
import {
  Container,
  ErrorText,
  JourneysContainer,
  JourneyContainer,
  JourneyTitle,
  JourneyDescription,
  Tasks,
  EmptyContainer,
  Empty
} from './styles';
import { JourneyService } from 'services/journeys.service';
import LoadingScreen from 'components/LoadingScreen';
import { useParams } from 'react-router';
import empty from 'assets/images/GenericIcons/no users.svg';
const Journeys = () => {
  const { id } = useParams();
  const [searchParam, setSearchParam] = useState('');
  const [journeys, setJourneys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const getJourneys = async () => {
    setLoading(true);
    if (id) {
      try {
        //using the workflow list api for testing porpoises
        const { data } = await JourneyService.list({ teams: id });
        setJourneys(data.content);
      } catch (err) {
        console.log(err);
        setError(err);
      }
    } else {
      setJourneys([]);
    }

    setLoading(false);
  };
  useEffect(() => {
    getJourneys();
  }, []);

  return (
    <Container>
      <CustomSearch
        placeholder="Search journeys"
        value={searchParam}
        offWhite
        name="journeys"
        width="100%"
        onChange={e => setSearchParam(e.target.value)}
      />

      <JourneysContainer>
        {error ? (
          <ErrorText>Something has gone wrong </ErrorText>
        ) : loading ? (
          <LoadingScreen />
        ) : !journeys.length ? (
          <EmptyContainer>
            <Empty src={empty} />
            <p>
              There are no journeys assigned to this team. Please assign them in
              the ‘Journeys’ page.{' '}
            </p>
          </EmptyContainer>
        ) : (
          journeys.map(journey => (
            <JourneyContainer key={journey.id}>
              <JourneyTitle>
                <p>{journey.workflowName}</p>
                <Tasks>{journey.workflowTasks.length} Tasks</Tasks>
              </JourneyTitle>
              <JourneyDescription>{journey.workflowDesc}</JourneyDescription>
            </JourneyContainer>
          ))
        )}
      </JourneysContainer>
    </Container>
  );
};
export default Journeys;

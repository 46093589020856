import styled from 'styled-components';
import 'swiper/swiper.min.css';
const mobileBreakpoint = '900px';
export const Container = styled.div`
  position: relative;
  width: 100%;
  overflow-x: hidden;
  display: none;
  .swiper-slide {
    width: 200px;
    margin: 0 4px;
  }
  .swiper-wrapper {
    height: 272px;
  }
  .swiper-slide:first-child {
    margin-left: 20px;
  }
  .swiper-slide:last-child {
    margin-right: 20px;
  }
  @media only screen and (max-width: ${mobileBreakpoint}) {
    display: unset;
  }
`;

export const Card = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 248px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.24);
  flex-shrink: 0;
  flex-grow: 0;
  overflow: hidden;
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 175px;
  width: 100%;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(${props => props.src});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transform: scale(1.1);
    filter: blur(8px);
  }

  img {
    position: relative;
    z-index: 3;
    width: 100%;
    height: auto;
    object-fit: ;
  }
`;

export const Description = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.theme.typography.header3};
  color: ${props => props.theme.colors.neutral.grey10};
  font-weight: 600;
`;

export const SwipeInstruction = styled.div`
  text-align: center;
  ${props => props.theme.typography.control};
  font-weight: 500;
  color: ${props => props.theme.colors.neutral.grey04};
`;

import axios from 'axios';
import env from '../env';
const baseUrl = `${env.serviceDomain}/dropbox`;
const getUrl = `${baseUrl}/api/dropbox/files`;

export const DropboxService = {
  downloadUser: fileId =>
    axios.get(`${baseUrl}/api/dropbox/download/file/${fileId}/user`, {
      responseType: 'blob'
    }),
  downloadAdmin: fileId =>
    axios.get(`${baseUrl}/api/dropbox/download/file/${fileId}/admin`, {
      responseType: 'blob'
    }),
  updateFileMetadata: newMetadata =>
    axios.put(`${baseUrl}/api/dropbox/file/metadata`, newMetadata),

  deleteFile: dropboxFileId =>
    axios.delete(`${baseUrl}/api/dropbox/file/${dropboxFileId}`),

  updateFileCategory: (dropboxFileId, category) =>
    axios.put(
      `${baseUrl}/api/dropbox/file/${dropboxFileId}/category/${category}`
    ),
  findDropboxFileByJWT: (page = '0', size = '10') => {
    const searchParams = new URLSearchParams({
      page,
      size
    });
    const queryString = searchParams.toString();
    return axios.get(`${getUrl}/jwt?${queryString}`);
  },
  listFile: (name = '', page = '0', size = '10') =>
    axios.get(`${getUrl}?name=${name}&page=${page}&size=${size}`),
  findDropboxFileByCategoryAll: () => axios.get(`${getUrl}/all`),
  findDropboxFileByCategory: category =>
    axios.get(`${getUrl}/category/${category}`),
  findDropboxFileByLyncworkUser: (lyncworkUserId, category) =>
    axios.get(
      `${baseUrl}/api/dropbox/files/user/${lyncworkUserId}/category/${category}`
    ),
  // other type=overwrite
  upload: (file, type = 'duplicate') => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);
    return axios.post(`${baseUrl}/api/dropbox/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};

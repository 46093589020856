import React from 'react';
import CustomDropdown from '../../../../components/NewLyncworkUIKit/CustomDropdown';
import { DropdownContainer } from './styles';

const options = [{ label: 'Most Popular', value: 'most-popular' }];

const SortByDropdown = () => {
  return (
    <DropdownContainer>
      <p>Sort by:</p>
      <CustomDropdown
        minWidth="160px"
        values={options}
        DefaultValue={options[0]}
        onChange={() => {}}
      />
    </DropdownContainer>
  );
};

export default SortByDropdown;

import React from 'react';
import LoopIcon from '@material-ui/icons/Loop';

import generateMediaUrl from 'utilities/generateMediaUrl';
import Avatar from 'components/NewLyncworkUIKit/Avatar';

import {
  StickyContainer,
  Title,
  LicenseAssignmentTitle,
  ApplicationLicenseCard,
  LicenseComparisonContainer,
  IconContainer,
  AppListContainer
} from './styles';

export const ApplicationDetail = ({ application, license }) => {
  const { appName, appLogo, id } = application;
  const src = generateMediaUrl(appLogo);

  const licenseName =
    license?.applicationLicenseInformation.licenseName || 'None';
  const formattedLicenseName = licenseName.toLowerCase();
  return (
    <>
      <Avatar src={src} colorKey={id} name={appName} />
      <div className="detail">
        <span className="app-name">{appName}</span>
        <span className="license-name">{formattedLicenseName}</span>
      </div>
    </>
  );
};

const ApplicationLicense = ({ application, license }) => {
  const { appName, appLogo, id } = application;
  const src = generateMediaUrl(appLogo);

  const licenseName =
    license?.applicationLicenseInformation.licenseName || 'None';
  const formattedLicenseName = licenseName.toLowerCase();

  return (
    <ApplicationLicenseCard>
      <ApplicationDetail application={application} license={license} />
    </ApplicationLicenseCard>
  );
};

const SyncIcon = () => {
  return (
    <IconContainer>
      <LoopIcon />
      <span>License Changed</span>
    </IconContainer>
  );
};

const LicenseComparison = ({ licenseInfo }) => {
  const { application, oldLicense, assignedLicense } = licenseInfo;
  return (
    <LicenseComparisonContainer>
      <ApplicationLicense application={application} license={oldLicense} />
      <SyncIcon />
      <ApplicationLicense application={application} license={assignedLicense} />
    </LicenseComparisonContainer>
  );
};

const ApplicationLicenseChange = ({ appsWithLicenseChanged }) => {
  return (
    <>
      <StickyContainer>
        <Title>Licence Assignment Changes</Title>
        <LicenseAssignmentTitle>
          <span>Before</span>
          <span>After</span>
        </LicenseAssignmentTitle>
      </StickyContainer>
      <AppListContainer>
        {appsWithLicenseChanged.map(licenseInfo => {
          const key = licenseInfo.application.id;
          return <LicenseComparison key={key} licenseInfo={licenseInfo} />;
        })}
      </AppListContainer>
    </>
  );
};

export default ApplicationLicenseChange;

import React from 'react';
import { useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

import { AuthenticationService } from 'services/authentication.service';
import TopNav from 'navigation/TopNav';

import { CustomButton } from 'components/NewLyncworkUIKit';

import { Container, Title, Triangle } from './styles';

const getNewPathname = search => {
  const params = new URLSearchParams(search);
  const newPath = params.get('r');
  switch (newPath) {
    case null:
    case '':
    case '/':
      return '/dashboard';
      break;
    default:
      return decodeURIComponent(newPath);
  }
};

const Login = () => {
  const { keycloak } = useKeycloak();
  const location = useLocation();
  const newPathName = getNewPathname(location.search);

  const redirectUri = `${window.location.origin.toString()}/${newPathName}`;

  return (
    <Container>
      <TopNav />
      <Title data-testid="login-page-title">
        Welcome to <span>Lyncwork</span>
      </Title>
      <CustomButton
        width="120px"
        data-testid="login-button"
        onClick={() => keycloak.login({ redirectUri })}
      >
        Log In
      </CustomButton>
      <Triangle />
    </Container>
  );
};

export default Login;

import styled from 'styled-components';

import { colors } from 'designSystem/foundations';
import {
  CustomCheckbox,
  Avatar,
  CustomButton
} from 'components/NewLyncworkUIKit';

const {
  brand: { primary, primaryLite },
  neutral: { grey01, grey07 }
} = colors;

export const StyledCheckbox = styled(CustomCheckbox)`
  margin: 0 0 0 24px;
`;

const getCategoryBackground = ({ fileCategory }) => {
  if (fileCategory === 'ALL') {
    return grey01;
  }
  return primaryLite;
};

const getCategoryColor = ({ fileCategory }) => {
  if (fileCategory === 'ALL') {
    return grey07;
  }
  return primary;
};

export const CategoryPill = styled.div`
  height: 28px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  border-radius: 14px;
  background-color: ${getCategoryBackground};
  color: ${getCategoryColor};
  ${props => props.theme.typography.control};
  font-weight: 500;
`;

CategoryPill.defaultProps = { fileCategory: 'ALL' };

export const StyledAvatar = styled(Avatar)`
  width: 28px;
  height: 28px;
  margin: 0 8px 0 0;
`;

export const UserName = styled.span`
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${props => props.theme.typography.control};
  font-weight: 500;
`;

export const EditButton = styled(CustomButton)`
  margin: 0 24px 0 auto;
`;
EditButton.defaultProps = {
  size: 'small',
  variant: 'secondary'
};

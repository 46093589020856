import styled from 'styled-components';
import { CustomTextInput } from 'components/NewLyncworkUIKit';

export const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 450px;
  padding: 24px;
  border-radius: 8px;
  background: white;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  color: ${props => props.theme.colors.neutral.grey10};
  width: 16px;
  height: 16px;
  appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: transform 0.2s;
  &:focus-visible {
    outline: 2px solid #0078d4;
  }
`;

export const Title = styled.h1`
  ${props => props.theme.typography.header1};
  font-weight: 600;
  margin: 0 0 16px;
`;

export const Label = styled.label`
  text-align: left;
  ${props => props.theme.typography.control};
  font-weight: 600;
  color: ${props => props.theme.colors.neutral.grey10};
  margin: 8px 0;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  button {
    margin: 16px 0 0;
  }
  button.isCancel {
    margin: 16px 8px 0 auto;
  }
`;

export const DayInput = styled(CustomTextInput)`
  width: 80px;
  margin: 0 16px 0 0;
`;
DayInput.defaultProps = { type: 'number', required: 'true' };

export const ErrorMessage = styled.p`
  text-align: left;
  ${props => props.theme.typography.metadata};
  color: ${props => props.theme.colors.feedback.error};
  margin: 8px 0;
`;

import React from 'react';
import useAuthentication from 'hooks/useAuthentication';
import generateMediaUrl from 'utilities/generateMediaUrl';
import { Container, AppContainer, StyledAppLink, StyledAvatar } from './styles';

const ApplicationLink = ({ app }) => {
  const { appName, id, appLink, appLogo } = app;
  const src = generateMediaUrl(appLogo);
  return (
    <StyledAppLink target="_blank" rel="noopener noreferrer" href={appLink}>
      <StyledAvatar
        name={appName}
        src={src}
        width="32px"
        height="32px"
        objectFit="contain"
        colorKey={id}
      />
    </StyledAppLink>
  );
};

const Applications = () => {
  const {
    user: { applications }
  } = useAuthentication();
  //remove lyncwork app, which has id 6
  const filteredApplication = applications.filter(app => app.id !== 6);
  return (
    <Container>
      <AppContainer>
        {filteredApplication.map(app => (
          <ApplicationLink key={app.id} app={app} />
        ))}
      </AppContainer>
    </Container>
  );
};

export default Applications;

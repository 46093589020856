import { useEffect, useState, useCallback, useReducer } from 'react';
import debounce from 'utilities/debounce';
import { JourneyService } from 'services';
import { useButtonTrack } from 'matomo/hooks';
import {
  journeysReducer,
  initialState,
  CHANGE_PARAMS,
  CHANGE_PAGE,
  CHANGE_ASSIGNMENT_TYPE,
  SET_DATA
} from './journeysReducer';
import { journeyListPageEvents } from 'pages/Journeys/instrumentationEvents';

const useJourneysList = () => {
  const trackButton = useButtonTrack();
  const [searchQuery, setSearchQuery] = useState('');
  const [state, dispatch] = useReducer(journeysReducer, initialState);
  const {
    name,
    page,
    teams,
    roles,
    assignmentType,
    journeys,
    loading,
    totalPages,
    key
  } = state;

  useEffect(() => {
    const fetchJourneys = async () => {
      try {
        const response = await JourneyService.list({
          name,
          page,
          teams: teams?.id || '',
          roles: roles?.id || '',
          assignmentType
        });
        const { content: journeys, totalPages } = response.data;
        dispatch({ type: SET_DATA, journeys, totalPages });
      } catch (error) {
        console.log(error);
      }
    };
    fetchJourneys();
  }, [name, page, teams, roles, assignmentType, key]);

  const changePage = newPage => {
    const newPageIndex = newPage - 1;
    dispatch({ type: CHANGE_PAGE, page: newPageIndex });
    trackButton(journeyListPageEvents.change_page);
  };

  const changeName = useCallback(
    debounce(
      value => dispatch({ type: CHANGE_PARAMS, newParams: { name: value } }),
      500
    ),
    []
  );

  const handleInputChange = e => {
    const value = e.target.value;
    setSearchQuery(value);
    changeName(value);
  };

  const handleAssignmentChange = e => {
    const assignmentType = e.target.value;
    dispatch({ type: CHANGE_ASSIGNMENT_TYPE, assignmentType });
    trackButton(journeyListPageEvents.change_assignment_type);
  };

  const changeParams = newParams => {
    dispatch({ type: CHANGE_PARAMS, newParams });
  };

  return {
    ...state,
    changePage,
    handleInputChange,
    searchQuery,
    handleAssignmentChange,
    changeParams
  };
};

export default useJourneysList;

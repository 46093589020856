import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import PortalContainer from 'components/PortalContainer';
import TrapFocus from 'components/TrapFocus';
import { CustomTextInput } from 'components/NewLyncworkUIKit';
import {
  Container,
  CloseButton,
  Title,
  Tagline,
  Message,
  ErrorMessage,
  CustomButton,
  ButtonContainer
} from './styles';

const DeleteModalContent = ({ appName, closeModal, deleteIntegration }) => {
  const [inputValue, setInputValue] = useState('');
  const confirmationText = `Delete ${appName}`;
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleDelete = async () => {
    try {
      setErrorMessage('');
      setLoading(true);
      await deleteIntegration();
    } catch (error) {
      setErrorMessage(error);
    }
  };

  const confirmationCorrect = confirmationText === inputValue;
  const inputLabel = `To delete, type '${confirmationText}'`;
  return (
    <TrapFocus>
      <Container onClick={e => e.stopPropagation()}>
        <CloseButton onClick={closeModal} disabled={loading}>
          <CloseIcon />
        </CloseButton>
        <Title>Delete {appName}</Title>
        <Tagline>Are you sure you want to delete {appName}?</Tagline>
        <Message>
          If you proceed with this step, all associated users will be removed
          and will no longer have access to {appName}. You can’t undo this
          action.
        </Message>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <CustomTextInput
          width="50%"
          label={inputLabel}
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
        />
        <ButtonContainer>
          <CustomButton
            variant="secondary"
            onClick={closeModal}
            disabled={loading}
          >
            Cancel
          </CustomButton>
          <CustomButton
            onClick={handleDelete}
            disabled={loading || !confirmationCorrect}
          >
            {loading ? (
              <CircularProgress size="24px" color="inherit" />
            ) : (
              'Delete'
            )}
          </CustomButton>
        </ButtonContainer>
      </Container>
    </TrapFocus>
  );
};

const DeleteApplicationModal = ({
  isOpen,
  closeModal,
  appName,
  deleteIntegration
}) => {
  return (
    <PortalContainer isOpen={isOpen}>
      <DeleteModalContent
        appName={appName}
        closeModal={closeModal}
        deleteIntegration={deleteIntegration}
      />
    </PortalContainer>
  );
};

export default DeleteApplicationModal;

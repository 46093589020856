import React from 'react';
import { useHistory } from 'react-router-dom';

import { BreadcrumbsContainer, BreadcrumbsItem, BreadcrumbsSeparatorContainer, BreadcrumbsSeparatorInner } from './styles';

const Separator = () => (
  <BreadcrumbsSeparatorContainer>
    <BreadcrumbsSeparatorInner />
  </BreadcrumbsSeparatorContainer>
);

const Item = ({ text, path, onClick }) => {
  const history = useHistory();

  const handleClick = (event) => {
    event.preventDefault();
    if (onClick) {
      onClick();
      return;
    }
    if (path) {
      history.push(path);
    }
  };

  return (
    <BreadcrumbsItem isLast={!path && !onClick} onClick={handleClick}>
      {text}
    </BreadcrumbsItem>
  );
};

const Breadcrumbs = ({ items }) => (
  <BreadcrumbsContainer>
    {items.reduce((components, item, i) => {
      const isLast = i === items.length - 1;
      const config = isLast ? { text: item.text } : item;
      components.push(<Item key={item.text + i} { ...config } />);
      if (!isLast) {
        components.push(<Separator key={item.text + i + 'sep'} />);
      }
      return components;
    }, [])}
  </BreadcrumbsContainer>
);

export default Breadcrumbs;
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import JourneysListPage from './JourneysListPage';
import {
  JourneysAddPage,
  JourneysDuplicatePage,
  JourneysEditPage
} from './SingleJourneysPage';
const RolesPage = () => {
  return (
    <Switch>
      <Route path="/admin-journeys" exact>
        <JourneysListPage />
      </Route>
      <Route path="/admin-journeys/edit/:id">
        <JourneysEditPage />
      </Route>
      <Route path="/admin-journeys/duplicate/:id">
        <JourneysDuplicatePage />
      </Route>
      <Route path="/admin-journeys/add">
        <JourneysAddPage />
      </Route>
    </Switch>
  );
};

export default RolesPage;

import React, { useState, useEffect } from 'react';
import { format, isSameDay } from 'date-fns';
import { CircularProgress } from '@material-ui/core';
import imagePersonalInformation from 'assets/images/Onboarding/PersonalInformation.svg';

import { UserJourneyService } from 'services';

import {
  Container,
  HeaderContainer,
  Header,
  AgendaOverview,
  TaskHeader,
  TaskCardContainer,
  TaskStatusCard,
  EmptyImage,
  EmptyMessage
} from './styles';

const TaskCard = ({ task }) => {
  const {
    taskDue,
    schedDueDate,
    workflow,
    task: { taskEventName }
  } = task;
  const statusText = taskDue ? 'Due Today' : 'New';
  const workflowId = workflow?.id;
  const link = workflowId ? `/user-journeys/${workflowId}` : '/dashboard';
  const workflowName = workflow?.workflowName;
  return (
    <TaskCardContainer to={link}>
      <span className="task-title">{taskEventName}</span>
      <TaskStatusCard taskDue={taskDue}>{statusText}</TaskStatusCard>
      <span className="task-desc">{workflowName}</span>
    </TaskCardContainer>
  );
};

const Overview = ({ taskCount, dueTaskCount }) => {
  if (!taskCount) {
    return <AgendaOverview>You have no tasks today.</AgendaOverview>;
  }

  return (
    <>
      <AgendaOverview>
        You have <strong>{taskCount}</strong> tasks total,{' '}
        <strong>{dueTaskCount}</strong> due today.
      </AgendaOverview>
      <TaskHeader>Tasks</TaskHeader>
    </>
  );
};

const Tasks = ({ loading, tasks }) => {
  const hasTasks = tasks.length > 0;
  if (loading) {
    return <CircularProgress />;
  }

  if (!hasTasks) {
    return (
      <>
        <EmptyImage src={imagePersonalInformation} alt="no-tasks-available" />
        <EmptyMessage>You're all clear!</EmptyMessage>
      </>
    );
  }

  return tasks.map(task => <TaskCard key={task.id} task={task} />);
};

const TodayAgenda = () => {
  const [{ loading, tasks }, setData] = useState({ loading: true, tasks: [] });
  useEffect(() => {
    let mounted = true;
    const getTask = async () => {
      const today = new Date();
      const startDate = format(today, 'yyyy-MM-dd');
      try {
        const response = await UserJourneyService.listTask({
          startDate,
          status: 'ASSIGNED'
        });
        const tasks = response.data.content.map(task => {
          const dueDate = new Date(task.schedDueDate);
          const taskDue = isSameDay(today, dueDate);
          return { ...task, taskDue };
        });
        mounted && setData({ loading: false, tasks });
      } catch (error) {
        mounted && setData({ loading: false, tasks: [] });
        console.log(error);
      }
    };
    getTask();
    return () => {
      mounted = false;
    };
  }, []);
  const taskCount = tasks.length;
  const hasNoTasks = taskCount < 1;
  const dueTaskCount = tasks.filter(({ taskDue }) => taskDue).length;
  return (
    <Container data-tutorial-id="today-agenda">
      <HeaderContainer>
        <Header>Today's Agenda</Header>
        {!loading && (
          <Overview taskCount={taskCount} dueTaskCount={dueTaskCount} />
        )}
      </HeaderContainer>
      <Tasks loading={loading} tasks={tasks} />
    </Container>
  );
};

export default TodayAgenda;

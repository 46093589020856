import styled from 'styled-components';
import { Link } from 'react-router-dom';
import CustomButton from 'components/NewLyncworkUIKit/CustomButton';

export const PostListContainer = styled.div``;

export const PostListItemContainer = styled(Link)`
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding: 20px 30px;
  border: 1px solid #aab2bb;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
`;

export const PostListItemIcon = styled.div`
  filter: grayscale(1);
  width: 60px;
  margin-right: 30px;
  & img {
    width: 100%;
  }
`;

export const PostListItemContent = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

export const PostListItemTitle = styled.h4`
  color: #ff6b6b;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
`;

export const PostListItemDescription = styled.p`
  color: #4d4d4d;
  margin: 10px 0 0 0;
`;

export const PostListItemUpdatedAt = styled.p`
  color: #aab2bb;
  margin-top: 5px;
  margin: 10px 0 0 0;
`;

export const PostListViewMoreButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

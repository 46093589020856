import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
// import Button from 'components/NewLyncworkUIKit/CustomButton';
import LoadingButton from './LoadingButton';

const DeleteButton = ({
  variant = 'secondary',
  children = 'Delete',
  ...loadingButtonProps
}) => {
  return (
    <LoadingButton variant={variant} {...loadingButtonProps}>
      <DeleteIcon fontSize="small" /> {children}
    </LoadingButton>
  );
};

export default DeleteButton;

import React, { useEffect, useState } from 'react';
import { Route, Switch, useParams, useHistory } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import { ApplicationService, SubscriptionAppService } from 'services/';

import NestedLink from 'components/NestedLink';
import { Container, ContentContainer } from './styles';

import useToast from 'hooks/useToast';
import { ApplicationOverview, SubscriptionForm } from './components';

const browseApplication = {
  id: 1,
  text: 'Browse Applications',
  link: '/admin-applications/browse'
};

const LoadingScreen = () => {
  return (
    <Container>
      <NestedLink linkInfoList={[browseApplication]} />
      <CircularProgress />
    </Container>
  );
};

const getLinkInfoList = app => {
  const { appName, id } = app;
  const viewAppLink = `/admin-applications/view/${id}`;
  const linkInfoList = [
    browseApplication,
    {
      id: 2,
      text: appName,
      link: viewAppLink
    },
    {
      id: 3,
      text: 'Add Application',
      link: `${viewAppLink}/add`
    }
  ];
  return linkInfoList;
};

const ApplicationView = () => {
  const { createToast } = useToast();
  const { id } = useParams();
  const history = useHistory();
  const [app, setApp] = useState(null);
  useEffect(() => {
    let mounted = true;
    const fetchApp = async () => {
      const response = await ApplicationService.find(id);
      if (mounted) {
        setApp(response.data);
      }
    };
    fetchApp();
    return () => {
      mounted = false;
    };
  }, [id]);

  if (!app) {
    return <LoadingScreen />;
  }

  const handleSave = async formValue => {
    const properties = app.applicationProperties.map(property => {
      const { name, label, application } = property;
      const key = name;
      const value = formValue[name];
      return { name, label, application, key, value };
    });

    const requestData = { application: app, properties };
    const response = await SubscriptionAppService.create(requestData);
    const subscriptionId = response.data.id;

    history.push('/admin-applications/browse');
    createToast({
      title: 'Application Added',
      message: `${app.appName} has been successfully added as an application.`,
      linkText: 'View Application',
      //grab the subscription id from the response earlier, and add to the link below
      link: `/admin-applications/my-apps/${subscriptionId}`
    });
  };

  const handleCancel = () => history.goBack();

  const linkInfoList = getLinkInfoList(app);

  return (
    <Container>
      <Switch>
        <Route path="/admin-applications/view/:id" exact>
          <NestedLink linkInfoList={linkInfoList.slice(0, 2)} />
          <ApplicationOverview app={app} />
        </Route>
        <Route path="/admin-applications/view/:id/add">
          <NestedLink linkInfoList={linkInfoList} />
          <SubscriptionForm
            application={app}
            handleSave={handleSave}
            handleCancel={handleCancel}
          />
        </Route>
      </Switch>
    </Container>
  );
};

export default ApplicationView;

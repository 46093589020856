import styled from 'styled-components';
import Button from 'components/NewLyncworkUIKit/CustomButton';

const mobileBreakPoint = '900px';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h3`
  ${props => props.theme.typography.title3};
  font-weight: 600;
  margin: 0 0 16px 0;
  @media only screen and (max-width: ${mobileBreakPoint}) {
    width: 100%;
    ${props => props.theme.typography.header2};
    font-weight: 600;
  }
`;

export const TeamContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const CustomButton = styled(Button)`
  border-color: ${props => props.theme.colors.brand.primary01};
`;

CustomButton.defaultProps = {
  size: 'medium',
  variant: 'secondary'
};

export const CountText = styled.span`
  ${props => props.theme.typography.metadata};
  color: ${props => props.theme.colors.neutral.grey06};
  margin: 16px 0;
`;

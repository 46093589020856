import React from 'react';

import PortalContainer from 'components/PortalContainer';

import FileEditContent from './FileEditContent';

const FileEditModal = ({
  fileToEdit,
  closeEditModal,
  updateMetadata,
  downloadFile
}) => {
  return (
    <PortalContainer isOpen={!!fileToEdit} handleClickOutside={closeEditModal}>
      <FileEditContent
        file={fileToEdit}
        closeEditModal={closeEditModal}
        updateMetadata={updateMetadata}
        downloadFile={downloadFile}
      />
    </PortalContainer>
  );
};

export default FileEditModal;

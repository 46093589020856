import React, { createContext, useContext } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { LocaleService } from 'services';

import { CustomButton, Label } from 'components/NewLyncworkUIKit';

import AvatarSection from '../AvatarSection';

import {
  accountDetailsFormContext,
  useAccountDetailsForm
} from './accountDetailsFormContext';
import FormField, { CustomField } from './FormField';

import {
  Container,
  Form,
  FormSection,
  SectionTitle,
  AvatarSectionContainer,
  ThreeWordsContainer,
  ThreeWordsInput
} from './styles';

const ThreeWordsField = () => {
  const { formInfo, updateThreeWords } = useAccountDetailsForm();
  const [first = '', second = '', third = ''] = formInfo.threeWords;

  return (
    <ThreeWordsContainer>
      <Label className="three-words-label">Descriptive Words About Me</Label>
      <ThreeWordsInput
        value={first}
        onChange={e => updateThreeWords(0, e.target.value)}
        label="Word 1"
      />
      <ThreeWordsInput
        value={second}
        onChange={e => updateThreeWords(1, e.target.value)}
        label="Word 2"
      />
      <ThreeWordsInput
        value={third}
        onChange={e => updateThreeWords(2, e.target.value)}
        label="Word 3"
      />
    </ThreeWordsContainer>
  );
};

const GeneralInfo = ({ optionalFields }) => {
  return (
    <FormSection>
      <SectionTitle>General Information</SectionTitle>
      <FormField name="emailSecondary" required />
      <FormField className="half" name="phonePrimary" required />
      <FormField className="half" name="phoneSecondary" />
      {optionalFields.map(field => (
        <CustomField field={field} key={field.name} />
      ))}
      <ThreeWordsField />
    </FormSection>
  );
};

const Address = () => {
  return (
    <FormSection>
      <SectionTitle>Address</SectionTitle>
      <FormField name="country" required />
      <FormField name="lineOne" required />
      <FormField name="lineTwo" />
      <FormField name="state" required />
      <FormField name="city" className="half" required />
      <FormField name="postalCode" className="half" required />
    </FormSection>
  );
};

const Social = () => {
  return (
    <FormSection>
      <SectionTitle>Social</SectionTitle>
      <FormField className="half" name="linkedinUrl" />
      <FormField className="half" name="mediumUrl" />
    </FormSection>
  );
};

const EmergencyContact = ({ optionalFields }) => {
  if (optionalFields.length < 1) {
    return null;
  }

  return (
    <FormSection>
      <SectionTitle>Emergency Contact</SectionTitle>
      {optionalFields.map(field => {
        const { name } = field;
        const newField = {
          ...field,
          name: `emergencyContact-${name}`
        };
        return <CustomField field={newField} key={field.name} />;
      })}
    </FormSection>
  );
};

const AccountDetailsForm = ({ formProps }) => {
  const {
    avatarData,
    changeAvatar,
    handleSubmit,
    submitting,
    optionalFields
  } = formProps;

  return (
    <accountDetailsFormContext.Provider value={formProps}>
      <Container>
        <Form onSubmit={handleSubmit}>
          <AvatarSectionContainer>
            <AvatarSection
              avatarData={avatarData}
              changeAvatar={changeAvatar}
            />
          </AvatarSectionContainer>
          <GeneralInfo optionalFields={optionalFields.LyncWorkUser} />
          <Address />
          <Social />
          <EmergencyContact optionalFields={optionalFields.EmergencyContact} />
          <CustomButton type="submit" size="medium">
            {submitting ? (
              <CircularProgress color="inherit" size="18px" />
            ) : (
              'Save Account Details'
            )}
          </CustomButton>
        </Form>
      </Container>
    </accountDetailsFormContext.Provider>
  );
};

export default AccountDetailsForm;

import styled from 'styled-components';
import colors from 'designSystem/foundations/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
  margin: 0 0 28px;

  h1 {
    ${props => props.theme.typography.title3};
    font-weight: 600;
    color: ${props => props.theme.colors.neutral.grey10};
    margin: 0 auto 0 0;
  }
`;

export const FilterRow = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 0 0 8px;
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.neutral.grey07};

  span {
    margin: 0 4px 0 0;
  }
`;

export const SelectContainer = styled.div`
  width: 200px;
`;

export const BatchActionContainer = styled.div`
  margin: 0 0 0 auto;
  display: flex;
  align-items: center;
  color: ${({ hasSelectedUsers }) =>
    hasSelectedUsers ? colors.neutral.grey10 : colors.neutral.grey05};
  gap: 16px;

  div.batch-action-button {
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    cursor: pointer;
    padding: 8px;

    &:hover {
      background-color: ${colors.neutral.grey02};
    }
  }
`;

export const ContentRow = styled.div`
  display: flex;
  gap: 16px;
  .users-table {
    flex-grow: 1;
  }
`;

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  SideBarMobileProgress,
  Header,
  StepContainer,
  ReachedCircle,
  GreyCircle
} from './styles';
import checkmark from 'assets/images/Onboarding/checkmark.svg';

const StepCircle = ({ done, doing }) => {
  const reached = done || doing;
  if (reached) {
    return (
      <ReachedCircle done={done}>
        {done && <img alt="done" src={checkmark} />}
      </ReachedCircle>
    );
  }
  return <GreyCircle />;
};

const Step = ({ step, currentStep, isFirstStep, title }) => {
  const done = step < currentStep;
  const doing = step === currentStep;
  const reached = done || doing;

  return (
    <StepContainer reached={reached} isFirstStep={isFirstStep}>
      {title}
      <StepCircle done={done} doing={doing} />
    </StepContainer>
  );
};

const ProgressMobile = ({ pageData, isOpen }) => {
  const { pathname } = useLocation();
  const currentIndex = pageData.indexOf(
    pageData.find(x => x.pathName === pathname)
  );

  return (
    <SideBarMobileProgress isOpen={isOpen}>
      <Header>Onboarding Progress</Header>
      {pageData.map((page, index) => {
        const isFirstStep = index === 0;
        const { title } = page.progress;
        return (
          <Step
            key={title}
            title={title}
            currentStep={currentIndex}
            step={index}
            isFirstStep={isFirstStep}
          />
        );
      })}
    </SideBarMobileProgress>
  );
};
export default ProgressMobile;

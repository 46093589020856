import React from 'react';
import MobileNav from './MobileNav';
import SideNavDesktop from './SideNav';
import TopNav from './TopNav';
const Menu = () => {
  return (
    <>
      <TopNav />
      <SideNavDesktop />
      <MobileNav />
    </>
  );
};

export default Menu;

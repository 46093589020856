import styled from 'styled-components';

export const SearchBarContainer = styled.div`
  display: flex;
  margin: 0 0 20px;
  .search-input {
    flex-grow: 1;
  }
  .close-icon {
    font-size: 1.2rem;
    width: 20px;
    height: 20px;
  }
`;

export const BlankContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  gap: 16px;
  text-align: center;
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey07};
  img {
    width: 140px;
    height: auto;
    margin: 0 0 8px;
  }
  h3 {
    ${props => props.theme.typography.header3};
    color: ${props => props.theme.colors.neutral.grey10};
    font-weight: 600;
    margin: 0;
  }
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  .MuiCircularProgress-root {
    margin: auto;
  }
`;

export const SubscriptionItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 68px;
  padding: 16px 0;
  box-sizing: border-box;
  gap: 8px;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.grey01};
`;

export const SubscriptionDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  margin-right: auto;
  .name {
    ${props => props.theme.typography.body};
    font-weight: 500;
  }
`;

export const AddedStatus = styled.span`
  ${props => props.theme.typography.control};
  color: ${props => props.theme.colors.neutral.grey04};
  font-weight: 600;
  padding: 0 16px;
`;

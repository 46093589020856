import axios from 'axios';
import env from '../env';
const baseUrlCompany = `${env.storeDomain}/api/companies/lync-work-users`;
const baseUrl = `${env.storeDomain}/api/lync-work-users`;
const baseUrlFrontdoor = `${env.frontDoorDomain}/api/users`;
const baseUrlCustom = `${env.storeDomain}/api/lync-work-users-custom`;
export const UserService = {
  create: data => {
    return axios.post(`${baseUrl}`, data);
  },

  update: data => {
    return axios.put(`${baseUrl}`, data);
  },

  find: id => {
    return axios.get(`${baseUrl}/${id}`);
  },

  findByUsername: async username => {
    const res = await axios.get(`${baseUrl}?username=${username}`);
    res.data = res.data.length ? res.data[0] : null;

    return res;
  },
  findUserByJWT: async () => {
    const res = await axios.get(`${baseUrl}/userByJWT`);
    return res;
  },

  findRelatedWorkflows: async id => {
    const { data } = await axios.get(`${baseUrl}/workflows`);
    return data;
  },

  //requires admin permission
  list: (
    name = '',
    page = '0',
    size = 10,
    arr = [
      'id',
      'firstName',
      'lastName',
      'roles',
      'teams',
      'manager',
      'mentor',
      'emailPrimary',
      'userStatus',
      'avatar'
    ],
    teamId = '',
    roleId = '',
    status = 'active,pending_activation,pending_email_notification',
    sort = ''
  ) => {
    const searchParams = new URLSearchParams({
      name,
      page,
      size,
      arr,
      teamId,
      roleId,
      status,
      sort
    });
    const queryString = searchParams.toString();
    return axios.get(`${baseUrl}?${queryString}`);
  },

  //list for user view
  companyDirectory: ({
    name = '',
    page = '0',
    size = '10',
    sort = 'firstName',
    direction = 'ASC',
    roleId = '',
    teamId = ''
  }) => {
    const searchParams = new URLSearchParams({
      name,
      page,
      size,
      sort,
      direction,
      teamId,
      roleId
    });
    const queryString = searchParams.toString();
    return axios.get(
      `${env.storeDomain}/api/companies/lync-work-users?${queryString}`
    );
  },

  delete: id => {
    return axios.delete(`${baseUrl}/${id}`);
  },
  changeStatus: (id, desiredStatus) => {
    return axios.put(`${baseUrl}/${id}/status/${desiredStatus}`);
  },
  resendEmail: id => {
    return axios.post(`${baseUrl}/${id}/send-activation-email`);
  },
  // type can be id or user
  listByIds: (
    ids,
    type = 'id',
    fields = ['id', 'firstName', 'lastName', 'avatar']
  ) => {
    let uniqueIds = new Set(ids);
    uniqueIds = [...uniqueIds]?.join('%2C');
    const translatedFields = fields.join('%2C');
    return axios.get(
      `${baseUrlCustom}/?lyncWorkUsers=${uniqueIds}&fields=${fields}&type=${type}`
    );
  },
  getApplications: id => {
    return axios.get(`${baseUrl}/${id}/applications`);
  },

  completeOnboarding: () => {
    return axios.post(`${baseUrl}/onboarding/completed`);
  }
};

import axios from 'axios';
import env from '../env';

const baseUrl = `${env.storeDomain}/api/dashboard/info`;

export const DashboardService = {
  list: () => {
    // returns the amount of teams, roles, workflows and users
    return axios.get(`${baseUrl}`);
  }
};

import React from 'react';

import { HeaderRow, HeaderCell } from 'components/ListTable';

const TableHeader = () => (
  <HeaderRow>
    <HeaderCell flex={2}>Name</HeaderCell>
    <HeaderCell flex={2}>Total Users</HeaderCell>
    <HeaderCell flex={2}>Journeys</HeaderCell>
    <HeaderCell flex={2}>Date Created</HeaderCell>
    {/* for edit button */}
    <HeaderCell />
  </HeaderRow>
);

export default TableHeader;

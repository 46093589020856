import styled, { css } from 'styled-components';

export const FlipCard = styled.div`
  width: 212px;
  height: 272px;
  margin: 8px;
`;

export const FlipCardInner = styled.div`
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;

  ${props => props.flip && `transform: rotateY(180deg)`};
`;
export const FlipCardFront = styled.div`
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 32px 0 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  background-color: ${props => props.theme.colors.neutral.white};
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
`;
export const FlipCardBack = styled(FlipCardFront)`
  transform: rotateY(180deg);
  &::after {
    position: absolute;
    content: '';
    right: 0;
    bottom: 0;
    transform: translate(40%, 40%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background-color: #fff2f2;
  }
  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: 240px;
    border-radius: 50%;
    background-color: #fff2f2;
  }
`;

export const AvatarContainer = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.neutral.white};
  ${props => props.theme.typography.title2};
  font-weight: 600;
  background-color: #2f4858;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Name = styled.h1`
  text-align: center;
  ${props => props.theme.typography.header3};
  font-weight: 600;
  margin: 8px 8px 8px;
`;

export const Role = styled.span`
  margin: 0;
  ${props => props.theme.typography.control};
`;

export const LinkContainer = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CustomLink = styled.a`
  width: 24px;
  height: 24px;
  margin: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${props => props.src});
  background-size: contain;
  cursor: pointer;
`;

export const FlipContainer = styled.button`
  appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin-top: auto;
  display: flex;
  ${props => props.theme.typography.metadata};
  color: ${props => props.theme.colors.brand.primary};
  img {
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 4px;
    object-fit: contain;
  }
`;

export const CardBackContentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 60px 0 12px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 30;
`;

export const CardBackWords = styled.span`
  ${props => props.theme.typography.header1};
  margin: 4px;
`;
export const CardBackWordsHeader = styled.span`
  ${props => props.theme.typography.title3};
  color: ${props => props.theme.colors.brand.primary};
  margin: 4px;
  font-weight: 600;
`;

export const Quote = styled.div`
  position: absolute;
  top: 8px;
  left: 20px;
  &::before {
    font-size: 90px;
    content: open-quote;
    color: ${props => props.theme.colors.brand.primary};
  }
`;

import { useEffect, useRef } from 'react';

function useInterval(callback, delay, immediateStart) {
  const savedCallback = useRef(callback);
  const initCall = useRef(immediateStart);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.

    if (!delay && delay !== 0) {
      return;
    }

    if (initCall.current) {
      savedCallback.current();
      initCall.current = false;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
}

export default useInterval;

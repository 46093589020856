import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

import { CustomButton } from 'components/NewLyncworkUIKit';

import {
  Container,
  Title,
  SubheadingContainer,
  ButtonContainer,
  ChevronButton,
  LoadingContainer
} from './styles';

import { TableContainer, TableHeader, TableRow } from './Table';
import useSelectUsers from './useSelectUsers';

export const LoadingWrapper = ({ children, loading }) => {
  if (!loading) {
    return children;
  }
  return (
    <LoadingContainer>
      <CircularProgress />
    </LoadingContainer>
  );
};

const SelectUsers = ({
  previousPage,
  nextPage,
  data,
  setData,
  licenseInfo,
  appAPIName
}) => {
  const hasLicenseInfo = Object.keys(licenseInfo).length > 0;
  const {
    totalSelectedCount,
    visibleUsers,
    selected,
    areAllVisibleUsersSelected,
    nextPageUsersFetched,
    loading,
    page,
    showNextPageUsers,
    showPrevPageUsers,
    handleNextStep,
    handleSelectAll,
    handleUserToggle,
    changeRole
  } = useSelectUsers({ nextPage, data, setData, appAPIName });

  const showNextButton = nextPageUsersFetched || data.nextPageParams;
  const showPrevButton = page > 1;

  return (
    <>
      <Container>
        <Title>Select Users</Title>
        <SubheadingContainer>
          <span>
            <strong>{totalSelectedCount}</strong> users selected
          </span>
          <span>Scroll horizontally to see more ></span>
        </SubheadingContainer>
        <TableContainer>
          <LoadingWrapper loading={loading}>
            <TableHeader
              handleSelectAll={handleSelectAll}
              isAllSelected={areAllVisibleUsersSelected}
              hasLicenseInfo={hasLicenseInfo}
            />
            {visibleUsers.map(userData => {
              const { username } = userData;
              const userSelected = !!selected[username];
              const toggleUser = () => handleUserToggle(username);
              const handleRoleChange = role => changeRole(username, role);
              return (
                <TableRow
                  licenseInfo={licenseInfo}
                  key={username}
                  userData={userData}
                  userSelected={userSelected}
                  handleRoleChange={handleRoleChange}
                  toggleUser={toggleUser}
                />
              );
            })}
          </LoadingWrapper>
        </TableContainer>
        <ButtonContainer>
          {showPrevButton && (
            <ChevronButton onClick={showPrevPageUsers} disabled={loading}>
              <ChevronLeftRoundedIcon />
            </ChevronButton>
          )}
          <span>Page {page}</span>
          {showNextButton && (
            <ChevronButton onClick={showNextPageUsers} disabled={loading}>
              <ChevronRightRoundedIcon />
            </ChevronButton>
          )}
        </ButtonContainer>
      </Container>
      <ButtonContainer>
        <CustomButton variant="secondary" onClick={previousPage}>
          Cancel
        </CustomButton>
        <CustomButton onClick={handleNextStep}>Next</CustomButton>
      </ButtonContainer>
    </>
  );
};

export default SelectUsers;

import React from 'react';
import { Link } from 'react-router-dom';
import { format, isValid } from 'date-fns';
import { CustomButton } from 'components/NewLyncworkUIKit';
import {
  Container,
  Title,
  Description,
  Row,
  MetaData,
  ProgressBar,
  StyledLink
} from './styles';

const formatDate = date => {
  const convertedDate = new Date(date);
  if (!isValid(convertedDate)) {
    return '';
  }
  const dateFormat = `MMM d, yyyy`;
  return format(convertedDate, dateFormat);
};

const getDateText = taskWork => {
  const { completedTasks, total, lastDateConclusion, lastDueDate } = taskWork;
  const isComplete = completedTasks === total;

  if (isComplete) {
    return `Completed on ${formatDate(lastDateConclusion)}`;
  }

  return `Due By ${formatDate(lastDueDate)}`;
};

const JourneyGridCard = ({ journey }) => {
  const { id, workflowName, workflowDesc, taskWork } = journey;
  const { completedTasks, total, lastDueDate } = taskWork;

  const repeatableGroup = journey.taskWork.repeatableGroup || 1;

  const barWidth = `${Math.floor((completedTasks * 100) / total)}%`;
  const completionCount = `${completedTasks} of ${total}`;
  const dateText = getDateText(taskWork);

  const path = `/user-journeys/${id}?repeatableGroup=${repeatableGroup}`;
  return (
    <Container data-tutorial-id="journey-list-card">
      <Link to={path}>
        <Title>{workflowName}</Title>
      </Link>
      <Description>{workflowDesc}</Description>
      <Row>
        <MetaData>Task Completed</MetaData>
        <MetaData>{completionCount}</MetaData>
      </Row>
      <ProgressBar barWidth={barWidth} />
      <Row>
        <MetaData className="bold">{dateText}</MetaData>
        <StyledLink to={path} data-tutorial-id="view-button">
          <CustomButton size="medium">View</CustomButton>
        </StyledLink>
      </Row>
    </Container>
  );
};

export default JourneyGridCard;

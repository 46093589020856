import styled from 'styled-components';
import Button from 'components/NewLyncworkUIKit/CustomButton';

export const Container = styled.div`
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  height: 460px;
`;

export const CustomButton = styled(Button)`
  margin-top: 24px;
  width: 160px;
`;
CustomButton.defaultProps = {
  variant: 'secondary'
};

import styled, { css } from 'styled-components';
import { multilineEllipsisFunc } from 'StyleVariables/StandardComponents';

export const Container = styled.div`
  height: 80px;
  flex-shrink: 0;
  margin: 0 0 8px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colors.neutral.offWhite01};
  ${props => props.theme.typography.control};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  margin-right: auto;
`;

export const FileName = styled.span`
  ${props => props.theme.typography.grey10};
  font-weight: 500;
  ${multilineEllipsisFunc(1)}
`;

const success = css`
  ${props => props.theme.colors.feedback.success};
`;
const error = css`
  ${props => props.theme.colors.feedback.error};
`;

export const FileStatus = styled.span`
  ${props => props.theme.typography.metadata};
  ${multilineEllipsisFunc(1)}
  color:${props => (props.complete ? success : error)};
`;

export const IconButton = styled.button`
  appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  margin-left: 4px;
  border-radius: 50%;
  background-color: transparent;
  color: ${props => props.theme.colors.neutral.grey04};

  &:hover {
    background-color: ${props => props.theme.colors.neutral.offWhite03};
  }
  &:focus-visible {
    outline: 2px solid blue;
  }
`;

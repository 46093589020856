import styled, { css } from 'styled-components';
import { CustomCheckbox } from 'components/NewLyncworkUIKit';

export const Card = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.neutral.white};
  padding: 24px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  display: flex;
  flex-wrap: wrap;
  grid-column: 2 / 3;
`;

export const ToggleContainer = styled.div`
  flex-shrink: 0;
  margin: 0 48px 8px 0;
`;

export const SectionTitle = styled.h1`
  margin: 0 0 16px 0;
  display: flex;
  align-items: center;
  ${props => props.theme.typography.header2};
  font-weight: 600;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 4px;
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.neutral.grey05};
  cursor: pointer;
`;

const regularTextColor = css`
  ${props => props.theme.colors.neutral.grey10};
`;
const disabledTextColor = css`
  ${props => props.theme.colors.neutral.grey04};
`;

export const CheckboxContainer = styled.div`
  margin: 0 0 16px 0;
  flex-basis: 324px;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  color: ${props => (props.disabled ? disabledTextColor : regularTextColor)};
`;

export const CheckboxTitle = styled.span`
  ${props => props.theme.typography.control};
  color: 'inherit';
  font-weight: 600;
  flex-basis: 80%;
  margin: 0 0 8px 0;
`;

export const Checkbox = styled(CustomCheckbox)`
  margin: 0 0 4px 0;
  flex-shrink: 1;
  flex-basis: 30%;
`;

import styled from 'styled-components';
import { colors, breakPoints } from 'designSystem/foundations';

const {
  neutral: { grey02, grey06, grey07, grey08, grey10 }
} = colors;
const { medium } = breakPoints;
export const Container = styled.div`
  position: sticky;
  top: 0;
  margin: 44px 0 0 32px;
  padding: 0 0 0 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: min-content;
  max-width: 360px;
  @media only screen and (max-width: ${medium}) {
    display: none;
  }
`;

export const NavLink = styled.a`
  text-decoration: none;
  ${props => props.theme.typography.body};
  color: ${props => (props.isCurrent ? grey08 : grey06)};
  padding: 8px 0 8px 12px;
  border-left: 2px solid ${grey02};
  border-color: ${props => (props.isCurrent ? grey10 : grey02)};
  font-weight: ${props => (props.isCurrent ? 600 : 400)};
  &:hover {
    color: ${props => (props.isCurrent ? grey08 : grey07)};
  }
`;

export const NavTitle = styled.span`
  ${props => props.theme.typography.header3};
  font-weight: 600;
  color: ${grey08};
  margin: 0 0 28px 0;
`;

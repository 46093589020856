import React, { useState, useRef } from 'react';
import { Visibility, VisibilityOff, FileCopy, Done } from '@material-ui/icons';
import { useButtonTrack } from 'matomo/hooks';
import { CustomButton, CustomTooltip } from 'components/NewLyncworkUIKit';
import generateMediaUrl from 'utilities/generateMediaUrl';

import {
  Container,
  AppName,
  LogoContainer,
  Step,
  Text,
  Email,
  PasswordInput,
  HiddenPasswordField,
  PasswordButton,
  CopyPasswordButton,
  ExternalLink,
  ErrorMessage
} from './styles';

const Password = ({ password }) => {
  const [copied, setCopied] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const passwordFieldRef = useRef();
  const handleCopy = () => {
    const passwordField = passwordFieldRef.current;
    passwordField.select();
    passwordField.setSelectionRange(0, 9999);
    document.execCommand('copy');
    passwordField.setSelectionRange(0, 0);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };
  const buttonText = copied ? 'Copied!' : password;

  const handleOpen = () => setTooltipOpen(true);
  const handleClose = () => setTooltipOpen(false);
  const open = tooltipOpen && !copied;
  return (
    <>
      <HiddenPasswordField readOnly value={password} ref={passwordFieldRef} />
      <CustomTooltip
        title="Click to copy"
        arrow
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        placement="top"
        disableFocusListener
        disableTouchListener
        enterDelay={2000}
      >
        <CopyPasswordButton onClick={handleCopy} copied={copied}>
          {copied ? <Done /> : <FileCopy />}
          {buttonText}
        </CopyPasswordButton>
      </CustomTooltip>
    </>
  );
};

const CoreApp = ({ coreApp, errorMessage }) => {
  const trackButton = useButtonTrack();
  const {
    appLogo,
    applicationName,
    applicationUrl,
    emailPrimary,
    tempPassword
  } = coreApp;
  const src = generateMediaUrl(appLogo);
  const handleSetupAppClick = () => {
    trackButton('onboarding open email login link');
  };
  return (
    <Container>
      <AppName>{applicationName}</AppName>
      <LogoContainer>
        <img src={src} alt={applicationName} />
      </LogoContainer>
      <Text>
        Set up {applicationName} by signing in with a temporary password.
      </Text>
      <Step>Step 1: Remember your login email</Step>
      <Email>{emailPrimary}</Email>
      <Step>Step 2: Copy the temporary password</Step>
      <Password password={tempPassword} />
      <Step>
        Step 3: Press set up and enter your email and temporary password
      </Step>
      <ExternalLink
        href={applicationUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <CustomButton width="92px" size="small" onClick={handleSetupAppClick}>
          Set up
        </CustomButton>
      </ExternalLink>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  );
};

export default CoreApp;

import React, { createContext, useState, useContext } from 'react';
import useLocalStorage from 'hooks/useLocalStorage';
import { useHistory } from 'react-router-dom';

import { steps } from './constants';

const mobileTutorial = createContext();

export const useMobileTutorial = () => useContext(mobileTutorial);

const localStorageKey = 'lw-init-tutorial';

const MobileTutorialContext = ({ children }) => {
  const [initTutorial, setInitTutorial] = useLocalStorage(
    localStorageKey,
    window.innerWidth < 640
  );
  const history = useHistory();
  const [stepIndex, setStepIndex] = useState(0);
  const currentStep = steps[stepIndex];

  const [showTutorial, setShowTutorial] = useState(initTutorial);

  const [tutorialStarted, setTutorialStarted] = useState(false);
  const [tutorialFinished, setTutorialFinished] = useState(false);
  const [showExitConfirmation, setShowExitConfirmation] = useState(false);

  const reset = () => {
    setShowTutorial(false);
    setStepIndex(0);
    setTutorialStarted(false);
    setTutorialFinished(false);
    setShowExitConfirmation(false);
  };

  const next = () => {
    if (stepIndex + 1 === steps.length) {
      setTutorialFinished(true);
      history.push('/dashboard');
      return;
    }
    const { nextLink } = currentStep;
    if (nextLink) {
      history.push(nextLink);
    }
    setStepIndex(a => a + 1);
  };

  const startTutorial = () => {
    setInitTutorial(false);
    history.push('/dashboard');
    setTutorialStarted(true);
  };
  const exitTutorial = () => {
    setInitTutorial(false);
    setShowExitConfirmation(true);
  };

  const cancelExit = () => setShowExitConfirmation(false);
  const confirmExit = () => {
    setShowTutorial(false);
    setTutorialStarted(false);
    setStepIndex(0);
    setShowExitConfirmation(false);
  };

  const value = {
    currentStep,
    next,
    showTutorial,
    tutorialFinished,
    tutorialStarted,
    showExitConfirmation,
    startTutorial,
    exitTutorial,
    confirmExit,
    cancelExit
  };
  return (
    <mobileTutorial.Provider value={value}>{children}</mobileTutorial.Provider>
  );
};

export default MobileTutorialContext;

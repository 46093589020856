import React from 'react';
import PostListItem from './PostListItem';
import { PostListContainer, PostListViewMoreButtonContainer } from './styles';
import CustomButton from '../../../../components/NewLyncworkUIKit/CustomButton';

const PostList = ({ items, viewMore }) => {
  return (
    <PostListContainer>
      {items.map(({ icon, title, description, updatedAt, path }) => (
        <PostListItem
          key={title}
          icon={icon}
          title={title}
          description={description}
          updatedAt={updatedAt}
          path={path}
        />
      ))}
      {viewMore && (
        <PostListViewMoreButtonContainer>
          <CustomButton buttonStyle="alternative">View More</CustomButton>
        </PostListViewMoreButtonContainer>
      )}
    </PostListContainer>
  );
};

export default PostList;

import styled from 'styled-components';
import Card from 'components/Card';
import { multilineEllipsis } from 'StyleVariables/StandardComponents';

export const TableContainer = styled(Card)`
  box-sizing: border-box;
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 600px;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-shrink: 0;
  align-items: center;
  box-sizing: border-box;
  height: 56px;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.grey03};
  &:nth-child(10) {
    border-color: transparent;
  }
`;

export const HeaderRow = styled(Row)`
  height: 40px;
  background-color: ${props => props.theme.colors.neutral.offWhite01};
  border-bottom: 1px solid ${props => props.theme.colors.neutral.grey03};
  border-radius: 8px 8px 0 0;
`;

export const Cell = styled.div`
  flex: ${props => props.flex};
  box-sizing: border-box;
  margin: 0 0 0 24px;
  ${props => props.theme.typography.control};

  color: ${props => props.theme.colors.neutral.grey07};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  gap: 8px;
  align-items: center;

  .overflow {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .list-row-name {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    ${props => props.theme.typography.control};
    color: ${props => props.theme.colors.neutral.grey10};
    font-weight: 500;
  }
  &.list-row-name {
    display: block;
    ${props => props.theme.typography.control};
    color: ${props => props.theme.colors.neutral.grey10};
    font-weight: 500;
  }
`;

Cell.defaultProps = { flex: 1, lineClamp: 1 };

export const HeaderCell = styled(Cell)`
  ${props => props.theme.typography.metadata};
  font-weight: 500;
`;

export const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.neutral.grey07};
`;

export const BlankContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 200px;
    height: 200px;
    object-fit: contain;
    margin-bottom: 16px;
  }
`;

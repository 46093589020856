import React from 'react';

import { useButtonTrack } from 'matomo/hooks';
import { rolesListPageEvents } from '../instrumentationEvents';

import { CustomSearch, CustomButton } from 'components/NewLyncworkUIKit';
import Pagination from 'components/Pagination';
import ListTable from 'components/ListTable';

import { Container, HeaderContainer, StyledLink } from './styles';

import useRolesList from './useRolesList';

import RoleCard from './RoleCard';
import TableHeader from './TableHeader';

const { add_role } = rolesListPageEvents;

const RolesListPage = () => {
  const trackButton = useButtonTrack();
  const {
    roles,
    totalPages,
    page,
    inputValue,
    changePage,
    handleInputChange,
    loading,
    error
  } = useRolesList();

  const hasContent = roles.length > 0;

  const handleNewRoleClick = () => trackButton(add_role);
  return (
    <Container>
      <HeaderContainer>
        <h1>Roles & Permissions</h1>
        <CustomSearch
          placeholder="Search for Roles"
          value={inputValue}
          onChange={handleInputChange}
        />
        <StyledLink to="/admin-roles/add">
          <CustomButton
            size="medium"
            tabIndex="-1"
            onClick={handleNewRoleClick}
          >
            + New Role
          </CustomButton>
        </StyledLink>
      </HeaderContainer>
      <ListTable
        header={<TableHeader />}
        loading={loading}
        error={error}
        hasContent={hasContent}
      >
        {roles.map(role => (
          <RoleCard key={role.id} role={role} />
        ))}
      </ListTable>
      <Pagination
        totalPages={totalPages}
        currentPage={page + 1}
        changePage={changePage}
      />
    </Container>
  );
};

export default RolesListPage;

import React from 'react';
import LinkedinIcon from './linkedIn.svg';

import {
  Card,
  AvatarContainer,
  StyledAvatar,
  Name,
  Role,
  LinkedInLink
} from './styles';

const formatUrl = url => {
  const removeHttps = url.replace('https://', '').replace('http://');
  return `https://${removeHttps}`;
};

const getLinkedIn = linkedinUrl => {
  if (!linkedinUrl) {
    return '';
  }
  return formatUrl(linkedinUrl);
};

const KeyContactCard = ({ user, role }) => {
  const { firstName, lastName, avatar, linkedinUrl, id } = user;
  const avatarUrl = avatar?.url;
  const firstInitial = firstName.toUpperCase()[0];
  const name = `${firstName} ${lastName}`;
  const linkedIn = getLinkedIn(linkedinUrl);
  return (
    <Card>
      <AvatarContainer>
        <StyledAvatar src={avatarUrl} name={firstInitial} colorKey={id} />
        {linkedinUrl && (
          <LinkedInLink
            href={linkedIn}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={LinkedinIcon} alt="linkedin" />
          </LinkedInLink>
        )}
      </AvatarContainer>
      <Name>{name}</Name>
      <Role>{role}</Role>
    </Card>
  );
};

export default KeyContactCard;
